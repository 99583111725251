import {
  ADD_SESSION_CHAT,
  UPDATE_SESSION_CHAT_TITLE,
  UPDATE_SESSION_CHAT,
  ADD_NEW_SESSION,
  UPDATE_SESSION_CHAT_FEEDBACK
} from '../../../actionTypes'

const getSessionChat =
  (data, callback = () => {}) =>
  async (dispatch) => {
    const { page_num, id } = data
    const dispatchType =
      id === 'new'
        ? ADD_NEW_SESSION
        : page_num === 1
        ? ADD_SESSION_CHAT
        : UPDATE_SESSION_CHAT
    dispatch({
      type: dispatchType,
      callback,
      payload: {
        data,
        id
      }
    })
  }

const updateSessionChatFeedback = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_SESSION_CHAT_FEEDBACK,
    payload: data
  })
}

const updateSessionTitle = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_SESSION_CHAT_TITLE,
    payload: data
  })
}

export { getSessionChat, updateSessionChatFeedback, updateSessionTitle }
