import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import Switch from '@mui/material/Switch'
import { useStyles } from './styles'
import { Box, Grid, Typography, Collapse, ButtonGroup, Tooltip, IconButton } from '@mui/material'
import Button from '../../components/Button'
import { getbg } from './errorCode'
import TextLineLimiter from '../../components/TextLineLimiter'
import { Container, Section, SectionFixed } from '../../components/Container'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import { useResizeDetector } from 'react-resize-detector'
import _ from 'lodash'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import CircularProgress from '@mui/material/CircularProgress'
import { toast } from 'react-toastify'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import { useColor } from '../../ThemeContext'

const ErrorCard = (props) => {
  const { word, i, showPageNumberOnly = false, handleDataChange, jumpToPage, shareType = 'private', editingDisabled = 'false' } = props
  const classes = useStyles()
  const {
    dot,
    buttonWrapper,
    errorContainer,
    buttonGroupWrapper,
    replacementWrapper,
    collapseWrapper,
    correctionContainer,
    descWrapper,
    errorWrapper,
    keywordWrapper,
    pageNoWrapper,
    accordianTitle,
    groupCount,
    toggleSwitchScroll,
    buttonHighlight1,
    buttonHighlight2,
    contentWrapper,
    groupingErrorCard
  } = classes
  const [expandedId, setExpandedId] = useState(-1)
  const [innerExpandedId, setInnerExpandedId] = useState(-1)
  const [statusLoadingResolve, setStatusLoadingResolve] = useState(false)
  const [statusLoadingIgnore, setStatusLoadingIgnore] = useState(false)
  const [copied, setCopied] = useState(false)
  const { selectedColor } = useColor()

  const handleInnerClick = (i) => {
    setInnerExpandedId(innerExpandedId === i ? -1 : i)
    /* setExpandedId(-1) */
  }

  const handleDataChangeChild = async (items, status) => {
    switch (status) {
      case 'complete':
        setStatusLoadingResolve(true)
        break
      case 'ignore':
        setStatusLoadingIgnore(true)
        break
    }
    await handleDataChange(items, status)
    setStatusLoadingResolve(false)
    setStatusLoadingIgnore(false)
  }

  const {
    errorType,
    keyword,
    pageIndex,
    description,
    id,
    status,
    keywordCorrection = '',
    pageIndexDup
  } = word
  const bg = getbg(errorType, '')

  const controlledSetShowExpand = (val) => {
    if (val) {
      handleInnerClick(`${i}-${id}`)
    } else {
      setInnerExpandedId(-1)
    }
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
    // toast.success('Copied!', {
    //     position: "top-right",
    //     autoClose: 1500,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    // });
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <Box
      className={errorContainer}
      key={i}
      onClick={() => {
        if (!Array.isArray(pageIndexDup)) {
          jumpToPage(pageIndexDup - 1)
        }
        handleInnerClick(`${i}-${id}`)
      }}
    >
      <Box className={errorWrapper}>
        {showPageNumberOnly
          ? <>
            <Box sx={{ display: 'flex', alignItems: 'baseline', maxWidth: 'calc(100% - 48px)' }}>
              <Box className={dot} sx={{ background: bg }} />
              <Box className={keywordWrapper} sx={{
                flexWrap: 'wrap'
              }}>
                {
                  Array.isArray(pageIndexDup)
                    ? <>
                      <span style={{
                        marginRight: '5px'
                      }}>
                        {pageIndexDup.length > 1 ? 'Pages ' : 'Page '}
                      </span>
                      {
                        pageIndexDup.map((item, index) => {
                          return (
                            <span key={index} onClick={(e) => {
                              e.stopPropagation()
                              jumpToPage(pageIndexDup[index] - 1)
                            }}>
                              {
                                index === 0
                                  ? <span style={{ textDecoration: 'underline' }}>{item}</span>
                                  : <>
                                    <span>, </span>
                                    <span style={{ textDecoration: 'underline' }}>{item}</span>
                                  </>
                              }
                            </span>
                          )
                        })
                      }
                    </>
                    : <>
                      Page {pageIndexDup}
                    </>

                }
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              <Box className={pageNoWrapper}>
                {status
                  ? <></>
                  : <>
                    {shareType === 'private' &&
                      <>
                        {(statusLoadingResolve || statusLoadingIgnore)
                          ? <CircularProgress size={'1.1rem'} />
                          : <>
                            <Tooltip title="Mark complete">
                              <IconButton disabled={editingDisabled} sx={{ padding: '0px' }}>
                                <CheckCircleIcon style={{ color: selectedColor, verticalAlign: 'sub' }} onClick={() => handleDataChangeChild(word, 'complete')} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Ignore">
                              <IconButton disabled={editingDisabled} sx={{ padding: '0px' }}>
                                <RemoveCircleOutlineIcon style={{ color: selectedColor, verticalAlign: 'sub' }} onClick={() => handleDataChangeChild(word, 'ignore')} />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                      </>
                    }
                  </>
                }
              </Box>
            </Box>

          </>

          : <>
            <Box sx={{ display: 'flex', alignItems: 'baseline', maxWidth: 'calc(100% - 48px)' }}>
              <Box className={dot} sx={{ background: bg }} />
              <Box className={keywordWrapper}>
                <TextLineLimiter
                  content={keyword + ' (1)'}
                  limit={2}
                  controlledExpand={innerExpandedId === `${i}-${id}`}
                  controlledSetExpand={controlledSetShowExpand}
                  isControlled={true} />
              </Box>
            </Box>
            <Box className={pageNoWrapper}>
              {status
                ? <></>
                : <>
                  {shareType === 'private' &&
                    <>
                      {(statusLoadingResolve || statusLoadingIgnore)
                        ? <CircularProgress size={'1.1rem'} />
                        : <>
                          <Tooltip title="Mark complete">
                            <IconButton disabled={editingDisabled} sx={{ padding: '0px' }}>
                              <CheckCircleIcon style={{ color: selectedColor, verticalAlign: 'sub' }} onClick={() => handleDataChangeChild(word, 'complete')} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Ignore">
                            <IconButton disabled={editingDisabled} sx={{ padding: '0px' }}>
                              <RemoveCircleOutlineIcon style={{ color: selectedColor, verticalAlign: 'sub' }} onClick={() => handleDataChangeChild(word, 'ignore')} />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    </>
                  }
                </>
              }
            </Box>
          </>
        }
      </Box>
      <Collapse in={innerExpandedId === `${i}-${id}`} timeout="auto" unmountOnExit >
        <Box className={collapseWrapper}>
          <Box className={descWrapper}>
            {description}
          </Box>
          {keywordCorrection &&
            <Box className={replacementWrapper} onClick={
              (e) => {
                e.stopPropagation()
                handleCopy(keywordCorrection)
              }
            } >
              Suggested Replacement: {keywordCorrection}
              {
                copied
                  ? <DoneRoundedIcon style={{ color: selectedColor, verticalAlign: 'sub', height: '15px' }} />
                  : <Tooltip title="Copy">
                    <ContentCopyIcon style={{ color: selectedColor, verticalAlign: 'sub', height: '15px' }} />
                  </Tooltip>
              }
            </Box>
          }
          {/* <Box className={contentWrapper}>
                            {keyword}
                        </Box> */}
          {!showPageNumberOnly && <Box className={contentWrapper} sx={{
            display: 'flex',
            flexWrap: 'wrap'
          }}>
            {
              Array.isArray(pageIndexDup)
                ? <>
                  <span style={{
                    marginRight: '5px'
                  }}>
                    {pageIndexDup.length > 1 ? 'Pages ' : 'Page '}
                  </span>
                  {
                    pageIndexDup.map((item, index) => {
                      return (
                        <span key={index} onClick={(e) => {
                          e.stopPropagation()
                          jumpToPage(pageIndexDup[index] - 1)
                        }}>
                          {
                            index === 0
                              ? <span style={{ textDecoration: 'underline' }}>{item}</span>
                              : <>
                                <span>, </span>
                                <span style={{ textDecoration: 'underline' }}>{item}</span>
                              </>
                          }
                        </span>
                      )
                    })
                  }
                </>
                : <>
                  Page {pageIndexDup}
                </>

            }
          </Box>}
          <Box className={buttonWrapper}>
            {status
              ? <Button variant="outlined" onClick={() => handleDataChange(word, '')}>Revert {status}</Button>
              : <></>
            }

          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

export default ErrorCard
