import { LayerRenderStatus } from '@react-pdf-viewer/core'
import * as React from 'react'
import { calculateOffset } from './calculateOffset'
import { HightlightItem } from './HightlightItem'
import { EMPTY_KEYWORD_REGEXP } from './constants.js'
import { unwrap } from './unwrap'
import { getCssProperties } from './getCssProperties'

// Sort the highlight elements by their positions
const sortHighlightPosition = (a, b) => {
  // Compare the top values first
  if (a.top < b.top) {
    return -1
  }
  if (a.top > b.top) {
    return 1
  }
  // Then compare the left values
  if (a.left < b.left) {
    return -1
  }
  if (a.left > b.left) {
    return 1
  }
  return 0
}

export const Highlights = ({
  numPages,
  pageIndex,
  renderHighlights,
  store,
  onHighlightKeyword,
  handleDataChange,
  showError = false,
  selectedErrorType = ''
}) => {
  const containerRef = React.useRef(null)
  const defaultRenderHighlights = React.useCallback(
    (renderProps) => (
      <>
        {renderProps.highlightAreas.map((area, index) => (
          <HightlightItem
            index={index}
            key={index}
            area={area}
            onHighlightKeyword={onHighlightKeyword}
            handleDataChange={handleDataChange}
          />
        ))}
      </>
    ),
    []
  )
  const renderHighlightElements = renderHighlights || defaultRenderHighlights

  // The initial matching position is taken from the store
  // So the current highlight is kept (after zooming the document, for example)
  const defaultMatchPosition = {
    matchIndex: 0,
    pageIndex: 0
  }
  const [matchPosition, setMatchPosition] = React.useState(
    store.get('matchPosition') || defaultMatchPosition
  )
  const [keywordRegexp, setKeywordRegexp] = React.useState(
    store.get('keyword') || [EMPTY_KEYWORD_REGEXP]
  )
  const [renderStatus, setRenderStatus] = React.useState({
    pageIndex,
    scale: 1,
    status: LayerRenderStatus.PreRender
  })
  const currentMatchRef = React.useRef(null)
  const characterIndexesRef = React.useRef([])
  const [highlightAreas, setHighlightAreas] = React.useState([])

  const defaultTargetPageFilter = () => true
  const targetPageFilter = React.useCallback(
    () => store.get('targetPageFilter') || defaultTargetPageFilter,
    [store.get('targetPageFilter')]
  )

  const highlight = (
    id,
    status,
    errorType,
    description,
    keywordCorrection,
    pageNo,
    keywordStr,
    keyword,
    textLayerEle,
    span,
    charIndexSpan
  ) => {
    const range = document.createRange()

    const firstChild = span?.firstChild
    if (!firstChild || firstChild.nodeType !== Node.TEXT_NODE) {
      return null
    }
    const length = firstChild?.textContent?.length
      ? firstChild?.textContent?.length
      : 0
    const startOffset = charIndexSpan[0].charIndexInSpan
    const endOffset =
      charIndexSpan.length === 1
        ? startOffset
        : charIndexSpan[charIndexSpan.length - 1].charIndexInSpan
    if (startOffset > length || endOffset + 1 > length) {
      return null
    }

    range.setStart(firstChild, startOffset)
    range.setEnd(firstChild, endOffset + 1)

    const wrapper = document.createElement('span')
    range.surroundContents(wrapper)

    const wrapperRect = wrapper.getBoundingClientRect()
    const textLayerRect = textLayerEle.getBoundingClientRect()
    const pageHeight = textLayerRect.height
    const pageWidth = textLayerRect.width

    const left = (100 * (wrapperRect.left - textLayerRect.left)) / pageWidth
    const top = (100 * (wrapperRect.top - textLayerRect.top)) / pageHeight
    const height = (100 * wrapperRect.height) / pageHeight
    const width = (100 * wrapperRect.width) / pageWidth

    unwrap(wrapper)

    return {
      keyword,
      keywordStr,
      numPages,
      pageIndex,
      left,
      top,
      height,
      width,
      pageHeight,
      pageWidth,
      errorType,
      id,
      status,
      description,
      keywordCorrection,
      pageNo
    }
  }

  const highlightAll = (textLayerEle) => {
    const charIndexes = characterIndexesRef.current
    if (charIndexes.length === 0) {
      return []
    }

    const highlightPos = []
    const spans = [].slice.call(
      textLayerEle.querySelectorAll('.rpv-core__text-layer-text')
    )
    // Generate the full text of page
    const fullText = charIndexes.map((item) => item.char).join('')
    keywordRegexp.forEach((keyword) => {
      if (Array.isArray(keyword.pageIndex)) {
        keyword.pageIndex.map((page) => {
          if (parseInt(pageIndex) === parseInt(page) - 1) {
            const keywordStr = keyword.keyword
            if (!keywordStr.trim()) {
              return []
            }

            // Clone the keyword regular expression, and add the global (`g`) flag
            // If the `g` flag is missing, it will lead to an infinitive loop
            const cloneKeyword =
              keyword.regExp.flags.indexOf('g') === -1
                ? new RegExp(keyword.regExp, `${keyword.regExp.flags}g`)
                : keyword.regExp

            const cloneKeyword2 = keyword.keywordRegExp
            // Find all matches in the full text
            let match, minMatch
            const matches = []
            while ((match = cloneKeyword.exec(fullText)) !== null) {
              while ((minMatch = cloneKeyword2.exec(match)) !== null) {
                const startIndex =
                  parseInt(match.index) + parseInt(minMatch.index) + 1
                const endIndex =
                  startIndex + parseInt(keyword.keyword.length + 1)
                if (
                  keyword.errorType === selectedErrorType ||
                  selectedErrorType === 'all'
                ) {
                  matches.push({
                    keyword: cloneKeyword,
                    startIndex,
                    endIndex,
                    errorType: keyword.errorType,
                    description: keyword.description,
                    keywordCorrection: keyword.keywordCorrection,
                    pageNo: keyword.pageNo,
                    id: keyword.id,
                    status: keyword.status
                  })
                }
              }
            }
            matches
              .map((item) => ({
                keyword: item.keyword,
                indexes: charIndexes.slice(item.startIndex, item.endIndex),
                errorType: item.errorType,
                description: item.description,
                keywordCorrection: item.keywordCorrection,
                id: item.id,
                status: item.status,
                pageNo: item.pageNo
              }))
              .forEach((item) => {
                // Group by the span index
                const spanIndexes = item.indexes.reduce((acc, item) => {
                  acc[item.spanIndex] = (acc[item.spanIndex] || []).concat([
                    item
                  ])
                  return acc
                }, {})
                Object.values(spanIndexes).forEach((charIndexSpan) => {
                  // Ignore the space between words
                  if (
                    charIndexSpan.length !== 1 ||
                    charIndexSpan[0].char.trim() !== ''
                  ) {
                    // Ignore the first and last spaces if we are finding the whole word
                    const normalizedCharSpan = keyword.wholeWords
                      ? charIndexSpan.slice(1, -1)
                      : charIndexSpan
                    const hightlighPosition = highlight(
                      item.id,
                      item.status,
                      item.errorType,
                      item.description,
                      item.keywordCorrection,
                      item.pageNo,
                      keywordStr,
                      item.keyword,
                      textLayerEle,
                      spans[normalizedCharSpan[0]?.spanIndex],
                      normalizedCharSpan
                    )
                    if (hightlighPosition) {
                      highlightPos.push(hightlighPosition)
                    }
                  }
                })
              })
          }
          return []
        })
      } else {
        if (parseInt(pageIndex) === parseInt(keyword.pageIndex) - 1) {
          const keywordStr = keyword.keyword
          if (!keywordStr.trim()) {
            return
          }

          // Clone the keyword regular expression, and add the global (`g`) flag
          // If the `g` flag is missing, it will lead to an infinitive loop
          const cloneKeyword =
            keyword.regExp.flags.indexOf('g') === -1
              ? new RegExp(keyword.regExp, `${keyword.regExp.flags}g`)
              : keyword.regExp

          const cloneKeyword2 = keyword.keywordRegExp
          // Find all matches in the full text
          let match, minMatch
          const matches = []
          while ((match = cloneKeyword.exec(fullText)) !== null) {
            while ((minMatch = cloneKeyword2.exec(match)) !== null) {
              const startIndex =
                parseInt(match.index) + parseInt(minMatch.index) + 1
              const endIndex = startIndex + parseInt(keyword.keyword.length + 1)
              if (
                keyword.errorType === selectedErrorType ||
                selectedErrorType === 'all'
              ) {
                matches.push({
                  keyword: cloneKeyword,
                  startIndex,
                  endIndex,
                  errorType: keyword.errorType,
                  description: keyword.description,
                  keywordCorrection: keyword.keywordCorrection,
                  pageNo: keyword.pageNo,
                  id: keyword.id,
                  status: keyword.status
                })
              }
            }
          }
          matches
            .map((item) => ({
              keyword: item.keyword,
              indexes: charIndexes.slice(item.startIndex, item.endIndex),
              errorType: item.errorType,
              description: item.description,
              keywordCorrection: item.keywordCorrection,
              id: item.id,
              status: item.status,
              pageNo: item.pageNo
            }))
            .forEach((item) => {
              // Group by the span index
              const spanIndexes = item.indexes.reduce((acc, item) => {
                acc[item.spanIndex] = (acc[item.spanIndex] || []).concat([item])
                return acc
              }, {})
              Object.values(spanIndexes).forEach((charIndexSpan) => {
                // Ignore the space between words
                if (
                  charIndexSpan.length !== 1 ||
                  charIndexSpan[0].char.trim() !== ''
                ) {
                  // Ignore the first and last spaces if we are finding the whole word
                  const normalizedCharSpan = keyword.wholeWords
                    ? charIndexSpan.slice(1, -1)
                    : charIndexSpan
                  const hightlighPosition = highlight(
                    item.id,
                    item.status,
                    item.errorType,
                    item.description,
                    item.keywordCorrection,
                    item.pageNo,
                    keywordStr,
                    item.keyword,
                    textLayerEle,
                    spans[normalizedCharSpan[0]?.spanIndex],
                    normalizedCharSpan
                  )
                  if (hightlighPosition) {
                    highlightPos.push(hightlighPosition)
                  }
                }
              })
            })
        }
      }
    })

    // Sort the highlight elements as they appear in the texts
    return highlightPos.sort(sortHighlightPosition)
  }

  const handleKeywordChanged = (keyword) => {
    if (keyword && keyword.length > 0) {
      setKeywordRegexp(keyword)
    }
  }

  const handleMatchPositionChanged = (currentPosition) =>
    setMatchPosition(currentPosition)

  const handleRenderStatusChanged = (status) => {
    if (!status.has(pageIndex)) {
      return
    }
    const currentStatus = status.get(pageIndex)
    if (currentStatus) {
      setRenderStatus({
        ele: currentStatus.ele,
        pageIndex,
        scale: currentStatus.scale,
        status: currentStatus.status
      })
    }
  }

  const isEmptyKeyword = () =>
    keywordRegexp.length === 0 ||
    (keywordRegexp.length === 1 && keywordRegexp[0].keyword?.trim() === '')

  // Prepare the characters indexes
  // The order of hooks are important. Since `charIndexes` will be used when we highlight matching items,
  // this hook is put at the top
  React.useEffect(() => {
    if (
      isEmptyKeyword() ||
      renderStatus.status !== LayerRenderStatus.DidRender ||
      characterIndexesRef.current.length
    ) {
      return
    }

    const textLayerEle = renderStatus.ele
    const spans = [].slice.call(
      textLayerEle?.querySelectorAll('.rpv-core__text-layer-text')
    )

    const initalValue = [
      {
        char: '',
        charIndexInSpan: 0,
        spanIndex: 0
      }
    ]
    const charIndexes = spans
      .map((span) => span.textContent)
      .reduce(
        (prev, curr, index) =>
          prev.concat(
            curr.split('').length > 0
              ? curr.split('').map((c, i) => ({
                  char: c,
                  charIndexInSpan: i,
                  spanIndex: index
                }))
              : [
                  {
                    char: ' ',
                    charIndexInSpan: 0,
                    spanIndex: index
                  }
                ]
          ),
        initalValue
      )
    characterIndexesRef.current = charIndexes
  }, [keywordRegexp, renderStatus.status])

  React.useEffect(() => {
    if (
      isEmptyKeyword() ||
      !renderStatus.ele ||
      renderStatus.status !== LayerRenderStatus.DidRender ||
      !targetPageFilter()({ pageIndex, numPages })
    ) {
      return
    }

    const textLayerEle = renderStatus.ele
    const highlightPos = highlightAll(textLayerEle)
    setHighlightAreas(highlightPos)
  }, [
    keywordRegexp,
    matchPosition,
    renderStatus.status,
    characterIndexesRef.current
  ])

  React.useEffect(() => {
    if (
      isEmptyKeyword() &&
      renderStatus.ele &&
      renderStatus.status === LayerRenderStatus.DidRender
    ) {
      setHighlightAreas([])
    }
  }, [keywordRegexp, renderStatus.status])

  React.useEffect(() => {
    if (highlightAreas.length === 0) {
      return
    }

    const container = containerRef.current
    if (
      matchPosition.pageIndex !== pageIndex ||
      !container ||
      renderStatus.status !== LayerRenderStatus.DidRender
    ) {
      return
    }

    const highlightEle = container.querySelector(
      `.rpv-search__highlight[data-index="${matchPosition.matchIndex}"]`
    )
    if (!highlightEle) {
      return
    }

    const { left, top } = calculateOffset(highlightEle, container)
    const jump = store.get('jumpToDestination')
    if (jump) {
      jump(
        pageIndex,
        (container.getBoundingClientRect().height - top) / renderStatus.scale,
        left / renderStatus.scale,
        renderStatus.scale
      )
      if (currentMatchRef.current) {
        currentMatchRef.current.classList.remove(
          'rpv-search__highlight--current'
        )
      }
      currentMatchRef.current = highlightEle
      highlightEle.classList.add('rpv-search__highlight--current')
    }
  }, [highlightAreas, matchPosition])

  React.useEffect(() => {
    store.subscribe('keyword', handleKeywordChanged)
    store.subscribe('matchPosition', handleMatchPositionChanged)
    store.subscribe('renderStatus', handleRenderStatusChanged)

    return () => {
      store.unsubscribe('keyword', handleKeywordChanged)
      store.unsubscribe('matchPosition', handleMatchPositionChanged)
      store.unsubscribe('renderStatus', handleRenderStatusChanged)
    }
  }, [])

  return (
    <div
      className="rpv-search__highlights"
      data-testid={`search__highlights-${pageIndex}`}
      style={{ zIndex: showError ? 1 : 0 }}
      ref={containerRef}
    >
      {renderHighlightElements({
        getCssProperties,
        highlightAreas
      })}
    </div>
  )
}
