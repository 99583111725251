import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingTop: '5px',
    paddingRight: '20px'
  },
  wrapper: {
    margin: 5
  },
  avatar: {
    color: 'green',
    border: '2px green solid',
    backgroundColor: 'white',
    borderRadius: '50%',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'PoppinsRegular',
    width: '35px',
    height: '35px'
  },
  avatarButton: {
    padding: '0px !important'
  },
  listCardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  listCardText: {
    paddingLeft: '10px',
    '& p': {
      margin: '0px !important',
      marginLeft: '5px !important',
      fontFamily: 'PoppinsRegular',
      whiteSpace: 'break-spaces',
      fontSize: '13px',
      letterSpacing: '0.01em',
      lineHeight: '20px'
    }
  },
  cardTitle: {
    fontWeight: 'bold',
    color: 'black'
  },
  cardDescription: {
    fontWeight: '500',
    color: '#949494'
  },
  cardIcon: {
    width: '16px'
  }
}))

export { useStyles }
