import { SET_ACTIVE_LIBRARY_TAG } from '../../../actionTypes'

const setActiveTag = (tag) => async (dispatch) => {
  dispatch({
    type: SET_ACTIVE_LIBRARY_TAG,
    payload: tag
  })
}

export { setActiveTag }
