import eventMapping from '../../config/eventMapping'
import trackEvent from '../TrackEvent/TrackEvent'

export const copyHTMLContent = (content) => {
  const clipboardItem = new ClipboardItem({
    'text/html': new Blob([content], { type: 'text/html' }),
    'text/plain': new Blob([htmlToText(content)], { type: 'text/plain' })
  })
  navigator.clipboard.write([clipboardItem])
}

export const stripHTML = (html) => {
  return html?.replace(/<[^>]+>/g, '')
}

export const stripMark = (html) => {
  return html?.replaceAll('<mark>', '').replaceAll('</mark>', '')
}

export const textToHtml = (text) => {
  if (text) {
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br>')
    text = text.replace(/^-\s(.*)$/gm, '<li>$1</li>')
    text = text.replace(/^[0-9]+\.\s(.*)$/gm, '<li>$1</li>')
    text = text.replace(/^(#+)\s(.*)$/gm, '<h1>$2</h1>')
  }
  return text
}

export const htmlToText = (html) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  let text = ''
  let list_type = ''
  let bullet = ''
  let count = 1

  const processNode = (node) => {
    switch (node.nodeName) {
      case 'UL':
        list_type = 'ul'
        bullet = '-'
        node.childNodes.forEach((li) => {
          if (li.nodeName === 'LI') processNode(li)
        })
        list_type = ''
        bullet = ''
        text += '\n'
        break
      case 'OL':
        list_type = 'ol'
        count = 1
        node.childNodes.forEach((li) => {
          if (li.nodeName === 'LI') processNode(li)
        })
        list_type = ''
        bullet = ''
        text += '\n'
        break
      case 'LI':
        if (list_type === 'ul') {
          text += bullet + ' ' + node.textContent.trim() + '\n'
        } else if (list_type === 'ol') {
          text += count + '. ' + node.textContent.trim() + '\n'
          count++
        } else {
          text += node.textContent.trim() + '\n'
        }
        break
      case 'BR':
        text += '\n'
        break
      case 'P':
      case 'H1':
      case 'H2':
      case 'H3':
      case 'H4':
      case 'H5':
      case 'H6':
        text += node.textContent.trim() + '\n\n'
        break
      default:
        if (node.childNodes.length > 0) {
          node.childNodes.forEach((child) => processNode(child))
        } else {
          text += node.textContent
        }
    }
  }

  doc.body.childNodes.forEach((node) => processNode(node))
  return text.trim()
}

export const stripUnknownASCII = (text) => {
  // eslint-disable-next-line no-control-regex
  return text?.replace(/[^\x00-\x7F]/g, '')
}

export const removeClassNamesFromHTML = (htmlString) => {
  const cleanedHTML = htmlString.replace(/ class="[^"]*"/g, '')
  return cleanedHTML
}
