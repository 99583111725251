import { putSuperUserDomainId } from '../../../api'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'

const updateSuperUserDomainId = (data, callback) => async () => {
  const res = await putSuperUserDomainId(data)
  if (res.status === 200) {
    Auth.currentAuthenticatedUser().then(async (user) => {
      const session = await Auth.currentSession()
      user.refreshSession(session.refreshToken, async (err, session) => {
        window.location.reload()
      })
      localStorage.removeItem('collectionFilterMap')
    })
  } else {
    callback()
    toast.error('Unable to switch domain')
  }
}

export { updateSuperUserDomainId }
