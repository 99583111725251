import { UPDATE_PEOPLE_TAG } from '../../../actionTypes'

const updateTag = (data, callback) => async (dispatch) => {
  dispatch({
    type: UPDATE_PEOPLE_TAG,
    payload: data,
    callback
  })
}

export default updateTag
