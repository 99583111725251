import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
  outerContainer: {
    height: '100%',
    padding: '0 20px'
  },
  root: {
    margin: 5,
    width: '100%',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'flex-end'
  },
  avatar: {
    color: theme.palette.primary.main,
    border: `2px ${theme.palette.primary.secondary} solid`,
    backgroundColor: 'white',
    borderRadius: '50%',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'PoppinsRegular'
  }
}))

export { useStyles }
