import React, { useEffect, useRef } from 'react'
import {
  CircleLoader,
  CrossIcon,
  SearchIcon
} from '../../components/Icons/Icons'

const SearchNew = ({
  onEnter = () => {},
  onChange = () => {},
  onClear = null,
  value,
  onDebounce,
  applyDebounce,
  loading
}) => {
  // const inputRef = useRef(null)

  useEffect(() => {
    if (applyDebounce) {
      const timer = setTimeout(() => {
        onDebounce(value)
      }, 500)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [value])

  return (
    <div className="flex gap-2 items-center bg-grey-50 p-2 px-3 rounded-lg border-1 border-grey-200">
      <SearchIcon className="size-4 text-grey-500" />
      <input
        type="text"
        className="input-base bg-grey-50 w-full"
        placeholder="Search..."
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onEnter(event.target.value)
          }
        }}
      />
      {loading ? (
        <CircleLoader className="size-4 text-grey-500 animate-spin ml-auto" />
      ) : (
        <CrossIcon
          className={`size-4 text-grey-500 cursor-pointer ml-auto ${
            value.length > 0 ? 'visible' : 'invisible'
          }`}
          onClick={() => (onClear ? onClear('') : onChange(''))}
        />
      )}
    </div>
  )
}

export default SearchNew
