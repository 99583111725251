import eventMapping from '../../../../config/eventMapping'
import mixpanelEvents from '../../../../config/mixpanelEvents'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import { ADD_DOMAIN_INVITES, TOASTER_ALERT } from '../../../actionTypes'
import { postUserInvites } from '../../../api'
import { toast } from 'react-toastify'

const inviteUsertoDomain = (emailList) => async (dispatch) => {
  const request = {
    emailList
  }
  const res = await postUserInvites(request)
  if (res.status === 200) {
    const newInvite = res.data
    dispatch({
      type: ADD_DOMAIN_INVITES,
      payload: newInvite?.recipients
    })
    trackEvent(mixpanelEvents.SETTINGS_USER_INVITED, 'SUCCESS', {}, {})
    if (newInvite.exiting_user.length === 0) {
      dispatch({
        type: TOASTER_ALERT,
        payload: {
          message: 'Invite sent',
          show: true,
          type: 'success'
        }
      })
    } else {
      const message =
        'User' +
        newInvite.exiting_user.join(', ') +
        ' are already registered with the other domain'
      dispatch({
        type: TOASTER_ALERT,
        payload: {
          message,
          show: true,
          type: 'success'
        }
      })
    }
  } else if (res.status === 208) {
    dispatch({
      type: TOASTER_ALERT,
      payload: {
        message: 'User already invited',
        show: true,
        type: 'success'
      }
    })
  } else if (res.response.status === 405) {
    dispatch({
      type: TOASTER_ALERT,
      payload: {
        message: 'You need admin access to add or update users',
        show: true,
        type: 'error'
      }
    })
  } else {
    dispatch({
      type: TOASTER_ALERT,
      payload: {
        message: 'Failed to invite user',
        show: true,
        type: 'error'
      }
    })
    trackEvent(
      mixpanelEvents.SETTINGS_USER_INVITED,
      'FAILED',
      {},
      { message: 'Failed to invite user' }
    )
  }
}

export { inviteUsertoDomain }
