import React, { useState, useEffect, useRef } from 'react'
import { useStyles } from './styles'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { autoCompleteAll } from '../../store/api'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Search from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import _, { set } from 'lodash'
import clsx from 'clsx'
import HistoryIcon from '@mui/icons-material/History'
import ClearIcon from '@mui/icons-material/Clear'
import { v4 as uuidv4 } from 'uuid'
import { IconButton, SvgIcon, Tooltip } from '@mui/material'
import { clearSearchHistory } from '../../store/Search/Actions'
import Box from '@mui/material/Box'
import PeopleIcon from '@mui/icons-material/People'
import DescriptionIcon from '@mui/icons-material/Description'
import ConstructionIcon from '@mui/icons-material/Construction'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Diversity1Icon from '@mui/icons-material/Diversity1'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import { checkDomainAccess } from '../../utils/User'
import { EllipsisTooltip, ImageAvatarFallback } from '../../components'
import Masonry from 'react-masonry-css'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { ReactComponent as LibraryIcon } from '../../assets/images/Library.svg'

export default function AutoCompleteSearch(props) {
  const {
    searchKey,
    setSearchKey,
    className = '',
    handleKeyDown = () => {},
    setSearchType = () => {},
    showSearchIcon = true,
    disabled = false,
    s3Obj = {},
    initalState = false,
    endAdornment = <></>
  } = props

  const classes = useStyles()
  const {
    inputWrapper,
    suggestionWrapper,
    listWrapper,
    clearIcon,
    displayListWrapper
  } = classes
  const [options, setOptions] = useState([])
  const [recentOptions, setRecentOptions] = useState([])
  const [filteredOptions, setFilteredOptions] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    LIBRARY_PROJECTS,
    LIBRARY_KNOWLEDGE,
    LIBRARY_PEOPLE_RESUME,
    LIBRARY_CLIENTS_RESUME
  } = ROUTES
  const showPeopleTabs = checkDomainAccess('people_resume')
  const showProjectTabs = checkDomainAccess('project_resume')
  const showClientTabs = checkDomainAccess('client_resume')
  const [fetchedAutoData, setFetchedAutoData] = useState({})
  const searchKeyRef = useRef(searchKey)
  const [open, setOpen] = useState(initalState)
  const [recentSearchs, setRecentSearchs] = useState([])
  const autocompleteRef = useRef(null)
  const searchState = useSelector((state) => state.search)
  const { recentSearch } = searchState

  useEffect(() => {
    setOpen(initalState)
  }, [initalState])

  useEffect(() => {
    if (_.isArray(recentSearch)) {
      let prevSearch = []
      recentSearch.forEach((search, index) => {
        const { search_keyword, search_type, id } = search
        let type = ''
        if (
          search_type === 'section' ||
          search_type === 'all' ||
          search_type === 'narrative' ||
          search_type === 'text'
        ) {
          type = 'text'
        } else if (search_type === 'asset') {
          type = 'assets'
        } else if (search_type === 'chat') {
          type = 'chat'
        }
        prevSearch.push({
          id: `recent_${index}`,
          index: id,
          type: 'recent',
          search_type,
          search_keyword,
          label: search_keyword
        })
      })
      prevSearch = prevSearch.filter(function ({
        search_keyword,
        search_type
      }) {
        const key = `${search_keyword}${search_type}`
        return !this.has(key) && this.add(key)
      },
      new Set())
      if (recentSearch) {
        if (prevSearch.length >= 20) {
          prevSearch = prevSearch.slice(0, 20)
        }
      }
      setRecentSearchs(prevSearch)
    }
  }, [recentSearch])

  useEffect(() => {
    searchKeyRef.current = searchKey
  }, [searchKey])

  const getData = async () => {
    if (fetchedAutoData[searchKey]) {
      setUniqueOptions(fetchedAutoData[searchKey])
    } else {
      const res = await autoCompleteAll(searchKey)
      if (res.status === 200 && res?.data) {
        const { autocomplete, suggestion, filenames } = res.data || {}
        const newAutocomplete = []
        autocomplete.forEach((el) => {
          const { value, type, profile_link = '' } = el
          const formatedValue = _.capitalize(value?.replace(/<[^>]+>/g, ''))
          if (type === 'resume' && showPeopleTabs) {
            newAutocomplete.push({
              label: formatedValue,
              id: el.master_group_id,
              type,
              profile_link
            })
          } else if (type === 'project' && showProjectTabs) {
            newAutocomplete.push({
              label: formatedValue,
              id: 'auto_' + uuidv4(),
              type
            })
          } else if (type === 'client' && showClientTabs) {
            newAutocomplete.push({
              label: formatedValue,
              id: 'auto_' + uuidv4(),
              type
            })
          } else if (!['resume', 'project', 'client'].includes(type)) {
            newAutocomplete.push({
              label: formatedValue,
              id: 'auto_' + uuidv4(),
              type
            })
          }
        })
        const newSuggestion = suggestion.forEach((el) => {
          if (_.lowerCase(el) !== _.lowerCase(searchKey)) {
            return {
              label: _.capitalize(el),
              suggestedFor: searchKey,
              id: 'sugg_' + uuidv4(),
              type: 'suggestion'
            }
          }
        })
        const newFilenames = filenames.map((el) => {
          const { filename } = el
          const formatedValue = _.capitalize(filename)
          return { label: formatedValue, id: 'file_' + uuidv4(), type: 'file' }
        })

        const updatedOptions = [
          ...(newAutocomplete || []),
          ...(newFilenames || []),
          ...(newSuggestion || [])
        ]
        setFetchedAutoData((prev) => {
          return {
            ...prev,
            [searchKey]: updatedOptions
          }
        })
        if (searchKey && searchKeyRef.current === searchKey) {
          setUniqueOptions(updatedOptions)
        }
      }
    }
  }

  const setUniqueOptions = (
    options = filterOptions,
    recOptions = recentOptions
  ) => {
    let uniqOptions = options?.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.label === obj.label)
    )
    if (uniqOptions.length === 1) {
      if (uniqOptions[0].label === '') {
        uniqOptions = []
      }
    }
    setFilteredOptions(uniqOptions)
    uniqOptions = [...uniqOptions, ...recOptions]
    const sortOrder = [
      'resume',
      'client',
      'project',
      'recent',
      'library',
      'file',
      'section',
      'suggestion'
    ]
    uniqOptions = uniqOptions.sort((a, b) => {
      return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type)
    })
    const types = uniqOptions.map((el) => el.type)
    setOptions(uniqOptions)
  }

  useEffect(() => {
    if (searchKey) {
      const delayDebounceFn = setTimeout(() => {
        getData()
      }, 200)
      return () => clearTimeout(delayDebounceFn)
    } else {
      setOptions([])
      setUniqueOptions([], recentOptions)
    }
  }, [searchKey])

  useEffect(() => {
    if (!_.isEmpty(recentSearchs)) {
      let uniqOptions = recentSearchs?.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.label === obj.label)
      )
      if (uniqOptions.length === 1) {
        if (uniqOptions[0].label === '') {
          uniqOptions = []
        }
      }
      setRecentOptions(uniqOptions)
      setUniqueOptions(filteredOptions, uniqOptions)
    } else {
      setRecentOptions([])
    }
  }, [recentSearchs])

  const filterOptions = createFilterOptions({
    stringify: (option) => option && option.label + option?.suggestedFor
  })

  const handleOnChange = (e, val, isNewTab = false) => {
    const { type, label, id } = val || {}
    if (['resume', 'project', 'client', 'file', 'library'].includes(type)) {
      if (type === 'resume') {
        const path = `${LIBRARY_PEOPLE_RESUME}/${id}`
        if (isNewTab) {
          openInNewTab(e, path)
          return
        }
        window.location.href = path
        // navigate(path)
      } else if (type === 'project') {
        const path = `${LIBRARY_PROJECTS}/${id}`
        if (isNewTab) {
          openInNewTab(e, path)
          return
        }
        navigate(path)
      } else if (type === 'client') {
        const path = `${LIBRARY_CLIENTS_RESUME}/${id}`
        if (isNewTab) {
          openInNewTab(e, path)
          return
        }
        navigate(path)
      } else if (type === 'file') {
        const path = `/view/pdf/${id}/0`
        if (isNewTab) {
          openInNewTab(e, path)
          return
        }
        navigate(path)
      } else if (type === 'library') {
        const path = `${LIBRARY_KNOWLEDGE}?libraryId=${id}`
        if (isNewTab) {
          openInNewTab(e, path)
          return
        }
        navigate(path)
      }
    } else {
      if (label) {
        setSearchKey(label)
      }
      if (type === 'recent') {
        setSearchType(val?.search_type === 'all' ? 'text' : val?.search_type)
      }
    }
  }

  const openInNewTab = (e, url) => {
    setOpen(true)
    e.stopPropagation()
    e.preventDefault()
    const newTab = window.open(url, '_blank')
    if (!newTab) {
      toast.error(
        'Unable to open in new tab. Please allow popups for this site'
      )
    }
  }

  const handleClearHistory = (e, option) => {
    e.stopPropagation()
    e.preventDefault()
    const requestData = {
      search_keyword: option.search_keyword,
      search_type: option.search_type,
      id: option.index
    }
    dispatch(clearSearchHistory(requestData))
  }

  const getIcon = (option) => {
    const { type, profile_link, label } = option
    if (type === 'resume') {
      return (
        <Box>
          <ImageAvatarFallback
            name={label}
            s3Obj={s3Obj}
            profilePic={profile_link}
            style={{
              width: '30px',
              height: '30px',
              border: '1px solid #E5E5E5'
            }}
          />
        </Box>
      )
    }
    switch (type) {
      case 'resume':
        return <PeopleIcon />
      case 'section':
        return <Search />
      case 'project':
        return <ConstructionIcon />
      case 'recent':
        return <HistoryIcon />
      case 'file':
        return <DescriptionIcon />
      case 'suggestion':
        return <AutoAwesomeIcon />
      case 'client':
        return <Diversity1Icon />
      case 'library':
        return (
          <Box className={classes.libraryIcon}>
            <SvgIcon>
              <LibraryIcon />
            </SvgIcon>
          </Box>
        )
      default:
        return <Search />
    }
  }

  const getOptionGroupLabel = (label) => {
    if (label === 'library') {
      return 'Knowledge'
    } else if (label === 'recent') {
      return 'Recent Searches'
    } else if (label === 'resume') {
      return 'People'
    } else if (label === 'file') {
      return 'Files'
    } else if (label === 'section') {
      return 'Document'
    } else if (label === 'suggestion') {
      return 'Suggestions'
    } else {
      return _.capitalize(label)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target)
      ) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [autocompleteRef])

  const getBreakPointCols = (length) => {
    return length > 1
      ? {
          default: 2,
          800: 1
        }
      : { default: 1 }
  }

  const ListboxComponent = React.forwardRef((props, ref) => {
    const breakpointCols = getBreakPointCols(props?.children?.length)
    return (
      <Box className={classes.masonryWrapper}>
        <Box ref={ref} {...props} sx={{ padding: '0px !important' }}>
          <Masonry
            breakpointCols={breakpointCols}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {props.children}
          </Masonry>
        </Box>
      </Box>
    )
  })

  return (
    <div ref={autocompleteRef} style={{ width: '100%' }}>
      <Autocomplete
        filterOptions={filterOptions}
        disablePortal
        options={_.isEmpty(options) ? recentOptions : options}
        freeSolo
        onChange={(e, val) => handleOnChange(e, val)}
        className={clsx(inputWrapper, className)}
        inputValue={searchKey}
        disabled={disabled}
        ListboxComponent={ListboxComponent}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={(e) => {
          const { type } = e || {}
          if (type !== 'blur') {
            setOpen(false)
          }
        }}
        componentsProps={{
          paper: {
            style: {
              borderRadius: '6px'
            }
          }
        }}
        groupBy={(option) => option.type}
        renderGroup={(params) => {
          const { key, group, children } = params
          return (
            <Box id={key} className={'masonry-item'}>
              <Box sx={{ padding: '10px', fontWeight: 'bold' }}>
                {getOptionGroupLabel(group)}
              </Box>
              {children.map((child) => child)}
            </Box>
          )
        }}
        renderInput={(params) => {
          params.InputProps.startAdornment = showSearchIcon ? (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ) : (
            <></>
          )
          params.InputProps.endAdornment = endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : (
            <></>
          )
          return (
            <TextField
              multiline
              sx={{ width: '100%' }}
              maxRows={5}
              value={searchKey}
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearchKey(e.target.value)}
              placeholder="What can I help you find"
              {...params}
            />
          )
        }}
        renderOption={(props, option) => {
          return (
            <Box key={option.id} className={displayListWrapper}>
              <Box
                {...props}
                component={'li'}
                className={clsx(listWrapper, props?.className)}
              >
                <Box className={classes.labelWrapperOuter}>
                  <Box
                    className={
                      ['resume', 'recent'].includes(option.type)
                        ? classes.labelWrapperCenter
                        : classes.labelWrapper
                    }
                  >
                    <Box className={classes.recentIcon}>
                      <Tooltip title={_.capitalize(option?.type)}>
                        {getIcon(option)}
                      </Tooltip>
                    </Box>
                    <Box
                      className={
                        [
                          'resume',
                          'project',
                          'client',
                          'file',
                          'library',
                          'recent'
                        ].includes(option.type)
                          ? classes.labelTextRecent
                          : classes.labelText
                      }
                    >
                      <EllipsisTooltip text={option.label} />
                      {option?.search_type && (
                        <Box
                          component={'span'}
                          className={classes.searchTypeHightlight}
                        >
                          {' '}
                          in{' '}
                          {_.capitalize(
                            option?.search_type === 'all'
                              ? 'text'
                              : option?.search_type
                          )}
                        </Box>
                      )}
                    </Box>
                    {option.type === 'recent' && (
                      <Box>
                        <Tooltip title="Remove Search History">
                          <IconButton
                            className={classes.clearIcon}
                            disableRipple
                            onClick={(e) => {
                              handleClearHistory(e, option)
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                    {[
                      'resume',
                      'project',
                      'client',
                      'file',
                      'library'
                    ].includes(option.type) && (
                      <Box>
                        <Tooltip title="Open in New Tab">
                          <IconButton
                            className={classes.clearIcon}
                            disableRipple
                            onClick={(e) => {
                              handleOnChange(e, option, true)
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        }}
      />
    </div>
  )
}
