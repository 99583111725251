import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  inputWrapper: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot': {
      borderRadius: '6px',
      padding: '9px 15px !important'
    }
  },
  suggestionWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: '#949494'
  },
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    padding: 5,
    width: '100%',
    paddingTop: '8px'
  },
  displayListWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 0',
    '&:hover': {
      '& >div >div': { display: 'block' }
    },
    '& li': {
      padding: '0px !important'
    }
  },
  masonryWrapper: {
    fontSize: '14px',
    '& .my-masonry-grid': {
      display: 'flex',
      width: 'calc(100% - 14px)',
      padding: '0px 7px',
      gap: '7px'
    },
    '& .my-masonry-grid_column': {
      padding: '0px'
    },
    '& .masonry-item': {
      padding: '5px',
      background: '#f7f7f7',
      borderRadius: '6px',
      margin: '7px 0px'
    }
  },
  clearIcon: {
    padding: '0px',
    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    }
  },
  recentIcon: {
    padding: '0px',
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    }
  },
  libraryIcon: {
    padding: '0px',
    color: 'black',
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    }
  },
  labelWrapperOuter: {
    marginRight: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'self-start',
    justifyContent: 'center',
    width: '100%'
  },
  labelWrapperCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  labelText: {
    paddingLeft: '5px',
    width: 'calc(100% - 40px)'
  },
  labelTextRecent: {
    paddingLeft: '5px',
    width: 'calc(100% - 74px)'
  },
  searchTypeHightlight: {
    color: '#9f9d9d',
    fontSize: '12px',
    fontStyle: 'italic'
  }
})

export { useStyles }
