import { CLEAR_SEARCH_HISTORY } from '../../../actionTypes'
import { clearUserSearchHistory } from '../../../api'

const clearSearchHistory =
  (data = {}) =>
  async (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_HISTORY,
      payload: { id: data?.id }
    })
    clearUserSearchHistory(data)
  }

export { clearSearchHistory }
