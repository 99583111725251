import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import Switch from '@mui/material/Switch'
import { useStyles } from './styles'
import { Box, Grid, Typography, Collapse, ButtonGroup, Tooltip, IconButton } from '@mui/material'
import Button from '../../components/Button'
import { getbg } from './errorCode'
import TextLineLimiter from '../../components/TextLineLimiter'
import { Container, Section, SectionFixed } from '../../components/Container'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import { useResizeDetector } from 'react-resize-detector'
import _ from 'lodash'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { defaultErrorTypes } from './DefaultErrorTypes'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorCard from './ErrorCard'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'

const ErrorCardDetail = (props) => {
  const { jumpToPage, handleDataChange, errorType, obj, i, shareType = 'private', editingDisabled = 'false' } = props
  const classes = useStyles()
  const {
    dot,
    buttonWrapper,
    errorContainer,
    buttonGroupWrapper,
    replacementWrapper,
    collapseWrapper,
    correctionContainer,
    descWrapper,
    errorWrapper,
    keywordWrapper,
    pageNoWrapper,
    accordianTitle,
    toggleSwitchScroll,
    buttonHighlight1,
    buttonHighlight2,
    contentWrapper,
    groupingErrorCard
  } = classes
  const [expandedId, setExpandedId] = useState(-1)
  const [innerExpandedId, setInnerExpandedId] = useState(-1)
  const [view, setView] = useState('corrections')
  const [reverse, setReverse] = useState(false)
  const { width, height, ref } = useResizeDetector()
  const [statusLoadingResolve, setStatusLoadingResolve] = useState(false)
  const [statusLoadingIgnore, setStatusLoadingIgnore] = useState(false)
  const { selectedColor } = useColor()

  const handleInnerClick = (i) => {
    setInnerExpandedId(innerExpandedId === i ? -1 : i)
    /* setExpandedId(-1) */
  }

  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i)
    setInnerExpandedId(-1)
  }

  const findStatusEmpty = (items) => {
    /* return !items.some(item => item['status'] === 'complete' || item['status'] === 'ignore'); */
    for (let i = 0; i < items.length; i++) {
      if (items[i].status === 'complete' || items[i].status === 'ignore') {
        return false
      }
    }
    return true // this function is given an input of all the errors present, and then if anyone of the error does not have status as empty then it should return false
  }

  const handleDataChangeChild = async (items, status) => {
    switch (status) {
      case 'complete':
        trackEvent(
          mixpanelEvents.ANALYTICS_MARKED_AS_RESOLVED,
          'SUCCESS',
          {},
          {}
        )
        setStatusLoadingResolve(true)
        break
      case 'ignore':
        trackEvent(
          mixpanelEvents.ANALYTICS_MARKED_AS_IGNORED,
          'SUCCESS',
          {},
          {}
        )
        setStatusLoadingIgnore(true)
        break
    }
    await handleDataChange(items, status)
    setStatusLoadingResolve(false)
    setStatusLoadingIgnore(false)
  }

  const dotbg = getbg(errorType)

  const { keyword, items } = obj
  const groupCount = items.length
  const combinedStatus = findStatusEmpty(items)
  return (
    <>
      {
        groupCount > 1
          ? <>
            <Box
              className={errorContainer}
              key={i}
            >
              <Box className={errorWrapper} onClick={() => {
                handleExpandClick(i)
              }}>
                <Box style={{ display: 'flex', alignItems: 'baseline', maxWidth: 'calc(100% - 48px)' }}>
                  <Box className={dot} sx={{ background: dotbg }} />
                  <Box className={keywordWrapper}>
                    <TextLineLimiter content={keyword + ' (' + groupCount + ')'} limit={2} />
                  </Box>
                </Box>
                <Box className={pageNoWrapper}>
                  {combinedStatus && shareType === 'private' &&
                    <>
                      {(statusLoadingResolve || statusLoadingIgnore)
                        ? <CircularProgress size={'1.1rem'} />
                        : <>
                          <Tooltip title="Mark complete">
                            <IconButton disabled={editingDisabled} sx={{ padding: '0px' }}>
                              <CheckCircleIcon style={{ color: selectedColor, verticalAlign: 'sub' }} onClick={() => handleDataChangeChild(items, 'complete')} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Ignore">
                            <IconButton disabled={editingDisabled} sx={{ padding: '0px' }}>
                              <RemoveCircleOutlineIcon style={{ color: selectedColor, verticalAlign: 'sub' }} onClick={() => handleDataChangeChild(items, 'ignore')} />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    </>
                  }
                </Box>
              </Box>
              <Collapse in={expandedId === i} timeout="auto" unmountOnExit>
                {
                  items.map((duplicateResult, i) => {
                    return (
                      <ErrorCard key={i} word={duplicateResult} i={i} showPageNumberOnly={true} handleDataChange={handleDataChange} jumpToPage={jumpToPage} shareType={shareType} editingDisabled={editingDisabled} />
                    )
                  })
                }
              </Collapse>
            </Box>
          </>
          : <ErrorCard word={items[0]} i={i} handleDataChange={handleDataChange} jumpToPage={jumpToPage} shareType={shareType} editingDisabled={editingDisabled} />
      }
    </>
  )
}

export default ErrorCardDetail
