import React from 'react'
import Typography from '../Typography'
import TextField from '@mui/material/TextField'

const TextInput = (props) => {
  const {
    id = 'text-input',
    keyId = '',
    label = '',
    name = '',
    handleChange = () => {},
    onBlurChange = () => {},
    placeholder = '',
    error = false,
    errorMessage = '',
    value = '',
    className = '',
    autoFocus = false,
    multiLine = false,
    multiSelectHeight = 10,
    inputProp = {},
    handleEnterKey = () => {},
    children,
    ...rest
  } = props

  return (
    <div>
      {label && (
        <div
          style={{
            marginBottom: '5px'
          }}
        >
          <Typography title3>
            <span
              style={{
                fontSize: '14px',
                color: '#3f3f46',
                fontWeight: 600,
                letterSpacing: '0.5px'
              }}
            >
              {label}
            </span>

            {error && (
              <span
                style={{
                  color: 'red',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginLeft: '5px'
                }}
              >
                {errorMessage}
              </span>
            )}
          </Typography>
        </div>
      )}
      <div>
        <TextField
          value={value}
          id={id}
          name={name}
          fullWidth
          onKeyDown={(event) => {
            handleEnterKey(event)
          }}
          onChange={(event) => handleChange(event, keyId)}
          onBlur={(event) => onBlurChange(event)}
          autoFocus={autoFocus}
          multiline={multiLine}
          InputProps={inputProp}
          placeholder={placeholder}
          className={className}
          {...rest}
          label=""
        >
          {children}
        </TextField>
      </div>
    </div>
  )
}

export default TextInput
