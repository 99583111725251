import React, { useEffect, useState } from 'react'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { IconButton, Box, Pagination as MUIPagination } from '@mui/material'
import { useStyles } from './styles'

const Pagination = ({
  paginationConfig = {},
  rows,
  rowsPerPage,
  page,
  handleChangePage,
  startIndex = '',
  endIndex = '',
  fullCount = '',
  loading = false
}) => {
  useEffect(() => {
    setCurrentPage(page)
  }, [page])

  const { type = 'default', hasNextPage = false } = paginationConfig
  const pageCount = Math.ceil(rows / rowsPerPage)

  const [currentPage, setCurrentPage] = useState(page)

  const classes = useStyles()

  const { paginationContent, displayCount } = classes

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1)
    handleChangePage(currentPage - 1)
  }

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1)
    handleChangePage(currentPage + 1)
  }

  const handlePageClick = (event, value) => {
    setCurrentPage(value)
    handleChangePage(value - 1)
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px'
        }}
      >
        {type === 'prevnext' ? (
          <>
            <IconButton
              onClick={handlePrevPage}
              disabled={loading || currentPage === 1}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={handleNextPage}
              disabled={loading || !hasNextPage}
            >
              <KeyboardArrowRight />
            </IconButton>
          </>
        ) : (
          <>
            <MUIPagination
              disabled={loading}
              page={currentPage}
              className={paginationContent}
              id="table-paginations"
              shape="rounded"
              onChange={handlePageClick}
              count={pageCount}
              prevIconButtonProps={{
                onClick: handlePrevPage
              }}
              nextIconButtonProps={{
                onClick: handleNextPage,
                disabled: !hasNextPage
              }}
            />
            <span className={displayCount}>
              Showing {startIndex} to {endIndex} of {fullCount} results
            </span>
          </>
        )}
      </Box>
    </>
  )
}

export default Pagination
