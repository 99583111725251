import { FETCH_ANALYTICS } from '../../../actionTypes'
import { getAnalytics } from '../../../api'

const fetchAnalytics = () => async (dispatch) => {
  dispatch({
    type: FETCH_ANALYTICS,
    payload: null
  })
  let analytics = []
  const res = await getAnalytics()
  if (res.status === 200) {
    analytics = JSON.parse(res.data)
    dispatch({
      type: FETCH_ANALYTICS,
      payload: analytics
    })
  }
}

export default fetchAnalytics
