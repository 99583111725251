import * as React from 'react'
import { Highlights } from './Highlights'

export const searchPlugin = (props) => {
  const renderPageLayer = (renderProps) => {
    const { height, width, scale, pageIndex, doc = {} } = renderProps
    const { numPages } = doc || {}
    const orgHeight = Math.round(height / scale)
    const orgWidth = Math.round(width / scale)
    return (
      <Highlights
        key={pageIndex}
        numPages={numPages}
        pageIndex={pageIndex}
        orgHeight={orgHeight}
        orgWidth={orgWidth}
        {...props}
      />
    )
  }

  return {
    renderPageLayer
  }
}
