import { Dialog, DialogContent, Fade } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  CircleLoader,
  EnterIcon,
  SearchIcon
} from '../../components/Icons/Icons'
import SearchNew from '../ListFilter/SearchNew'
import { useSelector } from 'react-redux'
import { ImageAvatarFallback, Loader } from '../../components'
import Button2 from '../../components/Button/Button2'
import { getPeople } from '../../store/api'

const MoveModal = ({
  open,
  setOpen,
  s3Obj,
  onMove,
  onMoveToPeople,
  selectedPerson,
  personName
}) => {
  const peopleList = useSelector((state) => state.people?.peopleList)
  const [moving, setMoving] = useState(false)
  const [people, setPeople] = useState([])
  const [selectedPeople, setSelectedPeople] = useState(null)
  const [search, setSearch] = useState('')
  const [fetchingPeople, setFetchingPeople] = useState(false)
  useEffect(() => {
    setPeople(peopleList)
  }, [peopleList])

  const handleClose = () => {
    setSelectedPeople(null)
    setOpen(false)
  }

  const searchPeople = (search) => {
    setSelectedPeople(null)
    if (search === '') {
      setPeople(peopleList)
    } else {
      const body = {
        keyword: search,
        page_num: 1,
        page_size: 10,
        tags: [],
        tag_dates: []
      }
      setFetchingPeople(true)
      getPeople(body).then((res) => {
        if (res.status === 200) {
          const finalPeople = res.data.people.filter(
            (person) => person.id !== selectedPerson.id
          )

          console.log('finalPeople', finalPeople)

          setPeople(finalPeople)
          setFetchingPeople(false)
        }
      })
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Fade}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div
        className="p-3 border-box border-b-05"
        style={{
          width: '450px'
        }}
      >
        <SearchNew
          value={search}
          onChange={(value) => {
            setSearch(value)
          }}
          loading={fetchingPeople}
          applyDebounce={true}
          onDebounce={(value) => {
            searchPeople(value)
          }}
          onEnter={(value) => {
            //
          }}
        />
      </div>
      <DialogContent
        style={{
          padding: '0',
          minHeight: '350px'
        }}
      >
        {people?.length === 0 && (
          <Button2
            secondary
            onClick={onMoveToPeople}
            style={{
              margin: 'auto',
              marginTop: '30px'
            }}
          >
            Move {personName} as new person
          </Button2>
        )}
        {people?.map((person, index) => {
          const {
            name,
            profile_picture_location,
            resumes = [],
            id,
            status,
            version
          } = person

          if (selectedPerson.id === id) {
            return null
          }
          return (
            <div
              className={`flex justify-between  gap-2 px-4 text-center text-sm border-b-05 p-2 row text-grey-600 ${
                selectedPeople === id ? 'bg-grey-100' : ''
              }`}
              key={index}
              onClick={() => {
                if (selectedPeople === id) {
                  setSelectedPeople(null)
                } else {
                  setSelectedPeople(id)
                }
              }}
            >
              <div className="font-medium w-full flex items-center gap-2 text-capitalize">
                <ImageAvatarFallback
                  name={name}
                  profilePic={profile_picture_location}
                  style={{
                    height: '30px',
                    width: '30px',
                    marginLeft: 2
                  }}
                  openImage={() => {}}
                  s3Obj={s3Obj}
                />
                <span>{name.toLowerCase()}</span>
              </div>

              <div
                className="flex items-center h-full cursor-pointer py-1"
                onClick={(e) => {
                  //   e.stopPropagation()
                  //   handleSetSelectedPeople(id)
                }}
              >
                <input
                  type="checkbox"
                  className={`cursor-pointer checkbox-ui border-grey-200 rounded-md w-3 h-3  ${
                    selectedPeople === id ? 'bg-grey-700' : ''
                  }`}
                  checked={selectedPeople === id}

                  // onChange={(e) => {
                  //   e.stopPropagation()
                  // }}
                />
              </div>
            </div>
          )
        })}
      </DialogContent>
      <div className="flex justify-end gap-2 items-center p-2 border-t-1">
        <Button2
          secondary
          noOutline={true}
          onClick={handleClose}
          style={{
            padding: '8px 16px'
          }}
        >
          Cancel
        </Button2>
        <Button2
          onClick={() => {
            setMoving(true)
            onMove(selectedPeople)
          }}
          disabled={!selectedPeople}
          primary
          style={{
            padding: '8px 16px'
          }}
        >
          {moving ? (
            <CircleLoader className="animate-spin text-white size-4" />
          ) : (
            'Move'
          )}
        </Button2>
      </div>
    </Dialog>
  )
}

export default MoveModal
