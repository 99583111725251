import { useSettings } from '../SettingsProvider'
import React from 'react'

function Radio() {
  const settings = useSettings()
  const handleRadioChange = (q, e) => {
    settings.changeSetting(q, e.target.checked)
  }

  return (
    <>
      <div>
        <input
          type="checkbox"
          id="showCrosshairs"
          onChange={(e) => handleRadioChange('showCrosshairs', e)}
          checked={settings.showCrosshairs}
        />
        <label htmlFor="showCrosshairs">&nbsp;Show Crosshairs</label>
      </div>
      <div>
        <input
          type="checkbox"
          id="showHighlightBox"
          onChange={(e) => handleRadioChange('showHighlightBox', e)}
          checked={settings.showHighlightBox}
        />
        <label htmlFor="showHighlightBox">&nbsp;Show HighlightBox</label>
      </div>
      <div>
        <input
          type="checkbox"
          id="wasdMode"
          onChange={(e) => handleRadioChange('wasdMode', e)}
          checked={settings.wasdMode}
        />
        <label htmlFor="wasdMode">&nbsp;WASD Mode</label>
      </div>
    </>
  )
}

export default Radio
