import React, { useState, useEffect, memo } from 'react'
import JustifiedLayout from '@codekraft-studio/react-justified-layout'
import { Box } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Loader from '../../components/Loader'

const bricks = (array, signedImages, onClickImage) => {
  return array.map((item, index) => {
    const { style = {} } = item
    const { width = 215, height = 145 } = style
    return (
      <Box
        id="image-div"
        sx={{
          ...style,
          background: '#E8F0FE',
          border: '1px solid #E8F0FE',
          '& img': {
            cursor: 'pointer'
          }
        }}
        key={index}
      >
        <LazyLoadImage
          height={height}
          width={width}
          effect="blur"
          onClick={() => onClickImage(index)}
          src={signedImages[index]?.signedSrc}
        />
      </Box>
    )
  })
}

const SimilarityViewer = (props) => {
  const { signedImages = [], aspectRatio, handleImageClick = () => {} } = props
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (signedImages) {
      setLoading(false)
    }
  }, [signedImages])

  const onClickImage = (index) => {
    const image = signedImages[index]
    handleImageClick(image, signedImages)
  }

  return loading ? (
    <Box
      id="image_loading"
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader loading={loading} caption={''} />
    </Box>
  ) : (
    <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
      {signedImages?.length > 0 && aspectRatio.length > 0 && (
        <JustifiedLayout
          items={aspectRatio}
          options={{
            containerPadding: 20,
            boxSpacing: 10,
            targetRowHeight: 150,
            targetRowHeightTolerance: 0.1
          }}
        >
          {(items) => bricks(items, signedImages, onClickImage)}
        </JustifiedLayout>
      )}
    </Box>
  )
}

export default SimilarityViewer
