import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  grid: {
    width: '100%',
    height: '100vh'
  },
  tableContainer: {
    padding: '0px 10px',
    height: '100%',
    overflow: 'auto'
  },
  drawerStyle: {
    width: '50% !impoertant'
  },
  proposalNameDisplay: {
    margin: '7px 0'
  },
  proposalNameWrapper: {
    maxHeight: '150px',
    overflow: 'auto',
    padding: '5px 0'
  }
})

export { useStyles }
