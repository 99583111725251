import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  imageContainer: {
    width: '130px',
    height: '150px',
    padding: '10px'
  },
  copyButton: {
    width: '20px',
    height: '18px'
  },
  favoriteIcon: {
    color: 'red'
  },
  imageWrapper: {
    height: '115px',
    width: '130px',
    display: 'flex',
    justifyContent: 'center',
    background: '#EEEEEE',
    alignItems: 'center',
    '& img': {
      height: '115px',
      width: '130px',
      objectFit: 'contain',
      cursor: 'grab'
    }
  }
})

export { useStyles }
