export const getbg = (key, status) => {
  if (status) {
    return '#14C646'
  } else if (key === 'all') {
    return 'conic-gradient(red, yellow, lime, aqua, blue, magenta, red)'
  } else {
    return hashToLightColor(key)
  }
}

function hashToLightColor(value) {
  const stringValue = String(value)

  const chunkSize = 4
  const chunks = [
    stringValue.substring(0, chunkSize),
    stringValue.substring(chunkSize, chunkSize * 2),
    stringValue.substring(chunkSize * 2)
  ]

  const hexChunks = chunks.map((chunk) => {
    const hash = chunk.split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc)
    }, 0)
    const component = (Math.abs(hash) % 128) + 128 // Limit range to light shades (128-255)
    const hex = component.toString(16).padStart(2, '0')
    return hex
  })

  const hexColor = `#${hexChunks.join('')}`

  return hexColor
}
