import Button from '@mui/material/Button'
import { useStyles } from './styles'
import clsx from 'clsx'
import { Typography } from '@mui/material'
const MUITypography = (props) => {
  const {
    id = 'typography',
    className = {},
    variant = '',
    children,
    component = 'span'
  } = props
  const classes = useStyles()
  const { defaultText } = classes

  return (
    <Typography
      id={id}
      component={component}
      variant={variant}
      className={clsx(defaultText, className)}
      {...props}
    >
      {children}
    </Typography>
  )
}
export default MUITypography
