import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  listitem: {
    width: '600px',
    padding: 0
  },
  listClass: {
    padding: 16,
    maxHeight: '40vh',
    overflow: 'auto',
    maxWidth: '700px',
    background: 'aliceblue'
  },
  formWrapper: {
    width: 500
  },
  inputWrapper: {
    marginBottom: 24,
    width: '100%'
  },
  buttonWrapper: {
    margin: '15px 0px'
  },
  container: {
    paddingLeft: 20,
    paddingTop: 24
  }
})
export { useStyles }
