import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import FilterListIcon from '@mui/icons-material/FilterList'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import AddIcon from '../../assets/images/AddIcon.svg'
import { Box, Icon, IconButton, Tooltip } from '@mui/material'
import { useStyles } from './styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import Search from './search'
import Filter from './filter'
import RefreshIcon from '@mui/icons-material/Refresh'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import eventMapping from '../../config/eventMapping'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import AddFilter from '../AddFilter'
import { isEmpty } from 'lodash'
import BackspaceIcon from '@mui/icons-material/Backspace'
import { Button, Image, useConfirmation } from '../../components'
import { setUploader } from '../../store/Uploader/Actions'
import CreateLibraryMeta from '../../containers/CreateLibraryMeta'
import Modal from '@mui/material/Modal'
import { ROUTES } from '../../config/routes'
import { useNavigate } from 'react-router-dom'
import mixpanelEvents from '../../config/mixpanelEvents'
import SearchIcon from '@mui/icons-material/Search'
// import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup'

const ListFilter = (props) => {
  const {
    filterData,
    handleSearch,
    handleFilter,
    advancedFilter,
    filter,
    refresh = false,
    searchValue,
    keyValueFilter = {},
    filterKeyValue,
    removeKeyValueFilter,
    selectedFilter = {},
    setSelectedFilter,
    dateRange = [null, null],
    setDateRange,
    pageType = '',
    searchOnEnter,
    resetFilter = () => {},
    extraButtons = [],
    handleCreateNewDoc = () => {},
    isDelete = false,
    isEdit = false,
    handleGroupEdit = () => {},
    handleGroupDelete = () => {},
    isEditable = true,
    isUpdateAnnotation = false,
    handleAnnotationChange = () => () => {},
    isFilterActive = true,
    isShowFilter = true,
    openFilter = false,
    filterItems = [],
    afterSearchElement = '',
    disabled = false,
    handleChangeView = () => {},
    currentView = 'others',
    uploadedByGroup = false
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    container,
    iconContainer,
    iconActive,
    iconInActive,
    filterOption,
    filterButton,
    filterDivider,
    refreshContainer,
    refreshIcon,
    keyValueContainer,
    keyValue,
    keyValueTextInput,
    buttonWrapper,
    buttonText,
    addIcon,
    deleteWrapper,
    tabSelected,
    tab
  } = classes

  const [showFilter, setShowFilter] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [showClearFilter, setshowClearFilter] = useState(false)
  const [showLibraryModel, setShowLibraryModel] = useState(false)
  const [addToolTip, setAddToolTip] = useState(false)
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [unSavedChanges, setUnSavedChanges] = useState(false)
  const { NEW_DOCUMENTS } = ROUTES

  useEffect(() => {
    if (filterItems.length > 0) {
      setShowFilter(true)
    }
  }, [filterItems])

  useEffect(() => {
    if (pageType === 'library') {
      setAddToolTip('Create a Knowledge')
    } else if (pageType === 'assets') {
      setAddToolTip('Upload Asset(s)')
    } else if (pageType === 'proposal') {
      setAddToolTip('Upload Proposal(s)')
    } else if (pageType === 'draft') {
      setAddToolTip('Create a draft')
    } else if (pageType === 'analytics') {
      setAddToolTip('Analyze a document')
    } else if (pageType === 'rfx') {
      setAddToolTip('Upload a RFx')
    }
  }, [pageType])

  useEffect(() => {
    if (searchValue) {
      setShowSearch(true)
    }
    if (!isEmpty(selectedFilter) || dateRange[0] || dateRange[1]) {
      setShowFilter(true)
      setshowClearFilter(true)
    } else {
      setshowClearFilter(false)
    }
  }, [searchValue, selectedFilter, dateRange])

  const handleCloseModal = (event, reason) => {
    if (unSavedChanges) {
      showConfirmDialog({
        onConfirm: () => {
          setShowLibraryModel(false)
        },
        confirmationMessageTitle: 'Are you sure you want to leave this page?',
        confirmationMessage: 'All unsaved changes will be lost.'
      })
    } else {
      setShowLibraryModel(false)
    }
  }

  const handleSelect = (val) => {
    const eventType = ''
    if (val === 'filter') {
      if (pageType === 'proposal') {
        trackEvent(mixpanelEvents.PROPOSAL_FILTER_APPLIED, 'SUCCESS', {}, {})
      }
      // else if (pageType === 'draft') {
      //   trackEvent(eventMapping['DRAFT_FILTER'], "SUCCESS");
      // }
      // else if (pageType === 'analytics') {
      //   trackEvent(eventMapping['ANALYTICS_FILTER'], "SUCCESS");
      // }
      // else if (pageType === 'assets') {
      //   trackEvent(eventMapping['ASSETS_FILTER'], "SUCCESS");
      // }
      // else if (pageType === 'rfx') {
      //   trackEvent(eventMapping['RFX_FILTER'], "SUCCESS");
      // }
      setShowFilter(!showFilter)
      resetFilter()
    } else {
      if (showSearch) {
        handleSearch('')
      }
      setShowSearch(!showSearch)
    }
    filter({})
  }

  const handleKeyValueChange = (e, key) => {
    filterKeyValue({ ...keyValueFilter, [key]: e.target.value }, false)
  }

  const clearFilters = () => {
    if (setDateRange) {
      setDateRange([null, null])
    }
    if (setSelectedFilter) {
      setSelectedFilter({})
    }
  }

  const handleNewClick = (id) => {
    if (pageType === 'workspace') {
      handleCreateNewDoc()
    } else if (pageType === 'proposal') {
      trackEvent(mixpanelEvents.PROPOSAL_UPLOAD, 'IN-PROCESS', {}, {})
      dispatch(
        setUploader({
          uploaderType: 'proposal',
          showUploader: true,
          fullScreen: true
        })
      )
    } else if (pageType === 'analytics') {
      trackEvent(mixpanelEvents.ANALYTICS_FILE_UPLOAD, 'IN-PROCESS', {}, {})
      dispatch(
        setUploader({
          uploaderType: 'analytics',
          showUploader: true,
          fullScreen: true
        })
      )
    } else if (pageType === 'library') {
      setShowLibraryModel(true)
    } else if (pageType === 'assets') {
      trackEvent(mixpanelEvents.ASSET_UPLOAD, 'IN-PROCESS', {}, {})
      dispatch(
        setUploader({
          uploaderType: 'asset',
          showUploader: true,
          fullScreen: true
        })
      )
    } else if (pageType === 'rfx') {
      trackEvent(mixpanelEvents.RFX_FILE_UPLOAD, 'IN-PROCESS', {}, {})
      dispatch(
        setUploader({
          uploaderType: 'rfx',
          showUploader: true,
          fullScreen: true
        })
      )
    }
  }

  const renderExtraButtons = (btn) => {
    const { text, condition, onClick, icon = () => <></>, tooltip } = btn
    return (
      condition && (
        <Box
          sx={{
            padding: '2.5px 10px'
          }}
        >
          <Tooltip
            placement="bottom"
            title={tooltip}
            style={{ zIndex: 1, marginLeft: '5px' }}
          >
            <Button className={buttonWrapper} onClick={onClick}>
              {icon}
              <p className={buttonText}>{text}</p>
            </Button>
          </Tooltip>
        </Box>
      )
    )
  }

  return (
    <div className={`${filterDivider}`}>
      <Box id="filter-root-container" className={`${container}`}>
        <Box className={filterOption}>
          {showFilter && setSelectedFilter && (
            <>
              {isFilterActive && (
                <Box>
                  {pageType === 'proposal' ||
                  pageType === 'draft' ||
                  pageType === 'analytics' ||
                  pageType === 'assets' ||
                  pageType === 'rfx' ? (
                    <AddFilter
                      filterItems={filterItems}
                      handleFilter={handleFilter}
                      filterData={advancedFilter}
                    />
                  ) : (
                    <Filter
                      filterData={filterData}
                      filter={filter}
                      selectedFilter={selectedFilter}
                      setSelectedFilter={setSelectedFilter}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                    />
                  )}
                </Box>
              )}
              <Box className={keyValueContainer}>
                {Object.keys(keyValueFilter).map(
                  (key, selectedKeyValueIndex) => (
                    <Box className={keyValue} key={selectedKeyValueIndex}>
                      {key}&nbsp;:&nbsp;
                      <TextField
                        className={keyValueTextInput}
                        onChange={(e) => handleKeyValueChange(e, key)}
                        variant="standard"
                        placeholder="Value"
                        value={keyValueFilter[key]}
                      />
                      <CloseIcon onClick={() => removeKeyValueFilter(key)} />
                    </Box>
                  )
                )}
              </Box>
            </>
          )}
        </Box>

        <Box className={filterButton}>
          {uploadedByGroup && (
            <Box
              sx={{
                padding: '2.5px 10px'
              }}
            >
              <ButtonGroup variant="contained" aria-label="Basic button group">
                <Button
                  className={currentView === 'myFiles' ? tabSelected : tab}
                  onClick={() => handleChangeView('myFiles')}
                >
                  My{' '}
                  {pageType === 'analytics'
                    ? 'Analytics'
                    : pageType === 'rfx'
                    ? 'RFx'
                    : 'Files'}
                </Button>
                <Button
                  className={currentView === 'others' ? tabSelected : tab}
                  onClick={() => handleChangeView('others')}
                >
                  All Files
                </Button>
              </ButtonGroup>
            </Box>
          )}
          {isEditable && isEdit && (
            <Tooltip
              placement="bottom"
              title={'Edit selected'}
              style={{ zIndex: 1 }}
            >
              <Box
                sx={{
                  padding: '2.5px 10px'
                }}
              >
                <Button className={buttonWrapper} onClick={handleGroupEdit}>
                  <p className={buttonText}>Edit</p>
                </Button>
              </Box>
            </Tooltip>
          )}
          {isEditable && isDelete && (
            <Tooltip
              placement="bottom"
              title={'Delete selected'}
              style={{ zIndex: 1 }}
            >
              <Box className={deleteWrapper}>
                <Button className={buttonWrapper} onClick={handleGroupDelete}>
                  <p className={buttonText}>Delete</p>
                </Button>
              </Box>
            </Tooltip>
          )}
          {isUpdateAnnotation && (
            <Tooltip
              placement="bottom"
              title={'Update annotation status'}
              style={{ zIndex: 1 }}
            >
              <Box className={deleteWrapper}>
                <Button
                  className={buttonWrapper}
                  onClick={handleAnnotationChange}
                >
                  <p className={buttonText}>Save Changes</p>
                </Button>
              </Box>
            </Tooltip>
          )}
          {showClearFilter && (
            <Tooltip title={'Clear Filters'}>
              <Box className={iconContainer} onClick={() => clearFilters()}>
                <BackspaceIcon />
              </Box>
            </Tooltip>
          )}
          {isShowFilter && (
            <Tooltip title={showFilter ? 'Clear Filters' : 'Add Filters'}>
              <Box
                className={iconContainer}
                onClick={() => handleSelect('filter')}
              >
                <IconButton
                  disableRipple
                  className={showFilter ? iconActive : iconInActive}
                >
                  {!showFilter ? (
                    <FilterListIcon color="secondary" />
                  ) : (
                    <FilterListOffIcon color="secondary" />
                  )}
                </IconButton>
              </Box>
            </Tooltip>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
            // className={iconContainer}
            id="searchInput"
          >
            <Box onClick={() => handleSelect('search')}>
              <Tooltip title="Search">
                {pageType === 'people' ? (
                  <Button
                    className={classes.searchButtonWrapper}
                    disableElevation
                  >
                    <p className={classes.searchButtonText}>Search</p>
                  </Button>
                ) : (
                  <IconButton
                    disableRipple
                    className={showSearch ? iconActive : iconInActive}
                  >
                    <SearchIcon color="secondary" />
                  </IconButton>
                )}
              </Tooltip>
            </Box>
            {showSearch && (
              <>
                <Search
                  variant="standard"
                  handleInputChange={handleSearch}
                  value={searchValue}
                  id="searchInputs"
                  handleEnterKey={(e) => {
                    if (!disabled) searchOnEnter(e)
                  }}
                />
              </>
            )}
          </Box>
          {afterSearchElement && (
            <Box className={iconContainer}>{afterSearchElement}</Box>
          )}
          {refresh && (
            <Box className={iconContainer} onClick={() => refresh()}>
              <Tooltip title="Refresh">
                <Box className={refreshContainer}>
                  <IconButton
                    disableRipple
                    className={iconInActive + ' ' + refreshIcon}
                  >
                    <RefreshIcon color="secondary" />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
          )}
          {isEditable && (
            <Tooltip
              placement="bottom"
              title={addToolTip}
              style={{ zIndex: 1 }}
            >
              <Box
                sx={{
                  padding: '2px 10px'
                }}
              >
                <Button
                  className={buttonWrapper}
                  onClick={handleNewClick}
                  disabled={disabled}
                  id="knowledge-create-button"
                >
                  <Image src={AddIcon} alt="add icon" className={addIcon} />
                  <p className={buttonText}>New</p>
                </Button>
              </Box>
            </Tooltip>
          )}
          {extraButtons.map((btn) => renderExtraButtons(btn))}
        </Box>
      </Box>
      <Modal
        fullWidth={true}
        sx={{
          padding: '100px'
        }}
        disableEscapeKeyDown
        open={showLibraryModel}
        onClose={() => handleCloseModal(false)}
      >
        <Box
          className="libContainer"
          sx={{
            width: '100%',
            height: '100%',
            background: 'white'
          }}
        >
          <CreateLibraryMeta
            onClose={() => handleCloseModal(false)}
            openEditor={true}
            redirectOnCreate
            setUnSavedChanges={setUnSavedChanges}
          />
          {ConfirmDialog}
        </Box>
      </Modal>
    </div>
  )
}

export default ListFilter
