import { FETCH_STYLE_SETTINGS, TOASTER_ALERT } from '../../../actionTypes'
import { getStyleSettings } from '../../../api'

const fetchStyleSettings = () => async (dispatch) => {
  try {
    const res = await getStyleSettings()
    if (res.status === 200) {
      dispatch({
        type: FETCH_STYLE_SETTINGS,
        payload: res.data
      })
    } else {
      dispatch({
        type: TOASTER_ALERT,
        payload: {
          message: 'Failed to fetch style guide settings',
          show: true,
          type: 'error'
        }
      })
    }
  } catch (e) {
    dispatch({
      type: TOASTER_ALERT,
      payload: {
        message: 'Failed to fetch style guide settings',
        show: true,
        type: 'error'
      }
    })
  }
}
export default fetchStyleSettings
