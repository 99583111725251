import { SET_SIDEBAR_ACTIVE } from '../../../actionTypes'

const setSidebarActive = (isActive) => {
  return {
    type: SET_SIDEBAR_ACTIVE,
    payload: isActive
  }
}

export { setSidebarActive }
