import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Select, { components as selectDefaultComponents } from 'react-select'
import { colors } from './theme'
import CheckboxOption from './CheckboxOption'
import Chevron from './Chevron'
import Dropdown from './Dropdown'
import DropdownButton, { defaultDropdownButtonStyle } from './DropdownButton'
import DropdownIndicator from './DropdownIndicator'
import CreatableSelect from 'react-select/creatable'
import { FixedSizeList as List } from 'react-window'
import MenuList from './MenuList'

const countOptions = (opts) => {
  if (!opts || !Array.isArray(opts)) return 0
  return opts.reduce(
    (acc, o) => acc + (o.options ? countOptions(o.options) : 1),
    0
  )
}

const augmentOptionsWithGroupLabel = (opts) => {
  return opts.map((o) => {
    if (!o.options) {
      return o
    }
    return null
  })
}

const defaultStyles = {
  control: (provided) => ({ ...provided, margin: 8 }),
  menu: () => ({
    boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF'
  }),
  groupHeading: (def, opts) => {
    const provided = {
      ...def,
      marginBottom: 0,
      padding: '8px 12px 4px',
      fontSize: '110%',
      // textTransform: undefined,
      display: 'flex',
      alignItems: 'center'
    }
    if (opts.checked) {
      return {
        ...provided,
        backgroundColor: colors.primary50,
        color: colors.neutral80
      }
    }
    if (opts.indeterminate) {
      return { ...provided, backgroundColor: colors.hover }
    }
    return {
      ...provided,
      ':hover': {
        backgroundColor: colors.hover
      }
    }
  },
  group: (provided) => ({ ...provided, padding: 0 }),
  dropdownButton: (baseProvided, opts) => {
    const provided = { ...baseProvided }
    ;['width', 'maxWidth', 'minWidth'].forEach((widthProp) => {
      if (opts[widthProp]) {
        provided[widthProp] = opts[widthProp]
      }
    })
    if (opts.isOpen) {
      return { ...provided, background: colors.neutral3 }
    }
    return provided
  },
  option: (provided, opts) => {
    if (opts.isSelected) {
      return {
        ...provided,
        color: '#000',
        backgroundColor: colors.neutral10,
        alignItems: 'center',
        display: 'flex'
      }
    }
    return {
      ...provided,
      backgroundColor: 'transparent',
      ':hover': { backgroundColor: colors.hover },
      alignItems: 'center',
      display: 'flex'
    }
  }
}

const defaultComponents = {
  // these three components pertain to react-multiselect-checkboxes
  MenuList,
  Dropdown,
  DropdownButton,
  DropdownButtonIcon: Chevron,
  // these are react-select components, with sane defaults for react-multiselect-checkboxes
  DropdownIndicator,
  IndicatorSeparator: null,
  Option: CheckboxOption,
  SelectContainer: ({ children, ...props }) => {
    const { innerProps } = props
    const { onClick = () => {} } = innerProps || {}
    const propsInner = {
      ...innerProps,
      onClick: (e) => {
        e.stopPropagation()
        onClick(e)
      }
    }
    props.innerProps = propsInner
    return (
      <selectDefaultComponents.SelectContainer {...props}>
        {children}
      </selectDefaultComponents.SelectContainer>
    )
  },
  Control: ({ children, ...props }) => {
    const { innerProps } = props
    const { onClick = () => {} } = innerProps || {}
    const propsInner = {
      ...innerProps,
      onClick: (e) => {
        e.stopPropagation()
        onClick(e)
      }
    }
    props.innerProps = propsInner
    return (
      <selectDefaultComponents.Control {...props}>
        {children}
      </selectDefaultComponents.Control>
    )
  }
}

const valueShape = PropTypes.shape({
  value: PropTypes.any,
  label: PropTypes.string,
  options: PropTypes.array
})
export default class ReactMultiselectCheckboxes extends Component {
  static propTypes = {
    components: PropTypes.shape({
      Dropdown: PropTypes.func,
      DropdownButton: PropTypes.func,
      DropdownButtonIcon: PropTypes.func
    }),
    options: PropTypes.arrayOf(valueShape).isRequired,
    styles: PropTypes.objectOf(PropTypes.func),
    placeholderButtonLabel: PropTypes.string,
    getDropdownButtonLabel: PropTypes.func,
    onChange: PropTypes.func,
    menuIsOpen: PropTypes.bool,
    rightaligned: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([valueShape, PropTypes.arrayOf(valueShape)]),
    hideSearch: PropTypes.bool,
    minItemsForSearch: PropTypes.number,
    resetInputOnSelect: PropTypes.bool,
    onInputChange: PropTypes.func
  }

  static defaultProps = {
    menuIsOpen: undefined,
    components: {},
    styles: {},
    placeholderButtonLabel: 'Select...',
    onChange() {},
    getDropdownButtonLabel({ placeholderButtonLabel, value }) {
      if (!value) {
        return placeholderButtonLabel
      }
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return placeholderButtonLabel
        }
        return `${placeholderButtonLabel}: ${value.length}`
      }
      return value.label
    },
    rightaligned: false,
    width: null,
    minWidth: null,
    maxWidth: null,
    value: null,
    hideSearch: false,
    minItemsForSearch: 0,
    resetInputOnSelect: false,
    onInputChange() {}
  }

  state = {
    isOpen: false,
    value: undefined,
    inputValue: '',
    prevValue: undefined
  }

  onSelectChange = (value, ...rest) => {
    // this.toggleOpen();
    this.setState({ value })
    this.props.onChange(value, ...rest)
  }

  onInputChange = (inputValue, event, ...restArgs) => {
    if (this.props.onInputChange) {
      this.props.onInputChange(inputValue, event, ...restArgs)
    }
    switch (event.action) {
      case 'input-change':
        this.setState({ inputValue })
        break
      case 'menu-close':
        this.setState({ inputValue: '' })
        break
      default:
        break
    }
  }

  toggleOpen = (e) => {
    e.stopPropagation()
    const value = this.state.value || this.props.value
    this.setState({ prevValue: value, value })
    this.setState((state) => ({ isOpen: !state.isOpen }))
  }

  clearInputValue = () => {
    this.setState({ inputValue: '' })
  }

  calcStyles() {
    // This is messy, but conceptually simple. We're just replacing react-select's defaults
    // with the defaults from defaultStyles for user-provided style functions.
    const propsStyles = { ...this.props.styles }
    Object.entries(defaultStyles).forEach(([k, defaultFunc]) => {
      if (propsStyles[k]) {
        const passedInStyleFunc = propsStyles[k]
        propsStyles[k] = (provided, selectState) =>
          passedInStyleFunc(defaultFunc(provided, selectState), selectState)
      } else {
        propsStyles[k] = defaultFunc
      }
    })
    return propsStyles
  }

  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur()
    }
    if (this.props.onBlurStateChange) {
      const currentState = _.compact(
        _.isArray(this.state.value) ? this.state.value : [this.state.value]
      )
      const initalState = _.compact(
        _.isArray(this.state.prevValue)
          ? this.state.prevValue
          : [this.state.prevValue]
      )
      if (!_.isEqual(currentState, initalState)) {
        this.props.onBlurStateChange({
          prevValue: this.state.prevValue,
          value: this.state.value
        })
      }
    }
  }

  render() {
    const {
      getDropdownButtonLabel,
      placeholderButtonLabel,
      components: propsComponents,
      styles: propsStyles,
      menuIsOpen,
      rightaligned,
      onChange, // Don't want to spread this into the select!
      width,
      minWidth,
      maxWidth,
      value: propsValue,
      hideSearch,
      minItemsForSearch,
      options: propsOptions,
      resetInputOnSelect,
      onInputChange,
      disableMenu = false,
      isDisabled = false,
      isCreatable = false,
      isSortOptions = false,
      sortOrder = 'asc',
      isMulti = true,
      isClearable = true,
      selectRef = null,
      ...rest
    } = this.props
    // Values can be duplicated between groups; how to disambiguate? Need to augment grouped options
    // with the group label.
    const options = isSortOptions
      ? _.orderBy(propsOptions, ['label'], [sortOrder])
      : propsOptions
    const components = { ...defaultComponents, ...propsComponents }
    if (hideSearch || countOptions(options) < minItemsForSearch) {
      components.Control = () => null
    }
    const styles = this.calcStyles()
    const isOpen =
      typeof menuIsOpen === 'boolean' ? menuIsOpen : this.state.isOpen
    const value = propsValue || this.state.value
    const inputValueIfDefined = resetInputOnSelect
      ? {}
      : { inputValue: this.state.inputValue }
    return disableMenu ? (
      <Select
        id="some-div-check"
        autoFocus
        closeMenuOnSelect={false}
        backspaceRemovesValue={false}
        components={components}
        hideSelectedOptions={false}
        menuIsOpen
        onChange={this.onSelectChange}
        placeholder="Search"
        tabSelectsValue={false}
        value={value}
        options={options}
        onInputChange={this.onInputChange}
        inputValue={this.state.inputValue}
        autosize={true}
        styles={styles}
        onBlur={this.onBlur}
        isMulti={isMulti}
        isClearable={isClearable}
        ref={selectRef}
        {...rest}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...colors
          }
        })}
      />
    ) : (
      <components.Dropdown
        isOpen={isOpen}
        rightaligned={rightaligned}
        onClose={this.toggleOpen}
        target={
          <components.DropdownButton
            id="root-div"
            iconAfter={<components.DropdownButtonIcon open={isOpen} />}
            onPress={this.toggleOpen}
            isSelected={isOpen}
            isDisabled={isDisabled}
            style={styles.dropdownButton(defaultDropdownButtonStyle, {
              value,
              isOpen,
              width,
              minWidth,
              maxWidth
            })}
          >
            {getDropdownButtonLabel({ placeholderButtonLabel, value })}
          </components.DropdownButton>
        }
      >
        <div
          id="multiCheckboxMenuWrapper"
          style={{
            background: '#FFF',
            border: 'solid 1px #E5E5E5'
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {isCreatable ? (
            isMulti ? (
              <CreatableSelect
                id="some-div-check"
                autoFocus
                closeMenuOnSelect={false}
                backspaceRemovesValue={false}
                components={components}
                hideSelectedOptions={false}
                menuIsOpen
                onClick={(e) => e.stopPropagation()}
                onChange={this.onSelectChange}
                placeholder="Search"
                tabSelectsValue={false}
                value={value}
                options={options}
                onInputChange={this.onInputChange}
                autosize={true}
                styles={styles}
                onBlur={this.onBlur}
                isMulti={isMulti}
                isClearable={isClearable}
                ref={selectRef}
                {...rest}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...colors
                  }
                })}
              />
            ) : (
              <CreatableSelect
                id="some-div-check"
                autoFocus
                closeMenuOnSelect={false}
                backspaceRemovesValue={false}
                components={components}
                hideSelectedOptions={false}
                menuIsOpen
                onClick={(e) => e.stopPropagation()}
                onChange={this.onSelectChange}
                placeholder="Search"
                tabSelectsValue={false}
                value={value}
                options={options}
                onInputChange={this.onInputChange}
                inputValue={this.state.inputValue}
                autosize={true}
                styles={styles}
                onBlur={this.onBlur}
                isMulti={isMulti}
                isClearable={isClearable}
                ref={selectRef}
                {...rest}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...colors
                  }
                })}
              />
            )
          ) : (
            <Select
              id="some-div-check"
              autoFocus
              closeMenuOnSelect={false}
              backspaceRemovesValue={false}
              components={components}
              hideSelectedOptions={false}
              menuIsOpen
              onClick={(e) => e.stopPropagation()}
              onChange={this.onSelectChange}
              placeholder="Search"
              tabSelectsValue={false}
              value={value}
              options={options}
              onInputChange={this.onInputChange}
              inputValue={this.state.inputValue}
              autosize={true}
              styles={styles}
              onBlur={this.onBlur}
              isMulti={isMulti}
              isClearable={isClearable}
              ref={selectRef}
              {...rest}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...colors
                }
              })}
            />
          )}
        </div>
      </components.Dropdown>
    )
  }
}
