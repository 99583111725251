import {
  SET_LOADING,
  TOASTER_ALERT,
  FETCH_PROMPTS,
  SET_SIDEBAR_ACTIVE
} from '../../actionTypes'

const initialState = {
  sidebarActive: true,
  toaster: {},
  prompts: null
}

function commonReducers(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload
      }
    case SET_SIDEBAR_ACTIVE:
      return {
        ...state,
        sidebarActive: payload
      }
    case TOASTER_ALERT:
      return {
        ...state,
        toaster: payload
      }
    case FETCH_PROMPTS:
      return {
        ...state,
        prompts: payload
      }
    default:
      return state
  }
}

export default commonReducers
