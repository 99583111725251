import { Container, Section, SectionFixed } from '../../components/Container'
import { useStyles } from './styles'
import { Box, IconButton, Tooltip } from '@mui/material'
import { ReactComponent as Toogle_Pane } from '../../assets/images/Chat_Session.svg'
import _ from 'lodash'
import Table from '../ResourceTable'
import { CloseSidebar, OpenSidebar } from '../../components/Icons/Icons'

const ResourceMainPane = (props) => {
  const {
    toggleListPane = () => {},
    listWidth = 0,
    showTogglePane = false,
    s3Obj = {},
    handleDragEnd = () => {},
    rows = null,
    setRowsState = () => {},
    collectionsRows = null,
    setCollectionsRows = () => {}
  } = props

  const classes = useStyles()

  return (
    <Container>
      {showTogglePane && (
        <SectionFixed>
          <Box className={classes.toggleButtonContainer}>
            <Tooltip title="Open Sidepane">
              <IconButton onClick={() => toggleListPane()}>
                <OpenSidebar height="18" width="18" />
              </IconButton>
            </Tooltip>
          </Box>
        </SectionFixed>
      )}
      <Section overFlow>
        <Table
          listWidth={listWidth}
          s3Obj={s3Obj}
          handleDragEnd={handleDragEnd}
          rows={rows}
          setRowsState={setRowsState}
          collectionsRows={collectionsRows}
          setCollectionsRows={setCollectionsRows}
        />
      </Section>
    </Container>
  )
}

export default ResourceMainPane
