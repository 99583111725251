import React, { forwardRef } from 'react'
import { useStyles } from './styles'
import clsx from 'clsx'
import classNames from 'classnames'

const Button2 = forwardRef((props, ref) => {
  const classes = useStyles()

  if (props.secondary) {
    return (
      <button
        className={clsx(
          props.className,
          !props.noOutline
            ? 'button-base flex items-center gap-1 bg-grey-50 rounded-lg border-1 border-grey-300 text-grey-800 px-3 text-xs p-1-5 font-medium'
            : 'button-base flex items-center gap-1 bg-grey-50 rounded-lg text-grey-800 px-3 text-xs p-1-5 font-medium',

          classes.secondaryHover
        )}
        {...props}
      >
        {props.children}
      </button>
    )
  }

  if (props.disabled) {
    return (
      <button
        className={clsx(
          'button-base flex items-center gap-1 bg-grey-400 rounded-lg border-1 border-grey-700 text-grey-800 px-3 text-xs p-1-5 text-white pointer-events-none font-medium'
        )}
        {...props}
      >
        {props.children}
      </button>
    )
  }

  if (props.primary) {
    return (
      <button
        className={clsx(
          props.className,
          'button-base flex items-center gap-1 bg-grey-800 rounded-lg border-1 border-grey-700 text-grey-800 px-3 text-xs p-1-5 text-white font-medium',
          classes.secondaryHover
        )}
        {...props}
      >
        {props.children}
      </button>
    )
  }

  return (
    <button
      ref={ref}
      {...props}
      className={clsx(
        props.className,
        classes.button2,
        props.isActive && classes.button2Active,
        props.icon && classes.iconOnly
      )}
    >
      {props.children}
    </button>
  )
})

export default Button2
