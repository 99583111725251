import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const ToolTipButton = (props) => {
  const { children, title = '', onBreak = <></> } = props
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'))
  return matches ? <Tooltip title={title}>{onBreak}</Tooltip> : children
}

export default ToolTipButton
