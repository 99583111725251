import { makeStyles } from '@mui/styles'

import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor() // Get the selected color from the context

  return {
    paginationContent: {
      '& > ul button': {
        fontWeight: 'bold'
      },
      '& > ul .Mui-selected': {
        background: selectedColor,
        color: 'white',
        '&:hover': {
          background: selectedColor
        }
      }
    },
    displayCount: {
      color: '#949494',
      fontSize: '14px'
    }
  }
})

export { useStyles }
