import React, { useEffect, useState } from 'react'
import {
  Button,
  useConfirmation,
  Loader,
  TextInput,
  MultiSelectCheckBox
} from '../../components'
import { toast } from 'react-toastify'
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  TextField
} from '@mui/material'
import key_type from 'lodash'
import { useStyles } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import {
  getChatDomainPrompts,
  postChatDomainPrompts,
  putUserDomain
} from '../../store/api'
import { Container, Section, SectionFixed } from '../../components/Container'
import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import ContentEditable from 'react-contenteditable'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { htmlToText, textToHtml } from '../../utils/CopyHTML'
import TextLineLimiter from '../../components/TextLineLimiter'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import {
  DOMAIN_CONFIG_UPDATE,
  REVERT_FAVPROMPT_DOMAIN_CONFIG
} from '../../store/actionTypes'

const SortLibrary = (props) => {
  const { filterBy = [], setFilterBy = () => {}, disabled = false } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!disabled) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const filterOptions = [
    {
      key: 'title',
      order: 'asc',
      label: 'Name (A-Z)'
    },
    {
      key: 'title',
      order: 'desc',
      label: 'Name (Z-A)'
    },
    {
      key: 'created_at',
      order: 'desc',
      label: 'Created Date (Newest)'
    },
    {
      key: 'created_at',
      order: 'asc',
      label: 'Created Date (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'asc',
      label: 'Last Modified (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'desc',
      label: 'Last Modified (Newest)'
    }
  ]

  const handleFilter = (e, filter) => {
    e.stopPropagation()
    e.preventDefault()
    setFilterBy(filter)
    handleClose()
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <IconButton
        className={classes.iconButton}
        disableRipple
        onClick={handleClick}
        disabled={disabled}
      >
        <SwapVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.hideMenu}
      >
        {filterOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={
              filterBy.key === option.key && filterBy.order === option.order
            }
            onClick={(e) =>
              handleFilter(e, { key: option.key, order: option.order })
            }
          >
            <Box className={classes.hideMenuListText}>{option.label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

const ChatDomainSettings = ({ isFocused }) => {
  const [loading, setLoading] = useState(true)
  const [prompts, setPrompts] = useState(null)
  const [filteredPrompts, setFilteredPrompts] = useState([])
  const [showModel, setShowModel] = useState(false)
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { user_name = '', domain_id, domain_config } = domain || {}
  const dispatch = useDispatch()

  const classes = useStyles()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [tagOptions, setTagOptions] = useState({})
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState({
    key: 'created_at',
    order: 'desc'
  })
  const [promptFav, setPromptFav] = useState({})
  const domainPromptsFav = promptFav?.domain_prompts || []

  useEffect(() => {
    const promptFav = domain_config?.prompt_fav || {}
    setPromptFav(promptFav)
  }, [domain_config])

  useEffect(() => {
    const fetchData = async () => {
      if (prompts === null && isFocused) {
        const res = await getChatDomainPrompts()
        if (res.status === 200) {
          const { domain_prompts = [] } = res.data
          const tags = {}
          domain_prompts.forEach((item) => {
            Object.keys(item.tags || {}).forEach((tag) => {
              if (!tags[tag]) {
                tags[tag] = []
              }
              tags[tag] = [...new Set([...tags[tag], ...item.tags[tag]])]
            })
          })
          setTagOptions(tags)
          setPrompts(domain_prompts)
          setLoading(false)
        } else {
          toast.error('Unable to fetch shared prompts')
        }
      }
    }
    fetchData()
  }, [isFocused])

  const sortData = (data) => {
    return data.sort((a, b) => {
      const key = sort.key
      let order = sort.order
      let comparisonA = a[key]
      let comparisonB = b[key]
      if (key === 'created_at' || key === 'updated_at') {
        comparisonA = new Date(a[key])
        comparisonB = new Date(b[key])
        if (comparisonA.getTime() === comparisonB.getTime()) {
          comparisonA = a.title?.toLowerCase()
          comparisonB = b.title?.toLowerCase()
          order = 'asc'
        }
      } else if (key === 'title') {
        comparisonA = a[key]?.toLowerCase()
        comparisonB = b[key]?.toLowerCase()
      }
      if (comparisonA < comparisonB) {
        return order === 'desc' ? 1 : -1
      }
      if (comparisonA > comparisonB) {
        return order === 'desc' ? -1 : 1
      }
      return 0
    })
  }

  useEffect(() => {
    if (!prompts) {
      return
    }
    let filteredPrompts = [...prompts]
    filteredPrompts = filteredPrompts.filter((item) => {
      if (search === '') {
        return true
      }
      return (
        item.title.toLowerCase().includes(search.toLowerCase()) ||
        item.prompt.toLowerCase().includes(search.toLowerCase())
      )
    })

    filteredPrompts = sortData(filteredPrompts)
    setFilteredPrompts(filteredPrompts)
  }, [search, sort, prompts])

  const selectStyle = {
    indicatorsContainer: (base) => ({
      ...base,
      color: '#0645AD'
    }),
    control: (base) => ({
      ...base,
      minHeight: '0',
      margin: '5px'
    }),
    input: (base) => ({
      ...base,
      fontSize: '12px',
      padding: '0px'
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px 8px'
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '12px'
    }),
    noOptionsMessage: (base) => ({
      ...base,
      fontSize: '12px',
      color: '#0645AD'
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '12px'
    }),
    multiValue: (base) => ({
      ...base,
      fontSize: '12px'
    }),
    loadingMessage: (base) => ({
      ...base,
      fontSize: '12px'
    }),
    menuPortal: (base) => ({ ...base, zIndex: 99999 })
  }

  const handleSave = async () => {
    if (!showModel?.title) {
      toast.error('Please enter a name for your voice')
      return
    }
    if (!showModel?.prompt) {
      toast.error('Please enter a prompt')
      return
    }
    setShowModel(false)
    const mode = showModel?.id ? 'update' : 'create'
    let req = {
      ...showModel,
      mode,
      id: showModel?.id || uuidv4(),
      prompt: htmlToText(showModel?.prompt)
    }
    let oldData = req
    if (mode === 'update') {
      oldData = prompts.find((item) => item.id === req.id)
      setPrompts((prevPrompts) =>
        prevPrompts.map((item) =>
          item.id === req.id ? { ...req, loading: true } : item
        )
      )
    } else {
      req = {
        ...req,
        updated_at: moment.utc(),
        created_by_user: user_name
      }
      setPrompts((prevPrompts) => [
        ...prevPrompts,
        {
          ...req,
          loading: true
        }
      ])
    }
    const res = await postChatDomainPrompts(req)
    if (res.status === 200) {
      if (mode === 'update') {
        setPrompts((prevPrompts) =>
          prevPrompts.map((item) =>
            item.id === req.id
              ? { ...item, updated_by_user: user_name, loading: false }
              : item
          )
        )
      } else {
        setPrompts((prevPrompts) =>
          prevPrompts.map((item) =>
            item.id === req.id ? { ...item, loading: false } : item
          )
        )
      }
    } else {
      toast.error('Something went wrong')
      if (mode === 'update') {
        setPrompts((prevPrompts) =>
          prevPrompts.map((item) =>
            item.id === req.id ? { ...oldData, loading: false } : item
          )
        )
      } else {
        setPrompts((prevPrompts) =>
          prevPrompts.filter((item) => item.id !== req.id)
        )
      }
    }
  }

  const handleDelete = async (item) => {
    showConfirmDialog({
      onConfirm: async () => {
        const req = {
          id: item.id,
          mode: 'delete'
        }
        setPrompts((prevPrompts) =>
          prevPrompts.map((item) =>
            item.id === req.id ? { ...item, loading: true } : item
          )
        )
        const res = await postChatDomainPrompts(req)
        if (res.status === 200) {
          setPrompts((prevPrompts) =>
            prevPrompts.filter((item) => item.id !== req.id)
          )
        } else {
          setPrompts((prevPrompts) =>
            prevPrompts.map((item) =>
              item.id === req.id ? { ...item, loading: false } : item
            )
          )
          toast.error('Unable to delete voice config')
        }
      },
      confirmationMessageTitle: `Are you sure you want to delete voice config "${item.title}" ?`
    })
  }

  const convertTolabel = (arr) => {
    arr = arr.filter((item) => !item.includes('new_key'))
    return arr.map((item) => {
      return {
        label: item,
        value: item
      }
    })
  }

  function changeKey(o, oldKey, newKey) {
    delete Object.assign(o, { [newKey]: o[oldKey] })[oldKey]
    return o
  }

  const convertFromBraces = (text) => {
    if (!text) {
      return ''
    }
    const regex = /{([^}]+)}/g
    let position = 0
    const newText = text.replace(regex, (match, p1) => {
      const result = `<span class="chat-variable-span" id="chat-variable-${position}">${p1}</span>&nbsp;`
      position++
      return result
    })
    return newText
  }

  const convertToBraces = (html) => {
    const regex = /<span class="chat-variable-span"[^>]*>(.*?)<\/span>&nbsp;/g
    let newText = html.replace(regex, (match, p1) => `{${p1}}`)
    newText = newText === '<br>' ? '' : newText
    return newText
  }

  const handleFav = async (id) => {
    setPrompts((prevPrompts) =>
      prevPrompts.map((item) =>
        item.id === id ? { ...item, loading: true } : item
      )
    )
    const fav = domainPromptsFav.includes(id)
      ? domainPromptsFav.filter((item) => item !== id)
      : [...domainPromptsFav, id]
    const req = {
      domain_id,
      domain_config: {
        ...domain_config,
        prompt_fav: {
          ...promptFav,
          domain_prompts: fav
        }
      }
    }
    dispatch({
      type: DOMAIN_CONFIG_UPDATE,
      payload: { domain_config: req.domain_config }
    })
    const res = await putUserDomain(req)
    if (res.status !== 200) {
      toast.error('Unable to update favourites')
      dispatch({
        type: REVERT_FAVPROMPT_DOMAIN_CONFIG,
        payload: { id, key: 'domain_prompts' }
      })
    }
    setPrompts((prevPrompts) =>
      prevPrompts.map((item) =>
        item.id === id ? { ...item, loading: false } : item
      )
    )
  }

  return (
    <Container>
      {loading ? (
        <Loader loading={loading} flex />
      ) : (
        <Box className={classes.childContainer}>
          <Container>
            <SectionFixed>
              <Box
                className={clsx(classes.titleText, classes.gradientTextStyle)}
              >
                Shared Prompts
              </Box>
              <Box className={classes.subText}>
                Create and share frequently-used prompts with your team to use
                in chat.
              </Box>
              {_.isEmpty(prompts) ? (
                <Box className={classes.headerWrapper}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowModel({})
                    }}
                  >
                    Create your Prompt
                  </Button>
                </Box>
              ) : (
                <>
                  <Box className={classes.headerWrapper}>
                    <Box>
                      <Box className={classes.promptSearchWrapper}>
                        <TextField
                          placeholder="Search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <SortLibrary filterBy={sort} setFilterBy={setSort} />
                      </Box>
                    </Box>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowModel({})
                      }}
                    >
                      Create new
                    </Button>
                  </Box>
                  <Divider />
                </>
              )}
            </SectionFixed>
            <Section overFlow>
              <Box className={classes.promptContainer}>
                {!_.isEmpty(prompts)
                  ? filteredPrompts.map((item, index) => {
                      const {
                        loading = false,
                        title,
                        updated_at,
                        updated_by_user,
                        description = '',
                        prompt = '',
                        tags
                      } = item
                      return (
                        <Box key={'prompts_' + index}>
                          <Box
                            className={
                              loading
                                ? clsx(
                                    classes.voiceWrapper,
                                    classes.disabledOpacity
                                  )
                                : classes.voiceWrapper
                            }
                          >
                            <Box className={classes.flexCol}>
                              <Box className={classes.voiceTitle}>{title}</Box>
                              {description && (
                                <Box className={classes.description}>
                                  {description}
                                </Box>
                              )}
                              <Box className={classes.prompt}>
                                <TextLineLimiter
                                  content={
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: textToHtml(prompt)
                                      }}
                                    />
                                  }
                                  limit={5}
                                  wordBreak={'break-word'}
                                />
                              </Box>
                              <Box className={classes.voiceTags}>
                                {Object.keys(tags || {}).map((tag, index) => {
                                  return (
                                    <Box
                                      key={index}
                                      className={classes.tagWrapper}
                                    >
                                      <Box>{tag}:</Box>
                                      <Box>{tags[tag].join(', ')}</Box>
                                    </Box>
                                  )
                                })}
                              </Box>
                              <Box className={classes.voiceUpdated}>
                                Last updated {moment(updated_at).format('ll')}{' '}
                                {updated_by_user && <>by {updated_by_user}</>}
                              </Box>
                            </Box>
                            <Box
                              className={clsx(
                                classes.flexStart,
                                classes.iconContainer
                              )}
                            >
                              <Tooltip
                                title={
                                  domainPromptsFav.includes(item.id)
                                    ? 'Remove from favourites'
                                    : 'Add to favourites'
                                }
                              >
                                <IconButton
                                  disableRipple
                                  onClick={() => handleFav(item.id)}
                                >
                                  {domainPromptsFav.includes(item.id) ? (
                                    <StarIcon />
                                  ) : (
                                    <StarBorderIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit">
                                <IconButton
                                  disabled={loading}
                                  disableRipple
                                  onClick={() => {
                                    setShowModel({
                                      ...item,
                                      prompt: textToHtml(item.prompt)
                                    })
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  disabled={loading}
                                  disableRipple
                                  onClick={() => {
                                    handleDelete(item)
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Divider />
                        </Box>
                      )
                    })
                  : !_.isEmpty(prompts) && (
                      <Box className={classes.noData}>No prompts found</Box>
                    )}
              </Box>
            </Section>
          </Container>
          <Dialog
            fullScreen
            sx={{ margin: '5vh' }}
            open={!!showModel}
            onClose={() => setShowModel(false)}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }
            }}
          >
            <Box className={classes.dialogWrapper}>
              <Container>
                <SectionFixed>
                  <Box className={classes.dialogTitleWrapper}>
                    <Box>
                      <Box
                        className={clsx(
                          classes.titleText,
                          classes.gradientTextStyle
                        )}
                      >
                        SHARED PROMPT
                      </Box>
                    </Box>
                    <Box className={classes.dialogButtonWrapper}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setShowModel(false)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleSave()
                        }}
                      >
                        {showModel?.id ? 'Update' : 'Create'} Prompt
                      </Button>
                    </Box>
                  </Box>
                  <Divider />
                </SectionFixed>
                <Section overFlow>
                  <Box className={classes.dailogContent}>
                    <Box className={classes.dailogContentSubTitle}>Title</Box>
                    <TextInput
                      className={classes.textFeild}
                      placeholder="Prompt Title"
                      value={showModel.title || ''}
                      onChange={(e) => {
                        setShowModel({
                          ...showModel,
                          title: e.target.value
                        })
                      }}
                    />
                    <Box className={classes.dailogContentSubTitle}>
                      Description
                    </Box>
                    <TextInput
                      multiline
                      minRows={3}
                      maxRows={5}
                      className={classes.textFeild}
                      placeholder="Description"
                      value={showModel.description || ''}
                      onChange={(e) => {
                        setShowModel({
                          ...showModel,
                          description: e.target.value
                        })
                      }}
                    />
                    <Box className={classes.dailogContentSubTitle}>Prompt</Box>
                    <ContentEditable
                      html={convertFromBraces(showModel.prompt) || ''}
                      placeholder={'Prompt'}
                      onChange={(e) => {
                        const html = e.currentTarget.innerHTML
                        const newText = convertToBraces(html)
                        setShowModel({
                          ...showModel,
                          prompt: newText
                        })
                      }}
                      style={{
                        padding: '16.5px 14px',
                        overflow: 'auto',
                        border: '1px solid #e5e5e5',
                        borderRadius: '5px',
                        height: '150px'
                      }}
                    />
                    <Box className={classes.textFeildSubText}>
                      Add a variable by wrapping it in curly brackets. Eg:{' '}
                      {'{name}'}
                    </Box>
                    <Box className={classes.dailogContentSubTitle}>
                      Tags
                      <IconButton
                        disableRipple
                        onClick={() => {
                          const key =
                            'new_key_' +
                            Object.keys(showModel?.tags || {}).length
                          setShowModel({
                            ...showModel,
                            tags: {
                              ...showModel.tags,
                              [key]: []
                            }
                          })
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                    {Object.keys(showModel?.tags || {}).map((item, index) => {
                      const options = Object.keys(tagOptions).filter(
                        (tag) => !Object.keys(showModel.tags).includes(tag)
                      )

                      return (
                        <Box
                          key={index}
                          className={classes.multiSelectCheckBox}
                        >
                          <Box sx={{ width: '50%' }}>
                            <MultiSelectCheckBox
                              options={convertTolabel(options || [])}
                              value={
                                !item.includes('new_key')
                                  ? { label: item, value: item }
                                  : ''
                              }
                              onChange={(value) => {
                                if (value && item) {
                                  const oldKey = item
                                  const newKey = value.value
                                  const newTags = changeKey(
                                    showModel.tags,
                                    oldKey,
                                    newKey
                                  )
                                  setShowModel({
                                    ...showModel,
                                    tags: newTags
                                  })
                                }
                              }}
                              placeholder="Select Tag name"
                              placeholderButtonLabel="Select Tag name"
                              styles={selectStyle}
                              isCreatable
                              isSortOptions
                              isMulti={false}
                              menuPortalTarget={document.body}
                              components={{
                                IndicatorsContainer: (props) => {
                                  const {
                                    clearValue,
                                    hasValue,
                                    isDisabled,
                                    selectProps
                                  } = props
                                  const { isClearable } = selectProps || {}
                                  const onClick = () => {
                                    clearValue()
                                    const newTags = { ...showModel.tags }
                                    delete newTags[item]
                                    setShowModel({
                                      ...showModel,
                                      tags: newTags
                                    })
                                  }
                                  return (
                                    hasValue &&
                                    isClearable && (
                                      <Box
                                        x={{
                                          '& .MuiSvgIcon-root': {
                                            color: '#0645AD',
                                            fontSize: '18px'
                                          }
                                        }}
                                      >
                                        <IconButton
                                          disableRipple
                                          onClick={onClick}
                                          disabled={isDisabled}
                                        >
                                          <ClearIcon />
                                        </IconButton>
                                      </Box>
                                    )
                                  )
                                }
                              }}
                              onCreateOption={(value) => {
                                setTagOptions({
                                  ...tagOptions,
                                  [value]: []
                                })
                                const oldKey = item
                                const newKey = value
                                const newTags = changeKey(
                                  showModel.tags,
                                  oldKey,
                                  newKey
                                )
                                console.log(newTags)
                                setShowModel({
                                  ...showModel,
                                  tags: newTags
                                })
                              }}
                            />
                          </Box>
                          <Box sx={{ width: '50%' }}>
                            <MultiSelectCheckBox
                              options={convertTolabel(tagOptions[item] || [])}
                              value={convertTolabel(showModel.tags[item] || [])}
                              onChange={(value) => {
                                value = value.map((item) => item.value)
                                setShowModel({
                                  ...showModel,
                                  tags: {
                                    ...showModel.tags,
                                    [item]: value
                                  }
                                })
                              }}
                              placeholder={'Select Tag value'}
                              placeholderButtonLabel={
                                item.includes('new_key')
                                  ? 'Select Tag value'
                                  : item
                              }
                              styles={selectStyle}
                              isCreatable
                              isMulti
                              isDisabled={item.includes('new_key')}
                              isSortOptions
                              menuPortalTarget={document.body}
                              components={{
                                IndicatorsContainer: (props) => {
                                  const {
                                    clearValue,
                                    hasValue,
                                    isDisabled,
                                    selectProps
                                  } = props
                                  const { isClearable } = selectProps || {}
                                  return (
                                    hasValue &&
                                    isClearable && (
                                      <Box
                                        x={{
                                          '& .MuiSvgIcon-root': {
                                            color: '#0645AD',
                                            fontSize: '18px'
                                          }
                                        }}
                                      >
                                        <IconButton
                                          disableRipple
                                          onClick={clearValue}
                                          disabled={isDisabled}
                                        >
                                          <ClearIcon />
                                        </IconButton>
                                      </Box>
                                    )
                                  )
                                }
                              }}
                              getDropdownButtonLabel={({
                                placeholderButtonLabel,
                                value
                              }) => {
                                if (!value) {
                                  return (
                                    <span
                                      style={{
                                        color: '#797979',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      {placeholderButtonLabel}
                                    </span>
                                  )
                                }
                                const valueLables = _.isArray(value)
                                  ? value?.map((item) => {
                                      return item?.label
                                    })
                                  : [value?.label]
                                if (Array.isArray(valueLables)) {
                                  if (value.length === 0) {
                                    return (
                                      <span
                                        style={{
                                          color: '#797979',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {placeholderButtonLabel}
                                      </span>
                                    )
                                  }
                                  return (
                                    <span
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <span style={{ color: '#797979' }}>
                                        {placeholderButtonLabel}:&nbsp;
                                      </span>
                                      <span>{valueLables.join(', ')}</span>
                                    </span>
                                  )
                                }
                                return (
                                  <Box
                                    sx={{
                                      whiteSpace: 'break-spaces',
                                      wordBreak: 'break-all',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    value.label;
                                  </Box>
                                )
                              }}
                              onCreateOption={(value) => {
                                setTagOptions({
                                  ...tagOptions,
                                  [item]: [...(tagOptions[item] || []), value]
                                })
                                setShowModel({
                                  ...showModel,
                                  tags: {
                                    ...showModel.tags,
                                    [item]: [
                                      ...(showModel.tags[item] || []),
                                      value
                                    ]
                                  }
                                })
                              }}
                            />
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                </Section>
              </Container>
            </Box>
          </Dialog>
          {ConfirmDialog}
        </Box>
      )}
    </Container>
  )
}

export default ChatDomainSettings
