import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  backdrop1: {
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.drawer + 2
  },
  backdrop2: (props) => ({
    backgroundColor: 'rgb(255 255 255 / 100%)',
    zIndex: theme.zIndex.drawer + 1
  }),
  backdrop3: {
    backgroundColor: 'rgb(255 255 255 / 100%)',
    zIndex: theme.zIndex.drawer + 1
  }
}))

export { useStyles }
