import { useEffect, useState, memo } from 'react'
import LexicalEditor from '../../components/Lexical'
import { $insertHTMLString } from '../../components/Lexical/insertHTML'
import {
  $getRoot,
  $createTextNode,
  $getSelection,
  $createParagraphNode
} from 'lexical'
import _ from 'lodash'
import { $generateHtmlFromNodes } from '@lexical/html'
const escapeUnclosedTags =
  '</span></ul></li></div></h1></h2></h3></h4></h5></h6></b></i></a></strong></sub></mark></sup>'
const Editor = (props) => {
  const {
    seteditorUpdating,
    setEditedState,
    selectedEntry,
    isReadOnly,
    editorWidth
  } = props
  const [currentEditor, setCurrentEditor] = useState({})
  const [dochtml, setDocHtml] = useState('<html></html>')
  let timeout
  const handleEditorChange = (currentEditorState, editor) => {
    seteditorUpdating(true)
    editor.update(() => {
      setCurrentEditor(editor)
      const htmlString = $generateHtmlFromNodes(editor, null)
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setEditedState(htmlString)
        seteditorUpdating(false)
      }, 500)
    })
  }

  useEffect(() => {
    if (!_.isEmpty(selectedEntry) && !_.isEmpty(currentEditor)) {
      updateEditorStatefromHTML()
    }
  }, [selectedEntry, currentEditor])

  useEffect(() => {
    setDocHtml('<html><span><span></html>')
  }, [])

  const updateEditorStatefromHTML = () => {
    let { sectionText = '', titleText = '' } = selectedEntry
    sectionText = `<h2>Section:</h2>${sectionText}${escapeUnclosedTags}<p>======</p>`
    titleText = `<h2>Title:</h2>${titleText}${escapeUnclosedTags}<p>======</p>`
    const html = `<html><body><span> </span>${titleText}${sectionText}</body></html>`
    !_.isEmpty(currentEditor) &&
      currentEditor.update(() => {
        const root = $getRoot()
        root.clear()
        const text = $createTextNode()
        root.append($createParagraphNode().append(text))
        $getRoot().select()
        const selection = $getSelection()
        $insertHTMLString(html, selection, currentEditor)
      })
  }

  return (
    <LexicalEditor
      {...props}
      documentHtml={dochtml}
      initialEditorContent={{}}
      handleEditorChange={handleEditorChange}
      isEditable={!isReadOnly}
      editorWidth={editorWidth}
    />
  )
}

const EditorWrapper = (props) => <Editor {...props} />

export default memo(EditorWrapper)
