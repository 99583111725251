import {
  GET_WORKSPACES,
  ADD_WORKSPACES,
  UPDATE_WORKSPACE,
  DELETE_WORKSPACE,
  CREATE_WORKSPACE
} from '../../actionTypes'

const initialState = {
  currentPage: 1,
  workspaces: null,
  hasNextPage: false,
  searchKeyword: '',
  sortBy: 'created_at',
  sortOrder: 'desc'
}

const checkIfAlreadyExists = (workspaces, payload) => {
  return workspaces?.findIndex((workspace) => workspace.id === payload.id)
}

function workspaceDocument(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_WORKSPACES: {
      return {
        ...state,
        ...payload
      }
    }
    case ADD_WORKSPACES: {
      const { workspaces, hasNextPage } = payload
      const newWorkspaces = [...state.workspaces]
      workspaces.forEach((workspace) => {
        const index = checkIfAlreadyExists(newWorkspaces, workspace)
        if (index === -1) {
          newWorkspaces.push(workspace)
        }
      })
      return {
        ...state,
        workspaces: newWorkspaces,
        hasNextPage
      }
    }
    case CREATE_WORKSPACE: {
      return {
        ...state,
        workspaces: [payload, ...state.workspaces]
      }
    }
    case UPDATE_WORKSPACE: {
      const index = state.workspaces?.findIndex(
        (workspace) => workspace.id === payload.id
      )
      if (index > -1) {
        const newWorkspaces = [...state.workspaces]
        newWorkspaces[index] = { ...newWorkspaces[index], ...payload }
        return {
          ...state,
          workspaces: newWorkspaces
        }
      }

      return {
        ...state
      }
    }
    case DELETE_WORKSPACE: {
      return {
        ...state,
        workspaces: state.workspaces.filter(
          (workspace) => workspace.id !== payload.id
        )
      }
    }
    default:
      return state
  }
}

export default workspaceDocument
