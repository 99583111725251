import ContentEditable from 'react-contenteditable'
import { stripHTML as stripHTMLfun } from '../../utils/CopyHTML'

const ReactContentEditable = (props) => {
  const { html, stripHTML = false, onChange = () => {}, ...rest } = props
  let safeHtml = (html ?? '').toString()
  safeHtml = stripHTML ? stripHTMLfun(safeHtml) : safeHtml
  return (
    <ContentEditable
      {...rest}
      onChange={(e) => {
        if (stripHTML) {
          e.target.value = stripHTMLfun(e.target.value)
        }
        onChange(e)
      }}
      html={safeHtml}
    />
  )
}

export default ReactContentEditable
