import { ANALYTICS_COMPLIANCE_DATA } from '../../actionTypes'
import { findIndex, cloneDeep } from 'lodash'

const initialState = {
  analyticsComplianceData: {}
}

function complianceData(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ANALYTICS_COMPLIANCE_DATA:
      return {
        ...state,
        analyticComplianceData: payload
      }
    default:
      return state
  }
}

export default complianceData
