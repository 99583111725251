import { useEffect, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import { useStyles } from './styles'
import AddIcon from '../../assets/images/AddIcon_Gray.svg'
import Image from '../../components/ImageElement'
import { cloneDeep, camelCase } from 'lodash'
import AdvancedFilter from '../AdvancedFilter'
import useDidMountEffect from '../../hooks'

const AddFilter = (props) => {
  const {
    id = 'advanced-filter',
    setDefault = true,
    handleFilter,
    filterData,
    Keys,
    fullWidth,
    resetFilter = false,
    selectLabel = 'Select Filter',
    filterItems = [],
    showCloseIcon = true,
    showAddMore = true,
    valueWidth = 100
  } = props
  const { keyOptions = [], valueOptions = [] } = filterData
  const classes = useStyles()
  const { filterWrapper, addButtonWrapper, addIcon, addIconText, fullSize } =
    classes

  const [filterList, setFilterList] = useState([])

  useEffect(() => {
    if (filterItems.length > 0) {
      setFilterList(filterItems)
    }
    /* else{
            if (keyOptions.length > 0) {
                let data = [{
                    key: "",
                    value: [],
                    keyList: keyOptions,
                    valueList: [],
                }]
                setFilterList(data)
            }
        } */
  }, [filterItems])

  useEffect(() => {
    if (setDefault && filterItems.length === 0) {
      if (keyOptions.length > 0) {
        const data = [
          {
            key: '',
            value: [],
            keyList: keyOptions,
            valueList: []
          }
        ]
        setFilterList(data)
      }
    }
  }, [keyOptions, resetFilter])

  /* useDidMountEffect(() => {
    }, [filterList]) */

  const addFilter = () => {
    const data = {
      key: '',
      value: [],
      keyList: keyOptions,
      valueList: []
    }
    const newFilter = cloneDeep(filterList)
    newFilter.push(data)
    setFilterList(newFilter)
  }

  const removeFilter = (keyId) => {
    const newFilterList = JSON.parse(JSON.stringify(filterList))
    newFilterList.splice(keyId, 1)
    setFilterList(newFilterList)
    handleFilter(newFilterList)
  }

  const handleValueSelection = (
    key,
    value,
    keyId,
    selectionType,
    keyListId
  ) => {
    const newFilterList = cloneDeep(filterList)
    newFilterList[keyId].key = key
    const { type } = keyOptions[keyListId] || {}
    if (type === 'tagcenter') {
      newFilterList[keyId].type = 'tagcenter'
      const tagValues = []
      const tagValuesId = []
      valueOptions[key].forEach((element) => {
        tagValues.push(element.label)
        tagValuesId.push(element.value)
      })
      if (selectionType === 'key') {
        newFilterList[keyId].value = tagValues
        newFilterList[keyId].valueList = tagValues
        newFilterList[keyId].valueId = tagValuesId
      } else {
        newFilterList[keyId].value = value
        newFilterList[keyId].valueList = tagValues
        const newTagValuesId = []
        value.forEach((element) => {
          const index = tagValues.indexOf(element)
          newTagValuesId.push(tagValuesId[index])
        })
        newFilterList[keyId].valueId = newTagValuesId
      }
    } else {
      if (selectionType === 'key') {
        if (key !== 'Created On') {
          newFilterList[keyId].value = !key.includes('tag_')
            ? valueOptions[camelCase(key)]
            : valueOptions[key]
        } else {
          newFilterList[keyId].value = [null, null]
        }
      } else {
        newFilterList[keyId].value = value
      }
      newFilterList[keyId].valueList = !key.includes('tag_')
        ? valueOptions[camelCase(key)]
        : valueOptions[key]
    }
    handleFilter(newFilterList)
    setFilterList(newFilterList)
  }

  const keyOptionsWithoutTitle = filterData?.keyOptions.filter(
    (key) => key?.type !== 'title'
  )

  const isAddMore = filterList?.length < keyOptionsWithoutTitle.length

  return (
    <span className={filterWrapper}>
      {filterList.length > 0 &&
        filterList.map((filter, keyId) => {
          return (
            <div key={keyId} className={fullWidth ? fullSize : ''}>
              <AdvancedFilter
                keyId={keyId}
                selectedFilter={filter}
                removeFilter={removeFilter}
                handleFilter={() => {}}
                filterData={filterData}
                handleValueSelection={handleValueSelection}
                fullWidth={fullWidth}
                selectLabel={selectLabel}
                showCloseIcon={showCloseIcon}
                valueWidth={valueWidth}
              />
            </div>
          )
        })}
      {isAddMore && (
        <div
          className={addButtonWrapper}
          onClick={() => {
            addFilter()
          }}
        >
          <Image src={AddIcon} className={addIcon} />
          <span className={addIconText}>Add Filter</span>
        </div>
      )}
    </span>
  )
}

export default AddFilter
