import React from 'react'
import { colors } from './theme'

export default function Menu(props) {
  const shadow = colors.neutral10a
  const style = {
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    height: '100%'
  }
  if (props.rightAligned) {
    style.right = 0
  }
  return <div id="multiCheckboxMenu" style={style} {...props} />
}
