import { TOASTER_ALERT, DELETE_RFX } from '../../../actionTypes'
import { delRfx } from '../../../api'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../../../config/eventMapping'

import { toast } from 'react-toastify'
import mixpanelEvents from '../../../../config/mixpanelEvents'

const deleteRfx = (data) => async (dispatch) => {
  try {
    const response = await delRfx(data)
    if (response.status === 200) {
      trackEvent(mixpanelEvents.RFX_FILE_DELETED, 'SUCCESS', response?.data)
      dispatch({
        type: TOASTER_ALERT,
        payload: {
          message: 'Rfx deleted',
          show: true,
          type: 'success'
        }
      })
      dispatch({
        type: DELETE_RFX,
        payload: data
      })
    } else {
      trackEvent(mixpanelEvents.RFX_FILE_DELETED, 'FAILED', response)
    }
  } catch (e) {
    /* toast.error(e); */
    dispatch({
      type: TOASTER_ALERT,
      payload: {
        message: 'Failed to delete Rfx',
        show: true,
        type: 'error'
      }
    })
  }
}
export default deleteRfx
