import { useEffect, useState, memo } from 'react'
import { Container, Section, SectionFixed } from '../../components/Container'
import Fab from '@mui/material/Fab'
import Box from '@mui/material/Box'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import DeleteIcon from '@mui/icons-material/Delete'
import { SketchPicker } from 'react-color'
import { useStyles } from './styles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Select from '@mui/material/Select'
import VisibleIcon from '@mui/icons-material/Visibility'
import VisibleOffIcon from '@mui/icons-material/VisibilityOff'
import Tooltip from '@mui/material/Tooltip'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import ListIcon from '@mui/icons-material/List'
import LayersIcon from '@mui/icons-material/Layers'
import SaveIcon from '@mui/icons-material/Save'
import LockIcon from '@mui/icons-material/Lock'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import SyncIcon from '@mui/icons-material/Sync'
import Checkbox from '@mui/material/Checkbox'
import MergeIcon from '@mui/icons-material/Merge'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import LayersClearIcon from '@mui/icons-material/LayersClear'
import RedoIcon from '@mui/icons-material/Redo'
import UndoIcon from '@mui/icons-material/Undo'
import Paper from '@mui/material/Paper'
import AbcIcon from '@mui/icons-material/Abc'
import Divider from '@mui/material/Divider'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import Grid from '@mui/material/Grid'
import DownloadIcon from '@mui/icons-material/Download'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import { checkExpiry, CheckAndRenderImage } from '../../utils/AWS'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import HelpIcon from '@mui/icons-material/Help'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Pagination from '@mui/material/Pagination'
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import ImageIcon from '@mui/icons-material/Image'
import ArticleIcon from '@mui/icons-material/Article'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ContactPageIcon from '@mui/icons-material/ContactPage'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import ApiIcon from '@mui/icons-material/Api'

const KeyboardShortcuts = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }
  const handleClose = (event) => {
    setAnchorEl(null)
    event.stopPropagation()
  }

  const keys = [
    {
      key: 'Ctrl + S',
      use: 'Save'
    },
    {
      key: 'Esc',
      use: 'Exit Overlays'
    },
    {
      key: 'Del',
      use: 'Delete Selected BBox'
    },
    {
      key: 'Shift + N',
      use: 'New Entry'
    },
    {
      key: 'Shift + R',
      use: 'New Resume'
    },
    {
      key: 'Shift + I',
      use: 'Toggle Between Elements Shown'
    },
    {
      key: 'Shift + T',
      use: 'Add Title'
    },
    {
      key: 'Shift + S',
      use: 'Add Section'
    },
    {
      key: 'Shift + M',
      use: 'Merge Overlay'
    },
    {
      key: 'Shift + D',
      use: 'Screenshot'
    },
    {
      key: 'Shift + V',
      use: 'Toggle Stick Adder'
    }
  ]
  return (
    <div>
      <Tooltip title={'Keyboard Shortcuts'}>
        <IconButton sx={{ padding: '0' }} onClick={handleClick}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Shortcut</TableCell>
                <TableCell>Function</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys.map((el, index) => {
                const { key, use } = el
                return (
                  <TableRow key={index}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{use}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Menu>
    </div>
  )
}

const Toolbar = (props) => {
  const {
    merge,
    setMerge,
    pageLimit,
    setPageLimit,
    mergeChecked,
    setMergeChecked,
    editorUpdating,
    mode,
    setMode,
    annotations = [],
    handleMode,
    createEntry,
    canvasToggle,
    canvasState,
    selectedEntry,
    handleSubVisiblity,
    setSelectedEntry,
    updateEntryColor,
    handleDeleteEntry,
    handleVisibility,
    handleSubEntryDelete,
    selectedSubEntry,
    setSelectedSubEntry,
    setGoToPage,
    handleSave,
    isReadOnly,
    setIsReadOnly,
    handleMerge,
    handleClearMerge,
    isSaving,
    handleReset,
    removeScreenShot,
    handleMove,
    handleUndo,
    handleRedo,
    disableUndo,
    disableRedo,
    handleSubMove,
    reConstructTextData,
    s3Obj,
    saveSync,
    saveText,
    disableSave,
    vimMode,
    setVimMode,
    dataPage,
    setDataPage,
    dataPageMeta,
    elementShown,
    setElementShown,
    handleTextExtract,
    toggleReadOnly,
    resumeEditorState,
    renderResumeReprocessMenu,
    renderProjectReprocessMenu,
    createNewResume,
    createNewProject,
    handleResumeExtract,
    handleProjectExtract,
    handleResumeVerify,
    selectedImage,
    setSelectedImage,
    removeMasterMapping,
    handleRenderPrediction,
    domain_id,
    handleHidefromSearch
  } = props
  const classes = useStyles()
  const { color, swatch, text } = classes
  const [dragActive, setDragActive] = useState('')
  const [subDragActive, setSubDragActive] = useState('')
  const [menuCreate, setMenuCreate] = useState(null)
  const [menuExtract, setMenuExtract] = useState(null)

  const handleClickMenu = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = (setAnchorEl) => {
    setAnchorEl(null)
  }

  const stripHTML = (html, text) => {
    if (html) {
      return html?.replace(/<[^>]+>/g, '')
    } else if (text) {
      return text
    }
    return 'New Entry'
  }

  const renderSubEntry = (data, type, showDelete = true) => {
    return (
      <Accordion
        disableGutters
        sx={{ Height: '18px', marginTop: '10px' }}
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ minHeight: '0px', height: '32px', textAlign: 'center' }}
        >
          {_.capitalize(type + 's')}
        </AccordionSummary>
        <AccordionDetails sx={{ maxHeight: '100px', overflow: 'auto' }}>
          {data.map((ex, ind) => (
            <Box
              key={ind}
              id={`listItem_${ex.type}_${ex.id}`}
              sx={{
                border: '1px solid #E5E5E5',
                borderTop: ex?.id === subDragActive ? '2px solid red' : '',
                cursor: 'pointer',
                background: selectedSubEntry?.id === ex?.id ? 'black' : '',
                color: selectedSubEntry?.id === ex?.id ? 'white' : 'black'
              }}
              onClick={() => setSelectedSubEntry(ex)}
              draggable={!isReadOnly}
              onDragStart={(event) => {
                handleDragStart(event, ex, ind)
              }}
              onDragEnd={() => handleDragEnd()}
              onDrop={(event) => handleSubDrop(event, type, ind)}
              onDragEnter={() => handleSubDragEnter(ex.id)}
              onDragOver={(event) => handleDragOver(event)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <IconButton
                  sx={{
                    padding: '0',
                    color: selectedSubEntry?.id === ex?.id ? 'white' : 'black'
                  }}
                  disabled={isReadOnly}
                >
                  <DragIndicatorIcon />
                </IconButton>
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: '5px',
                    padding: '5px'
                  }}
                  onClick={() => setSelectedSubEntry(ex)}
                >
                  {stripHTML(ex?.text, `New ${type}`)}
                </Box>
                <Box sx={{ display: 'flex' }}>
                  {ex.visible !== undefined && (
                    <Tooltip title={'Hide/View Bounding Boxes'}>
                      {ex.visible ? (
                        <VisibleIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            handleSubVisiblity(ex, false)
                          }}
                        />
                      ) : (
                        <VisibleOffIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            handleSubVisiblity(ex, true)
                          }}
                        />
                      )}
                    </Tooltip>
                  )}
                  {!isReadOnly && showDelete && (
                    <Tooltip title={'Delete Entry'}>
                      <DeleteIcon
                        onClick={(e) => {
                          e.stopPropagation()
                          handleSubEntryDelete(ex)
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
          {data.length < 1 && (
            <Box
              sx={{
                border: subDragActive === `empty_${type}` ? '1px solid red' : ''
              }}
              onDragEnd={() => handleDragEnd()}
              onDrop={(event) => handleSubDrop(event, type, 0)}
              onDragEnter={() => handleSubDragEnter(`empty_${type}`)}
              onDragOver={(event) => handleDragOver(event)}
            >
              Created {type + 's'} will appear here
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    )
  }

  const disableReset = isSaving

  const addToMerge = (id, checked) => {
    if (checked) {
      setMergeChecked([...mergeChecked, id])
    } else {
      const newMergeChecked = _.clone(mergeChecked)
      const index = newMergeChecked.indexOf(id)
      if (index !== -1) {
        newMergeChecked.splice(index, 1)
      }
      setMergeChecked(newMergeChecked)
    }
  }

  const handleDragOver = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleDrop = (event, val) => {
    if (!isReadOnly) {
      let el = event.dataTransfer.getData('data')
      if (el) {
        el = JSON.parse(el)
        handleMove(el, val)
      }
    }
    handleDragEnd()
  }

  const handleDragEnd = () => {
    setSubDragActive('')
    setDragActive('')
  }

  const handleDragStart = (event, val) => {
    event.dataTransfer.setData('data', JSON.stringify(val))
  }

  const handleDragEnter = (key) => {
    if (key && !isReadOnly) {
      setDragActive(key)
    }
  }

  const handleSubDragEnter = (key) => {
    if (key && !isReadOnly) {
      setSubDragActive(key)
    }
  }

  const handleSubDrop = (event, type, ind) => {
    if (!isReadOnly) {
      let el = event.dataTransfer.getData('data')
      if (el) {
        el = JSON.parse(el)
        handleSubMove(el, type, ind)
      }
    }
    handleDragEnd()
  }

  const entryindex = _.findIndex(annotations, { id: selectedEntry.id })
  const {
    title = [],
    section = [],
    screenshots = []
  } = entryindex > -1 ? annotations[entryindex] : {}

  const filteredAnnotations = _.filter(annotations, function (o) {
    return o?.updateStatus !== 'delete'
  })

  const [showPageLimitEdit, setShowPageLimitEdit] = useState(false)
  const [currpageLimit, setCurrPageLimit] = useState(pageLimit)

  const renderPagination = () => {
    const { total_pages, pdf_start_page, pdf_end_page } = dataPageMeta
    const handleChange = (event, value) => {
      setDataPage(value)
    }
    return (
      <Box sx={{ marginTop: '5px', fontSize: '14px' }}>
        <Box>
          Current PDF Pages: {pdf_start_page} - {pdf_end_page}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box>Elements Per Page:</Box>
          {showPageLimitEdit ? (
            <TextField
              sx={{ width: '60px', '& input': { padding: '0px 5px' } }}
              value={currpageLimit}
              variant="standard"
              onChange={(e) => setCurrPageLimit(e.target.value)}
              disabled={isSaving}
              onBlur={(e) => {
                setShowPageLimitEdit(false)
                setPageLimit(currpageLimit)
              }}
            />
          ) : (
            <Box
              component={'span'}
              sx={{ cursor: 'pointer' }}
              onClick={() => setShowPageLimitEdit(true)}
            >
              {pageLimit}
            </Box>
          )}
        </Box>
        <Pagination
          sx={{
            display: 'flex',
            justifyContent: 'center',
            '& ul': { justifyContent: 'center' }
          }}
          count={total_pages}
          page={dataPage}
          onChange={handleChange}
        />
      </Box>
    )
  }

  const handleDownload = (el, name) => {
    const { src } = el
    if (src) {
      const fileName = name ? name + '.png' : 'screenshot.png'
      if (/^data:image\//.test(src)) {
        const link = document.createElement('a')
        link.download = fileName
        link.href = src
        link.click()
      } else {
        initalizeDownload(src, fileName)
      }
    }
  }

  const renderMasterScreenshot = () => {
    const { master } = selectedEntry || {}
    const { people_data = [], status = '' } = master || {}

    return (
      !_.isEmpty(people_data) &&
      status !== 'deleted' && (
        <Accordion
          disableGutters
          sx={{ Height: '18px', marginTop: '15px' }}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ minHeight: '0px', height: '32px', textAlign: 'center' }}
          >
            Master Screenshots
          </AccordionSummary>

          <AccordionDetails sx={{ maxHeight: '100px', overflow: 'auto' }}>
            {people_data?.map((ex, ind) => {
              const {
                thumbnail_url,
                domain_id,
                proposal_id,
                page_number,
                proposal_name
              } = ex
              let screenshot_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/${page_number}.png`
              const name = proposal_name || proposal_id
              if (thumbnail_url) {
                screenshot_url = thumbnail_url
              }
              return (
                <Box
                  key={ind}
                  id={`listItem_master_screenshot_${ex.id}`}
                  sx={{
                    border: '1px solid #E5E5E5',
                    cursor: 'pointer',
                    color: 'black'
                  }}
                  onClick={async () => {
                    const signedSrc = await checkExpiry(screenshot_url, s3Obj)
                    setSelectedImage([{ src: signedSrc, name }])
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box
                      sx={{
                        height: '50px',
                        width: '50px'
                      }}
                    >
                      <CheckAndRenderImage
                        style={{
                          height: '50px',
                          width: '50px',
                          objectFit: 'contain',
                          border: '1px solid #E5E5E5',
                          overflow: 'hidden'
                        }}
                        src={screenshot_url}
                        s3Obj={s3Obj}
                      />
                    </Box>
                    <Box
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginLeft: '5px',
                        padding: '5px'
                      }}
                    >
                      {name}
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Tooltip title={'Download Image'}>
                        <IconButton
                          onClick={(e) => {
                            handleDownload({ src: screenshot_url }, name)
                            e.stopPropagation()
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={'Remove Mapping'}>
                        <IconButton
                          disabled={isReadOnly}
                          onClick={(e) => {
                            removeMasterMapping(ind)
                            e.stopPropagation()
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={'Open in Annotation Viewer'}>
                        <IconButton
                          disabled={isReadOnly}
                          onClick={(e) => {
                            const { proposal_id } = ex
                            const path = `/view/pdf/${proposal_id}/0`
                            if (path) {
                              window.open(path, '_blank')
                            }
                            e.stopPropagation()
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </AccordionDetails>
        </Accordion>
      )
    )
  }

  const renderMappingPredictions = () => {
    const { showPredictions, predictions } = selectedEntry
    return (
      showPredictions && (
        <Accordion
          disableGutters
          sx={{ Height: '18px', marginTop: '15px' }}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ minHeight: '0px', height: '32px', textAlign: 'center' }}
          >
            Predictive Mapping
          </AccordionSummary>
          <Box sx={{ textAlign: 'end' }}>
            <Tooltip
              title={
                predictions
                  ? 'Re-fetch Mapping Prediction'
                  : 'Fetch Mapping Prediction'
              }
            >
              <IconButton
                onClick={(e) => {
                  handleRenderPrediction()
                  e.stopPropagation()
                }}
                disableRipple
              >
                <BurstModeIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <AccordionDetails sx={{ maxHeight: '100px', overflow: 'auto' }}>
            {predictions && !_.isEmpty(predictions) ? (
              <>
                {Object.keys(predictions).map((key, index) => {
                  const item = predictions[key]
                  const {
                    proposal_id,
                    thumbnail_url,
                    page_number,
                    proposal_name
                  } = item
                  const name = proposal_name || proposal_id
                  const screenshot_url =
                    thumbnail_url ||
                    `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/${page_number}.png`
                  const ex = { src: screenshot_url, name: proposal_id }
                  return (
                    <Box
                      id={`listItem_predictions_${ex.id}`}
                      key={index}
                      sx={{
                        border: '1px solid #E5E5E5',
                        cursor: 'pointer',
                        color: 'black'
                      }}
                      onClick={async () => {
                        const signedSrc = await checkExpiry(ex.src, s3Obj)
                        ex.src = signedSrc
                        setSelectedImage([ex])
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box
                          sx={{
                            height: '50px',
                            width: '50px'
                          }}
                        >
                          <CheckAndRenderImage
                            style={{
                              height: '50px',
                              width: '50px',
                              objectFit: 'contain',
                              border: '1px solid #E5E5E5',
                              overflow: 'hidden'
                            }}
                            src={ex.src}
                            s3Obj={s3Obj}
                          />
                        </Box>
                        <Box
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginLeft: '5px',
                            padding: '5px'
                          }}
                        >
                          {name}
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                          <Tooltip title={'Download Image'}>
                            <IconButton
                              onClick={(e) => {
                                handleDownload({ src: screenshot_url }, name)
                                e.stopPropagation()
                              }}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={'Open in Annotation Viewer'}>
                            <IconButton
                              disabled={isReadOnly}
                              onClick={(e) => {
                                const path = `/view/pdf/${proposal_id}/${page_number}}`
                                if (path) {
                                  window.open(path, '_blank')
                                }
                                e.stopPropagation()
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  )
                })}
              </>
            ) : (
              <>
                {predictions && _.isEmpty(predictions)
                  ? 'No Predicted Mapping Found'
                  : 'Predictions will appear here'}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      )
    )
  }

  const renderScreenshot = () => {
    return (
      <Accordion
        disableGutters
        sx={{ Height: '18px', marginTop: '15px' }}
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: '0px', height: '32px', textAlign: 'center' }}
        >
          Screenshots
        </AccordionSummary>
        <AccordionDetails sx={{ maxHeight: '100px', overflow: 'auto' }}>
          {screenshots?.map((ex, ind) => (
            <Box
              id={`listItem_screenshot_${ex.id}`}
              key={ind}
              sx={{
                border: '1px solid #E5E5E5',
                cursor: 'pointer',
                color: 'black'
              }}
              onClick={async () => {
                const signedSrc = await checkExpiry(ex.src, s3Obj)
                ex.src = signedSrc
                setSelectedImage([ex])
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  sx={{
                    height: '50px',
                    width: '50px'
                  }}
                >
                  <CheckAndRenderImage
                    style={{
                      height: '50px',
                      width: '50px',
                      objectFit: 'contain',
                      border: '1px solid #E5E5E5',
                      overflow: 'hidden'
                    }}
                    src={ex.src}
                    s3Obj={s3Obj}
                  />
                </Box>
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: '5px',
                    padding: '5px'
                  }}
                >
                  {`Image ${ind + 1}`}
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Tooltip title={'Download Image'}>
                    <IconButton
                      onClick={(e) => {
                        handleDownload(ex)
                        e.stopPropagation()
                      }}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Delete Image'}>
                    <IconButton
                      onClick={(e) => {
                        removeScreenShot(ind)
                        e.stopPropagation()
                      }}
                      disabled={isReadOnly}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          ))}
          {screenshots.length < 1 && (
            <Box>Created screenshots will appear here</Box>
          )}
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderImage = () => {
    const { images = {} } = selectedEntry
    return (
      <Accordion
        disableGutters
        sx={{ Height: '18px', marginTop: '15px' }}
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: '0px', height: '32px', textAlign: 'center' }}
        >
          Image
        </AccordionSummary>
        <AccordionDetails sx={{ maxHeight: '100px', overflow: 'auto' }}>
          {!_.isEmpty(images) ? (
            <Box
              id={'listItem_image_screen'}
              sx={{
                border: '1px solid #E5E5E5',
                cursor: 'pointer',
                color: 'black'
              }}
              onClick={async () => {
                const signedSrc = await checkExpiry(images.src, s3Obj)
                images.src = signedSrc
                setSelectedImage([images])
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  sx={{
                    height: '50px',
                    width: '50px'
                  }}
                >
                  <CheckAndRenderImage
                    style={{
                      height: '50px',
                      width: '50px',
                      objectFit: 'contain',
                      border: '1px solid #E5E5E5',
                      overflow: 'hidden'
                    }}
                    src={images?.thumbnail ? images?.thumbnail : images?.src}
                    s3Obj={s3Obj}
                  />
                </Box>
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: '5px',
                    padding: '5px'
                  }}
                >
                  {images.name}
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Tooltip title={'Download Image'}>
                    <DownloadIcon
                      onClick={(e) => {
                        handleDownload(images)
                        e.stopPropagation()
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>Image screenshots will appear here</Box>
          )}
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderSectionTools = () => {
    return (
      <>
        {canvasState && (
          <Grid
            sx={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              borderTop: '1px',
              borderBottom: '1px',
              border: 'solid #E5E5E5',
              padding: '10px'
            }}
          >
            <Grid
              onClick={() => handleMode('title')}
              sx={{
                cursor: 'pointer',
                padding: '0',
                color: mode === 'title' ? 'primary' : 'grey',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                fontSize: '14px'
              }}
            >
              <HighlightAltIcon />
              <span>Add Title</span>
            </Grid>
            <Grid
              onClick={() => handleMode('section')}
              sx={{
                cursor: 'pointer',
                padding: '0',
                color: mode === 'section' ? 'primary' : 'grey',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                fontSize: '14px'
              }}
            >
              <HighlightAltIcon />
              <div>Add Section</div>
            </Grid>
            <Grid
              onClick={() => handleMode('multiselect')}
              sx={{
                cursor: 'pointer',
                padding: '0',
                color: mode === 'multiselect' ? 'primary' : 'grey',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                fontSize: '14px'
              }}
            >
              <MergeIcon />
              <div>Merge Overlay</div>
            </Grid>
            <Grid
              onClick={() => handleMode('screenshot')}
              sx={{
                cursor: 'pointer',
                padding: '0',
                color: mode === 'screenshot' ? 'primary' : 'grey',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                fontSize: '14px'
              }}
            >
              <ScreenshotMonitorIcon />
              <div>Screenshot</div>
            </Grid>
            <Grid
              sx={{
                cursor: 'pointer',
                color: 'grey',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                fontSize: '14px'
              }}
              onClick={() => {
                if (isReadOnly) return
                reConstructTextData(selectedEntry, annotations)
              }}
            >
              <AbcIcon />
              <div>Force Re-Construct</div>
            </Grid>
            <Grid
              onClick={() => {
                if (isReadOnly) return
                setVimMode(!vimMode)
              }}
              sx={{
                cursor: 'pointer',
                padding: '0',
                color: vimMode ? 'primary' : 'grey',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                fontSize: '14px'
              }}
            >
              <PlaylistAddIcon />
              <div>Sticky Adder</div>
            </Grid>
          </Grid>
        )}
      </>
    )
  }

  const renderResumeTools = () => {
    return (
      <>
        {canvasState && (
          <Paper sx={{ marginTop: '10px', padding: '10px' }} elevation={3}>
            <Grid container>
              <Grid
                item
                md={4}
                sm={6}
                onClick={() => handleMode('screenshot')}
                sx={{
                  cursor: 'pointer',
                  padding: '0',
                  color: mode === 'screenshot' ? 'primary' : 'grey'
                }}
              >
                <ScreenshotMonitorIcon />
                <div>Screenshot</div>
              </Grid>
            </Grid>
          </Paper>
        )}
      </>
    )
  }

  const renderAdditionalOptions = () => {
    const type = selectedEntry?.type

    switch (type) {
      case 'section':
        return (
          <>
            {renderSectionTools()}
            {renderSubEntry(title, 'title')}
            {renderSubEntry(section, 'section')}
            {renderScreenshot()}
          </>
        )
      case 'resume':
        return (
          <>
            {renderResumeTools()}
            {renderScreenshot()}
            {resumeEditorState === 'Edit Master Resume' && (
              <Box sx={{ marginTop: '20px' }}>{renderMasterScreenshot()}</Box>
            )}
            {renderMappingPredictions()}
          </>
        )
      case 'image':
        return (
          <>
            {_.isEmpty(selectedEntry?.images) ? (
              <>
                {canvasState && (
                  <Paper
                    sx={{ marginTop: '10px', padding: '10px' }}
                    elevation={3}
                  >
                    <Grid container>
                      <Grid
                        item
                        md={4}
                        sm={6}
                        onClick={() => handleMode('screenshot_image')}
                        sx={{
                          cursor: 'pointer',
                          padding: '0',
                          color:
                            mode === 'screenshot_image' ? 'primary' : 'grey'
                        }}
                        disabled={!canvasState || isReadOnly}
                      >
                        <ScreenshotMonitorIcon />
                        <div>Add Image</div>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </>
            ) : (
              <>{renderImage()}</>
            )}
          </>
        )
      default:
        return null
    }
  }

  const renderEntryOptions = (entry, index) => {
    const { type } = entry
    switch (type) {
      case 'image': {
        const { hidden_from_search } = entry
        return (
          entry?.updateStatus !== 'delete' && (
            <div
              id={`listItem_${entry.id}`}
              style={{
                border:
                  dragActive === entry.id
                    ? '2px solid #6BBDFA'
                    : '1px solid #E5E5E5',
                cursor: 'pointer',
                background:
                  selectedEntry.id === entry.id
                    ? 'black'
                    : entry?.updateStatus === 'edit'
                    ? 'lightgrey'
                    : '',
                color: selectedEntry.id === entry.id ? 'white' : 'black'
              }}
              onClick={() => {
                setSelectedEntry(entry)
                setGoToPage(parseInt(entry.pageIndex - 1))
                setSelectedSubEntry(null)
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Tooltip title={'Change Color'}>
                  <div className={swatch}>
                    <Select
                      disabled={isReadOnly}
                      inputProps={{ IconComponent: () => null }}
                      className={color}
                      style={{ background: entry.color }}
                    >
                      <SketchPicker
                        color={entry.color}
                        onChange={(e) => updateEntryColor(e.hex, entry)}
                      />
                    </Select>
                  </div>
                </Tooltip>
                <Tooltip
                  enterDelay={1000}
                  title={stripHTML(
                    `${entry.index + 1} : ${_.capitalize(entry.type)}`,
                    'New Page'
                  )}
                >
                  <Box
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginLeft: '5px',
                      padding: '5px',
                      width: '100%',
                      display: 'flex'
                    }}
                  >
                    {stripHTML(
                      `${entry.index + 1} : ${_.capitalize(entry.type)}`,
                      'New Page'
                    )}
                  </Box>
                </Tooltip>
                <Box sx={{ display: 'flex' }}>
                  {!isReadOnly && (
                    <Tooltip title={'Toggle Hide from Search'}>
                      {hidden_from_search ? (
                        <SearchOffIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            handleHidefromSearch(entry, false)
                          }}
                        />
                      ) : (
                        <SearchIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            handleHidefromSearch(entry, true)
                          }}
                        />
                      )}
                    </Tooltip>
                  )}
                  <Tooltip title={'Hide/View Bounding Boxes'}>
                    {entry.visible ? (
                      <VisibleIcon
                        onClick={(e) => {
                          e.stopPropagation()
                          handleVisibility(entry, false)
                        }}
                      />
                    ) : (
                      <VisibleOffIcon
                        onClick={(e) => {
                          e.stopPropagation()
                          handleVisibility(entry, true)
                        }}
                      />
                    )}
                  </Tooltip>
                  {!isReadOnly && (
                    <Tooltip title={'Delete Entry'}>
                      <DeleteIcon
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteEntry(entry)
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </div>
          )
        )
      }
      case 'section': {
        const { hidden_from_search } = entry
        return (
          entry?.updateStatus !== 'delete' && (
            <div
              id={`listItem_${entry.id}`}
              style={{
                border:
                  dragActive === entry.id
                    ? '2px solid #6BBDFA'
                    : '1px solid #E5E5E5',
                cursor: 'pointer',
                background:
                  selectedEntry.id === entry.id
                    ? 'black'
                    : entry?.updateStatus === 'edit'
                    ? 'lightgrey'
                    : '',
                color: selectedEntry.id === entry.id ? 'white' : 'black'
              }}
              onClick={() => {
                setSelectedEntry(entry)
                setGoToPage(parseInt(entry.pageIndex - 1))
                setSelectedSubEntry(null)
              }}
              onDrop={(event) => handleDrop(event, entry)}
              onDragEnter={() => handleDragEnter(entry.id)}
              onDragOver={(event) => handleDragOver(event)}
              onDragExit={() => setSelectedSubEntry('')}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                {merge && (
                  <Checkbox
                    sx={{
                      color: selectedEntry.id === entry.id ? 'white' : 'black',
                      '&.Mui-checked': {
                        color: selectedEntry.id === entry.id ? 'white' : 'black'
                      }
                    }}
                    checked={mergeChecked.includes(entry.id)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(_, checked) => addToMerge(entry.id, checked)}
                  />
                )}
                <Tooltip title={'Change Color'}>
                  <div className={swatch}>
                    <Select
                      disabled={isReadOnly}
                      inputProps={{ IconComponent: () => null }}
                      className={color}
                      style={{ background: entry.color }}
                    >
                      <SketchPicker
                        color={entry.color}
                        onChange={(e) => updateEntryColor(e.hex, entry)}
                      />
                    </Select>
                  </div>
                </Tooltip>
                <Tooltip
                  enterDelay={1000}
                  title={stripHTML(
                    `${entry.index + 1} : ${_.capitalize(entry.type)}`,
                    'New Page'
                  )}
                >
                  <Box
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginLeft: '5px',
                      padding: '5px',
                      width: '100%',
                      display: 'flex'
                    }}
                  >
                    {stripHTML(
                      `${entry.index + 1} : ${_.capitalize(entry.type)}`,
                      'New Page'
                    )}
                  </Box>
                </Tooltip>
                <Box sx={{ display: 'flex' }}>
                  {!isReadOnly && (
                    <Tooltip title={'Toggle Hide from Search'}>
                      {hidden_from_search ? (
                        <SearchOffIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            handleHidefromSearch(entry, false)
                          }}
                        />
                      ) : (
                        <SearchIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            handleHidefromSearch(entry, true)
                          }}
                        />
                      )}
                    </Tooltip>
                  )}
                  <Tooltip title={'Hide/View Bounding Boxes'}>
                    {entry.visible ? (
                      <VisibleIcon
                        onClick={(e) => {
                          e.stopPropagation()
                          handleVisibility(entry, false)
                        }}
                      />
                    ) : (
                      <VisibleOffIcon
                        onClick={(e) => {
                          e.stopPropagation()
                          handleVisibility(entry, true)
                        }}
                      />
                    )}
                  </Tooltip>
                  {!isReadOnly && (
                    <Tooltip title={'Delete Entry'}>
                      <DeleteIcon
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteEntry(entry)
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </div>
          )
        )
      }
      case 'resume': {
        const { verified, profile_picture_location } = entry
        const name = entry?.parsedData?.emp_name || ''
        return (
          <div
            id={`listItem_${entry.id}`}
            style={{
              border:
                dragActive === entry.id
                  ? '2px solid #6BBDFA'
                  : '1px solid #E5E5E5',
              cursor: 'pointer',
              background:
                selectedEntry.id === entry.id
                  ? 'black'
                  : entry?.updateStatus === 'edit'
                  ? 'lightgrey'
                  : '',
              color: selectedEntry.id === entry.id ? 'white' : 'black'
            }}
            onClick={() => {
              setSelectedEntry(entry)
              setGoToPage(parseInt(entry.pageIndex - 1))
              setSelectedSubEntry(null)
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Tooltip title={'View Profile Picture'}>
                <div
                  className={swatch}
                  onClick={async () => {
                    if (profile_picture_location?.src) {
                      const signedSrc = await checkExpiry(
                        profile_picture_location?.src,
                        s3Obj
                      )
                      setSelectedImage([{ src: signedSrc }])
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: '35px'
                    }}
                  >
                    {profile_picture_location?.src ? (
                      <CheckAndRenderImage
                        style={{
                          width: '35px',
                          objectFit: 'contain',
                          border: '1px solid #E5E5E5',
                          overflow: 'hidden'
                        }}
                        src={profile_picture_location?.src}
                        s3Obj={s3Obj}
                      />
                    ) : (
                      <ContactPageIcon sx={{ width: '35px', height: '35px' }} />
                    )}
                  </Box>
                </div>
              </Tooltip>
              <Tooltip
                enterDelay={1000}
                title={name || `${entry.index + 1} Resume`}
              >
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: '5px',
                    padding: '5px',
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  {`${entry.index + 1} : ${name || 'Resume'}`}
                </Box>
              </Tooltip>
              <Box sx={{ display: 'flex' }}>
                {!isReadOnly && (
                  <Tooltip title={'Delete Resume'}>
                    <IconButton
                      disableRipple
                      sx={{
                        padding: '0',
                        color: 'inherit'
                      }}
                      onClick={(e) => {
                        handleDeleteEntry(entry)
                        e.stopPropagation()
                      }}
                      disabled={isSaving}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </div>
        )
      }
      case 'project': {
        const { verified, profile_picture_location } = entry
        const name =
          entry?.parsedData?.emp_name || entry?.parsedData?.project_name || ''
        return (
          <div
            id={`listItem_${entry.id}`}
            style={{
              border:
                dragActive === entry.id
                  ? '2px solid #6BBDFA'
                  : '1px solid #E5E5E5',
              cursor: 'pointer',
              background:
                selectedEntry.id === entry.id
                  ? 'black'
                  : entry?.updateStatus === 'edit'
                  ? 'lightgrey'
                  : '',
              color: selectedEntry.id === entry.id ? 'white' : 'black'
            }}
            onClick={() => {
              setSelectedEntry(entry)
              setGoToPage(parseInt(entry.pageIndex - 1))
              setSelectedSubEntry(null)
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Tooltip title={'View Project'}>
                <div
                  className={swatch}
                  onClick={async () => {
                    if (profile_picture_location?.src) {
                      const signedSrc = await checkExpiry(
                        profile_picture_location?.src,
                        s3Obj
                      )
                      setSelectedImage([{ src: signedSrc }])
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: '35px'
                    }}
                  >
                    {<ApiIcon sx={{ width: '35px', height: '35px' }} />}
                  </Box>
                </div>
              </Tooltip>
              <Tooltip
                enterDelay={1000}
                title={name || `${entry.index + 1} Resume`}
              >
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: '5px',
                    padding: '5px',
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  {`${entry.index + 1} : ${name || 'Resume'}`}
                </Box>
              </Tooltip>
              <Box sx={{ display: 'flex' }}>
                {/* {!isReadOnly &&
                    <Tooltip title={'Toggle Resume Verify'}>
                      <IconButton
                        disableRipple
                        sx={{
                          padding: '0',
                          color: 'inherit'

                        }}
                        disabled={isSaving}
                        onClick={() => handleResumeVerify(index)}
                      >
                        {verified ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                      </IconButton>
                    </Tooltip>} */}
                {!isReadOnly && (
                  <Tooltip title={'Delete Project'}>
                    <IconButton
                      disableRipple
                      sx={{
                        padding: '0',
                        color: 'inherit'
                      }}
                      onClick={(e) => {
                        handleDeleteEntry(entry)
                        e.stopPropagation()
                      }}
                      disabled={isSaving}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </div>
        )
      }
    }
  }

  return (
    <Container>
      <Section overFlow>
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Fab
            sx={{ margin: '7px' }}
            disabled={isSaving || !toggleReadOnly}
            variant="extended"
            size="small"
            color={isReadOnly ? 'primary' : ''}
            onClick={() => setIsReadOnly(!isReadOnly)}
          >
            {isReadOnly ? (
              <>
                <LockIcon />
                {'Read Only On'}
              </>
            ) : (
              <>
                <AppRegistrationIcon />
                {'Read Only off'}
              </>
            )}
          </Fab>
          <Fab
            sx={{ margin: '7px' }}
            variant="extended"
            size="small"
            color="primary"
            onClick={() => handleSave()}
            disabled={disableSave}
          >
            {saveSync ? (
              <>
                {' '}
                <SyncIcon />
                {saveText}
              </>
            ) : (
              <>
                <SaveIcon />
                Save
              </>
            )}
          </Fab>
          {/* <Fab sx={{ margin: '7px 0px' }} variant="extended" size="small" disabled={disableReset} color='' onClick={() => handleReset()}><RestartAltIcon /> Reset</Fab> */}
          {/* <Box sx={{ margin: '7px 0px', display: 'flex', justifyContent: 'space-around' }}>
            <Fab variant="extended" size="small" disabled={disableReset || disableUndo} color='' onClick={() => handleUndo()} > <UndoIcon />Undo</Fab>
            <Fab variant="extended" size="small" disabled={disableReset || disableRedo} color='' onClick={() => handleRedo()}> <RedoIcon />Redo</Fab>
          </Box> */}
          {renderPagination()}
          <Accordion
            disableGutters
            elevation={0}
            sx={{ Height: '18px', marginTop: '20px' }}
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ paddingRight: '20px' }}
            >
              <KeyboardShortcuts />
              <Typography
                align="center"
                sx={{ width: '100%' }}
                className={text}
              >
                Entry
              </Typography>
            </AccordionSummary>
            <Box sx={{ padding: '5px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Tooltip title={'Toggle Elements'}>
                  {elementShown === 'section' ? (
                    <IconButton
                      sx={{ padding: '0' }}
                      onClick={() => setElementShown('image')}
                      disabled={isReadOnly}
                    >
                      <ArticleIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      sx={{ padding: '0' }}
                      onClick={() => setElementShown('section')}
                      disabled={isReadOnly}
                    >
                      <ImageIcon />
                    </IconButton>
                  )}
                </Tooltip>
                <Tooltip title={'Create new entry'}>
                  <IconButton
                    sx={{ padding: '0' }}
                    onClick={(e) => handleClickMenu(e, setMenuCreate)}
                    disabled={isReadOnly}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={menuCreate}
                  open={Boolean(menuCreate)}
                  onClose={() => handleCloseMenu(setMenuCreate)}
                >
                  <MenuItem
                    onClick={() => {
                      createEntry()
                      handleCloseMenu(setMenuCreate)
                    }}
                  >
                    <ArticleIcon /> Section
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      createNewResume()
                      handleCloseMenu(setMenuCreate)
                    }}
                  >
                    <ContactPageIcon /> Resume
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      createNewProject()
                      handleCloseMenu(setMenuCreate)
                    }}
                  >
                    <ApiIcon /> Project
                  </MenuItem>
                </Menu>
                <Tooltip title={'Toggle Merge'}>
                  <IconButton
                    sx={{ padding: '0' }}
                    disabled={isReadOnly}
                    onClick={() => setMerge(!merge)}
                  >
                    <MergeIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={'Toggle Canvas'}>
                  <IconButton
                    sx={{ padding: '0' }}
                    onClick={() => canvasToggle()}
                  >
                    {canvasState ? <LayersIcon /> : <LayersClearIcon />}
                  </IconButton>
                </Tooltip>
                <Tooltip title={'Extract'} disabled={isReadOnly}>
                  <IconButton
                    sx={{ padding: '0' }}
                    onClick={(e) => handleClickMenu(e, setMenuExtract)}
                  >
                    <DocumentScannerIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={menuExtract}
                  open={Boolean(menuExtract)}
                  onClose={() => handleCloseMenu(setMenuExtract)}
                >
                  <MenuItem
                    onClick={() => {
                      handleTextExtract()
                      handleCloseMenu(setMenuExtract)
                    }}
                  >
                    <ArticleIcon /> Text
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleResumeExtract()
                      handleCloseMenu(setMenuExtract)
                    }}
                  >
                    <ContactPageIcon /> Resume
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleProjectExtract()
                      handleCloseMenu(setMenuExtract)
                    }}
                  >
                    <ApiIcon /> Project
                  </MenuItem>
                </Menu>
              </Box>
              {merge && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    marginTop: '5px',
                    border: '1px solid lightgrey'
                  }}
                >
                  Merge
                  <Tooltip title={'Merge'}>
                    <IconButton
                      sx={{ padding: '0' }}
                      color="primary"
                      onClick={() => handleMerge()}
                      disabled={isReadOnly}
                    >
                      <DoneIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Cancel Merge'}>
                    <IconButton
                      sx={{ padding: '0' }}
                      color="primary"
                      onClick={() => handleClearMerge()}
                      disabled={isReadOnly}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <AccordionDetails sx={{ maxHeight: '200px', overflow: 'auto' }}>
              {filteredAnnotations?.map((entry, i) =>
                renderEntryOptions(entry, i)
              )}
              {annotations.length < 1 && (
                <Box>Created entries will appear here</Box>
              )}
            </AccordionDetails>
          </Accordion>
          {renderResumeReprocessMenu()}
          {renderProjectReprocessMenu()}
          {selectedEntry.id && <>{renderAdditionalOptions()}</>}
        </Box>
      </Section>
    </Container>
  )
}

export default Toolbar
