import clsx from 'clsx'
import { useStyles } from './styles'
import defaultLogo from '../../assets/images/logo.svg'
const Image = (props) => {
  const classes = useStyles()
  const { defaultImage } = classes
  const {
    id = 'image',
    src = { defaultLogo },
    alt = 'image',
    className = '',
    fallBackSrc = '',
    handleClick = () => {}
  } = props
  return (
    <img
      onClick={handleClick}
      onError={({ currentTarget }) => {
        if (fallBackSrc) {
          currentTarget.onerror = null
          currentTarget.src = fallBackSrc
        }
      }}
      id={id}
      src={src}
      alt={alt}
      className={clsx(defaultImage, className)}
      {...props}
    />
  )
}
export default Image
