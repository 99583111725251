import React, { useEffect, useState } from 'react'
import { Container, Section } from '../../components/Container'
import { Resizable } from 'react-resizable'
import { Grid, Box, IconButton, Tooltip } from '@mui/material'
import { useResizeDetector } from 'react-resize-detector'
import { ReactComponent as Chat_Session } from '../../assets/images/Chat_Session.svg'
import { useStyles } from './styles'
import ResourceSidePane from '../../sections/ResourceSidePane'
import ResourceMainPane from '../../sections/ResourceMainPane'
import { initalizeS3 } from '../../utils/AWS'

const ResourceViewer = () => {
  const [listWidth, setListWidth] = useState(0)
  const [isResizing, setIsResizing] = useState(false)
  const { width, height, ref } = useResizeDetector()
  const classes = useStyles()
  const [showSidePane, setShowSidePane] = useState(true)
  const [s3Obj, sets3Obj] = useState({})
  const [dragActive, setDragActive] = useState('')
  const [rows, setRowsState] = useState(null)
  const [collectionsRows, setCollectionsRows] = useState(null)

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    if (width > 600) {
      handleOnResize()
    } else {
      setListWidth(0)
    }
  }, [width])

  const handleOnResize = (event, obj) => {
    if (obj) {
      let newWidth = width / 5
      if (newWidth > 300) {
        newWidth = 300
      }
      if (obj.size && obj.size.width < width / 3 && obj.size.width > newWidth) {
        setListWidth(obj.size.width)
      }
    } else {
      let newWidth = width / 5
      if (newWidth > 300) {
        newWidth = 300
      }
      setListWidth(newWidth)
    }
  }

  const CustomResizeHandle = React.forwardRef((props, ref2) => {
    const { handleAxis, ...restProps } = props
    return (
      <Box
        className={classes.customResizeHandler}
        style={{ background: isResizing ? '#cdcdcd' : 'inherit' }}
        ref={ref2}
        {...restProps}
      >
        <span
          className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
        ></span>
      </Box>
    )
  })

  const toggleListPane = () => {
    setShowSidePane(!showSidePane)
  }
  const handleDragEnd = () => {
    const dragGhost = document.getElementById('drag-ghost')
    if (dragGhost) {
      document.body.removeChild(dragGhost)
    }
    setDragActive('')
  }

  return (
    <Container parentref={ref}>
      <Section overFlow>
        <Box className={classes.containerDiv}>
          {showSidePane && (
            <Resizable
              width={listWidth}
              axis="x"
              onResize={handleOnResize}
              resizeHandles={['e']}
              onResizeStart={() => {
                setIsResizing(true)
              }}
              onResizeStop={() => {
                setIsResizing(false)
              }}
              handle={
                <CustomResizeHandle
                  style={{
                    opacity: '0'
                  }}
                />
              }
            >
              <Box
                className={classes.sidePaneContainer}
                style={{
                  width: listWidth - 1 + 'px'
                }}
              >
                <ResourceSidePane
                  s3Obj={s3Obj}
                  toggleListPane={toggleListPane}
                  listWidth={listWidth}
                  dragActive={dragActive}
                  setDragActive={setDragActive}
                  rows={rows}
                  setRowsState={setRowsState}
                  collectionsRows={collectionsRows}
                  setCollectionsRows={setCollectionsRows}
                  handleDragEnd={handleDragEnd}
                />
              </Box>
            </Resizable>
          )}
          <Box
            sx={{
              width: showSidePane ? `calc(100% - ${listWidth}px)` : '100%'
            }}
            className={classes.mainContainer}
          >
            <ResourceMainPane
              s3Obj={s3Obj}
              toggleListPane={toggleListPane}
              listWidth={listWidth}
              showTogglePane={!showSidePane}
              handleDragEnd={handleDragEnd}
              rows={rows}
              setRowsState={setRowsState}
              collectionsRows={collectionsRows}
              setCollectionsRowsState={setCollectionsRows}
            />
          </Box>
        </Box>
      </Section>
    </Container>
  )
}

export default ResourceViewer
