import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor()
  return {
    container: {
      height: '100%',
      width: '100%',
      display: 'flex',
      color: '#797979'
    },
    sidePane: {
      borderRight: '1px solid rgb(229, 229, 229)',
      height: '100%'
    },
    mainPane: {
      alignContent: 'center',
      height: '100%'
    },
    mainPaneContainer: {
      height: '100%',
      width: 'calc(100% - 25px)',
      marginLeft: '25px'
    },
    titleText: {
      fontSize: '24px',
      fontWeight: '600',
      marginTop: '30px'
    },
    subText: {
      fontSize: '16px',
      fontWeight: '400',
      marginTop: '20px'
    },
    sidePaneContainer: {
      marginTop: '30px',
      padding: '10px'
    },
    listWrapper: {
      padding: '0px',
      width: '100%',
      '& .Mui-selected': {
        backgroundColor: '#DBEAFF'
      }
    },
    listButtonWrapper: {
      padding: '0px'
    },
    listTextWrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 5px',
      '& input': {
        padding: '0.5px 5px',
        color: '#797979'
      },
      '& input:focus': {
        background: 'transparent !important'
      },
      '& .MuiSvgIcon-root': {
        fontSize: '18px'
      }
    },
    listText: {
      marginLeft: '5px',
      fontWeight: 500,
      width: '100%',
      wordBreak: 'break-word',
      fontSize: '15px',
      flex: '1',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical'
    },
    secondaryColor: {
      color: selectedColor
    },
    allText: {
      fontWeight: 600,
      fontSize: '16px'
    },
    allItem: {
      margin: '5px 0px'
    },
    titleActions: {
      padding: '0px',
      '& >svg': {
        width: '20px',
        height: '20px'
      }
    },
    flexContainer: {
      display: 'flex'
    },
    accordianWrapper: {
      marginTop: '30px',
      padding: '10px',
      '& .Mui-expanded': {
        transform: 'none',
        margin: '0px',
        '&:first-of-type': {
          '& .MuiAccordionSummary-root': {
            borderStartEndRadius: '4px',
            borderStartStartRadius: '4px'
          }
        },
        '&:last-of-type': {
          '& .MuiAccordionSummary-root': {
            borderRadius: '0px'
          }
        }
      },
      '& .MuiAccordionSummary-root': {
        backgroundColor: '#FAFAFA',
        border: '1px solid #797979',
        borderTop: '0px',
        borderRadius: '0px'
      },
      '& .MuiAccordion-root': {
        boxShadow: 'none',
        '&:first-of-type': {
          borderTop: '1px solid #797979',
          borderStartEndRadius: '4px',
          borderStartStartRadius: '4px',
          '& .MuiAccordionSummary-root': {
            borderStartEndRadius: '4px',
            borderStartStartRadius: '4px'
          }
        },
        '&:last-of-type': {
          '& .MuiAccordionSummary-root': {
            borderEndEndRadius: '4px',
            borderEndStartRadius: '4px'
          },

          '& .MuiAccordionDetails-root': {
            borderBottom: '1px solid #797979 !important',
            borderEndEndRadius: '4px',
            borderEndStartRadius: '4px'
          }
        }
      },
      '& .MuiAccordionDetails-root': {
        border: '1px solid #797979',
        borderRadius: '0px',
        borderTop: 'none',
        padding: '0px'
      }
    },
    accordianTitleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    accordianTitle: {
      fontSize: '16px',
      color: '#797979',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '700',
      '& div': {
        marginLeft: '5px'
      },
      '& .MuiSvgIcon-root': {
        fontSize: '20px'
      }
    },
    tableHeader: {
      fontFamily: 'PoppinsRegular',
      fontWeight: 700,
      fontSize: 16,
      padding: '11px 11px 0',
      color: '#797979'
    },
    cellText: {
      fontFamily: 'PoppinsRegular',
      fontWeight: 500,
      fontSize: 14,
      cursor: 'default',
      color: '#797979',
      wordBreak: 'break-word',
      '& .tableCell': {
        whiteSpace: 'initial',
        textOverflow: 'ellipsis',
        fontFamily: 'PoppinsRegular',
        fontWeight: 500,
        fontSize: 14,
        cursor: 'default'
      }
    },
    tableStyle: {
      height: '90%',
      position: 'relative',
      tableLayout: 'auto'
    },
    tablesortlabel: {
      '& .MuiTableSortLabel-icon': {
        color: '#DEE0E8',
        opacity: 1
      },
      '&. Mui-active .MuiTableSortLabel-icon ': {
        color: '#000000',
        opacity: 1
      }
    },
    tablebody: {
      display: 'contents'
    },
    tablerow1: {
      height: '15px',
      padding: '0 20px'
    },
    tablecell: {
      textAlign: 'center'
    },
    sortLabel: {
      marginLeft: '10px',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '20px',
      width: '10px'
    },
    tableRowOption: {
      padding: '10px'
    },
    hideSortIcon: {
      visibility: 'hidden'
    },
    sortIconActive: {
      color: '#000000',
      width: '8px',
      height: '8px'
    },
    sortIcon: {
      color: '#9494946e',
      width: '8px',
      height: '8px'
    },
    sortDownArrow: {
      paddingTop: '2px'
    },
    multiSelectWrapper: {
      paddingLeft: '4px !important'
    },
    tableOptions: {
      fontSize: '14px',
      color: selectedColor,
      cursor: 'pointer',
      fontWeight: '500',
      margin: '0px 10px'
    },
    tableOptionsWrapper: {
      wordBreak: 'break-word',
      display: 'flex',
      alignItems: 'center'
    },
    tableAdd: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center'
    },
    tableTextInput: {
      wordBreak: 'break-word',
      '& input': {
        padding: '0.5px 5px',
        color: '#797979',
        fontFamily: 'PoppinsRegular',
        fontWeight: 500,
        fontSize: 14
      },
      '& input:focus': {
        background: 'transparent !important'
      }
    },
    emptyCheckbox: {
      height: '38px',
      width: '38px'
    },
    disabledRow: {
      opacity: '0.5',
      cursor: 'inherit !important'
    },
    tableSaveButtonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      borderBottom: '1px solid #797979',
      color: '#797979'
    },
    tableSaveButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px'
    },
    searchTextWrapper: {
      '& input': {
        padding: '0.5px 5px'
      },
      '& input:focus': {
        background: 'transparent !important'
      },
      '& .MuiSvgIcon-root': {
        fontSize: '18px',
        color: '#797979'
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#797979'
      }
    },
    searchTextClose: {
      color: '#797979',
      fontSize: '16px',
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        fontSize: '18px',
        color: '#797979'
      }
    },
    visibilityIcon: {
      color: '#797979',
      fontSize: '18px',
      display: 'flex',
      alignItems: 'center'
    },
    accessControl: {
      marginTop: '20px',
      '& table': {
        background: '#FAFAFA',
        padding: '20px'
      },
      '& th': {
        padding: '4px',
        textAlign: 'left',
        width: '70px'
      },
      '& td': {
        padding: '4px',
        textAlign: 'left'
      },
      '& .MuiCheckbox-root': {
        padding: '0px'
      }
    }
  }
})

export { useStyles }
