import React, { Fragment, useEffect, useState } from 'react'
import { getAllSimiarImages, hideAllImages } from '../../store/api'
import { Box, Pagination, TextField, Checkbox } from '@mui/material'
import Modal from '@mui/material/Modal'
import Loader from '../../components/Loader'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import _, { set } from 'lodash'
import { checkExpiry, extractDimensions } from '../../utils/AWS'
import JustifiedLayout from '@codekraft-studio/react-justified-layout'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Container, Section, SectionFixed } from '../../components/Container'
import { Button } from '../../components'
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import HideImageIcon from '@mui/icons-material/HideImage'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ImageIcon from '@mui/icons-material/Image'
import { toast } from 'react-toastify'
import Slider from '@mui/material/Slider'
import Divider from '@mui/material/Divider'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'

const bricks = (array = [], signedImages, onClickImage, selectedImages) => {
  return array.map((item, index) => {
    const { style = {} } = item
    let { width = 215, height = 145 } = style
    style.background = '#E8F0FE'
    style.border = '1px solid lightgray'
    const id = signedImages[index]?.id
    const checked = selectedImages?.includes(id)
    if (checked) {
      width = parseInt(width) - 20 + 'px'
      height = parseInt(height) - 20 + 'px'
    }
    return (
      <Fragment key={index}>
        <Box
          onClick={() => onClickImage(id)}
          className="deleteButton"
          sx={{ ...style, cursor: 'pointer' }}
        >
          <Box
            sx={{ position: 'absolute', background: '#e8f0fe94', right: '0' }}
          >
            <Checkbox
              sx={{
                padding: '0px',
                color: 'black',
                right: '0',
                '&.Mui-checked': {
                  color: 'black'
                }
              }}
              checked={checked}
            />
          </Box>
        </Box>
        <Box
          id="image-div"
          sx={{
            ...style,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& img': {
              cursor: 'pointer',
              objectFit: 'contain'
            },
            '&:hover': {
              opacity: 0.8
            }
          }}
        >
          <LazyLoadImage
            height={height}
            width={width}
            effect="blur"
            src={signedImages[index]?.signedSrc}
          />
        </Box>
      </Fragment>
    )
  })
}

const HideImages = ({
  parentImage = {},
  s3Obj = {},
  show = false,
  onClose = () => {}
}) => {
  const [images, setImages] = useState(null)
  const [aspectArray, setAspectArray] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [totalImages, setTotalImages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [loading, setLoading] = useState(false)
  const [currpageLimit, setCurrPageLimit] = useState(pageSize)
  const [modeHidden, setModeHidden] = useState(null)
  const [threshold, setThreshold] = useState(1.85)
  const [tempThreshold, setTempThreshold] = useState(1.85)
  const [showReFetch, setShowReFetch] = useState(false)
  const id = parentImage?.id

  const fetchImages = async (page, mode, min_score = threshold) => {
    setLoading(true)
    const req = {
      asset_id: id,
      start_page: page,
      page_size: pageSize,
      hidden_from_search: mode,
      min_score
    }
    const res = await getAllSimiarImages(req)
    if (res.status === 200) {
      const { results = [], total_count } = res.data
      const aspectArray = []
      const signedImages = []
      await Promise.all(
        results.map(async (img, idx) => {
          const { metadata } = img
          const { dimensions = '' } = metadata || {}
          const url = img?.image_thumbnail_url
            ? img?.image_thumbnail_url
            : img?.image_url
          const signedSrc = await checkExpiry(url, s3Obj)
          const signedOrignal = await checkExpiry(
            img?.image_url ? img?.image_url : img?.image_thumbnail_url,
            s3Obj
          )
          const image_data = await extractDimensions(img, signedSrc, 'image')
          const { width = 215, height = 145 } = image_data
          const aspectRatio = width / height
          aspectArray.push(aspectRatio)
          signedImages.push({
            ...img,
            aspectRatio,
            signedSrc: signedSrc + '&cacheblock=true',
            signedOrignal: signedOrignal + '&cacheblock=true'
          })
        })
      )
      setImages(signedImages)
      setAspectArray(aspectArray)
      setTotalImages(total_count)
      setThreshold(min_score)
      setTempThreshold(min_score)
    } else {
      setImages([])
      setTotalImages(0)
      setAspectArray(aspectArray)
      toast.error('Error in fetching similar images')
    }
    setShowReFetch(false)
    setLoading(false)
  }

  useEffect(() => {
    if (show && id) {
      setCurrentPage(1)
      setModeHidden(false)
    }
  }, [id, show])

  useEffect(() => {
    if (show && modeHidden !== null) {
      fetchImages(currentPage, modeHidden)
      setSelectedImages([])
    }
  }, [modeHidden, show, pageSize])

  useEffect(() => {
    if (pageSize > 0) {
      setCurrentPage(1)
    }
  }, [pageSize])

  const totalPages = Math.ceil(totalImages / pageSize)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
    fetchImages(value, modeHidden)
  }

  const onClickImage = (id) => {
    if (selectedImages.includes(id)) {
      const index = selectedImages.indexOf(id)
      if (index > -1) {
        const newSelectedImages = [...selectedImages]
        newSelectedImages.splice(index, 1)
        setSelectedImages(newSelectedImages)
      }
    } else {
      setSelectedImages([...selectedImages, id])
    }
  }

  const handleSelectAll = () => {
    const ids = images.map((img) => img.id)
    const newSelectedImages = [...selectedImages]
    ids.forEach((id) => {
      if (!selectedImages.includes(id)) {
        newSelectedImages.push(id)
      }
    })
    setSelectedImages(newSelectedImages)
  }
  const handleUnSelectAll = () => {
    const ids = images.map((img) => img.id)
    const newSelectedImages = [...selectedImages]
    ids.forEach((id) => {
      if (selectedImages.includes(id)) {
        const index = newSelectedImages.indexOf(id)
        if (index > -1) {
          newSelectedImages.splice(index, 1)
        }
      }
    })
    setSelectedImages(newSelectedImages)
  }

  const handleHide = async (hideAll = false) => {
    setLoading(true)
    const imagesToHide = hideAll ? [id] : selectedImages
    const req = {
      asset_ids: imagesToHide,
      is_hide_all: hideAll,
      hidden_from_search: !modeHidden
    }
    const res = await hideAllImages(req)
    if (res.status === 200) {
      setCurrentPage(1)
      fetchImages(1, modeHidden)
      setSelectedImages([])
    } else {
      const msg = modeHidden
        ? 'Error in unhiding images'
        : 'Error in hiding images'
      toast.error(msg)
      setLoading(false)
    }
  }

  const handleChangeThreshold = (event, newValue) => {
    setTempThreshold(newValue)
    setShowReFetch(true)
  }

  const handleReFetch = async (val) => {
    if (val === false) {
      setShowReFetch(false)
      setTempThreshold(threshold)
    } else {
      setCurrentPage(1)
      fetchImages(1, modeHidden, tempThreshold)
      setSelectedImages([])
      setShowReFetch(false)
    }
  }

  return (
    <Modal
      fullwidth
      disableEscapeKeyDown
      open={show}
      onClose={onClose}
      sx={{
        zIndex: 1301,
        padding: '120px'
      }}
    >
      <Box
        sx={{
          background: 'white',
          padding: '20px',
          height: 'calc(100% - 40px)',
          overflow: 'auto'
        }}
      >
        <Container>
          <>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <Loader loading={loading} caption={'loading similar images'} />
              </Box>
            ) : (
              <>
                <SectionFixed>
                  <Box
                    sx={{
                      marginTop: '10px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderRadius: 1,
                        bgcolor: 'background.paper',
                        color: 'text.secondary',
                        padding: '8px 15px'
                      }}
                    >
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                      <Divider orientation="vertical" flexItem />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: '0px 10px'
                        }}
                      >
                        <Box>Images Per Page </Box>
                        <TextField
                          sx={{
                            width: '60px',
                            '& input': { padding: '0px 5px' }
                          }}
                          value={currpageLimit}
                          variant="standard"
                          onChange={(e) => setCurrPageLimit(e.target.value)}
                          onBlur={(e) => {
                            setPageSize(currpageLimit)
                          }}
                        />
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '0px 10px',
                          justifyContent: 'center'
                        }}
                      >
                        <Box>Threshold </Box>
                        <Slider
                          sx={{ width: '200px', margin: '10px 10px' }}
                          value={tempThreshold}
                          step={0.05}
                          marks
                          min={1.5}
                          max={2}
                          valueLabelDisplay="auto"
                          onChange={handleChangeThreshold}
                        />
                        {showReFetch && (
                          <Box>
                            <IconButton
                              disableRipple
                              onClick={() => {
                                handleReFetch()
                              }}
                            >
                              <DoneIcon />
                            </IconButton>
                            <IconButton
                              disableRipple
                              onClick={() => {
                                handleReFetch(false)
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <IconButton disableRipple onClick={onClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '10px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                      <Button onClick={() => handleSelectAll()}>
                        <CheckBoxIcon /> Select all
                      </Button>
                      <Button
                        onClick={() => handleUnSelectAll()}
                        disabled={_.isEmpty(selectedImages)}
                      >
                        <CheckBoxOutlineBlankIcon /> Unselect all
                      </Button>
                      <Button
                        onClick={() => handleHide()}
                        disabled={_.isEmpty(selectedImages)}
                      >
                        <LibraryAddCheckOutlinedIcon />{' '}
                        {modeHidden ? 'Unhide' : 'Hide'} Selected
                      </Button>
                      <Button
                        onClick={() => handleHide(true)}
                        disabled={totalImages < 1}
                      >
                        {modeHidden ? <ImageIcon /> : <HideImageIcon />}
                        {modeHidden ? 'Unhide' : 'Hide'} All Similarties
                      </Button>
                    </Box>
                    <Button
                      variant={modeHidden ? 'contained' : 'outlined'}
                      onClick={() => setModeHidden(!modeHidden)}
                    >
                      {modeHidden
                        ? 'View Visible Images'
                        : 'View Hidden Images'}
                    </Button>
                  </Box>
                </SectionFixed>
                <Section overFlow>
                  <Box sx={{ height: '100%', width: '100%' }}>
                    {images && _.isEmpty(images) ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%'
                        }}
                      >
                        No Similar Images Found
                      </Box>
                    ) : (
                      <Box
                        sx={{ height: '100%', width: '100%', overflow: 'auto' }}
                      >
                        {images?.length > 0 && aspectArray.length > 0 && (
                          <JustifiedLayout
                            items={aspectArray}
                            options={{
                              containerPadding: 20,
                              boxSpacing: 10,
                              targetRowHeight: 150,
                              targetRowHeightTolerance: 0.1
                            }}
                          >
                            {(items) =>
                              bricks(
                                items,
                                images,
                                onClickImage,
                                selectedImages
                              )
                            }
                          </JustifiedLayout>
                        )}
                      </Box>
                    )}
                  </Box>
                </Section>
              </>
            )}
          </>
        </Container>
      </Box>
    </Modal>
  )
}

export default HideImages
