import axios from 'axios'
import { Auth } from 'aws-amplify'
import * as Sentry from '@sentry/react'

const axiosinstance = axios.create({})

axiosinstance.interceptors.request.use(
  async function (config) {
    const transaction = Sentry.startTransaction({
      op: 'transaction',
      name: config.url
    })

    config.transaction = transaction

    const currentSession = await Auth.currentSession()
    const token = currentSession?.accessToken?.getJwtToken()

    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axiosinstance.interceptors.response.use(
  function (response) {
    response.config.transaction.finish()

    return response
  },
  function (error) {
    if (error.response) {
      error.response.config.transaction.finish()
    }

    return Promise.reject(error)
  }
)

export default axiosinstance
