import React, { useEffect } from 'react'
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../config/routes'
import Domain from '../../pages/Domain'
import Spinner from '../SpinnerBackDrop'
import { initializeIntercom } from '../../utils/Intercom/Intercom'
import { Auth } from 'aws-amplify'

const PrivateRoute = () => {
  const isAuthenticated = useSelector(
    (state) => state.authenticate?.isAuthenticated
  )
  const userData = useSelector((state) => state.authenticate?.user)
  const { domain = {} } = userData
  const { id = '', domain_id = '', email = '', user_name = '' } = domain
  const navigate = useNavigate()
  const { DOMAIN, SIGNIN } = ROUTES
  const location = useLocation()

  const checkUser = () => {
    Auth.currentAuthenticatedUser().catch((err) => {
      const currentURL = location.pathname + location.search
      navigate(SIGNIN, { state: { redirectTo: currentURL } })
      return false
    })
  }

  useEffect(() => {
    checkUser()
  }, [])

  useEffect(() => {
    if (domain_id === null) {
      navigate(DOMAIN)
    } else {
      if (isAuthenticated && id && email) {
        if (
          process.env.REACT_APP_ENV === 'staging' ||
          process.env.REACT_APP_ENV === 'production'
        ) {
          initializeIntercom(domain)
        }
      }
    }
  }, [domain])

  return isAuthenticated ? (
    domain_id === null ? (
      <Domain />
    ) : (
      <Outlet />
    )
  ) : (
    isAuthenticated === false && <Spinner loading={true} />
  )
}

export default PrivateRoute
