import moment from 'moment'

function convertIsoToDaysAgo(isoDate) {
  const currentDate = moment()
  const inputDate = moment(isoDate)
  const daysDifference = currentDate.diff(inputDate, 'days')
  if (daysDifference === 0) {
    return 'Today'
  } else if (daysDifference === 1) {
    return 'Yesterday'
  } else {
    return `${daysDifference} days ago`
  }
}

export { convertIsoToDaysAgo }
