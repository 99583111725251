import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import Tab from '../../components/VerticalTabs'
import { useStyles } from './styles'
import ChatDisclaimerSettings from '../ChatDisclaimerSettings'
import ChatVoiceSettings from '../ChatVoiceSettings'
import ChatDomainPrompts from '../ChatDomainPrompts'

const ChatSettings = ({ isFocused }) => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)

  const Tabs = [
    {
      label: 'Shared Prompts',
      children: <ChatDomainPrompts isFocused={activeTab === 0} />
    },
    {
      label: 'Voice Setup',
      children: <ChatVoiceSettings isFocused={activeTab === 1} />
    },
    {
      label: 'Disclaimer',
      children: <ChatDisclaimerSettings isFocused={activeTab === 2} />
    }
  ]
  return (
    <Box className={classes.container}>
      <Tab
        data={Tabs}
        activeTab={activeTab}
        tabChangeCallBack={(index) => setActiveTab(index)}
      />
    </Box>
  )
}

export default ChatSettings
