import {
  UPDATE_CHILDREN_COLLECTIONS,
  UPDATE_COLLECTIONS
} from '../../../actionTypes'
import { getCollections } from '../../../api'
import { toast } from 'react-toastify'

const collections =
  (req, callback, setLoadingState = false) =>
  async (dispatch) => {
    const startRequest = async () => {
      const res = await getCollections(req === 'all' ? 'root' : req)
      if (res.status === 200) {
        const { data = [] } = res
        dispatch({
          type: UPDATE_CHILDREN_COLLECTIONS,
          payload: { children: data, parent_id: req },
          callback
        })
      } else {
        toast.error('Unable to get collection')
        callback && callback()
      }
      if (setLoadingState) {
        dispatch({
          type: UPDATE_COLLECTIONS,
          payload: { id: req, status: 'active' },
          callback
        })
      }
    }
    if (setLoadingState) {
      dispatch({
        type: UPDATE_COLLECTIONS,
        payload: { id: req, status: 'loading' },
        callback: () => {
          startRequest()
          callback()
        }
      })
    } else {
      startRequest()
    }
  }

export default collections
