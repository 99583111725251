import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  dateButton: {
    padding: '1px',
    backgroundColor: '#FFF',
    borderRadius: '4px',
    border: 'solid 1px #E5E5E5',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.36,
    alignItems: 'baseline',
    background: '#FFF',
    boxSizing: 'border-box',
    color: '#515151',
    cursor: 'pointer',
    display: 'inline-flex',
    margin: 0,
    maxWidth: '100%',
    flexWrap: 'nowrap',
    outline: 'currentcolor none medium !important',
    textAlign: 'left',
    textDecoration: 'none',
    transition:
      'background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    width: 'auto',
    textTransform: 'none'
  },
  buttonText: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: '#515151'
    },
    '& input': {
      fontSize: '12px',
      color: '#515151'
    }
  },

  dateSelected: {
    color: '#0645AD !important',
    border: '1px solid #0645AD !important',
    '& input': {
      color: '#0645AD !important'
    },
    '& .MuiSvgIcon-root': {
      color: '#0645AD !important'
    }
  },

  closeIcon: {
    fontSize: '18px'
  },
  disabledButton: {
    backgroundColor: '#f5f5f5 !important'
  }
})

export { useStyles }
