import { FETCH_COLLECTIONS, REFRESH_COLLECTIONS } from '../../../actionTypes'
import { getCollections } from '../../../api'
import { toast } from 'react-toastify'

const collections =
  (force = false) =>
  async (dispatch) => {
    const res = await getCollections()
    if (force) {
      if (res.status === 200) {
        const { data } = res
        dispatch({
          type: REFRESH_COLLECTIONS,
          payload: data
        })
      } else {
        toast.error('Unable to fetch collections')
      }
    } else {
      if (res.status === 200) {
        const { data } = res
        dispatch({
          type: FETCH_COLLECTIONS,
          payload: data
        })
      } else {
        toast.error('Unable to fetch collections')
        dispatch({
          type: FETCH_COLLECTIONS,
          payload: []
        })
      }
    }
  }

export default collections
