import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: ({ isSettings }) => ({
    display: 'flex',
    justifyContent: isSettings ? 'start' : 'center',
    padding: isSettings ? '24px' : '0px'
  }),
  formcontrol: {
    width: '350px',
    paddingTop: '20px'
  },
  text: {
    marginBottom: 24
  },
  text1: {
    marginBottom: 16
  }
})
export { useStyles }
