import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom/client'
import _ from 'lodash'
import { Resizable } from 'react-resizable'
import { useStyles } from './styles'
import {
  Box,
  Dialog,
  ListItemText,
  ListItemIcon,
  Grid,
  TextField,
  Tooltip,
  Skeleton,
  Menu,
  MenuItem,
  Checkbox,
  SvgIcon,
  Divider,
  IconButton,
  Popover,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButtonGroup,
  ToggleButton,
  Fade
} from '@mui/material'
import {
  MenuButton,
  Button,
  Loader,
  MultiSelectCheckBox,
  EllipsisTooltip,
  ImageGallery,
  IconPicker,
  ReactSelect,
  TextInput,
  useConfirmation,
  TextLineLimiter,
  DateTagSelector,
  TagFilter,
  Container,
  Section,
  SectionFixed,
  ResourceAddToCollection,
  ContentEditable
} from '../../components'
import DeleteIcon from '@mui/icons-material/Delete'
import { ReactComponent as ViewAllIcon } from '../../assets/svg/ViewAll.svg'
import { ReactComponent as ExpandDownIcon } from '../../assets/svg/ExpandDown.svg'
import { ReactComponent as SortDown } from '../../assets/svg/SortDown.svg'
import { ReactComponent as SortUp } from '../../assets/svg/SortUp.svg'
import ReactResizeDetector from 'react-resize-detector'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import {
  copyFilesToCollection,
  moveFilesToCollection,
  postCollectionData,
  deleteProposals,
  updateResource,
  deleteAsset,
  deleteDocument,
  hideAsset,
  multiDocuments,
  updateFileAccess,
  getFileAccess,
  deleteFromCollection
} from '../../store/api'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import clsx from 'clsx'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DownloadIcon from '@mui/icons-material/Download'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { toast } from 'react-toastify'
import DocumentSvg, {
  ReactComponent as DocumentIcon
} from '../../assets/svg/FileIcons/Document.svg'
import PDFSvg, {
  ReactComponent as PdfIcon
} from '../../assets/svg/FileIcons/PDF.svg'
import PPTSvg, {
  ReactComponent as PPTIcon
} from '../../assets/svg/FileIcons/PPT.svg'
import DOCSvg, {
  ReactComponent as DOCIcon
} from '../../assets/svg/FileIcons/DOC.svg'
import ImageSvg, {
  ReactComponent as ImageIcon
} from '../../assets/svg/FileIcons/Image.svg'
import VideoSvg, {
  ReactComponent as VideoIcon
} from '../../assets/svg/FileIcons/Video.svg'
import ProposalImageSvg, {
  ReactComponent as ProposalImageIcon
} from '../../assets/svg/FileIcons/ProposalImage.svg'
import { checkExpiry, extractDimensions } from '../../utils/AWS/getSignedUrl'
import { CheckAndRenderImage } from '../../utils/AWS'
import TableRowsIcon from '@mui/icons-material/TableRows'
import AppsIcon from '@mui/icons-material/Apps'
import JustifiedLayout from '@codekraft-studio/react-justified-layout'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import HoverVideoPlayer from 'react-hover-video-player'
import { convertIsoToDaysAgo } from '../../utils/Moment'
import ResourceFileView from '../ResourceFileView'
import {
  checkUserRoleSuperUser,
  checkUserRoleAnnotator,
  checkUserRoleAdmin,
  hasAccess,
  checkUserRoleViewer
} from '../../utils/User'
import {
  updateActiveCollection,
  getCollectionChildren,
  setExpandedObj,
  updateCollections,
  deleteCollection
} from '../../store/Resource/Action'
import { setUploader } from '../../store/Uploader/Actions'
import moment from 'moment'
import { initalizeDownload } from '../../utils/DownloadFromS3'
import PublicFolderSvg, {
  ReactComponent as PublicFolder
} from '../../assets/svg/PublicFolder.svg'
import SharedFolderSvg, {
  ReactComponent as SharedFolder
} from '../../assets/svg/SharedFolder.svg'
import FolderIconSvg, {
  ReactComponent as FolderIcon
} from '../../assets/svg/Folder.svg'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { updateUserConfig } from '../../store/Authentication/Actions'
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit'
import AutoModeIcon from '@mui/icons-material/AutoMode'
import HideImageIcon from '@mui/icons-material/HideImage'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import StyleIcon from '@mui/icons-material/Style'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme, styled } from '@mui/material/styles'
import { AddNewKey, addNewValue } from '../../sections/TagCenterSettings'
import ReorderIcon from '@mui/icons-material/Reorder'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import HelpIcon from '@mui/icons-material/Help'
import Drawer from '@mui/material/Drawer'
import TagCenterUpdate from '../TagCenterUpdate'
import CircularProgress from '@mui/material/CircularProgress'
import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import EditNoteIcon from '@mui/icons-material/EditNote'
import InfoIcon from '@mui/icons-material/Info'
import { tooltipClasses } from '@mui/material/Tooltip'
import { updateNewTagValue } from '../../store/TagCenter/Actions'
import FilterListIcon from '@mui/icons-material/FilterList'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import { renderURL } from '../../utils/TagCenter'
import GroupIcon from '@mui/icons-material/Group'
import { NavigateNext, ViewList, Window } from '@mui/icons-material'
import { CloudUpload, CrossIcon } from '../../components/Icons/Icons'
import KeyIcon from '@mui/icons-material/Key'
import Avatar from '@mui/material/Avatar'
import {
  getInitialsFromUserName,
  getColorsForInitials
} from '../../utils/String'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import FolderDeleteIcon from '@mui/icons-material/FolderDelete'
import LockIcon from '@mui/icons-material/Lock'
import SearchInput from '../SearchInput'
import SearchNew from '../ListFilter/SearchNew'
import Button2 from '../../components/Button/Button2'

const formatHeader = (header) => {
  const mapping = {
    created_at: 'created_on',
    created_by_user: 'created_by'
  }
  header = mapping[header] || header
  if (header === 'file_name') {
    return 'Name'
  }
  if (header === 'file_type') {
    return 'Type'
  }
  if (header === 'file_extension') {
    return 'Extension'
  }
  return _.startCase(header)
}

const multiSelectStyle = {
  control: (base) => ({
    ...base,
    minHeight: '0',
    margin: '5px'
  }),
  indicatorsContainer: (base) => ({
    ...base,
    color: '#0645AD',
    '& .MuiSvgIcon-root': {
      display: 'none',
      padding: '0px'
    }
  }),
  input: (base) => ({
    ...base,
    fontSize: '12px',
    padding: '0px'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0px 8px'
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '12px'
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontSize: '12px',
    color: '#0645AD'
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: '12px'
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: '12px'
  }),
  loadingMessage: (base) => ({
    ...base,
    fontSize: '12px'
  })
}

const CustomResizeHandle = React.forwardRef((props, ref2) => {
  const classes = useStyles()
  const {
    handleAxis,
    id,
    isResizing,
    isHovering,
    setIsHovering,
    ...restProps
  } = props

  let background = 'transparent'
  if (isResizing === id) {
    background = '#E5E5E5'
  }
  if (isHovering === id && !isResizing) {
    background = '#E5E5E5'
  }

  return (
    <div
      onMouseEnter={() => {
        setIsHovering(id)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
      className={classes.customResizeHandler}
      style={{ background }}
      ref={ref2}
      {...restProps}
    ></div>
  )
})

const convertToPercentage = (width, parentWidth) => {
  return (width * 100) / parentWidth
}

const ResizableCell = ({
  parentWidth,
  tableComponent,
  id,
  setIsResizing,
  isHovering,
  setIsHovering,
  isResizing,
  onResize,
  width,
  ...rest
}) => {
  const classes = useStyles()
  let resizeWidth = width
  resizeWidth = (parentWidth * parseInt(width.replace('%', ''))) / 100

  const styles = {
    width,
    borderRight: id === 'file_name' ? '1px solid #E5E5E5' : 'none'
  }

  return (
    <Resizable
      width={resizeWidth}
      height={0}
      axis="x"
      onResize={(_, { size }) =>
        onResize(convertToPercentage(size.width, parentWidth))
      }
      resizeHandles={['e']}
      onResizeStart={() => {
        setIsResizing(id)
      }}
      onResizeStop={() => {
        setTimeout(() => {
          setIsResizing(false)
        }, 100)
      }}
      handle={
        <CustomResizeHandle
          id={id}
          isResizing={isResizing}
          isHovering={isHovering}
          setIsHovering={setIsHovering}
        />
      }
    >
      {tableComponent === 'row' ? (
        <td {...rest} className={classes.tableRow} style={styles} />
      ) : (
        <th {...rest} className={classes.tableHeader} style={styles} />
      )}
    </Resizable>
  )
}

const ToolTipButton = (props) => {
  const { children, title = '' } = props
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'))
  return matches ? (
    <Tooltip title={props.title}>{props.children}</Tooltip>
  ) : (
    props.children
  )
}

const GridSort = (props) => {
  const {
    disabled = false,
    columns = [],
    GetSortIcon = () => <></>,
    handleColumnClick = () => {},
    sortOrder = {}
  } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSort = (column) => {
    handleColumnClick(column)
    handleClose()
  }

  const sortOrderKeys = Object.keys(sortOrder)

  return (
    <Box sx={{ display: 'flex' }}>
      <Button2 secondary onClick={handleClick}>
        <SwapVertIcon sx={{ fontSize: '18px' }} />
        Sort by
        {sortOrderKeys.length > 0 ? `: ${sortOrderKeys.length}` : ''}
      </Button2>

      <Menu
        anchorEl={anchorEl}
        TransitionComponent={Fade}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.sortMenu}
      >
        {columns.map((column, index) => (
          <Box key={index}>
            <MenuItem onClick={() => handleSort(column)}>
              <Box className={classes.hideMenuListText}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '100%',
                    gap: '10px'
                  }}
                >
                  <GetSortIcon
                    id={column}
                    isSelected={Object.keys(sortOrder).includes(column)}
                    order={sortOrder[column]}
                  />
                  {formatHeader(column)}
                </Box>
              </Box>
            </MenuItem>
            {index < columns.length - 1 && (
              <Divider className={classes.hideMenuDivider} />
            )}
          </Box>
        ))}
      </Menu>
    </Box>
  )
}

const HideColumn = (props) => {
  const {
    columns = [],
    setColumns = () => {},
    visibleColumns = [],
    hiddenColumns = [],
    setHiddenColumns = () => {},
    disabled = false,
    defaultHidden = [],
    handleSaveView = () => {},
    handleClearView = () => {},
    handleOpenAddNewKey = () => {},
    viewMode = 'table',
    isAdmin = false,
    canCreateTags = false
  } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dragActive, setDragActive] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    if (columns.length === 0) return
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleColumnClick = (column) => () => {
    setHiddenColumns((prev) => {
      if (prev.includes(column)) {
        return prev.filter((item) => item !== column)
      } else {
        if (visibleColumns.length === 1) {
          toast.error('Atleast one column should be visible')
          return prev
        }
        return [...prev, column]
      }
    })
  }

  const handleSave = (all) => {
    handleSaveView(all)
    handleClose()
  }
  const handleClear = () => {
    handleClearView()
    handleClose()
  }
  const handleDragEnter = (key) => {
    setDragActive(key)
  }

  const handleDragEnd = () => {
    setDragActive('')
  }
  const handleDragStart = (event, index) => {
    event.dataTransfer.setData('text/plain', index)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }
  const handleDrop = (event, targetIndex) => {
    const draggedIndex = parseInt(event.dataTransfer.getData('text/plain'), 10)
    const newColumns = [...columns]
    const [draggedItem] = newColumns.splice(draggedIndex, 1)
    newColumns.splice(targetIndex, 0, draggedItem)
    setColumns(newColumns)
  }

  return (
    <Box sx={{ display: 'grid', placeContent: 'center', width: '100%' }}>
      <ToolTipButton title={viewMode === 'table' ? 'Columns' : 'Save View'}>
        <div
          onClick={handleClick}
          disabled={disabled || columns?.length === 0}
          style={{
            display: 'grid',
            placeContent: 'center',
            padding: '0px',
            borderRadius: '50%',
            width: '18px',
            height: '18px',
            border: '1px solid #71717a'
          }}
        >
          +
        </div>
      </ToolTipButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.hideMenu}
      >
        <Box key="menu">
          {viewMode === 'table' &&
            columns.map(
              (column, index) =>
                !defaultHidden.includes(column) && (
                  <Box className={classes.columnMenu} key={index}>
                    <MenuItem
                      onClick={handleColumnClick(column)}
                      draggable
                      onDragStart={(event) => handleDragStart(event, index)}
                      onDragOver={handleDragOver}
                      onDragEnd={() => handleDragEnd()}
                      onDrop={(event) => handleDrop(event, index)}
                      onDragEnter={() => handleDragEnter(index)}
                      sx={{
                        borderTop: dragActive === index ? '1px solid red' : ''
                      }}
                    >
                      <Checkbox
                        color="neutral"
                        checked={!hiddenColumns.includes(column)}
                        size="small"
                      />
                      <Box className={classes.hideMenuListText}>
                        {formatHeader(column)}
                      </Box>
                      <IconButton disableRipple sx={{ cursor: 'grab' }}>
                        <DragHandleIcon />
                      </IconButton>
                    </MenuItem>
                    {column.length > 1 && (
                      <Divider className={classes.hideMenuDivider} />
                    )}
                  </Box>
                )
            )}
          {viewMode === 'table' && (
            <Divider
              className={clsx(classes.hideMenuDivider, classes.blackDivider)}
            />
          )}
          {viewMode === 'table' && canCreateTags && (
            <MenuItem
              onClick={() => {
                handleOpenAddNewKey()
                handleClose()
              }}
            >
              <AddIcon sx={{ padding: '3px 5px', fontSize: '18px' }} />
              <Box className={classes.hideMenuListText}>Add New Tag</Box>
            </MenuItem>
          )}
          {viewMode === 'table' && (
            <Divider className={classes.hideMenuDivider} />
          )}
          <MenuItem onClick={() => handleSave(false)}>
            <SaveIcon sx={{ padding: '1px 6px', fontSize: '18px' }} />
            <Box className={classes.hideMenuListText}>Save View</Box>
          </MenuItem>
          {isAdmin && (
            <>
              <Divider className={classes.hideMenuDivider} />
              <MenuItem onClick={() => handleSave(true)}>
                <GroupIcon sx={{ padding: '1px 6px', fontSize: '18px' }} />
                <Box className={classes.hideMenuListText}>
                  Save View for all users
                </Box>
              </MenuItem>
            </>
          )}
          <Divider className={classes.hideMenuDivider} />
          <MenuItem onClick={handleClear}>
            <RestartAltIcon sx={{ padding: '1px 6px', fontSize: '18px' }} />
            <Box className={classes.hideMenuListText}>Reset View</Box>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  )
}

const ViewMode = (props) => {
  const { viewMode = '', setViewMode = () => {}, disabled = false } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const setMode = (mode) => {
    setViewMode(mode)
    handleClose()
  }

  return viewMode === 'grid' ? (
    <Button2 secondary onClick={() => setMode('table')}>
      <ViewList
        sx={{
          height: '16px',
          width: '16px'
        }}
      />
    </Button2>
  ) : (
    <Button2 secondary onClick={() => setMode('grid')}>
      <Window
        sx={{
          height: '16px',
          width: '16px'
        }}
      />
    </Button2>
  )
}

const CollectionEdit = (props) => {
  const {
    isOpen = false,
    onClose = () => {},
    buttonFunction = () => {},
    collection = {}
  } = props
  const [editedData, setEditedData] = useState(null)

  useEffect(() => {
    setEditedData(collection)
  }, [collection])

  const handleButton = () => {
    if (editedData.file_name === '') {
      toast.error('Please enter collection name')
    } else {
      buttonFunction(editedData)
    }
  }

  return (
    editedData && (
      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
        <DialogTitle>Edit Collection</DialogTitle>
        <DialogContent>
          <TextInput
            placeholder="Collection Name"
            value={editedData?.file_name || ''}
            handleChange={(e) =>
              setEditedData({
                ...editedData,
                file_name: e.target.value
              })
            }
          />
          <TextInput
            style={{ marginTop: '10px' }}
            placeholder="Collection Description"
            value={editedData?.other_data?.description || ''}
            multiLine
            rows={4}
            handleChange={(e) =>
              setEditedData({
                ...editedData,
                other_data: {
                  ...editedData.other_data,
                  description: e.target.value
                }
              })
            }
          />
          {/* <Box sx={{ marginTop: '10px' }}>
                    <IconPicker
                        selectedIcon={editedData?.other_data?.icon || ''}
                        onSelect={(value) => {
                            setEditedData({
                                ...editedData,
                                other_data: {
                                    ...editedData.other_data,
                                    icon: value
                                }
                            })
                        }}
                        placeholder="Collection Icon"
                    />
                </Box> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleButton}>Save</Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}

const FileInputText = (props) => {
  const { defaultValue = '', onBlur = () => {}, disabled = false } = props

  const [value, setValue] = useState('')
  const valueRef = useRef(value)

  useEffect(() => {
    const handleKeyPress = (event) => {
      switch (event.key) {
        case 'Escape':
          setValue(defaultValue)
          break
        default:
          break
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [defaultValue])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleBlur = () => {
    if (valueRef.current && defaultValue !== valueRef.current) {
      onBlur(valueRef.current)
    } else if (!valueRef.current) {
      setValue(defaultValue)
    }
  }

  useEffect(() => {
    valueRef.current = value
  }, [value])

  return (
    <ContentEditable
      html={value}
      onClick={(e) => {
        if (!disabled) {
          e.stopPropagation()
        }
      }}
      onDoubleClick={(e) => {
        if (!disabled) {
          e.stopPropagation()
        }
      }}
      style={{
        width: '100%',
        borderRadius: '4px',
        border: !disabled ? '1px solid #000000' : 'none'
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.target.blur()
        }
      }}
      disabled={disabled}
      placeholder="File Name"
      onChange={(e) => {
        setValue(e.currentTarget.innerText)
      }}
      onBlur={(e) => {
        handleBlur()
      }}
    />
  )
}

const FileInputTags = (props) => {
  const {
    baseComponent = null,
    baseComponentOptions = [],
    defaultValue = '',
    onChange = () => {},
    onBlur = () => {},
    onDateTagChange = () => {},
    className = '',
    tagKey = '',
    isMulti = true,
    isDate = false,
    options = [],
    disabled = false,
    tagsCenterStateTags = {},
    dispatch = () => {},
    isDocumentType = false,
    tagType = '',
    isURL = false,
    canCreateTags = false
  } = props

  const [loading, setLoading] = useState(false)
  const selectWrapperRef = useRef()
  const selectRef = useRef()

  const handleOnAddNew = async (value) => {
    if (selectRef?.current) {
      selectRef.current.selectOption(value)
    }
    if (isMulti) {
      if (selectWrapperRef?.current) {
        selectWrapperRef.current.clearInputValue()
      }
    }
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  const urlRender = (values) => {
    return values.map((item, index) => (
      <Box key={index}>
        {renderURL(item)}
        {index !== values.length - 1 && ', '}
      </Box>
    ))
  }

  return disabled ? (
    <Box>{isURL ? urlRender(baseComponentOptions) : baseComponent}</Box>
  ) : isDate ? (
    <DateTagSelector
      isMulti={isMulti}
      isDisabled={disabled}
      onChange={(value) => {
        onDateTagChange(value)
      }}
      value={isMulti ? defaultValue : [defaultValue]}
      placeholder={tagKey}
    />
  ) : (
    <MultiSelectCheckBox
      getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
        if (!value) {
          return placeholderButtonLabel
        }
        if (Array.isArray(value)) {
          if (value.length === 0) {
            return placeholderButtonLabel
          }
          return `${placeholderButtonLabel}: ${value.length}`
        }
        return isURL ? (
          <Box
            sx={{
              whiteSpace: 'break-spaces',
              wordBreak: 'break-all',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {renderURL(value.label, true)}
          </Box>
        ) : (
          value.label
        )
      }}
      formatOptionLabel={(option) => {
        const { __isNew__ = false } = option || {}
        return !__isNew__ && isURL
          ? renderURL(option.label, true)
          : option.label
      }}
      selectRef={selectRef}
      ref={selectWrapperRef}
      id={tagKey}
      isCreatable={!isDocumentType && canCreateTags}
      isClearable={!isDocumentType}
      isLoading={loading}
      isDisabled={disabled || loading}
      onCreateOption={(value) => {
        setLoading(true)
        const callback = (newOption) => {
          handleOnAddNew(newOption)
        }
        addNewValue(
          value,
          tagKey,
          tagType,
          tagsCenterStateTags,
          dispatch,
          callback
        )
      }}
      className={className}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation()
      }}
      components={{
        IndicatorsContainer: (props) => {
          const { clearValue, hasValue, isDisabled, selectProps } = props
          const { isClearable } = selectProps || {}
          return (
            hasValue &&
            isClearable && (
              <Box
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: '#0645AD',
                    fontSize: '18px'
                  }
                }}
              >
                <IconButton
                  disableRipple
                  onClick={clearValue}
                  disabled={isDisabled}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            )
          )
        }
      }}
      sx={{ cursor: 'text' }}
      options={loading ? [] : options}
      value={defaultValue}
      placeholderButtonLabel={tagKey}
      isSortOptions
      placeholder={_.startCase(tagKey)}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          e.target.blur()
        }
      }}
      onChange={(e) => onChange(e)}
      onBlurStateChange={(e) => {
        onBlur(e)
      }}
      styles={multiSelectStyle}
      isMulti={isMulti}
      onValueClick={(e) => {
        e.stopPropagation()
      }}
    />
  )
}
const getFallbackImages = (row) => {
  const { other_data, file_type, file_extension = '' } = row || {}

  if (file_type === 'proposal') {
    if (file_extension?.includes('ppt')) {
      return PPTSvg
    } else if (file_extension?.includes('doc')) {
      return DOCSvg
    }
    return PDFSvg
  } else if (file_type === 'image') {
    return ImageSvg
  } else if (file_type === 'video') {
    return VideoSvg
  } else if (file_type === 'proposal image') {
    return ProposalImageSvg
  } else if (file_type === 'document') {
    return DocumentSvg
  } else if (file_type === 'collection') {
    const { is_public, access_config } = other_data
    const { user_ids = [] } = access_config || {}
    if (is_public) {
      return PublicFolderSvg
    } else if (!is_public && user_ids.length > 0) {
      return SharedFolderSvg
    } else {
      return FolderIconSvg
    }
  }
}

const getFileIcon = (row) => {
  const { other_data, file_type, file_extension = '' } = row || {}

  if (file_type === 'proposal') {
    if (file_extension?.includes('ppt')) {
      return <PPTIcon />
    } else if (file_extension?.includes('doc')) {
      return <DOCIcon />
    }
    return <PdfIcon />
  } else if (file_type === 'image') {
    return <ImageIcon />
  } else if (file_type === 'video') {
    return <VideoIcon />
  } else if (file_type === 'proposal image') {
    return <ProposalImageIcon />
  } else if (file_type === 'collection') {
    const { is_public, access_config } = other_data
    const { user_ids = [] } = access_config || {}
    if (is_public) {
      return <PublicFolder />
    } else if (!is_public && user_ids.length > 0) {
      return <SharedFolder />
    } else {
      return <FolderIcon />
    }
  } else if (file_type === 'document') {
    if (file_extension?.includes('ppt')) {
      return <PPTIcon />
    } else if (file_extension?.includes('doc')) {
      return <DOCIcon />
    } else if (file_extension?.includes('pdf')) {
      return <PdfIcon />
    }
    return <DocumentIcon />
  }
}
const FileIcon = (props) => {
  const {
    row = {},
    handleShowImage = null,
    s3Obj = {},
    showPreview = false,
    fileIconClassName = ''
  } = props

  const { other_data, file_type } = row || {}
  const [icon, setIcon] = useState(null)
  const {
    thumbnail_location = '',
    raw_location = '',
    asset_type = '',
    is_public = false
  } = other_data || {}
  let thumbnail = thumbnail_location || raw_location
  let original = raw_location || thumbnail_location
  if (asset_type?.includes('video')) {
    thumbnail = thumbnail_location
    original = thumbnail_location
  }
  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef(null)

  const hoverTimeoutRef = useRef(null)

  const popoverEnter = (e) => {
    if (file_type !== 'collection' && thumbnail) {
      hoverTimeoutRef.current = setTimeout(() => {
        setOpenedPopover(true)
      }, 1000)
    }
  }

  const popoverLeave = (e) => {
    clearTimeout(hoverTimeoutRef.current)
    setOpenedPopover(false)
  }

  const scrolldiv = useRef(null)

  const handleScroll = (event) => {
    const delta = Math.max(
      -1,
      Math.min(1, event.nativeEvent.wheelDelta || -event.nativeEvent.detail)
    )
    scrolldiv.current.scrollLeft -= delta * 40
  }
  const { imageContainer, imageWrapper, fileIcon, fileIconDocs } = useStyles()
  const fileIconStyle =
    fileIconClassName ||
    (row?.file_type === 'collection' ? fileIcon : fileIconDocs)

  useEffect(() => {
    const icon = getFileIcon(row)
    setIcon(icon)
  }, [row])

  const imageClick = () => {
    if (file_type === 'collection') return
    if (original && handleShowImage) {
      handleShowImage([{ src: original }])
    }
  }
  const onLeave = () => {
    clearTimeout(hoverTimeoutRef.current)
  }

  return (
    <Box>
      {showPreview ? (
        <>
          <span
            ref={popoverAnchor}
            onMouseEnter={popoverEnter}
            onMouseLeave={onLeave}
          >
            <Box disableRipple onClick={() => imageClick()}>
              {!is_public && (
                <Box
                  sx={{ position: 'relative', width: '100%', height: '100%' }}
                >
                  <LockIcon
                    sx={{
                      position: 'absolute',
                      height: '10px',
                      width: '10px',
                      right: '0'
                    }}
                  />
                </Box>
              )}
              <SvgIcon className={fileIconStyle}>{icon && icon}</SvgIcon>
            </Box>
          </span>
          <Popover
            open={openedPopover}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            slotProps={{
              paper: { onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }
            }}
            elevation={10}
          >
            <Box
              className={imageContainer}
              ref={scrolldiv}
              onWheel={handleScroll}
            >
              <CheckAndRenderImage
                className={imageWrapper}
                src={thumbnail}
                s3Obj={s3Obj}
                onClick={() => imageClick()}
              />
            </Box>
          </Popover>
        </>
      ) : (
        <Box disableRipple onClick={() => imageClick()}>
          {!is_public && (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                '& .MuiSvgIcon-root': {
                  height: '10px !important',
                  width: '10px !important',
                  backgroundColor: 'transparent !important'
                }
              }}
            >
              <LockIcon
                sx={{
                  position: 'absolute',
                  height: '10px',
                  width: '10px',
                  right: '0'
                }}
              />
            </Box>
          )}
          <SvgIcon className={fileIconStyle}>{icon && icon}</SvgIcon>
        </Box>
      )}
    </Box>
  )
}

const RenderAsset = (props) => {
  const {
    itemProps = {},
    item = {},
    parentProps = {},
    index = 0,
    s3Obj = {}
  } = props

  const { other_data, file_name, file_type, created_at, created_by_user, id } =
    item || {}
  const { asset_type = '', loading: rowLoading } = other_data || {}
  const {
    getMenuOptions = () => {},
    handleRowClick = () => {},
    handleDragStart = () => {},
    handleDragOver = () => {},
    handleDragEnd = () => {},
    draggable,
    onClickRow = () => {},
    selectedRow = []
  } = parentProps
  const classes = useStyles()
  const menuOptions = getMenuOptions(item)
  const [url, setUrl] = useState('')
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const fallbackSvg = getFallbackImages(item)

  useEffect(() => {
    async function initalizeData() {
      const {
        asset_type = '',
        raw_location = '',
        thumbnail_location = ''
      } = other_data || {}
      let url = thumbnail_location || raw_location
      let thumbnailLocationUrl = ''
      if (asset_type?.includes('video')) {
        url = raw_location
        thumbnailLocationUrl = thumbnail_location
      }
      url = await checkExpiry(url, s3Obj)
      setUrl(url)
      if (thumbnailLocationUrl) {
        thumbnailLocationUrl = await checkExpiry(thumbnailLocationUrl, s3Obj)
        setThumbnailUrl(thumbnailLocationUrl)
      }
    }
    initalizeData()
  }, [item])

  let { style = {} } = itemProps || {}
  style = {
    ...style,
    background: '#E8F0FE',
    border: '1px solid lightgray'
  }
  if (rowLoading) {
    style.opacity = '.5'
  }
  const { width = 430, height = 290 } = style
  const overlayRef = useRef(null)

  const handleOnDoubleClick = (e) => {
    handleRowClick(item, index)
    e.stopPropagation()
    e.preventDefault()
  }

  const isSelected = selectedRow.filter((item) => item.id === id).length > 0
  const dragStyle = { ...style }
  const dragUrl =
    file_type === 'collection'
      ? fallbackSvg
      : asset_type?.includes('video')
      ? thumbnailUrl
      : url
  if (isSelected) {
    style.border = '5px solid #0645AD'
    style.height = `calc(${style.height} - 10px)`
    style.width = `calc(${style.width} - 10px)`
  }

  return (
    <Box
      style={style}
      className={classes.assetWrapper}
      draggable={!rowLoading && draggable}
      onDragStart={(event) => handleDragStart(event, item)}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      {file_type === 'collection' ? (
        <>
          <Box
            ref={overlayRef}
            className={classes.assetOverlay}
            onClick={(e) => onClickRow(item, e)}
            onDoubleClick={handleOnDoubleClick}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '5px'
              }}
            >
              <Box></Box>
              <Box
                id="assetOverlayHidden"
                className={classes.assetOverlayHidden}
              >
                <MenuButton
                  disabled={rowLoading}
                  options={menuOptions}
                  callBackData={item}
                  menuOpenCallback={onClickRow}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              height,
              width
            }}
          >
            <FileIcon
              s3Obj={s3Obj}
              row={item}
              fileIconClassName={classes.collectionFileIcon}
            />
            <Box
              style={{
                padding: '5px',
                backgroundColor: '#000000b3',
                color: 'white'
              }}
            >
              <EllipsisTooltip className={classes.assetName} text={file_name} />
              {created_by_user && (
                <EllipsisTooltip
                  className={classes.assetSubText}
                  text={created_by_user}
                />
              )}
              {created_at && (
                <EllipsisTooltip
                  className={classes.assetSubText}
                  text={convertIsoToDaysAgo(created_at)}
                />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              '#assetOverlayHidden': {
                opacity: isSelected ? '1 !important' : 0
              }
            }}
            ref={overlayRef}
            className={classes.assetOverlay}
            onClick={(e) => onClickRow(item, e)}
            onDoubleClick={handleOnDoubleClick}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '5px'
              }}
            >
              <FileIcon s3Obj={s3Obj} row={item} />
              <Box
                id="assetOverlayHidden"
                className={classes.assetOverlayHidden}
              >
                <MenuButton
                  disabled={rowLoading}
                  options={menuOptions}
                  callBackData={item}
                  menuOpenCallback={onClickRow}
                />
              </Box>
            </Box>
            <Box
              style={{
                padding: '5px',
                backgroundColor: '#000000b3',
                color: 'white'
              }}
              id="assetOverlayHidden"
              className={classes.assetOverlayHidden}
            >
              <EllipsisTooltip
                className={classes.assetName}
                text={item.file_name}
              />
              {created_by_user && (
                <EllipsisTooltip
                  className={classes.assetSubText}
                  text={created_by_user}
                />
              )}
              {created_at && (
                <EllipsisTooltip
                  className={classes.assetSubText}
                  text={convertIsoToDaysAgo(created_at)}
                />
              )}
            </Box>
          </Box>
          {asset_type?.includes('video') ? (
            <HoverVideoPlayer
              hoverTarget={overlayRef}
              id={'video' + index}
              className={classes.hoverVideo}
              videoSrc={url}
              loop={true}
              pausedOverlay={
                thumbnailUrl && (
                  <img src={thumbnailUrl} alt="" style={{ width, height }} />
                )
              }
            />
          ) : (
            <LazyLoadImage
              onError={({ currentTarget }) => {
                if (fallbackSvg) {
                  currentTarget.onerror = null
                  currentTarget.src = fallbackSvg
                }
              }}
              id={'img' + index}
              height={height}
              width={width}
              effect="blur"
              src={url}
            />
          )}
        </>
      )}
    </Box>
  )
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    arrow
    placement="right-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000',
    color: '#ffffff',
    fontSize: '14px',
    border: '1px solid #dadde9',
    maxWidth: 500
  }
}))

export default function ResourceTable(props) {
  const {
    s3Obj = {},
    handleDragEnd = () => {},
    rows = null,
    setRowsState = () => {},
    collectionsRows = null,
    setCollectionsRowsState = () => {},
    listWidth = 0
  } = props
  const classes = useStyles()
  const [page, setPage] = useState(null)
  const [pageData, setPageData] = useState(null)
  const [loading, setLoading] = useState('')
  const [hasMoreData, setHasMoreData] = useState(true)
  const [columnWidths, setColumnWidths] = useState({})
  const [isResizing, setIsResizing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [columns, setColumns] = useState([])
  const [hiddenColumns, setHiddenColumns] = useState([])
  const [visibleColumns, setVisibleColumns] = useState([])
  const validColumns = [
    'id',
    'tags',
    'file_name',
    'file_type',
    'file_extension',
    'created_by_user',
    'created_at',
    'status',
    'other_data'
  ]
  const defaultHidden = ['id', 'tags', 'other_data', 'file_type']
  const validDefaultColumns = [
    'file_name',
    'file_extension',
    'created_by_user',
    'created_at'
  ]
  const [sortOrder, setSortOrder] = useState({
    created_at: 'desc'
  })
  const [fetchingData, setFetchingData] = useState(false)
  const [tableLoading, setTableLoading] = useState(true)
  const pageSize = 30
  const scrollDivId = 'scrollableDivResourceTable'
  const [searchValue, setSearchValue] = useState('')
  const searchInputRef = useRef(null)

  const activeCollectionState = useSelector(
    (state) => state.resource?.activeCollection
  )

  const domainUsers = useSelector(
    (state) => state.authenticate.user?.domain?.domain_users || []
  )

  const user_name = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )
  const user_id = useSelector(
    (state) => state.authenticate.user?.domain?.id || ''
  )

  const user_email = useSelector(
    (state) => state.authenticate.user?.domain?.email || ''
  )
  const user_config_state = useSelector(
    (state) => state.authenticate.user?.domain?.user_config || {}
  )
  const tagsCenterStateTagsAll = useSelector((state) => state?.tagCenter?.tags)
  const [tagsCenterStateTags, setTagsCenterStateTags] = useState({})

  const collectionsList = useSelector((state) => state.resource?.collections)
  const expanded = useSelector((state) => state.resource?.expanded)

  const [tagsOptions, setTagsOptions] = useState(null)
  const [selectedTags, setSelectedTags] = useState(null)
  const [fileTypeOptions, setFileTypeOptions] = useState([])
  const [fileExternsionOptions, setFileExternsionOptions] = useState([])
  const [domainUserNames, setDomainUserNames] = useState([])
  const [domainUserNamesWoCurrentUser, setDomainUserNamesWoCurrentUser] =
    useState([])
  const [viewMode, setViewMode] = useState('table')
  const [addToCollectionData, setAddToCollectionData] = useState(null)
  const [showAddToCollection, setShowAddToCollection] = useState(false)
  const [imageGalleryImages, setImageGalleryImages] = useState([])
  const [aspectRatioArray, setAspectRatioArray] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)
  const [nextFile, setNextFile] = useState(null)
  const [prevFile, setPrevFile] = useState(null)
  const [searchCollection, setSearchCollection] = useState(null)
  const [selectedTagValues, setSelectedTagValues] = useState({})
  const [collectionEdit, setCollectionEdit] = useState(null)
  const [isFiltersApplied, setIsFiltersApplied] = useState(false)
  const [controllers, setControllers] = useState([])
  const isSuperUser = checkUserRoleSuperUser()
  const isAnnotator = checkUserRoleAnnotator()
  const isUserViewer = checkUserRoleViewer()
  const isAdmin = checkUserRoleAdmin()
  const [prevFilters, setPrevFilters] = useState({})
  const dispatch = useDispatch()
  const rowsRef = useRef(rows)
  const collectionsRowsRef = useRef(collectionsRows)
  const [selectedRow, setSelectedRow] = useState([])
  const [editRows, setEditRows] = useState(null)
  const [activeCollection, setActiveCollection] = useState({})
  const isRootCollection = activeCollection?.serverType !== 'collection'
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const visibleColumnPercentageTotal = useMemo(() => {
    let total = 0
    ;[...visibleColumns, 'menu_button'].forEach((column) => {
      total += columnWidths[column]
    })
    return total
  }, [columnWidths, visibleColumns])
  const [openAddNewKey, setOpenAddNewKey] = useState(false)
  const [eventShiftKey, setEventShiftKey] = useState(null)
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [documentTypeTags, setDocumentTypeTags] = useState([])
  const canCreateTags = hasAccess('tags', 'create')
  const [shareFile, setShareFile] = useState(null)
  const [, setRenderTrigger] = useState(false)

  const allRows = [...(collectionsRows || []), ...(rows || [])]

  const handleOpenAddNewKey = () => {
    setOpenAddNewKey(true)
  }

  const handleCloseAddNewKey = () => {
    setOpenAddNewKey(false)
  }

  useEffect(() => {
    rowsRef.current = rows
    setRenderTrigger((prev) => !prev)
  }, [rows])

  const setRows = (data) => {
    rowsRef.current = data
    setRowsState(data)
  }

  const setCollectionsRows = (data) => {
    collectionsRowsRef.current = data
    setCollectionsRowsState(data)
  }

  const { ConfirmDialog, showConfirmDialog } = useConfirmation()

  const createAbortController = () => {
    const abortController = new AbortController()
    setControllers((prevControllers) => [...prevControllers, abortController])
    return abortController
  }

  const stopAllRequests = () => {
    controllers.forEach((controller) => controller.abort())
  }

  const [totalPageSize, setTotalPageSize] = useState(0)
  const [totalCollectionsPageSize, setTotalCollectionsPageSize] = useState(0)

  useEffect(() => {
    if (!_.isEmpty(user_config_state)) {
      const {
        columnWidths = {},
        hiddenColumns,
        viewMode = 'table'
      } = user_config_state || {}
      setColumnWidths({ ...columnWidths })
      setHiddenColumns(hiddenColumns)
      setViewMode(viewMode)
    }
  }, [user_config_state])

  useEffect(() => {
    if (
      tagsCenterStateTagsAll &&
      !_.isEqual(
        Object.keys(tagsCenterStateTagsAll),
        Object.keys(tagsCenterStateTags)
      )
    ) {
      const filteredData = Object.keys(tagsCenterStateTagsAll || {}).reduce(
        (acc, key) => {
          const { key_type } = tagsCenterStateTagsAll[key] || {}
          if (key_type && key_type?.includes('document')) {
            acc[key] = tagsCenterStateTagsAll[key]
          }
          return acc
        },
        {}
      )
      setTagsCenterStateTags({ ...filteredData })
    }
  }, [tagsCenterStateTagsAll])

  useEffect(() => {
    const tagsValues = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        value_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      if (value_type === 'document_type') {
        setDocumentTypeTags(data)
      }
      data.forEach((element) => {
        if (element.value) {
          if (tagsValues[key]?.values) {
            tagsValues[key].values.push({
              value: element.id,
              label: element.value
            })
          } else {
            if (!tagsValues[key]) tagsValues[key] = {}
            tagsValues[key].values = [
              {
                value: element.id,
                label: element.value
              }
            ]
          }
        }
        tagsValues[key] = {
          ...tagsValues[key],
          type,
          ...rest
        }
      })
    })

    setTagsOptions(tagsValues)
  }, [tagsCenterStateTags])

  useEffect(() => {
    if (!_.isEmpty(activeCollectionState) && loading) {
      setActiveCollection({ ...activeCollectionState })
      const { serverType } = activeCollectionState || {}
      if (serverType === 'assetsImage' || serverType === 'assetsVideo') {
        setViewMode('grid')
      } else {
        setViewMode(user_config_state?.viewMode || 'table')
      }
      setTableLoading(true)
      setPageData(null)
      setPage(0)
      setRows(null)
      setCollectionsRows(null)
      setHasMoreData(false)
      initFileOptions(activeCollectionState)
      setEditRows(null)
      setSelectedRow([])
      const { clearSearch = false } = activeCollectionState || {}
      if (clearSearch) {
        setSelectedTags(null)
        setSortOrder({
          created_at: 'desc'
        })
      }
      setSearchCollection(activeCollectionState)
    }
  }, [loading])

  useEffect(() => {
    if (
      _.isEmpty(activeCollection) ||
      activeCollection?.id !== activeCollectionState?.id
    ) {
      setLoading(activeCollectionState?.id)
      if (activeCollectionState?.serverType === 'collection') {
        initBreadcrumbs(activeCollectionState)
      } else {
        setBreadcrumbs([])
      }
    } else {
      setActiveCollection({ ...activeCollectionState })
    }
  }, [activeCollectionState])

  const initBreadcrumbs = (activeCollectionState) => {
    const { id } = activeCollectionState || {}
    const breadcrumbs = findPathToChild(id, collectionsList)
    setBreadcrumbs(breadcrumbs)
  }

  const findPathToChild = (id, nestedObject) => {
    function recursiveFindPath(nodes, currentPath) {
      for (const node of nodes) {
        const newPath = [
          ...currentPath,
          {
            name: node.name,
            id: node.id
          }
        ]

        if (node.id === id) {
          return newPath
        }

        if (node.children && node.children.length > 0) {
          const foundPath = recursiveFindPath(node.children, newPath)
          if (foundPath) {
            return foundPath
          }
        }
      }

      return null
    }
    const result = recursiveFindPath(nestedObject, [])
    return result || null
  }

  useEffect(() => {
    if (page === 0) {
      fetchData(page, true)
      stopAllRequests()
    }
  }, [page])

  useEffect(() => {
    setPage(0)
  }, [])

  const initFileOptions = (activeCollection) => {
    let fileTypes = []
    let fileExtensions = []
    if (
      activeCollection?.serverType === 'proposal' ||
      activeCollection?.serverType === 'documents'
    ) {
      if (activeCollection?.serverType === 'documents') {
        fileTypes = [
          {
            value: 'document',
            label: 'Document'
          }
        ]
      } else {
        fileTypes = [
          {
            value: 'proposal',
            label: 'Proposal'
          }
        ]
      }
      fileExtensions = [
        {
          value: 'pdf',
          label: 'PDF'
        },
        {
          value: 'doc',
          label: 'DOC'
        },
        {
          value: 'ppt',
          label: 'PPT'
        }
      ]
    } else if (activeCollection?.serverType === 'assetsImage') {
      fileTypes = [
        {
          value: 'images',
          label: 'Images'
        },
        {
          value: 'proposal_images',
          label: 'Proposal Images'
        }
      ]
      fileExtensions = [
        {
          value: 'jpeg',
          label: 'JPEG'
        },
        {
          value: 'png',
          label: 'PNG'
        },
        {
          value: 'jpg',
          label: 'JPG'
        }
      ]
    } else if (activeCollection?.serverType === 'assetsVideo') {
      fileTypes = [
        {
          value: 'videos',
          label: 'Videos'
        }
      ]
      fileExtensions = [
        {
          value: 'mp4',
          label: 'MP4'
        }
      ]
    } else {
      fileTypes = [
        {
          value: 'proposal',
          label: 'Proposals'
        },
        {
          value: 'images',
          label: 'Images'
        },
        {
          value: 'videos',
          label: 'Videos'
        },
        {
          value: 'proposal_images',
          label: 'Proposal Images'
        },
        {
          value: 'document',
          label: 'Documents'
        }
      ]
      fileExtensions = [
        {
          value: 'jpeg',
          label: 'JPEG'
        },
        {
          value: 'png',
          label: 'PNG'
        },
        {
          value: 'mp4',
          label: 'MP4'
        },
        {
          value: 'jpg',
          label: 'JPG'
        },
        {
          value: 'pdf',
          label: 'PDF'
        },
        {
          value: 'doc',
          label: 'DOC'
        },
        {
          value: 'ppt',
          label: 'PPT'
        }
      ]
    }
    const userNames = domainUsers.map((user) => {
      return {
        value: user.id,
        label: user.user_name,
        email: user.email
      }
    })
    setDomainUserNamesWoCurrentUser(_.cloneDeep(userNames))

    userNames.push({
      value: user_id,
      label: user_name
    })

    setFileTypeOptions(fileTypes)
    setFileExternsionOptions(fileExtensions)
    setDomainUserNames(userNames)
  }

  const getDataFromLocal = (id) => {
    let data = localStorage.getItem('collectionFilterMap')
    data = data ? JSON.parse(data) : null
    return data?.[id]
  }

  const saveDataToLocal = (data, id) => {
    let dataMap = localStorage.getItem('collectionFilterMap')
    dataMap = dataMap ? JSON.parse(dataMap) : {}
    dataMap[id] = data
    localStorage.setItem('collectionFilterMap', JSON.stringify(dataMap))
  }

  useEffect(() => {
    if (activeCollection?.id) {
      if (selectedTags === null) {
        const data = getDataFromLocal(activeCollection?.id)
        if (!_.isEmpty(data)) {
          if (searchCollection?.id === activeCollection?.id) {
            setSelectedTags(data)
            const tag_values = []
            const tag_dates = []
            let file_type = {}
            let file_extension = {}
            let hidden_manually = {}
            data &&
              Object.keys(data).forEach((key) => {
                if (key === 'file_type') {
                  const { condition, values = [] } = data[key] || {}
                  const value = values.map((item) => item.value)
                  file_type = { condition: condition || 'is', values: value }
                } else if (key === 'file_extension') {
                  const { condition, values = [] } = data[key] || {}
                  const value = values.map((item) => item.value)
                  file_extension = {
                    condition: condition || 'is',
                    values: value
                  }
                } else if (key === 'hidden_from_search') {
                  const { condition, values = [] } = data[key] || {}
                  const value = values.map((item) => item.value)
                  hidden_manually = {
                    condition: condition || 'is',
                    values: value?.[0]
                  }
                } else {
                  const tags = []
                  let { condition, values = [] } = data[key] || {}
                  values = values || []
                  values.forEach((item) => {
                    if (item?.type?.includes('date')) {
                      tag_dates.push({ ...item, condition })
                    } else {
                      tags.push(item.value)
                    }
                  })
                  if (tags.length > 0) {
                    tag_values.push({
                      key,
                      condition,
                      values: tags
                    })
                  }
                }
              })
            const isFiltersApplied =
              !_.isEmpty(file_extension) ||
              !_.isEmpty(file_type) ||
              tag_values?.length > 0 ||
              !_.isEmpty(hidden_manually) ||
              tag_dates?.length > 0
            setIsFiltersApplied(isFiltersApplied)
          }
        }
      } else {
        saveDataToLocal(selectedTags, activeCollection?.id)
      }
    }
  }, [selectedTags, activeCollection])

  const fetchData = async (page, force) => {
    if (hasMoreData || force) {
      setFetchingData(true)
      const abortController = createAbortController()
      const type = activeCollection?.serverType
        ? activeCollection?.serverType
        : 'all'
      const { id: collectionId } = activeCollection
      const tag_values = []
      const tag_dates = []
      let file_type = {}
      let file_extension = {}
      let hidden_manually = {}
      selectedTags &&
        Object.keys(selectedTags).forEach((key) => {
          if (key === 'file_type') {
            const { condition, values = [] } = selectedTags[key] || {}
            const value = values.map((item) => item.value)
            file_type = { condition: condition || 'is', values: value }
          } else if (key === 'file_extension') {
            const { condition, values = [] } = selectedTags[key] || {}
            const value = values.map((item) => item.value)
            file_extension = { condition: condition || 'is', values: value }
          } else if (key === 'hidden_from_search') {
            const { condition, values = [] } = selectedTags[key] || {}
            const value = values.map((item) => item.value)
            hidden_manually = {
              condition: condition || 'is',
              values: value?.[0]
            }
          } else {
            const tags = []
            let { condition, values = [] } = selectedTags[key] || {}
            values = values || []
            values.forEach((item) => {
              if (item?.type?.includes('date')) {
                tag_dates.push({ ...item, condition })
              } else {
                tags.push(item.value)
              }
            })
            if (tags.length > 0) {
              tag_values.push({
                key,
                condition,
                values: tags
              })
            }
          }
        })
      setPrevFilters({
        searchValue,
        sortOrder,
        selectedTags,
        searchCollection
      })
      const req = {
        page_num: page + 1,
        page_size: pageSize,
        type,
        keyword: searchValue,
        tags: tag_values,
        tag_dates,
        file_type: _.isEmpty(file_type) ? null : file_type,
        file_extension: _.isEmpty(file_extension) ? null : file_extension,
        hidden_manually: _.isEmpty(hidden_manually) ? null : hidden_manually,
        sort_order: sortOrder
      }
      const isFiltersApplied =
        searchValue !== '' ||
        !_.isEmpty(file_extension) ||
        !_.isEmpty(file_type) ||
        tag_values?.length > 0 ||
        !_.isEmpty(hidden_manually) ||
        tag_dates?.length > 0
      setIsFiltersApplied(isFiltersApplied)
      if (type === 'collection') {
        req.identifier = collectionId
      }
      setPage(page + 1)
      const res = await postCollectionData(req, {
        signal: abortController.signal
      })
      if (res.status === 200) {
        const { data } = res
        const { has_next_page, data: dataToAdd, total_size } = data || {}
        setHasMoreData(has_next_page)
        setTotalPageSize(total_size)
        if (page === 0) {
          setPageData(dataToAdd)
        } else {
          setPageData((prev) => {
            if (prev) {
              return [...prev, ...dataToAdd]
            }
            return dataToAdd
          })
        }
        setLoading('')
        setTableLoading(false)
      } else if (res.code !== 'ERR_CANCELED') {
        const dataToAdd = []
        setHasMoreData(false)
        if (page === 0) {
          setPageData(dataToAdd)
        } else {
          setPageData((prev) => {
            if (prev) {
              return [...prev, ...dataToAdd]
            }
            return dataToAdd
          })
        }
        setLoading('')
        setTableLoading(false)
      }
    }
  }

  useEffect(() => {
    async function initalizeData() {
      if (pageData) {
        if (pageData?.length > 0) {
          const rows = []
          const aspectRatioArray = []
          pageData.forEach((item, index) => {
            const row = {}
            Object.keys(item).forEach((key) => {
              if (!validColumns.includes(key)) return
              // if (hiddenColumns.includes(key)) return
              row[key] = item[key]
            })
            rows.push(row)
          })
          await Promise.all(
            rows?.map(async (row, index) => {
              const { other_data = {}, file_type } = row || {}
              const { raw_location, thumbnail_location } = other_data || {}
              if (file_type === 'collection') return
              let image_data = {}
              if (
                ['asset', 'proposal image'].includes(file_type) &&
                raw_location
              ) {
                image_data = await extractDimensions(
                  other_data,
                  raw_location,
                  'image',
                  false
                )
              } else if (file_type === 'proposal' && thumbnail_location) {
                image_data = { width: 215, height: 145 }
              }
              const { width = 215, height = 145 } = image_data
              let aspectRatio = width / height
              aspectRatio = aspectRatio || 1
              aspectRatioArray[index] = aspectRatio
            })
          )
          setRows(rows)
          setAspectRatioArray(aspectRatioArray)
        } else {
          setRows([])
        }
        setFetchingData(false)
      }
    }
    initalizeData()
  }, [pageData])

  useEffect(() => {
    if (
      (collectionsRows?.length > 0 || rows?.length > 0) &&
      tagsCenterStateTags
    ) {
      initColumns()
    }
  }, [collectionsRows, rows, tagsCenterStateTags])

  useEffect(() => {
    if (hasMoreData) {
      const hasScroll = hasVerticalScrollbar(scrollDivId)
      if (!hasScroll) {
        fetchData(page)
      }
    }
  }, [rows, viewMode])

  const initCollectionsRows = async () => {
    let collections = []
    if (activeCollection?.serverType === 'all') {
      collectionsList?.forEach((item, index) => {
        collections.push({
          id: item.id,
          file_name: item.name,
          file_type: 'collection',
          file_extension: '',
          created_by_user: item.created_by_user,
          created_at: item.created_at,
          other_data: {
            icon: item.icon,
            color: item.color,
            parent_id: item.parent_id,
            description: item.description,
            loading: item.status === 'loading',
            folder_rights: item.folder_rights,
            is_public: item.is_public,
            access_config: item.access_config
          }
        })
      })
    } else if (activeCollection?.serverType === 'sharedCollections') {
      const sharedCollections = collectionsList?.filter(
        (data) => data?.folder_rights === 'shared'
      )
      sharedCollections.forEach((item, index) => {
        collections.push({
          id: item.id,
          file_name: item.name,
          file_type: 'collection',
          file_extension: '',
          created_by_user: item.created_by_user,
          created_at: item.created_at,
          other_data: {
            icon: item.icon,
            color: item.color,
            parent_id: item.parent_id,
            description: item.description,
            loading: item.status === 'loading',
            folder_rights: item.folder_rights,
            is_public: item.is_public,
            access_config: item.access_config
          }
        })
      })
    } else if (activeCollection?.serverType === 'collection') {
      const { children = [] } = activeCollection || {}
      children?.forEach((item, index) => {
        collections.push({
          id: item.id,
          file_name: item.name,
          file_type: 'collection',
          file_extension: '',
          created_by_user: item.created_by_user,
          created_at: item.created_at,
          other_data: {
            icon: item.icon,
            color: item.color,
            parent_id: item.parent_id,
            description: item.description,
            folder_rights: item.folder_rights,
            loading: item.status === 'loading',
            is_public: item.is_public,
            access_config: item.access_config
          }
        })
      })
    } else {
      collections = null
    }
    collections?.sort((a, b) => {
      for (const key in sortOrder) {
        const order = sortOrder[key]
        const aValue = a[key]
        const bValue = b[key]
        if (order === 'asc') {
          if (aValue < bValue) return -1
          if (aValue > bValue) return 1
        } else if (order === 'desc') {
          if (aValue > bValue) return -1
          if (aValue < bValue) return 1
        }
      }
      return 0
    })
    setCollectionsRows(collections)
    setTotalCollectionsPageSize(collections?.length)
  }

  useEffect(() => {
    initCollectionsRows()
  }, [sortOrder, collectionsList, activeCollection])

  const initColumns = () => {
    let columns = []
    let hiddenColumnsArray = [...hiddenColumns]
    collectionsRows &&
      Object.keys(collectionsRows[0] || {}).forEach((key) => {
        if (validColumns.includes(key) && !defaultHidden.includes(key)) {
          columns.push(key)
        }
      })
    pageData &&
      Object.keys(pageData[0] || {}).forEach((key) => {
        if (validColumns.includes(key) && !defaultHidden.includes(key)) {
          columns.push(key)
        }
      })
    rows &&
      Object.keys(rows[0] || {}).forEach((key) => {
        if (validColumns.includes(key) && !defaultHidden.includes(key)) {
          columns.push(key)
        }
      })
    tagsCenterStateTags &&
      Object.keys(tagsCenterStateTags || {}).forEach((key) => {
        columns.push(key)
        hiddenColumnsArray.push(key)
      })

    columns = _.uniq(columns)
    hiddenColumnsArray = _.uniq(hiddenColumnsArray)
    const orderedColumns = []
    const { columns: configColumns = [] } = user_config_state || {}
    configColumns.forEach((item) => {
      if (columns.includes(item)) {
        orderedColumns.push(item)
      }
    })
    columns.forEach((item) => {
      if (!orderedColumns.includes(item)) {
        orderedColumns.push(item)
      }
    })
    setColumns(orderedColumns)
    if (_.isEmpty(hiddenColumns) && !_.isEmpty(hiddenColumnsArray)) {
      setHiddenColumns(hiddenColumnsArray)
    }
  }

  useEffect(() => {
    const visibleColumns = columns.filter(
      (item) => !hiddenColumns.includes(item) && !defaultHidden.includes(item)
    )
    setVisibleColumns(visibleColumns)
    initColumnsWidth(visibleColumns)
  }, [hiddenColumns, columns])

  const initColumnsWidth = (visibleColumns) => {
    if (visibleColumns.length === 0) return
    const newWidths = columnWidths
    visibleColumns = [...visibleColumns, 'menu_button']
    visibleColumns.forEach((item) => {
      if (!Object.keys(columnWidths).includes(item)) {
        if (item === 'file_name') {
          newWidths[item] = 20
        } else if (validDefaultColumns.includes(item)) {
          newWidths[item] = 10
        } else if (item === 'menu_button') {
          newWidths[item] = 5
        } else {
          newWidths[item] = 8
        }
      }
    })
    if (!_.isEmpty(newWidths)) {
      setColumnWidths(newWidths)
    }
  }

  const handleResize = (column, width) => {
    if (width > 10) {
      setColumnWidths({
        ...columnWidths,
        [column]: width
      })
    }
  }

  const getMenuOptions = (row) => {
    const { file_type, other_data = {} } = row || {}
    const hideFlag = !!other_data?.hidden_manually
    let options = []
    if (isAnnotator) {
      if (file_type === 'proposal') {
        options = [
          {
            label: 'Open in Annotator',
            icon: <DriveFileRenameOutlineIcon />,
            onClick: handleOpenAnnoator
          },
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleFileSelect
          }
        ]
      } else if (
        file_type === 'image' ||
        file_type === 'video' ||
        file_type === 'proposal image'
      ) {
        options = [
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleFileSelect
          }
        ]
      } else if (file_type === 'collection') {
        options = [
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleOpenCollection
          }
        ]
      } else if (file_type === 'document') {
        options = [
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleFileSelect
          }
        ]
      }
    } else if (isUserViewer) {
      if (file_type === 'proposal') {
        options = [
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleFileSelect
          },
          {
            label: 'Download Proposal',
            icon: <DownloadIcon />,
            onClick: handleDownload
          }
        ]
      } else if (
        file_type === 'image' ||
        file_type === 'video' ||
        file_type === 'proposal image'
      ) {
        options = [
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleFileSelect
          },
          {
            label: 'Download Asset',
            icon: <DownloadIcon />,
            onClick: handleDownload
          }
        ]
      } else if (file_type === 'collection') {
        const { folder_rights = {} } = other_data || {}
        options = [
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleOpenCollection
          }
        ]
      } else if (file_type === 'document') {
        options = [
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleFileSelect
          },
          {
            label: 'Download Document',
            icon: <DownloadIcon />,
            onClick: handleDownload
          }
        ]
      }

      if (viewMode === 'grid') {
        options = options.filter((item) => item.name !== 'Edit Row')
      }
      return options
    } else {
      if (file_type === 'proposal') {
        options = [
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleFileSelect
          },
          {
            label: 'Edit Row',
            icon: <DriveFileRenameOutlineIcon />,
            onClick: handleEditRow
          },
          {
            label: 'Add to collection',
            icon: <CreateNewFolderIcon />,
            onClick: singleAddToCollectionData
          },
          {
            label: 'Restrict Access',
            icon: <KeyIcon />,
            onClick: handleShare
          },
          {
            label: 'Download Proposal',
            icon: <DownloadIcon />,
            onClick: handleDownload
          },
          {
            label: 'Delete',
            icon: <DeleteIcon />,
            onClick: handleDeleteProposal
          },
          {
            label: hideFlag
              ? 'Unhide from Search & Chat'
              : 'Hide from Search & Chat',
            icon: hideFlag ? <SearchIcon /> : <SearchOffIcon />,
            onClick: handleSingleHide
          }
        ]
        if (isSuperUser) {
          options.push({
            label: 'Open in Annotator',
            icon: <DriveFileRenameOutlineIcon />,
            onClick: handleOpenAnnoator
          })
        }
        const { serverType, folder_rights } = activeCollection
        if (
          ['owner', 'shared'].includes(folder_rights) &&
          serverType === 'collection' &&
          !isRootCollection
        ) {
          options.push({
            label: 'Remove from collection',
            icon: <FolderDeleteIcon />,
            onClick: singleRemoveToCollectionData
          })
        }
      } else if (
        file_type === 'image' ||
        file_type === 'video' ||
        file_type === 'proposal image'
      ) {
        options = [
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleFileSelect
          },
          {
            label: 'Edit Row',
            icon: <DriveFileRenameOutlineIcon />,
            onClick: handleEditRow
          },
          {
            label: 'Add to collection',
            icon: <CreateNewFolderIcon />,
            onClick: singleAddToCollectionData
          },
          {
            label: 'Restrict Access',
            icon: <KeyIcon />,
            onClick: handleShare
          },
          {
            label: 'Download Asset',
            icon: <DownloadIcon />,
            onClick: handleDownload
          },
          {
            label: hideFlag
              ? 'Unhide from Search & Chat'
              : 'Hide from Search & Chat',
            icon: hideFlag ? <SearchIcon /> : <SearchOffIcon />,
            onClick: handleSingleHide
          },
          {
            label: 'Delete',
            icon: <DeleteIcon />,
            onClick: handleDeleteAsset
          }
        ]
        const { serverType, folder_rights } = activeCollection
        if (
          ['owner', 'shared'].includes(folder_rights) &&
          serverType === 'collection'
        ) {
          options.push({
            label: 'Remove from Collection',
            icon: <FolderDeleteIcon />,
            onClick: singleRemoveToCollectionData
          })
        }
      } else if (file_type === 'collection') {
        const { folder_rights = {} } = other_data || {}
        if (['owner', 'shared'].includes(folder_rights)) {
          options = [
            {
              label: 'Open',
              icon: <OpenInNewIcon />,
              onClick: handleOpenCollection
            },
            {
              label: 'Edit Row',
              icon: <DriveFileRenameOutlineIcon />,
              onClick: handleEditRow
            },
            {
              label: hideFlag
                ? 'Unhide from Search & Chat'
                : 'Hide from Search & Chat',
              icon: hideFlag ? <SearchIcon /> : <SearchOffIcon />,
              onClick: handleSingleHide
            },
            {
              label: 'Delete',
              icon: <DeleteIcon />,
              onClick: handleDeleteCollection
            }
          ]
          if (folder_rights === 'owner') {
            options.push({
              label: 'Edit Collection Properties',
              icon: <EditIcon />,
              onClick: setCollectionEdit
            })
          }
        } else {
          options = [
            {
              label: 'Open',
              icon: <OpenInNewIcon />,
              onClick: handleOpenCollection
            }
          ]
        }
      } else if (file_type === 'document') {
        options = [
          {
            label: 'Open',
            icon: <OpenInNewIcon />,
            onClick: handleFileSelect
          },
          {
            label: 'Edit Row',
            icon: <DriveFileRenameOutlineIcon />,
            onClick: handleEditRow
          },
          {
            label: 'Add to collection',
            icon: <CreateNewFolderIcon />,
            onClick: singleAddToCollectionData
          },
          {
            label: 'Restrict Access',
            icon: <KeyIcon />,
            onClick: handleShare
          },
          {
            label: 'Download Document',
            icon: <DownloadIcon />,
            onClick: handleDownload
          },
          {
            label: hideFlag
              ? 'Unhide from Search & Chat'
              : 'Hide from Search & Chat',
            icon: hideFlag ? <SearchIcon /> : <SearchOffIcon />,
            onClick: handleSingleHide
          },
          {
            label: 'Delete',
            icon: <DeleteIcon />,
            onClick: handleDeleteDocument
          }
        ]
        const { serverType, folder_rights } = activeCollection
        if (
          ['owner', 'shared'].includes(folder_rights) &&
          serverType === 'collection'
        ) {
          options.push({
            label: 'Remove from Collection',
            icon: <FolderDeleteIcon />,
            onClick: singleRemoveToCollectionData
          })
        }
      }

      if (viewMode === 'grid') {
        options = options.filter((item) => item.name !== 'Edit Row')
      }
      return options
    }
  }

  const handleSingleHide = async (row) => {
    if (isUserViewer) {
      toast.error("You don't have permission to hide/unhide the document")
      return
    }
    const { id, file_type, other_data = {} } = row
    const hideFlag = !!other_data?.hidden_manually
    showConfirmDialog({
      onConfirm: async () => {
        const documents = [
          {
            id,
            file_type
          }
        ]
        let rowsCurrent = rowsRef.current
        const index = rowsCurrent.findIndex((item) => item.id === id)
        rowsCurrent[index] = {
          ...rowsCurrent[index],
          other_data: {
            ...rowsCurrent[index].other_data,
            loading: true
          }
        }
        setRows([...rowsCurrent])
        const req = {
          documents,
          action: 'hide',
          data: !hideFlag
        }
        const res = await multiDocuments(req)
        if (res.status === 200) {
          toast.success('Document hidden successfully')
          rowsCurrent = rowsRef.current
          rowsCurrent[index] = {
            ...rowsCurrent[index],
            other_data: {
              ...rowsCurrent[index].other_data,
              loading: false,
              hidden_manually: !hideFlag
            }
          }
          setRows([...rowsCurrent])
          const indexSelect = selectedRow.findIndex((item) => item.id === id)
          if (indexSelect !== -1) {
            const selectedRowCopy = [...selectedRow]
            selectedRowCopy[indexSelect] = {
              ...selectedRowCopy[indexSelect],
              other_data: {
                ...selectedRowCopy[indexSelect].other_data,
                hidden_manually: !hideFlag
              }
            }
            setSelectedRow(selectedRowCopy)
          }
        } else {
          toast.error('Something went wrong while hiding the document')
          rowsCurrent = rowsRef.current
          rowsCurrent[index] = {
            ...rowsCurrent[index],
            other_data: {
              ...rowsCurrent[index].other_data,
              loading: false
            }
          }
          setRows([...rowsCurrent])
        }
      },
      confirmationMessageTitle: `Are you sure you want to hide "${
        row?.file_name || ''
      }" ?`
    })
  }

  const handleDeleteDocument = async (row) => {
    if (isUserViewer) {
      toast.error("You don't have permission to delete the document")
      return
    }
    showConfirmDialog({
      onConfirm: async () => {
        const { id: documentId } = row
        let rowsCurrent = rowsRef.current
        let index = rowsCurrent.findIndex((item) => item.id === documentId)
        rowsCurrent[index] = {
          ...rowsCurrent[index],
          other_data: {
            ...rowsCurrent[index].other_data,
            loading: true
          }
        }
        setRows([...rowsCurrent])
        const response = await deleteDocument(documentId)
        if (response.status === 200) {
          rowsCurrent = rowsRef.current
          index = rowsCurrent.findIndex((item) => item.id === documentId)
          toast.success('Document deleted successfully')
          rowsCurrent.splice(index, 1)
          setRows([...rowsCurrent])
        } else {
          toast.error('Something went wrong while deleting the document')
          rowsCurrent = rowsRef.current
          index = rowsCurrent.findIndex((item) => item.id === row.id)
          rowsCurrent[index] = {
            ...rowsCurrent[index],
            other_data: {
              ...rowsCurrent[index].other_data,
              loading: false
            }
          }
          setRows([...rowsCurrent])
        }
      },
      confirmationMessageTitle: `Are you sure you want to delete the document "${
        row?.file_name || ''
      }" ?`
    })
  }

  const singleRemoveToCollectionData = async (row) => {
    const {
      serverType,
      name: collectionName,
      id: activeCollectionId
    } = activeCollection
    if (serverType !== 'collection') return
    showConfirmDialog({
      onConfirm: async () => {
        let rowsCurrent = rowsRef.current
        let index = rowsCurrent.findIndex((item) => item.id === row.id)
        rowsCurrent[index] = {
          ...rowsCurrent[index],
          other_data: {
            ...rowsCurrent[index].other_data,
            loading: true
          }
        }
        setRows([...rowsCurrent])
        const req = {
          collection_ids: [activeCollectionId],
          documents: [row]
        }
        const response = await deleteFromCollection(req)
        if (response.status === 200) {
          rowsCurrent = rowsRef.current
          index = rowsCurrent.findIndex((item) => item.id === row.id)
          rowsCurrent.splice(index, 1)
          setRows([...rowsCurrent])
          toast.success('File removed from collection successfully')
        } else {
          toast.error(
            'Something went wrong while removing the file from collection'
          )
          rowsCurrent = rowsRef.current
          index = rowsCurrent.findIndex((item) => item.id === row.id)
          rowsCurrent[index] = {
            ...rowsCurrent[index],
            other_data: {
              ...rowsCurrent[index].other_data,
              loading: false
            }
          }
          setRows([...rowsCurrent])
        }
      },
      confirmationMessageTitle: `Are you sure you want to remove "${
        row?.file_name || ''
      }" from the collection  "${collectionName || ''}" ?`
    })
  }

  const renderTagDrawer = () => {
    return (
      <Drawer
        anchor={'right'}
        open={showTagDrawer}
        onClose={() => setShowTagDrawer(false)}
        disableEnforceFocus={true}
      >
        <Box sx={{ padding: '20px', width: '400px' }}>
          <TagCenterUpdate
            isOnlySelect={true}
            filterTags="document"
            selectCallback={(e) => handleMultiTags(e)}
            cancelCallback={() => setShowTagDrawer(false)}
          />
        </Box>
      </Drawer>
    )
  }

  const handleDeleteProposal = async (row) => {
    if (isUserViewer) {
      toast.error("You don't have permission to delete the proposal")
      return
    }
    showConfirmDialog({
      onConfirm: async () => {
        const { id: proposalId } = row
        let rowsCurrent = rowsRef.current
        let index = rowsCurrent.findIndex((item) => item.id === proposalId)
        rowsCurrent[index] = {
          ...rowsCurrent[index],
          other_data: {
            ...rowsCurrent[index].other_data,
            loading: true
          }
        }
        setRows([...rowsCurrent])
        const response = await deleteProposals(proposalId)
        if (response.status === 200) {
          rowsCurrent = rowsRef.current
          index = rowsCurrent.findIndex((item) => item.id === proposalId)
          rowsCurrent.splice(index, 1)
          setRows([...rowsCurrent])
          toast.success('Proposal deleted successfully')
        } else {
          toast.error('Something went wrong while deleting the proposal')
          rowsCurrent = rowsRef.current
          index = rowsCurrent.findIndex((item) => item.id === proposalId)
          rowsCurrent[index] = {
            ...rowsCurrent[index],
            other_data: {
              ...rowsCurrent[index].other_data,
              loading: false
            }
          }
          setRows([...rowsCurrent])
        }
      },
      confirmationMessageTitle: `Are you sure you want to delete the proposal "${
        row?.file_name || ''
      }" ?`,
      confirmationMessage:
        'This action will also remove the proposal from all the collections.'
    })
  }
  const handleDeleteAsset = async (row) => {
    if (isUserViewer) {
      toast.error("You don't have permission to delete the asset")
      return
    }
    showConfirmDialog({
      onConfirm: async () => {
        const { id: assetId } = row
        let rowsCurrent = rowsRef.current
        let index = rowsCurrent.findIndex((item) => item.id === assetId)
        rowsCurrent[index] = {
          ...rowsCurrent[index],
          other_data: {
            ...rowsCurrent[index].other_data,
            loading: true
          }
        }
        setRows([...rowsCurrent])
        const response = await deleteAsset({ asset_id: [assetId] })
        if (response.status === 200) {
          rowsCurrent = rowsRef.current
          index = rowsCurrent.findIndex((item) => item.id === assetId)
          toast.success('Asset deleted successfully')
          rowsCurrent.splice(index, 1)
          setRows([...rowsCurrent])
        } else {
          toast.error('Something went wrong while deleting the asset')
          rowsCurrent = rowsRef.current
          index = rowsCurrent.findIndex((item) => item.id === assetId)
          rowsCurrent[index] = {
            ...rowsCurrent[index],
            other_data: {
              ...rowsCurrent[index].other_data,
              loading: false
            }
          }
          setRows([...rowsCurrent])
        }
      },
      confirmationMessageTitle: `Are you sure you want to delete the asset "${
        row?.file_name || ''
      }" ?`,
      confirmationMessage:
        'The action will also remove the asset from all the collections'
    })
  }

  const handleDeleteCollection = async (row) => {
    if (isUserViewer) {
      toast.error("You don't have permission to delete the collection")
      return
    }
    showConfirmDialog({
      onConfirm: () => {
        const { id } = row
        dispatch(deleteCollection({ id }))
      },
      confirmationMessageTitle: `Are you sure you want to delete the collection "${
        row?.file_name || ''
      }" ?`,
      confirmationMessage:
        'This action will delete all subcollections within this collection, and any private files that are not part of other collections will be permanently deleted.'
    })
  }
  const handleDownload = async (row) => {
    const { file_name, other_data, file_extension = '' } = row
    const { download_location = '' } = other_data || {}

    let updatedFileName = file_name

    if (file_extension) {
      if (!file_name.endsWith(`.${file_extension}`)) {
        updatedFileName = `${file_name}.${file_extension}`
      }
    }
    if (download_location && updatedFileName) {
      initalizeDownload(download_location, updatedFileName, s3Obj)
    }
  }

  const handleOpenAnnoator = (row) => {
    const { id } = row
    const path = `/view/pdf/${id}/0/${true}`
    window.open(path, '_blank')
  }

  const singleAddToCollectionData = (row) => {
    if (isUserViewer) {
      toast.error("You don't have permission to add files to collection")
      return
    }
    setAddToCollectionData([row])
    setShowAddToCollection(true)
  }

  const multiAddToCollectionData = () => {
    if (isUserViewer) {
      toast.error("You don't have permission to add files to collection")
      return
    }
    setAddToCollectionData(selectedRow)
    setShowAddToCollection(true)
  }

  const addToCollection = async (collections, action = 'copy') => {
    if (_.isEmpty(collections)) {
      toast.error('Please select a collection')
      return
    }
    const {
      is_public,
      id: to_collection_id,
      name: collection_name
    } = collections[0]
    const { id } = activeCollection
    const isFromRoot = id === 'all'
    const from_collection_id = id === 'all' ? 'None' : id

    if (to_collection_id === from_collection_id) {
      toast.error(
        'The selected files are already in the destination collection'
      )
      return
    }
    let messageTitle = `Are you sure you want to ${action} the selected file to a ${
      is_public ? 'public' : 'private'
    } collection "${collection_name}"?`
    let message = `${
      action === 'copy' ? 'Copying' : 'Moving'
    } will inherit the access rights of the destination collection.`
    if (to_collection_id === 'None') {
      messageTitle = `Are you sure you want to ${action} the selected file to the root collection?`
      message = `${
        action === 'copy' ? 'Copying' : 'Moving'
      } to root will make the file public.`
    }
    showConfirmDialog({
      confirmationMessageTitle: messageTitle,
      confirmationMessage: message,
      onConfirm: async () => {
        const documents = addToCollectionData.map((item) => {
          return {
            id: item.id,
            file_type: item.file_type,
            file_name: item.file_name
          }
        })
        const req = {
          to_collection_id,
          documents,
          from_collection_id
        }
        const api =
          action === 'copy' ? copyFilesToCollection : moveFilesToCollection
        const res = await api(req)
        if (res.status === 200) {
          toast.success(
            `${action === 'copy' ? 'Copied' : 'Moved'} successfully`
          )
          if (action === 'move' && !isFromRoot) {
            const rowsCurrent = rowsRef.current
            const newRows = rowsCurrent.filter(
              (item) => !addToCollectionData.find((doc) => doc.id === item.id)
            )
            setRows(newRows)
          }
        } else {
          toast.error('Something went wrong while adding the files')
        }
        setAddToCollectionData([])
      },
      onCancel: () => {
        setAddToCollectionData([])
      }
    })
  }

  const handleFileSelect = (row) => {
    setSelectedFile(row)
    const { id } = row || {}
    const index = rows.findIndex((item) => item.id === id)
    if (index >= 0) {
      if (index > 0) {
        setPrevFile(rows[index - 1])
      } else {
        setPrevFile(null)
      }
      if (index < rows.length - 1) {
        setNextFile(rows[index + 1])
      } else {
        setNextFile(null)
      }
    }
  }

  const getColWidth = (width, key) => {
    if (width) {
      return width + '%'
    } else return 'auto'
  }

  const TableLoader = ({ count, viewMode, hideHeader = false }) => {
    return viewMode === 'table' ? (
      <table
        className={classes.tableWrapper}
        style={{ width: visibleColumnPercentageTotal + '%' }}
      >
        {!hideHeader && (
          <thead>
            <tr>
              {visibleColumns.map((column, index) => {
                const width = getColWidth(columnWidths[column], column)
                return (
                  <th
                    style={{ width }}
                    key={index}
                    className={classes.tableHeaderLoader}
                  >
                    <Skeleton
                      variant="rectangular"
                      height={25}
                      sx={{ marginBottom: '5px' }}
                    />
                  </th>
                )
              })}
            </tr>
          </thead>
        )}
        <tbody>
          {[...Array(count)].map((item, index) => (
            <tr key={index}>
              {visibleColumns.map((column, index) => {
                const width = getColWidth(columnWidths[column], column)
                return (
                  <td
                    className={classes.tableRowLoader}
                    key={index}
                    style={{ width }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={'100%'}
                      height={25}
                      sx={{ marginBottom: '5px' }}
                    />
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          padding: '20px 50px 20px 50px',
          justifyContent: 'space-between'
        }}
      >
        {[...Array(count)].map((item, index) => (
          <Skeleton
            variant="rectangular"
            key={index}
            width={150}
            height={150}
            sx={{ marginBottom: '5px' }}
          />
        ))}
      </Box>
    )
  }

  const handleSort = (column, order) => {
    const newOrder = _.cloneDeep(sortOrder)
    if (newOrder?.[column] && newOrder[column] === order) {
      delete newOrder[column]
    } else {
      newOrder[column] = order
    }
    setSortOrder(newOrder)
    setPage(0)
    setTableLoading(true)
  }

  const GetSortIcon = ({ id, isSelected, order }) => {
    return (
      <Box className={classes.sortLabel}>
        <IconButton
          disableRipple
          disabled={tableLoading}
          onClick={() => {
            handleSort(id, 'asc')
          }}
        >
          <SvgIcon
            className={
              isSelected && order === 'asc'
                ? classes.sortIconActive
                : classes.sortIcon
            }
          >
            <SortUp />
          </SvgIcon>
        </IconButton>
        <IconButton
          disableRipple
          disabled={tableLoading}
          onClick={() => {
            handleSort(id, 'desc')
          }}
        >
          <SvgIcon
            className={
              isSelected && order === 'desc'
                ? classes.sortIconActive
                : classes.sortIcon
            }
          >
            <SortDown />
          </SvgIcon>
        </IconButton>
      </Box>
    )
  }

  const handleSortColumnClick = (column) => {
    const order =
      sortOrder[column] === 'asc'
        ? 'desc'
        : sortOrder[column] === 'desc'
        ? ''
        : 'asc'
    handleSort(column, order)
  }

  const hasVerticalScrollbar = (elementId) => {
    const element = document.getElementById(elementId)
    if (!element) {
      return false
    }

    return element.scrollHeight > element.clientHeight
  }

  const handleSearchQuery = (reffer, newSelectedTags = selectedTags) => {
    if (reffer === 'tags') {
      const newFilters = {
        searchValue,
        sortOrder,
        newSelectedTags,
        searchCollection
      }
      if (_.isEqual(newFilters, prevFilters)) {
        return
      } else {
        setSelectedRow([])
        setPage(0)
      }
    } else if (reffer === 'clear') {
      setSelectedRow([])
      setPage(0)
    } else {
      if (searchInputRef.current) {
        searchInputRef.current.blur()
      }
      if (searchCollection) {
        setPage(0)
        setSelectedRow([])
      } else {
        dispatch(
          updateActiveCollection({
            id: 'all',
            label: 'All Files',
            type: 'defaultPrelist',
            serverType: 'all',
            clearSearch: false
          })
        )
      }
    }
    setTableLoading(true)
  }

  useEffect(() => {
    if (selectedTags !== null) {
      handleSearchQuery('tags', selectedTags)
    }
  }, [selectedTags])

  const handleShowImage = async (screenshots) => {
    await Promise.all(
      screenshots.map(async (img, index) => {
        const signedSrc = await checkExpiry(img.src, s3Obj)
        screenshots[index].src = signedSrc
      })
    )
    setImageGalleryImages(screenshots)
  }

  const bricks = (array, parentProps, parentArray) => {
    return array.map((item, index) => {
      return (
        <RenderAsset
          itemProps={item}
          item={parentArray[index]}
          parentProps={parentProps}
          key={index}
          index={index}
          s3Obj={s3Obj}
        />
      )
    })
  }

  const onClickRow = (row, event) => {
    if (event) {
      if (event.metaKey || event.key === 'Meta') {
        if (selectedRow.findIndex((item) => item.id === row.id) === -1) {
          setSelectedRow([...selectedRow, row])
        } else {
          setSelectedRow(selectedRow.filter((item) => item.id !== row.id))
        }
      } else if (event.shiftKey) {
        if (eventShiftKey) {
          const start = allRows.findIndex(
            (item) => item.id === eventShiftKey.id
          )
          const end = allRows.findIndex((item) => item.id === row.id)
          const shiftSelected = allRows.slice(
            Math.min(start, end),
            Math.max(start, end) + 1
          )
          setSelectedRow([...shiftSelected])
        } else {
          setSelectedRow([...selectedRow, row])
        }
      } else {
        const index = selectedRow.findIndex((item) => item.id === row.id)
        if (index === -1) {
          setSelectedRow([row])
        } else {
          setSelectedRow([])
        }
      }
    } else {
      const index = selectedRow.findIndex((item) => item.id === row.id)
      if (index === -1) {
        setSelectedRow([row])
      } else {
        setSelectedRow([])
      }
    }
    setEditRows(null)
  }

  useEffect(() => {
    if (selectedRow.length === 1) {
      setEventShiftKey(selectedRow[0])
    }
  }, [selectedRow])

  const handleEditRow = (row) => {
    if (isUserViewer) {
      toast.error("You don't have permission to edit the row")
      return
    }
    setEditRows(row.id)
  }

  const handleRowClick = (row) => {
    !isResizing && handleFileSelect(row)
  }

  const handleOpenCollection = async (item) => {
    if (isResizing) return
    const { id } = item
    let collection = {}
    if (activeCollection?.serverType === 'collection') {
      const { children } = activeCollection || {}
      const index = children?.findIndex((item) => item.id === id)
      if (index >= 0) {
        collection = children[index]
      }
    } else {
      const index = collectionsList.findIndex((item) => item.id === id)
      if (index >= 0) {
        collection = collectionsList[index]
      }
    }
    if (!_.isEmpty(collection)) {
      dispatch(
        updateActiveCollection({
          ...collection,
          serverType: 'collection',
          clearSearch: true
        })
      )
      const { children, type, hasChildren = false } = collection
      if (type === 'collection' && hasChildren) {
        if (children) {
          dispatch(setExpandedObj({ ...expanded, [id]: true }))
        } else {
          dispatch(getCollectionChildren(id))
          dispatch(setExpandedObj({ ...expanded, [id]: true }))
        }
      }
    }
  }

  const handleUploaderClick = () => {
    dispatch(
      setUploader({
        uploaderType: 'resource',
        showUploader: true,
        fullScreen: true,
        callback: uploaderCallback,
        activeCollection
      })
    )
  }

  const uploaderCallback = useCallback(
    (data, mode) => {
      const rowData = rowsRef.current || []
      if (mode === 'onComplete') {
        const { successful = [] } = data || {}
        const newRows = []
        successful.forEach((file) => {
          const { extension, meta, serverFileId, type, s3Multipart, id } = file
          const { name, selectedTags, document_type, collection_id } =
            meta || {}

          let processFile = false
          const { serverType } = activeCollection

          switch (serverType) {
            case 'collection':
              processFile = collection_id.includes(activeCollection?.id)
              break

            case 'proposal':
              processFile = document_type === 'proposal'
              break

            case 'assetsImage':
              processFile = ['jpeg', 'png', 'jpg'].includes(extension)
              break

            case 'assetsVideo':
              processFile = ['mp4'].includes(extension)
              break

            case 'documents':
              processFile = document_type === 'document'
              break

            case 'all':
            case 'sharedCollections':
            case 'myUploads':
              processFile = true
              break

            default:
              processFile = false
              break
          }

          if (!processFile) return

          const { key: s3key = '' } = s3Multipart || {}
          const tags = []
          Object.keys(selectedTags || {}).forEach((key) => {
            selectedTags[key].forEach((item) => {
              if (_.isArray(selectedTags[key])) {
                tags.push({
                  tag_key: key,
                  tag_value: item.label,
                  id: item.value
                })
              }
            })
          })
          if (['pdf', 'pptx', 'docx', 'doc', 'ppt'].includes(extension)) {
            const file_type =
              document_type?.label?.toLowerCase() === 'proposal'
                ? 'proposal'
                : 'document'
            const fileObj = {
              id: serverFileId,
              created_at: new Date().toISOString(),
              created_by_user: user_name,
              file_name: name,
              file_type,
              tags,
              isRecent: true,
              file_extension: extension
            }
            if (s3key) {
              const location = `https://${process.env.REACT_APP_PDF_BUCKET}.s3.amazonaws.com/${s3key}`
              fileObj.other_data = {
                raw_location: location,
                download_location: location
              }
            }
            newRows.push(fileObj)
          } else {
            const file_type = ['mp4'].includes(extension) ? 'video' : 'image'
            const fileObj = {
              id: serverFileId,
              created_at: new Date().toISOString(),
              created_by_user: user_name,
              file_name: name,
              file_type,
              tags,
              isRecent: true,
              file_extension: extension
            }
            if (s3key) {
              const location = `https://${process.env.REACT_APP_ASSET_BUCKET}.s3.amazonaws.com/${s3key}`
              fileObj.other_data = {
                asset_type: type,
                raw_location: location,
                thumbnail_location: location,
                download_location: location
              }
            }
            newRows.push(fileObj)
          }
        })
        setRows([...newRows, ...rowData])
      } else if (mode === 'onUpdateData') {
        const { document_id, name, selectedTags } = data || {}
        const fileTagObj = selectedTags
        const tags = []
        Object.keys(fileTagObj || {}).forEach((key) => {
          fileTagObj[key].forEach((item) => {
            tags.push({
              tag_key: key,
              tag_value: item.label,
              id: item.value
            })
          })
        })
        const newRows = [...rowData]
        const index = newRows.findIndex((item) => item.id === document_id)
        if (index >= 0) {
          newRows[index].file_name = name
          newRows[index].tags = tags
        }
        setRows(newRows)
      } else if (mode === 'onUpdateDataMulti') {
        const { documents, selectedTags } = data || {}
        const newRows = [...rowData]

        documents.forEach((item) => {
          const fileTagObj = selectedTags[item.uppy_id]
          const tags = []
          Object.keys(fileTagObj || {}).forEach((key) => {
            fileTagObj[key].forEach((item) => {
              tags.push({
                tag_key: key,
                tag_value: item.label,
                id: item.value
              })
            })
          })

          const index = newRows.findIndex((row) => row.id === item.id)
          if (index >= 0) {
            newRows[index].tags = tags
          }
        })
        setRows(newRows)
      } else if (mode === 'onUpdateType') {
        const { document_ids, document_type } = data || {}
        const newRows = [...rowData]
        document_ids.forEach((id) => {
          const index = newRows.findIndex((item) => item.id === id)
          if (index >= 0) {
            newRows[index].file_type = document_type
          }
        })
        setRows(newRows)
      }
    },
    [rows]
  )

  const handleUpdateRows = async (value, oldValue, row, key) => {
    if (value === oldValue || !value) return
    const { id, file_type } = row
    const serverKey = key === 'file_name' ? 'name' : key
    const data = {
      document_id: id,
      type: file_type,
      [serverKey]: value
    }
    let rowsCurrent = rowsRef.current
    let index = rowsCurrent.findIndex((item) => item.id === id)
    rowsCurrent[index] = {
      ...rowsCurrent[index],
      other_data: {
        ...rowsCurrent[index].other_data,
        loading: true
      }
    }
    setRows([...rowsCurrent])
    const res = await updateResource(data)
    if (res.status === 200) {
      rowsCurrent = rowsRef.current
      index = rowsCurrent.findIndex((item) => item.id === id)
      const newRows = [...rowsCurrent]
      newRows[index][key] = value
      newRows[index].other_data.loading = false
      setRows(newRows)
    } else {
      toast.error('Something went wrong while updating')
      rowsCurrent = rowsRef.current
      index = rowsCurrent.findIndex((item) => item.id === id)
      rowsCurrent[index] = {
        ...rowsCurrent[index],
        other_data: {
          ...rowsCurrent[index].other_data,
          loading: false
        }
      }
      setRows([...rowsCurrent])
    }
  }

  const handleUpdateCollections = async (value, oldValue, row, key) => {
    if (value === oldValue || !value) return
    const { id, file_type } = row
    const serverKey = key === 'file_name' ? 'name' : key
    const data = {
      document_id: id,
      type: file_type,
      [serverKey]: value
    }
    let currentRows = collectionsRowsRef.current
    let index = currentRows.findIndex((item) => item.id === id)
    currentRows[index] = {
      ...currentRows[index],
      other_data: {
        ...currentRows[index].other_data,
        loading: true
      }
    }
    setCollectionsRows([...currentRows])
    const res = await updateResource(data)
    if (res.status === 200) {
      const newRows = collectionsRowsRef.current
      index = newRows.findIndex((item) => item.id === id)
      newRows[index][key] = value
      newRows[index].other_data.loading = false
      setCollectionsRows(newRows)
    } else {
      toast.error('Something went wrong while updating')
      currentRows = collectionsRowsRef.current
      index = currentRows.findIndex((item) => item.id === id)
      currentRows[index] = {
        ...currentRows[index],
        other_data: {
          ...currentRows[index].other_data,
          loading: false
        }
      }
      setCollectionsRows([...currentRows])
    }
  }

  const handleTagChange = (value, isMulti, row, key) => {
    const { id } = row
    if (isMulti) {
      setSelectedTagValues({
        ...selectedTagValues,
        [id]: {
          ...selectedTagValues[id],
          [key]: value
        }
      })
    } else {
      if (value) {
        setSelectedTagValues({
          ...selectedTagValues,
          [id]: {
            ...selectedTagValues[id],
            [key]: [value]
          }
        })
      } else {
        setSelectedTagValues({
          ...selectedTagValues,
          [id]: {
            ...selectedTagValues[id],
            [key]: []
          }
        })
      }
    }
  }

  const onDateTagChange = async (value, type, key, row) => {
    value = value.map((item) => {
      if (item instanceof Date) {
        return item.toISOString()
      }
      return item
    })

    const { id, tags, file_type } = row
    let rowsCurrent = rowsRef.current
    let index = rowsCurrent.findIndex((item) => item.id === id)
    rowsCurrent[index] = {
      ...rowsCurrent[index],
      other_data: {
        ...rowsCurrent[index].other_data,
        loading: true
      }
    }
    setRows([...rowsCurrent])

    const tags_date = []
    value.forEach((item) => {
      tags_date.push({
        tag_key: key,
        tag_value: item,
        tag_type: type
      })
    })

    const tagsIds = tags
      .map((item) => (item.tag_key !== key ? item.id : null))
      .filter((id) => id !== null)

    const data = {
      document_id: id,
      type: file_type,
      tags: tagsIds,
      tags_date
    }

    const res = await updateResource(data)
    if (res.status === 200) {
      const { new_tags } = res.data
      const tagsFormated = []
      Object.keys(tagsCenterStateTags || {}).forEach((k, index) => {
        const { data = [] } = tagsCenterStateTags[k] || {}
        if (key !== k) {
          data.forEach((element) => {
            if (tagsIds.includes(element.id) && element.value) {
              tagsFormated.push({
                tag_key: k,
                tag_value: element.value,
                id: element.id
              })
            }
          })
        }
      })
      const { data = [] } = tagsCenterStateTags[key] || {}
      value.forEach((item) => {
        const tag = data.find((element) => {
          return element.value === item
        })
        if (tag) {
          tagsFormated.push({
            tag_key: key,
            tag_value: tag.value,
            id: tag.id
          })
        } else {
          const newTag = new_tags.find((element) => {
            return element.value === item
          })
          if (newTag) {
            tagsFormated.push({
              tag_key: key,
              tag_value: newTag.value,
              id: newTag.id
            })
          }
        }
      })
      dispatch(updateNewTagValue({ new_tags, key }))
      rowsCurrent = rowsRef.current
      index = rowsCurrent.findIndex((item) => item.id === id)
      const newRows = [...rowsCurrent]
      newRows[index].tags = tagsFormated
      newRows[index].other_data.loading = false
      setRows(newRows)
    } else {
      toast.error('Something went wrong while updating')
      rowsCurrent = rowsRef.current
      index = rowsCurrent.findIndex((item) => item.id === id)
      rowsCurrent[index] = {
        ...rowsCurrent[index],
        other_data: {
          ...rowsCurrent[index].other_data,
          loading: false
        }
      }
      setRows([...rowsCurrent])
    }
  }

  const handleTagsUpdate = async (row, key, isDocumentType) => {
    const { id, tags, file_type } = row
    const tagsIds = []
    const selectedTags = selectedTagValues[id]
    const tagsObjs = []
    const alteredKeys = Object.keys(selectedTags || {})
    alteredKeys.forEach((key) => {
      const values = selectedTags[key]
      if (values.length > 0) {
        values.forEach((element) => {
          tagsIds.push(element.value)
          tagsObjs.push({
            tag_key: key,
            tag_value: element.label,
            id: element.value
          })
        })
      }
    })
    const oldTagsObj = tags.filter(
      (item) => !alteredKeys.includes(item.tag_key)
    )
    const oldTagsIds = oldTagsObj.map((item) => item.id)
    const combineTagsIds = [...oldTagsIds, ...tagsIds]
    const uniqueTagsIds = _.uniq(combineTagsIds)
    const combineTagsObjs = [...oldTagsObj, ...tagsObjs]
    const uniqueTagsObjs = _.uniqBy(combineTagsObjs, 'id')

    const data = {
      document_id: id,
      type: file_type,
      tags: uniqueTagsIds
    }
    let rowsCurrent = rowsRef.current
    let index = rowsCurrent.findIndex((item) => item.id === id)
    rowsCurrent[index] = {
      ...rowsCurrent[index],
      other_data: {
        ...rowsCurrent[index].other_data,
        loading: true
      }
    }
    setRows([...rowsCurrent])
    const res = await updateResource(data)
    if (res.status === 200) {
      rowsCurrent = rowsRef.current
      index = rowsCurrent.findIndex((item) => item.id === id)
      const newRows = [...rowsCurrent]
      newRows[index].tags = uniqueTagsObjs
      if (
        isDocumentType &&
        (newRows[index].file_type === 'document' ||
          newRows[index].file_type === 'proposal')
      ) {
        const typeTags = documentTypeTags.filter((item) =>
          uniqueTagsIds.includes(item.id)
        )
        if (typeTags?.length > 0) {
          const newType = typeTags[0]?.value_type
            ?.toLowerCase()
            .includes('proposal')
            ? 'proposal'
            : 'document'
          if (newRows[index].file_type !== newType) {
            newRows[index].file_type = newType
          }
        }
      }
      newRows[index].other_data.loading = false
      setRows(newRows)
    } else {
      toast.error('Something went wrong while updating')
      rowsCurrent = rowsRef.current
      index = rowsCurrent.findIndex((item) => item.id === id)
      rowsCurrent[index] = {
        ...rowsCurrent[index],
        other_data: {
          ...rowsCurrent[index].other_data,
          loading: false
        }
      }
      setRows([...rowsCurrent])
    }
    delete selectedTagValues[id]
    setSelectedTagValues({
      ...selectedTagValues
    })
  }

  const fileViewerCallbackDelete = (id) => {
    const newPageData = [...pageData]
    if (_.isArray(id)) {
      id.forEach((i) => {
        const index = newPageData.findIndex((item) => item.id === i)
        if (index >= 0) {
          newPageData.splice(index, 1)
        }
      })
    } else {
      const index = pageData.findIndex((item) => item.id === id)
      if (index >= 0) {
        newPageData.splice(index, 1)
      }
    }
    setPageData(newPageData)
  }

  const fileViewerCallbackUpdate = (id, key, value) => {
    const rowsCurrent = rowsRef.current
    const newRows = [...rowsCurrent]
    const index = newRows.findIndex((item) => item.id === id)
    if (index >= 0) {
      if (key === 'loading') {
        newRows[index].other_data.loading = value
      } else if (key === 'notes') {
        newRows[index].other_data = {
          ...newRows[index].other_data,
          notes: value
        }
      } else {
        newRows[index][key] = value
      }
      if (key === 'tags') {
        const documentKeys = documentTypeTags.map((item) => item.id)
        const valueTags = value.filter((item) => documentKeys.includes(item.id))
        if (
          newRows[index].file_type === 'document' ||
          newRows[index].file_type === 'proposal'
        ) {
          const typeTags = documentTypeTags.filter(
            (item) => valueTags[0]?.id === item.id
          )
          if (typeTags?.length > 0) {
            const newType = typeTags[0]?.value_type
              ?.toLowerCase()
              .includes('proposal')
              ? 'proposal'
              : 'document'
            if (newRows[index].file_type !== newType) {
              newRows[index].file_type = newType
            }
          }
        }
      }
    }
    setRows(newRows)
  }

  const handleCollectionUpdate = (data) => {
    const { file_name, other_data, id } = data || {}
    const { icon, description, color } = other_data || {}
    const req = {
      id,
      name: file_name,
      description,
      icon,
      color
    }
    const callback = () => {
      setCollectionEdit(null)
      const index = collectionsRowsRef.current?.findIndex(
        (item) => item.id === id
      )
      if (index >= 0) {
        const newRows = [...collectionsRowsRef.current]
        newRows[index].file_name = file_name
        newRows[index].other_data = {
          ...newRows[index].other_data,
          icon,
          color,
          description
        }
        setCollectionsRows(newRows)
      }
    }

    dispatch(updateCollections(req, callback))
  }

  const createDragGhost = (row) => {
    const elem = document.createElement('div')
    elem.id = 'drag-ghost'
    const icon = getFileIcon(row)
    const jsxContent = (
      <Box
        sx={{
          background: '#DBEAFF',
          display: 'flex',
          alignItems: 'center',
          width: '300px',
          position: 'absolute',
          top: '-9999px',
          padding: '10px',
          gap: '10px',
          fontSize: '14px',
          color: '#797979',
          '& .MuiSvgIcon-root': {
            fontSize: '30px !important'
          }
        }}
      >
        <Box>
          <SvgIcon>{icon}</SvgIcon>
        </Box>
        <Box>
          {row.file_name}{' '}
          {selectedRow.length > 1 ? `+ ${selectedRow.length - 1} more` : ''}
        </Box>
      </Box>
    )
    const root = ReactDOM.createRoot(elem)
    root.render(jsxContent)
    document.body.appendChild(elem)
    return elem
  }

  const handleDragStart = (event, row) => {
    if (
      activeCollection?.serverType === 'collection' &&
      !['owner', 'shared'].includes(activeCollection?.folder_rights)
    ) {
      return
    }
    let data = row
    if (selectedRow.length > 0) {
      data = selectedRow
      event.dataTransfer.setData('multiple', true)
    }
    event.dataTransfer.setData('data', JSON.stringify(data))
    event.dataTransfer.setData('parent', JSON.stringify(activeCollection))
    if (selectedRow.length > 0) {
      const dragGhost = createDragGhost(selectedRow[0])
      event.dataTransfer.setDragImage(dragGhost, 0, 0)
    } else {
      if (viewMode === 'table') {
        const dragGhost = createDragGhost(row)
        event.dataTransfer.setDragImage(dragGhost, 0, 0)
      }
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleSaveView = (isAll = false) => {
    const req = {
      user_config: {
        columnWidths,
        hiddenColumns,
        viewMode,
        columns,
        update_for_all: isAll
      }
    }
    const callback = () => {
      toast.success('View saved successfully')
    }
    dispatch(updateUserConfig(req, callback))
  }

  const handleClearView = () => {
    setViewMode('table')
    setColumnWidths({})
    const hiddenColumnsArray = []
    tagsOptions &&
      Object.keys(tagsOptions || {}).forEach((key) => {
        hiddenColumnsArray.push(key)
      })
    setHiddenColumns(hiddenColumnsArray)
  }

  const handleBreadcrumbClick = (item) => {
    const { id } = item
    if (id !== activeCollection?.id) {
      const newNode = findNodeById(id, collectionsList)
      const { children } = newNode
      if (children.length > 0) {
        dispatch(setExpandedObj({ ...expanded, [id]: true }))
      }
      dispatch(
        updateActiveCollection({
          ...newNode,
          serverType: 'collection',
          clearSearch: true
        })
      )
    }
  }

  function findNodeById(id, nestedObject) {
    function recursiveFindNode(nodes) {
      for (const node of nodes) {
        if (node.id === id) {
          return node
        }
        if (node.children && node.children.length > 0) {
          const foundNode = recursiveFindNode(node.children)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    }
    return recursiveFindNode(nestedObject)
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      switch (event.key) {
        // case 'ArrowLeft':
        //     if (selectedFile && prevFile) {
        //         handleFileSelect(prevFile)
        //     }
        //     break;
        // case 'ArrowRight':
        //     if (selectedFile && nextFile) {
        //         handleFileSelect(nextFile)
        //     }
        //     break;
        case 'A':
        case 'a':
          if (event.metaKey || event.key === 'Meta') {
            setSelectedRow([...allRows])
            event.stopPropagation()
            event.preventDefault()
          }
          break
        case 'Escape':
          if (selectedFile) {
            setSelectedFile(null)
            setPrevFile(null)
            setNextFile(null)
          } else {
            setEditRows(null)
            setSelectedRow([])
          }
          break
        default:
          break
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [prevFile, nextFile, selectedRow, allRows])

  const renderKeyValues = (key, value) => {
    if (key === 'created_at') {
      return moment(value).format('MMM D, YYYY')
    } else if (key === 'file_type') {
      return _.startCase(value)
    } else {
      return value
    }
  }

  const handleMultiTags = async (totalTags) => {
    try {
      const datetags = {}
      const tags = {}
      const dateArr = {}
      const tagsArr = {}
      for (const key in totalTags) {
        if (
          ['multidate', 'singledate'].includes(totalTags?.[key]?.[0]?.tag_type)
        ) {
          datetags[key] = totalTags[key]
        } else {
          tags[key] = totalTags[key]
        }
      }
      Object.keys(datetags).forEach((key) => {
        datetags[key].forEach((item) => {
          if (dateArr[key]) {
            dateArr[key].push({
              tag_key: item.key,
              tag_value: item.label,
              tag_type: item.tag_type
            })
          } else {
            dateArr[key] = [
              {
                tag_key: item.key,
                tag_value: item.label,
                tag_type: item.tag_type
              }
            ]
          }
        })
      })
      Object.keys(tags).forEach((key) => {
        tags[key].forEach((item) => {
          if (tagsArr[key]) {
            tagsArr[key].push({
              tag_key: item.key,
              tag_value: item.value,
              tag_label: item.label
            })
          } else {
            tagsArr[key] = [
              {
                tag_key: item.key,
                tag_value: item.value,
                tag_label: item.label
              }
            ]
          }
        })
      })

      for (const key in dateArr) {
        if (dateArr[key].length === 0) {
          delete dateArr[key]
        }
      }
      for (const key in tagsArr) {
        if (tagsArr[key].length === 0) {
          delete tagsArr[key]
        }
      }
      if (_.isEmpty(tagsArr) && _.isEmpty(dateArr)) {
        toast.error('Please select atleast one tag')
      } else {
        setShowTagDrawer(false)
        const toastId = toast.info(
          <div style={{ display: 'flex' }}>
            {'Tagging Selected Rows'}&nbsp;
            <CircularProgress size={20} />
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false
          }
        )
        const documents = selectedRow.map((item) => {
          return {
            id: item.id,
            file_type: item.file_type
          }
        })
        const document_ids = documents.map((item) => item.id)
        let rowsCurrent = rowsRef.current
        let indexes = rowsCurrent
          .map((item, index) =>
            document_ids.includes(item.id) ? index : undefined
          )
          .filter((index) => index !== undefined)

        for (const index of indexes) {
          rowsCurrent[index] = {
            ...rowsCurrent[index],
            other_data: {
              ...(rowsCurrent[index]?.other_data || {}),
              loading: true
            }
          }
        }
        setRows([...rowsCurrent])
        const req = {
          documents,
          action: 'tags',
          data: { tags: tagsArr, date_tags: dateArr }
        }
        const res = await multiDocuments(req)
        toast.dismiss(toastId)
        if (res.status === 200) {
          const { new_tags } = res.data
          const tagsFormated = []
          const keysToRemove = []
          Object.keys(tagsArr || {}).forEach((key) => {
            keysToRemove.push(key)
            tagsArr[key].forEach((item) => {
              tagsFormated.push({
                tag_key: key,
                tag_value: item.tag_label,
                id: item.tag_value
              })
            })
          })
          const newTagPayload = {}
          new_tags.forEach((item) => {
            if (!newTagPayload[item.key]) {
              newTagPayload[item.key] = []
            }
            newTagPayload[item.key].push(item)
          })
          Object.keys(new_tags || {}).forEach((key) => {
            dispatch(updateNewTagValue({ new_tags: newTagPayload[key], key }))
          })

          Object.keys(datetags || {}).forEach((key) => {
            const values = datetags[key]
            keysToRemove.push(key)
            const { data = [] } = tagsCenterStateTags[key] || {}
            values.forEach((item) => {
              const tag = data.find((element) => {
                return element.value === item.label
              })
              if (tag) {
                tagsFormated.push({
                  tag_key: key,
                  tag_value: tag.value,
                  id: tag.id
                })
              } else {
                const newTag = new_tags.find((element) => {
                  return element.value === item.label
                })
                if (newTag) {
                  tagsFormated.push({
                    tag_key: key,
                    tag_value: newTag.value,
                    id: newTag.id
                  })
                }
              }
            })
          })
          const documentKeyArray = documentTypeTags.find((item) => item.key)
          const documentKey = documentKeyArray?.key
          rowsCurrent = rowsRef.current
          indexes = rowsCurrent
            .map((item, index) =>
              document_ids.includes(item.id) ? index : undefined
            )
            .filter((index) => index !== undefined)
          for (const index of indexes) {
            let oldTags = rowsCurrent[index].tags || []
            oldTags = oldTags.filter(
              (item) => !keysToRemove.includes(item.tag_key)
            )
            if (documentKey && keysToRemove.includes(documentKey)) {
              const valueTags = tagsArr[documentKey]
              if (
                rowsCurrent[index].file_type === 'document' ||
                rowsCurrent[index].file_type === 'proposal'
              ) {
                const typeTags = documentTypeTags.filter(
                  (item) => item.id === valueTags[0]?.tag_value
                )
                if (typeTags?.length > 0) {
                  const newType = typeTags[0]?.value_type
                    ?.toLowerCase()
                    .includes('proposal')
                    ? 'proposal'
                    : 'document'
                  if (newType !== rowsCurrent[index].file_type) {
                    rowsCurrent[index].file_type = newType
                  }
                }
              }
            }
            const newTags = [...oldTags, ...tagsFormated]
            rowsCurrent[index] = {
              ...rowsCurrent[index],
              tags: newTags,
              other_data: {
                ...rowsCurrent[index].other_data,
                loading: false
              }
            }
          }
          setRows([...rowsCurrent])
        } else {
          toast.error(
            'Something went wrong while tagging the files. Try reducing the number of files selected'
          )
          rowsCurrent = rowsRef.current
          indexes = rowsCurrent
            .map((item, index) =>
              document_ids.includes(item.id) ? index : undefined
            )
            .filter((index) => index !== undefined)
          for (const index of indexes) {
            rowsCurrent[index] = {
              ...rowsCurrent[index],
              other_data: {
                ...rowsCurrent[index].other_data,
                loading: false
              }
            }
          }
          setRows([...rowsCurrent])
        }
      }
    } catch (e) {
      console.log('error', e)
      toast.error(
        'Something went wrong while tagging the files. Try reducing the number of files selected'
      )
      const document_ids = selectedRow.map((item) => item.id)
      rowsCurrent = rowsRef.current
      const indexes = rowsCurrent
        .map((item, index) =>
          document_ids.includes(item.id) ? index : undefined
        )
        .filter((index) => index !== undefined)

      for (const index of indexes) {
        rowsCurrent[index] = {
          ...rowsCurrent[index],
          other_data: {
            ...rowsCurrent[index].other_data,
            loading: false
          }
        }
      }
      setRows([...rowsCurrent])
    }
  }

  const handleHideMulti = async (hasSelectedCollection, hideFlag = true) => {
    if (isUserViewer) {
      toast.error("You don't have permission to hide/unhide files")
      return
    }
    const names = selectedRow.map((item) => item.file_name)
    showConfirmDialog({
      onConfirm: async () => {
        const toastId = toast.info(
          <div style={{ display: 'flex' }}>
            {`${hideFlag ? 'Hiding' : 'Unhiding'} files`}&nbsp;
            <CircularProgress size={20} />
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false
          }
        )
        const documents = selectedRow.map((item) => {
          return {
            id: item.id,
            file_type: item.file_type
          }
        })
        const document_ids = documents.map((item) => item.id)
        let rowsCurrent = rowsRef.current
        let indexes = rowsCurrent
          .map((item, index) =>
            document_ids.includes(item.id) ? index : undefined
          )
          .filter((index) => index !== undefined)

        for (const index of indexes) {
          rowsCurrent[index] = {
            ...rowsCurrent[index],
            other_data: {
              ...(rowsCurrent[index]?.other_data || {}),
              loading: true
            }
          }
        }
        setRows([...rowsCurrent])

        const req = {
          documents,
          action: 'hide',
          data: hideFlag
        }
        const res = await multiDocuments(req)
        toast.dismiss(toastId)
        if (res.status === 200) {
          rowsCurrent = rowsRef.current
          indexes = rowsCurrent
            .map((item, index) =>
              document_ids.includes(item.id) ? index : undefined
            )
            .filter((index) => index !== undefined)

          for (const index of indexes) {
            rowsCurrent[index] = {
              ...rowsCurrent[index],
              other_data: {
                ...rowsCurrent[index].other_data,
                hidden_manually: hideFlag,
                loading: false
              }
            }
          }
          selectedRow.forEach((item) => {
            if (document_ids.includes(item.id)) {
              item.other_data.hidden_manually = hideFlag
            }
          })
          setSelectedRow([...selectedRow])
          setRows([...rowsCurrent])
        } else {
          toast.error(
            `Something went wrong while ${
              hideFlag ? 'hiding' : 'unhiding'
            } the files`
          )
          rowsCurrent = rowsRef.current
          indexes = rowsCurrent
            .map((item, index) =>
              document_ids.includes(item.id) ? index : undefined
            )
            .filter((index) => index !== undefined)
          for (const index of indexes) {
            rowsCurrent[index] = {
              ...rowsCurrent[index],
              other_data: {
                ...rowsCurrent[index].other_data,
                loading: false
              }
            }
          }
          setRows([...rowsCurrent])
        }
      },
      confirmationMessageTitle:
        'Are you sure you want to hide the following files ?',
      confirmationMessage: (
        <Box>
          {hasSelectedCollection && (
            <Box>
              <Box sx={{ fontWeight: 'bold' }}>
                This action will also delete all the subcollections under the
                selected collections
              </Box>
            </Box>
          )}
          <Box>
            {names.map((item, index) => (
              <Box key={index}>{item}</Box>
            ))}
          </Box>
        </Box>
      )
    })
  }

  const handleTagsValueChange = (key, value, mode) => {
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
      }
    }
  }

  const handleShare = async (data) => {
    const { id, file_type, other_data, file_name } = data || {}
    const { created_by_user_id } = other_data || {}
    const meta = {
      id,
      file_type,
      file_created_by: created_by_user_id,
      file_name
    }
    setShareFile({
      ...meta,
      loading: true
    })
    const res = await getFileAccess(id)
    if (res.status === 200) {
      setShareFile({
        ...meta,
        ...res.data,
        loading: false
      })
    }
  }

  const renderShareDialog = () => {
    const {
      collection_access_private,
      created_by,
      collection_access_public = [],
      loading,
      updating = false,
      file_name = ''
    } = shareFile || {}
    const rootAccess = shareFile?.root_access || {
      user_ids: [],
      users: [],
      editor_ids: []
    }
    const rootUsers = rootAccess?.users || []
    const shared_user_ids = rootAccess?.user_ids || []
    const editorIds = rootAccess?.editor_ids || []
    const usersOptions = domainUserNamesWoCurrentUser.filter(
      (item) => !shared_user_ids.includes(item.value)
    )

    const publicCollection = collection_access_public
      .map((item) => item.name)
      .join(', ')

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={shareFile}
        onClose={() => setShareFile(null)}
      >
        <DialogTitle>Restrict Access to "{file_name}" </DialogTitle>
        {loading ? (
          <Box sx={{ height: '150px' }}>
            <Loader loading={loading} caption={''} flex />
          </Box>
        ) : (
          <>
            <DialogContent>
              <Box sx={{ margin: '10px 0px' }}>
                <ReactSelect
                  options={usersOptions}
                  isMulti={false}
                  onChange={(item, e) => {
                    const selectedUser = {
                      id: item.value,
                      name: item.label,
                      email: item.email
                    }
                    const newUsers = [...rootUsers, selectedUser]
                    const uniqNewUsers = _.uniqBy(newUsers, 'id')
                    setShareFile({
                      ...shareFile,
                      root_access: {
                        ...rootAccess,
                        users: uniqNewUsers,
                        user_ids: uniqNewUsers.map((item) => item.id)
                      }
                    })
                  }}
                  placeholder="Select users to restrict access to"
                  menuPortalTarget={document.body}
                />
              </Box>
              {created_by?.name ||
              !_.isEmpty(rootUsers) ||
              !_.isEmpty(collection_access_private) ||
              publicCollection ? (
                <>
                  <Box>People with access</Box>
                  <Box className={classes.shareContainer}>
                    {created_by?.name && (
                      <Box className={classes.shareAvatarContainer}>
                        <Box className={classes.shareAvatarWrapper}>
                          <Avatar
                            className={classes.shareAvatar}
                            sx={{
                              backgroundColor: getColorsForInitials(
                                created_by?.name
                              )
                            }}
                          >
                            {getInitialsFromUserName(
                              created_by?.name.toUpperCase()
                            )}
                          </Avatar>
                          <Box>
                            <Box>{created_by?.name}</Box>
                            <Box className={classes.shareAvatarEmail}>
                              {created_by?.email}
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ textAlign: 'end' }}>Creator</Box>
                      </Box>
                    )}
                    {rootUsers.map((user, index) => {
                      const { name, email, id } = user
                      const isEditorInherited = editorIds.includes(id)
                      return (
                        <Box
                          className={classes.shareAvatarContainer}
                          key={index}
                        >
                          <Box className={classes.shareAvatarWrapper}>
                            <Avatar
                              className={classes.shareAvatar}
                              sx={{
                                backgroundColor: getColorsForInitials(name)
                              }}
                            >
                              {getInitialsFromUserName(name.toUpperCase())}
                            </Avatar>
                            <Box>
                              <Box>{name}</Box>
                              <Box className={classes.shareAvatarEmail}>
                                {email}
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              textAlign: 'end',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                              }}
                            >
                              <Box>Explicitly Shared</Box>
                              <Box className={classes.shareAvatarEmail}>
                                {isEditorInherited ? 'inherited as editor' : ''}
                              </Box>
                            </Box>
                            <Tooltip title="Remove User">
                              <IconButton
                                onClick={() => {
                                  const newUsers = rootUsers.filter(
                                    (item) => item.id !== user.id
                                  )
                                  setShareFile({
                                    ...shareFile,
                                    root_access: {
                                      ...rootAccess,
                                      users: newUsers,
                                      user_ids: newUsers.map((item) => item.id)
                                    }
                                  })
                                }}
                              >
                                <PersonRemoveIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      )
                    })}
                    {Object.keys(collection_access_private || {}).map(
                      (key, index) => {
                        const data = collection_access_private[key]
                        const { name, email, collections } = data
                        const collectionName = collections
                          .map((item) => item.name)
                          .join(', ')
                        return (
                          <Box
                            className={classes.shareAvatarContainer}
                            key={index}
                          >
                            <Box className={classes.shareAvatarWrapper}>
                              <Avatar
                                className={classes.shareAvatar}
                                sx={{
                                  backgroundColor: getColorsForInitials(name)
                                }}
                              >
                                {getInitialsFromUserName(name.toUpperCase())}
                              </Avatar>
                              <Box>
                                <Box>{name}</Box>
                                <Box className={classes.shareAvatarEmail}>
                                  {email}
                                </Box>
                              </Box>
                            </Box>
                            <Box sx={{ textAlign: 'end' }}>
                              <Box>via Private Collections</Box>
                              <Box className={classes.shareAvatarEmail}>
                                {collectionName}
                              </Box>
                            </Box>
                          </Box>
                        )
                      }
                    )}
                    {publicCollection && (
                      <Box className={classes.shareAvatarContainer}>
                        <Box className={classes.shareAvatarWrapper}>
                          <Avatar
                            className={classes.shareAvatar}
                            sx={{
                              backgroundColor: getColorsForInitials('all')
                            }}
                          >
                            {'All'}
                          </Avatar>
                          <Box>
                            <Box>{'All Users'}</Box>
                          </Box>
                        </Box>
                        <Box sx={{ textAlign: 'end' }}>
                          <Box>via Public Collections</Box>
                          <Box className={classes.shareAvatarEmail}>
                            {publicCollection}
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {_.isEmpty(rootUsers) &&
                      _.isEmpty(collection_access_private) &&
                      _.isEmpty(publicCollection) && (
                        <Box className={classes.shareAvatarContainer}>
                          <Box className={classes.shareAvatarWrapper}>
                            <Avatar
                              className={classes.shareAvatar}
                              sx={{
                                backgroundColor: getColorsForInitials('all')
                              }}
                            >
                              {'All'}
                            </Avatar>
                            <Box>
                              <Box>{'All Users'}</Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                  </Box>
                </>
              ) : (
                <Box className={classes.shareContainer}>
                  <Box className={classes.shareAvatarContainer}>
                    <Box className={classes.shareAvatarWrapper}>
                      <Avatar
                        className={classes.shareAvatar}
                        sx={{
                          backgroundColor: getColorsForInitials('all')
                        }}
                      >
                        {'All'}
                      </Avatar>
                      <Box>
                        <Box>{'All Users'}</Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button disabled={updating} onClick={() => handleShareUpdate()}>
                {updating ? (
                  <CircularProgress size={24.5} color="secondary" />
                ) : (
                  'Update'
                )}
              </Button>
              <Button variant="outlined" onClick={() => setShareFile(null)}>
                Cancel
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    )
  }

  const handleShareUpdate = async () => {
    setShareFile({
      ...shareFile,
      updating: true
    })
    let shareState = shareFile
    const { root_access, file_type, id, file_created_by } = shareFile
    const user_ids = root_access?.user_ids || []
    const editor_ids = root_access?.editor_ids || []
    const current_user = user_id
    if (
      current_user !== file_created_by &&
      !_.isEmpty(user_ids) &&
      !user_ids.includes(current_user)
    ) {
      user_ids.push(current_user)
      editor_ids.push(current_user)
      shareState = {
        ...shareFile,
        root_access: {
          ...root_access,
          user_ids,
          users: [
            ...root_access.users,
            {
              id: current_user,
              name: user_name,
              email: user_email
            }
          ]
        }
      }
    }

    const access = {
      user_ids,
      created_by: file_created_by,
      editor_ids,
      is_public: !(user_ids.length > 0)
    }
    const req = {
      document_id: id,
      document_type: file_type,
      root_property: access
    }
    const res = await updateFileAccess(req)
    if (res.status === 200) {
      const { is_public } = res.data
      const rowData = rowsRef.current
      const index = rowData.findIndex((item) => item.id === id)
      if (index >= 0) {
        rowData[index].other_data = {
          ...rowData[index].other_data,
          is_public
        }
      }
      setRows([...rowData])
      toast.success('File shared successfully')
    } else {
      toast.error('Something went wrong while sharing the file')
    }
    setShareFile({
      ...shareState,
      updating: false
    })
  }

  const renderMultiSelectControl = () => {
    const keys = [
      {
        key: 'Cmd/Win + A',
        use: 'Select All'
      },
      {
        key: 'Esc',
        use: 'Deselect All'
      },
      {
        key: 'Cmd/Win + Select',
        use: 'Select Individual'
      },
      {
        key: 'Shift + Select',
        use: 'Select Range'
      }
    ]
    const hasSelectedCollection =
      selectedRow.filter((item) => item.file_type === 'collection').length > 0
    const isAllDocument =
      selectedRow.filter((item) => item.file_type === 'document').length ===
      selectedRow.length
    const isAllProposal =
      selectedRow.filter((item) => item.file_type === 'proposal').length ===
      selectedRow.length
    let hideFlag = selectedRow[0]?.other_data?.hidden_manually
    const showHide = _.every(selectedRow, (item) =>
      _.isEqual(item?.other_data.hidden_manually, hideFlag)
    )
    hideFlag = showHide ? hideFlag : false

    return (
      selectedRow.length > 0 && (
        <Box className={classes.multiControl}>
          <Box className={classes.multiControlBox}>
            <Box className={classes.multiControlOptions}>
              <Tooltip
                title={
                  <TableContainer
                    sx={{
                      '& th': {
                        color: '#fff'
                      },
                      '& td': {
                        color: '#fff'
                      }
                    }}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Shortcut</TableCell>
                          <TableCell>Function</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {keys.map((el, index) => {
                          const { key, use } = el
                          return (
                            <TableRow key={index}>
                              <TableCell>{key}</TableCell>
                              <TableCell>{use}</TableCell>
                            </TableRow>
                          )
                        })}
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ textAlign: 'center' }}
                          >
                            Document Switch can be done only for 10 files at a
                            time.
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              >
                <IconButton disableRipple>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
              Selected {selectedRow.length}{' '}
              {selectedRow.length > 1 ? 'items' : 'item'}
            </Box>
            <Box className={classes.multiControlOptions}>
              {!hasSelectedCollection && (
                <>
                  <Tooltip title="Add to collection">
                    <IconButton
                      disableRipple
                      onClick={() => {
                        multiAddToCollectionData()
                      }}
                      disabled={isUserViewer}
                    >
                      <CreateNewFolderIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add Tags">
                    <IconButton
                      disableRipple
                      onClick={() => setShowTagDrawer(true)}
                      disabled={isUserViewer}
                    >
                      <StyleIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      hideFlag
                        ? 'Unhide from Search & Chat'
                        : 'Hide from Search & Chat'
                    }
                  >
                    <IconButton
                      disableRipple
                      onClick={() =>
                        handleHideMulti(hasSelectedCollection, !hideFlag)
                      }
                      disabled={isUserViewer}
                    >
                      {hideFlag ? <SearchIcon /> : <SearchOffIcon />}
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>
          </Box>
        </Box>
      )
    )
  }

  const draggable = isResizing
    ? false
    : activeCollection?.serverType === 'collection'
    ? ['owner', 'shared'].includes(activeCollection?.folder_rights)
    : true
  return pageData ? (
    <Container sx={{ position: 'relative' }}>
      <SectionFixed>
        <div
          style={{
            padding: '5px 10px',
            borderBottom: '1px solid #e4e4e7'
          }}
        >
          {activeCollection?.serverType === 'collection' ? (
            <Box>
              {breadcrumbs && (
                <Breadcrumbs
                  separator={<NavigateNext fontSize="small" />}
                  sx={{
                    '& .MuiBreadcrumbs-separator': {
                      marginLeft: '3px',
                      marginRight: '3px'
                    }
                  }}
                  maxItems={3}
                >
                  {breadcrumbs.map((item, index) => {
                    const { name, id } = item || {}
                    const isActive = id === activeCollection?.id
                    return (
                      <Box
                        key={index}
                        className={
                          isActive
                            ? classes.breadcrumbActive
                            : classes.breadcrumb
                        }
                        onClick={() => handleBreadcrumbClick(item)}
                      >
                        {name}
                      </Box>
                    )
                  })}
                </Breadcrumbs>
              )}
              <Box className={classes.collectionDesc}>
                <TextLineLimiter
                  content={activeCollection?.description}
                  limit={4}
                  indicatorStyle={{ color: '#79797999', cursor: 'pointer' }}
                />
              </Box>
            </Box>
          ) : (
            <p
              style={{
                fontSize: '16px',
                margin: '0',
                fontFamily: 'PoppinsMedium',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}
            >
              {activeCollectionState.label}{' '}
              <Box
                sx={{
                  fontSize: '11px',
                  marginTop: '3px',
                  /* color: #71717a; */
                  fontFamily: 'PoppinsRegular'
                }}
              >
                {totalCollectionsPageSize > 0 && totalPageSize > 0 ? (
                  <>
                    {totalCollectionsPageSize}{' '}
                    {totalCollectionsPageSize > 1
                      ? 'Collections, '
                      : 'Collection, '}
                  </>
                ) : (
                  <></>
                )}
                {totalPageSize > 0 ? (
                  <>
                    {totalPageSize} {totalPageSize > 1 ? 'Files' : 'File'}
                  </>
                ) : (
                  <></>
                )}
                {totalCollectionsPageSize === 0 && totalPageSize === 0 && (
                  <>No data found</>
                )}
              </Box>
            </p>
          )}
        </div>
        <Box className={classes.searchWrapper}>
          <Box className={classes.searchBarWrapper}>
            <SearchNew
              value={searchValue}
              onChange={(value) => setSearchValue(value)}
              onEnter={(e) => {
                handleSearchQuery('search')
              }}
              onClear={() => {
                setSearchValue('')
                handleSearchQuery('clear')
              }}
            />
            {/* <TextField
              inputRef={searchInputRef}
              variant="outlined"
              // placeholder={
              //   'Search' + ' ' + _.lowerCase(activeCollection?.label)
              // }
              placeholder={'Search'}
              fullWidth
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearchQuery('search')
                }
                if (e.key === 'Backspace') {
                  if (!searchValue && searchCollection) {
                    setSearchCollection(null)
                  }
                }
              }}
              sx={{
                outline: 'none',
                '& .MuiOutlinedInput-root:focus': {
                  outline: 'none'
                },
                '& .MuiInputBase-root': {
                  paddingLeft: '5px',
                  fontSize: '13px',
                  outline: 'none'
                },
                '& input': {
                  padding: '6px 2px',
                  fontSize: '13px',
                  outline: 'none'
                },

                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '#a1a1aa 1px solid'
                },
                '& .Mui-not-focused .MuiOutlinedInput-notchedOutline': {
                  border: '#e4e4e7 1px solid'
                },
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    border: '#a1a1aa 1px solid'
                  }
              }}
              disabled={tableLoading}
              InputProps={{
                startAdornment: activeCollection?.serverType ===
                  'collection' && (
                  <>
                    {searchCollection ? (
                      <Box className={classes.searchTag}>
                        <EllipsisTooltip text={searchCollection?.name} />
                        <Tooltip title="Search in all files">
                          <IconButton
                            disableRipple
                            onClick={() => setSearchCollection(null)}
                            sx={{ padding: 0 }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Box className={classes.searchTag}>
                        <Tooltip title="Search in current collection">
                          <IconButton
                            disableRipple
                            onClick={() =>
                              setSearchCollection(activeCollection)
                            }
                            sx={{ padding: 0 }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </>
                )
              }}
            /> */}
          </Box>
          <Box className={classes.searchButtonWrapper}>
            <ViewMode
              disabled={tableLoading}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
            <TagFilter
              tags={{
                // file_type: {
                //   values: fileTypeOptions,
                //   type: 'default',
                //   editable: false
                // },
                file_extension: {
                  values: fileExternsionOptions,
                  type: 'default',
                  editable: false,
                  label: 'Extension'
                },
                created_by: {
                  values: domainUserNames,
                  type: 'default',
                  editable: false
                },
                created_on: {
                  type: 'multidate',
                  editable: false
                },
                hidden_from_search: {
                  values: [
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ],
                  type: 'defaultsingle',
                  editable: false
                },
                ...tagsOptions
              }}
              showButton={true}
              showTags={false}
              disabled={tableLoading}
              selectedTags={selectedTags}
              onChange={handleTagsValueChange}
              clearFilter={
                isFiltersApplied && (
                  <Button2
                    secondary
                    onClick={() => {
                      if (!tableLoading) {
                        setSelectedTags(null)
                        saveDataToLocal(null, activeCollection?.id)
                        setSearchValue('')
                        handleSearchQuery('clear')
                      }
                    }}
                  >
                    <CrossIcon className="size-4 text-grey-500 " />
                    clear filter
                  </Button2>
                )
              }
              filterIcon={
                !isFiltersApplied && (
                  <Button2 secondary disabled={tableLoading}>
                    <FilterListIcon
                      style={{
                        height: '16px',
                        width: '16px'
                      }}
                    />
                    Filter
                  </Button2>
                )
              }
            />

            {viewMode === 'grid' && (
              <GridSort
                disabled={tableLoading}
                sortOrder={sortOrder}
                columns={visibleColumns.filter(
                  (item) => !hiddenColumns.includes(item)
                )}
                GetSortIcon={GetSortIcon}
                handleColumnClick={handleSortColumnClick}
              />
            )}

            <Button2 primary onClick={() => handleUploaderClick()}>
              <CloudUpload
                disabled={isUserViewer}
                style={{
                  height: '16px',
                  width: '16px'
                }}
              />
              Upload
            </Button2>
          </Box>
        </Box>
        <Box></Box>
        {selectedTags && Object.keys(selectedTags).length > 0 && (
          <Box className={classes.tagsWrapper}>
            <TagFilter
              tags={{
                // file_type: {
                //   values: fileTypeOptions,
                //   type: 'default',
                //   editable: false
                // },
                file_extension: {
                  values: fileExternsionOptions,
                  type: 'default',
                  editable: false,
                  label: 'Extension'
                },
                created_by: {
                  values: domainUserNames,
                  type: 'default',
                  editable: false
                },
                created_on: {
                  type: 'multidate',
                  editable: false
                },
                hidden_from_search: {
                  values: [
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ],
                  type: 'defaultsingle',
                  editable: false
                },
                ...tagsOptions
              }}
              showButton={false}
              showTags={true}
              disabled={tableLoading}
              selectedTags={selectedTags}
              onChange={handleTagsValueChange}
              clearFilter={<></>}
            />
          </Box>
        )}
      </SectionFixed>
      <Section overFlow id={scrollDivId}>
        {tableLoading ? (
          <TableLoader viewMode={viewMode} count={30} />
        ) : (
          <InfiniteScroll
            dataLength={rows?.length || 0}
            next={() => fetchData(page)}
            hasMore={hasMoreData}
            loader={
              fetchingData && (
                <TableLoader viewMode={viewMode} count={10} hideHeader />
              )
            }
            scrollableTarget={scrollDivId}
            style={{ overflow: 'inherit' }}
          >
            {viewMode === 'grid' ? (
              <Box>
                <Divider />
                {!isFiltersApplied &&
                  collectionsRows &&
                  collectionsRows?.length > 0 &&
                  collectionsRows?.length > 0 && (
                    <Box>
                      <Box className={classes.gridTitleText}>Collections</Box>
                      <JustifiedLayout
                        items={Array(collectionsRows.length).fill(1)}
                        options={{
                          containerPadding: {
                            top: 20,
                            right: 50,
                            bottom: 20,
                            left: 50
                          },
                          boxSpacing: 5,
                          targetRowHeight: 150,
                          targetRowHeightTolerance: 0.1
                        }}
                      >
                        {(items) =>
                          bricks(
                            items,
                            {
                              ...props,
                              getMenuOptions,
                              handleRowClick: handleOpenCollection,
                              handleDragEnd,
                              handleDragOver,
                              handleDragStart,
                              draggable,
                              onClickRow,
                              selectedRow
                            },
                            collectionsRows
                          )
                        }
                      </JustifiedLayout>
                    </Box>
                  )}
                <Box>
                  {aspectRatioArray &&
                    aspectRatioArray.length > 0 &&
                    rows &&
                    rows.length > 0 && (
                      <>
                        <Box className={classes.gridTitleText}>Files</Box>
                        <JustifiedLayout
                          items={aspectRatioArray}
                          options={{
                            containerPadding: {
                              top: 20,
                              right: 50,
                              bottom: 20,
                              left: 50
                            },
                            boxSpacing: 5,
                            targetRowHeight: 150,
                            targetRowHeightTolerance: 0.1
                          }}
                        >
                          {(items) =>
                            bricks(
                              items,
                              {
                                ...props,
                                getMenuOptions,
                                handleRowClick,
                                handleDragEnd,
                                handleDragOver,
                                handleDragStart,
                                draggable,
                                onClickRow,
                                selectedRow
                              },
                              rows
                            )
                          }
                        </JustifiedLayout>
                      </>
                    )}
                </Box>
                {(isFiltersApplied || collectionsRows?.length === 0) &&
                  rows?.length === 0 && (
                    <Box className={classes.emptyDataWrapper}>
                      No data found
                    </Box>
                  )}
              </Box>
            ) : (
              <table
                className={classes.tableWrapper}
                style={{ width: visibleColumnPercentageTotal + '%' }}
              >
                <ReactResizeDetector handleWidth>
                  {({ width }) => (
                    <>
                      <colgroup>
                        {[...visibleColumns, 'menu_button'].map((column) => {
                          const resizeWidth = getColWidth(
                            columnWidths[column],
                            column
                          )
                          return (
                            <col key={column} style={{ width: resizeWidth }} />
                          )
                        })}
                      </colgroup>
                      <thead>
                        <tr>
                          {visibleColumns.map(
                            (key) =>
                              !defaultHidden.includes(key) && (
                                <ResizableCell
                                  parentWidth={width}
                                  key={key}
                                  onResize={(width) => handleResize(key, width)}
                                  width={getColWidth(columnWidths[key], key)}
                                  id={key}
                                  isResizing={isResizing}
                                  setIsResizing={setIsResizing}
                                  isHovering={isHovering}
                                  setIsHovering={setIsHovering}
                                  tableComponent="header"
                                >
                                  <Box className={classes.tableHeaderText}>
                                    <Box>{key && formatHeader(key || '')}</Box>
                                    {!Object.keys(
                                      tagsCenterStateTags || {}
                                    ).includes(key) && (
                                      <GetSortIcon
                                        id={key}
                                        isSelected={Object.keys(
                                          sortOrder
                                        ).includes(key)}
                                        order={sortOrder[key]}
                                      />
                                    )}
                                  </Box>
                                </ResizableCell>
                              )
                          )}
                          <th
                            className={clsx(classes.tableHeader)}
                            style={{
                              position: 'sticky',
                              zIndex: 10,
                              borderLeft: '1px solid #e4e4e7'
                            }}
                          >
                            <Box>
                              <HideColumn
                                visibleColumns={visibleColumns}
                                disabled={tableLoading}
                                columns={columns}
                                setColumns={setColumns}
                                hiddenColumns={hiddenColumns}
                                setHiddenColumns={setHiddenColumns}
                                defaultHidden={defaultHidden}
                                handleSaveView={handleSaveView}
                                handleClearView={handleClearView}
                                viewMode={viewMode}
                                handleOpenAddNewKey={handleOpenAddNewKey}
                                isAdmin={isAdmin}
                                canCreateTags={canCreateTags}
                              />
                            </Box>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isFiltersApplied &&
                          collectionsRowsRef.current?.map((row, index) => {
                            const {
                              tags = [],
                              isRecent = false,
                              other_data,
                              id
                            } = row || {}
                            const { loading: rowLoading = false } =
                              other_data || {}
                            const tagsObj = {}
                            const isSelected =
                              selectedRow.filter((item) => item.id === id)
                                .length > 0
                            const isEditable =
                              !rowLoading &&
                              isSelected &&
                              editRows &&
                              editRows === id
                            tags.forEach((item) => {
                              if (!tagsObj[item.tag_key]) {
                                tagsObj[item.tag_key] = []
                              }
                              tagsObj[item.tag_key].push({
                                label: item.tag_value,
                                value: item.id
                              })
                            })
                            const menuOptions = getMenuOptions(row)
                            return (
                              <>
                                <tr
                                  key={index}
                                  className={
                                    isSelected ? classes.tableRowActive : ''
                                  }
                                  draggable={!rowLoading && draggable}
                                  onDragStart={(event) =>
                                    handleDragStart(event, row)
                                  }
                                  onDragOver={handleDragOver}
                                  onDragEnd={handleDragEnd}
                                  onClick={(e) => {
                                    if (!rowLoading) {
                                      onClickRow(row, e)
                                    }
                                  }}
                                  onDoubleClick={() => {
                                    if (!rowLoading) {
                                      handleOpenCollection(row)
                                    }
                                  }}
                                  style={{
                                    background: rowLoading ? 'inherit' : '',
                                    opacity: rowLoading ? '0.38' : 1,
                                    cursor: rowLoading ? 'inherit' : 'pointer'
                                  }}
                                >
                                  {visibleColumns.map((key) => {
                                    if (validDefaultColumns.includes(key)) {
                                      return (
                                        <ResizableCell
                                          key={key}
                                          parentWidth={width}
                                          onResize={(width) =>
                                            handleResize(key, width)
                                          }
                                          width={getColWidth(
                                            columnWidths[key],
                                            key
                                          )}
                                          id={key}
                                          isResizing={isResizing}
                                          setIsResizing={setIsResizing}
                                          tableComponent="row"
                                          isHovering={isHovering}
                                          setIsHovering={setIsHovering}
                                        >
                                          {key === 'file_name' ? (
                                            <Box
                                              className={classes.nameRowWrapper}
                                            >
                                              <Box
                                                className={
                                                  classes.tableRowTextFileName
                                                }
                                              >
                                                <FileIcon
                                                  s3Obj={s3Obj}
                                                  row={row}
                                                  handleShowImage={
                                                    handleShowImage
                                                  }
                                                />
                                                <Box>
                                                  <FileInputText
                                                    disabled={!isEditable}
                                                    defaultValue={row[key]}
                                                    onBlur={(value) => {
                                                      handleUpdateCollections(
                                                        value,
                                                        row[key],
                                                        row,
                                                        key
                                                      )
                                                    }}
                                                  />
                                                  {isRecent && (
                                                    <Box
                                                      className={
                                                        classes.recentText
                                                      }
                                                    >
                                                      (Recently Added)
                                                    </Box>
                                                  )}
                                                </Box>
                                              </Box>
                                            </Box>
                                          ) : (
                                            <Box
                                              className={classes.tableRowText}
                                            >
                                              {renderKeyValues(key, row[key])}
                                            </Box>
                                          )}
                                        </ResizableCell>
                                      )
                                    } else if (
                                      Object.keys(tagsOptions || {}).includes(
                                        key
                                      )
                                    ) {
                                      const tagsOption = []
                                      if (tagsObj[key]) {
                                        tagsObj[key].forEach((item) => {
                                          tagsOption.push(item.label)
                                        })
                                      }
                                      return (
                                        <ResizableCell
                                          key={key}
                                          parentWidth={width}
                                          onResize={(width) =>
                                            handleResize(key, width)
                                          }
                                          width={getColWidth(
                                            columnWidths[key],
                                            key
                                          )}
                                          id={key}
                                          isResizing={isResizing}
                                          setIsResizing={setIsResizing}
                                          tableComponent="row"
                                          isHovering={isHovering}
                                          setIsHovering={setIsHovering}
                                        >
                                          <Box className={classes.tableRowText}>
                                            {tagsOption.join(', ')}
                                          </Box>
                                        </ResizableCell>
                                      )
                                    }
                                    return <></>
                                  })}
                                  <td
                                    className={clsx(
                                      classes.tableRow,
                                      classes.menuButton
                                    )}
                                  >
                                    <MenuButton
                                      disabled={rowLoading}
                                      options={menuOptions}
                                      callBackData={row}
                                      menuOpenCallback={onClickRow}
                                    />
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                        {rowsRef.current?.map((row, index) => {
                          const {
                            tags = [],
                            isRecent = false,
                            other_data,
                            id
                          } = row || {}
                          const {
                            loading: rowLoading = false,
                            hidden_manually = false
                          } = other_data || {}
                          const isSelected =
                            selectedRow.filter((item) => item.id === id)
                              .length > 0
                          const isEditable =
                            !rowLoading &&
                            isSelected &&
                            editRows &&
                            editRows === id
                          const tagsObj = {}
                          tags.forEach((item) => {
                            if (!tagsObj[item.tag_key]) {
                              tagsObj[item.tag_key] = []
                            }
                            tagsObj[item.tag_key].push({
                              label: item.tag_value,
                              value: item.id
                            })
                          })
                          const menuOptions = getMenuOptions(row)
                          return (
                            <>
                              <tr
                                key={index}
                                className={
                                  isSelected ? classes.tableRowActive : ''
                                }
                                onClick={(e) => {
                                  if (!rowLoading) {
                                    onClickRow(row, e)
                                  }
                                }}
                                onDoubleClick={(e) => {
                                  if (!rowLoading) {
                                    handleRowClick(row, index)
                                  }
                                  e.stopPropagation()
                                  e.preventDefault()
                                }}
                                draggable={!rowLoading && draggable}
                                onDragStart={(event) =>
                                  handleDragStart(event, row)
                                }
                                onDragOver={handleDragOver}
                                onDragEnd={handleDragEnd}
                                style={{
                                  background: rowLoading ? 'inherit' : '',
                                  opacity: rowLoading ? '0.38' : 1,
                                  cursor: rowLoading ? 'inherit' : 'pointer'
                                }}
                              >
                                {visibleColumns.map((key) => {
                                  if (validDefaultColumns.includes(key)) {
                                    return (
                                      <ResizableCell
                                        key={key}
                                        parentWidth={width}
                                        onResize={(width) =>
                                          handleResize(key, width)
                                        }
                                        width={getColWidth(
                                          columnWidths[key],
                                          key
                                        )}
                                        id={key}
                                        isResizing={isResizing}
                                        setIsResizing={setIsResizing}
                                        tableComponent="row"
                                        isHovering={isHovering}
                                        setIsHovering={setIsHovering}
                                        disabled={rowLoading}
                                      >
                                        {key === 'file_name' ? (
                                          <Box
                                            className={classes.nameRowWrapper}
                                          >
                                            <Box
                                              className={
                                                classes.tableRowTextFileName
                                              }
                                            >
                                              <FileIcon
                                                s3Obj={s3Obj}
                                                row={row}
                                                showPreview={true}
                                                handleShowImage={
                                                  handleShowImage
                                                }
                                              />
                                              <Box>
                                                <FileInputText
                                                  disabled={!isEditable}
                                                  defaultValue={row[key]}
                                                  onBlur={(value) => {
                                                    handleUpdateRows(
                                                      value,
                                                      row[key],
                                                      row,
                                                      key
                                                    )
                                                  }}
                                                />
                                                {hidden_manually && (
                                                  <Tooltip title="Hidden from Search">
                                                    <Box
                                                      className={
                                                        classes.recentText
                                                      }
                                                    >
                                                      <SearchOffIcon />
                                                    </Box>
                                                  </Tooltip>
                                                )}
                                                {isRecent && (
                                                  <Box
                                                    className={
                                                      classes.recentText
                                                    }
                                                  >
                                                    (Recently Added)
                                                  </Box>
                                                )}
                                              </Box>
                                            </Box>
                                            {row?.other_data?.notes && (
                                              <HtmlTooltip
                                                title={
                                                  <React.Fragment>
                                                    {row?.other_data?.notes
                                                      ?.split('\n')
                                                      .map((item, index) => {
                                                        return (
                                                          <div key={index}>
                                                            {item}
                                                          </div>
                                                        )
                                                      })}
                                                  </React.Fragment>
                                                }
                                              >
                                                <IconButton disableRipple>
                                                  <InfoIcon
                                                    sx={{
                                                      fontSize: '18px'
                                                    }}
                                                  />
                                                </IconButton>
                                              </HtmlTooltip>
                                            )}
                                          </Box>
                                        ) : (
                                          <Box className={classes.tableRowText}>
                                            {renderKeyValues(key, row[key])}
                                          </Box>
                                        )}
                                      </ResizableCell>
                                    )
                                  } else if (
                                    Object.keys(tagsOptions || {}).includes(key)
                                  ) {
                                    const {
                                      data = [],
                                      type,
                                      value_type
                                    } = tagsCenterStateTagsAll[key] || {}
                                    const isMulti = type.includes('multi')
                                    const isDate = type.includes('date')
                                    const isURL = type.includes('url')
                                    const values = []
                                    const tagsOption = []
                                    if (tagsObj[key]) {
                                      tagsObj[key].forEach((item) => {
                                        tagsOption.push(
                                          isDate
                                            ? moment(item.label).format('ll')
                                            : item.label
                                        )
                                        values.push({
                                          value: item.value,
                                          label: item.label
                                        })
                                      })
                                    }
                                    const options = data
                                      .filter((item) => {
                                        return item.value
                                      })
                                      .map((item) => {
                                        return {
                                          value: item.id,
                                          label: item.value
                                        }
                                      })
                                    const { id } = row
                                    let defaultValue =
                                      selectedTagValues[id] &&
                                      selectedTagValues[id][key]
                                        ? selectedTagValues[id][key]
                                        : values
                                    defaultValue = isMulti
                                      ? defaultValue
                                      : defaultValue[0]
                                    const isDocumentType =
                                      value_type === 'document_type'

                                    return (
                                      <ResizableCell
                                        key={key}
                                        parentWidth={width}
                                        onResize={(width) =>
                                          handleResize(key, width)
                                        }
                                        width={getColWidth(
                                          columnWidths[key],
                                          key
                                        )}
                                        id={key}
                                        isResizing={isResizing}
                                        setIsResizing={setIsResizing}
                                        tableComponent="row"
                                        isHovering={isHovering}
                                        setIsHovering={setIsHovering}
                                      >
                                        <Box
                                          className={clsx(
                                            classes.rowTagsWrapper
                                          )}
                                          onDoubleClick={(e) => {
                                            e.stopPropagation()
                                          }}
                                        >
                                          <FileInputTags
                                            isDocumentType={isDocumentType}
                                            disabled={!isEditable}
                                            baseComponent={
                                              <Box
                                                className={
                                                  rowLoading
                                                    ? classes.tableRowText
                                                    : clsx(
                                                        classes.tableRowText,
                                                        classes.nameHoverWrapper
                                                      )
                                                }
                                              >
                                                <TextLineLimiter
                                                  content={tagsOption.join(
                                                    ', '
                                                  )}
                                                  limit={1}
                                                  wordBreak={'break-word'}
                                                  showMore={false}
                                                />
                                                {/* {tagsOption.join(', ')}&nbsp; */}
                                              </Box>
                                            }
                                            baseComponentOptions={tagsOption}
                                            defaultValue={defaultValue}
                                            onChange={(values) =>
                                              handleTagChange(
                                                values,
                                                isMulti,
                                                row,
                                                key
                                              )
                                            }
                                            onBlur={() => {
                                              handleTagsUpdate(
                                                row,
                                                key,
                                                isDocumentType
                                              )
                                            }}
                                            tagType={type}
                                            onDateTagChange={(e) =>
                                              onDateTagChange(e, type, key, row)
                                            }
                                            options={options}
                                            isMulti={isMulti}
                                            isDate={isDate}
                                            tagsCenterStateTags={
                                              tagsCenterStateTagsAll
                                            }
                                            tagKey={key}
                                            isURL={isURL}
                                            dispatch={dispatch}
                                            canCreateTags={canCreateTags}
                                          />
                                        </Box>
                                      </ResizableCell>
                                    )
                                  }
                                  return <></>
                                })}
                                <td
                                  className={clsx(
                                    classes.tableRow,
                                    classes.menuButton
                                  )}
                                >
                                  <MenuButton
                                    disabled={rowLoading}
                                    options={menuOptions}
                                    callBackData={row}
                                    menuOpenCallback={onClickRow}
                                  />
                                </td>
                              </tr>
                            </>
                          )
                        })}
                        {(isFiltersApplied ||
                          collectionsRowsRef.current?.length === 0) &&
                          rowsRef?.length === 0 && (
                            <tr>
                              <td
                                className={classes.tableRow}
                                colSpan={visibleColumns.length + 1}
                              >
                                <Box className={classes.emptyDataWrapper}>
                                  No data found
                                </Box>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </>
                  )}
                </ReactResizeDetector>
              </table>
            )}
          </InfiniteScroll>
        )}
      </Section>
      <CollectionEdit
        isOpen={!!collectionEdit}
        onClose={() => setCollectionEdit(null)}
        buttonFunction={handleCollectionUpdate}
        collection={collectionEdit}
      />
      <Dialog
        fullScreen
        sx={{ margin: '5vh' }}
        disableEnforceFocus={true}
        open={!!selectedFile}
        onClose={() => setSelectedFile(null)}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <Box className={classes.fileViwerWrapper}>
          <Box
            className={classes.fileChangeIcons}
            onClick={() => setSelectedFile(null)}
          >
            <IconButton
              disableRipple
              disabled={!prevFile}
              onClick={(e) => {
                handleFileSelect(prevFile)
                e.stopPropagation()
              }}
            >
              <Tooltip title="Previous File">
                <KeyboardArrowLeftIcon />
              </Tooltip>
            </IconButton>
          </Box>
          <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <ResourceFileView
              s3Obj={s3Obj}
              file={selectedFile}
              setFile={setSelectedFile}
              onClose={() => setSelectedFile(null)}
              searchKey={searchValue}
              isSuperUser={isSuperUser}
              isAdmin={isAdmin}
              onDeleteCallback={fileViewerCallbackDelete}
              onUpdateCallback={fileViewerCallbackUpdate}
              activeCollection={activeCollection}
            />
          </Box>
          <Box
            className={classes.fileChangeIcons}
            onClick={() => setSelectedFile(null)}
          >
            <IconButton
              disableRipple
              disabled={!nextFile}
              onClick={(e) => {
                handleFileSelect(nextFile)
                e.stopPropagation()
              }}
            >
              <Tooltip title="Next File">
                <KeyboardArrowRightIcon />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
      </Dialog>
      <ResourceAddToCollection
        isOpen={showAddToCollection}
        handleClose={() => setShowAddToCollection(null)}
        actionButtons={[
          {
            label: 'Copy',
            action: (e) => addToCollection(e, 'copy')
          },
          {
            label: 'Move',
            action: (e) => addToCollection(e, 'move'),
            condition: !isRootCollection
          }
        ]}
        singleSelect
        showRoot
      />
      <AddNewKey
        isOpen={openAddNewKey}
        onClose={handleCloseAddNewKey}
        source={'document'}
      />
      {renderMultiSelectControl()}
      {renderTagDrawer()}
      {ConfirmDialog}
      {imageGalleryImages.length > 0 && (
        <ImageGallery
          images={imageGalleryImages}
          onClose={() => setImageGalleryImages([])}
        />
      )}
      {renderShareDialog()}
    </Container>
  ) : (
    <Loader loading={loading} caption={''} flex />
  )
}
