import React from 'react'
import PropTypes from 'prop-types'
import Blanket from './Blanket'
import Menu from './Menu'

export default function Dropdown({
  children,
  isOpen,
  target,
  onClose,
  rightaligned
}) {
  return (
    <div
      id="multiCheckboxWrapper"
      style={{ position: 'relative', width: '100%', height: '100%' }}
    >
      {target}
      {isOpen && <Menu rightaligned={rightaligned}> {children}</Menu>}
      {isOpen && <Blanket onClick={onClose} />}
    </div>
  )
}

Dropdown.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  target: PropTypes.node,
  onClose: PropTypes.func,
  rightaligned: PropTypes.bool
}
Dropdown.defaultProps = {
  children: null,
  isOpen: false,
  target: null,
  onClose() {}
}
