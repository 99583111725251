import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  childContainer: {
    height: 'calc(100% - 40px)',
    width: 'calc(100% - 40px)',
    padding: '20px'
  },
  textFeild: {
    width: '100%',
    maxWidth: '400px',
    marginTop: '10px'
  },
  textFeildLabel: {
    marginTop: '20px'
  },
  titleText: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#797979'
  },
  subText: {
    fontSize: '16px',
    fontWeight: '400',
    marginTop: '10px',
    color: '#797979'
  },
  dialogWrapper: {
    width: 'calc(100% - 20px)',
    backgroundColor: 'white',
    padding: '10px',
    overflow: 'auto',
    maxWidth: '1000px',
    margin: 'auto'
  },
  dialogTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    alignItems: 'flex-start'
  },
  dialogButtonWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center'
  },
  dailogContent: {
    padding: '0px 20px'
  },
  dailogContentTitle: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#000'
  },
  dailogContentSubTitle: {
    fontSize: '16px',
    fontWeight: '400',
    marginTop: '10px',
    color: '#000'
  },
  optionsWrapper: {
    padding: '10px 0px'
  },
  traitWrapper: {
    display: 'flex',
    gap: '5px'
  },
  trait: {
    width: '100%'
  },
  voiceContainer: {
    padding: '10px'
  },
  traitHeader: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#000'
  },
  traitContent: {
    padding: '10px 0px'
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '5px'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    flexWrap: 'wrap'
  },
  traitbtn: {
    color: '#797979',
    borderRadius: '5px',
    padding: '6px 10px',
    marginTop: '5px',
    cursor: 'pointer',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      inset: '0',
      borderRadius: '4px',
      padding: '1px',
      background: 'linear-gradient(100deg, #471CA8, #FF3465)',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude'
    }
  },
  traitbtnSelected: {
    backgroundColor: '#000 !important',
    color: 'white !important',
    '&:before': {
      background: 'none'
    }
  },
  disabledOpacity: {
    opacity: '0.5'
  },
  marginTop: {
    marginTop: '10px'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap'
  },
  gradientTextStyle: {
    background: 'linear-gradient(100deg, #471CA8, #FF3465)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent !important',
    display: 'inline-block',
    '&:before': {
      content: 'attr(data-text)',
      position: 'absolute',
      left: '0',
      top: '0',
      zIndex: '-1'
    }
  },
  voiceTitle: {
    fontSize: '16px',
    color: '#000'
  },
  voiceUpdated: {
    fontSize: '12px',
    color: '#797979',
    margin: '5px 0px'
  },
  voiceWrapper: {
    padding: '10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  promptSearchWrapper: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  headerWrapper: {
    color: 'white',
    textAlign: 'right',
    marginTop: '30px',
    marginBottom: '20px',
    padding: '0px 10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  hideMenuListText: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#000000',
    width: '100px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word'
  },
  hideMenu: {
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    },
    '& .MuiButtonBase-root': {
      padding: '3px 5px'
    },
    '& .MuiMenu-paper': {
      border: '1px solid #E5E5E5',
      marginTop: '10px'
    },
    '& .MuiList-root': {
      padding: '8px'
    }
  },
  iconButton: {
    padding: '0px !important'
  },
  icon: {
    fontSize: '20px !important',
    color: '#797979'
  },
  noData: {
    fontSize: '16px',
    color: '#797979',
    padding: '20px',
    fontStyle: 'italic',
    textAlign: 'center'
  },
  iconContainer: {
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    }
  }
})
export { useStyles }
