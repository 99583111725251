import {
  UPDATE_WORKSPACE,
  DELETE_WORKSPACE,
  CREATE_WORKSPACE
} from '../../../actionTypes'
import { editWorkspace } from '../../../api'
import { toast } from 'react-toastify'

export const updateWorkspace =
  (request, successCallback = () => {}, failureCallback = () => {}) =>
  async (dispatch) => {
    const { id, mode } = request
    if (mode === 'create') {
      dispatch({
        type: CREATE_WORKSPACE,
        payload: { ...request, status: 'creating', isRecent: true }
      })
    } else {
      dispatch({
        type: UPDATE_WORKSPACE,
        payload: { id, status: 'updating' }
      })
    }
    const response = await editWorkspace(request)
    if (response.status === 200) {
      if (mode === 'delete') {
        dispatch({
          type: DELETE_WORKSPACE,
          payload: { id },
          callback: successCallback
        })
      } else if (mode === 'update') {
        dispatch({
          type: UPDATE_WORKSPACE,
          payload: { ...request, status: false },
          callback: successCallback
        })
      } else if (mode === 'create') {
        dispatch({
          type: UPDATE_WORKSPACE,
          payload: { ...request, status: false },
          callback: successCallback
        })
      }
    } else {
      toast.error(`Workspace ${mode} failed`)
      if (mode === 'create') {
        dispatch({
          type: DELETE_WORKSPACE,
          payload: { id },
          callback: failureCallback
        })
      } else {
        dispatch({
          type: UPDATE_WORKSPACE,
          payload: { id, status: false },
          callback: failureCallback
        })
      }
    }
  }

export default updateWorkspace
