import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  field: {
    margin: '10px 0px',
    width: '100%',
    '& button': {
      padding: '8.5px 14px !important'
    }
  },
  inputLabel: {
    fontFamily: 'PoppinsRegular',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    marginBottom: '5px'
  }
})

export { useStyles }
