import React, { useEffect, useState } from 'react'
import { ImageAvatarFallback, Loader } from '../../components'
import { DocumentIcon, PencilIcon } from '../../components/Icons/Icons'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import DisplayTags from '../../sections/ResumeResult/DisplayTags'
import InfiniteScroll from 'react-infinite-scroll-component'

const PeopleTable = ({
  isSuperUser,
  fetchNextPage,
  hasNext,
  pageLoading,
  loading,
  displayPeople,
  selectedPeople,
  isMerge,
  setSelectedPeople,
  s3Obj,
  setResumeOpen,
  setShowTagDrawer,
  handleMasterResumeNameUpdate
}) => {
  const navigate = useNavigate()
  const [editName, setEditName] = useState(null)
  // get if last row is visible

  const handleSetSelectedPeople = (id) => {
    if (selectedPeople.includes(id)) {
      setSelectedPeople(selectedPeople.filter((person) => person !== id))
    } else {
      setSelectedPeople([...selectedPeople, id])
    }
  }

  useEffect(() => {
    const scrollPosition = localStorage.getItem('scrollPosition')
    if (scrollPosition) {
      setTimeout(() => {
        document.getElementById('scrollableDiv').scrollTop =
          parseInt(scrollPosition)
      }, 200)
    }
  }, [])

  const handleOpenPerson = (index) => {
    const person = displayPeople[index]
    // get scrolled position of scrollablediv

    localStorage.setItem(
      'scrollPosition',
      document.getElementById('scrollableDiv').scrollTop
    )

    setResumeOpen(true)

    navigate(
      `${ROUTES.LIBRARY_PEOPLE_RESUME}/${person?.id}/${person?.resumes[0]?.id}`
    )

    trackEvent(
      mixpanelEvents.PEOPLE_MASTER_RESUME_OPENED,
      'SUCCESS',
      {},
      { people_name: displayPeople?.[index]?.name }
    )
  }

  return (
    <div className="border-grey-200 rounded-lg relative">
      <div className="grid grid-col-4 gap-2 p-2 px-4 text-center bg-grey-100 text-xs sticky top-0 z-10 font-medium border-b-1 ">
        <div className=" w-full">Name</div>
        <div className="w-full">Tags</div>
        <div className=" w-full">Resumes</div>
      </div>
      {loading ? (
        <div className="grid place-content-center " style={{ height: '50vh' }}>
          Loading People Resume... <Loader />
        </div>
      ) : displayPeople?.length > 0 ? (
        <div className="grid text-center" id="rows">
          {displayPeople?.map((person, index) => {
            const {
              name,
              profile_picture_location,
              resumes = [],
              id,
              status,
              version
            } = person
            const checked = selectedPeople.includes(id)
            const isMerging = status === 'merging'
            const disabled = isMerge === 'loading' || isMerging
            const onClickBox = () => {
              if ((isMerge === true || isMerge === 'multitag') && !isMerging) {
                handleSetSelectedPeople(id)
              } else if (isMerge === false) {
                handleOpenPerson(index)
              }
            }
            return (
              <div
                className={`grid grid-col-4 gap-2 px-4 text-center text-sm border-b-1 p-2 row text-grey-600 ${
                  checked ? 'bg-grey-100' : ''
                }`}
                key={index}
                onClick={() => {
                  if (editName?.id) return
                  if (selectedPeople.length > 0) {
                    handleSetSelectedPeople(id)
                  } else {
                    handleOpenPerson(index)
                  }
                }}
              >
                <div className="font-medium w-full flex items-center gap-2 text-capitalize parent-card">
                  <div
                    className="flex items-center h-full cursor-pointer py-1 mr-2"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleSetSelectedPeople(id)
                    }}
                  >
                    <input
                      type="checkbox"
                      className={`cursor-pointer checkbox-ui border-grey-200 rounded-md w-3 h-3  ${
                        checked ? 'bg-grey-700' : ''
                      }`}
                      checked={checked}

                      // onChange={(e) => {
                      //   e.stopPropagation()
                      // }}
                    />
                  </div>
                  <ImageAvatarFallback
                    name={name}
                    profilePic={profile_picture_location}
                    style={{
                      height: '30px',
                      width: '30px'
                    }}
                    openImage={() => {}}
                    s3Obj={s3Obj}
                  />

                  {editName?.id === id ? (
                    <input
                      type="text input-base capitalize"
                      autoFocus
                      value={editName?.name}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      onChange={(e) => {
                        e.stopPropagation()
                        setEditName({
                          id,
                          name: e.target.value
                        })
                      }}
                      onBlur={(e) => {
                        e.stopPropagation()
                        if (
                          e.target.value.toLowerCase() !== name.toLowerCase()
                        ) {
                          handleMasterResumeNameUpdate(id, e.target.value)
                        }
                        setEditName(null)
                      }}
                    />
                  ) : (
                    <p className=" flex items-center gap-2 m-0">
                      {name.toLowerCase()}
                      <span
                        onClick={(e) => {
                          e.stopPropagation()
                          setEditName({
                            id,
                            name: name.toLowerCase()
                          })
                        }}
                      >
                        {selectedPeople.length === 0 && (
                          <PencilIcon className="size-4 card-options text-grey-400 mt-1" />
                        )}{' '}
                      </span>
                    </p>
                  )}
                </div>

                <div
                  className="w-full grid place-content-center text-xs"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <DisplayTags
                    tags={person?.tags}
                    setShowTagDrawer={() => setShowTagDrawer(id)}
                  />
                </div>
                <div className="font-medium w-full grid place-content-center text-xs">
                  {resumes.length}
                </div>

                <div className="w-full flex items-center justify-center text-xs">
                  <DocumentIcon className="size-4" />{' '}
                  {isSuperUser && (
                    <span className="ml-2 text-grey-500 text-xxs">
                      v{version}
                    </span>
                  )}
                </div>
              </div>
            )
          })}
          {pageLoading && (
            <div className="grid place-content-center">
              <Loader
                loading={pageLoading}
                caption={'Loading More People'}
                flex
              />
            </div>
          )}
        </div>
      ) : (
        <div className="grid place-content-center " style={{ height: '50vh' }}>
          No Data found
        </div>
      )}
    </div>
  )
}

export default PeopleTable
