import { FETCH_RFX } from '../../../actionTypes'
import { getRFx } from '../../../api'
import _ from 'lodash'
const fetchRFx = (rfxId) => async (dispatch) => {
  const rfx = {}
  dispatch({
    type: FETCH_RFX,
    payload: rfx
  })

  let rfxData = {}
  const res = await getRFx(rfxId)
  if (res.status === 200) {
    const result = res.data
    rfxData = result
    dispatch({
      type: FETCH_RFX,
      payload: rfxData
    })
  }
}

export default fetchRFx
