import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { useStyles } from './styles'
import { cloneDeep, isArray } from 'lodash'
import ReactMultiSelectCheckboxes from '../../components/MultiSelect'
import DateRangePicker from '../../components/DateRangerPicker'

const Filter = (props) => {
  const {
    filterData,
    filter,
    selectedFilter = {},
    setSelectedFilter,
    dateRange = [null, null],
    setDateRange
  } = props
  const classes = useStyles()
  const { filterContainer, childContainer } = classes

  const handleSelect = (e, key) => {
    const newFilter = cloneDeep(selectedFilter)
    const temp = []
    if (isArray(e) && e.length > 0) {
      e.forEach((arr) => {
        temp.push(arr.value)
      })
      newFilter[key] = temp
    } else {
      delete newFilter[key]
    }
    setSelectedFilter(newFilter)
  }

  const getValue = (val) => {
    const value = []
    selectedFilter[val]?.forEach((v) => {
      value.push({ label: v, value: v })
    })
    return value
  }
  const handleDateSelect = (e) => {
    setDateRange(e)
  }

  return (
    <Box className={filterContainer}>
      {Object.keys(filterData).map((key, indexFilterData) => {
        const options = []
        filterData[key]?.data?.forEach((opt, indexOption) => {
          options.push({ label: opt, value: opt })
        })
        return (
          <Box key={indexFilterData} className={childContainer}>
            <ReactMultiSelectCheckboxes
              onChange={(e) => {
                handleSelect(e, key)
              }}
              options={options}
              value={getValue(key)}
              placeholderButtonLabel={filterData[key].label}
            />
          </Box>
        )
      })}
      {setDateRange && (
        <Box className={childContainer}>
          <DateRangePicker setRange={handleDateSelect} range={dateRange} />
        </Box>
      )}
    </Box>
  )
}

export default Filter
