import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchDomainSettings,
  updateDomainSettings
} from '../../store/Settings/Actions'
import {
  Box,
  InputLabel,
  IconButton,
  Tooltip,
  CircularProgress
} from '@mui/material'
import _ from 'lodash'
import {
  Button,
  Loader,
  ReactSelect,
  TextInput,
  Container,
  Section,
  useConfirmation,
  ImageRender,
  ImageGallery
} from '../../components'
import { useStyles } from './styles'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'
import { toast } from 'react-toastify'
import { initalizeS3, checkExpiry } from '../../utils/AWS'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

const updateDomainForm = ({ isFocused }) => {
  const dispatch = useDispatch()
  const {
    updateColor,
    updateNavigationColor,
    updateLogo,
    updateBackgroundWallpaper
  } = useColor()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()

  const domain = useSelector((state) => state?.settings?.domain)
  const [domainConfig, setDomainConfig] = useState({})
  const [loading, setLoading] = useState(true)
  const [s3Obj, sets3Obj] = useState({})
  const fileInputRef = useRef(null)
  const fileInputRefWallpaper = useRef(null)
  const [enableSave, setEnableSave] = useState(false)
  const [saveInProgress, setSaveInProgress] = useState(false)
  const [imageGallery, setImageGallery] = useState(false)
  const [imageGalleryIndex, setImageGalleryIndex] = useState(0)

  useEffect(() => {
    if (!_.isEqual(domainConfig, domain)) {
      setEnableSave(true)
    } else {
      setEnableSave(false)
    }
  }, [domainConfig])

  const classes = useStyles()

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    if (_.isEmpty(domain) && isFocused) {
      dispatch(fetchDomainSettings())
    }
  }, [isFocused])

  useEffect(() => {
    if (!_.isEmpty(domain)) {
      setDomainConfig(domain)
      setLoading(false)
    }
  }, [domain, isFocused])

  const {
    domain_id,
    domain_name,
    domain_policy,
    subdomain_name,
    theme_color = {},
    domain_logo = '',
    domain_wallpaper: domain_wallpaper_data = []
  } = domainConfig

  const domain_wallpaper = domain_wallpaper_data || []

  const { general = '#0645AD', navigation = '#131516' } = theme_color || {}

  const policyOptions = [
    {
      value: 'open_to_subdomain',
      label: `Allow all users with the same mail subdomain ${subdomain_name} to Join`
    },
    { value: 'invite_only', label: 'Allow only invited user to join' }
  ]
  const policyValue = policyOptions.find((item) => item.value === domain_policy)

  const handleLogoChange = (e) => {
    const newLogo = e.target.files[0]
    if (newLogo) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const image = new Image()
        image.onload = () => {
          const aspectRatio = image.width / image.height
          if (aspectRatio <= 0.95 || aspectRatio >= 1.05) {
            toast.error('Please choose a square aspect ratio image.')
          } else {
            const base64String = reader.result
            if (base64String) {
              setDomainConfig({
                ...domainConfig,
                domain_logo: base64String
              })
            }
          }
        }
        image.onerror = () => {
          toast.error('Failed to load the image. Please try again.')
        }
        image.src = reader.result
      }
      reader.onerror = () => {
        toast.error('Failed to read the file. Please try again.')
      }
      reader.readAsDataURL(newLogo)
    }
  }

  const handleWallpaperChange = (e) => {
    const newWallpapers = e.target.files
    if (newWallpapers) {
      const newWallpaperArray = Array.from(newWallpapers)
      const readFiles = newWallpaperArray.map((wallpaper) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64String = reader.result
            if (base64String) {
              resolve(base64String)
            } else {
              reject('Failed to convert file to base64 string.')
            }
          }
          reader.onerror = () => {
            reject('Failed to read the file. Please try again.')
          }
          reader.readAsDataURL(wallpaper)
        })
      })
      Promise.all(readFiles)
        .then((newWallpaper) => {
          setDomainConfig((prev) => ({
            ...prev,
            domain_wallpaper: [
              ...(prev.domain_wallpaper || []),
              ...newWallpaper
            ]
          }))
        })
        .catch((error) => {
          toast.error(error)
        })
    }
  }

  const handleUpdate = () => {
    setSaveInProgress(true)
    const req = {}
    _.forEach(domainConfig, (value, key) => {
      if (!_.isEqual(value, domain[key])) {
        req[key] = value
      }
    })
    const reqKeys = Object.keys(req)
    if (reqKeys.includes('domain_policy')) {
      trackEvent(
        mixpanelEvents.SETTINGS_USER_INVITED,
        'SUCCESS',
        {},
        { policy }
      )
    }

    const success = async () => {
      if (reqKeys.includes('theme_color')) {
        updateNavigationColor(req.theme_color.navigation)
        updateColor(req.theme_color.general)
      }
      if (reqKeys.includes('domain_logo')) {
        updateLogo(req.domain_logo)
      }
      if (reqKeys.includes('domain_wallpaper')) {
        const domainWallpaper = req.domain_wallpaper
        const randomWallpaper =
          domainWallpaper[Math.floor(Math.random() * domainWallpaper.length)]
        const signedUrl = await checkExpiry(randomWallpaper, s3Obj)
        updateBackgroundWallpaper(signedUrl)
      }
      setSaveInProgress(false)
    }
    const fail = () => {
      setSaveInProgress(false)
    }

    dispatch(updateDomainSettings(req, success, fail))
  }

  const handleReset = () => {
    showConfirmDialog({
      onConfirm: async () => {
        setSaveInProgress(true)
        const req = {
          theme_color: {
            general: '#0645AD',
            navigation: '#131516'
          },
          domain_logo: '',
          domain_wallpaper: []
        }

        const success = () => {
          updateNavigationColor(req.theme_color.navigation)
          updateColor(req.theme_color.general)
          updateLogo(req.domain_logo)
          updateBackgroundWallpaper('')
          setSaveInProgress(false)
        }
        const fail = () => {
          setSaveInProgress(false)
        }

        dispatch(updateDomainSettings(req, success, fail))
      },
      confirmationMessageTitle: `Are you sure you want to reset color theme, brand logo and
                  wallpaper to default?`
    })
  }

  const handleOpenImage = async (images, index) => {
    setImageGalleryIndex(index)
    const imagesObj = images.map((img) => ({ src: img }))
    await Promise.all(
      imagesObj.map(async (img, index) => {
        const signedSrc = await checkExpiry(img.src, s3Obj)
        imagesObj[index].src = signedSrc
      })
    )
    setImageGallery(imagesObj)
  }

  return loading ? (
    <Loader loading={loading} flex />
  ) : (
    <Container>
      <Section overFlow>
        <Box className={classes.container}>
          <Box>
            <InputLabel>Domain ID</InputLabel>
            <TextInput value={domain_id} disabled className={classes.text} />
          </Box>
          <Box className={classes.inputWrapper}>
            <InputLabel>Organization Name</InputLabel>
            <TextInput
              value={domain_name}
              className={classes.text}
              onChange={(e) =>
                setDomainConfig({
                  ...domainConfig,
                  domain_name: e.target.value
                })
              }
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <InputLabel>Organization Policy</InputLabel>
            <ReactSelect
              className={classes.text}
              options={policyOptions}
              value={policyValue}
              onChange={(e) =>
                setDomainConfig({ ...domainConfig, domain_policy: e.value })
              }
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <InputLabel>Subdomain</InputLabel>
            <TextInput
              value={subdomain_name}
              disabled
              className={classes.text}
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <InputLabel>Color Theme</InputLabel>
            <Box className={classes.colorWrapper}>
              <Box className={classes.color}>
                <input
                  type="color"
                  value={navigation}
                  onChange={(e) =>
                    setDomainConfig({
                      ...domainConfig,
                      theme_color: {
                        ...theme_color,
                        navigation: e.target.value
                      }
                    })
                  }
                />
                <Box>Navigation</Box>
              </Box>
              <Box className={classes.color}>
                <input
                  type="color"
                  value={general}
                  onChange={(e) =>
                    setDomainConfig({
                      ...domainConfig,
                      theme_color: {
                        ...theme_color,
                        general: e.target.value
                      }
                    })
                  }
                />
                <Box>General</Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.inputWrapper}>
            <InputLabel>
              Brand Logo (Square aspect ratio images only)
            </InputLabel>
            {domain_logo ? (
              <Box className={classes.imageWrapper}>
                <Box>
                  <ImageRender
                    className={classes.logo}
                    effect="blur"
                    src={domain_logo}
                    s3Obj={s3Obj}
                    showLoader={false}
                    onClick={() => handleOpenImage([domain_logo], 0)}
                  />
                </Box>
                <Tooltip title="Remove">
                  <IconButton
                    onClick={() =>
                      setDomainConfig({ ...domainConfig, domain_logo: '' })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <>
                <input
                  type="file"
                  accept="image/jpeg, image/png, image/jpg"
                  onChange={(e) => {
                    handleLogoChange(e)
                  }}
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
                <Button onClick={() => fileInputRef.current.click()}>
                  Choose File
                </Button>
              </>
            )}
          </Box>
          <Box className={classes.inputWrapper}>
            <InputLabel>Background Wallpaper</InputLabel>
            <Box className={classes.wallpaperWapper}>
              {domain_wallpaper?.length > 0 &&
                domain_wallpaper.map((wallpaper, index) => (
                  <Box className={classes.imageWrapper} key={index}>
                    <Box>
                      <ImageRender
                        className={classes.wallpaper}
                        effect="blur"
                        src={wallpaper}
                        s3Obj={s3Obj}
                        showLoader={false}
                        onClick={() => handleOpenImage(domain_wallpaper, index)}
                      />
                    </Box>
                    <Tooltip title="Remove">
                      <IconButton
                        onClick={() => {
                          const newWallpaper = domain_wallpaper.filter(
                            (item, i) => i !== index
                          )
                          setDomainConfig({
                            ...domainConfig,
                            domain_wallpaper: newWallpaper
                          })
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ))}
              <input
                type="file"
                accept="image/jpeg, image/png, image/jpg"
                onChange={(e) => {
                  handleWallpaperChange(e)
                }}
                multiple
                style={{ display: 'none' }}
                ref={fileInputRefWallpaper}
              />
              {domain_wallpaper?.length > 0 ? (
                <Tooltip title="Add More">
                  <IconButton
                    onClick={() => fileInputRefWallpaper.current.click()}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Button onClick={() => fileInputRefWallpaper.current.click()}>
                  Choose File
                </Button>
              )}
            </Box>
          </Box>
          <Box className={classes.inputWrapper}>
            <Box className={classes.colorWrapper}>
              <Button disabled={!enableSave} onClick={() => handleUpdate()}>
                {saveInProgress ? (
                  <CircularProgress size={18} color="secondary" />
                ) : (
                  'Update'
                )}
              </Button>
              <Button onClick={() => handleReset()}>Reset to defaults</Button>
            </Box>
          </Box>
        </Box>
        {ConfirmDialog}
        {imageGallery.length > 0 && (
          <ImageGallery
            images={imageGallery}
            onClose={() => setImageGallery([])}
            options={{ initialViewIndex: imageGalleryIndex }}
          />
        )}
      </Section>
    </Container>
  )
}
export default updateDomainForm
