import {
  ADD_TO_COLLECTIONS,
  UPDATE_ADDED_COLLECTIONS
} from '../../../actionTypes'
import { createCollections } from '../../../api'
import { toast } from 'react-toastify'

const collections = (req, callback) => async (dispatch) => {
  dispatch({
    type: ADD_TO_COLLECTIONS,
    payload: req,
    callback
  })
  const res = await createCollections(req)
  if (res.status === 200) {
    const newpayload = { ...req, status: 'active' }
    dispatch({
      type: UPDATE_ADDED_COLLECTIONS,
      payload: newpayload
    })
  } else {
    const newpayload = { ...req, status: 'failed' }
    toast.error('Unable to add new collection')
    dispatch({
      type: UPDATE_ADDED_COLLECTIONS,
      payload: newpayload
    })
  }
}

export default collections
