import { useState, useEffect } from 'react'
import AWS from 'aws-sdk'
import { Auth } from 'aws-amplify'
import { isEmpty, isString } from 'lodash'
import Loader from '../../components/Loader'
import ImageNotFound from '../../assets/images/ImageNotFound.svg'
import { Sha256 } from '@aws-crypto/sha256-js'
import AmazonS3URI from 'amazon-s3-uri'
import { SignatureV4 } from '@aws-sdk/signature-v4'

export const initalizeS3 = () => {
  return Auth.currentSession()
    .then((session) => {
      const jwt = session.getIdToken().getJwtToken()
      const IdentityPoolId = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID
      const idKey = process.env.REACT_APP_COGNITO_IDENITY_KEY
      AWS.config.update({
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId,
          Logins: { [idKey]: jwt }
        })
      })
      AWS.config.region = process.env.REACT_APP_AWS_COGNITO_REGION
      const s3 = new AWS.S3()
      return s3
    })
    .catch((error) => {
      console.log(error)
      return null
    })
}

export const initalizeAWSSigner = () => {
  return Auth.currentSession()
    .then((session) => {
      const jwt = session.getIdToken().getJwtToken()
      const IdentityPoolId = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID
      const idKey = process.env.REACT_APP_COGNITO_IDENITY_KEY

      AWS.config.region = process.env.REACT_APP_AWS_COGNITO_REGION
      const creds = new AWS.CognitoIdentityCredentials({
        IdentityPoolId,
        Logins: { [idKey]: jwt }
      })

      return creds.getPromise().then(() => {
        const signer = new SignatureV4({
          service: 'lambda',
          region: process.env.REACT_APP_AWS_COGNITO_REGION,
          credentials: creds,
          sha256: Sha256
        })
        return signer
      })
    })
    .catch((error) => {
      throw new Error('Credentials are invalid:', error)
    })
}

export const getSignedUrl = async (url, s3, filename) => {
  try {
    return new Promise((resolve, reject) => {
      if (!isEmpty(s3) && url && isString(url)) {
        const { region, bucket, key } = AmazonS3URI(url)
        if (bucket && key) {
          const params = {
            Bucket: bucket,
            Key: key,
            Expires: 3600
          }
          if (filename) {
            params.ResponseContentDisposition = `attachment; filename ="${filename}"`
          }
          s3.getSignedUrl('getObject', params, (err, signedUrl) => {
            if (err) {
              reject(err)
            }
            resolve(signedUrl)
          })
        }
      } else {
        resolve()
      }
    })
  } catch (e) {
    console.log(e)
  }
}

export const getSignedUrlHead = async (url, s3, isDownload) => {
  return new Promise((resolve, reject) => {
    if (!isEmpty(s3) && url && isString(url)) {
      const { region, bucket, key } = AmazonS3URI(url)
      if (bucket && key) {
        const params = {
          Bucket: bucket,
          Key: key
        }
        s3.headObject(params, (err, data) => {
          if (err) {
            reject(new Error('Object does not exist'))
          } else {
            const signedUrlParams = {
              Bucket: bucket,
              Key: key,
              Expires: 3600
            }
            if (isDownload) {
              signedUrlParams.ResponseContentDisposition = 'attachment'
            }
            s3.getSignedUrl('getObject', signedUrlParams, (err, signedUrl) => {
              if (err) {
                reject(err)
              }
              resolve(signedUrl)
            })
          }
        })
      }
    } else {
      resolve()
    }
  })
}

export const getSignedUrlWithoutS3 = (url) => {
  return initalizeS3().then((s3) => {
    if (!isEmpty(s3) && url && isString(url)) {
      const { region, bucket, key } = AmazonS3URI(url)
      if (bucket && key) {
        const params = {
          Bucket: bucket,
          Key: key,
          Expires: 3600
        }
        return new Promise((resolve, reject) => {
          s3.getSignedUrl('getObject', params, (err, signedUrl) => {
            if (err) {
              reject(err)
            } else {
              resolve(signedUrl)
            }
          })
        })
      }
    }
    return Promise.resolve()
  })
}

export const getVideoMeta = (url) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.src = url
    video.autoplay = true
    video.oncanplay = () => {
      resolve(video)
    }
    video.onerror = (e) => {
      console.log(e)
      reject()
    }
  })
}

function deepFind(obj, key) {
  if (obj === null || typeof obj !== 'object') return null
  if (obj.hasOwnProperty(key)) return obj[key]

  for (const i in obj) {
    if (obj[i] !== null && typeof obj[i] === 'object') {
      const found = deepFind(obj[i], key)
      if (found) return found
    }
  }
  return null
}

export const extractDimensions = async (
  rootObj,
  url,
  type,
  fetchFromImage = true
) => {
  try {
    const dimensions = deepFind(rootObj, 'dimensions')
    let width = ''
    let height = ''

    if (dimensions) {
      const match = dimensions.match(/(\d+) x (\d+)(?: px)?/)
      if (match) {
        height = parseInt(match[1], 10)
        width = parseInt(match[2], 10)
      }
    }
    if (fetchFromImage) {
      if ((width === '' || height === '') && url) {
        if (type === 'video') {
          const video = await getVideoMeta(url)
          width = video.videoWidth
          height = video.videoHeight
        } else {
          const img = await getImageMeta(url)
          width = img.width
          height = img.height
        }
      }
    }
    return { width, height }
  } catch (e) {
    return { width: '', height: '' }
  }
}

export const getImageMeta = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve(img)
    }
    img.onerror = (e) => {
      reject()
    }
    img.src = url
  })
}

export const checkExpiry = async (url, s3) => {
  if (url && s3 && url?.includes('amazonaws')) {
    const expiration = new URL(url).searchParams.get('Expires')
    if (!expiration || new Date(expiration * 1000) < new Date()) {
      return getSignedUrl(url, s3)
    }
  }
  return url
}

export const CheckAndRenderImage = ({
  style = {},
  className = '',
  key = '',
  src,
  onClick = () => {},
  s3Obj = {},
  overlay = <></>,
  fallback = '',
  ...props
}) => {
  const [imageUrl, setImageUrl] = useState(null)
  const [error, setError] = useState(false)
  const handleLoadFailure = (e) => {
    e.target.onerror = null
    if (fallback) {
      setError(true)
    } else {
      e.target.src = ImageNotFound
    }
  }

  useEffect(() => {
    async function getImageUrl() {
      const signedSrc = await checkExpiry(src, s3Obj)
      setImageUrl(signedSrc)
    }
    getImageUrl()
  }, [src])

  return !imageUrl ? (
    <div
      className={className}
      key={key}
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style
      }}
    >
      <Loader loading={true} caption={''} />
    </div>
  ) : error ? (
    <>{fallback}</>
  ) : (
    <>
      {overlay}
      <img
        className={className}
        style={style}
        src={imageUrl}
        key={key}
        onClick={onClick}
        onError={handleLoadFailure}
        {...props}
      />
    </>
  )
}

export const checkS3File = async (url, s3) => {
  return new Promise((resolve, reject) => {
    if (!isEmpty(s3) && url && isString(url)) {
      const { bucket, key } = AmazonS3URI(url)
      if (bucket && key) {
        const params = {
          Bucket: bucket,
          Key: key
        }
        s3.headObject(params, (err, data) => {
          if (err) {
            resolve(false)
          } else {
            resolve(true)
          }
        })
      }
    } else {
      resolve(false)
    }
  })
}
