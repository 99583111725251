import { cloneDeep } from 'lodash'
import {
  SESSION_CHAT_HISTORY,
  SESSION_CHAT_HISTORY_ADD,
  SESSION_CHAT_HISTORY_ADD_SINGLE
} from '../../../actionTypes'
import { getUserChatHistory } from '../../../api'
import moment from 'moment'

const getChatSessionHistory =
  (req, callback = () => {}) =>
  async (dispatch) => {
    const { page_num = 1 } = req
    const res = await getUserChatHistory(req)
    const dispatchType =
      page_num === 1 ? SESSION_CHAT_HISTORY : SESSION_CHAT_HISTORY_ADD

    if (res.status === 200) {
      const {
        session_history,
        has_next_page,
        page_num: currentPage,
        page_size,
        count
      } = res.data
      const categorizeSession = categorizeSessions(session_history)
      dispatch({
        type: dispatchType,
        payload: {
          sessions: categorizeSession,
          hasMoreData: has_next_page,
          currentPage,
          pageSize: page_size,
          sessionsCount: count
        },
        callback
      })
    }
  }

const getSingleChatHistory =
  (id, failure_callback = () => {}) =>
  async (dispatch) => {
    const req = {
      id,
      page_num: 1,
      page_size: 1
    }
    const res = await getUserChatHistory(req)
    if (res.status === 200) {
      const { session_history, count } = res.data
      if (count > 0) {
        const categorizeSession = categorizeSessions(session_history)
        dispatch({
          type: SESSION_CHAT_HISTORY_ADD_SINGLE,
          payload: {
            sessions: categorizeSession,
            sessionsCount: count
          }
        })
      } else {
        failure_callback()
      }
    } else {
      failure_callback()
    }
  }

const categorizeSessions = (sessions) => {
  const today = moment().startOf('day')

  const yesterday = moment(today).subtract(1, 'days')
  const lastWeek = moment(today).subtract(7, 'days')
  const last30Days = moment(today).subtract(30, 'days')

  const categorizedSessions = {
    today: [],
    yesterday: [],
    previous_7_Days: [],
    previous_30_Days: [],
    more_than_30_Days: []
  }

  Object.entries(sessions).forEach((data) => {
    const key = data[0]
    const session = sessions[key]
    const createdAt = moment.utc(session.created_at) // Parse the UTC time

    const categorizedSession = {}
    categorizedSession[key] = session

    if (createdAt.isSameOrAfter(today, 'day')) {
      categorizedSessions.today.push(categorizedSession)
    } else if (createdAt.isBetween(yesterday, today, 'day', '[]')) {
      categorizedSessions.yesterday.push(categorizedSession)
    } else if (createdAt.isSameOrAfter(lastWeek, 'day')) {
      categorizedSessions.previous_7_Days.push(categorizedSession)
    } else if (createdAt.isSameOrAfter(last30Days, 'day')) {
      categorizedSessions.previous_30_Days.push(categorizedSession)
    } else {
      categorizedSessions.more_than_30_Days.push(categorizedSession)
    }
  })

  return categorizedSessions
}

export { getSingleChatHistory, getChatSessionHistory }
