import * as React from 'react'

export const useDocument = (store) => {
  // We use a _ref_ here to track the current document instead of `useState`
  // Because `useDocument` is used directly in `searchPlugin`, it can cause a re-render
  const currentDocRef = React.useRef(store.get('doc'))

  const handleDocumentChanged = (doc) => {
    currentDocRef.current = doc
  }

  React.useEffect(() => {
    store.subscribe('doc', handleDocumentChanged)

    return () => {
      store.unsubscribe('doc', handleDocumentChanged)
    }
  }, [])

  return currentDocRef
}
