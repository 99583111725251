import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  grid: {
    width: '100%',
    height: '100vh'
  },
  tableContainer: {
    padding: '0px 10px',
    height: '100%',
    overflow: 'auto'
  }
})

export { useStyles }
