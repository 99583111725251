import { FETCH_PROJECTS } from '../../../actionTypes'
import { getAllProject, getParticularProject } from '../../../api'
import { toast } from 'react-toastify'

const fetchProjects =
  (req, filters, callback = () => {}) =>
  async (dispatch) => {
    const { collection_id = '' } = req
    if (collection_id) {
      const res = await getParticularProject(collection_id)
      if (res.status === 200) {
        callback(res.data)
      } else {
        toast.error('Unable to fetch project')
      }
    } else {
      const { page_num } = req
      const res = await getAllProject(req)
      const result = { ...res.data, pageNo: page_num, filters }
      if (res.status === 200) {
        dispatch({
          type: FETCH_PROJECTS,
          payload: result,
          callback
        })
      }
    }
  }

export default fetchProjects
