import {
  USER_SIGN_IN,
  USER_SIGN_OUT,
  USER_CONFIG_UPDATE,
  DOMAIN_CONFIG_UPDATE,
  REVERT_FAVPROMPT_DOMAIN_CONFIG
} from '../../actionTypes'
import _ from 'lodash'

const initialState = {
  user: {
    auth: {},
    domain: {}
  },
  isAuthenticated: null
}

function authentication(state = initialState, action) {
  switch (action.type) {
    case USER_SIGN_IN: {
      return {
        ...state,
        isAuthenticated: true,
        user: { ...action.payload }
      }
    }
    case USER_CONFIG_UPDATE: {
      let domain = _.cloneDeep(state?.user?.domain)
      domain = { ...domain, ...action.payload }
      return {
        ...state,
        user: {
          ...state.user,
          domain
        }
      }
    }
    case USER_SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: {}
      }
    case DOMAIN_CONFIG_UPDATE: {
      const domain = _.cloneDeep(state?.user?.domain)
      domain.domain_config = _.cloneDeep(action.payload?.domain_config || {})
      return {
        ...state,
        user: {
          ...state.user,
          domain
        }
      }
    }
    case REVERT_FAVPROMPT_DOMAIN_CONFIG: {
      const domain_config = _.cloneDeep(
        state?.user?.domain?.domain_config || {}
      )
      const { id, key } = action.payload
      if (!domain_config.prompt_fav) domain_config.prompt_fav = {}
      domain_config.prompt_fav = {
        ...domain_config.prompt_fav,
        [key]: domain_config.prompt_fav[key].includes(id)
          ? domain_config.prompt_fav[key].filter((item) => item !== id)
          : [...domain_config.prompt_fav[key], id]
      }
      return {
        ...state,
        user: {
          ...state.user,
          domain: {
            ...state.user.domain,
            domain_config
          }
        }
      }
    }
    default:
      return state
  }
}

export default authentication
