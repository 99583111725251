import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  textbox: {
    width: 500,
    height: '100%'
  },
  buttonsize: {
    marginTop: 16,
    marginBottom: 16
  },
  invitedlist: {
    padding: 16,
    maxHeight: '40vh',
    overflow: 'auto',
    maxWidth: '700px',
    background: 'aliceblue'
  },
  listitem: {
    width: '600px',
    padding: 0
  },
  box: {
    padding: '20px 10px',
    height: 'calc(100% - 40px)'
  },
  chipContainer: {
    display: 'flex',
    marginTop: '5px',
    flexWrap: 'wrap',
    maxWidth: '65%'
  },
  inputChip: {
    marginTop: '5px',
    marginRight: '5px'
  },
  errorText: {
    color: 'red',
    margin: 0,
    fontSize: '16px',
    fontFamily: 'PoppinsRegular'
  },
  inputSize: {
    maxWidth: '500px'
  },
  paginationContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  tableStyle: {
    position: 'relative',
    tableLayout: 'auto',
    border: '1px solid #0000001a'
  },
  cancelIconButton: {
    background: '#0000001a',
    width: '6px',
    height: '6px',
    marginLeft: '5px',
    padding: '12px'
  },
  emailCard: {
    background: '#0000001a',
    padding: '5px 10px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    marginTop: '10px'
  },
  roleSelection: {
    // width: "110px",
    '& > div': {
      paddingTop: '2px',
      paddingBottom: '2px',
      fontSize: '14px'
    }
  },
  cancelIcon: {
    fontSize: '16px'
  },
  tableContainer: {
    padding: '0px 0px',
    height: '100%',
    overflow: 'auto'
  }
})
export { useStyles }
