import { UPDATE_MULTI_VALUE_TAG } from '../../actionTypes'
import { postMultiTagCenterValue } from '../../api'
import { toast } from 'react-toastify'

const updateMultiValue =
  (req, callback = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_MULTI_VALUE_TAG,
      payload: { ...req }
    })
    const res = await postMultiTagCenterValue(req)
    if (res.status === 200) {
      const { count_change = 0 } = res?.data || {}
      dispatch({
        type: UPDATE_MULTI_VALUE_TAG,
        payload: { ...req, synced: true, count_change },
        callback
      })
    } else {
      toast.error(
        res?.response?.data?.error ||
          'Something went wrong updating tags. Try to reduce the number of tags.'
      )
      dispatch({
        type: UPDATE_MULTI_VALUE_TAG,
        payload: { ...req, synced: true, status: 'undo' },
        callback
      })
    }
  }

export default updateMultiValue
