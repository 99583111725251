import React from 'react'
import { FixedSizeList as List } from 'react-window'

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props
  const [value] = getValue()
  const itemHeight = 40
  const itemCount = children.length
  const totalHeight = itemCount * itemHeight
  const listHeight = Math.min(totalHeight, maxHeight)
  const initialOffset = options.indexOf(value) * itemHeight

  return (
    <List
      height={listHeight}
      itemCount={itemCount}
      itemSize={itemHeight}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  )
}

export default MenuList
