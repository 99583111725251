import React, { useEffect, useState, useRef } from 'react'
import { Grid, Tooltip as MuiTooltip } from '@mui/material'
import Box from '@mui/material/Box'
import { Container, Section, SectionFixed } from '../../components/Container'
import { useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import _ from 'lodash'
import { Bar } from 'react-chartjs-2'
import autocolors from 'chartjs-plugin-autocolors'
import zoomPlugin from 'chartjs-plugin-zoom'
import {
  customGenerateLabels,
  generateRandomLightColor,
  customTooltip,
  htmlLegendPlugin
} from './ChartHelper'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import IconButton from '@mui/material/IconButton'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  autocolors
)

const ChartsJS = (props) => {
  const { currentPage, PDFData } = props
  const { document_name } = PDFData
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [charts, setCharts] = useState([])
  const [otherInfo, setOtherInfo] = useState({})
  const mainChartRef = useRef(null)
  const chartingData = useSelector((state) => state?.analyticList?.chartingData)
  const [pageReadingTime, setPageReadingTime] = useState([])
  const [pageReadablity, setPageReadablity] = useState([])
  useEffect(() => {
    if (chartingData !== null) {
      setLoading(false)
      processChartingData()
    }
  }, [chartingData])

  useEffect(() => {
    if (mainChartRef && mainChartRef.current) {
      mainChartRef.current.resetZoom()
    }
  }, [selectedIndex])

  useEffect(() => {
    trackEvent(mixpanelEvents.ANALYTICS_INSIGHTS_TAB_OPENED, 'SUCCESS', {}, {})
  }, [])

  const processChartingData = () => {
    const { labels = [], datasets = [] } = chartingData
    let graph1 = {
      chartData: {
        labels,
        datasets: []
      },
      chartOptions: {
        responsive: true,
        plugins: {
          autocolors: {
            enabled: true,
            mode: 'dataset'
          },
          legend: {
            position: 'top',
            display: false
          },
          title: {
            display: true,
            text: 'Text Image Whitespace Ratio',
            position: 'bottom'
          },
          tooltip: {
            mode: 'index',
            custom: customTooltip,
            enabled: true
          }
        },
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      },
      type: 'bar'
    }
    let graph2 = {
      chartData: {
        labels,
        datasets: []
      },
      chartOptions: {
        responsive: true,
        plugins: {
          autocolors: {
            enabled: true,
            mode: 'dataset'
          },
          legend: {
            position: 'top',
            display: false
          },
          title: {
            display: true,
            text: 'Detected Entities',
            position: 'bottom'
          },
          tooltip: {
            mode: 'index',
            custom: customTooltip,
            enabled: true
          }
        },
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      },
      type: 'bar'
    }
    let graph3 = {
      chartData: {
        labels,
        datasets: []
      },
      chartOptions: {
        responsive: true,
        plugins: {
          autocolors: {
            enabled: true,
            mode: 'dataset'
          },
          legend: {
            position: 'top',
            display: false
          },
          title: {
            display: true,
            text: 'Word/Images Count',
            position: 'bottom'
          },
          tooltip: {
            mode: 'index',
            custom: customTooltip,
            enabled: true
          }
        },
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      },
      type: 'bar'
    }

    let graph4 = {
      chartData: {
        labels,
        datasets: []
      },
      chartOptions: {
        responsive: true,
        plugins: {
          autocolors: {
            enabled: true,
            mode: 'dataset'
          },
          legend: {
            position: 'top',
            display: false
          },
          title: {
            display: true,
            text: 'Reading Time',
            position: 'bottom'
          },
          tooltip: {
            mode: 'index',
            custom: customTooltip,
            enabled: true
          }
        }
      },
      type: 'bar'
    }
    let graph5 = {
      chartData: {
        labels,
        datasets: []
      },
      chartOptions: {
        responsive: true,
        plugins: {
          autocolors: {
            enabled: true,
            mode: 'dataset'
          },
          legend: {
            position: 'top',
            display: false
          },
          title: {
            display: true,
            text: 'Style Suggestions',
            position: 'bottom'
          },
          tooltip: {
            mode: 'index',
            custom: customTooltip,
            enabled: true
          }
        },
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      },
      type: 'bar'
    }
    let other = {}

    if (labels.length > 0) {
      other = { ...other, 'Total Pages': labels.length }
    }
    datasets.forEach((dataset, index) => {
      const { light: lighterColor, dark: darkerColor } =
        generateRandomLightColor()
      const { label, data } = dataset
      if (data.length > 0) {
        if (label === 'Text Image Whitespace Ratio (in %)') {
          const { light: lighterColor2, dark: darkerColor2 } =
            generateRandomLightColor()
          const { light: lighterColor3, dark: darkerColor3 } =
            generateRandomLightColor()
          const [text, image, whitespace] = data
            .map((item) => {
              const [text = 0, image = 0, whitespace = 0] = item.split(':')
              return [text, image, whitespace]
            })
            .reduce(
              (acc, val) => {
                acc[0].push(val[0])
                acc[1].push(val[1])
                acc[2].push(val[2])
                return acc
              },
              [[], [], []]
            )
          const chartData = {
            labels,
            datasets: [
              {
                label: 'Text',
                data: text,
                backgroundColor: lighterColor,
                darkBackgroundColor: darkerColor,
                lightBackgroundColor: lighterColor
              },
              {
                label: 'Image',
                data: image,
                backgroundColor: lighterColor2,
                darkBackgroundColor: darkerColor2,
                lightBackgroundColor: lighterColor3
              },
              {
                label: 'Whitespace',
                data: whitespace,
                backgroundColor: lighterColor3,
                darkBackgroundColor: darkerColor3,
                lightBackgroundColor: lighterColor3
              }
            ]
          }
          graph1 = { ...graph1, chartData }
        } else if (
          [
            'Locations',
            'Organizations',
            'Dates',
            'People Names',
            'Contact Numbers',
            'Email IDs'
          ].includes(label)
        ) {
          let { chartData = {} } = graph2
          chartData = {
            ...chartData,
            datasets: [
              ...chartData?.datasets,
              {
                label,
                data,
                backgroundColor: lighterColor,
                darkBackgroundColor: darkerColor,
                lightBackgroundColor: lighterColor
              }
            ]
          }
          graph2 = { ...graph2, chartData }
        } else if (['Words', 'Images'].includes(label)) {
          let { chartData = {} } = graph3
          chartData = {
            ...chartData,
            datasets: [
              ...chartData?.datasets,
              {
                label,
                data,
                backgroundColor: lighterColor,
                darkBackgroundColor: darkerColor,
                lightBackgroundColor: lighterColor
              }
            ]
          }
          graph3 = { ...graph3, chartData }
        } else if (label === 'Readability Time (in minutes)') {
          const sum = (
            Math.round(data.reduce((a, b) => a + b, 0) * 100) / 100
          ).toFixed(2)
          other = { ...other, 'Total Reading Time (mins)': sum }
          let { chartData = {} } = graph4
          chartData = {
            ...chartData,
            datasets: [
              {
                label: 'Reading Time (mins)',
                data,
                backgroundColor: lighterColor,
                darkBackgroundColor: darkerColor,
                lightBackgroundColor: lighterColor
              }
            ]
          }
          graph4 = { ...graph4, chartData }
          setPageReadingTime(data)
        } else if (label === 'Readability Level') {
          setPageReadablity(data)
        } else {
          let { chartData = {} } = graph5
          chartData = {
            ...chartData,
            datasets: [
              ...chartData?.datasets,
              {
                label,
                data,
                backgroundColor: lighterColor,
                darkBackgroundColor: darkerColor,
                lightBackgroundColor: lighterColor
              }
            ]
          }
          graph5 = { ...graph5, chartData }
        }
      }
    })
    setCharts([graph4, graph5, graph1, graph2, graph3])
    setOtherInfo(other)
  }

  const renderChart = (chart, isMainChart = false) => {
    const { chartData, chartOptions, type } = chart
    const valueToColor = 'Pg' + (currentPage + 1)
    const modifiedData = _.cloneDeep(chartData)

    modifiedData.datasets.forEach((dataset) => {
      const backgroundColor = dataset.data.map((value, index) => {
        if (chartData.labels[index] === valueToColor) {
          return dataset.darkBackgroundColor
        }
        return dataset.lightBackgroundColor
      })
      dataset.backgroundColor = backgroundColor
    })

    const optionsCurrent = {
      ...chartOptions,
      scales: {
        ...chartOptions?.scales,
        x: {
          ...chartOptions?.scales?.x,
          ticks: {
            color: (context) => {
              const { tick } = context
              if (tick?.label === valueToColor) {
                return '#000'
              }
              return '#666'
            }
          }
        }
      },
      plugins: {
        ...chartOptions?.plugins,
        htmlLegend: isMainChart
          ? {
              containerID: 'legend-container'
            }
          : null,
        legend: {
          display: false,
          labels: {
            generateLabels: customGenerateLabels
          }
        },
        zoom: {
          pan: {
            enabled: isMainChart,
            mode: 'xy'
          },
          zoom: {
            wheel: {
              enabled: isMainChart
            },
            mode: 'xy'
          }
        }
      }
    }
    if (type === 'bar') {
      return (
        <Bar
          data={modifiedData}
          options={optionsCurrent}
          ref={isMainChart ? mainChartRef : null}
          plugins={[htmlLegendPlugin]}
        />
      )
    }
  }

  const handleReset = () => {
    if (mainChartRef && mainChartRef.current) {
      const chartRef = mainChartRef.current
      chartRef.resetZoom()
      chartRef.reset()
      chartRef.data.datasets.forEach((dataset, datasetIndex) => {
        const meta = chartRef.getDatasetMeta(datasetIndex)
        meta.hidden = null
      })
      chartRef.update()
    }
  }

  const downloadImage = () => {
    if (mainChartRef && mainChartRef.current) {
      const image = mainChartRef.current.toBase64Image('image/png', 1)
      const link = document.createElement('a')
      let title = charts[selectedIndex]?.chartOptions?.plugins?.title?.text
      title = `${document_name} - ${title} ${new Date().toISOString()}`
      link.download = `${title}.png`
      link.href = image
      link.click()
    }
  }
  const getReadingTime = (time) => {
    let readingTime = time
    let readingTimelabel = 'Current Page Read Time (Mins)'
    if (!readingTime || readingTime === 0) {
      readingTime = '-'
    } else if (readingTime < 1) {
      readingTime = Math.floor(readingTime * 60)
      readingTimelabel = 'Current Page Read Time (Secs)'
    }
    return { readingTime, readingTimelabel }
  }

  const { readingTime, readingTimelabel } = getReadingTime(
    pageReadingTime[currentPage]
  )
  return loading ? (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className="search-loader-container"
    >
      <Loader loading={loading} caption={'fetching your insights'} />
    </Grid>
  ) : (
    <Container>
      <SectionFixed>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {!_.isEmpty(otherInfo) && (
            <Box
              sx={{
                display: 'flex',
                padding: '20px',
                gap: '10px',
                justifyContent: 'space-evenly'
              }}
            >
              {pageReadingTime.length > 0 && currentPage > -1 && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      margin: '0px 10px',
                      width: '150px',
                      flexDirection: 'column'
                    }}
                  >
                    <Box sx={{ fontSize: '30px', fontWeight: '600' }}>
                      {currentPage + 1}
                    </Box>
                    <Box sx={{ fontSize: '16px', color: '#00000080' }}>
                      {'Current Page'}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      margin: '0px 10px',
                      width: '150px',
                      flexDirection: 'column'
                    }}
                  >
                    <Box sx={{ fontSize: '30px', fontWeight: '600' }}>
                      {readingTime}
                    </Box>
                    <Box sx={{ fontSize: '16px', color: '#00000080' }}>
                      {readingTimelabel}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      margin: '0px 10px',
                      width: '150px',
                      flexDirection: 'column'
                    }}
                  >
                    <Box sx={{ fontSize: '30px', fontWeight: '600' }}>
                      <Box component={'span'} sx={{ fontSize: '20px' }}>
                        {pageReadablity[currentPage]}
                      </Box>
                    </Box>
                    <Box sx={{ fontSize: '16px', color: '#00000080' }}>
                      {'Readability Level'}
                    </Box>
                  </Box>
                </>
              )}
              {Object.keys(otherInfo).map((key, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: '0px 10px',
                    width: '150px',
                    flexDirection: 'column'
                  }}
                >
                  <Box sx={{ fontSize: '30px', fontWeight: '600' }}>
                    {otherInfo[key]}
                  </Box>
                  <Box sx={{ fontSize: '16px', color: '#00000080' }}>{key}</Box>
                </Box>
              ))}
            </Box>
          )}
          <Box
            sx={{
              padding: '20px',
              minHeight: '150px',
              minWidth: '300px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box id="legend-container" />
            {charts[selectedIndex] && renderChart(charts[selectedIndex], true)}
            <Box>
              <MuiTooltip title="Reset Chart">
                <IconButton disableRipple onClick={() => handleReset()}>
                  <RestartAltOutlinedIcon sx={{ color: '#666666' }} />
                </IconButton>
              </MuiTooltip>
              <MuiTooltip title="Download Chart">
                <IconButton disableRipple onClick={() => downloadImage()}>
                  <DownloadOutlinedIcon sx={{ color: '#666666' }} />
                </IconButton>
              </MuiTooltip>
            </Box>
          </Box>
        </Box>
      </SectionFixed>

      <Section overFlow>
        <Box sx={{ overflow: 'auto', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '2%',
              justifyContent: 'center',
              marginTop: '15px',
              height: '100%'
            }}
          >
            {charts.map((chart, index) => (
              <Box
                sx={{
                  width: '45%',
                  margin: '8px',
                  minWidth: '300px',
                  minHeight: '150px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  '& canvas': {
                    border:
                      selectedIndex === index
                        ? '2px dashed #666666'
                        : '2px solid transparent'
                  }
                }}
                key={index}
                onClick={() => setSelectedIndex(index)}
              >
                {renderChart(chart, false)}
              </Box>
            ))}
          </Box>
        </Box>
      </Section>
    </Container>
  )
}
export default ChartsJS
