import { createStore } from '@react-pdf-viewer/core'
import * as React from 'react'
import { EMPTY_KEYWORD_REGEXP } from './constants.js'
import { Highlights } from './Highlights'
import { normalizeSingleKeyword } from './normalizeKeyword'
import { useSearch } from './useSearch'

const normalizeKeywords = (keyword) =>
  Array.isArray(keyword)
    ? keyword.map((k) => normalizeSingleKeyword(k))
    : [normalizeSingleKeyword(keyword)]

export const searchPlugin = (props) => {
  const searchPluginProps = React.useMemo(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () =>
      Object.assign(
        {},
        { enableShortcuts: true, onHighlightKeyword: () => {} },
        props
      ),
    []
  )
  const store = React.useMemo(
    () =>
      createStore({
        initialKeyword:
          props && props.keyword
            ? Array.isArray(props.keyword)
              ? props.keyword
              : [props.keyword]
            : [],
        keyword:
          props && props.keyword
            ? normalizeKeywords(props.keyword)
            : [EMPTY_KEYWORD_REGEXP],
        matchPosition: {
          matchIndex: -1,
          pageIndex: -1
        },
        renderStatus: new Map()
      }),
    []
  )
  const { clearKeyword, setKeywords, searchFor } = useSearch(store)

  const [selectedHighlight, setSelectedHighlight] = React.useState()

  const renderPageLayer = (renderProps) => (
    <Highlights
      selectedHighlight={selectedHighlight}
      setSelectedHighlight={setSelectedHighlight}
      key={renderProps.pageIndex}
      numPages={renderProps.doc.numPages}
      pageIndex={renderProps.pageIndex}
      renderHighlights={props?.renderHighlights}
      store={store}
      showError={props.showError}
      handleDataChange={props.handleDataChange}
      onHighlightKeyword={searchPluginProps.onHighlightKeyword}
      selectedErrorType={props.selectedErrorType}
    />
  )

  return {
    install: (pluginFunctions) => {
      const initialKeyword =
        props && props.keyword
          ? Array.isArray(props.keyword)
            ? props.keyword
            : [props.keyword]
          : []
      const keyword =
        props && props.keyword
          ? normalizeKeywords(props.keyword)
          : [EMPTY_KEYWORD_REGEXP]
      store.update('initialKeyword', initialKeyword)
    },
    renderPageLayer,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uninstall: (props) => {
      const renderStatus = store.get('renderStatus')
      if (renderStatus) {
        renderStatus.clear()
      }
    },
    onDocumentLoad: (props) => {
      store.update('doc', props.doc)
    },
    onTextLayerRender: (props) => {
      let renderStatus = store.get('renderStatus')
      if (renderStatus) {
        renderStatus = renderStatus.set(props.pageIndex, props)
        store.update('renderStatus', renderStatus)
      }
    },
    highlight: (keyword) => {
      const keywords = Array.isArray(keyword) ? keyword : [keyword]
      setKeywords(keywords)
      return searchFor(keywords)
    },
    clearHighlights: () => {
      clearKeyword()
    }
  }
}
