// import * as React from 'react';
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TextField,
  IconButton,
  FormControl,
  Button
} from '@mui/material'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Checkbox from '@mui/material/Checkbox'
import { cloneDeep, isArray } from 'lodash'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import { useStyles } from './styles'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Pagination from '../../components/Pagination'
import { SectionFixed, Container, Section } from '../../components/Container'
import ListTable from '../../components/ListTable'
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { inviteUsertoDomain } from '../../store/Settings/Actions'
import { toast } from 'react-toastify'
import Chip from '@mui/material/Chip'

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

export default function InviteListV2(props) {
  const { inviteList = [], updateInvite = () => {} } = props
  const [checked, setChecked] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [currentTableData, setCurrentTableData] = React.useState(inviteList)
  const [removeLoggedinUser, setRemoveLoggedInUser] = React.useState(false)

  const user = useSelector((state) => state.authenticate.user)
  const { domain = {} } = user || {}
  const dispatch = useDispatch()

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - currentTableData.length)
      : 0

  React.useEffect(() => {
    setCurrentTableData(inviteList)
  }, [inviteList])

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const classes = useStyles()
  const {
    invitedlist,
    listitem,
    paginationContainer,
    tableContainer,
    tableStyle,
    roleSelection
  } = classes

  React.useEffect(() => {
    const checkboxArray = []
    currentTableData.forEach((data) => {
      checkboxArray.push(data.status !== 'Inactive')
    })
    if (domain?.email) {
      const containsEmail = inviteList.some(
        (obj) => obj.email === domain?.email
      )
      setRemoveLoggedInUser(containsEmail)
    }
    setChecked(checkboxArray)
  }, [inviteList])

  const handleActionClick = (index) => {
    const tempArray = cloneDeep(checked)
    tempArray[index] = !tempArray[index]
    setChecked(tempArray)
    const deleteData = inviteList[index]

    // updateInvite(deleteData)
  }

  // if (isArray(inviteList) && currentTableData.length < 1) {
  //   return (
  //     <ListItem className={listitem}>
  //       <ListItemText primary={"Looks like no invites have been sent"} />
  //     </ListItem>
  //   )
  // }

  const handleStatusChange = (event, targetId) => {
    const index = currentTableData.findIndex((item) => item.id === targetId)
    const updatedStatus = event
    const tempArray = cloneDeep(currentTableData)
    tempArray[index].status = updatedStatus
    setCurrentTableData(tempArray)
    const deleteData = tempArray[index]
    updateInvite(deleteData, 'status')
  }

  const handleRemoveUserInvite = (targetId) => {
    const index = currentTableData.findIndex((item) => item.id === targetId)
    const tempArray = cloneDeep(currentTableData)
    tempArray[index].status = 'Remove'
    setCurrentTableData(tempArray)
    const deleteData = tempArray[index]
    updateInvite(deleteData, 'status')
  }

  const handleRoleChange = (event, targetId) => {
    const index = currentTableData.findIndex((item) => item.id === targetId)
    const updatedRole = event.target.value
    const tempArray = cloneDeep(currentTableData)
    tempArray[index].role = updatedRole
    setCurrentTableData(tempArray)
    const deleteData = tempArray[index]

    updateInvite(deleteData, 'role')
  }

  // const currentPageRowData = (rowsPerPage > 0
  //   ? currentTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  //   : inviteList)

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('email')
  const [searchTerm, setSearchTerm] = useState('')

  const handleCreate = (source = '') => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (source === 'single') {
      try {
        if (emailRegex.test(searchTerm)) {
          const inviteData = [
            {
              email: searchTerm,
              role: 'viewer'
            }
          ]
          dispatch(inviteUsertoDomain(inviteData))
          setSearchTerm('')
        } else {
          toast.warn('Enter valid email', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: 'light'
          })
        }
      } catch (e) {
        console.log(e)
      }
    } else if (source === 'list') {
      try {
        const emailsToInvite = []
        selectedEmails.forEach((email) => {
          if (emailRegex.test(email)) {
            const inviteData = {
              email,
              role: 'viewer'
            }
            emailsToInvite.push(inviteData)
          } else {
            toast.error(`${email} is not a valid email`, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: 'light'
            })
          }
        })
        dispatch(inviteUsertoDomain(emailsToInvite))
        setSelectedEmails([])
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleToggleSortOrder = () => {
    setOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
  }

  const filteredData = currentTableData.filter(
    (row) =>
      row.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      row.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  )

  const sortedData = filteredData.sort((a, b) => {
    let aValue = a[orderBy] || ''
    let bValue = b[orderBy] || ''

    try {
      aValue = aValue?.toLowerCase()
      bValue = bValue?.toLowerCase()
    } catch (e) {
      console.log(e)
    }

    if (order === 'asc') {
      return aValue.localeCompare(bValue)
    } else {
      return bValue.localeCompare(aValue)
    }
  })

  const currentPageRowData =
    rowsPerPage > 0
      ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : inviteList

  const [selectedEmails, setSelectedEmails] = useState([])

  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
    const trimmedTerm = e.target.value
    if (trimmedTerm.endsWith(' ') || trimmedTerm.endsWith(',')) {
      // const emailToAdd = trimmedTerm.slice(0, -1);
      // const emailRegex = /[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}/g;
      const emailRegex = /[\w.+.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}/g

      const emails = trimmedTerm.match(emailRegex)
      const newSelectedEmails = [...new Set([...selectedEmails, ...emails])]
      setSelectedEmails(newSelectedEmails)
      setSearchTerm('')
    }
  }

  const handleChipAdd = () => {
    if (
      searchTerm.trim() !== '' &&
      selectedEmails.indexOf(searchTerm.trim()) === -1
    ) {
      setSelectedEmails([...selectedEmails, searchTerm.trim()])
      setSearchTerm('')
    }
  }

  const handleChipDelete = (index) => {
    const newSelectedEmails = [...selectedEmails]
    newSelectedEmails.splice(index, 1)
    setSelectedEmails(newSelectedEmails)
  }

  const searchBarWidthStyle = selectedEmails.length > 0 ? '65%' : '100%'
  const chipWidthStyle = selectedEmails.length > 0 ? '35%' : '0%'

  return (
    <Container>
      <Section
        style={{
          overflow: 'auto'
        }}
      >
        <div
          style={{
            width: '70%',
            border: '1px solid #0000001a',
            margin: '10px auto',
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          <div
            style={{
              width: '98%',
              margin: '10px auto',
              display: 'flex',
              alignItems: 'flex-start'
              // height: "30px"
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <IconButton
                onClick={handleToggleSortOrder}
                style={{}}
                disableRipple
              >
                {order === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
              </IconButton>
              <TextField
                label=""
                variant="outlined"
                size="small"
                sx={{ width: '100%', '& input': { padding: '4px 7px' } }}
                value={searchTerm}
                onChange={(e) => handleSearch(e)}
                placeholder={
                  currentPageRowData.length >= 1
                    ? 'Search for email or enter email to invite new users'
                    : 'Invite Users'
                }
                InputProps={{
                  startAdornment: (
                    // <IconButton disabled style={{ padding: '1px' }}>
                    <IconButton
                      disabled
                      style={{ padding: '1px' }}
                      onClick={handleChipAdd}
                    >
                      <SearchIcon />
                    </IconButton>
                  )
                }}
              />
            </div>
          </div>
          <div
            style={{
              width: '98%',
              margin: '10px auto',
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column'
              // height: "30px"
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              {selectedEmails.length > 0 && (
                <>
                  {selectedEmails?.map((email, index) => (
                    <Chip
                      key={index}
                      label={email}
                      onDelete={() => handleChipDelete(index)}
                      sx={{
                        marginRight: '5px',
                        marginBottom: '5px'
                      }}
                    />
                  ))}
                </>
              )}
            </div>
            {selectedEmails.length > 0 && (
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  color: '#0000008a',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    padding: '5px 5px'
                  }}
                  onClick={() => handleCreate('list')}
                >
                  Invite
                </Button>
              </div>
            )}
          </div>
          <div
            style={{
              width: '98%',
              maxWidth: '98%',
              margin: '10px auto',
              overflow: 'auto'
            }}
          >
            {currentPageRowData.length < 1 ? (
              <>
                {searchTerm !== '' && selectedEmails.length < 1 ? (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      padding: '10px 0px',
                      color: '#0000008a'
                    }}
                  >
                    Invite{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {' ' + searchTerm + ' '}
                    </span>{' '}
                    to your domain?
                    <Button
                      variant="contained"
                      sx={{
                        marginLeft: '10px',
                        padding: '5px 10px'
                      }}
                      onClick={() => handleCreate('single')}
                    >
                      Invite
                    </Button>
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        padding: '10px 0px',
                        color: '#0000008a'
                      }}
                    >
                      Looks like no invites have been sent.
                    </div>
                  </>
                )}
              </>
            ) : (
              <Section>
                <Table
                  stickyHeader
                  className={tableStyle}
                  aria-label="simple table"
                >
                  <TableBody>
                    {currentPageRowData.map((dom, indexInviteList) => {
                      const {
                        id = 0,
                        email = '',
                        status = '',
                        name = '',
                        role = ''
                      } = dom
                      let statusValue = ''
                      let statusDropDown = ''
                      if (status === 'joined' || status === 'Active') {
                        statusValue = 'Active'
                        statusDropDown = 'Inactive'
                      } else if (status === 'Pending') {
                        statusValue = 'Pending'
                        statusDropDown = 'Remove'
                      } else if (status === 'Inactive') {
                        statusValue = 'Inactive'
                        statusDropDown = 'Active'
                      } else {
                        statusValue = 'Remove'
                        statusDropDown = 'Pending'
                      }
                      const emailColor = name ? 'green' : 'red'
                      return (
                        <>
                          {email !== domain?.email && (
                            <TableRow
                              key={id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                },
                                width: '50%',
                                height: '15px',
                                background:
                                  status === 'Inactive' ? '#0000001a' : 'white'
                              }}
                            >
                              <TableCell
                                key={'tableCell1-' + id}
                                component="th"
                                scope="row"
                                sx={{ width: '60%', fontSize: '14px' }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignContent: 'flex-start',
                                    alignCtems: 'flex-start'
                                  }}
                                >
                                  <div>
                                    {name ? (
                                      <span style={{ fontWeight: 'bold' }}>
                                        {name}
                                      </span>
                                    ) : (
                                      <span style={{ fontWeight: 'bold' }}>
                                        Pending
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      color: emailColor
                                    }}
                                  >
                                    {email}
                                  </div>
                                </div>
                              </TableCell>
                              {/* <TableCell component="th" scope="row" sx={{ width: "20%" }}>
                              <Select
                                labelId="user-role-select"
                                id="invite-user-role-select"
                                value={statusValue}
                                className={roleSelection}
                                onChange={(event) => { handleStatusChange(event, indexInviteList) }}
                              >
                                <MenuItem style={{ fontSize: "14px" }} value={statusValue}>{statusValue}</MenuItem>
                                <MenuItem style={{ fontSize: "14px" }} value={statusDropDown}>{statusDropDown}</MenuItem>
                              </Select>
                            </TableCell> */}
                              <TableCell
                                key={'tableCell2-' + id}
                                component="th"
                                scope="row"
                                sx={{
                                  width: '20%',
                                  textTransform: 'capitalize'
                                }}
                              >
                                <FormControl sx={{ m: 1, minWidth: '100px' }}>
                                  <Select
                                    disabled={status === 'Inactive'}
                                    labelId="user-role-select"
                                    id="invite-user-role-select"
                                    value={role}
                                    className={roleSelection}
                                    autoWidth
                                    onChange={(event) => {
                                      handleRoleChange(event, id)
                                    }}
                                    sx={{
                                      '& .MuiSelect-select': {
                                        // fontSize: "14px",
                                        // padding: "6px 6px",
                                        // width: "100%"
                                      }
                                      // width: "100%"
                                    }}
                                  >
                                    <MenuItem
                                      sx={{ fontSize: '14px', width: '100px' }}
                                      value={'user'}
                                    >
                                      User
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ fontSize: '14px', width: '100%' }}
                                      value={'admin'}
                                    >
                                      Admin
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ fontSize: '14px', width: '100%' }}
                                      value={'viewer'}
                                    >
                                      Viewer
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell
                                key={'tableCell3-' + id}
                                component="th"
                                scope="row"
                                sx={{
                                  width: '20%',
                                  textTransform: 'capitalize'
                                }}
                              >
                                <Button
                                  variant="text"
                                  color="error"
                                  onClick={(event) => {
                                    if (status === 'Pending') {
                                      handleRemoveUserInvite(id)
                                    } else {
                                      handleStatusChange(statusDropDown, id)
                                    }
                                  }}
                                >
                                  {status === 'Pending'
                                    ? 'Remove'
                                    : statusDropDown === 'Inactive'
                                    ? 'Deactivate'
                                    : 'Activate'}
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )
                    })}
                  </TableBody>
                </Table>
              </Section>
            )}
          </div>
        </div>
      </Section>
      <SectionFixed>
        {currentPageRowData.length >= 1 && (
          <Pagination
            rows={currentTableData.length}
            rowsPerPage={rowsPerPage}
            page={page + 1}
            handleChangePage={handleChangePage}
            startIndex={page * rowsPerPage + 1}
            endIndex={page * rowsPerPage + currentPageRowData.length}
            fullCount={currentTableData.length}
          />
        )}
      </SectionFixed>
    </Container>
  )
}
