import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  childContainer: {
    height: 'calc(100% - 40px)',
    width: 'calc(100% - 40px)',
    padding: '20px'
  },
  textFeild: {
    width: '100%',
    marginTop: '10px'
  },
  textFeildLabel: {
    marginTop: '20px'
  },
  titleText: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#797979'
  },
  subText: {
    fontSize: '16px',
    fontWeight: '400',
    marginTop: '10px',
    color: '#797979'
  },
  dialogWrapper: {
    width: 'calc(100% - 20px)',
    backgroundColor: 'white',
    padding: '10px',
    overflow: 'auto',
    maxWidth: '1000px',
    margin: 'auto'
  },
  dialogTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px'
  },
  dialogButtonWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center'
  },
  dailogContent: {
    padding: '10px 20px',
    marginBottom: '40px'
  },
  dailogContentTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#000'
  },
  dailogContentSubTitle: {
    fontSize: '16px',
    fontWeight: '400',
    marginTop: '10px',
    color: '#000'
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '5px'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    flexWrap: 'wrap'
  },
  disabledOpacity: {
    opacity: '0.5'
  },
  marginTop: {
    marginTop: '10px'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap'
  },
  gradientTextStyle: {
    background: 'linear-gradient(100deg, #471CA8, #FF3465)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent !important',
    display: 'inline-block',
    '&:before': {
      content: 'attr(data-text)',
      position: 'absolute',
      left: '0',
      top: '0',
      zIndex: '-1'
    }
  },
  voiceTitle: {
    fontSize: '16px',
    color: '#000',
    fontWeight: 'bold'
  },
  description: {
    fontSize: '16px',
    color: '#000'
  },
  voiceTags: {
    fontSize: '12px',
    color: '#797979',
    display: 'flex',
    gap: '5px'
  },
  tagWrapper: {
    display: 'flex',
    gap: '5px',
    border: '1px solid #E5E5E5',
    borderRadius: '5px',
    padding: '3px 5px'
  },
  prompt: {
    fontSize: '16px',
    color: '#000'
  },
  voiceUpdated: {
    fontSize: '12px',
    color: '#797979',
    margin: '5px 0px'
  },
  promptContainer: {
    padding: '10px'
  },
  voiceWrapper: {
    padding: '10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  textFeildSubText: {
    fontSize: '14px',
    color: '#797979',
    marginTop: '10px'
  },
  multiSelectCheckBox: {
    display: 'flex',
    width: '100%',
    gap: '10px',
    marginTop: '10px',
    '& #multiCheckboxWrapper': {
      width: 'auto !important',
      '& button': {
        fontSize: '12px',
        padding: '1px 4px'
      }
    },
    '& #multiCheckboxOption': {
      width: '100% !important',
      fontWeight: '500',
      fontSize: '12px',
      color: '#000000',
      '& .MuiSvgIcon-root': {
        fontSize: '20px'
      },
      '& .MuiCheckbox-root': {
        padding: '5px'
      }
    },
    '& .Mui-checked': {
      color: '#0645AD !important'
    },
    '& #multiCheckboxMenuWrapper': {
      border: '1px solid #E5E5E5 !important',
      borderRadius: '4px !important',
      marginTop: '5px'
    },
    '& #multiCheckboxMenu': {
      '& input': {
        fontSize: '12px !important'
      }
    }
  },
  promptSearchWrapper: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  headerWrapper: {
    color: 'white',
    textAlign: 'right',
    marginTop: '30px',
    marginBottom: '20px',
    padding: '0px 10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  hideMenuListText: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#000000',
    width: '100px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word'
  },
  hideMenu: {
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    },
    '& .MuiButtonBase-root': {
      padding: '3px 5px'
    },
    '& .MuiMenu-paper': {
      border: '1px solid #E5E5E5',
      marginTop: '10px'
    },
    '& .MuiList-root': {
      padding: '8px'
    }
  },
  iconButton: {
    padding: '0px !important'
  },
  icon: {
    fontSize: '20px !important',
    color: '#797979'
  },
  noData: {
    fontSize: '16px',
    color: '#797979',
    padding: '20px',
    fontStyle: 'italic',
    textAlign: 'center'
  },
  iconContainer: {
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    }
  }
})
export { useStyles }
