import { UPDATE_ACTIVE_COLLECTION } from '../../../actionTypes'

const collections = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_ACTIVE_COLLECTION,
    payload: data
  })
}

export default collections
