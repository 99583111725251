import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Select from 'react-select'
import { useStyles } from './styles'
import { isArray, isEmpty } from 'lodash'
import {
  fetchAllDomainList,
  updateSuperUserDomainId,
  fetchDomainSettings
} from '../../store/Settings/Actions'
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import Button from '../../components/Button'
import Spinner from '../../components/SpinnerBackDrop'
import { CircularProgress } from '@mui/material'

const SelectDomain = ({ isFocused }) => {
  const dispatch = useDispatch()
  const domainList = useSelector((state) => state?.settings?.domainList)
  const domain = useSelector((state) => state?.settings?.domain)
  const { domain_id, domain_name } = domain
  const [selectedTagsOptions, setSelectedTagsOptions] = useState([])
  const [selectedDomain, setSelectedDomain] = useState()

  useEffect(() => {
    if (isEmpty(domainList) && isFocused) {
      dispatch(fetchAllDomainList())
    }
    if (isEmpty(domain) && isFocused) {
      dispatch(fetchDomainSettings())
    }
  }, [isFocused])

  useEffect(() => {
    const option = []
    if (!isEmpty(domainList)) {
      domainList.map((e) =>
        option.push({ label: `${e.name} (${e.id})`, value: e.id, name: e.name })
      )
      setSelectedTagsOptions(option)
    }
  }, [domainList])

  const handleDomainChange = () => {
    const { value, name } = selectedDomain
    const data = {
      domain_id: value,
      domain_name: name
    }
    setIsChangingDomain(true)
    const callback = () => {
      setIsChangingDomain(false)
    }
    dispatch(updateSuperUserDomainId(data, callback))
  }

  const domainSelect = (value) => {
    setSelectedDomain(value)
  }

  const classes = useStyles()

  const { container, domain_selector, selector_box, changeButton } = classes

  const loading = isEmpty(domainList) || isEmpty(domain)
  const [isChangingDomain, setIsChangingDomain] = useState(false)

  return (
    <Box className={container}>
      {loading ? (
        <Spinner
          loading={loading}
          showSideBar
          caption={'loading available domains'}
        />
      ) : (
        <>
          <Box>
            <Typography variant="h6">
              Current Domain : {domain_name} ({domain_id})
            </Typography>
          </Box>
          <Box className={domain_selector}>
            <Select
              options={selectedTagsOptions}
              className={selector_box}
              onChange={(e) => {
                domainSelect(e)
              }}
            />
            <Button
              disabled={isChangingDomain}
              className={changeButton}
              onClick={(e) => {
                handleDomainChange(e)
              }}
            >
              {isChangingDomain ? (
                <CircularProgress size={20} />
              ) : (
                'Change Domain'
              )}
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default SelectDomain
