import {
  ElementNode,
  $getRoot,
  $isParagraphNode,
  $createParagraphNode,
  $createLineBreakNode
} from 'lexical'

export class SectionElementNode extends ElementNode {
  constructor(id, key) {
    super(key)
    this.__id = id
  }

  static getType() {
    return 'section'
  }

  static clone(node) {
    return new SectionElementNode(node.__id, node.__key)
  }

  createDOM(config) {
    const dom = document.createElement('div')
    dom.className = 'editor-section'
    dom.id = this.__id
    dom.style.borderBottom = '1px solid #e5e5e5'

    return dom
  }

  updateDOM(prevNode, dom) {
    if (prevNode.__id !== this.__id) {
      dom.id = this.__id
    }
    return false
  }

  canInsertAfter(node) {
    return true
  }

  canReplaceWith(replacement, config) {
    return true
  }

  static importJSON(serializedNode) {
    const node = $createSectionElementNode(serializedNode.id)
    return node
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      id: this.__id,
      type: 'section',
      version: 1
    }
  }

  getId() {
    return this.__id
  }

  insertNewAfter(restoreSelection) {
    const lineBreak = $createLineBreakNode()
    this.insertAfter(lineBreak, restoreSelection)
    const newElement = $createParagraphNode()
    this.insertAfter(newElement, restoreSelection)
    newElement.select()
    return newElement
  }
}

export function $createSectionElementNode(id) {
  return new SectionElementNode(id)
}

export function $isSectionElementNode(node) {
  return node instanceof SectionElementNode
}

export function $getSectionElementById(sectionId) {
  const root = $getRoot()
  const stack = [...root.getChildren()]
  while (stack.length > 0) {
    const node = stack.pop()
    if ($isSectionElementNode(node) && node.getId() === sectionId) {
      return node
    }
    if (node.getChildren) {
      stack.push(...node.getChildren())
    }
  }
  return null
}

export function $getSectionOtherElementsById(sectionId) {
  const root = $getRoot()
  const stack = [...root.getChildren()]
  const others = []
  while (stack.length > 0) {
    const node = stack.pop()
    if ($isSectionElementNode(node) && node.getId() !== sectionId) {
      others.push(node)
    }
    if (node.getChildren) {
      stack.push(...node.getChildren())
    }
  }
  return others
}

export function $getFirstParaOfSection(section) {
  const stack = [...section.getChildren()]
  while (stack.length > 0) {
    const node = stack.pop()
    if ($isParagraphNode(node)) {
      return node
    }
    if (node.getChildren) {
      stack.push(...node.getChildren())
    }
  }
  return null
}
