import { RESIZE_UPLOADER } from '../../../actionTypes'

const resizeUploader = (data) => async (dispatch) => {
  dispatch({
    type: RESIZE_UPLOADER,
    payload: data
  })
}

export { resizeUploader }
