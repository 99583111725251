import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.primary.main,
    border: `2px ${theme.palette.primary.main} solid`,
    backgroundColor: 'white',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'PoppinsRegular'
  },
  avatarButton: {
    padding: '0px !important'
  },
  userDetailsContainer: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10px'
  },
  userNameBox: {
    fontSize: '16px',
    fontFamily: 'PoppinsRegular',
    color: theme.palette.primary.main
  },
  userEmailBox: {
    fontSize: '14px',
    fontFamily: 'PoppinsRegular'
  },
  userDomainBox: {
    fontSize: '14px',
    fontFamily: 'PoppinsRegular',
    color: theme.palette.primary.secondary
  },
  centerMenuItems: {
    justifyContent: 'center'
  },
  buttonWrapper: {
    minHeight: 48,
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgb(196 196 196 / 30%) !important'
    },
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '10px !important'
  }
}))

const MenuPaperProps = {
  elevation: 0,
  sx: {
    width: '300px',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    marginLeft: '47px',
    '& .MuiAvatar-root': {
      width: 50,
      height: 50,
      ml: -0.5,
      mr: 1,
      mb: 2
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-5px',
      bottom: '17px',
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
}

export { useStyles, MenuPaperProps }
