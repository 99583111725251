import { Dialog, DialogContent, Fade } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  CircleLoader,
  CloudUpload,
  EnterIcon,
  SearchIcon
} from '../../components/Icons/Icons'
import SearchNew from '../ListFilter/SearchNew'
import { useSelector } from 'react-redux'
import { ImageAvatarFallback } from '../../components'
import Button2 from '../../components/Button/Button2'
import { uuid } from '../../components/Lexical/plugins/AutocompletePlugin'

const UploadProfileModal = ({
  open,
  setOpen,
  s3Obj,
  profilePic,
  handleSaveProfilePic
}) => {
  const [pictureInfo, setPictureSrc] = useState(null)
  const [uploadingProfilePic, setUploadingProfilePic] = useState(false)

  useEffect(() => {
    document.addEventListener('paste', handlePaste)
    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [])

  const handlePaste = (e) => {
    const items = e.clipboardData.items
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile()
        const reader = new FileReader()
        reader.onload = (event) => {
          const base64String = event.target.result.split(',')[1]
          const prefix = `data:${blob.type};base64,`
          const src = prefix + base64String
          if (src) {
            setPictureSrc({ src, name: 'item-paste-file.png' })
          }
        }
        reader.readAsDataURL(blob)
      }
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Fade}
      keepMounted
      onClose={(e, reason) => {
        setOpen(false)
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <div
        className="p-3 py-2 border-box border-b-05 text-xs "
        style={{
          width: '450px'
        }}
      >
        <span>Upload Profile Picture</span>
      </div>
      <DialogContent
        className="flex flex-col justify-center items-center gap-4"
        style={{
          padding: '0',
          minHeight: '350px'
        }}
      >
        <ImageAvatarFallback
          profilePic={pictureInfo?.src ?? profilePic}
          style={{
            height: '200px',
            width: '200px',
            fontSize: '20px'
          }}
          s3Obj={s3Obj}
        />

        {pictureInfo ? (
          <div className="flex items-center gap-2">
            <Button2
              secondary
              style={{
                padding: '0.5rem 1rem'
              }}
            >
              Reset
            </Button2>
            <Button2
              primary
              style={{
                padding: '0.5rem 1rem'
              }}
              onClick={() => {
                setUploadingProfilePic(true)
                handleSaveProfilePic(pictureInfo, () => {
                  setOpen(false)
                })
              }}
            >
              {uploadingProfilePic ? (
                <CircleLoader className="animate-spin size-4 text-white" />
              ) : (
                'Save'
              )}
            </Button2>
          </div>
        ) : (
          <label
            htmlFor="profileUpload"
            className="button-base flex items-center gap-1 bg-grey-800 rounded-lg border-1 border-grey-700 text-grey-800 px-3 text-xs p-1-5 text-white "
          >
            <CloudUpload className="size-4" /> Upload
            <input
              id="profileUpload"
              type="file"
              className="hidden"
              onChange={(e) => {
                const file = e.target.files?.[0]
                if (file) {
                  const reader = new FileReader()
                  reader.onloadend = () => {
                    const base64String = reader.result?.toString().split(',')[1]
                    if (base64String) {
                      const prefix = `data:${file.type};base64,`
                      const src = prefix + base64String

                      if (src) {
                        setPictureSrc({ src, name: file.name })
                      }
                    }
                  }
                  reader.readAsDataURL(file)
                }
              }}
            />
          </label>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default UploadProfileModal
