import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useDispatch } from 'react-redux'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import { isArray, capitalize, isEmpty } from 'lodash'
import { useStyles } from './styles'
import { joinDomain } from '../../store/api'
import Button from '../../components/Button'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { signOut } from '../../utils/User'
import { Auth } from 'aws-amplify'
import useConfirmation from '../../components/ConfirmDialog'
import { ROUTES } from '../../config/routes'
import { toast } from 'react-toastify'

const DomainList = (props) => {
  const classes = useStyles()
  const {
    dataContainer,
    key,
    data,
    loaderContainer,
    cardAction,
    container,
    container1,
    card,
    cardContent
  } = classes
  const { domain } = props
  const dispatch = useDispatch()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [loading, setLoading] = useState(false)

  const join = async (id, type, name, role) => {
    setLoading(true)
    if (id && type) {
      const data = {
        domain_id: id,
        domain_join: type,
        invited_role: role,
        domain_name: name
      }
      const res = await joinDomain(data)
      console.log(res)
      if (res.status === 200) {
        Auth.currentAuthenticatedUser().then(async (user) => {
          const session = await Auth.currentSession()
          user.refreshSession(session.refreshToken, async (err, session) => {
            window.location.reload()
          })
        })
      } else {
        toast.error('Something went wrong. Please try again.')
        setLoading(false)
      }
    }
  }

  const handleSelectDomain = (id, type, name, role) => {
    showConfirmDialog({
      onConfirm: () => {
        join(id, type, name, role)
      },
      confirmationMessageTitle: `Are you sure you want to join "${name}" ?`,
      confirmationMessage:
        "Once joined, you won't be able to change the domain."
    })
  }

  return (
    <Box>
      {domain === null || loading ? (
        <Box className={loaderContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {isArray(domain?.domain_list) && domain.domain_list.length > 0 && (
            <Box className={container}>
              <Typography variant="p">Domains open to join</Typography>
              <Grid container className={container1} spacing={1}>
                {isArray(domain?.domain_list) &&
                  domain.domain_list.map((dom, ind) => {
                    const {
                      name,
                      owner_name,
                      created_at,
                      id,
                      role = 'user'
                    } = dom
                    const date = new Date(created_at + 'Z')
                    return (
                      <Grid item key={ind}>
                        <Card variant="outlined" className={card}>
                          <CardContent className={cardContent}>
                            <Box className={dataContainer}>
                              <Box className={key}>{'Name'}</Box>
                              <Box className={data}>{name}</Box>
                            </Box>
                            {owner_name && (
                              <Box className={dataContainer}>
                                <Box className={key}>{'Owner'}</Box>
                                <Box className={data}>{owner_name}</Box>
                              </Box>
                            )}
                            <Box className={dataContainer}>
                              <Box className={key}>{'Created at'}</Box>
                              <Box className={data}>
                                {moment(date.toString()).format('ll')}
                              </Box>
                            </Box>
                            <CardActions className={cardAction}>
                              <Button
                                onClick={() =>
                                  handleSelectDomain(
                                    id,
                                    'open_join',
                                    name,
                                    'viewer'
                                  )
                                }
                                variant="text"
                              >
                                Join Domain
                              </Button>
                            </CardActions>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  })}
              </Grid>
            </Box>
          )}
          {isArray(domain?.invite_list) && domain.invite_list.length > 0 && (
            <Box className={container}>
              <Typography variant="p">Domains Invited</Typography>
              <Grid container className={container1}>
                {domain.invite_list.map((dom, ind) => {
                  const { domain_name, invited_by, created_at, id, role } = dom
                  return (
                    <Grid item key={ind}>
                      <Card variant="outlined" className={card}>
                        <CardContent className={cardContent}>
                          <Box className={dataContainer}>
                            <Box className={key}>{'Name'}</Box>
                            <Box className={data}>{domain_name}</Box>
                          </Box>
                          <Box className={dataContainer}>
                            <Box className={key}>{'Invited by'}</Box>
                            <Box className={data}>{invited_by}</Box>
                          </Box>
                          <Box className={dataContainer}>
                            <Box className={key}>{'Invited at'}</Box>
                            <Box className={data}>
                              {moment(created_at).format('ll')}
                            </Box>
                          </Box>
                          <CardActions className={cardAction}>
                            <Button
                              onClick={() =>
                                handleSelectDomain(
                                  id,
                                  'invited',
                                  domain_name,
                                  role
                                )
                              }
                              variant="text"
                            >
                              Accept Invite
                            </Button>
                          </CardActions>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          )}
          {isArray(domain?.domain_list) &&
            isArray(domain?.invite_list) &&
            isEmpty(domain?.domain_list) &&
            isEmpty(domain?.invite_list) && (
              <Box className={container}>
                <Box>No Domain or Invite Found.</Box>
                <Box>Please create a new domain to proceed.</Box>
              </Box>
            )}
          {ConfirmDialog}
        </>
      )}
    </Box>
  )
}

export default DomainList
