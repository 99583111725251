import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  outerContainer: {
    // borderTop: 'solid 1px #E5E5E5'
  },
  libraryContainer: {
    padding: '100px'
  },
  listContainer: {
    padding: '20px'
  }
})

export { useStyles }
