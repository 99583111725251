import { UPDATE_NEW_VALUE_TAG } from '../../actionTypes'

const updateNewValue = (payload) => async (dispatch) => {
  dispatch({
    type: UPDATE_NEW_VALUE_TAG,
    payload
  })
}

export default updateNewValue
