import { useEffect, useState, useRef, useLayoutEffect, Fragment } from 'react'
import { Container, Section, SectionFixed } from '../../components/Container'
import {
  Box,
  IconButton,
  Tooltip,
  List,
  ListItem,
  Skeleton,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
  Menu,
  MenuItem,
  Divider
} from '@mui/material'
import { ReactComponent as Toogle_Pane } from '../../assets/images/Chat_Session.svg'
import { useStyles } from './styles'
import {
  Tabs,
  Button,
  TextInput,
  MenuButton,
  IconPicker,
  useConfirmation,
  ReactSelect,
  ColorPicker
} from '../../components'
import { ReactComponent as All } from '../../assets/svg/AllFiles.svg'
import { ReactComponent as AllProposals } from '../../assets/svg/Proposals.svg'
import { ReactComponent as MyUploads } from '../../assets/svg/MyUpload.svg'
import { ReactComponent as AllAssets } from '../../assets/svg/Assets.svg'
import { ReactComponent as AllAssetsVideo } from '../../assets/svg/AssetsVideo.svg'
import { ReactComponent as PublicFolder } from '../../assets/svg/PublicFolder.svg'
import { ReactComponent as SharedFolder } from '../../assets/svg/SharedFolder.svg'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCollections,
  setExpandedObj,
  addNewToCollections,
  updateActiveCollection,
  getCollectionChildren,
  updateCollections,
  deleteCollection,
  updateCollectionObj
} from '../../store/Resource/Action'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import * as MUIIcons from '@mui/icons-material'
import LockIcon from '@mui/icons-material/Lock'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import StopIcon from '@mui/icons-material/Stop'
import clsx from 'clsx'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { checkUserRoleViewer } from '../../utils/User'
import {
  AllIcon,
  CloseSidebar,
  CloudUpload,
  CollectionIcon,
  DocumentIcon,
  ImagesIcon,
  ProposalIcon,
  SharedPeople,
  VideoPlay
} from '../../components/Icons/Icons'
import { copyFilesToCollection, moveFilesToCollection } from '../../store/api'

const perList = [
  {
    id: 'all',
    label: 'All Files',
    type: 'defaultPrelist',
    icon: <AllIcon />,
    serverType: 'all'
  },
  {
    id: 'allProposals',
    label: 'Proposals',
    type: 'defaultPrelist',
    icon: <ProposalIcon />,
    serverType: 'proposal'
  },
  {
    id: 'allAssetsImage',
    label: 'Images',
    type: 'defaultPrelist',
    icon: <ImagesIcon />,
    serverType: 'assetsImage'
  },
  {
    id: 'allAssetsVideo',
    label: 'Videos',
    type: 'defaultPrelist',
    icon: <VideoPlay />,
    serverType: 'assetsVideo'
  },
  {
    id: 'allDocuments',
    label: 'Documents',
    type: 'defaultPrelist',
    icon: <DocumentIcon />,
    serverType: 'documents'
  },
  {
    id: 'myUploads',
    label: 'My Uploads',
    type: 'defaultPrelist',
    icon: <CloudUpload />,
    serverType: 'allMyUploads'
  }
]

const sharedCollection = {
  id: 'sharedCollections',
  label: 'Shared Collection',
  type: 'defaultPrelist',
  icon: <All />,
  serverType: 'sharedCollections'
}

const allCollection = {
  id: 'allCollection',
  label: 'All Files',
  type: 'defaultPrelist',
  icon: <All />,
  serverType: 'all'
}

const defaultCollectionList = [
  {
    id: 'proposals',
    label: 'Proposals',
    type: 'default',
    serverType: 'proposal'
  },
  {
    id: 'analytics',
    label: 'Analytics',
    type: 'default',
    serverType: 'analytics'
  },
  {
    id: 'rfx',
    label: 'RFX',
    type: 'default',
    serverType: 'rfx'
  },
  {
    id: 'Knowledge',
    label: 'Knowledge',
    type: 'default',
    serverType: 'library'
  },
  {
    id: 'assets',
    label: 'Assets',
    type: 'default',
    serverType: 'assets'
  }
]

const SortMenu = (props) => {
  const {
    filterBy = [],
    setFilterBy = () => {},
    disabled = false,
    filterOpen = false,
    setFilterOpen = () => {}
  } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!disabled) {
      setFilterOpen(!filterOpen)
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setFilterOpen(false)
  }

  const filterOptions = [
    {
      key: 'name',
      order: 'asc',
      label: 'Name (A-Z)'
    },
    {
      key: 'name',
      order: 'desc',
      label: 'Name (Z-A)'
    },
    {
      key: 'created_at',
      order: 'desc',
      label: 'Created Date (Newest)'
    },
    {
      key: 'created_at',
      order: 'asc',
      label: 'Created Date (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'asc',
      label: 'Last Modified (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'desc',
      label: 'Last Modified (Newest)'
    }
  ]

  const handleFilter = (e, filter) => {
    e.stopPropagation()
    e.preventDefault()
    setFilterBy(filter)
    handleClose()
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <IconButton
        className={classes.iconButton}
        onClick={handleClick}
        disabled={disabled}
      >
        <SwapVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.hideMenu}
      >
        {filterOptions.map((option, index) => (
          <Box key={index}>
            <MenuItem
              selected={
                filterBy.key === option.key && filterBy.order === option.order
              }
              onClick={(e) =>
                handleFilter(e, { key: option.key, order: option.order })
              }
            >
              <Box className={classes.hideMenuListText}>{option.label}</Box>
            </MenuItem>
            <Divider className={classes.hideMenuDivider} />
          </Box>
        ))}
      </Menu>
    </Box>
  )
}

const LineClampTooltip = ({ text, disbleToolTip, ...rest }) => {
  const textRef = useRef(null)
  const [hasLineClamp, setHasLineClamp] = useState(false)

  useLayoutEffect(() => {
    const element = textRef.current
    if (element) {
      const hasLineClamp = element.scrollHeight > element.clientHeight
      setHasLineClamp(hasLineClamp)
    }
  }, [text])

  return (
    <Tooltip title={hasLineClamp && !disbleToolTip ? text : ''} placement="top">
      <Box ref={textRef} {...rest}>
        {text}
      </Box>
    </Tooltip>
  )
}

const SidePane = (props) => {
  const {
    toggleListPane = () => {},
    listWidth = 0,
    dragActive,
    setDragActive = () => {},
    rows = null,
    setRowsState = () => {},
    collectionsRows = null,
    setCollectionsRows = () => {},
    handleDragEnd = () => {}
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()

  const collectionsState = useSelector((state) => state.resource?.collections)

  const activeCollection = useSelector(
    (state) => state.resource?.activeCollection
  )

  const expanded = useSelector((state) => state.resource?.expanded)

  const userData = useSelector((state) => state.authenticate?.user)
  const domainData = userData?.domain
  const { domain_users = [] } = domainData || {}
  const [filterBy, setFilterBy] = useState({
    key: 'name',
    order: 'asc'
  })
  const [filterOpen, setFilterOpen] = useState(false)

  const [loadingCollections, setLoadingCollections] = useState(true)
  const [collections, setCollections] = useState(null)
  const { ownerCollections = [], sharedCollections = [] } = collections || {}

  const isUserViewer = checkUserRoleViewer()

  const getDataFromLocal = () => {
    let data = localStorage.getItem('resourceCollectionsSort')
    data = data ? JSON.parse(data) : null
    return data
  }

  const saveDataToLocal = (data) => {
    localStorage.setItem('resourceCollectionsSort', JSON.stringify(data))
  }

  useEffect(() => {
    const data = getDataFromLocal()
    if (data) {
      setFilterBy(data)
    }
  }, [])

  useEffect(() => {
    if (!collectionsState) {
      setLoadingCollections(true)
      dispatch(getCollections())
    } else {
      const collectionSorted = sortCollections(_.cloneDeep(collectionsState))
      setCollections(collectionSorted)
      setLoadingCollections(false)
      saveDataToLocal(filterBy)
    }
  }, [collectionsState, filterBy])

  const [newCollection, setNewCollection] = useState({
    collection_name: '',
    collection_type: 'public',
    collection_description: '',
    icon: '',
    color: '',
    access_config: []
  })
  const [showCollectionCreate, setShowCollectionCreate] = useState(false)
  const [showCollectionEdit, setShowCollectionEdit] = useState(false)
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()

  const handleCreateClose = () => {
    setShowCollectionCreate(false)
    setShowCollectionEdit(false)
    setNewCollection({
      collection_name: '',
      collection_type: 'public',
      collection_description: '',
      icon: '',
      color: '',
      access_config: []
    })
  }

  const handleNewCollectionCreate = () => {
    if (newCollection?.collection_name && newCollection?.collection_type) {
      const parentElement =
        showCollectionCreate[showCollectionCreate.length - 1]
      const { id } = parentElement
      const nesting = id === 'root' ? [] : showCollectionCreate.map((p) => p.id)
      const user_ids = newCollection?.access_config?.map((data) => data?.value)
      const req = {
        id: uuidv4(),
        name: newCollection.collection_name,
        parent_id: id === 'root' ? null : id,
        access_config: {
          user_ids
        },
        is_public: newCollection.collection_type === 'public',
        nesting,
        status: 'loading',
        icon: newCollection.icon,
        color: newCollection.color,
        description: newCollection.collection_description,
        hasChildren: false,
        user_collection: true,
        folder_rights: 'owner',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }
      const callback = () => {
        handleCreateClose()
        setExpanded({ ...expanded, [id]: true })
      }
      dispatch(addNewToCollections(req, callback))
    } else {
      toast.error('Name is required')
    }
  }

  const handleCreateEditChange = (e, key) => {
    setNewCollection((prevCollection) => {
      const newKey = e.target.value
      if (prevCollection && prevCollection.changed_key) {
        return {
          ...prevCollection,
          [key]: newKey,
          changed_key: [...prevCollection.changed_key, key]
        }
      } else {
        return {
          ...prevCollection,
          [key]: newKey,
          changed_key: [key]
        }
      }
    })
  }

  const handleCollectionUpdate = () => {
    const showCollectionEditCopy = { ...showCollectionEdit }
    const newCollectionCopy = { ...newCollection }
    handleCreateClose()
    if (newCollection?.collection_name && newCollection?.collection_type) {
      const { id } = showCollectionEditCopy
      const { changed_key = [] } = newCollectionCopy || {}
      if (changed_key.length === 0) {
        return
      }
      const req = {
        id
      }
      changed_key.forEach((key) => {
        if (key === 'collection_type') {
          req.is_public = newCollectionCopy.collection_type === 'public'
        } else if (key === 'access_config') {
          const user_ids = newCollectionCopy?.access_config?.map(
            (data) => data?.value
          )
          req.access_config = {
            user_ids
          }
        } else if (key === 'collection_name') {
          req.name = newCollectionCopy[key]
        } else if (key === 'collection_description') {
          req.description = newCollectionCopy[key]
        } else {
          req[key] = newCollectionCopy[key]
        }
      })
      if (
        changed_key.includes('collection_type') ||
        changed_key.includes('access_config')
      ) {
        showConfirmDialog({
          onConfirm: () => {
            dispatch(
              updateCollections(
                req,
                () => {},
                () => {
                  dispatch(getCollectionChildren(id, () => {}, true))
                }
              )
            )
          },
          confirmationMessageTitle: `Are you sure you want to update the sharing settings for the collection "${
            newCollectionCopy?.collection_name || ''
          }" ?`,
          confirmationMessage:
            'This action will also update all sharing settings of the subcollections and files under this collection.'
        })
      } else {
        dispatch(updateCollections(req))
      }
    } else {
      toast.error('Name is required')
    }
  }

  const domainUsersOptions = domain_users.map((data) => {
    return {
      label: data?.user_name,
      value: data?.id,
      ...data
    }
  })

  const handleCollectionCreateShow = (path, parent) => {
    if (parent) {
      const { access_config = {} } = parent
      const { user_ids = [] } = access_config
      const userSelected = []
      user_ids.forEach((data) => {
        const user = domainUsersOptions.find((user) => user?.value === data)
        userSelected.push({
          label: user?.label,
          value: user?.value
        })
      })
      setNewCollection({
        collection_type: parent.is_public ? 'public' : 'private',
        access_config: userSelected,
        parent_type: parent.is_public ? 'public' : 'private'
      })
    }
    setShowCollectionCreate(path)
  }
  const renderCollectionDialogCreate = () => {
    const title = 'Create New Collection'
    const buttonText = 'Create'
    const isOpen = !!showCollectionCreate
    const buttonFunction = handleNewCollectionCreate
    const isParentPublic = newCollection?.parent_type === 'public'
    return (
      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleCreateClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextInput
            placeholder="Collection Name"
            value={newCollection?.collection_name}
            handleChange={(e) => handleCreateEditChange(e, 'collection_name')}
          />
          <Box
            sx={{
              marginTop: '10px',
              '& .MuiSelect-select': { padding: '8.5px 14px' }
            }}
          >
            <ReactSelect
              options={[
                { value: 'public', label: 'Public' },
                { value: 'private', label: 'Private' }
              ]}
              disabled={isParentPublic}
              onChange={(e) => {
                setNewCollection({
                  ...newCollection,
                  collection_type: e.value
                })
              }}
              defaultValue={{
                value: newCollection?.collection_type,
                label: _.startCase(newCollection?.collection_type)
              }}
              placeholder="Collection Type"
            />
            {isParentPublic && (
              <Box sx={{ fontSize: '14px' }}>
                Private collections cannot be created under public collections.
              </Box>
            )}
          </Box>
          <TextInput
            style={{ marginTop: '10px' }}
            placeholder="Collection Description"
            value={newCollection?.collection_description}
            multiLine
            rows={4}
            handleChange={(e) =>
              handleCreateEditChange(e, 'collection_description')
            }
          />
          {newCollection?.collection_type === 'private' && (
            <>
              <Box sx={{ margin: '10px 0px' }}>
                <ReactSelect
                  options={domainUsersOptions}
                  defaultValue={newCollection?.access_config}
                  onChange={(e) => {
                    setNewCollection({
                      ...newCollection,
                      access_config: e
                    })
                  }}
                  placeholder="Select Users to share with"
                  isMulti
                  menuPortalTarget={document.body}
                />
              </Box>
              <Box sx={{ fontSize: '14px' }}>
                All the subcollections under this collection will also inherit
                the same access permissions.
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={buttonFunction}>{buttonText}</Button>
          <Button variant="outlined" onClick={handleCreateClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderCollectionDialogEdit = () => {
    const title = 'Edit Collection'
    const buttonText = 'Save'
    const isOpen = !!showCollectionEdit
    const buttonFunction = handleCollectionUpdate
    const { changed_key = [] } = newCollection || {}
    const isParentPublic =
      newCollection?.parent_type === 'public' &&
      (newCollection?.parent_id !== 'None' || !newCollection?.parent_id)
    return (
      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleCreateClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextInput
            placeholder="Collection Name"
            value={newCollection?.collection_name}
            handleChange={(e) => handleCreateEditChange(e, 'collection_name')}
          />
          <Box
            sx={{
              marginTop: '10px',
              '& .MuiSelect-select': { padding: '8.5px 14px' }
            }}
          >
            <ReactSelect
              disabled={isParentPublic}
              options={[
                { value: 'public', label: 'Public' },
                { value: 'private', label: 'Private' }
              ]}
              onChange={(e) => {
                setNewCollection({
                  ...newCollection,
                  collection_type: e.value,
                  access_config: [],
                  changed_key: [
                    ...changed_key,
                    'collection_type',
                    'access_config'
                  ]
                })
              }}
              defaultValue={{
                value: newCollection?.collection_type,
                label: _.startCase(newCollection?.collection_type)
              }}
              placeholder="Collection Type"
            />
            {isParentPublic && (
              <Box sx={{ fontSize: '14px' }}>
                Private collections cannot be created under public collections.
              </Box>
            )}
          </Box>
          <TextInput
            style={{ marginTop: '10px' }}
            placeholder="Collection Description"
            value={newCollection?.collection_description}
            multiLine
            rows={4}
            handleChange={(e) =>
              handleCreateEditChange(e, 'collection_description')
            }
          />
          {/* <Box sx={{ marginTop: '10px' }}>
                        <IconPicker
                            selectedIcon={newCollection?.icon}
                            onSelect={(value) => {
                                setNewCollection({
                                    ...newCollection,
                                    icon: value
                                });
                            }}
                            placeholder="Collection Icon"
                        />
                    </Box> */}
          {/* <Box sx={{ marginTop: '10px' }}>
                        <ColorPicker color={newCollection?.color || '#FFFFFF'}
                            onChange={(value) => {
                                setNewCollection({
                                    ...newCollection,
                                    color: value
                                });
                            }}
                            showLabel
                            label={"Choose a accent color for the collection"}
                            labelStyle={{ fontSize: '14px', color: '#797979' }}
                        />
                    </Box> */}
          {newCollection?.collection_type === 'private' && (
            <>
              <Box sx={{ margin: '10px 0px' }}>
                <ReactSelect
                  options={domainUsersOptions}
                  defaultValue={newCollection?.access_config}
                  onChange={(e) => {
                    setNewCollection({
                      ...newCollection,
                      access_config: e,
                      changed_key: [...changed_key, 'access_config']
                    })
                  }}
                  placeholder="Select Users to share with"
                  isMulti
                  menuPortalTarget={document.body}
                />
              </Box>
              <Box sx={{ fontSize: '14px' }}>
                All the subcollections under this collection will also inhert
                the same access permissions.
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={buttonFunction}>{buttonText}</Button>
          <Button variant="outlined" onClick={handleCreateClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleMenuEdit = ({ item, parent }) => {
    setShowCollectionEdit(item)
    const { access_config = {} } = item
    const { user_ids = [] } = access_config
    const userSelected = []
    user_ids.forEach((data) => {
      const user = domainUsersOptions.find((user) => user?.value === data)
      userSelected.push({
        label: user?.label,
        value: user?.value
      })
    })
    setNewCollection({
      collection_name: item.name,
      collection_type: item.is_public ? 'public' : 'private',
      collection_description: item.description,
      icon: item.icon,
      parent_id: item?.parent_id || 'None',
      parent_type: parent ? (parent.is_public ? 'public' : 'private') : 'None',
      access_config: userSelected,
      color: item.color
    })
  }

  const handleDelete = ({ item }) => {
    showConfirmDialog({
      onConfirm: () => {
        const { id } = item
        dispatch(deleteCollection({ id }))
      },
      confirmationMessageTitle: `Are you sure you want to delete the collection "${
        item?.name || ''
      }" ?`,
      confirmationMessage:
        'This action will delete all subcollections within this collection, and any private files that are not part of other collections will be permanently deleted.'
    })
  }

  const menuOptions = [
    {
      label: 'Edit',
      icon: <DriveFileRenameOutlineIcon />,
      onClick: handleMenuEdit
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: handleDelete
    }
  ]
  const sortData = (data = [], filterBy = {}) => {
    return data
      .sort((a, b) => {
        const key = filterBy.key
        let order = filterBy.order
        let comparisonA = a[key]
        let comparisonB = b[key]

        if (key === 'created_at' || key === 'updated_at') {
          comparisonA = new Date(a[key])
          comparisonB = new Date(b[key])
          if (comparisonA.getTime() === comparisonB.getTime()) {
            comparisonA = a.name.toLowerCase()
            comparisonB = b.name.toLowerCase()
            order = 'asc'
          }
        } else if (key === 'name') {
          comparisonA = a[key].toLowerCase()
          comparisonB = b[key].toLowerCase()
        }

        if (comparisonA < comparisonB) {
          return order === 'desc' ? 1 : -1
        }
        if (comparisonA > comparisonB) {
          return order === 'desc' ? -1 : 1
        }
        return 0
      })
      .map((collection) => ({
        ...collection,
        children: collection.children
          ? sortData(collection.children, filterBy)
          : null
      }))
  }

  const sortCollections = (collections) => {
    if (collections) {
      const ownerCollections = sortData(
        collections?.filter((data) => data?.folder_rights === 'owner'),
        filterBy
      )
      const sharedCollections = sortData(
        collections?.filter((data) => data?.folder_rights === 'shared'),
        filterBy
      )
      return { ownerCollections, sharedCollections }
    } else {
      return {}
    }
  }

  const [hoveredId, setHoveredId] = useState(null)

  const handleMouseEnter = (id) => {
    setHoveredId(id)
  }

  const handleMouseLeave = () => {
    setHoveredId(false)
  }

  const setExpanded = (obj) => {
    dispatch(setExpandedObj(obj))
  }

  const setActiveCollection = (item) => {
    if (filterOpen) {
      return
    }
    item.clearSearch = true
    dispatch(updateActiveCollection(item))
    const { children, id, type, hasChildren = false } = item
    if (type === 'collection' && !children && hasChildren) {
      dispatch(getCollectionChildren(id))
      setExpanded({ ...expanded, [id]: true })
    }
  }

  const handleDragOver = (event, key, item) => {
    event.stopPropagation()
    event.preventDefault()
    if (['owner', 'shared'].includes(item?.folder_rights) || key === 'root') {
      setDragActive(key)
    }
  }

  const handleDrop = (event, item) => {
    try {
      handleDragEnd()
      if (
        !['owner', 'shared'].includes(item?.folder_rights) &&
        item.id !== 'root'
      ) {
        return
      }
      const dragGhost = document.getElementById('drag-ghost')
      if (dragGhost) {
        document.body.removeChild(dragGhost)
      }
      let el = event.dataTransfer.getData('data')
      const isMulti = event.dataTransfer.getData('multiple') || false
      let previousParent = event.dataTransfer.getData('parent')
      el = JSON.parse(el)
      previousParent = JSON.parse(previousParent)

      if (item.id === 'root') {
        item.id = 'None'
      }
      const isRoot = item.id === 'None'
      const extraButtons = []
      if (
        previousParent?.serverType === 'collection' ||
        el?.file_type === 'collection'
      ) {
        extraButtons.push({
          id: 0,
          onClick: () => {
            handleCopyMove(el, previousParent, item, 'move', isMulti)
          },
          text: 'Move'
        })
      }
      if (isMulti) {
        if (previousParent.id === item.id || previousParent.id === el.id) {
          toast.error('Cannot move a collection into itself')
          return
        }
        const selfCollections = el.filter((data) => data.id === item.id)
        if (selfCollections.length > 0) {
          const names = selfCollections.map((data) => data.file_name).join(', ')
          toast.error(`Cannot move a collection into itself : ${names}`)
          return
        }
        if (item.id === 'None' && el.file_type !== 'collection') {
          return
        }
        const filesName = el.map((data) => data.file_name)
        showConfirmDialog({
          onConfirm: () => {
            handleCopyMove(el, previousParent, item, 'copy', isMulti)
          },
          confirmationMessageTitle: `Do you want to ${
            extraButtons.length > 0 ? 'move or copy' : 'move'
          } the following items to the collection "${item.name}" ?`,
          confirmationMessage: (
            <Box>
              {el?.file_type === 'collection' && (
                <Box sx={{ fontWeight: 'bold' }}>
                  Copying or moving a collection will also copy or move all the
                  subcollections under it. The sharing permissions will be
                  inherited from the destination.
                </Box>
              )}
              <Box>
                {(filesName || []).map((name, index) => (
                  <Box key={index}>{name}</Box>
                ))}
              </Box>
            </Box>
          ),
          confirmButtonText: 'Copy',
          extraButtons
        })
      } else {
        if (
          previousParent.id === item.id ||
          previousParent.id === el.id ||
          item.id === el.id ||
          (item.id === 'None' && el.file_type !== 'collection')
        ) {
          return
        }
        showConfirmDialog({
          onConfirm: () => {
            handleCopyMove(el, previousParent, item, 'copy', isMulti)
          },
          confirmationMessageTitle: `Do you want to ${
            extraButtons.length > 0 ? 'move or copy' : 'move'
          }" ${el.file_name}" to the collection "${item.name}" ?`,
          confirmationMessage:
            el?.file_type === 'collection'
              ? 'Copying or moving a collection will also copy or move all the subcollections under it. The sharing permissions will be inherited from the destination.'
              : '',
          confirmButtonText: 'Copy',
          extraButtons
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleCopyMove = async (
    el,
    previousParent,
    newParent,
    action,
    isMulti
  ) => {
    let { id: prevId } = previousParent
    prevId = prevId === 'all' ? 'None' : prevId
    const { serverType: prevServerType } = previousParent
    const { id: newId } = newParent
    const from_collection_id = prevServerType === 'collection' ? prevId : 'None'
    const files = isMulti ? el : [el]
    const req = {
      from_collection_id,
      to_collection_id: newId,
      documents: files
    }
    if (action === 'move') {
      files.forEach((file) => {
        const { id, file_type } = file
        if (file_type === 'collection') {
          dispatch(updateCollectionObj({ id, status: 'loading' }))
        }
      })
    }
    const api =
      action === 'copy' ? copyFilesToCollection : moveFilesToCollection
    const res = await api(req)
    if (res.status === 200) {
      toast.success(`${action === 'copy' ? 'Copied' : 'Moved'} successfully`)
      if (action === 'move') {
        let newRows = rows
        newRows = rows?.filter(
          (data) => !files.some((item) => item.id === data.id)
        )
        setRowsState(newRows)
      }
      const collectionsArray = isMulti
        ? el.filter((data) => data.file_type === 'collection')
        : el.file_type === 'collection'
        ? [el]
        : []
      if (collectionsArray.length > 0) {
        let refreshCollections = false
        if (action === 'move') {
          if (prevId !== 'None') {
            dispatch(getCollectionChildren(prevId, () => {}, true))
          } else {
            refreshCollections = true
          }
          if (newId === 'None') {
            refreshCollections = true
          } else {
            dispatch(getCollectionChildren(newId, () => {}, true))
          }
          collectionsArray.forEach((collection) => {
            if (activeCollection.id === collection.id) {
              dispatch(
                updateActiveCollection({
                  id: 'all',
                  label: 'All Files',
                  type: 'defaultPrelist',
                  serverType: 'all'
                })
              )
            }
          })
        } else {
          if (newId === 'None') {
            refreshCollections = true
          } else {
            dispatch(getCollectionChildren(newId, () => {}, true))
          }
        }
        if (refreshCollections) {
          dispatch(getCollections(true))
        }
      }
    } else {
      toast.error(`Unable to ${action}`)
    }
    if (action === 'move') {
      files.forEach((file) => {
        const { id, file_type } = file
        if (file_type === 'collection') {
          dispatch(updateCollectionObj({ id, status: 'active' }))
        }
      })
    }
  }

  const handleDragStart = (event, row) => {
    if (!['owner', 'shared'].includes(row?.folder_rights)) {
      return
    }
    const data = {
      id: row.id,
      file_name: row.name,
      file_type: 'collection'
    }

    const parent = {
      id: row.parent_id ? row.parent_id : 'None',
      serverType: 'collection'
    }

    event.dataTransfer.setData('data', JSON.stringify(data))
    event.dataTransfer.setData('parent', JSON.stringify(parent))
  }

  const renderIcon = (item) => {
    const { is_public, access_config } = item
    const { user_ids = [] } = access_config || {}
    if (is_public) {
      return <PublicFolder />
    } else if (!is_public && user_ids.length > 0) {
      return <SharedFolder />
    } else {
      return <></>
    }
  }

  const CollectionItem = ({
    item,
    index,
    path = [],
    editable,
    parent = null
  }) => {
    const {
      id,
      name,
      icon,
      status,
      type,
      hasChildren = false,
      children,
      folder_rights
    } = item
    // const IconRender = MUIIcons[icon];
    const isSelected =
      activeCollection?.id === id && activeCollection?.type === type
    const isExpanded = expanded[id]
    const isHovered = hoveredId === id
    const currentPath = [...path, item]

    return status === 'active' ? (
      <Box key={index}>
        <ListItem
          disablePadding
          onMouseEnter={() => handleMouseEnter(id)}
          onMouseLeave={() => handleMouseLeave(id)}
          draggable={editable}
          onDragStart={(event) => handleDragStart(event, item)}
          onDrop={(event) => handleDrop(event, item)}
          onDragOver={(event) => {
            handleDragOver(event, id, item)
          }}
          onDragLeave={handleDragEnd}
          style={{
            border: dragActive === id ? '1px solid #0645AD' : ''
          }}
        >
          <ListItemButton
            disableRipple
            className={classes.listButtonWrapper}
            selected={isSelected}
            onClick={() => {
              setActiveCollection({ ...item, serverType: 'collection' })
            }}
          >
            <ListItemText
              primary={
                <Box className={classes.collectionTitleWrapper}>
                  <Box className={classes.listTextWrapper}>
                    {hasChildren ? (
                      isExpanded ? (
                        <IconButton
                          className={classes.iconButton}
                          onClick={(e) => {
                            setExpanded({ ...expanded, [id]: false })
                            e.stopPropagation()
                          }}
                          sx={{ width: '15px' }}
                        >
                          <ArrowDropDownIcon className={classes.icon} />
                        </IconButton>
                      ) : (
                        <IconButton
                          className={classes.iconButton}
                          onClick={(e) => {
                            if (!children) {
                              dispatch(getCollectionChildren(id))
                            }
                            setExpanded({ ...expanded, [id]: true })
                            e.stopPropagation()
                          }}
                          sx={{ width: '15px' }}
                        >
                          <ArrowRightIcon className={classes.icon} />
                        </IconButton>
                      )
                    ) : (
                      <IconButton disabled className={classes.iconButton}>
                        <StopIcon
                          className={classes.iconEmpty}
                          sx={{ width: '15px' }}
                        />
                      </IconButton>
                    )}

                    {/* {icon && <IconRender className={classes.icon} />} */}
                    <Box className={classes.shareIcon}>
                      <LineClampTooltip
                        className={classes.listText}
                        text={name}
                        disbleToolTip={dragActive}
                      />
                      {/* {!isHovered && <SvgIcon>{renderIcon(item)}</SvgIcon>} */}
                    </Box>
                  </Box>
                  {isHovered && editable && (
                    <Box className={classes.listTextWrapperSelected}>
                      <IconButton
                        className={classes.iconButton}
                        onClick={(e) => {
                          handleCollectionCreateShow(currentPath, item)
                          e.stopPropagation()
                        }}
                      >
                        <AddIcon className={classes.icon} />
                      </IconButton>
                      <MenuButton
                        options={menuOptions}
                        callBackData={{ item, parent }}
                      />
                    </Box>
                  )}
                </Box>
              }
            />
          </ListItemButton>
        </ListItem>
        {isExpanded && (
          <Box className={classes.nestedCollection}>
            {children ? (
              children.map((child, index) => {
                return (
                  <CollectionItem
                    key={index}
                    item={child}
                    index={index}
                    path={currentPath}
                    editable={editable}
                    parent={item}
                  />
                )
              })
            ) : (
              <Skeleton
                variant="rectangular"
                width={'100%'}
                height={26.5}
                sx={{ marginBottom: '3px', marginTop: '2px' }}
              />
            )}
          </Box>
        )}
      </Box>
    ) : status === 'loading' ? (
      <ListItem disablePadding>
        <ListItemButton disabled className={classes.listButtonWrapper}>
          <ListItemText
            primary={
              <Box className={classes.listTextWrapper}>
                <IconButton disabled className={classes.iconButton}>
                  <StopIcon className={classes.iconEmpty} />
                </IconButton>
                {/* {icon && <IconRender className={classes.icon} />} */}
                <LineClampTooltip
                  className={classes.listText}
                  text={name}
                  disbleToolTip={dragActive}
                />
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
    ) : (
      <></>
    )
  }

  return (
    <Container>
      <SectionFixed>
        <Box className={classes.toggleButtonContainer}>
          <Box className={classes.tabName}>Files</Box>
          <Tooltip title="Close Sidepane">
            <IconButton onClick={() => toggleListPane()}>
              <CloseSidebar
                width={18}
                height={18}
                stroke="#e4e4e7"
                strokeWidth={0.5}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className={classes.defaultList}>
          <List className={classes.listWrapper}>
            {perList.map((item, index) => {
              const { id, label, type = 'default', icon } = item
              const isSelected =
                activeCollection?.id === id && activeCollection?.type === type
              return (
                <ListItem disablePadding key={index}>
                  <ListItemButton
                    disableRipple
                    className={classes.listButtonWrapper}
                    selected={isSelected}
                    onClick={() => setActiveCollection(item)}
                  >
                    <ListItemText
                      primary={
                        <Box className={classes.listTextWrapper}>
                          <SvgIcon
                            className={classes.preIcon}
                            style={{
                              color: isSelected
                                ? 'var(--grey-800)'
                                : 'var(--grey-500)'
                            }}
                          >
                            {icon}
                          </SvgIcon>
                          <LineClampTooltip
                            className={classes.listText}
                            text={label}
                            disbleToolTip={dragActive}
                          />
                        </Box>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </Box>
      </SectionFixed>
      <Section overFlow>
        <Box>
          <Box className={classes.collectionsList}>
            <Box
              style={{
                border: dragActive === 'root' ? '1px solid #0645AD' : ''
              }}
            >
              <Box className={classes.collectionTitleWrapper}>
                <ListItem
                  disablePadding
                  onDrop={(event) =>
                    handleDrop(event, { id: 'root', name: 'root' })
                  }
                  onDragOver={(event) => {
                    handleDragOver(event, 'root', { id: 'root' })
                  }}
                  onDragLeave={handleDragEnd}
                >
                  <ListItemButton
                    disableRipple
                    className={classes.listButtonWrapper}
                    selected={
                      activeCollection?.id === allCollection.id &&
                      activeCollection?.type === allCollection.type
                    }
                    onClick={() => setActiveCollection(allCollection)}
                  >
                    <ListItemText
                      primary={
                        <Box className={classes.collectionTitleWrapper}>
                          <Box className={classes.listTextWrapper}>
                            <CollectionIcon
                              style={{
                                height: '16px',
                                width: '16px',
                                color:
                                  activeCollection?.id === allCollection.id &&
                                  activeCollection?.type === allCollection.type
                                    ? 'var(--grey-800)'
                                    : 'var(--grey-600)'
                              }}
                            />
                            <LineClampTooltip
                              className={classes.listHeader}
                              text={'Collections'}
                              disbleToolTip={dragActive}
                            />
                          </Box>
                          <Box sx={{ display: 'flex' }}>
                            {isUserViewer ? (
                              <></>
                            ) : (
                              <IconButton
                                className={classes.iconButton}
                                onClick={(e) => {
                                  handleCollectionCreateShow([{ id: 'root' }])
                                  e.stopPropagation()
                                }}
                              >
                                <AddIcon className={classes.icon} />
                              </IconButton>
                            )}
                            {/* <SortMenu
                              filterBy={filterBy}
                              setFilterBy={setFilterBy}
                              filterOpen={filterOpen}
                              setFilterOpen={setFilterOpen}
                            /> */}
                          </Box>
                        </Box>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </Box>
              {ownerCollections.length > 0 && (
                <Box className={classes.collectionListWrapper}>
                  <List className={classes.listWrapper}>
                    {loadingCollections ? (
                      <>
                        {[...Array(5)].map((item, index) => (
                          <Skeleton
                            variant="rectangular"
                            key={index}
                            width={'100%'}
                            height={26.5}
                            sx={{ marginBottom: '5px' }}
                          />
                        ))}
                      </>
                    ) : (
                      ownerCollections &&
                      ownerCollections.map((item, index) => (
                        <CollectionItem
                          key={index}
                          item={item}
                          index={index}
                          editable={true}
                        />
                      ))
                    )}
                  </List>
                </Box>
              )}
            </Box>
            {sharedCollections?.length > 0 && (
              <>
                <Box className={classes.collectionTitleWrapper}>
                  <ListItemButton
                    disableRipple
                    selected={
                      activeCollection?.id === sharedCollection.id &&
                      activeCollection?.type === sharedCollection.type
                    }
                    className={classes.listButtonWrapper}
                    onClick={() => setActiveCollection(sharedCollection)}
                  >
                    <ListItemText
                      primary={
                        <Box className={classes.collectionTitleWrapper}>
                          <Box className={classes.listTextWrapper}>
                            <SharedPeople
                              style={{
                                height: '16px',
                                width: '16px',
                                color:
                                  activeCollection?.id ===
                                    sharedCollection.id &&
                                  activeCollection?.type ===
                                    sharedCollection.type
                                    ? 'var(--grey-800)'
                                    : 'var(--grey-600)'
                              }}
                            />
                            <LineClampTooltip
                              className={classes.listHeader}
                              text={'Shared with me'}
                              disbleToolTip={dragActive}
                            />
                          </Box>
                        </Box>
                      }
                    />
                  </ListItemButton>
                </Box>
                <Box className={classes.collectionListWrapper}>
                  <List className={classes.listWrapper}>
                    {loadingCollections ? (
                      <>
                        {[...Array(5)].map((item, index) => (
                          <Skeleton
                            variant="rectangular"
                            key={index}
                            width={'100%'}
                            height={26.5}
                            sx={{ marginBottom: '5px' }}
                          />
                        ))}
                      </>
                    ) : (
                      sharedCollections &&
                      sharedCollections.map((item, index) => (
                        <CollectionItem
                          key={index}
                          item={item}
                          index={index}
                          editable={false}
                        />
                      ))
                    )}
                  </List>
                </Box>
              </>
            )}
          </Box>
        </Box>
        {ConfirmDialog}
        {renderCollectionDialogCreate()}
        {renderCollectionDialogEdit()}
      </Section>
    </Container>
  )
}

export default SidePane
