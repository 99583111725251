import * as React from 'react'
import { Stage, Layer, Rect, Transformer, Text, Label, Tag } from 'react-konva'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { Html } from 'react-konva-utils'
import VisibleIcon from '@mui/icons-material/Visibility'
import VisibleOffIcon from '@mui/icons-material/VisibilityOff'
import FormatShapesIcon from '@mui/icons-material/FormatShapes'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip from '@mui/material/Tooltip'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import html2canvas from 'html2canvas'
import TransformIcon from '@mui/icons-material/Transform'
import { removeClassNamesFromHTML } from '../../../utils/CopyHTML'

const Rectangle = ({
  shapeProps,
  isSelected,
  selectedMul,
  onSelect,
  onChange,
  id,
  mode,
  isReadOnly
}) => {
  const shapeRef = React.useRef()
  const trRef = React.useRef()

  React.useEffect(() => {
    if (isSelected && !isReadOnly) {
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected, isReadOnly])
  const outlineShape = { ...shapeProps }
  if (isReadOnly && isSelected) {
    outlineShape.stroke = '#6BBDFA'
    outlineShape.opacity = 1
    outlineShape.strokeWidth = 2
    outlineShape.fillEnabled = false
  }
  return (
    <React.Fragment>
      {isReadOnly && isSelected && <Rect {...outlineShape} />}
      <Rect
        id={'highlight_overlay_' + id}
        onClick={(e) =>
          onSelect({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y()
          })
        }
        onTap={(e) => onSelect(e)}
        ref={shapeRef}
        {...shapeProps}
        draggable={!mode && !isReadOnly}
        onDragStart={(e) => onSelect(e)}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y()
          })
        }}
        onTransformEnd={(e) => {
          const node = shapeRef.current
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          node.scaleX(1)
          node.scaleY(1)
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY)
          })
        }}
      />
      {isSelected && !isReadOnly && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </React.Fragment>
  )
}

const DrawAnnotations = (props) => {
  const {
    orgHeight,
    orgWidth,
    areas,
    pageIndex,
    setAnnotations,
    setSelectedEntry,
    selectedSubEntry,
    setSelectedSubEntry,
    mode,
    annotations,
    setMode,
    selectedEntry,
    handleMergeBBox,
    handleSelectionAction,
    isReadOnly,
    setIsReadOnly,
    isScreenShotState,
    setIsScreenShotState,
    addImage,
    addScreenShot,
    vimMode,
    setTextBBox = () => {},
    selectedTextBBox = '',
    setSelectedTextBBox = () => {},
    textBBox = [],
    resumeReprocessMode = '',
    projectReprocessMode = ''
  } = props
  const [newAnnotation, setNewAnnotation] = React.useState([])
  const [mulAnnotation, setMulAnnotation] = React.useState('')
  const [selectedMul, setSelectedMul] = React.useState('')
  const [selectedScreenShot, setSelectedScreenShot] = React.useState('')
  const pageElement = document.querySelectorAll(
    `[data-testid=core__page-layer-${pageIndex}]`
  )
  const { clientWidth = 1, clientHeight = 1 } = pageElement[0]
  let isEditable = !!(!isReadOnly && mode && selectedEntry?.id)
  if (mode === 'textextract') {
    isEditable = !isReadOnly
  }

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage()
    if (clickedOnEmpty) {
      setSelectedSubEntry(null)
      setSelectedTextBBox(null)
    }
  }

  React.useEffect(() => {
    if (mode) {
      setSelectedTextBBox(null)
      setSelectedSubEntry(null)
      setMulAnnotation('')
      setSelectedMul('')
      setSelectedScreenShot('')
    } else {
      setNewAnnotation([])
    }
  }, [mode])

  const handleMouseDown = (event) => {
    if (newAnnotation.length === 0 && selectedSubEntry === null && mode) {
      const { x, y } = event.target.getStage().getPointerPosition()
      setNewAnnotation([{ x, y, width: 0, height: 0, key: '0' }])
    } else {
      checkDeselect(event)
    }
  }

  const handleMouseUp = (event) => {
    if (newAnnotation.length === 1 && isEditable) {
      const sx = newAnnotation[0].x
      const sy = newAnnotation[0].y
      const { x, y } = event.target.getStage().getPointerPosition()
      if (x - sx && y - sy) {
        const id = uuid()
        if (mode === 'textextract') {
          const annotationToAdd = {
            x: (sx / clientWidth) * 100,
            y: (sy / clientHeight) * 100,
            width: ((x - sx) / clientWidth) * 100,
            height: ((y - sy) / clientHeight) * 100,
            id,
            fill: 'grey',
            opacity: '0.2',
            type: mode,
            pageIndex: pageIndex + 1,
            visible: true,
            text: '',
            org_width: orgWidth,
            org_height: orgHeight
          }
          const text = handleGenerateText(annotationToAdd)
          annotationToAdd.text = text
          setNewAnnotation([])
          setTextBBox([...textBBox, annotationToAdd])
          setMode('')
        } else {
          const annotationToAdd = {
            x: (sx / clientWidth) * 100,
            y: (sy / clientHeight) * 100,
            width: ((x - sx) / clientWidth) * 100,
            height: ((y - sy) / clientHeight) * 100,
            id,
            fill: selectedEntry.color ? selectedEntry.color : 'grey',
            opacity: '0.2',
            type: mode,
            pageIndex: pageIndex + 1,
            visible: true,
            parentId: selectedEntry.id,
            parentIndex: selectedEntry.index,
            parentType: selectedEntry.type,
            index: selectedEntry?.[mode]?.length
              ? selectedEntry[mode].length
              : 0,
            text: '',
            org_width: orgWidth,
            org_height: orgHeight
          }
          if (['title', 'section'].includes(mode)) {
            const index = _.findIndex(annotations, { id: selectedEntry.id })
            const newAnn = _.cloneDeep(annotations)
            if (index === -1) {
              const temp = {
                ...selectedEntry,
                [mode]: [annotationToAdd],
                updateStatus: 'edit'
              }
              newAnn.push(temp)
            } else {
              if (!_.isEmpty(newAnn[index][mode])) {
                newAnn[index][mode] = [...newAnn[index][mode], annotationToAdd]
              } else {
                newAnn[index][mode] = [annotationToAdd]
                newAnn[index].pageIndex = pageIndex + 1
              }
              newAnn[index].updateStatus = 'edit'
            }
            setNewAnnotation([])
            setAnnotations(newAnn)
            setSelectedEntry(newAnn[index])
            if (!vimMode) {
              setMode('')
              setSelectedSubEntry(annotationToAdd)
            }
          } else if (
            [
              'multiselect',
              'screenshot',
              'screenshot_image',
              'screenshot_profile'
            ].includes(mode)
          ) {
            newAnnotation.id = uuid()
            newAnnotation.type = mode
            setMulAnnotation(annotationToAdd)
            setMode('')
            setNewAnnotation([])
          } else {
            setNewAnnotation([])
          }
        }
      }
    }
  }

  const orderElements = (htmlString) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const elements = Array.from(
      doc.querySelectorAll('.rpv-core__text-layer-text')
    )
    elements.sort((a, b) => {
      const aLeft = parseInt(a.style.left, 10)
      const bLeft = parseInt(b.style.left, 10)
      const aTop = parseInt(a.style.top, 10)
      const bTop = parseInt(b.style.top, 10)

      if (aTop === bTop || Math.abs(aTop - bTop < 15)) {
        return aLeft - bLeft
      }

      return aTop - bTop
    })
    return elements
  }

  const handleGenerateText = (attrs) => {
    const pageElement = document.querySelectorAll(
      `[data-testid=core__page-layer-${parseInt(attrs.pageIndex) - 1}]`
    )
    const { clientWidth = 1, clientHeight = 1 } = pageElement[0]
    const textElement = pageElement[0]?.querySelectorAll(
      '.rpv-core__text-layer-text'
    )
    const te = []

    const reCalculatedValue = {
      ...attrs,
      x: (attrs.x * clientWidth) / 100,
      y: (attrs.y * clientHeight) / 100,
      width: (attrs.width * clientWidth) / 100,
      height: (attrs.height * clientHeight) / 100
    }

    const rectSelection = {
      top: reCalculatedValue.y,
      left: reCalculatedValue.x,
      bottom: reCalculatedValue.height + reCalculatedValue.y,
      right: reCalculatedValue.width + reCalculatedValue.x,
      width: reCalculatedValue.height,
      height: reCalculatedValue.width
    }
    const parentPos = pageElement[0].getBoundingClientRect()

    textElement.forEach((el) => {
      const childPos = el.getBoundingClientRect()
      const relativePos = {}
      relativePos.top = Math.abs(childPos.top - parentPos.top)
      relativePos.left = Math.abs(childPos.left - parentPos.left)
      relativePos.right = relativePos.left + childPos.width
      relativePos.bottom = relativePos.top + childPos.height
      if (
        relativePos.bottom > rectSelection.top &&
        relativePos.right > rectSelection.left &&
        relativePos.top < rectSelection.bottom &&
        relativePos.left < rectSelection.right
      ) {
        te.push(el?.outerHTML)
      }
    })
    let htmlString = te.join('')
    const htmlArray = orderElements(htmlString)

    let prevHeight = ''
    let lineString = '<span>'
    let escapeSpan = 0
    htmlArray.forEach((el, ind) => {
      if ([1, 2, 3, 4].includes(escapeSpan)) {
        escapeSpan = escapeSpan + 1
      } else {
        escapeSpan = 0
      }
      if (el) {
        const currHeight = el?.style?.top
        const currTextContent = el?.textContent
        const nextHeight = htmlArray[ind + 1]?.style?.top
        const nextAfterTextContent = htmlArray[ind + 2]?.textContent
        const nextAfterHeight = htmlArray[ind + 2]?.style?.top
        if (
          parseInt(currHeight) !== parseInt(nextHeight) &&
          currTextContent.endsWith('-')
        ) {
          el.textContent = el.textContent.slice(0, -1)
          escapeSpan = 2
        }
        if (
          parseInt(currHeight) !== parseInt(nextAfterHeight) &&
          nextAfterTextContent === '-'
        ) {
          htmlArray[ind + 2] = ''
          escapeSpan = 1
        }
        if (prevHeight) {
          const diffHeight = Math.abs(
            parseInt(currHeight) - parseInt(prevHeight)
          )
          if (diffHeight > 20) {
            lineString = lineString + '<p></p>'
          } else if (diffHeight !== 0 && ![3, 4].includes(escapeSpan)) {
            lineString = lineString + '<span> </span>'
          }
        }
        lineString = lineString + el.outerHTML
        prevHeight = currHeight
      }
    })
    lineString = lineString + '</span>'
    htmlString = lineString
    const styleStripped = removeStyleAttribute(htmlString)
    return styleStripped
  }

  const removeStyleAttribute = (htmlString) => {
    htmlString = removeClassNamesFromHTML(htmlString)
    const div = document.createElement('div')
    div.innerHTML = htmlString
    const elementsWithStyle = div.querySelectorAll('[style]')
    elementsWithStyle.forEach((element) => {
      element.removeAttribute('style')
    })
    return div.innerHTML
  }

  const handleMouseMove = (event) => {
    if (newAnnotation.length === 1 && isEditable) {
      const sx = newAnnotation[0].x
      const sy = newAnnotation[0].y
      const { x, y } = event.target.getStage().getPointerPosition()
      if (x - sx && y - sy) {
        const newAnn = {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          id: '0',
          fill: selectedEntry.color ? selectedEntry.color : 'grey',
          opacity: '0.2',
          type: mode,
          pageIndex: pageIndex + 1,
          visible: true
        }
        setNewAnnotation([newAnn])
      }
    }
  }

  const handleDeleteMul = () => {
    setMulAnnotation('')
    setSelectedMul('')
  }

  React.useEffect(() => {
    if (selectedScreenShot) {
      setIsReadOnly(true)
      setIsScreenShotState(true)
      captureScreenshot()
    }
  }, [selectedScreenShot])

  const reCalculatedValueChild = (child) => {
    return {
      ...child,
      x: (child.x * clientWidth) / 100,
      y: (child.y * clientHeight) / 100,
      width: (child.width * clientWidth) / 100,
      height: (child.height * clientHeight) / 100
    }
  }

  const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Bytes'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
  }

  const captureScreenshot = async () => {
    const child = reCalculatedValueChild(selectedScreenShot)
    const corepage = document.querySelector(
      `[data-testid="core__page-layer-${pageIndex}"]`
    )
    const canvasLayer = corepage.querySelector('.rpv-core__canvas-layer')
    const { x, y, width, height } = child
    const screenshotCanvas = await html2canvas(canvasLayer, {
      x,
      y,
      width,
      height,
      logging: false
    })
    const screenshotImage = screenshotCanvas.toDataURL('image/png')
    const bbox = {
      x: selectedScreenShot.x,
      y: selectedScreenShot.y,
      width,
      height,
      pageIndex: selectedScreenShot.pageIndex,
      parentId: selectedScreenShot.parentId,
      org_width: selectedScreenShot.org_width,
      org_height: selectedScreenShot.org_height
    }
    const base64Data = screenshotImage.split(',')[1]
    const binaryString = atob(base64Data)
    const imageLength = binaryString.length
    const metadata = {
      format: 'png',
      size: bytesToSize(imageLength),
      dimensions: `${width}x${height}`
    }
    if (selectedScreenShot?.type === 'screenshot_image') {
      addImage(
        {
          src: screenshotImage,
          name: selectedScreenShot.id + '.png',
          metadata
        },
        selectedScreenShot
      )
    } else {
      addScreenShot(
        {
          src: screenshotImage,
          bbox,
          width,
          height,
          name: selectedScreenShot.id + '.png',
          metadata
        },
        selectedScreenShot.type
      )
    }
    setIsScreenShotState(false)
    setSelectedScreenShot('')
    setIsReadOnly(false)
  }

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete') {
        if (selectedMul) {
          handleDeleteMul()
        } else if (selectedScreenShot) {
          setSelectedScreenShot('')
        } else if (selectedTextBBox) {
          handleSelectionAction('deleteTextBox', selectedTextBBox)
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedMul, selectedScreenShot])

  const renderBoundingElement = (value, i, flag, org) => {
    const { type = '' } = value
    let reCalculatedValue = { ...value }
    const textPrefix =
      type === 'screenshot_image'
        ? _.capitalize(type)
        : value?.parentType === 'image'
        ? 'Image'
        : value?.parentType === 'section-temp'
        ? _.capitalize(type) + ' Temp'
        : _.capitalize(type)
    let textContent = textPrefix
    if (flag) {
      reCalculatedValue = {
        ...reCalculatedValue,
        x: (value.x * clientWidth) / 100,
        y: (value.y * clientHeight) / 100,
        width: (value.width * clientWidth) / 100,
        height: (value.height * clientHeight) / 100
      }
      textContent = `${textPrefix}.${value?.parentIndex + 1}.${
        value?.index + 1
      }`
      if (value?.type === 'textextract') {
        textContent = `${textPrefix}`
      }
    }

    let toolsOffsetX =
      reCalculatedValue.width > 215
        ? reCalculatedValue.x + reCalculatedValue.width - 140
        : reCalculatedValue.x
    const toolsOffsetY =
      reCalculatedValue.width > 215
        ? reCalculatedValue.y - 23
        : reCalculatedValue.y - 44
    if (selectedEntry?.type === 'section-temp') {
      toolsOffsetX = reCalculatedValue.x + reCalculatedValue.width - 30
    }
    if (isReadOnly) {
      toolsOffsetX = reCalculatedValue.x + reCalculatedValue.width - 21
    }
    let isSelected =
      value.id === selectedSubEntry?.id || selectedMul.id === value.id
    if (value.type === 'textextract') {
      isSelected = value.id === selectedTextBBox?.id
    }

    return (
      value.pageIndex - 1 === pageIndex &&
      value?.visible && (
        <>
          <Label x={reCalculatedValue.x} y={reCalculatedValue.y - 22}>
            <Tag
              fill="white"
              lineJoin="round"
              shadowColor="black"
              stroke={value.fill}
            />
            <Text
              text={textContent}
              fontSize={14}
              fill={value.fill}
              padding={3}
            />
          </Label>
          {['textextract'].includes(value?.type) ? (
            isSelected && (
              <Label x={toolsOffsetX + 110} y={toolsOffsetY}>
                <Html>
                  <div
                    style={{
                      border: '2px solid',
                      borderColor: '#6BBDFA',
                      background: 'white',
                      fontSize: '15px',
                      display: 'flex',
                      color: '#6BBDFA'
                    }}
                  >
                    <Tooltip title={'Delete'}>
                      <DeleteIcon
                        sx={{ fontSize: '18px', margin: '0px 5px' }}
                        onClick={() =>
                          handleSelectionAction('deleteTextBox', value)
                        }
                      />
                    </Tooltip>
                  </div>
                </Html>
              </Label>
            )
          ) : ['title', 'section'].includes(type) ? (
            isSelected && selectedEntry?.type !== 'image' ? (
              <>
                {isReadOnly ? (
                  <Label x={toolsOffsetX} y={toolsOffsetY}>
                    <Html>
                      <div
                        style={{
                          border: '2px solid',
                          borderColor: '#6BBDFA',
                          background: 'white',
                          fontSize: '15px',
                          display: 'flex',
                          color: '#6BBDFA'
                        }}
                      >
                        <Tooltip title={'Toggle Visibility'}>
                          {value.visible ? (
                            <VisibleIcon
                              sx={{ fontSize: '18px', margin: '0x 5px' }}
                              onClick={() =>
                                handleSelectionAction('visble', value, false)
                              }
                            />
                          ) : (
                            <VisibleOffIcon
                              sx={{ fontSize: '18px', margin: '0x 5px' }}
                              onClick={() =>
                                handleSelectionAction('visble', value, false)
                              }
                            />
                          )}
                        </Tooltip>
                      </div>
                    </Html>
                  </Label>
                ) : (
                  <Label x={toolsOffsetX} y={toolsOffsetY}>
                    <Html>
                      {selectedEntry?.type === 'section-temp' ? (
                        <div
                          style={{
                            border: '2px solid',
                            borderColor: '#6BBDFA',
                            background: 'white',
                            fontSize: '15px',
                            display: 'flex',
                            color: '#6BBDFA'
                          }}
                        >
                          <Tooltip title={'Convert to Annotated Entry'}>
                            <TransformIcon
                              sx={{ fontSize: '18px', margin: '0px 5px' }}
                              onClick={() =>
                                handleSelectionAction('convertTemp', value)
                              }
                            />
                          </Tooltip>
                        </div>
                      ) : (
                        <div
                          style={{
                            border: '2px solid',
                            borderColor: '#6BBDFA',
                            background: 'white',
                            fontSize: '15px',
                            display: 'flex',
                            color: '#6BBDFA'
                          }}
                        >
                          <Tooltip title={'Copy Text'}>
                            <FormatShapesIcon
                              sx={{ fontSize: '18px', margin: '0px 5px' }}
                              onClick={() =>
                                handleSelectionAction('generate', value)
                              }
                            />
                          </Tooltip>
                          <Tooltip title={'Copy Text as Bullet List'}>
                            <FormatListBulletedIcon
                              sx={{ fontSize: '18px', margin: '0px 5px' }}
                              onClick={() =>
                                handleSelectionAction(
                                  'generateBulletList',
                                  value
                                )
                              }
                            />
                          </Tooltip>
                          <Tooltip title={'Copy Text as Number List'}>
                            <FormatListNumberedIcon
                              sx={{ fontSize: '18px', margin: '0px 5px' }}
                              onClick={() =>
                                handleSelectionAction(
                                  'generateListNumber',
                                  value
                                )
                              }
                            />
                          </Tooltip>
                          <Tooltip title={'Toggle Visibility'}>
                            {value.visible ? (
                              <VisibleIcon
                                sx={{ fontSize: '18px', margin: '0px 5px' }}
                                onClick={() =>
                                  handleSelectionAction('visble', value, false)
                                }
                              />
                            ) : (
                              <VisibleOffIcon
                                sx={{ fontSize: '18px', margin: '0px 5px' }}
                                onClick={() =>
                                  handleSelectionAction('visble', value, false)
                                }
                              />
                            )}
                          </Tooltip>
                          <Tooltip title={`Delete ${type}`}>
                            <DeleteIcon
                              sx={{ fontSize: '18px', margin: '0px 5px' }}
                              onClick={() =>
                                handleSelectionAction('delete', value)
                              }
                            />
                          </Tooltip>
                        </div>
                      )}
                    </Html>
                  </Label>
                )}
              </>
            ) : (
              isSelected && (
                <>
                  <Label x={toolsOffsetX + 110} y={toolsOffsetY}>
                    <Html>
                      <div
                        style={{
                          border: '2px solid',
                          borderColor: '#6BBDFA',
                          background: 'white',
                          fontSize: '15px',
                          display: 'flex',
                          color: '#6BBDFA'
                        }}
                      >
                        <Tooltip title={'Toggle Visibility'}>
                          {value.visible ? (
                            <VisibleIcon
                              sx={{ fontSize: '18px', margin: '0px 5px' }}
                              onClick={() =>
                                handleSelectionAction('visble', value, false)
                              }
                            />
                          ) : (
                            <VisibleOffIcon
                              sx={{ fontSize: '18px', margin: '0px 5px' }}
                              onClick={() =>
                                handleSelectionAction('visble', value, false)
                              }
                            />
                          )}
                        </Tooltip>
                      </div>
                    </Html>
                  </Label>
                </>
              )
            )
          ) : (
            <>
              {type === 'multiselect' && (
                <Label x={toolsOffsetX + 85} y={toolsOffsetY}>
                  <Html>
                    <div
                      style={{
                        border: '2px solid',
                        borderColor: '#6BBDFA',
                        background: 'white',
                        fontSize: '15px',
                        display: 'flex',
                        color: '#6BBDFA'
                      }}
                    >
                      <Tooltip title={'Merge w/o bbox to sections'}>
                        <HighlightAltIcon
                          sx={{ fontSize: '18px', margin: '0x 5px' }}
                          onClick={() => {
                            handleMergeBBox(value, 'handleBBoxWithoutMerging')
                            handleDeleteMul()
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={'Merge with bbox to sections'}>
                        <SelectAllIcon
                          sx={{ fontSize: '18px', margin: '0x 5px' }}
                          onClick={() => {
                            handleMergeBBox(value, 'handleBBoxWithMerging')
                            handleDeleteMul()
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={'Delete Merge Layer'}>
                        <DeleteIcon
                          sx={{ fontSize: '18px', margin: '0x 5px' }}
                          onClick={() => handleDeleteMul()}
                        />
                      </Tooltip>
                    </div>
                  </Html>
                </Label>
              )}
              {[
                'screenshot',
                'screenshot_image',
                'screenshot_profile'
              ].includes(type) && (
                <Label x={toolsOffsetX + 120} y={toolsOffsetY}>
                  <Html>
                    <div
                      style={{
                        border: '2px solid',
                        borderColor: '#6BBDFA',
                        background: 'white',
                        fontSize: '15px',
                        display: 'flex',
                        color: '#6BBDFA'
                      }}
                    >
                      <ScreenshotMonitorIcon
                        sx={{ fontSize: '18px', margin: '0x 5px' }}
                        onClick={() => {
                          setSelectedScreenShot(value)
                          handleDeleteMul()
                        }}
                      />
                    </div>
                  </Html>
                </Label>
              )}
            </>
          )}
          <Rectangle
            key={i}
            id={value.id}
            mode={mode}
            shapeProps={reCalculatedValue}
            isReadOnly={isReadOnly}
            isSelected={isSelected}
            onSelect={(attrs) => {
              if (!mode) {
                if (['section', 'title'].includes(type)) {
                  setSelectedSubEntry(value)
                  if (!_.isEmpty(org)) {
                    setSelectedEntry(org)
                  }
                  setSelectedMul('')
                } else if (['textextract'].includes(value?.type)) {
                  setSelectedTextBBox(value)
                  setSelectedMul('')
                } else {
                  setSelectedSubEntry(null)
                  setSelectedMul(value)
                }
              }
            }}
            onChange={(newAttrs) => {
              if (!mode) {
                const annotationToAdd = {
                  ...newAttrs,
                  x: (newAttrs.x / clientWidth) * 100,
                  y: (newAttrs.y / clientHeight) * 100,
                  width: (newAttrs.width / clientWidth) * 100,
                  height: (newAttrs.height / clientHeight) * 100
                }
                if (['section', 'title'].includes(type)) {
                  const index = _.findIndex(annotations, {
                    id: selectedEntry.id
                  })
                  const newAnn = _.cloneDeep(annotations)
                  newAnn[index].updateStatus = 'edit'
                  newAnn[index][newAttrs.type][i] = annotationToAdd
                  setAnnotations(newAnn)
                } else if (['textextract'].includes(value?.type)) {
                  const newAnn = _.cloneDeep(textBBox)
                  const text = handleGenerateText(annotationToAdd)
                  annotationToAdd.text = text
                  newAnn[i] = annotationToAdd
                  setTextBBox(newAnn)
                } else {
                  setMulAnnotation(annotationToAdd)
                }
              }
            }}
          />
        </>
      )
    )
  }

  return (
    <div
      className="rpv-canvas_"
      id={'rpv-canvas_' + pageIndex}
      style={{
        height: '100%',
        width: '100%',
        cursor: mode ? 'crosshair' : '',
        background: mode ? '#18161626' : 'transparent'
      }}
    >
      <Stage
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={checkDeselect}
        width={clientWidth}
        height={clientHeight}
      >
        {!selectedScreenShot && (
          <Layer>
            {resumeReprocessMode === 'textextract' ||
            projectReprocessMode === 'textextract' ? (
              <>
                {textBBox?.map((ann, i) => {
                  return renderBoundingElement(ann, i, true)
                })}
              </>
            ) : (
              <>
                {annotations?.map((ann, i) => {
                  const {
                    section = [],
                    title = [],
                    visible,
                    updateStatus
                  } = ann
                  if (visible && updateStatus !== 'delete') {
                    return (
                      <>
                        {section.map((value, i) =>
                          renderBoundingElement(value, i, true, ann)
                        )}
                        {title.map((value, i) =>
                          renderBoundingElement(value, i, true, ann)
                        )}
                      </>
                    )
                  }
                  return <></>
                })}
                {renderBoundingElement(mulAnnotation, 0, true)}
              </>
            )}
            {newAnnotation.map((value, i) => renderBoundingElement(value, i))}
          </Layer>
        )}
      </Stage>
    </div>
  )
}

export const Highlights = (props) => {
  const { pageIndex, canvasState } = props
  return (
    <div
      className="rpv-search__highlights"
      data-testid={`search__highlights-${pageIndex}`}
      style={{ zIndex: canvasState ? 1 : 0 }}
    >
      <div style={{ height: '100%', width: '100%' }}>
        <DrawAnnotations {...props} />
      </div>
    </div>
  )
}
