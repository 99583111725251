import { USER_CONFIG_UPDATE } from '../../../actionTypes'
import { postUserProfile } from '../../../api'
import { toast } from 'react-toastify'

const updateConfig =
  (data, callback = () => {}) =>
  async (dispatch) => {
    const response = await postUserProfile(data)
    if (response.status === 200) {
      dispatch({
        type: USER_CONFIG_UPDATE,
        payload: data,
        callback
      })
    } else {
      toast.error('Something went wrong while saving the view')
    }
  }

export default updateConfig
