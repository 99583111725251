import { useIsomorphicLayoutEffect } from '@react-pdf-viewer/core'
import * as React from 'react'
import { getCssProperties } from './getCssProperties'
import Popover from '@mui/material/Popover'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import Button from '../../../components/Button'
import { capitalize, startCase } from 'lodash'
import { getbg } from '../errorCode'

export const HightlightItem = ({
  index,
  area,
  onHighlightKeyword,
  handleDataChange
}) => {
  const containerRef = React.useRef(null)

  useIsomorphicLayoutEffect(() => {
    const highlightEle = containerRef.current
    if (onHighlightKeyword && highlightEle) {
      onHighlightKeyword({
        highlightEle,
        keyword: area.keyword
      })
    }
  }, [])

  const { errorType, description, keywordStr, id, status, keywordCorrection } =
    area
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const popoverId = open ? 'simple-popover-' + index : undefined

  return (
    <div>
      <div
        className="rpv-search__highlight"
        data-index={index}
        ref={containerRef}
        style={getCssProperties(area)}
        title={area.keywordStr.trim()}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card sx={{ width: '300px' }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color={getbg(errorType, status)}
              gutterBottom
            >
              <div>{capitalize(startCase(errorType))}</div>
              <div>{status && `Marked as ${capitalize(status)}`}</div>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              sx={{ fontWeight: 'bold' }}
            >
              {keywordStr}
            </Typography>
            <Typography variant="body2">{capitalize(description)}</Typography>
            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
              Suggested Replacement: {capitalize(keywordCorrection)}
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            {' '}
            {!status ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() => handleDataChange(area, 'ignore')}
                >
                  Ignore
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleDataChange(area, 'complete')}
                >
                  Mark as Complete
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                onClick={() => handleDataChange(area, '')}
              >
                Revert {capitalize(status)}
              </Button>
            )}
          </CardActions>
        </Card>
      </Popover>
    </div>
  )
}
