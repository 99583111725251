import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../components/Button'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { setToasterAlert } from '../../store/Common/Actions'
import { addIdp, fetchIdp, delIdp } from '../../store/api'
import Loader from '../../components/Loader'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useStyles } from './styles'
import InputLabel from '@mui/material/InputLabel'
import { FormControl } from '@mui/base'

const CognitoIDP = ({ isFocused = false }) => {
  const [loading, setLoading] = useState(true)
  const [btnloading, setBtnloading] = useState(false)
  const [idps, setIdps] = useState([])
  const [name, setName] = useState('')
  const [cmpy, setCmpy] = useState('')
  const [sub, setSub] = useState('')
  const [url, seturl] = useState('')
  const [fileContents, setFileContents] = useState('')
  const fileInputRef = useRef(null)
  const classes = useStyles()
  const {
    listClass,
    listitem,
    formWrapper,
    inputWrapper,
    buttonWrapper,
    container,
    inputlabel
  } = classes
  const dispatch = useDispatch()
  useEffect(() => {
    if (isFocused) {
      initalize()
    }
  }, [isFocused])

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (event) => {
      setFileContents(event.target.result)
    }
    reader?.readAsText(file)
  }

  const handleCancel = () => {
    setFileContents('')
    fileInputRef.current.value = null
  }

  async function initalize() {
    console.log('fetching idp settings')
    setLoading(true)
    try {
      const res = await fetchIdp()
      if (res.status === 200) {
        setIdps(res.data)
      }
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  const deleteIdp = async (idp_name) => {
    setBtnloading(true)
    try {
      const res = await delIdp(idp_name)
      if (res.status === 200) {
        initalize()
        dispatch(
          setToasterAlert({
            message: 'Delete Successful',
            show: true,
            type: 'success'
          })
        )
      } else {
        dispatch(
          setToasterAlert({
            message: 'Delete Failed',
            show: true,
            type: 'error'
          })
        )
      }
    } catch (e) {
      dispatch(
        setToasterAlert({
          message: e.toString(),
          show: true,
          type: 'error'
        })
      )
    }
    setBtnloading(false)
  }

  const createNewProvider = async () => {
    setBtnloading(true)
    try {
      const regex = /^[a-z-]+$/
      const isLowerCaseWithDash = regex.test(name)
      if (isLowerCaseWithDash && (url || fileContents) && cmpy && sub) {
        const data = {
          subdomain: sub,
          idp_name: name,
          company: cmpy
        }
        if (fileContents) {
          data.appmetafile = fileContents
        } else if (url) {
          data.appmetaurl = url
        }
        const res = await addIdp(data)
        if (res.status === 200) {
          initalize()
          dispatch(
            setToasterAlert({
              message: 'Creation Successful',
              show: true,
              type: 'success'
            })
          )
          setName('')
          setCmpy('')
          seturl('')
          setSub('')
          setFileContents('')
        } else {
          console.log(res)
          dispatch(
            setToasterAlert({
              message: res?.data?.message || 'Creation Failed',
              show: true,
              type: 'error'
            })
          )
        }
      } else {
        dispatch(
          setToasterAlert({
            message:
              'All fields are required. The IDP Name should only contain lowercase letters and hyphens (-)',
            show: true,
            type: 'error'
          })
        )
      }
    } catch (e) {
      dispatch(
        setToasterAlert({
          message: e.toString(),
          show: true,
          type: 'error'
        })
      )
    }
    setBtnloading(false)
  }

  if (loading) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          marginTop: '40px'
        }}
      >
        <Loader loading={loading} caption={''} />
      </Box>
    )
  }
  return (
    <Box className={container}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        className={formWrapper}
      >
        <FormControl>
          <InputLabel className={inputlabel}>Identity Provider Name</InputLabel>
          <TextField
            placeholder="Identity Provider Name"
            value={name}
            className={inputWrapper}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel className={inputlabel}>Company Name</InputLabel>
          <TextField
            placeholder="Company Name"
            value={cmpy}
            className={inputWrapper}
            onChange={(e) => setCmpy(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel className={inputlabel}>Sub Domain</InputLabel>
          <TextField
            placeholder="Sub Domain"
            value={sub}
            className={inputWrapper}
            onChange={(e) => setSub(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel className={inputlabel}>App MetaData</InputLabel>
          <TextField
            placeholder="App Metadata URL"
            value={url}
            className={inputWrapper}
            onChange={(e) => seturl(e.target.value)}
          />
          <input
            type="file"
            onChange={handleFileChange}
            accept=".xml"
            ref={fileInputRef}
          />
          {fileContents && <button onClick={handleCancel}>Cancel</button>}
        </FormControl>
      </Box>
      <Box className={buttonWrapper}>
        <Button disabled={btnloading} onClick={() => createNewProvider()}>
          Create New Provider
        </Button>
      </Box>
      <List className={listClass}>
        {idps.map((dom, idx) => {
          const { company, idp_name, subdomain } = dom
          return (
            <ListItem
              key={idx}
              className={listitem}
              secondaryAction={
                <Button
                  disabled={btnloading}
                  onClick={() => deleteIdp(idp_name)}
                  variant="text"
                >
                  Remove
                </Button>
              }
            >
              <ListItemText primary={company} secondary={idp_name} />
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

export default CognitoIDP
