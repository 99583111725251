import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  workspaceHeader: {
    padding: '20px'
  },
  workspaceName: {
    fontSize: '24px',
    fontWeight: 'bold',
    maxWidth: '800px',
    maxHeight: '50px',
    overflow: 'auto'
  },
  workspaceDescription: {
    fontSize: '16px',
    color: '#797979',
    maxWidth: '800px',
    marginTop: '10px',
    maxHeight: '100px',
    overflow: 'auto'
  },
  newButton: {
    padding: '0px 15px'
  },
  fileViwerWrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    overflow: 'auto'
  },
  dailogContentTitle: {
    fontSize: '16px',
    fontWeight: '400',
    margin: '10px 0px',
    color: '#000'
  },
  splashTextStyle: {
    background: 'linear-gradient(100deg, #471CA8, #FF3465)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent !important',
    display: 'inline-block',
    '&:before': {
      content: 'attr(data-text)',
      position: 'absolute',
      left: '0',
      top: '0',
      zIndex: '-1'
    }
  },
  splashBtn: {
    color: '#797979',
    borderRadius: '5px',
    cursor: 'pointer',
    background: 'white',
    display: 'flex',
    position: 'relative',
    gap: '5px',
    '&:hover': {
      backgroundColor: '#f0f0f0 !important'
    },
    '& .MuiCircularProgress-root': {
      color: '#471CA8'
    },
    '& .MuiSvgIcon-root': {
      color: '#471CA8',
      fontSize: '14px'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      inset: '0',
      borderRadius: '4px',
      padding: '1px',
      background: 'linear-gradient(100deg, #471CA8, #FF3465)',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude'
    }
  }
})

export { useStyles }
