import { useEffect, useState } from 'react'
import PDFViewer from './PDFViewer'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Section } from '../../components/Container'
import { isObject } from 'lodash'
import { fetchPrompts } from '../../store/Common/Actions'

const RfxPDFView = (props) => {
  const {
    setShowPDF,
    PDFData,
    PDFUrl = '',
    isAnalytics = false,
    pageNo = 1
  } = props
  const common = useSelector((userState) => userState?.common)
  const { prompts = [] } = common
  const auth = useSelector((userState) => userState?.authenticate)
  const domain = auth?.user?.domain
  const { domain_id } = domain || {}

  const dispatch = useDispatch()
  useEffect(() => {
    if (!prompts) {
      dispatch(fetchPrompts())
    }
  }, [prompts])

  return (
    <Container>
      <Section overFlow={true}>
        <PDFViewer
          setShowPDF={setShowPDF}
          domain_id={domain_id}
          url={PDFUrl}
          PDFData={PDFData}
          isAnalytics={isAnalytics}
          pageNo={pageNo}
        />
      </Section>
    </Container>
  )
}

export default RfxPDFView
