import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  webviewer: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    // width: "90vh",
    overflow: 'auto'
  },
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
    border: '1px white solid'
  },
  swatch: {
    padding: '5px',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer'
  },
  popover: {
    position: 'absolute',
    zIndex: '2'
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  },
  text: {
    color: '#424242',
    fontSize: '15px',
    fontWeight: 800
  }
})

export { useStyles }
