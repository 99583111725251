import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  tableHeader: {
    fontFamily: 'PoppinsRegular',
    fontWeight: 700,
    fontSize: 15,
    padding: '11px 11px 0'
  },
  cellPointer: {
    fontFamily: 'PoppinsRegular',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
    '& .tableCell': {
      whiteSpace: 'initial',
      textOverflow: 'ellipsis',
      fontFamily: 'PoppinsRegular',
      fontWeight: 500,
      fontSize: 14,
      cursor: 'pointer'
    }
  },
  cellText: {
    fontFamily: 'PoppinsRegular',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'default',
    '& .tableCell': {
      whiteSpace: 'initial',
      textOverflow: 'ellipsis',
      fontFamily: 'PoppinsRegular',
      fontWeight: 500,
      fontSize: 14,
      cursor: 'default'
    }
  },
  tableStyle: {
    height: '90%',
    position: 'relative',
    tableLayout: 'auto'
  },
  tablesortlabel: {
    '& .MuiTableSortLabel-icon': {
      color: '#DEE0E8',
      opacity: 1
    },
    '&. Mui-active .MuiTableSortLabel-icon ': {
      color: '#000000',
      opacity: 1
    }
  },
  tablebody: {
    display: 'contents'
  },
  tablerow: {
    height: '15px',
    padding: '0 20px'
  },
  tablecell: {
    textAlign: 'center'
  },
  sortLabel: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '20px',
    width: '10px'
  },
  tableRowOption: {
    padding: '10px'
  },
  hideSortIcon: {
    visibility: 'hidden'
  },
  sortIconActive: {
    color: '#000000',
    width: '8px',
    height: '8px'
  },
  sortIcon: {
    color: '#9494946e',
    width: '8px',
    height: '8px'
  },
  sortDownArrow: {
    paddingTop: '2px'
  },
  multiSelectWrapper: {
    paddingLeft: '4px !important'
  },
  optionDisable: {
    pointerEvents: 'none',
    '& >div >div >button': {
      color: '#b3b2b2'
    }
  },
  selectDropdown: {
    width: '150px',
    '& >div': {
      paddingTop: '7px',
      paddingBottom: '7px'
    }
  },
  tooltip: {
    backgroundColor: 'black',
    border: '1px solid black',
    color: 'white',
    fontSize: '13px'
  },
  tablerowActiveRow: {
    backgroundColor: '#0000000a'
  },
  tablerowDisabled: {
    opacity: 0.5
  }
})

export { useStyles }
