import React from 'react'
import { Grid, Tooltip } from '@mui/material'
import Image from '../ImageElement'
import { useStyles } from './styles'
const ToolBar = (props) => {
  const { optionList = [], handleOptions = () => {} } = props
  const classes = useStyles()
  const { image, wrapper, activeWrapper, container } = classes
  return (
    <Grid container direction={'column'} className={container}>
      {optionList.map((option, key) => {
        const { id = '', src = '', title = '', active, enable = true } = option
        return (
          <>
            {enable ? (
              <Tooltip title={title} placement={'left'}>
                <Grid
                  id={`toolbar-option-${id}`}
                  key={key}
                  item
                  className={active ? activeWrapper : wrapper}
                  onClick={() => handleOptions(key)}
                >
                  {src && (
                    <Image id={`${id}-image`} src={src} className={image} />
                  )}
                </Grid>
              </Tooltip>
            ) : null}
          </>
        )
      })}
    </Grid>
  )
}

export default ToolBar
