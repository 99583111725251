import React, { useEffect, useState } from 'react'
import { Container, Section } from '../../components/Container'
import Logo from '../../assets/images/error.svg'
import Box from '@material-ui/core/Box'
import Button from '../Button'
import { useStyles } from './styles'

const Error = (props) => {
  const {
    errorMessage = 'Oops! Page not found',
    refresh = true,
    errorLogo = true,
    errorLogoSrc = Logo
  } = props
  const classes = useStyles()
  const { outerContainer, sectionWrapper, errorContainer, topMargin } = classes
  const refreshPage = () => {
    window.location.reload(false)
  }

  return (
    <Box className={outerContainer}>
      <Container>
        <Section className={sectionWrapper}>
          <Box className={errorContainer}>
            {errorLogo && errorLogoSrc && (
              <img src={errorLogoSrc} alt="applogo" />
            )}
            <Box variant="span" className={topMargin}>
              {errorMessage}
            </Box>
            {refresh && (
              <Box className={topMargin}>
                <Button id="refresh-button" onClick={() => refreshPage()}>
                  Refresh
                </Button>
              </Box>
            )}
          </Box>
        </Section>
      </Container>
    </Box>
  )
}

export default Error
