import React, { useEffect, useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import { Container, Section, SectionFixed } from '../../components/Container'
import _, { isEmpty, set } from 'lodash'
import ReactResizeDetector from 'react-resize-detector'
import IconButton from '@mui/material/IconButton'
import { Grid } from '@material-ui/core'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { resumeStyle } from '../ResumeResult/resumeStyle'
import { checkArrOfObjHasAtleastOneValue } from '../../utils/String'
import RemoveIcon from '@mui/icons-material/Remove'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import PreviewIcon from '@mui/icons-material/Preview'
import SyntaxHighlighter from 'react-syntax-highlighter'
import CodeIcon from '@mui/icons-material/Code'
import { CopyButton, ContentEditable } from '../../components'
import { getSignedUrl } from '../../utils/AWS'
import ProfilePicturePicker from './ProfilePicker'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { Auth } from 'aws-amplify'
import LockIcon from '@mui/icons-material/Lock'
import ContactPageIcon from '@mui/icons-material/ContactPage'
import LoopIcon from '@mui/icons-material/Loop'
import Button from '../../components/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useColor } from '../../ThemeContext'

const ResumeEditor = (props) => {
  const {
    annotations: resumes,
    setAnnotations: setResumes,
    selectedEntry: selectedResume,
    setSelectedEntry: setSelectedResume,
    isSaving: saving,
    s3Obj,
    faces,
    setFaces,
    currentPage,
    edit,
    setEdit,
    reprocessMode,
    setTextBBox,
    textBBox,
    handleSelectionAction,
    setIsSaving,
    handleResumeVerify,
    signerObj,
    setSelectedImage,
    handleMode,
    addprofilePicture
  } = props

  const selectedResumeIndex = resumes.findIndex(
    (item) => item.id === selectedResume.id
  )
  const [dragActiveTextBox, setDragActiveTextBox] = useState(false)
  const [insideView, setInsideView] = useState('edit')

  useEffect(() => {
    setInsideView('edit')
  }, [selectedResume, edit])

  const addNewToResume = (key, children, isMaster) => {
    const newObj = {}
    Object.keys(children).forEach((childKey) => {
      newObj[childKey] = ''
    })
    if (isMaster) {
      const { master = {} } = selectedResume || {}
      let { resume_data } = master
      resume_data = {
        ...resume_data,
        [key]: [...resume_data[key], newObj]
      }
      const editedResumeData = {
        ...selectedResume,
        master: {
          ...master,
          resume_data,
          updateStatus: 'edit'
        },
        updateStatus: 'edit'
      }
      setSelectedResume(editedResumeData)
      resumes[selectedResumeIndex] = editedResumeData
      setResumes([...resumes])
    } else {
      let { parsedData = {} } = selectedResume || {}
      parsedData = {
        ...parsedData,
        [key]: [...parsedData[key], newObj]
      }
      const editedResumeData = {
        ...selectedResume,
        parsedData,
        updateStatus: 'edit'
      }
      setSelectedResume(editedResumeData)
      resumes[selectedResumeIndex] = editedResumeData
      setResumes([...resumes])
    }
  }
  const removeFromResume = (key, index, isMaster) => {
    if (isMaster) {
      const { master = {} } = selectedResume || {}
      let { resume_data } = master
      resume_data = {
        ...resume_data,
        [key]: resume_data[key].filter((item, i) => i !== index)
      }
      const editedResumeData = {
        ...selectedResume,
        master: {
          ...master,
          resume_data,
          updateStatus: 'edit'
        },
        updateStatus: 'edit'
      }
      setSelectedResume(editedResumeData)
      resumes[selectedResumeIndex] = editedResumeData
      setResumes([...resumes])
    } else {
      let { parsedData = {} } = selectedResume || {}
      const filetoRemove = _.cloneDeep(parsedData[key][index])
      parsedData = {
        ...parsedData,
        [key]: parsedData[key].filter((item, i) => i !== index)
      }
      const editedResumeData = {
        ...selectedResume,
        parsedData,
        updateStatus: 'edit',
        del_screenshots: [
          ...selectedResume.del_screenshots,
          filetoRemove.delete_url ? filetoRemove.delete_url : filetoRemove.src
        ]
      }
      setSelectedResume(editedResumeData)
      resumes[selectedResumeIndex] = editedResumeData
      setResumes([...resumes])
    }
  }

  const handleResumeOnEdit = (key, value, index, childKey) => {
    const { parsedData = {} } = selectedResume || {}
    if (key === 'name') {
      key = 'emp_name'
    } else if (key === 'role') {
      key = 'emp_job_title'
      index = 0
      childKey = 'job_title'
      parsedData.emp_job_title = [
        {
          job_title: ''
        }
      ]
    }
    if (childKey) {
      parsedData[key][index][childKey] = value
    } else {
      parsedData[key] = value
    }
    const editedResumeData = {
      ...selectedResume,
      parsedData,
      updateStatus: 'edit'
    }
    setSelectedResume(editedResumeData)
    resumes[selectedResumeIndex] = editedResumeData
    setResumes([...resumes])
  }

  const renderEditor = () => {
    const { parsedData = {}, profile_picture_location = '' } =
      selectedResume || {}
    const { emp_job_title = [] } = parsedData || {}
    const { job_title = '' } = emp_job_title?.[0] || {}
    const { emp_name = '' } = parsedData || {}
    const selectedPeopleData = {
      name: emp_name,
      emp_name,
      role: job_title,
      profile_pic: profile_picture_location
    }
    const selectedResumeParsedData = parsedData || {}
    const { leftPane, rightPane } = resumeStyle
    return (
      <Box sx={{ width: '100%', height: '100%', wordBreak: 'break-word' }}>
        <Box>
          <Grid container>
            <Grid item sm={6} md={4} lg={3} style={{ padding: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '15px',
                  padding: '10px 0px'
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <ReactResizeDetector handleWidth>
                    {({ width }) => (
                      <div>
                        <ProfilePicturePicker
                          s3Obj={s3Obj}
                          isReadOnly={saving}
                          isEditable
                          name={emp_name}
                          profile_pic={profile_picture_location}
                          style={{
                            height: width - 30,
                            width: width - 30,
                            fontSize: '40px'
                          }}
                          handleMode={handleMode}
                          onImageSelect={addprofilePicture}
                          viewImage={setSelectedImage}
                          faces={faces}
                          currentPage={currentPage + 1}
                        />
                      </div>
                    )}
                  </ReactResizeDetector>
                </Box>
              </Box>
              {Object.keys(leftPane)?.map((key, index) => {
                const {
                  label,
                  style,
                  type,
                  location,
                  children,
                  placeholder = _.startCase(key)
                } = leftPane[key] || {}
                const { value: labelValue, style: labelStyle } = label || {}
                if (type === 'string') {
                  const displayValue =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  return (
                    <Box key={index}>
                      {label && <Box style={labelStyle}>{labelValue}</Box>}
                      <Tooltip
                        enterDelay={1000}
                        title={placeholder}
                        placement="right-start"
                        arrow
                      >
                        <Box>
                          <ContentEditable
                            style={style}
                            html={displayValue}
                            onChange={(e) =>
                              handleResumeOnEdit(key, e.currentTarget.innerText)
                            }
                            placeholder={placeholder}
                            disabled={saving}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  )
                } else if (type === 'array') {
                  const displayValueMap =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  return (
                    <Box key={index}>
                      {label && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            ...labelStyle
                          }}
                        >
                          <Box>{labelValue}</Box>
                          <Tooltip title={'Add New ' + labelValue}>
                            <IconButton
                              disableRipple
                              sx={{ padding: '0' }}
                              onClick={(e) => {
                                addNewToResume(key, children)
                              }}
                              disabled={saving}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      {displayValueMap &&
                        displayValueMap?.map((displayValueObj, index2) => {
                          return (
                            <Box key={index2}>
                              <Box sx={{ textAlign: 'end' }}>
                                <Tooltip title={'Remove ' + labelValue}>
                                  <IconButton
                                    disableRipple
                                    sx={{ padding: '0' }}
                                    disabled={saving}
                                    onClick={(e) => {
                                      removeFromResume(key, index2)
                                    }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              {Object.keys(displayValueObj || {})?.map(
                                (childKey, index3) => {
                                  const {
                                    style: childStyle,
                                    placeholder: childPlaceholder = _.startCase(
                                      childKey
                                    )
                                  } = children[childKey] || {}
                                  const childValue =
                                    displayValueObj[childKey] || ''
                                  return (
                                    <Tooltip
                                      enterDelay={1000}
                                      title={childPlaceholder}
                                      placement="right-start"
                                      arrow
                                      key={index3}
                                    >
                                      <Box>
                                        <ContentEditable
                                          key={index3}
                                          style={childStyle}
                                          html={childValue}
                                          onChange={(e) =>
                                            handleResumeOnEdit(
                                              key,
                                              e.currentTarget.innerText,
                                              index2,
                                              childKey
                                            )
                                          }
                                          placeholder={childPlaceholder}
                                          disabled={saving}
                                        />
                                      </Box>
                                    </Tooltip>
                                  )
                                }
                              )}
                            </Box>
                          )
                        })}
                    </Box>
                  )
                }
                return <></>
              })}
            </Grid>
            <Grid item sm={6} md={8} lg={9} style={{ padding: '20px' }}>
              {Object.keys(rightPane)?.map((key, index) => {
                const {
                  label,
                  style,
                  type,
                  location,
                  children,
                  placeholder = _.startCase(key)
                } = rightPane[key] || {}
                const { value: labelValue, style: labelStyle } = label || {}
                if (type === 'string') {
                  const displayValue =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]

                  return (
                    <Box key={index}>
                      {label && <Box style={labelStyle}>{labelValue}</Box>}
                      <Tooltip
                        enterDelay={1000}
                        title={placeholder}
                        placement="right-start"
                        arrow
                      >
                        <Box>
                          <ContentEditable
                            style={style}
                            html={displayValue}
                            onChange={(e) =>
                              handleResumeOnEdit(key, e.currentTarget.innerText)
                            }
                            placeholder={placeholder}
                            disabled={saving}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  )
                } else if (type === 'array') {
                  const displayValueMap =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  return (
                    <Box key={index}>
                      {label && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            ...labelStyle
                          }}
                        >
                          <Box>{labelValue}</Box>
                          <Tooltip title={'Add New ' + labelValue}>
                            <IconButton
                              disableRipple
                              sx={{ padding: '0' }}
                              disabled={saving}
                              onClick={(e) => {
                                addNewToResume(key, children)
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      {displayValueMap &&
                        displayValueMap?.map((displayValueObj, index2) => {
                          return (
                            <Box key={index2}>
                              <Box sx={{ textAlign: 'end' }}>
                                <Tooltip title={'Remove ' + labelValue}>
                                  <IconButton
                                    disableRipple
                                    sx={{ padding: '0' }}
                                    disabled={saving}
                                    onClick={(e) => {
                                      removeFromResume(key, index2)
                                    }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              {Object.keys(displayValueObj || {})?.map(
                                (childKey, index3) => {
                                  const {
                                    style: childStyle,
                                    childPlaceholder = _.startCase(childKey)
                                  } = children[childKey] || {}
                                  const childValue =
                                    displayValueObj[childKey] || ''
                                  return (
                                    <Tooltip
                                      enterDelay={1000}
                                      title={childPlaceholder}
                                      placement="right-start"
                                      arrow
                                      key={index3}
                                    >
                                      <Box>
                                        <ContentEditable
                                          key={index3}
                                          style={childStyle}
                                          html={childValue}
                                          onChange={(e) =>
                                            handleResumeOnEdit(
                                              key,
                                              e.currentTarget.innerText,
                                              index2,
                                              childKey
                                            )
                                          }
                                          placeholder={childPlaceholder}
                                          disabled={saving}
                                        />
                                      </Box>
                                    </Tooltip>
                                  )
                                }
                              )}
                            </Box>
                          )
                        })}
                    </Box>
                  )
                }
                return <></>
              })}
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }

  const renderResume = () => {
    const { parsedData = {}, profile_picture_location = '' } =
      selectedResume || {}
    const { emp_job_title = [] } = parsedData || {}
    const { job_title = '' } = emp_job_title?.[0] || {}
    const { emp_name = '' } = parsedData || {}
    const selectedPeopleData = {
      name: emp_name,
      role: job_title,
      profile_pic: profile_picture_location
    }
    const selectedResumeParsedData = parsedData || {}
    const { leftPane, rightPane } = resumeStyle
    return (
      <Box sx={{ width: '100%', height: '100%', wordBreak: 'break-word' }}>
        <Box
          sx={{
            padding: '25px 20px',
            textAlign: 'justify'
          }}
        >
          <Grid container>
            <Grid item sm={6} md={4} lg={3} style={{ padding: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '15px',
                  padding: '10px 0px'
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <ReactResizeDetector handleWidth>
                    {({ width }) => (
                      <div>
                        <ProfilePicturePicker
                          isReadOnly={saving}
                          name={emp_name}
                          profile_pic={profile_picture_location}
                          style={{
                            height: width - 30,
                            width: width - 30,
                            fontSize: '40px'
                          }}
                          handleMode={handleMode}
                          onImageSelect={addprofilePicture}
                          viewImage={setSelectedImage}
                          faces={faces}
                          s3Obj={s3Obj}
                          currentPage={currentPage + 1}
                        />
                      </div>
                    )}
                  </ReactResizeDetector>
                </Box>
              </Box>
              {Object.keys(leftPane)?.map((key, index) => {
                const { label, style, type, location, children } =
                  leftPane[key] || {}
                const { value: labelValue, style: labelStyle } = label || {}
                if (type === 'string') {
                  const displayValue =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  return (
                    displayValue && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        <Box style={style}>{displayValue}</Box>
                      </Box>
                    )
                  )
                } else if (type === 'array') {
                  const displayValueMap =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  const exists =
                    checkArrOfObjHasAtleastOneValue(displayValueMap)
                  return (
                    exists && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        {displayValueMap &&
                          displayValueMap?.map((displayValueObj, index2) => {
                            return (
                              <Box key={index2}>
                                {Object.keys(displayValueObj || {})?.map(
                                  (childKey, index3) => {
                                    const { style: childStyle } =
                                      children[childKey] || {}
                                    const childValue =
                                      displayValueObj[childKey] || ''
                                    return (
                                      childValue && (
                                        <Box style={childStyle} key={index3}>
                                          {childValue}
                                        </Box>
                                      )
                                    )
                                  }
                                )}
                              </Box>
                            )
                          })}
                      </Box>
                    )
                  )
                }
                return <></>
              })}
            </Grid>
            <Grid item sm={6} md={8} lg={9} style={{ padding: '20px' }}>
              {Object.keys(rightPane)?.map((key, index) => {
                const { label, style, type, location, children } =
                  rightPane[key] || {}
                const { value: labelValue, style: labelStyle } = label || {}
                if (type === 'string') {
                  const displayValue =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  return (
                    displayValue && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        <Box style={style}>{displayValue}</Box>
                      </Box>
                    )
                  )
                } else if (type === 'array') {
                  const displayValueMap =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  const exists =
                    checkArrOfObjHasAtleastOneValue(displayValueMap)
                  return (
                    exists && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        {displayValueMap &&
                          displayValueMap?.map((displayValueObj, index2) => {
                            return (
                              <Box key={index2}>
                                {Object.keys(displayValueObj || {})?.map(
                                  (childKey, index3) => {
                                    const { style: childStyle } =
                                      children[childKey] || {}
                                    const childValue =
                                      displayValueObj[childKey] || ''
                                    return (
                                      childValue && (
                                        <Box style={childStyle} key={index3}>
                                          {childValue}
                                        </Box>
                                      )
                                    )
                                  }
                                )}
                              </Box>
                            )
                          })}
                      </Box>
                    )
                  )
                }
                return <></>
              })}
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }

  const handleResumeOnEditMaster = (key, value, index, childKey) => {
    const { master } = selectedResume || {}
    const { resume_data } = master || {}
    if (key === 'name') {
      key = 'emp_name'
    } else if (key === 'role') {
      key = 'emp_job_title'
      index = 0
      childKey = 'job_title'
      resume_data.emp_job_title = [
        {
          job_title: ''
        }
      ]
    }
    if (childKey) {
      resume_data[key][index][childKey] = value
    } else {
      resume_data[key] = value
    }
    const editedResumeData = {
      ...selectedResume,
      master: {
        ...master,
        resume_data,
        updateStatus: 'edit'
      },
      updateStatus: 'edit'
    }
    setSelectedResume(editedResumeData)
    resumes[selectedResumeIndex] = editedResumeData
    setResumes([...resumes])
  }

  const handleDeleteMaster = () => {
    const newResumes = _.clone(resumes)
    newResumes[selectedResumeIndex].master.updateStatus = 'delete'
    newResumes[selectedResumeIndex].updateStatus = 'edit'
    setSelectedResume(newResumes[selectedResumeIndex])
    setResumes(newResumes)
  }

  const handleReprocessMasterResume = async (id) => {
    setIsSaving(true)
    const toastId = toast.info(
      <div style={{ display: 'flex' }}>
        {'Reprocessing Master Resume...'}&nbsp;
        <CircularProgress size={20} />
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false
      }
    )

    const payload = {
      master_people_id: id
    }
    const data = {
      forwardPayload: payload,
      requestType: 'masterResumeReprocess'
    }

    const url = process.env.REACT_APP_CHAT_URL || ''
    const apiUrl = new URL(url)
    const currentSession = await Auth.currentSession()
    const token = currentSession?.getAccessToken()?.getJwtToken()
    const signedUrl = await signerObj.sign({
      method: 'POST',
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      body: JSON.stringify(data),
      protocol: apiUrl.protocol,
      headers: {
        'Content-Type': 'application/json',
        host: apiUrl.hostname,
        userAuth: 'Bearer ' + token
      }
    })

    let completeText = ''
    const response = await fetch(`${url}`, { ...signedUrl })
    if (response.status === 200 && response.body) {
      const reader = response.body.getReader()
      while (true) {
        const { done, value } = await reader.read()
        if (done) {
          break
        }
        const text = new TextDecoder().decode(value)
        completeText = completeText + text
      }
      completeText = completeText.replace(/__END_OF_CHAT__/g, '')
      toast.dismiss(toastId)
      try {
        const resume_data = completeText
        const template = JSON.parse(resume_data)
        if (checkResumeHasData(template)) {
          toast.success('Extraction Success')
          const newResumes = _.clone(resumes)
          newResumes[selectedResumeIndex].master.resume_data = template
          newResumes[selectedResumeIndex].master.updateStatus = 'edit'
          newResumes[selectedResumeIndex].updateStatus = 'edit'
          setSelectedResume(newResumes[selectedResumeIndex])
          setResumes(newResumes)
        } else {
          toast.error('Extraction Failed')
        }
      } catch (e) {
        console.log(e)
        toast.error('Extraction Failed')
      }
    } else {
      toast.dismiss(toastId)
      toast.error('Extraction Failed')
    }
    setIsSaving(false)
  }

  const checkResumeHasData = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      if (key !== 'content_id') {
        if (Array.isArray(value) && value.length > 0) return true // Check for non-empty array
        if (typeof value === 'string' && value.trim() !== '') return true // Check for non-empty string
        if (
          typeof value === 'object' &&
          value !== null &&
          Object.keys(value).length > 0
        )
          return true // Check for non-empty object
        if (typeof value === 'number' && !isNaN(value)) return true // Check for number
      }
    }
    return false
  }

  const addMasterProfilePicture = (image) => {
    const newResumes = _.clone(resumes)
    newResumes[selectedResumeIndex].master.profile_picture = image || {}
    newResumes[selectedResumeIndex].master.updateStatus = 'edit'
    newResumes[selectedResumeIndex].updateStatus = 'edit'
    setSelectedResume(newResumes[selectedResumeIndex])
    setResumes(newResumes)
  }

  const masterResumeEditor = () => {
    const { master } = selectedResume || {}
    const { updateStatus = '', profile_picture = {} } = master || {}
    const readOnly = !!['reprocess', 'delete'].includes(updateStatus)
    if (!master || updateStatus === 'delete') {
      return (
        <Box>
          <Box sx={{ textAlign: 'center', padding: '20px' }}>
            No Master Resume Found
          </Box>
        </Box>
      )
    }

    const { id, resume_data, verified, people_data } = master || {}
    const parsedData = resume_data
    const { emp_job_title = [] } = parsedData || {}
    const { job_title = '' } = emp_job_title?.[0] || {}
    const { emp_name = '' } = parsedData || {}
    const selectedPeopleData = {
      name: emp_name,
      role: job_title
    }
    const selectedResumeParsedData = parsedData || {}
    const profile_picture_location = profile_picture?.location || ''
    const { leftPane, rightPane } = resumeStyle
    return (
      <Box sx={{ width: '100%', height: '100%', wordBreak: 'break-word' }}>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          {readOnly && (
            <Tooltip title={'Mapping changed. Read Only'}>
              <LockIcon />
            </Tooltip>
          )}
          <Tooltip title={'Toggle Master Verify'}>
            <IconButton
              disableRipple
              sx={{
                padding: '0',
                color: 'inherit'
              }}
              disabled={saving || readOnly}
              onClick={() => handleResumeVerify(selectedResumeIndex, true)}
            >
              {verified ? (
                <CheckBoxOutlinedIcon />
              ) : (
                <CheckBoxOutlineBlankOutlinedIcon />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={'Reprocess Master Resume'}>
            <IconButton
              disableRipple
              sx={{
                padding: '0',
                color: 'inherit'
              }}
              disabled={saving || readOnly}
              onClick={() => handleReprocessMasterResume(id)}
            >
              <LoopIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Delete and Regenerate Master Resume'}>
            <IconButton
              disableRipple
              sx={{
                padding: '0',
                color: 'inherit'
              }}
              disabled={saving || readOnly}
              onClick={() => handleDeleteMaster()}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            padding: '25px 20px'
          }}
        >
          <Grid container>
            <Grid item sm={6} md={4} lg={3} style={{ padding: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '15px',
                  padding: '10px 0px'
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <ReactResizeDetector handleWidth>
                    {({ width }) => (
                      <div>
                        <ProfilePicturePicker
                          s3Obj={s3Obj}
                          isReadOnly={saving}
                          isEditable
                          name={emp_name}
                          profile_pic={{ src: profile_picture_location }}
                          style={{
                            height: width - 30,
                            width: width - 30,
                            fontSize: '40px'
                          }}
                          handleMode={() => {}}
                          onImageSelect={addMasterProfilePicture}
                          resumes={resumes}
                          viewImage={setSelectedImage}
                          faces={faces}
                          currentPage={currentPage + 1}
                          isMasterPicker={true}
                          masterFaces={people_data}
                        />
                      </div>
                    )}
                  </ReactResizeDetector>
                </Box>
              </Box>
              {Object.keys(leftPane)?.map((key, index) => {
                const {
                  label,
                  style,
                  type,
                  location,
                  children,
                  placeholder = _.startCase(key)
                } = leftPane[key] || {}
                const { value: labelValue, style: labelStyle } = label || {}
                if (type === 'string') {
                  const displayValue =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  return (
                    <Box key={index}>
                      {label && <Box style={labelStyle}>{labelValue}</Box>}
                      <Tooltip
                        enterDelay={1000}
                        title={placeholder}
                        placement="right-start"
                        arrow
                      >
                        <Box>
                          <ContentEditable
                            style={style}
                            html={displayValue}
                            onChange={(e) =>
                              handleResumeOnEditMaster(
                                key,
                                e.currentTarget.innerText
                              )
                            }
                            placeholder={placeholder}
                            disabled={saving || readOnly}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  )
                } else if (type === 'array') {
                  const displayValueMap =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  return (
                    <Box key={index}>
                      {label && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            ...labelStyle
                          }}
                        >
                          <Box>{labelValue}</Box>
                          <Tooltip title={'Add New ' + labelValue}>
                            <IconButton
                              disableRipple
                              sx={{ padding: '0' }}
                              onClick={(e) => {
                                addNewToResume(key, children, true)
                              }}
                              disabled={saving || readOnly}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      {displayValueMap &&
                        displayValueMap?.map((displayValueObj, index2) => {
                          return (
                            <Box key={index2}>
                              <Box sx={{ textAlign: 'end' }}>
                                <Tooltip title={'Remove ' + labelValue}>
                                  <IconButton
                                    disableRipple
                                    sx={{ padding: '0' }}
                                    disabled={saving || readOnly}
                                    onClick={(e) => {
                                      removeFromResume(key, index2, master)
                                    }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              {Object.keys(displayValueObj || {})?.map(
                                (childKey, index3) => {
                                  const {
                                    style: childStyle,
                                    placeholder: childPlaceholder = _.startCase(
                                      childKey
                                    )
                                  } = children[childKey] || {}
                                  const childValue =
                                    displayValueObj[childKey] || ''
                                  return (
                                    <Tooltip
                                      enterDelay={1000}
                                      title={childPlaceholder}
                                      placement="right-start"
                                      arrow
                                      key={index3}
                                    >
                                      <Box>
                                        <ContentEditable
                                          key={index3}
                                          style={childStyle}
                                          html={childValue}
                                          onChange={(e) =>
                                            handleResumeOnEditMaster(
                                              key,
                                              e.currentTarget.innerText,
                                              index2,
                                              childKey
                                            )
                                          }
                                          placeholder={childPlaceholder}
                                          disabled={saving || readOnly}
                                        />
                                      </Box>
                                    </Tooltip>
                                  )
                                }
                              )}
                            </Box>
                          )
                        })}
                    </Box>
                  )
                }
                return <></>
              })}
            </Grid>
            <Grid item sm={6} md={8} lg={9} style={{ padding: '20px' }}>
              {Object.keys(rightPane)?.map((key, index) => {
                const {
                  label,
                  style,
                  type,
                  location,
                  children,
                  placeholder = _.startCase(key)
                } = rightPane[key] || {}
                const { value: labelValue, style: labelStyle } = label || {}
                if (type === 'string') {
                  const displayValue =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  return (
                    <Box key={index}>
                      {label && <Box style={labelStyle}>{labelValue}</Box>}
                      <Tooltip
                        enterDelay={1000}
                        title={placeholder}
                        placement="right-start"
                        arrow
                      >
                        <Box>
                          <ContentEditable
                            style={style}
                            html={displayValue}
                            onChange={(e) =>
                              handleResumeOnEditMaster(
                                key,
                                e.currentTarget.innerText
                              )
                            }
                            placeholder={placeholder}
                            disabled={saving || readOnly}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  )
                } else if (type === 'array') {
                  const displayValueMap =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  return (
                    <Box key={index}>
                      {label && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            ...labelStyle
                          }}
                        >
                          <Box>{labelValue}</Box>
                          <Tooltip title={'Add New ' + labelValue}>
                            <IconButton
                              disableRipple
                              sx={{ padding: '0' }}
                              disabled={saving || readOnly}
                              onClick={(e) => {
                                addNewToResume(key, children, true)
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      {displayValueMap &&
                        displayValueMap?.map((displayValueObj, index2) => {
                          return (
                            <Box key={index2}>
                              <Box sx={{ textAlign: 'end' }}>
                                <Tooltip title={'Remove ' + labelValue}>
                                  <IconButton
                                    disableRipple
                                    sx={{ padding: '0' }}
                                    disabled={saving || readOnly}
                                    onClick={(e) => {
                                      removeFromResume(key, index2, true)
                                    }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              {Object.keys(displayValueObj || {})?.map(
                                (childKey, index3) => {
                                  const {
                                    style: childStyle,
                                    childPlaceholder = _.startCase(childKey)
                                  } = children[childKey] || {}
                                  const childValue =
                                    displayValueObj[childKey] || ''
                                  return (
                                    <Tooltip
                                      enterDelay={1000}
                                      title={childPlaceholder}
                                      placement="right-start"
                                      arrow
                                      key={index3}
                                    >
                                      <Box>
                                        <ContentEditable
                                          key={index3}
                                          style={childStyle}
                                          html={childValue}
                                          onChange={(e) =>
                                            handleResumeOnEditMaster(
                                              key,
                                              e.currentTarget.innerText,
                                              index2,
                                              childKey
                                            )
                                          }
                                          placeholder={childPlaceholder}
                                          disabled={saving || readOnly}
                                        />
                                      </Box>
                                    </Tooltip>
                                  )
                                }
                              )}
                            </Box>
                          )
                        })}
                    </Box>
                  )
                }
                return <></>
              })}
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }

  const { selectedColor } = useColor()

  const renderFloatTools = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '10px',
          gap: '10px'
        }}
      >
        <Tooltip title={'Edit Resume'}>
          <IconButton
            disableRipple
            sx={{
              color: insideView === 'edit' ? selectedColor : 'primary',
              padding: '0'
            }}
            onClick={() => {
              setInsideView('edit')
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        {edit === 'people' && (
          <Tooltip title={'Preview'}>
            <IconButton
              disableRipple
              sx={{
                color: insideView === 'preview' ? selectedColor : 'primary',
                padding: '0'
              }}
              onClick={() => {
                setInsideView('preview')
              }}
            >
              <PreviewIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={'View JSON'}>
          <IconButton
            disableRipple
            sx={{
              color: insideView === 'viewjson' ? selectedColor : 'primary',
              padding: '0'
            }}
            onClick={() => {
              setInsideView('viewjson')
            }}
          >
            <CodeIcon />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  const renderMainEditor = () => {
    switch (insideView) {
      case 'edit':
        return edit === 'people' ? renderEditor() : masterResumeEditor()
      case 'preview':
        return edit === 'people' ? renderResume() : masterResumeEditor()
      case 'viewjson': {
        const resume =
          edit === 'people'
            ? selectedResume?.parsedData
            : selectedResume?.master?.resume_data
        const json = JSON.stringify(resume, null, 2)
        return !_.isEmpty(resume) ? (
          <Box
            sx={{
              padding: '20px',
              '& pre': {
                maxHeight: '100%'
              }
            }}
          >
            <Box sx={{ textAlign: 'end' }}>
              <Tooltip title={'Copy JSON'}>
                <CopyButton content={json} />
              </Tooltip>
            </Box>
            <SyntaxHighlighter language="json">{json}</SyntaxHighlighter>
          </Box>
        ) : (
          <Box>
            <Box sx={{ textAlign: 'center', padding: '20px' }}>
              No Resume JSON Found
            </Box>
          </Box>
        )
      }
    }
  }

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData('text/plain', index)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }
  const handleDrop = (event, targetIndex) => {
    const draggedIndex = parseInt(event.dataTransfer.getData('text/plain'), 10)
    const newTextBBox = [...textBBox]
    ;[newTextBBox[draggedIndex], newTextBBox[targetIndex]] = [
      newTextBBox[targetIndex],
      newTextBBox[draggedIndex]
    ]
    setTextBBox(newTextBBox)
  }

  const handleDragEnter = (key) => {
    if (!saving) {
      setDragActiveTextBox(key)
    }
  }

  const handleDragEnd = () => {
    setDragActiveTextBox('')
  }

  const handleTextExtractUpdate = (index, value) => {
    const newAnn = _.cloneDeep(textBBox)
    newAnn[index].text = value
    setTextBBox(newAnn)
  }

  return (
    <Box sx={{ height: '1px', minHeight: '100%' }}>
      <Container>
        {reprocessMode === 'textextract' ? (
          <Container>
            <Section overFlow>
              <Box sx={{ padding: '20px' }}>
                <Box sx={{ fontSize: '16px' }}>Text Extracted</Box>
                {textBBox.length > 0 &&
                  textBBox.map((textextract, index) => (
                    <Box
                      key={index}
                      draggable={!saving}
                      onDragStart={(event) => handleDragStart(event, index)}
                      onDragOver={handleDragOver}
                      onDragEnd={() => handleDragEnd()}
                      onDrop={(event) => handleDrop(event, index)}
                      onDragEnter={() => handleDragEnter(index)}
                      sx={{
                        borderTop:
                          dragActiveTextBox === index ? '1px solid red' : ''
                      }}
                    >
                      <Box sx={{ textAlign: 'end' }}>
                        <Tooltip title={'Delete BBox'}>
                          <IconButton
                            disableRipple
                            sx={{ padding: '0' }}
                            onClick={() => {
                              handleSelectionAction('deleteTextBoxIndex', index)
                            }}
                            disabled={saving}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          disableRipple
                          sx={{ padding: '0' }}
                          disabled={saving}
                        >
                          <DragIndicatorIcon />
                        </IconButton>
                      </Box>
                      <ContentEditable
                        style={{
                          border: '1px solid #E5E5E5',
                          margin: '10px 0px',
                          padding: '5px',
                          borderRadius: '5px'
                        }}
                        html={textextract?.text}
                        onChange={(e) =>
                          handleTextExtractUpdate(index, e.target.value)
                        }
                        placeholder={'Text Extracted'}
                        disabled={saving}
                      />
                    </Box>
                  ))}
              </Box>
            </Section>
          </Container>
        ) : _.isEmpty(selectedResume) ? (
          <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
            Selected Resume will appear here
          </Box>
        ) : (
          <>
            <SectionFixed>
              {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10px',
                  marginBottom: '5px'
                }}
              >
                <ButtonGroup>
                  <Button
                    variant={edit === 'people' ? 'contained' : 'outlined'}
                    onClick={() => {
                      setEdit('people')
                    }}
                  >
                    People Resume
                  </Button>
                  <Button
                    variant={edit === 'master' ? 'contained' : 'outlined'}
                    onClick={() => {
                      setEdit('master')
                    }}
                  >
                    Master Resume
                  </Button>
                </ButtonGroup>
              </Box> */}
            </SectionFixed>
            <Section overFlow>
              {renderFloatTools()}
              {renderMainEditor()}
            </Section>
          </>
        )}
      </Container>
    </Box>
  )
}

export default ResumeEditor
