import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%'
  },
  webviewer: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: '100%'
  },
  timeStampContainer: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    padding: '10px',
    width: '25%',
    // height: '100%',
    height: '100%',
    // maxHeight: '90vh',
    zIndex: 1
  },
  iconButtonRight: {
    float: 'right'
  },
  dataContainer: {
    display: 'flex'
  },
  markerKeyTime: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#A2A2A2',
    fontSize: '15px',
    // cursor: 'pointer',
    width: '70px'
  },
  markerKeyText: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#A2A2A2',
    fontSize: '15px',
    width: '150px'
  },
  markerText: {
    fontFamily: 'PoppinsRegular',
    cursor: 'pointer',
    fontWeight: '500',
    color: '#A2A2A2',
    fontSize: '12px',
    listStyleType: 'circle',
    wordBreak: 'break-all'
    // paddingBottom: "10px",
  },
  markerLabel: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '100',
    cursor: 'pointer',
    color: '#000000',
    fontSize: '15px',
    listStyleType: 'circle',
    wordBreak: 'break-all'
    // paddingBottom: "10px",
  },
  listPointer: {
    paddingBottom: '10px',
    margin: '0px',
    paddingInlineStart: '14px',
    borderLeft: '1px solid #E5E5E5'
  },
  liPointer: {
    margin: '0px',
    marginRight: '0px'
  },
  markerTime: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    cursor: 'pointer',
    color: '#000000',
    fontSize: '15px',
    wordBreak: 'break-all'
  },
  searchWrapper: {
    background: '#f4f4f4'
  }
})

export { useStyles }
