import { FETCH_CLIENTS_LIST, UPDATE_CLIENTS_LIST } from '../../actionTypes'
import { getClientsList } from '../../api'
import { toast } from 'react-toastify'

const fetchClientsList =
  (data, source = 'fetch') =>
  async (dispatch) => {
    if (source === 'fetch') {
      let clientsList = []
      const res = await getClientsList(data)
      if (res.status === 200) {
        const result = res.data
        clientsList = result.clients
        if (clientsList.length > 0) {
          clientsList = clientsList.map((client) => {
            const { domain_id, proposal_id } = client
            const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/1.png`
            const variation_list = client.variation_list.map((variation) => {
              const { proposal_id: variation_proposal_id } = variation
              const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${variation_proposal_id}/original/1.png`
              return {
                ...variation,
                thumbnail_url
              }
            })
            return {
              ...client,
              thumbnail_url,
              variation_list
            }
          })
        }
        const finalClientsList = {
          ...result,
          clients: clientsList
        }
        dispatch({
          type: FETCH_CLIENTS_LIST,
          payload: finalClientsList
        })
      } else {
        toast.error('Unable to fetch clients')
      }
    } else if (source === 'update') {
      if (data?.hidden) {
        const client_group_id = data.client_group_id
        dispatch({
          type: UPDATE_CLIENTS_LIST,
          payload: {
            client_group_id,
            hidden: true
          }
        })
      } else {
        const client_group_id = data.client_group_id
        const { domain_id, proposal_id } = data
        const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/1.png`
        const client_data = {
          client_id: data.client_id,
          proposal_id: data.prposal_id,
          proposal_name: data.proposal_name,
          proposal_url: data.proposal_url,
          source: 'added_from_client',
          thumbnail_url,
          title: data.client_name,
          data: {
            client_name: data.client_name,
            client_contact: '',
            client_location: '',
            project_name: data.project_name,
            collection_id: data.project_collection_id
          }
        }
        dispatch({
          type: UPDATE_CLIENTS_LIST,
          payload: {
            client_group_id,
            client_data
          }
        })
      }
    }
  }

export default fetchClientsList
