import { UPDATE_RFX, TOASTER_ALERT } from '../../../actionTypes'
import { postRfx } from '../../../api'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../../../config/eventMapping'
import fetchRFxDocuments from '../fetchRFxDocuments'
const updateRfx =
  (data, type = 'UPDATE_RFX') =>
  async (dispatch) => {
    try {
      const response = await postRfx(data)
      if (response.status === 200) {
        dispatch(fetchRFxDocuments())
        dispatch({
          type: TOASTER_ALERT,
          payload: {
            message: 'Rfx updated success',
            show: true,
            type: 'success'
          }
        })
      } else {
        dispatch({
          type: TOASTER_ALERT,
          payload: {
            message: 'Rfx updated failed',
            show: true,
            type: 'success'
          }
        })
      }
    } catch (e) {
      /* toast.error(e); */
      dispatch({
        type: TOASTER_ALERT,
        payload: {
          message: 'Failed to upload Rfx',
          show: true,
          type: 'error'
        }
      })
    }
  }
export default updateRfx
