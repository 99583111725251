import { SET_COLLECTIONS_EXPANDED } from '../../../actionTypes'

const setExpandedObj = (data) => async (dispatch) => {
  dispatch({
    type: SET_COLLECTIONS_EXPANDED,
    payload: data
  })
}

export default setExpandedObj
