import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor()

  return {
    sortLabel: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '20px',
      width: '10px',
      '& button': {
        padding: '0px !important'
      }
    },
    sortIcon: {
      width: '8px !important',
      height: '8px !important',
      color: '#949494',
      cursor: 'pointer'
    },
    sortIconActive: {
      color: '#000000',
      width: '8px !important',
      height: '8px !important',
      cursor: 'pointer'
    },
    searchWrapper: {
      padding: '5px 15px',
      width: 'calc(100% - 30px)',
      fontSize: '16px',
      margin: '0px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '50px',
      '& .MuiGrid-item': {
        padding: '0px'
      }
    },
    searchBarWrapper: {
      width: '-webkit-fill-available'
    },
    searchButtonWrapper: {
      display: 'flex',
      gap: '10px',
      whiteSpace: 'nowrap'
    },
    tagsWrapper: {
      padding: '5px 15px'
    },
    tags: {
      '& button': {
        minWidth: '100px',
        maxWidth: '200px'
      },
      '& #multiCheckboxWrapper': {
        width: 'auto !important',
        '& button': {
          fontSize: '12px',
          padding: '1px 4px'
        }
      },
      '& #multiCheckboxOption': {
        width: '100% !important',
        fontWeight: '500',
        fontSize: '12px',
        color: '#000000',
        '& .MuiSvgIcon-root': {
          fontSize: '20px'
        },
        '& .MuiCheckbox-root': {
          padding: '5px'
        }
      },
      '& .Mui-checked': {
        color: '#0645AD !important'
      },
      '& #multiCheckboxMenuWrapper': {
        border: '1px solid #E5E5E5 !important',
        borderRadius: '4px !important',
        marginTop: '5px'
      },
      '& #multiCheckboxMenu': {
        width: '250px !important',
        '& input': {
          fontSize: '12px !important'
        }
      }
    },
    tagsSelected: {
      '& button': {
        color: selectedColor,
        border: '1px solid ' + selectedColor
      }
    },
    clearFilterWrapper: {
      color: '#797979',
      fontSize: '14px',
      fontWeight: '400',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: '95px'
    },
    buttonTextWrapper: {
      fontSize: '12px',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      textTransform: 'none'
    },
    button: {
      backgroundColor: selectedColor
    }
  }
})

export { useStyles }
