import * as React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

export default function BasicDatePicker(props) {
  const { selectedDate, handleDateChange } = props
  const [value, setValue] = React.useState(dayjs(selectedDate))

  const handleChange = (newValue) => {
    setValue(newValue)
    handleDateChange(new Date(newValue.$d))
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker value={value} onChange={handleChange} disableFuture />
    </LocalizationProvider>
  )
}
