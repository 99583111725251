import { combineReducers } from 'redux'

import authentication from './Authentication/Reducer'
import workspaceDocument from './Workspace/Reducer'
import commonReducers from './Common/Reducer'
import settingsReducer from './Settings/Reducer'
import libraryList from './Library/Reducer'
import analyticsReducers from './Analytics/Reducer'
import uploaderReducer from './Uploader/Reducer'
import chatReducer from './Chat/Reducer'
import rfxReducers from './RFx/Reducer'
import peopleReducer from './People/Reducer'
import projectReducers from './Project/Reducer'
import tagReducer from './TagCenter/Reducer'
import resourceReducer from './Resource/Reducer'
import analyticsComplianceReducers from './AnalyticsCompliance/Reducer'
import clientsList from './Clients/Reducer'
import searchReducer from './Search/Reducer'

const rootReducer = combineReducers({
  authenticate: authentication,
  workspace: workspaceDocument,
  common: commonReducers,
  analyticList: analyticsReducers,
  settings: settingsReducer,
  library: libraryList,
  uploader: uploaderReducer,
  chat: chatReducer,
  rfxDocumentsList: rfxReducers,
  people: peopleReducer,
  projectsList: projectReducers,
  tagCenter: tagReducer,
  resource: resourceReducer,
  analyticsCompliance: analyticsComplianceReducers,
  clients: clientsList,
  search: searchReducer
})

export default rootReducer
