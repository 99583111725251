import React, { useEffect, useState } from 'react'
import { getDomain } from '../../store/api'
import { Container, Section, SectionFixed } from '../../components/Container'
import Typography from '@mui/material/Typography'
import { Box, Grid } from '@mui/material'
import { useStyles } from './styles'
import JoinDomain from '../../sections/DomainList'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../config/routes'
import Loader from '../../components/Loader'
import Logo from '../../assets/images/JoistLogo.png'
import { isArray, isEmpty } from 'lodash'

const Domain = () => {
  const userData = useSelector((state) => state.authenticate?.user)
  const domainData = userData?.domain
  const { domain_id = '', email = '' } = domainData
  const [domain, setDomain] = useState({})
  const [tab, setTab] = useState(0)
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const { container, titleH3, titleH4, box, loaderHeight } = classes
  const { domain_list = '' } = domain
  const navigate = useNavigate()
  const { DASHBOARD } = ROUTES

  useEffect(() => {
    async function fetchData() {
      const res = await getDomain()
      if (res.status === 200) {
        setDomain(res.data)
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (domain_id) {
      navigate(DASHBOARD)
    }
  }, [domain_id])

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={loaderHeight}
      >
        <Loader loading={loading} caption={'Fetching your team'} />
      </Grid>
    )
  }
  return (
    !domain_id && (
      <Container>
        <Section overFlow>
          <Box className={container}>
            <img src={Logo} alt="applogo" style={{ width: '200px' }} />
            {(isArray(domain?.domain_list) && !isEmpty(domain?.domain_list)) ||
            (isArray(domain?.invite_list) && !isEmpty(domain?.invite_list)) ? (
              <>
                <Typography
                  sx={{ marginTop: '25px' }}
                  variant="h4"
                  className={titleH3}
                >
                  Hello there! Welcome aboard!
                </Typography>
                <Typography
                  sx={{ marginTop: '10px' }}
                  variant="h5"
                  className={titleH4}
                >
                  Get started with a domain
                </Typography>
                <Box className={box}>
                  <JoinDomain domain={domain} />
                </Box>
              </>
            ) : (
              <Box
                sx={{ width: '500px', textAlign: 'center', marginTop: '25px' }}
              >
                <Typography
                  sx={{ marginTop: '20px' }}
                  variant="h5"
                  className={titleH3}
                >
                  Uh oh! It appears that you don't have any available domains to
                  join.
                </Typography>
                <Box
                  sx={{
                    textAlign: 'center',
                    marginTop: '20px',
                    fontSize: '18px'
                  }}
                  className={titleH3}
                >
                  If your organization is already a part of Joist, please
                  request an invite from your administrator. If you are new
                  here, please contact us at{' '}
                  <a href="https://www.joist.ai">joist.ai</a>&nbsp;to get
                  started.
                </Box>
              </Box>
            )}
          </Box>
        </Section>
      </Container>
    )
  )
}

export default Domain
