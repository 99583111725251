import React, { useState, useRef, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import { useStyles } from './styles'
import _, { add, set } from 'lodash'
import { checkExpiry } from '../../utils/AWS'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Loader, ImageRender, ImageGallery } from '../../components'
import { Close, Visibility } from '@mui/icons-material'
import MarkdownIt from 'markdown-it'
import Tooltip from '@mui/material/Tooltip'

import { Card, CardContent } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Box from '@material-ui/core/Box'
import ImageIcon from '@mui/icons-material/Image'
import AddIcon from '@mui/icons-material/Add'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
  getAllProjectIds,
  addProjectToClient,
  deleteProjectFromClient
} from '../../store/api'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import fetchClientsList from '../../store/Clients/Actions'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import DownloadIcon from '@mui/icons-material/Download'

function stringToColor(string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: '80px',
      width: '80px'
    },
    children: `${name?.split(' ')?.[0]?.[0] ? name.split(' ')[0][0] : ''}${
      name?.split(' ')?.[1]?.[0] ? name.split(' ')[1][0] : ''
    }`
  }
}

const Text = (props) => {
  const { text, style, onClick } = props
  return (
    <span
      style={style}
      dangerouslySetInnerHTML={{ __html: text }}
      onClick={onClick}
    />
  )
}

const ClientResult = (props) => {
  const {
    data = {},
    openProject,
    handleCloseProject = () => {},
    s3Obj,
    openType = '',
    thumbnail_url_list = [],
    handleVariationIndexChange = () => {},
    screenshotIndex = 0,
    originProposalNames = [],
    handlePDFViewer = () => {},
    topVariatonList = [],
    singleData = null,
    setSingleData = () => {}
  } = props
  const [selectedImage, setSelectedImage] = useState([])
  const md = new MarkdownIt() // this is used to convert textToHtml
  const [signedThumbnailList, setSignedThumbnailList] = useState([])
  const [addNewProjectDialog, setAddNewProjectDialog] = useState(false)
  const [projectNames, setProjectNames] = useState([])
  const [selectedProject, setSelectedProject] = useState('')
  const [addProjectLoading, setAddProjectLoading] = useState(false)
  const [deleteProjectDialog, setDeleteProjectDialog] = useState(false)
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false)
  const [selectedClientId, setSelectedClientId] = useState('')
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAllProjectIds()
        if (res.status === 200) {
          const results = res?.data?.project
          const options = results.map((project) => ({
            label: project?.title,
            value: project?.content_ids,
            name: project?.id
          }))
          setProjectNames(options)
        }
      } catch (error) {
        console.error('Error fetching project ids:', error)
      }
    }

    fetchData()
  }, [])

  const handleClickOpen = () => {
    setAddNewProjectDialog(true)
  }

  const handleClose = () => {
    if (deleteProjectLoading) {
      return
    }
    setAddNewProjectDialog(false)
    setDeleteProjectDialog(false)
    setSelectedClientId('')
  }

  const handleDeleteProjectOpen = (index) => {
    if (index === 0) {
      setSelectedClientId(data?.client_id)
    } else {
      setSelectedClientId(topVariatonList[index - 1]?.client_id)
    }
    setDeleteProjectDialog(true)
  }

  const handleProjectChange = (_, value) => {
    setSelectedProject(value)
  }

  const handleAddProject = async () => {
    setAddProjectLoading(true)
    const addProjectData = {
      client_name: data?.client_name,
      client_group_id: data?.client_group_id,
      project_collection_id: selectedProject?.name
    }
    handleClose()
    const res = await addProjectToClient(addProjectData)
    if (res.status === 200) {
      topVariatonList.push({
        client_id: res?.data?.client_id,
        proposal_id: res?.data?.proposal_id,
        proposal_name: res?.data?.proposal_name?.proposal_name,
        proposal_url: res?.data?.proposal_url,
        source: 'added_from_client',
        title: data?.client_name,
        domain_id: res?.data?.domain_id,
        data: {
          client_name: data?.client_name,
          client_contact: '',
          client_location: '',
          project_name: selectedProject?.label,
          collection_id: selectedProject?.name
        }
      })
      if (!singleData) {
        dispatch(
          fetchClientsList(
            {
              client_id: res?.data?.client_id,
              data: {
                client_name: data?.client_name,
                proposal_name: res?.data?.proposal_name?.proposal_name,
                collection_id: selectedProject?.name
              },
              proposal_id: res?.data?.proposal_id,
              proposal_url: res?.data?.proposal_url,
              project_name: selectedProject?.label,
              project_collection_id: selectedProject?.name,
              client_group_id: data?.client_group_id
            },
            'update'
          )
        )
      }
      const urlString = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${res?.data?.domain_id}/${res?.data?.proposal_id}/original/1.png`
      console.log('urlString: ', urlString)
      gets3Urls([...thumbnail_url_list, urlString], s3Obj)
      thumbnail_url_list.push(urlString)
    }
    setAddProjectLoading(false)
  }

  const handleDeleteProject = async () => {
    setDeleteProjectLoading(true)
    const deleteProjectData = {
      client_id: selectedClientId,
      client_group_id: data?.client_group_id
    }

    const res = await deleteProjectFromClient(deleteProjectData)
    if (res.status === 200) {
      if (data?.client_id === selectedClientId) {
        data.hidden = true
        if (!singleData) {
          dispatch(
            fetchClientsList(
              {
                client_group_id: data?.client_group_id,
                hidden: true
              },
              'update'
            )
          )
        }
        data.hidden = true
      } else {
        const index = topVariatonList.findIndex(
          (variation) => variation.client_id === selectedClientId
        )
        topVariatonList[index].hidden = true
      }
    }
    setSelectedClientId('')
    setDeleteProjectLoading(false)
    handleClose()
  }

  const gets3Urls = async (images, s3Obj) => {
    const newImagesUrl = []

    try {
      await Promise.all(
        images.map(async (imageUrl) => {
          const signedSrc = await checkExpiry(
            imageUrl?.src ? imageUrl?.src : imageUrl,
            s3Obj
          )
          newImagesUrl.push(signedSrc)
        })
      )
      const thumbnailList = []
      newImagesUrl.forEach((image, index) => {
        thumbnailList.push({
          screenShot: image,
          proposalName: originProposalNames[index]
        })
      })

      setSignedThumbnailList(thumbnailList)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    if (thumbnail_url_list.length > 0) {
      gets3Urls(thumbnail_url_list, s3Obj)
    }
  }, [thumbnail_url_list, s3Obj])

  const convertToHTML = (markdownText) => {
    let format = markdownText.replace(/\*/g, '\n+')
    format = format.replace(/<mark>/g, '')
    format = format.replace(/<\/mark>/g, '')
    return md.render(format) // this is used to convert textToHtml
  }

  const VariationIndexChange = (index) => {
    handleVariationIndexChange(index - 1)
  }

  const {
    client_name = '',
    client_contact = '',
    client_location = '',
    project_name = '',
    proposal_name = '',
    collection_id = ''
  } = data

  const renderObjectProperties = (object) => {
    return Object.keys(object).map((key) => (
      <div key={key}>
        {object?.[key] && (
          <>
            <Text
              text={_.startCase(key) + ' - '}
              style={{ fontWeight: 'bold' }}
            />
            {typeof object[key] === 'object' ? (
              renderObjectProperties(object[key])
            ) : (
              <Text text={object[key] + '<br/>'} />
            )}
          </>
        )}
      </div>
    ))
  }

  const viewImage = (image) => {
    setSelectedImage(image)
  }

  const downloadImage = async (payload) => {
    const { proposalName, screenShot } = payload
    const name = proposalName
      ? proposalName.split('.').slice(0, -1).join('.')
      : 'image'
    await initalizeDownload(screenShot, name + '.png', s3Obj)
  }

  return openType === 'search_results' ? (
    <></>
  ) : loading ? (
    <Loader loading={loading} caption={''} flex />
  ) : (
    <>
      <div
        style={{
          width: '70%',
          margin: 'auto',
          padding: '20px'
        }}
      >
        {!singleData && (
          <Box
            onClick={handleCloseProject}
            sx={{
              fontWeight: '600',
              fontSize: '16px',
              color: '#0645AD',
              cursor: 'pointer',
              display: 'flex',
              paddingBottom: '10px'
            }}
          >
            <ArrowBackIosIcon /> {'Back'}
          </Box>
        )}
        {openProject && (
          <div>
            <div
              style={{
                border: '1px solid #8c8c8c',
                borderBottom: '0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  height: '100px',
                  width: '100%',
                  background:
                    'linear-gradient(90deg, rgba(92,104,0,1) 25%, rgba(61,78,77,1) 50%, rgba(127,34,156,1) 75%, rgba(255,0,0,1) 100%)'
                }}
              ></div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '70px',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <div
                  style={{
                    padding: '10px',
                    transform: 'translate(0%, -40%)'
                  }}
                >
                  <Avatar {...stringAvatar(client_name)} variant="rounded" />
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: 'calc(50% - 100px)',
                    transform: 'translateX(-50%)'
                  }}
                >
                  {client_name && client_name !== 'N/A' && (
                    <Text
                      text={client_name + '<br/>'}
                      style={{ fontWeight: 'bold', fontSize: '25px' }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                border: '1px solid #8c8c8c',
                borderBottom: '0px'
              }}
            >
              <div
                style={{
                  display: 'flex'
                }}
              >
                <div
                  style={{
                    borderBottom: '3px solid blue',
                    padding: '10px'
                  }}
                >
                  <Text text={'Projects'} style={{ fontWeight: 'bold' }} />
                </div>
              </div>

              {
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {signedThumbnailList?.map((project, index) => {
                    if (data.hidden || topVariatonList[index - 1]?.hidden) {
                      return null
                    }
                    return (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          borderTop: '1px solid #8c8c8c',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          <Box
                            sx={{ padding: '10px' }}
                            id={'project_' + index}
                            key={'project_' + index}
                          >
                            <Box
                              sx={{
                                justifyContent: 'center',
                                height: '140px',
                                width: '110px',
                                border: '2px solid',
                                borderColor: '#E5E5E5',
                                display: 'flex',
                                position: 'relative',
                                cursor: 'pointer',
                                '& .overlayButtons': {
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  backgroundColor: 'inherit',
                                  width: '100%'
                                },
                                '&:hover img': {
                                  opacity: '0.5'
                                },
                                '&:hover .overlayButtons': {
                                  opacity: '1 !important'
                                }
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <ImageRender
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                    overflow: 'hidden'
                                  }}
                                  src={project?.screenShot}
                                  s3Obj={s3Obj}
                                  effect="blur"
                                  overlay={
                                    <Box className="overlayButtons">
                                      {project.screenShot && (
                                        <>
                                          <Tooltip title="View Screenshot">
                                            <IconButton
                                              disableRipple
                                              onClick={() =>
                                                viewImage([
                                                  { src: project.screenShot }
                                                ])
                                              }
                                            >
                                              <ImageIcon />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Download Screenshot">
                                            <IconButton
                                              disableRipple
                                              onClick={() =>
                                                downloadImage(project)
                                              }
                                            >
                                              <DownloadIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      )}
                                    </Box>
                                  }
                                  fallback={
                                    <>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          wordBreak: 'break-word'
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            fontWeight: '600',
                                            fontSize: '16px'
                                          }}
                                        >
                                          {project_name}
                                        </Box>
                                      </Box>
                                    </>
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>
                          <div>
                            {index === 0 ? (
                              <>
                                {project_name && project_name !== 'N/A' && (
                                  <Box
                                    sx={{
                                      marginTop: '8px'
                                    }}
                                  >
                                    <Text
                                      text={'Project Name : '}
                                      style={{ fontWeight: 'bold' }}
                                    />
                                    <Text
                                      text={project_name + ' '}
                                      onClick={() => {
                                        if (collection_id) {
                                          window.open(
                                            `/library/projects/${collection_id}`,
                                            '_blank'
                                          )
                                        }
                                      }}
                                      style={{
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        textDecorationLine: 'underline'
                                      }}
                                    />
                                  </Box>
                                )}
                                {client_contact && client_contact !== 'N/A' && (
                                  <Box
                                    sx={{
                                      marginTop: '8px'
                                    }}
                                  >
                                    <Text
                                      text={'Client Contact : '}
                                      style={{ fontWeight: 'bold' }}
                                    />
                                    <Text
                                      text={client_contact + '<br/>'}
                                      style={{ fontStyle: 'italic' }}
                                    />
                                  </Box>
                                )}
                                {client_location &&
                                  client_location !== 'N/A' && (
                                    <Box
                                      sx={{
                                        marginTop: '8px'
                                      }}
                                    >
                                      <Text
                                        text={'Client Location : '}
                                        style={{ fontWeight: 'bold' }}
                                      />
                                      <Text
                                        text={client_location + '<br/>'}
                                        style={{ fontStyle: 'italic' }}
                                      />
                                    </Box>
                                  )}
                                {proposal_name && proposal_name !== 'N/A' && (
                                  <Box
                                    sx={{
                                      marginTop: '8px'
                                    }}
                                  >
                                    <Text
                                      text={'Proposal : '}
                                      style={{ fontWeight: 'bold' }}
                                    />
                                    <Text
                                      text={proposal_name + '<br/>'}
                                      onClick={() => handlePDFViewer(index)}
                                      style={{
                                        cursor: 'pointer',
                                        textDecorationLine: 'underline',
                                        fontStyle: 'italic'
                                      }}
                                    />
                                  </Box>
                                )}
                              </>
                            ) : (
                              <>
                                {topVariatonList[index - 1]?.data
                                  ?.project_name &&
                                  topVariatonList[index - 1]?.data
                                    ?.project_name !== 'N/A' && (
                                    <Box
                                      sx={{
                                        marginTop: '8px'
                                      }}
                                    >
                                      <Text
                                        text={'Project Name : '}
                                        style={{ fontWeight: 'bold' }}
                                      />
                                      <Text
                                        text={
                                          topVariatonList[index - 1]?.data
                                            ?.project_name + ' '
                                        }
                                        onClick={() => {
                                          if (
                                            topVariatonList[index - 1]?.data
                                              ?.collection_id
                                          ) {
                                            window.open(
                                              `/library/projects/${
                                                topVariatonList[index - 1]?.data
                                                  ?.collection_id
                                              }`,
                                              '_blank'
                                            )
                                          }
                                        }}
                                        style={{
                                          fontWeight: 'bold',
                                          cursor: 'pointer',
                                          textDecorationLine: 'underline'
                                        }}
                                      />
                                    </Box>
                                  )}
                                {topVariatonList[index - 1]?.data
                                  ?.client_contact &&
                                  topVariatonList[index - 1]?.data
                                    ?.client_contact !== 'N/A' && (
                                    <Box
                                      sx={{
                                        marginTop: '8px'
                                      }}
                                    >
                                      <Text
                                        text={'Client Contact : '}
                                        style={{ fontWeight: 'bold' }}
                                      />
                                      <Text
                                        text={
                                          topVariatonList[index - 1]?.data
                                            ?.client_contact + '<br/>'
                                        }
                                        style={{ fontStyle: 'italic' }}
                                      />
                                    </Box>
                                  )}
                                {topVariatonList[index - 1]?.data
                                  ?.client_location &&
                                  topVariatonList[index - 1]?.data
                                    ?.client_location !== 'N/A' && (
                                    <Box
                                      sx={{
                                        marginTop: '8px'
                                      }}
                                    >
                                      <Text
                                        text={'Client Location : '}
                                        style={{ fontWeight: 'bold' }}
                                      />
                                      <Text
                                        text={
                                          topVariatonList[index - 1]?.data
                                            ?.client_location + '<br/>'
                                        }
                                        style={{ fontStyle: 'italic' }}
                                      />
                                    </Box>
                                  )}
                                {topVariatonList[index - 1]?.proposal_name &&
                                  topVariatonList[index - 1]?.proposal_name !==
                                    'N/A' && (
                                    <Box
                                      sx={{
                                        marginTop: '8px'
                                      }}
                                    >
                                      <Text
                                        text={'Proposal : '}
                                        style={{ fontWeight: 'bold' }}
                                      />
                                      <Text
                                        text={
                                          topVariatonList[index - 1]
                                            ?.proposal_name + '<br/>'
                                        }
                                        onClick={() => handlePDFViewer(index)}
                                        style={{
                                          cursor: 'pointer',
                                          textDecorationLine: 'underline',
                                          fontStyle: 'italic'
                                        }}
                                      />
                                    </Box>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            padding: '10px'
                          }}
                        >
                          <Tooltip title="Remove Project">
                            <CloseIcon
                              onClick={() => handleDeleteProjectOpen(index)}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            </div>
          </div>
        )}
        <div
          style={{
            border: '1px solid #8c8c8c',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '15px 0px'
          }}
        >
          {addProjectLoading ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Loader
                loading={addProjectLoading}
                caption={'adding a project'}
              />
            </Box>
          ) : (
            <Box
              onClick={handleClickOpen}
              sx={{
                fontWeight: '600',
                fontSize: '16px',
                color: '#0645AD',
                cursor: 'pointer',
                display: 'flex'
              }}
            >
              <Button variant="contained">
                <AddIcon /> {'Add a project'}
              </Button>
            </Box>
          )}
        </div>
      </div>
      {selectedImage.length > 0 && (
        <ImageGallery
          images={selectedImage}
          onClose={() => setSelectedImage([])}
          s3Obj={s3Obj}
        />
      )}
      <Dialog
        open={addNewProjectDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            width: '700px',
            padding: '10px'
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">{'Add new project'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Choose a project from project collection -
            <Autocomplete
              value={selectedProject}
              onChange={handleProjectChange}
              options={projectNames}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  padding: '0px 3.5px',
                  maxWidth: '-webkit-fill-available',
                  marginTop: '10px'
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: 'revert'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a Project"
                  type="text"
                  variant="outlined"
                  label=""
                />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddProject} autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteProjectDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            width: '700px',
            padding: '10px'
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">{'Delete a project'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete this project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={deleteProjectLoading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={deleteProjectLoading}
            onClick={handleDeleteProject}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ClientResult
