import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor()
  return {
    container: {
      height: '100%',
      width: '100%',
      display: 'flex',
      color: '#797979'
    },
    sidePane: {
      borderRight: '1px solid rgb(229, 229, 229)',
      height: '100%'
    },
    mainPane: {
      width: '100%',
      height: '100%'
    },
    mainPaneContainer: {
      height: '100%',
      width: 'calc(100% - 25px)',
      marginLeft: '25px'
    },
    sidePaneContainer: {
      marginTop: '30px',
      padding: '10px'
    },
    listWrapper: {
      padding: '0px',
      width: '100%',
      '& .Mui-selected': {
        backgroundColor: '#DBEAFF'
      }
    },
    listButtonWrapper: {
      padding: '0px'
    },
    listTextWrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 5px',
      '& input': {
        padding: '0.5px 5px',
        color: '#797979'
      },
      '& input:focus': {
        background: 'transparent !important'
      },
      '& .MuiSvgIcon-root': {
        fontSize: '18px'
      }
    },
    listText: {
      marginLeft: '5px',
      fontWeight: 500,
      width: '100%',
      wordBreak: 'break-word',
      fontSize: '15px',
      color: '#797979',
      flex: '1',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical'
    },
    secondaryColor: {
      color: selectedColor
    },
    allItem: {
      marginBottom: '10px'
    }
  }
})

export { useStyles }
