import { FETCH_DOMAIN_INVITES } from '../../../actionTypes'
import { getUserInvites } from '../../../api'
import { toast } from 'react-toastify'

const fetchUserInvites = () => async (dispatch) => {
  const res = await getUserInvites()
  if (res.status === 200) {
    const invites = res.data?.UNEXPIRED_INVITE
    dispatch({
      type: FETCH_DOMAIN_INVITES,
      payload: invites
    })
  }
}

export { fetchUserInvites }
