import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
  container: {
    /* borderLeft: '1px solid #EEEEEE', */
    // height: '100%',
    wordBreak: 'break-all',
    // overflow: 'auto',
    '& .Mui-expanded': {
      margin: '1px'
    },
    '& .MuiPaper-root': {
      borderRadius: '0px',
      borderColor: '#EEEEEE',
      boxShadow:
        '0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    },
    '& .Mui-disabled': {
      backgroundColor: 'inherit'
    }
  },
  proposalTitle: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#000000',
    cursor: 'pointer',
    fontSize: '14px'
  },
  proposalTitlePage: {
    color: '#949494',
    fontSize: '14px'
  },
  emptyVariationMessage: {
    width: '100%',
    textAlign: 'center',
    marginTop: '25px'
  },
  variationCardWrapper: {
    padding: '10px',
    border: '1px solid #e0e0e0',
    borderRadius: '5px',
    marginTop: '10px',
    cursor: 'pointer',
    '&:hover': {
      background: '#f5f5f5'
    }
  },
  accordianTitle: {
    color: '#949494',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'PoppinsRegular'
  },
  variationTitle: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'PoppinsRegular',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  variationContent: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'PoppinsRegular'
  },
  variationContainer: {
    color: '#00000066',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'PoppinsRegular',
    margin: '10px 0px',
    overflow: 'auto'
  },
  /*  proposalContainer:{
     color: "#00000066",
     fontSize: "14px",
     fontWeight: "bold",
     fontFamily: 'PoppinsRegular',
   } */
  proposalContainer: {
    border: '1px solid #aca9a966',
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '6px'
  }
}))

export { useStyles }
