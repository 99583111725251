import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor()
  return {
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      borderColor: selectedColor,
      color: selectedColor,
      fontFamily: 'PoppinsRegular',
      borderRadius: '6px',
      cursor: 'pointer',
      padding: '4px 10px'
    },
    buttonText: {
      fontFamily: 'PoppinsRegular',
      color: selectedColor,
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      margin: '0px',
      textTransform: 'capitalize'
    }
  }
})
export { useStyles }
