import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
  rootContainer: {
    '& .MuiInputBase-root': {
      '& .MuiSelect-multiple': {
        paddingTop: '3px ',
        paddingBottom: '3px ',
        fontSize: '14px',
        background: 'white'
      }
    }
  },
  menuItems: {
    borderBottom: '1px solid #D9D9D9',
    /* whiteSpace: "normal !important", */
    paddingRight: '10px',
    padding: 0,
    '& >div >span': {
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important'
    }
  },
  fullWidthContainer: {
    width: '100% !important'
  },
  autoWidth: (props) => ({
    minWidth: 60,
    maxWidth: props.width ? props.width : 100,
    margin: 0
  }),
  textSearch: {
    padding: '0 3px',
    width: 'calc(100% - 6px)'
  },
  tooltip: {
    background: 'black',
    fontSize: '15px',
    width: '100%'
  },
  listItemText: {
    '& > span': {
      fontSize: '14px'
    }
  }
}))

export { useStyles }
