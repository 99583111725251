import store from '../../store/store'
import { setAnalyticsComplianceData } from '../../store/AnalyticsCompliance/Actions'
import { posthog } from 'posthog-js'

const getFileId = (fileUrl = '') => {
  const filename = fileUrl.substring(fileUrl.lastIndexOf('/') + 1)
  const requiredSubstring = filename
    .substring(0, filename.lastIndexOf('.'))
    .slice(-36)
  return requiredSubstring
}

const trackEvent = (
  eventName = '',
  eventStatus = '',
  response = {},
  additionalParams = {}
) => {
  const storeValue = store.getState()
  store.dispatch(
    setAnalyticsComplianceData({
      ...(additionalParams?.compliance_id
        ? { rfx_id: additionalParams?.compliance_id }
        : {})
    })
  )
  if (process.env.REACT_APP_ENV === 'production') {
    // const { authenticate: { user: { domain: { domain_id = "", id: userId = "", email = "", domain_name = "", user_name = "" } } } = {} } = storeValue
    const {
      authenticate: {
        user: {
          domain: {
            domain_id = '',
            id: userId = '',
            email = '',
            domain_name = '',
            user_name = ''
          } = {}
        } = {}
      } = {}
    } = storeValue

    let eventData = {
      userId,
      'Domain Id': domain_id,
      'Event Status': eventStatus,
      email,
      'Domain Name': domain_name,
      user_name
      /* 'Response': response */
    }
    eventData = Object.assign(eventData, additionalParams)
    posthog.identify(userId, {
      domain_name
    })
    posthog.capture(eventName, eventData)
  } else if (process.env.REACT_APP_ENV === 'staging') {
    // const { authenticate: { user: { domain: { domain_id = "", id: userId = "", email = "", domain_name = "", user_name = "" } } } = {} } = storeValue
    const {
      authenticate: {
        user: {
          domain: {
            domain_id = '',
            id: userId = '',
            email = '',
            domain_name = '',
            user_name = ''
          } = {}
        } = {}
      } = {}
    } = storeValue

    const currentURL = window.location.href
    const url = new URL(currentURL)
    const hostname = url.hostname

    let eventData = {
      userId,
      'Domain Id': domain_id,
      'Event Status': eventStatus,
      email,
      'Domain Name': domain_name,
      user_name,
      hostname
      /* 'Response': response */
    }
    eventData = Object.assign(eventData, additionalParams)
    posthog.identify(userId, {
      domain_name
    })
    posthog.capture(eventName, eventData)
  }
}

export default trackEvent
