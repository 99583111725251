import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useStyles } from './styles'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'

const useConfirmation = () => {
  const [confirmConfig, setConfirmConfig] = useState(null)
  const [isConfirmVisible, setConfirmVisible] = useState(false)
  const classes = useStyles()
  const {
    actionContianer,
    cancelButton,
    submitButton,
    dialogHeader,
    dialogContents,
    contentWrapper
  } = classes

  const showConfirmDialog = (props) => {
    const {
      onConfirm,
      onCancel,
      confirmationMessageTitle = 'Are you sure?',
      confirmationMessage = '',
      confirmButtonText = "Yes, I'm sure",
      cancelButtonText = 'Cancel',
      extraButtons = []
    } = props || {}
    setConfirmConfig({
      onConfirm,
      onCancel,
      confirmationMessageTitle,
      confirmationMessage,
      confirmButtonText,
      cancelButtonText,
      extraButtons
    })
    setConfirmVisible(true)
  }

  const {
    onConfirm = () => {},
    onCancel = () => {},
    confirmationMessageTitle = 'Are you sure?',
    confirmationMessage = '',
    confirmButtonText = "Yes, I'm sure",
    cancelButtonText = 'No',
    extraButtons = []
  } = confirmConfig || {}

  const handleConfirm = () => {
    onConfirm()
    setConfirmVisible(false)
  }

  const handleCancel = () => {
    onCancel()
    setConfirmVisible(false)
  }

  return {
    isConfirmVisible,
    showConfirmDialog,
    ConfirmDialog: (
      <Dialog open={isConfirmVisible} onClose={handleCancel}>
        <Grid className={contentWrapper}>
          <DialogTitle id="responsive-dialog-title" className={dialogHeader}>
            {confirmationMessageTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={dialogContents}>
              {confirmationMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className={actionContianer}>
              <Button
                id="cancel-action"
                onClick={(e) => {
                  e.stopPropagation()
                  handleCancel()
                }}
                className={cancelButton}
              >
                {cancelButtonText}
              </Button>
              <Button
                id="submit-action"
                onClick={(e) => {
                  e.stopPropagation()
                  handleConfirm()
                }}
                className={submitButton}
              >
                {confirmButtonText}
              </Button>
              {extraButtons.map((button, index) => (
                <Button
                  key={index}
                  id={button.id}
                  onClick={(e) => {
                    e.stopPropagation()
                    button.onClick()
                    setConfirmVisible(false)
                  }}
                  className={submitButton}
                >
                  {button.text}
                </Button>
              ))}
            </div>
          </DialogActions>
        </Grid>
      </Dialog>
    )
  }
}

export default useConfirmation
