import React from 'react'
import { Resizable } from 'react-resizable'
import { useStyles } from './styles'

const convertToPercentage = (width, parentWidth) => {
  return (width * 100) / parentWidth
}

const CustomResizeHandle = React.forwardRef((props, ref2) => {
  const classes = useStyles()
  const {
    handleAxis,
    id,
    isResizing,
    isHovering,
    setIsHovering,
    setIsMenuHovering,
    ...restProps
  } = props

  let background = 'transparent'
  if (isResizing === id) {
    background = '#E5E5E5'
  }
  if (isHovering === id && !isResizing) {
    background = '#E5E5E5'
  }

  return (
    <div
      onMouseEnter={() => {
        setIsHovering(id)
        setIsMenuHovering(false)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
        setIsMenuHovering(false)
      }}
      className={classes.customResizeHandler}
      style={{ background }}
      ref={ref2}
      {...restProps}
    ></div>
  )
})

const ResizableCell = ({
  parentWidth,
  tableComponent,
  id,
  setIsResizing,
  isHovering,
  setIsHovering,
  isResizing,
  isMenuHovering,
  setIsMenuHovering = () => {},
  onResize,
  width,
  ...rest
}) => {
  const classes = useStyles()
  let resizeWidth = width
  resizeWidth = (parentWidth * parseInt(width.replace('%', ''))) / 100

  const styles = {
    width,
    borderRight: id === 'document_name' ? '1px solid #E5E5E5' : 'none'
  }
  if (isMenuHovering) {
    styles.backgroundColor = '#E5E5E5'
  }

  return (
    <Resizable
      width={resizeWidth}
      height={0}
      axis="x"
      onResize={(_, { size }) =>
        onResize(convertToPercentage(size.width, parentWidth))
      }
      resizeHandles={['e']}
      onResizeStart={() => {
        setIsResizing(id)
      }}
      onResizeStop={() => {
        setTimeout(() => {
          setIsResizing(false)
        }, 100)
      }}
      handle={
        <CustomResizeHandle
          id={id}
          isResizing={isResizing}
          isHovering={isHovering}
          setIsHovering={setIsHovering}
          setIsMenuHovering={setIsMenuHovering}
        />
      }
    >
      {tableComponent === 'row' ? (
        <td {...rest} className={classes.tableRow} style={styles} />
      ) : (
        <th {...rest} className={classes.tableHeader} style={styles} />
      )}
    </Resizable>
  )
}

export default ResizableCell
