import React, { useEffect, useState } from 'react'
import { Button, useConfirmation, Loader, TextInput } from '../../components'
import { toast } from 'react-toastify'
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  TextField,
  Checkbox
} from '@mui/material'
import _, { set } from 'lodash'
import { useStyles } from './styles'
import { useSelector } from 'react-redux'
import { postChatVoiceConfigs, getChatVoiceConfigs } from '../../store/api'
import { Container, Section, SectionFixed } from '../../components/Container'
import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import SwapVertIcon from '@mui/icons-material/SwapVert'

const CasualvsFormal = [
  ['Casual', 'Formal'],
  ['Familiar', 'Professional'],
  ['Approachable', 'Distanced'],
  ['Conversational', 'Dry'],
  ['Friendly', 'Conservative']
]
const Tone = [
  'Authoritative',
  'Trustworthy',
  'Confident',
  'Motivating',
  'Supportive',
  'Aspirational',
  'Informative',
  'Analytical',
  'Empowering',
  'Journalistic',
  'Serious',
  'Objective',
  'Persuasive',
  'Concise',
  'Straightforward',
  'Dynamic'
]
const Personality = [
  'Funny',
  'Witty',
  'Snarky',
  'Sarcastic',
  'Playful',
  'Clever',
  'Irreverent',
  'Edgy'
]
const Perspective = ['First Person', 'Second Person', 'Third Person']
const StyleGuide = ['AP Style', 'Chicago Manual of Style']
const Casual = [
  'Casual',
  'Familiar',
  'Approachable',
  'Conversational',
  'Friendly'
]
const Formal = ['Formal', 'Professional', 'Distanced', 'Dry', 'Conservative']

const SortLibrary = (props) => {
  const { filterBy = [], setFilterBy = () => {}, disabled = false } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!disabled) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const filterOptions = [
    {
      key: 'title',
      order: 'asc',
      label: 'Name (A-Z)'
    },
    {
      key: 'title',
      order: 'desc',
      label: 'Name (Z-A)'
    },
    {
      key: 'created_at',
      order: 'desc',
      label: 'Created Date (Newest)'
    },
    {
      key: 'created_at',
      order: 'asc',
      label: 'Created Date (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'asc',
      label: 'Last Modified (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'desc',
      label: 'Last Modified (Newest)'
    }
  ]

  const handleFilter = (e, filter) => {
    e.stopPropagation()
    e.preventDefault()
    setFilterBy(filter)
    handleClose()
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <IconButton
        className={classes.iconButton}
        disableRipple
        onClick={handleClick}
        disabled={disabled}
      >
        <SwapVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.hideMenu}
      >
        {filterOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={
              filterBy.key === option.key && filterBy.order === option.order
            }
            onClick={(e) =>
              handleFilter(e, { key: option.key, order: option.order })
            }
          >
            <Box className={classes.hideMenuListText}>{option.label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

const ChatVoiceSettings = ({ isFocused }) => {
  const [loading, setLoading] = useState(true)
  const [voiceConfigs, setVoiceConfigs] = useState(null)
  const [filteredVoiceConfigs, setFilteredVoiceConfigs] = useState([])
  const [showModel, setShowModel] = useState(false)
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { user_name } = domain || {}
  const classes = useStyles()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [selectedPosition, setSelectedPosition] = useState(null)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState({
    key: 'created_at',
    order: 'desc'
  })

  useEffect(() => {
    const fetchData = async () => {
      if (voiceConfigs === null && isFocused) {
        const res = await getChatVoiceConfigs()
        if (res.status === 200) {
          setVoiceConfigs(res.data)
          setLoading(false)
        } else {
          toast.error('Unable to fetch voice configs')
        }
      }
    }
    fetchData()
  }, [isFocused])

  const sortData = (data) => {
    return data.sort((a, b) => {
      const key = sort.key
      let order = sort.order
      let comparisonA = a[key]
      let comparisonB = b[key]
      if (key === 'created_at' || key === 'updated_at') {
        comparisonA = new Date(a[key])
        comparisonB = new Date(b[key])
        if (comparisonA.getTime() === comparisonB.getTime()) {
          comparisonA = a.title?.toLowerCase()
          comparisonB = b.title?.toLowerCase()
          order = 'asc'
        }
      } else if (key === 'title') {
        comparisonA = a[key]?.toLowerCase()
        comparisonB = b[key]?.toLowerCase()
      }
      if (comparisonA < comparisonB) {
        return order === 'desc' ? 1 : -1
      }
      if (comparisonA > comparisonB) {
        return order === 'desc' ? -1 : 1
      }
      return 0
    })
  }

  useEffect(() => {
    if (!voiceConfigs) {
      return
    }
    let filtered = [...voiceConfigs]
    filtered = filtered.filter((item) => {
      if (search === '') {
        return true
      }
      return item.title.toLowerCase().includes(search.toLowerCase())
    })
    filtered = sortData(filtered)
    setFilteredVoiceConfigs(filtered)
  }, [search, sort, voiceConfigs])

  const handleSave = async () => {
    if (!showModel?.title) {
      toast.error('Please enter a name for your voice')
      return
    }
    const voice = showModel?.voice
    if (!voice?.traits?.length || voice?.traits?.length < 3) {
      toast.error('Please select atleast three trait')
      return
    }
    if (!voice?.perspective) {
      toast.error('Please select a perspective')
      return
    }
    if (!voice?.style_guide) {
      toast.error('Please select a style guide')
      return
    }
    setShowModel(false)
    const mode = showModel?.id ? 'update' : 'create'
    let req = {
      ...showModel,
      mode,
      id: showModel?.id || uuidv4()
    }
    let oldData = req
    if (mode === 'update') {
      oldData = voiceConfigs.find((item) => item.id === req.id)
      setVoiceConfigs((prevVoiceConfigs) =>
        prevVoiceConfigs.map((item) =>
          item.id === req.id ? { ...req, loading: true } : item
        )
      )
    } else {
      req = {
        ...req,
        updated_at: moment.utc(),
        created_by_user: user_name
      }
      setVoiceConfigs((prevVoiceConfigs) => [
        ...prevVoiceConfigs,
        {
          ...req,
          loading: true
        }
      ])
    }
    const res = await postChatVoiceConfigs(req)
    if (res.status === 200) {
      if (mode === 'create') {
        setVoiceConfigs((prevVoiceConfigs) =>
          prevVoiceConfigs.map((item) =>
            item.id === req.id ? { ...item, loading: false } : item
          )
        )
      } else {
        setVoiceConfigs((prevVoiceConfigs) =>
          prevVoiceConfigs.map((item) =>
            item.id === req.id
              ? { ...item, loading: false, updated_by_user: user_name }
              : item
          )
        )
      }
    } else {
      toast.error('Something went wrong')
      if (mode === 'update') {
        setVoiceConfigs((prevVoiceConfigs) =>
          prevVoiceConfigs.map((item) =>
            item.id === req.id ? { ...oldData, loading: false } : item
          )
        )
      } else {
        setVoiceConfigs((prevVoiceConfigs) =>
          prevVoiceConfigs.filter((item) => item.id !== req.id)
        )
      }
    }
  }

  const handleTrait = (trait, position = null, disabled = false) => {
    if (disabled) return
    let traits = showModel?.voice?.traits || []
    if (traits.includes(trait)) {
      traits = traits.filter((item) => item !== trait)
      if (position) {
        const casualformalTraits = traits.filter((item) =>
          CasualvsFormal.flat().includes(item)
        )
        if (casualformalTraits.length < 1) {
          setSelectedPosition(null)
        }
      }
    } else {
      if (traits.length < 5) {
        traits.push(trait)
        if (position) {
          setSelectedPosition(position)
        }
      }
    }
    setShowModel({
      ...showModel,
      voice: {
        ...showModel.voice,
        traits
      }
    })
  }

  const handlePerspective = (perspective) => {
    if (showModel?.voice?.perspective === perspective) {
      setShowModel({
        ...showModel,
        voice: {
          ...showModel.voice,
          perspective: ''
        }
      })
    } else {
      setShowModel({
        ...showModel,
        voice: {
          ...showModel.voice,
          perspective
        }
      })
    }
  }

  const handleStyleGuide = (style_guide) => {
    if (showModel?.voice?.style_guide === style_guide) {
      setShowModel({
        ...showModel,
        voice: {
          ...showModel.voice,
          style_guide: ''
        }
      })
    } else {
      setShowModel({
        ...showModel,
        voice: {
          ...showModel.voice,
          style_guide
        }
      })
    }
  }

  const getButtonClass = (isSelected, disabled = null) => {
    if (!disabled) {
      disabled = showModel?.voice?.traits?.length >= 5
    }
    return isSelected
      ? clsx(classes.traitbtn, classes.traitbtnSelected)
      : disabled
      ? clsx(classes.traitbtn, classes.disabledOpacity)
      : classes.traitbtn
  }
  const getButtonClassPerspective = (isSelected) => {
    const disabled = showModel?.voice?.perspective
    return isSelected
      ? clsx(classes.traitbtn, classes.traitbtnSelected)
      : disabled
      ? clsx(classes.traitbtn, classes.disabledOpacity)
      : classes.traitbtn
  }

  const getButtonClassStyleGuide = (isSelected) => {
    const disabled = showModel?.voice?.style_guide
    return isSelected
      ? clsx(classes.traitbtn, classes.traitbtnSelected)
      : disabled
      ? clsx(classes.traitbtn, classes.disabledOpacity)
      : classes.traitbtn
  }

  const handleDelete = async (item) => {
    showConfirmDialog({
      onConfirm: async () => {
        const req = {
          id: item.id,
          mode: 'delete'
        }
        setVoiceConfigs((prevVoiceConfigs) =>
          prevVoiceConfigs.map((item) =>
            item.id === req.id ? { ...item, loading: true } : item
          )
        )
        const res = await postChatVoiceConfigs(req)
        if (res.status === 200) {
          setVoiceConfigs((prevVoiceConfigs) =>
            prevVoiceConfigs.filter((item) => item.id !== req.id)
          )
        } else {
          setVoiceConfigs((prevVoiceConfigs) =>
            prevVoiceConfigs.map((item) =>
              item.id === req.id ? { ...item, loading: false } : item
            )
          )
          toast.error('Unable to delete voice config')
        }
      },
      confirmationMessageTitle: `Are you sure you want to delete voice config "${item.title}" ?`
    })
  }

  const handleSetDefault = async (id, is_default) => {
    const req = {
      mode: 'update',
      id,
      is_default
    }
    setVoiceConfigs((prevVoiceConfigs) =>
      prevVoiceConfigs.map((item) =>
        item.id === req.id ? { ...item, loading: true, is_default } : item
      )
    )

    const res = await postChatVoiceConfigs(req)
    if (res.status === 200) {
      setVoiceConfigs((prevVoiceConfigs) =>
        prevVoiceConfigs.map((item) =>
          item.id === req.id
            ? { ...item, loading: false, is_default }
            : is_default
            ? { ...item, is_default: false }
            : item
        )
      )
    } else {
      toast.error('Something went wrong')
      setVoiceConfigs((prevVoiceConfigs) =>
        prevVoiceConfigs.map((item) =>
          item.id === req.id
            ? { ...item, loading: true, is_default: !is_default }
            : item
        )
      )
    }
  }

  return (
    <Container>
      {loading ? (
        <Loader loading={loading} flex />
      ) : (
        <Box className={classes.childContainer}>
          <Container>
            <SectionFixed>
              <Box
                className={clsx(classes.titleText, classes.gradientTextStyle)}
              >
                Voice
              </Box>
              <Box className={classes.subText}>
                Set up a voice profile that sounds more like you.Apply voices
                when you generate content with apps. You can also add voices to
                rewrite.
              </Box>
              {_.isEmpty(voiceConfigs) ? (
                <Box className={classes.headerWrapper}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowModel({})
                      setSelectedPosition(null)
                    }}
                  >
                    Create your own voice
                  </Button>
                </Box>
              ) : (
                <>
                  <Box className={classes.headerWrapper}>
                    <Box>
                      <Box className={classes.promptSearchWrapper}>
                        <TextField
                          placeholder="Search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <SortLibrary filterBy={sort} setFilterBy={setSort} />
                      </Box>
                    </Box>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowModel({})
                        setSelectedPosition(null)
                      }}
                    >
                      Create new
                    </Button>
                  </Box>
                  <Divider />
                </>
              )}
            </SectionFixed>
            <Section overFlow>
              <Box className={classes.voiceContainer}>
                {!_.isEmpty(voiceConfigs)
                  ? filteredVoiceConfigs.map((item, index) => {
                      const {
                        loading = false,
                        title,
                        updated_at,
                        updated_by_user,
                        is_default = false,
                        id,
                        voice = {}
                      } = item
                      const { traits = [] } = voice
                      return (
                        <Box key={'voices_' + index}>
                          <Box
                            className={
                              loading
                                ? clsx(
                                    classes.voiceWrapper,
                                    classes.disabledOpacity
                                  )
                                : classes.voiceWrapper
                            }
                          >
                            <Box className={classes.flexCol}>
                              <Box className={classes.voiceTitle}>{title}</Box>
                              <Box className={classes.voiceUpdated}>
                                Last updated {moment(updated_at).format('ll')}{' '}
                                {updated_by_user && <>by {updated_by_user}</>}
                              </Box>
                            </Box>
                            <Box
                              className={clsx(
                                classes.flexStart,
                                classes.iconContainer
                              )}
                            >
                              <Tooltip
                                title={
                                  is_default
                                    ? 'Default Voice'
                                    : 'Set as Default'
                                }
                              >
                                <Checkbox
                                  disableRipple
                                  checked={is_default}
                                  disabled={loading}
                                  onChange={(e) => {
                                    handleSetDefault(id, e.target.checked)
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title="Edit">
                                <IconButton
                                  disabled={loading}
                                  disableRipple
                                  onClick={() => {
                                    setShowModel(item)
                                    if (
                                      !_.isEmpty(_.intersection(traits, Casual))
                                    ) {
                                      setSelectedPosition(1)
                                    } else if (
                                      !_.isEmpty(_.intersection(traits, Formal))
                                    ) {
                                      setSelectedPosition(2)
                                    }
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  disabled={loading}
                                  disableRipple
                                  onClick={() => {
                                    handleDelete(item)
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Divider />
                        </Box>
                      )
                    })
                  : !_.isEmpty(voiceConfigs) && (
                      <Box className={classes.noData}>
                        No voice configs found
                      </Box>
                    )}
              </Box>
            </Section>
          </Container>
          <Dialog
            fullScreen
            sx={{ margin: '5vh' }}
            open={!!showModel}
            onClose={() => setShowModel(false)}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }
            }}
          >
            <Box className={classes.dialogWrapper}>
              <Container>
                <Section overFlow>
                  <Box className={classes.dialogTitleWrapper}>
                    <Box>
                      <Box
                        className={clsx(
                          classes.titleText,
                          classes.gradientTextStyle
                        )}
                      >
                        TUNE YOUR VOICE
                      </Box>
                      <Box className={classes.dailogContentSubTitle}>
                        Customize your voice by selecting traits and perspective
                      </Box>
                    </Box>
                    <Box className={classes.dialogButtonWrapper}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setShowModel(false)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleSave()
                        }}
                      >
                        {showModel?.id ? 'Update' : 'Create'} Voice
                      </Button>
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.dailogContent}>
                    <Box className={classes.optionsWrapper}>
                      <Box className={classes.dailogContentTitle}>
                        Name your voice
                      </Box>
                      <TextInput
                        className={clsx(classes.textFeild)}
                        placeholder="Voice name"
                        value={showModel.title || ''}
                        onChange={(e) => {
                          setShowModel({
                            ...showModel,
                            title: e.target.value
                          })
                        }}
                      />
                    </Box>
                    <Divider />
                    <Box className={classes.marginTop}>
                      <Box className={classes.dailogContentTitle}>
                        Select traits
                      </Box>
                      <Box
                        className={clsx(
                          classes.dailogContentSubTitle,
                          classes.flexBetween
                        )}
                      >
                        <Box>
                          Select between 3-5 traits that best represent your
                          brand voice
                        </Box>
                        <Box>
                          {(showModel?.voice?.traits || []).length}/5 Selected
                        </Box>
                      </Box>
                      <Box
                        className={clsx(
                          classes.optionsWrapper,
                          classes.traitWrapper
                        )}
                      >
                        <Box className={classes.trait}>
                          <Box className={classes.traitHeader}>
                            {' '}
                            Casual vs Formal
                          </Box>
                          <Box className={classes.traitContent}>
                            {CasualvsFormal.map((item, index) => {
                              const isSelected1 =
                                showModel?.voice?.traits?.includes(item[0])
                              const isSelected2 =
                                showModel?.voice?.traits?.includes(item[1])
                              const disable1 = selectedPosition
                                ? selectedPosition !== 1
                                : null
                              const disable2 = selectedPosition
                                ? selectedPosition !== 2
                                : null
                              return (
                                <Box key={index} className={classes.flexStart}>
                                  <Box
                                    key={index}
                                    onClick={() => {
                                      handleTrait(item[0], 1, disable1)
                                    }}
                                    className={getButtonClass(
                                      isSelected1,
                                      disable1
                                    )}
                                  >
                                    {item[0]}
                                  </Box>
                                  <Box
                                    key={index}
                                    onClick={() => {
                                      handleTrait(item[1], 2, disable2)
                                    }}
                                    className={getButtonClass(
                                      isSelected2,
                                      disable2
                                    )}
                                  >
                                    {item[1]}
                                  </Box>
                                </Box>
                              )
                            })}
                          </Box>
                        </Box>
                        <Box className={classes.trait}>
                          <Box className={classes.traitHeader}> Tone</Box>
                          <Box
                            className={clsx(
                              classes.traitContent,
                              classes.flexStart
                            )}
                          >
                            {Tone.map((item, index) => {
                              const isSelected =
                                showModel?.voice?.traits?.includes(item)
                              return (
                                <Box
                                  key={index}
                                  onClick={() => {
                                    handleTrait(item)
                                  }}
                                  className={getButtonClass(isSelected)}
                                >
                                  {item}
                                </Box>
                              )
                            })}
                          </Box>
                        </Box>
                        <Box className={classes.trait}>
                          <Box className={classes.traitHeader}>
                            {' '}
                            Personality
                          </Box>
                          <Box
                            className={clsx(
                              classes.traitContent,
                              classes.flexStart
                            )}
                          >
                            {Personality.map((item, index) => {
                              const isSelected =
                                showModel?.voice?.traits?.includes(item)
                              return (
                                <Box
                                  key={index}
                                  onClick={() => {
                                    handleTrait(item)
                                  }}
                                  className={getButtonClass(isSelected)}
                                >
                                  {item}
                                </Box>
                              )
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box className={classes.optionsWrapper}>
                      <Box
                        className={clsx(
                          classes.marginTop,
                          classes.dailogContentTitle
                        )}
                      >
                        Perspective
                      </Box>
                      <Box
                        className={classes.flexStart}
                        sx={{ gap: '30px !important' }}
                      >
                        <Box>
                          <Box className={classes.dailogContentSubTitle}>
                            Customize your point-of-view preferences
                          </Box>
                        </Box>
                        <Box className={classes.flexStart}>
                          {Perspective.map((item, index) => {
                            const isSelected =
                              showModel?.voice?.perspective === item
                            return (
                              <Box
                                key={index}
                                onClick={() => handlePerspective(item)}
                                className={getButtonClassPerspective(
                                  isSelected
                                )}
                              >
                                {item}
                              </Box>
                            )
                          })}
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box className={classes.optionsWrapper}>
                      <Box
                        className={clsx(
                          classes.marginTop,
                          classes.dailogContentTitle
                        )}
                      >
                        Style Guide
                      </Box>
                      <Box
                        className={classes.flexStart}
                        sx={{ gap: '30px !important' }}
                      >
                        <Box>
                          <Box className={classes.dailogContentSubTitle}>
                            Which writing style guide you want to use?
                          </Box>
                        </Box>
                        <Box className={classes.flexStart}>
                          {StyleGuide.map((item, index) => {
                            const isSelected =
                              showModel?.voice?.style_guide === item
                            return (
                              <Box
                                key={index}
                                onClick={() => handleStyleGuide(item)}
                                className={getButtonClassStyleGuide(isSelected)}
                              >
                                {item}
                              </Box>
                            )
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Section>
              </Container>
            </Box>
          </Dialog>
          {ConfirmDialog}
        </Box>
      )}
    </Container>
  )
}

export default ChatVoiceSettings
