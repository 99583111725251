import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@mui/material'
import { useStyles } from './styles'
const SelectRadio = (props) => {
  const {
    id = 'select-radio',
    keyId = 0,
    label = '',
    value = '',
    options = [],
    handleChange,
    className,
    disabled = false,
    ...rest
  } = props
  const classes = useStyles()
  const { inputLabel, field } = classes

  return (
    <FormControl className={field}>
      {label && <FormLabel className={inputLabel}>{label}</FormLabel>}
      <RadioGroup
        row
        value={value}
        onChange={(e) => handleChange(e, keyId)}
        {...rest}
      >
        {options.map((option, key) => {
          const { label, value } = option
          return (
            <FormControlLabel
              disabled={disabled}
              id={id}
              key={key}
              value={value}
              label={label}
              control={<Radio />}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}
export default SelectRadio
