import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import Scanning from './lottie/RFxScanning.json'
import ProposalScanning from './lottie/Proposal.json'
import PainPoint from './lottie/painpoint.json'
import { MinimizeIcon2 } from '../../components/Icons/Icons'
import { Tooltip } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

const SettingUpWorkspace = ({
  StoryBoard,
  settingUpSpace,
  draft,
  painPoints
}) => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const navigate = useNavigate()
  const logsData = [
    {
      timestamp: '2024-08-13T15:21:12.471050',
      log: 'Added RFX and Reference proposals to draft'
    },
    {
      timestamp: '2024-08-13T15:21:12.471076',
      log: 'Storyboard creation initiated'
    },
    {
      timestamp: '2024-08-13T15:21:16.389063',
      log: 'Extracting Proposal Outline'
    },
    {
      timestamp: '2024-08-13T15:21:32.355141',
      log: 'Generating Storyboards'
    },
    {
      timestamp: '2024-08-13T15:22:17.088852',
      log: 'Storyboards Generated Successfully'
    },
    {
      timestamp: '2024-08-13T15:22:17.117897',
      log: 'Inserting Storyboards'
    },
    {
      timestamp: '2024-08-13T15:22:17.161404',
      log: 'Storyboards Inserted Successfully'
    },
    {
      timestamp: '2024-08-13T15:22:17.186282',
      log: 'Generating People Resume Section'
    },
    {
      timestamp: '2024-08-15T12:43:33.515854',
      log: 'Generated People Positions'
    },
    {
      timestamp: '2024-08-13T15:22:26.460360',
      log: 'Generating People Suggestions'
    },
    {
      timestamp: '2024-08-13T15:23:33.276122',
      log: 'Generating Project Resume Section'
    }
  ]

  const options = {
    animationData: Scanning,
    loop: true
  }

  useEffect(() => {
    setCurrentIndex(
      settingUpSpace?.logs?.length === 0 ? 1 : settingUpSpace?.logs?.length
    )
  }, [settingUpSpace?.logs])

  return (
    <div
      style={{
        height: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '100%',
          flex: 1,
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            flex: 1,
            backgroundColor: 'var(--dark-primary)',
            borderLeft: '2px solid #313131'
          }}
        >
          <div
            style={{
              fontSize: '16px',
              color: 'var(--grey-100)',
              padding: '14px',
              borderBottom: '1px solid #313131'
            }}
            className="flex justify-between items-center"
          >
            <p className="m-0">{draft?.draft?.document_name}</p>
          </div>

          {!settingUpSpace.completed ? (
            <div
              className="flex"
              style={{
                padding: '20px',
                // display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                boxSizing: 'border-box',
                gap: '12px',
                background: 'rgba(255, 255, 255, 0.03)',
                height: '100%'
              }}
            >
              {logsData.map((log, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      color: 'var(--grey-300)',
                      fontFamily: 'monospace',
                      fontSize: '13px',
                      padding: 5,
                      display: 'flex',
                      gap: 8,
                      alignItems: 'center'
                    }}
                  >
                    <div
                      className={currentIndex === index ? 'rotate' : ''}
                      style={{
                        height: '6px',
                        width: '6px',
                        borderRadius: '100%',
                        backgroundColor: currentIndex > index && '#22c55e',
                        border:
                          currentIndex === index
                            ? '1px dashed orange'
                            : currentIndex > index
                            ? '1px solid #22c55e'
                            : '1px solid #313131'
                      }}
                    ></div>
                    <div
                      style={{
                        color:
                          index > currentIndex
                            ? 'var(--grey-500)'
                            : 'var(--grey-100)'
                      }}
                    >
                      {log.log}
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div
              style={{
                disply: settingUpSpace?.completed ? 'block' : 'none',
                width: settingUpSpace?.completed && '100%',
                height: 'calc(100% - 53px)'
              }}
            >
              {StoryBoard}
            </div>
          )}
        </div>

        <div
          style={{
            flex: 1,
            backgroundColor: 'var(--dark-primary)'
            // borderLeft: '1px solid #313131'
          }}
        >
          <div
            style={{
              fontSize: '16px',
              color: 'var(--grey-100)',
              padding: '10px',
              borderBottom: '1px solid #313131'
            }}
            className="flex justify-end items-center"
          >
            <Tooltip title="Run in background">
              <button
                className="border-0 text-white bg-dark-secondary p-1 rounded-md px-2 pointer "
                onClick={() => {
                  // go back
                  navigate(-1)
                }}
              >
                <MinimizeIcon2 className=" w-4 h-4 mt-1" />
              </button>
            </Tooltip>
          </div>
          <div
            className={
              painPoints ? 'flex flex-col gap-5' : 'grid place-content-center'
            }
            style={{
              padding: '20px',
              // display: 'flex',
              background: 'rgba(255, 255, 255, 0.03)',
              height: 'calc(100% - 80px)',
              overflow: 'auto',
              borderLeft: '1px solid #313131'
            }}
          >
            {painPoints ? (
              <>
                <div
                  style={{
                    fontSize: '20px',
                    fontFamily: 'PoppinsMedium',
                    padding: '14px',
                    paddingTop: '0'
                  }}
                  className={`flex justify-between items-center text-grey-300`}
                >
                  <div>
                    Pain Points
                    <div
                      style={{
                        fontSize: '11px'
                      }}
                    >
                      {Object.keys(painPoints).length} detected
                    </div>
                  </div>
                </div>
                {Object.keys(painPoints).map((key, index) => {
                  return (
                    <div key={index} className="text-grey-300 text-xs">
                      <div className="flex flex-row items-center gap-2">
                        <div className="size-2 border-05 border-grey-500 rounded-lg bg-grey-200"></div>
                        <label className="text-sm font-medium" htmlFor={key}>
                          {key}
                        </label>
                      </div>

                      <div className="text-grey-400 ml-3 p-1 px-2">
                        {painPoints[key]}
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <div>
                <div className="text-grey-300 text-lg font-medium text-center ">
                  Extracting Pain points
                </div>
                <div className="flex items-center justify-center">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: PainPoint
                    }}
                    style={{
                      width: '75%',
                      height: '75%'
                    }}
                  />
                </div>

                <div className="text-grey-300 text-xs font-medium text-center">
                  This may take couple of minutes. <br></br> We are constantly
                  improving our AI to make it faster
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingUpWorkspace
