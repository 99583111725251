import store from '../../store/store'

const addClarity = () => {
  const storeValue = store.getState()
  const userName = storeValue?.authenticate?.user?.domain?.user_name
  const domainName = storeValue?.authenticate?.user?.domain?.domain_name
  const clarityScript = document.createElement('script') // is a node
  clarityScript.innerHTML = `
     (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "e16y5bebng");    

    clarity("set", "userName", "${userName}");
    clarity("set", "domainName", "${domainName}");
     `
  /* document.head.innerHTML = clarityScript */
  document.head.appendChild(clarityScript)
  return clarityScript
}

export default addClarity
