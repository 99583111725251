import { GET_WORKSPACES, ADD_WORKSPACES } from '../../../actionTypes'
import { getWorkspaces } from '../../../api'
import { toast } from 'react-toastify'

const getDocuments =
  (data, callback = () => {}, axiosProps = {}) =>
  async (dispatch) => {
    const { page_num = 1 } = data
    const dispatchType = page_num === 1 ? GET_WORKSPACES : ADD_WORKSPACES
    const response = await getWorkspaces(data, axiosProps)
    if (response.status === 200) {
      const resdata = response.data
      const { workspaces, has_next_page } = resdata
      dispatch({
        type: dispatchType,
        payload: {
          currentPage: data.page_num,
          workspaces,
          hasNextPage: has_next_page,
          searchKeyword: data?.keyword || '',
          sortBy: data.sort_by,
          sortOrder: data.sort_order
        },
        callback: () => {
          callback()
        }
      })
    } else {
      if (page_num === 1) toast.error('Failed to fetch documents')
      dispatch({
        type: dispatchType,
        payload: {
          currentPage: data.page_num,
          workspaces: [],
          hasNextPage: false,
          searchKeyword: data?.keyword || '',
          sortBy: data.sort_by,
          sortOrder: data.sort_order
        },
        callback: () => {
          callback()
        }
      })
    }
  }

export default getDocuments
