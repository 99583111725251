import { useState } from 'react'
import { Box } from '@mui/material'
import {
  Container,
  Section,
  ContentEditable,
  useConfirmation
} from '../../components'
import { useStyles } from './styles'
import { formatDate } from '../../utils/Date'
import { toast } from 'react-toastify'

const InfoTab = (props) => {
  const {
    draft = {},
    setDraft = () => {},
    handleSave = () => {},
    workspaceId,
    draftId
  } = props

  const [loadingName, setLoadingName] = useState(false)

  const {
    document_name,
    created_by_user_name,
    updated_by_user_name,
    created_at,
    updated_at
  } = draft?.draft || {}

  const classes = useStyles()

  const handleSaveName = async (name) => {
    if (!name) {
      toast.error('Draft name cannot be empty')
      setDraft({ ...draft, document_name })
      return
    }
    if (name === document_name) return
    setLoadingName(true)
    handleSave(name, 'document_name', false, () => {
      setLoadingName(false)
    })
  }

  return (
    <Container>
      <Section overFlow>
        <Box className={classes.headerContainer}>
          <ContentEditable
            style={{ background: loadingName ? 'lightgray' : 'transparent' }}
            disabled={loadingName}
            html={document_name || ''}
            stripHTML
            placeholder={'Draft Name'}
            onBlur={(e) => {
              handleSaveName(e.target.innerText)
            }}
            className={classes.draftName}
          />
          <Box className={classes.draftInfoItem}>
            <Box className={classes.draftInfoLabel}>Created by:</Box>
            <Box className={classes.draftInfoValue}>{created_by_user_name}</Box>
          </Box>
          <Box className={classes.draftInfoItem}>
            <Box className={classes.draftInfoLabel}>Created at:</Box>
            <Box className={classes.draftInfoValue}>
              {formatDate(created_at)}
            </Box>
          </Box>
          <Box className={classes.draftInfoItem}>
            <Box className={classes.draftInfoLabel}>Updated by:</Box>
            <Box className={classes.draftInfoValue}>{updated_by_user_name}</Box>
          </Box>
          <Box className={classes.draftInfoItem}>
            <Box className={classes.draftInfoLabel}>Updated at:</Box>
            <Box className={classes.draftInfoValue}>
              {formatDate(updated_at)}
            </Box>
          </Box>
        </Box>
      </Section>
    </Container>
  )
}

export default InfoTab
