import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  outerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: 'var(--grey-100)',
    borderWidth: '1.5px',
    borderRadius: '2px',
    borderColor: 'var(--grey-300)',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '75%',
    justifyContent: 'center'
  },
  uploadCloudIcon: {
    height: '100px !important',
    width: '100px !important'
  },
  link: {
    cursor: 'pointer',
    color: 'blue',
    marginLeft: '5px'
  }
})

export { useStyles }
