import React, { useEffect, useState, useRef } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-markers-plugin'
import 'videojs-markers-plugin/dist/videojs.markers.plugin.css'
import { useStyles } from './styles'
import Box from '@material-ui/core/Box'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import clsx from 'clsx'
import { filter, isEmpty } from 'lodash'

const VideoJS = (props) => {
  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const { options, onReady, markers } = props
  const classes = useStyles()
  const {
    container,
    webviewer,
    searchWrapper,
    dataContainer,
    markerKeyTime,
    markerKeyText,
    iconButtonRight,
    markerTime,
    markerText,
    liPointer,
    listPointer,
    timeStampContainer,
    markerLabel
  } = classes

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return
      const highlighted = filter(markers, { highlight: true })
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player)
        player.markers({
          markerStyle: {
            width: '5px',
            'background-color': 'red'
          },
          markers: highlighted
        })
      }))
    }
  }, [options, videoRef])

  useEffect(() => {
    const player = playerRef.current
    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  useEffect(() => {
    const player = playerRef?.current
    if (player) {
      const highlighted = filter(markers, { highlight: true })
      if (!isEmpty(highlighted)) {
        handleVideoSeek(highlighted[0])
        // document.getElementById("chapter_" + highlighted[0]?.index)?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }
    }
  }, [markers])

  const handleVideoSeek = (mark) => {
    const player = playerRef.current
    const [hours, minutes, seconds, milliseconds] = mark.start_time.split(':')
    const seek_seconds =
      parseInt(hours) * 60 * 60 +
      parseInt(minutes) * 60 +
      parseInt(seconds) +
      milliseconds / 100
    player.currentTime(seek_seconds)
  }

  return (
    <Box className={container}>
      <Box className={webviewer}>
        <div data-vjs-player>
          <video
            id="video"
            ref={videoRef}
            className="video-js vjs-big-play-centered"
          />
        </div>
      </Box>
    </Box>
  )
}

export default VideoJS
