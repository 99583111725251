import { colors } from './theme'
import { css } from '@emotion/css'
import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
  onPress: PropTypes.func,
  iconAfter: PropTypes.node,
  style: PropTypes.object // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
  onPress() {},
  style: {},
  iconAfter: null,
  isDisabled: false
}

export const defaultDropdownButtonStyle = {
  fontFamily: 'PoppinsRegular',
  padding: '4px 14px',
  backgroundColor: '#FFF',
  borderRadius: '4px',
  border: 'solid 1px #E5E5E5',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 1.36,
  alignItems: 'baseline',
  background: '#FFF',
  boxSizing: 'border-box',
  color: '#515151',
  cursor: 'pointer',
  display: 'inline-flex',
  margin: 0,
  maxWidth: '100%',
  flexWrap: 'nowrap',
  outline: 'currentcolor none medium !important',
  textAlign: 'left',
  textDecoration: 'none',
  transition:
    'background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  width: '100%',
  ':hover': {
    background: 'rgba(9, 30, 66, 0.08) none repeat scroll 0% 0%'
  }
}

const innerSpan = {
  alignSelf: 'center',
  display: 'inline-flex',
  flexWrap: 'nowrap',
  width: '100%',
  justifyContent: 'space-between'
}

const iconSpan = {
  alignSelf: 'center',
  display: 'flex',
  flexShrink: 0,
  lineHeight: 0,
  fontSize: 0,
  margin: '0px 4px'
}

const textSpan = {
  alignItems: 'center',
  alignSelf: 'center',
  flex: '1 1 auto',
  margin: '0px 4px',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}

const defaultStyles = {
  innerSpan,
  iconSpan,
  textSpan
}

export default function DropdownButton({
  onPress,
  children,
  iconAfter,
  style: buttonStyle,
  isDisabled = false
}) {
  const styles = { ...defaultStyles, button: buttonStyle }
  const childContent =
    typeof children === 'string' ? (
      <div id="multiCheckboxButtonText" className={css(styles.textSpan)}>
        {children}
      </div>
    ) : (
      children
    )
  return (
    <button
      disabled={isDisabled}
      style={{ background: isDisabled ? '#F5F5F5' : 'inherit' }}
      type="button"
      className={css(styles.button)}
      onClick={onPress}
    >
      <div id="multiCheckboxButtonText" className={css(styles.innerSpan)}>
        {childContent}
        {!!iconAfter && <div className={css(styles.iconSpan)}>{iconAfter}</div>}
      </div>
    </button>
  )
}
DropdownButton.propTypes = propTypes
DropdownButton.defaultProps = defaultProps
