import { cloneDeep } from 'lodash'
import {
  DELETE_DOMAIN_INVITES,
  UPDATE_DOMAIN_INVITES,
  TOASTER_ALERT
} from '../../../actionTypes'
import { putUserInvites } from '../../../api'
import { toast } from 'react-toastify'

const updateUserInvite = (data) => async (dispatch) => {
  const res = await putUserInvites(data)
  if (res.status === 200) {
    if (data.updateType === 'role') {
      dispatch({
        type: TOASTER_ALERT,
        payload: {
          message: 'User role updated',
          show: true,
          type: 'success'
        }
      })
    } else {
      if (data.status === 'Remove') {
        dispatch({
          type: DELETE_DOMAIN_INVITES,
          payload: data
        })
        dispatch({
          type: TOASTER_ALERT,
          payload: {
            message: 'User removed',
            show: true,
            type: 'success'
          }
        })
      } else {
        const updatedData = cloneDeep(data)
        updatedData.status =
          updatedData.status === 'Active' || updatedData.status === 'joined'
            ? 'joined'
            : updatedData.status
        dispatch({
          type: UPDATE_DOMAIN_INVITES,
          payload: updatedData
        })

        if (data.status === 'Inactive') {
          dispatch({
            type: TOASTER_ALERT,
            payload: {
              message: 'User will be deactivated in the next 30 mins',
              show: true,
              type: 'success'
            }
          })
        } else {
          dispatch({
            type: TOASTER_ALERT,
            payload: {
              message: 'User is activated',
              show: true,
              type: 'success'
            }
          })
        }
      }
    }
  } else if (res.response.status === 405) {
    dispatch({
      type: TOASTER_ALERT,
      payload: {
        message: 'You need admin access to add or update users',
        show: true,
        type: 'error'
      }
    })
  } else {
    if (data.updateType === 'role') {
      dispatch({
        type: TOASTER_ALERT,
        payload: {
          message: 'Failed to updated user role ',
          show: true,
          type: 'error'
        }
      })
    } else {
      if (data.status === 'Remove') {
        dispatch({
          type: TOASTER_ALERT,
          payload: {
            message: 'Failed to remove invite',
            show: true,
            type: 'error'
          }
        })
      } else {
        if (data.status === 'Inactive') {
          dispatch({
            type: TOASTER_ALERT,
            payload: {
              message: 'Failed to deactivate user',
              show: true,
              type: 'error'
            }
          })
        } else {
          dispatch({
            type: TOASTER_ALERT,
            payload: {
              message: 'Failed to activate user',
              show: true,
              type: 'error'
            }
          })
        }
      }
    }
  }
}

export { updateUserInvite }
