import { useEffect, useState, useRef } from 'react'
import { useStyles } from './styles'
import { Box, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'

const DateTagSelector = (props) => {
  const {
    isMulti = false,
    isDisabled = false,
    onChange = () => {},
    value = [],
    placeholder = 'Select Date',
    showPlaceholderOnFilled = false
  } = props
  const classes = useStyles()

  const [dates, setDates] = useState([])
  const dateRef = useRef(null)
  const [calenderView, setCalederView] = useState(false)

  useEffect(() => {
    if (value.length > 0) {
      const dates = []
      value.forEach((date) => {
        if (date?.label) {
          dates.push(date?.label)
        }
      })
      setDates(dates)
    }
  }, [])

  const handleValueChange = (date, index) => {
    date.setHours(0, 0, 0, 0)
    const newDates = [...dates]
    newDates[index] = date
    setDates(newDates)
  }

  const addNewValue = () => {
    const newDates = [...dates, null]
    setDates(newDates)
  }

  const removeValue = (index) => {
    const newDates = dates.filter((d, i) => i !== index)
    setDates(newDates)
    handleCalenderClose()
  }

  useEffect(() => {
    dateRef.current = dates
  }, [dates])

  const handleCalenderClose = (index = null) => {
    // If the dates are manually changed the onChange is triggered for each change.
    // To avoid this we are using onClose event to trigger the onChange only after the user has stopped changing the date.
    // Timeout is to regulate the state change.
    if (index !== null) {
      setCalederView({ ...calenderView, [index]: {} })
    }
    setTimeout(() => {
      const isoDate = _.compact(dateRef.current).map((date) =>
        new Date(date).toISOString()
      )
      const valueDate = _.compact(value).map((date) => date?.label)
      if (!_.isEqual(isoDate, valueDate)) {
        onChange(isoDate)
      }
    }, 300)
  }

  return (
    <Box className={classes.dateStyle}>
      <Box
        className={!isDisabled ? classes.wrapper : classes.wrapperDisabled}
        onClick={(e) => e.stopPropagation()}
      >
        <Box className={classes.placeholder}>
          <Box className={classes.flexWrapper}>
            {dates.length > 0 && showPlaceholderOnFilled && (
              <Box className={classes.placeholderText}>{placeholder}:</Box>
            )}
            {dates.length > 0 ? (
              dates.map((date, index) => {
                return (
                  <Box className={classes.dateWrapper} key={index}>
                    <DatePicker
                      onChange={(e) => handleValueChange(e, index)}
                      value={date}
                      clearIcon={null}
                      calendarIcon={null}
                      format="MM/dd/yyyy"
                      monthPlaceholder="MM"
                      dayPlaceholder="DD"
                      yearPlaceholder="YYYY"
                      closeCalendar={true}
                      key={index}
                      disabled={isDisabled}
                      onCalendarClose={() => handleCalenderClose(index)}
                      calendarProps={{
                        view: calenderView?.[index]?.view
                          ? calenderView?.[index]?.view
                          : 'month',
                        onViewChange: (val) => {
                          setCalederView({
                            ...calenderView,
                            [index]: { ...calenderView?.[index], ...val }
                          })
                        },
                        activeStartDate: calenderView?.[index]?.activeStartDate
                          ? calenderView?.[index]?.activeStartDate
                          : date
                          ? new Date(date)
                          : new Date(),
                        onActiveStartDateChange: ({ activeStartDate }) => {
                          setCalederView({
                            ...calenderView,
                            [index]: {
                              ...calenderView?.[index],
                              activeStartDate
                            }
                          })
                        }
                      }}
                    />
                    <IconButton
                      disabled={isDisabled}
                      disableRipple
                      className={classes.iconButton}
                      onClick={() => removeValue(index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                )
              })
            ) : (
              <Box className={classes.placeholderText}>{placeholder}</Box>
            )}
          </Box>
          {!isMulti && dates.length > 0 ? null : (
            <IconButton
              disabled={isDisabled}
              disableRipple
              onClick={() => addNewValue()}
              className={classes.iconButton}
            >
              <AddIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default DateTagSelector
