import { UPDATE_STYLE_SETTINGS, TOASTER_ALERT } from '../../../actionTypes'
import { putStyleSettings } from '../../../api'

const updateStyleSettings =
  (data, callback = () => {}) =>
  async (dispatch) => {
    try {
      const res = await putStyleSettings(data)
      if (res.status === 200) {
        const { settings } = data
        dispatch({
          type: UPDATE_STYLE_SETTINGS,
          payload: settings,
          callback
        })
      } else {
        dispatch({
          type: TOASTER_ALERT,
          payload: {
            message: 'Failed to update style guide settings',
            show: true,
            type: 'error'
          }
        })
      }
    } catch (e) {
      dispatch({
        type: TOASTER_ALERT,
        payload: {
          message: 'Failed to update style guide settings',
          show: true,
          type: 'error'
        }
      })
    }
  }
export default updateStyleSettings
