import { makeStyles, withTheme } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor()

  return {
    libraryContainer: {
      width: '100%',
      height: '100%',
      background: 'white'
    },
    contentContainer: {
      // padding: '10px 20px',
      height: '100%',
      borderRight: '1px solid #EEEEEE',
      width: '70%'
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    titleStyle: {
      width: '100%',
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between'
    },
    titleText: {
      padding: '10px',
      display: 'flex'
    },
    htmlViewer: {
      height: '100%',
      overflow: 'auto'
    },
    saveText: {
      paddingLeft: '10px'
    },

    libraryEditor: {
      /* width: "100%", */
      height: '100%'
    },
    loaderContainter: {
      width: '100%',
      height: '100%'
    },
    editorHeight: {
      height: '100%'
    },
    draftTitle: {
      fontWeight: 'bold',
      display: 'flex',
      alignItem: 'center',
      justifyContent: 'center'
    },
    inputStyle: {
      '& > div': {
        width: '100%'
      },
      marginBottom: '10px',
      '& .MuiAutocomplete-input': {
        padding: '8.5px 14px'
      }
    },
    tagInputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    },
    tagContainer: {
      marginTop: '20px'
    },
    editorAction: {
      // padding: "10px",
      // paddingTop: "5px",
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    editorTitle: {
      justifyContent: 'space-between',
      width: '100%'
    },
    labelName: {
      // fontWeight: "bold",
      // marginBottom: "12px",
      fontSize: '14px',
      color: '#3f3f46'
    },
    contentFullWidth: {
      width: '96%'
    },
    contentCustomWidth: {
      width: '70%'
    },
    infoPanel: { width: '26%', height: '100%', backgroundColor: '#fafafa' },
    narrativeContent: {
      padding: '20px',
      paddingTop: '0',
      width: '100%',
      height: 'calc(100vh - 250px)',
      overflow: 'auto',
      borderRight: '1px solid #EEEEEE'
    },
    toolbarWrapper: {
      height: '92%',
      '& >div': {
        borderLeft: 'none'
      }
    },
    toolBarContainer: {
      width: '4%'
      /* '& >div': {
        height: "93% !important"
      } */
    },
    buttonText: {
      fontFamily: 'PoppinsRegular',
      color: '#ffffff',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      margin: '0px',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      background: 'selectedColor',
      color: '#ffffff',
      fontFamily: 'PoppinsRegular',
      borderRadius: '6px',
      cursor: 'pointer',
      padding: '4px 10px',
      '&:hover': {
        opacity: '0.8'
        // background: "#195ccb",
      }
    },
    historyCardWrapper: {
      padding: '10px',
      border: '1px solid #e0e0e0',
      borderRadius: '5px',
      marginTop: '10px',
      cursor: 'pointer',
      '&:hover': {
        background: '#f5f5f5'
      }
    },
    historySelectedCard: {
      background: '#e0e0e0 !important'
    },
    historyDate: {
      fontWeight: 'bold',
      color: '#00000066',
      fontSize: '14px'
    },
    versionName: {
      color: '#9e9595',
      fontSize: '12px',
      fontStyle: 'italic'
    },
    historyEditedBy: {
      fontSize: '12px',
      color: '#9e9595',
      paddingTop: '5px'
    },
    historyUserName: {
      fontStyle: 'italic',
      textDecoration: 'underline',
      color: '#9e9595'
    },
    editorLastUpdates: {
      fontSize: '14px',
      color: 'black',
      zIndex: '10',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      gap: '5px'
    },
    lateModifiedName: {
      fontStyle: 'italic'
    },
    toolBarOptions: { width: '3.5%' },
    infoInnerContainer: { height: '100%' },
    infoPanelHeader: {
      height: '5%',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      background: '#EEEEEE',
      padding: '15px 0'
    },
    infoPanelTitle: {
      padding: '10px',
      paddingLeft: '20px',
      width: '100%',
      color: '#52525b',
      borderRight: '1px solid #EEEEEE',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600
    },
    infoCloseIcon: {
      borderLeft: '1px solid #EEEEEE',
      background: 'white',
      width: '13%'
    },
    editorCloseIcon: { width: '150px', textAlign: 'end' },
    currentVersionInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px',
      paddingTop: '8px'
      // paddingLeft: "20px",
      // paddingBottom: "5px",
      // marginBottom: "10px",
      // marginTop: "-3px",
    },
    actionButtonWrapper: {
      display: 'flex'
      // paddingRight: "12px",
    },
    cancelButton: {
      background: 'white',
      color: '#18181b',
      fontWeight: '600',
      '&:hover': {
        background: '#eaeaea'
      },
      marginRight: '10px',
      textTransform: 'capitalize',
      paddingBottom: '3px',
      paddingTop: '4px',
      boxShadow: 'none'
    },
    userNameDisplay: {
      paddingLeft: '15px'
    },
    userNameProperty: {
      color: 'black',
      fontWeight: 'bold'
    },
    avatarWrapper: {
      width: '20px',
      height: '20px',
      background: '#D9D9D9',
      color: 'black',
      fontWeight: 'bold'
    },
    variationLink: {
      fontWeight: 'bold',
      color: 'black',
      cursor: 'pointer',
      width: '20%',
      textAlign: 'left',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    collapseIcon: {
      width: '30px',
      height: '30px',
      color: 'grey'
    },
    hiddenTagAction: {
      background: 'selectedColor'
    },
    libraryContent: {
      // overflow: "auto",
      /* padding: "20px", */
      /* marginBottom: "10px", */
      // borderTop: '1px solid #EEEEEE'
      /* borderBottom: "1px solid #EEEEEE", */
    },
    disableSave: {
      background: '#8aa7e2',
      pointerEvents: 'none'
    },
    collectionEditContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      maxHeight: '180px',
      overflow: 'auto',
      gap: '5px'
    },
    collectionCard: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'PoppinsRegular',
      // color: "selectedColor",
      fontWeight: '600',
      lineHeight: '20px',
      fontSize: '13px',
      border: 'solid 1px #0645AD',
      letterSpacing: '0.01em',
      borderRadius: '5px',

      padding: '5px 10px'
    },
    collectionCardTitle: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: '0',
      marginRight: '5px',
      fontSize: '11px',
      cursor: 'pointer'
    },
    collectionVisiblity: {
      width: '12px',
      height: '12px',
      marginRight: '5px'
    },
    removeCollection: {
      width: '8px',
      height: '20px',
      cursor: 'pointer'
    },
    collectionDropDown: {
      width: '17px',
      height: '17px'
    },
    collectionMoreCount: {
      padding: '2px 10px',
      marginLeft: '5px',
      marginTop: '0px',
      background: 'selectedColor',
      borderRadius: '5px',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '11px',
      cursor: 'pointer'
    },
    infoTextWrapper: {
      color: '#18181b',
      margin: 0,
      fontSize: '12px'
    }
  }
})

export { useStyles }
