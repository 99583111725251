import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  chatRootWrapper: {
    height: 'calc(100vh - 148px)',
    display: 'flex',
    flexFlow: 'column'
  },
  chatContainer: {
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    padding: '10px',
    margin: 'auto',
    background: 'fff'
    /* maxWidth: '800px', */
  },
  avatar: {
    color: theme.palette.primary.main,
    border: `2px ${theme.palette.primary.main} solid`,
    backgroundColor: 'white',
    borderRadius: '50%',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'PoppinsRegular'
  },
  pullWrapper: {
    color: '#6b6b6b6b',
    textAlign: 'center'
  },
  messageMe: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '10px'
  },
  messageThem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '10px'
  },
  messageText: {
    backgroundColor: '#eff3fa',
    padding: '10px',
    minWidth: '50px',
    color: '#696969',
    maxWidth: '650px',
    wordBreak: 'break-word'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    borderRadius: '5px',
    padding: '10px 10%',
    marginTop: '5px'
  },
  messageList: {
    height: '1px'
  },
  errorContainer: {
    color: 'red'
  },
  messageWrapper: {
    display: 'flex'
  },
  avatarWrapper: {
    margin: '0px 3px'
  },
  dotsWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  funcWrappers: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minWidth: '25px'
  },
  sessionChatWrapper: {
    padding: '0 15%',
    height: '84vh'
  },
  titleContent: {
    width: '25%',
    marginBottom: '20px',
    textAlign: 'center',
    fontFamily: 'PoppinsRegular',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px'
  },
  contentText: {
    background: '#DBEAFF',
    padding: '10px',
    width: '25%',
    marginBottom: '30px',
    textAlign: 'center',
    fontFamily: 'PoppinsRegular',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '19.5px',
    borderRadius: '4px',
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  listCenter: {
    height: '97%'
  },
  hoverAction: {
    cursor: 'pointer'
  },
  feedbackWrappers: {
    marginLeft: '75px'
  }
}))

export { useStyles }
