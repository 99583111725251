import { SET_UPLOADER } from '../../../actionTypes'

const setUploader = (data) => async (dispatch) => {
  dispatch({
    type: SET_UPLOADER,
    payload: data
  })
}

export { setUploader }
