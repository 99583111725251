import { Fragment, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Loader from '../../components/Loader'
import PDFViewer from './Viewer'

const PdfViewer = (props) => {
  const { PDFUrl } = props
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (PDFUrl) {
      setTimeout(() => {
        setLoading(false)
        setUrl(PDFUrl)
      }, 1000)
    }
  }, [PDFUrl])

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ width: '100%', height: '100%' }}
      >
        <Loader loading={loading} caption={'opening your pdf'} />
      </Grid>
    )
  }

  return (
    <Fragment>
      <PDFViewer url={url} {...props} />
    </Fragment>
  )
}

export default PdfViewer
