import { UPDATE_KEY_TAG } from '../../actionTypes'
import { postTagCenterKey } from '../../api'
import { toast } from 'react-toastify'

const updateKey = (req) => async (dispatch) => {
  const { status } = req
  dispatch({
    type: UPDATE_KEY_TAG,
    payload: { ...req }
  })
  const res = await postTagCenterKey(req)
  if (res.status === 200) {
    if (status === 'delete') {
      dispatch({
        type: UPDATE_KEY_TAG,
        payload: { ...req, synced: true }
      })
    } else {
      dispatch({
        type: UPDATE_KEY_TAG,
        payload: { ...req, synced: true, status: 'statusUpdate' }
      })
    }
  } else {
    toast.error(
      res?.response?.data?.error || 'Something went wrong updating tags'
    )
    if (status === 'update') {
      const { key, oldkey } = req
      dispatch({
        type: UPDATE_KEY_TAG,
        payload: { ...req, key: oldkey, oldkey: key, synced: true }
      })
    } else if (status === 'delete') {
      dispatch({
        type: UPDATE_KEY_TAG,
        payload: { ...req, synced: true, status: 'statusUpdate' }
      })
    }
  }
}

export default updateKey
