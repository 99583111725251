function toPadding(value) {
  const obj = toTRBL(value)
  obj.width = obj.left + obj.right
  obj.height = obj.top + obj.bottom
  return obj
}

function _readValueToProps(value, props) {
  const ret = {}
  const objProps = isObject(props)
  const keys = objProps ? Object.keys(props) : props
  const read = isObject(value)
    ? objProps
      ? (prop) => valueOrDefault(value[prop], value[props[prop]])
      : (prop) => value[prop]
    : () => value
  for (const prop of keys) {
    ret[prop] = numberOrZero(read(prop))
  }
  return ret
}
function valueOrDefault(value, defaultValue) {
  return typeof value === 'undefined' ? defaultValue : value
}
function isObject(value) {
  return (
    value !== null &&
    Object.prototype.toString.call(value) === '[object Object]'
  )
}
const numberOrZero = (v) => +v || 0

function toTRBL(value) {
  return _readValueToProps(value, {
    top: 'y',
    right: 'x',
    bottom: 'y',
    left: 'x'
  })
}

export function customGenerateLabels(chart) {
  const datasets = chart.data.datasets
  const {
    labels: {
      usePointStyle,
      pointStyle,
      textAlign,
      color,
      useBorderRadius,
      borderRadius
    }
  } = chart.legend.options
  return chart._getSortedDatasetMetas().map((meta) => {
    const style = meta.controller.getStyle(usePointStyle ? 0 : undefined)
    const borderWidth = toPadding(style.borderWidth)
    return {
      text: datasets[meta.index].label,
      fillStyle: datasets[meta.index].lightBackgroundColor,
      fontColor: color,
      hidden: !meta.visible,
      lineCap: style.borderCapStyle,
      lineDash: style.borderDash,
      lineDashOffset: style.borderDashOffset,
      lineJoin: style.borderJoinStyle,
      lineWidth: (borderWidth.width + borderWidth.height) / 4,
      strokeStyle: style.borderColor,
      pointStyle: pointStyle || style.pointStyle,
      rotation: style.rotation,
      textAlign: textAlign || style.textAlign,
      borderRadius: useBorderRadius && (borderRadius || style.borderRadius),
      datasetIndex: meta.index
    }
  }, this)
}

export const generateRandomLightColor = () => {
  const hue = Math.floor(Math.random() * 360)
  const lightColor = `hsl(${hue}, 70%, 80%)`
  const darkColor = `hsl(${hue}, 70%, 45%)`
  return {
    light: lightColor,
    dark: darkColor
  }
}

export const customTooltip = (tooltipModel) => {
  let tooltipEl = document.getElementById('chartjs-tooltip')
  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chartjs-tooltip'
    tooltipEl.classList.add('custom-tooltip')
    document.body.appendChild(tooltipEl)
  }
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.display = 'none'
    return
  }
  tooltipEl.innerHTML = tooltipModel.body
    .map((item) => `<span>${item.lines[0]}</span>`)
    .join('')

  const position = document.getElementById('myChart').getBoundingClientRect()
  tooltipEl.style.display = 'block'
  tooltipEl.style.position = 'absolute'
  tooltipEl.style.left = `${position.left + tooltipModel.caretX}px`
  tooltipEl.style.top = `${position.top + tooltipModel.caretY}px`
}

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.margin = 0
    listContainer.style.padding = 0
    listContainer.style.flexWrap = 'wrap'

    legendContainer.appendChild(listContainer)
  }

  return listContainer
}

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    if (!options) return
    const ul = getOrCreateLegendList(chart, options.containerID)

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove()
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart)

    items.forEach((item) => {
      const li = document.createElement('li')
      li.style.alignItems = 'center'
      li.style.cursor = 'pointer'
      li.style.display = 'flex'
      li.style.flexDirection = 'row'
      li.style.marginLeft = '10px'
      li.style.marginBottom = '5px'
      li.style.fontSize = '12px'
      li.style.fontFamily = 'PoppinsRegular'
      li.onclick = () => {
        const { type } = chart.config
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index)
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          )
        }
        chart.update()
      }

      // Color box
      const boxSpan = document.createElement('span')
      boxSpan.style.background = item.fillStyle
      boxSpan.style.borderColor = item.strokeStyle
      boxSpan.style.borderWidth = item.lineWidth + 'px'
      boxSpan.style.display = 'inline-block'
      boxSpan.style.flexShrink = 0
      boxSpan.style.height = '20px'
      boxSpan.style.marginRight = '10px'
      boxSpan.style.width = '20px'

      // Text
      const textContainer = document.createElement('p')
      textContainer.style.color = item.fontColor
      textContainer.style.margin = 0
      textContainer.style.padding = 0
      textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

      const text = document.createTextNode(item.text)
      textContainer.appendChild(text)

      li.appendChild(boxSpan)
      li.appendChild(textContainer)
      ul.appendChild(li)
    })
  }
}
