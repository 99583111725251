import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    padding: 20
  },
  text: {
    maxWidth: 500
  },
  inputlabel: {
    marginTop: 16,
    color: '#000000'
  },
  button: {
    marginTop: 16,
    marginBottom: 16,
    display: 'flex'
  },
  innerButton: {
    paddingRight: 10
  },
  inputWrapper: {
    margin: '16px 0'
  },
  colorWrapper: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'row'
  },
  color: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    fontSize: '13px'
  },
  logo: {
    borderRadius: '5px',
    width: '100%',
    maxHeight: '100px',
    objectFit: 'contain',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  wallpaper: {
    borderRadius: '5px',
    width: '100%',
    height: '100px',
    objectFit: 'contain',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  imageWrapper: {
    textAlign: 'center',
    width: 'fit-content'
  },
  wallpaperWapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'flex-start'
  }
})
export { useStyles }
