import { useState, useEffect, useRef } from 'react'
import {
  getDraftStatus,
  rerunDraft,
  getPeopleList,
  getAllProjectIds,
  saveDraftConfig,
  getRFXPainPoints
} from '../../store/api'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Fade,
  Slider,
  Tooltip,
  Menu,
  MenuItem,
  Skeleton
} from '@mui/material'
import { toast } from 'react-toastify'
import {
  Container,
  Loader,
  Section,
  SectionFixed,
  TextInput,
  Button,
  ReactSelect
} from '../../components'
import { useStyles } from './styles'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import clsx from 'clsx'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import AiLoader from '../../assets/lottie/ai.json'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { ROUTES } from '../../config/routes'
import SaveIcon from '@mui/icons-material/Save'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import HistoryIcon from '@mui/icons-material/History'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { formatDate } from '../../utils/Date'
import GenerationHistory from './GenerationHistory'
import {
  DeleteIcon,
  DragDotsIcon,
  EditIcon,
  MagicWand,
  ProjectIcon,
  RedirectIcon,
  RegenerateIcon,
  SectionIcon,
  SettingsIcon,
  StarIcon,
  ThreeDotsIcon
} from '../../components/Icons/Icons'
import Button2 from '../../components/Button/Button2'

const Storyboard = (props) => {
  const {
    darkTheme,
    draftId,
    workspaceId,
    storyboards = null,
    setStoryboards = () => {},
    selectedSection = null,
    setSelectedSection = () => {},
    generateContentFromStory = () => {},
    deleteSectionFromEditor = () => {},
    positionChangeCallback = () => {},
    loadingStates = {},
    insertIntoEditor = () => {},
    showConfirmDialog = () => {},
    settingUpWorkspace = () => {}
  } = props

  const [logs, setLogs] = useState([])
  const [status, setStatus] = useState(null)
  const [storyboardCopy, setStoryboardCopy] = useState(null)
  const pollInterval = 3000
  const classes = useStyles()
  const [logsLoading, setLogsLoading] = useState(true)
  const [peopleList, setPeopleList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [showSelectPeople, setShowSelectPeople] = useState(false)
  const [showSelectProject, setShowSelectProject] = useState(false)
  const storyBoardRef = useRef(null)
  const isErrored = status === 'errored'
  const isCompleted = status === 'completed'
  const [transitionController, setTransitionController] = useState(true)
  const [transitionDelayController, setTransitionDelayController] =
    useState(true)
  const [requiresSave, setRequiresSave] = useState(false)
  const [saving, setSaving] = useState(false)
  const [showFullLogs, setShowFullLogs] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [editMeta, setEditMeta] = useState(false)
  const currentUserId = useSelector(
    (state) => state.authenticate.user?.domain?.id || ''
  )
  const { LIBRARY_PROJECTS, LIBRARY_PEOPLE_RESUME } = ROUTES
  const requestController = useRef(null)

  const enableGenerateAll = Object.values(loadingStates).every(
    (state) => state === false
  )

  console.log('storyboards loadingState', loadingStates)

  useEffect(() => {
    const fetchDataPeople = async () => {
      const res = await getPeopleList()
      if (res.status === 200) {
        const people = res.data
        const options = people.map((person) => {
          const label = person.name
          return {
            label,
            value: person.id,
            name: person.name,
            is_added_by_user: true
          }
        })
        setPeopleList(options)
      }
    }
    const fetchDataProjects = async () => {
      const res = await getAllProjectIds()
      if (res.status === 200) {
        const results = res?.data?.project
        const options = results.map((project) => {
          return {
            label: project?.title,
            value: project?.content_ids[0],
            name: project?.id
          }
        })
        setProjectList(options)
      }
    }
    fetchDataPeople()
    fetchDataProjects()
  }, [])

  const reRunDraft = async () => {
    showConfirmDialog({
      onConfirm: async () => {
        setStatus('initiated')
        setLogsLoading(true)
        setLogs([
          {
            log: 'Regenerating draft',
            timestamp: new Date().toISOString()
          }
        ])
        const req = {
          id: draftId,
          workspace_id: workspaceId
        }
        const res = await rerunDraft(req)
        if (res.status === 200) {
          handleAllDelete()
        } else {
          toast.error('Error regenerating draft')
          setLogs([
            {
              log: 'Regenerate failed',
              timestamp: new Date().toISOString()
            }
          ])
          setStatus('completed')
        }
        const interval = setInterval(() => {
          fetchStatus(interval)
        }, pollInterval)
      },
      confirmationMessageTitle: `Are you sure you want to regenerate the draft?`,
      confirmationMessage: `This will remove all the previously generated content.`
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!requestController?.current) fetchStatus(interval)
    }, pollInterval)
    return () => clearInterval(interval)
  }, [draftId])

  const fetchStatus = async (interval) => {
    requestController.current = true
    const res = await getDraftStatus(draftId)
    if (res.status === 200) {
      const data = res.data
      const { status, logs, storyboards } = data || {}
      if (status === 'completed' || status === 'errored') {
        clearInterval(interval)
        setLogsLoading(false)
      }
      if (storyboards) {
        setStoryboards(storyboards)
        setStoryboardCopy(storyboards)
        setTransitionController(true)
        setTimeout(() => {
          setTransitionDelayController(false)
        }, 1000)
      }
      setLogs(logs)

      console.log('status', status)
      settingUpWorkspace({
        logs,
        completed: status === 'completed',
        error: status === 'errored'
      })
      setStatus(status)
    } else {
      settingUpWorkspace({
        logs,
        completed: status === 'completed',
        error: status === 'errored'
      })
      clearInterval(interval)
      setLogsLoading(false)
      toast.error('Error fetching status')
    }
    requestController.current = false
  }

  const handleReset = () => {
    setStoryboards(storyboardCopy)
    setRequiresSave(false)
    setSelectedSection(null)
    let items = Array.from(storyboardCopy)
    items = items.map((item, index) => {
      return {
        ...item,
        order: index
      }
    })
    positionChangeCallback(items)
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    let items = Array.from(storyboards)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    items = items.map((item, index) => {
      return {
        ...item,
        order: index
      }
    })
    setStoryboards(items)
    positionChangeCallback(items)
    setRequiresSave(true)
  }

  const handleDragEndSubSection = (result, subsections) => {
    if (!result.destination) {
      return
    }

    const newSubSections = Array.from(subsections)

    const [reorderedItem] = newSubSections.splice(result.source.index, 1)

    newSubSections.splice(result.destination.index, 0, reorderedItem)

    const newMetadata = {
      ...selectedSection.metadata_,
      sub_sections: newSubSections
    }

    const finalSection = {
      ...selectedSection,
      metadata_: newMetadata
    }

    setSelectedSection(finalSection)
  }

  useEffect(() => {
    setShowHistory(false)
  }, [selectedSection])

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#E5E5E5' : 'transparent'
  })

  const handleAddNewStory = () => {
    const newSection = {
      id: uuidv4(),
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      status: 'ready_to_be_generated',
      section_type: 'others',
      order: storyboards.length,
      created_by_user: currentUserId,
      metadata_: {
        section_title: '',
        section_type: 'others',
        section_summary: '',
        instructions: '',
        number_of_words: 200
      }
    }
    setStoryboards([...storyboards, newSection])
    setRequiresSave(true)
    setSelectedSection(newSection)
  }

  const handleDelete = (id) => {
    setStoryboards((prev) => prev.filter((s) => s.id !== id))
    setSelectedSection(null)
    setRequiresSave(true)
  }

  const scrollToSection = (id) => {
    const ele = document.getElementById(id)
    if (ele) {
      ele.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const updateSection = async () => {
    const index = storyboards.findIndex((s) => s.id === selectedSection.id)

    const newStoryboards = [...storyboards]
    newStoryboards[index] = selectedSection

    setSaving(true)
    const req = {
      storyboards: newStoryboards,
      workspace_id: workspaceId,
      draft_id: draftId
    }
    const res = await saveDraftConfig(req)
    if (res.status === 200) {
      setRequiresSave(false)
      setStoryboardCopy(storyboards)
      setStoryboards((prev) => {
        const index = prev.findIndex((s) => s.id === selectedSection.id)
        const newStoryboards = [...prev]
        newStoryboards[index] = selectedSection
        return newStoryboards
      })
      setSelectedSection(null)
    } else {
      toast.error('Error saving draft')
    }
    setSaving(false)
  }

  const renderSection = () => {
    const { id, metadata_ } = selectedSection
    const {
      section_title,
      section_type,
      section_summary,
      instructions,
      sub_sections = [],
      number_of_words = 200
    } = metadata_ || {}
    const isPeopleOrProject =
      section_type === 'project_resume' || section_type === 'people_resume'
    const disableGenerate = loadingStates[id]

    return (
      <>
        <div
          style={{
            height: 'calc(100% - 280px)',
            overflow: 'auto',
            color: 'var(--dark-text)'
          }}
        >
          <Box className={classes.sectionContainer}>
            <Box className={classes.sectionLabel}></Box>
            {!editMeta ? (
              <div
                className="flex flex-col gap-1"
                onClick={() => setEditMeta(true)}
              >
                <p className="m-0 text-xl font-medium">{section_title}</p>

                <div
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {section_summary}
                </div>
              </div>
            ) : (
              <>
                <Box className={classes.sectionHeaderInput}>
                  <Box className={classes.sectionLabel}>Section Title</Box>
                  <TextInput
                    value={section_title}
                    onChange={(e) => {
                      setRequiresSave(true)
                      setSelectedSection({
                        ...selectedSection,
                        metadata_: {
                          ...metadata_,
                          section_title: e.target.value
                        }
                      })
                    }}
                  />
                </Box>
                <Box className={classes.sectionHeaderInput}>
                  <Box className={classes.sectionLabel}>Description</Box>
                  <TextInput
                    multiline
                    rows={3}
                    value={section_summary}
                    onChange={(e) => {
                      setRequiresSave(true)
                      setSelectedSection({
                        ...selectedSection,
                        metadata_: {
                          ...metadata_,
                          section_summary: e.target.value
                        }
                      })
                    }}
                  />
                </Box>
              </>
            )}

            {!isPeopleOrProject && (
              <div>
                <p className="m-0 text-xs text-grey-500 mt-3">Subsections</p>
                <DragDropContext
                  onDragEnd={(d) => {
                    console.log(d)

                    handleDragEndSubSection(d, sub_sections)
                  }}
                >
                  <Droppable droppableId="droppable" direction="vertical">
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="flex flex-col gap-2 mt-1"
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {sub_sections.map((subSection, subIndex) => (
                          <Draggable
                            key={subIndex}
                            draggableId={subIndex.toString()}
                            index={subIndex}
                          >
                            {(provided) => (
                              <Box
                                className={clsx(
                                  classes.story,
                                  'border-1 border-grey-200'
                                )}
                                key={subIndex}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                  className="parent-card"
                                >
                                  <DragDotsIcon
                                    height={18}
                                    width={18}
                                    fill="var(--dark-primary)"
                                  />
                                  <input
                                    value={subSection}
                                    placeholder="Enter Title"
                                    autoFocus
                                    className="text-xs font-medium input-base w-full"
                                    onChange={(e) => {
                                      setRequiresSave(true)
                                      const newSubSections = sub_sections.map(
                                        (s, i) =>
                                          i === subIndex ? e.target.value : s
                                      )
                                      setSelectedSection({
                                        ...selectedSection,
                                        metadata_: {
                                          ...metadata_,
                                          sub_sections: newSubSections
                                        }
                                      })
                                    }}
                                  />

                                  <div className="card-options flex items-center">
                                    <Tooltip title="Remove Sub Section">
                                      <button
                                        className="button-base"
                                        onClick={() => {
                                          setRequiresSave(true)
                                          const newSubSections =
                                            sub_sections.filter(
                                              (_, i) => i !== subIndex
                                            )
                                          setSelectedSection({
                                            ...selectedSection,
                                            metadata_: {
                                              ...metadata_,
                                              sub_sections: newSubSections
                                            }
                                          })
                                        }}
                                      >
                                        <DeleteIcon className="w-4 h-4" />
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        <Button2
                          styleHover={{}}
                          className="w-full flex justify-center bg-grey-100 rounded-md text-dark"
                          onClick={() => {
                            setRequiresSave(true)
                            setSelectedSection({
                              ...selectedSection,
                              metadata_: {
                                ...metadata_,
                                sub_sections: [...sub_sections, '']
                              }
                            })
                          }}
                          style={{
                            color: 'var(--dark-primary)'
                          }}
                        >
                          + Add Sub Section
                        </Button2>
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
            {/* <Box className={classes.sectionHeaderInput + ' mt-3 '}>
              <Box className={classes.sectionLabel + ' mb-4'}>
                Instructions to AI
              </Box>
              <TextInput
                rows={5}
                multiline
                value={instructions}
                onChange={(e) => {
                  setRequiresSave(true)
                  setSelectedSection({
                    ...selectedSection,
                    metadata_: {
                      ...metadata_,
                      instructions: e.target.value
                    }
                  })
                }}
              />
            </Box> */}
            {/* <Box className={classes.sectionHeaderInput}>
              <Box className={classes.sectionSlider}>
                <Box className={classes.sectionLabel}>Number of Words</Box>
                <TextInput type="number" />
              </Box>
            </Box> */}
            {isPeopleOrProject && renderPeopleOrProject()}
          </Box>
        </div>
        <Box className={classes.storyToolbar}>
          <Box className={classes.sectionButtons} style={{}}>
            <div className="w-full">
              <div>
                <div className="text-xs text-grey-500 font-medium flex items-center gap-1">
                  <span className="ai-color">
                    <StarIcon className="w-4 h-4 mt-1 " />
                  </span>
                  Write your instructions for this section
                </div>
                <textarea
                  className="w-full text-sm p-2 input-base border-box rounded-lg mt-1 border-1 border-grey-200 "
                  style={{
                    fontFamily: 'PoppinsRegular'
                  }}
                  rows={6}
                  value={instructions}
                  onChange={(e) => {
                    setRequiresSave(true)
                    setSelectedSection({
                      ...selectedSection,
                      metadata_: {
                        ...metadata_,
                        instructions: e.target.value
                      }
                    })
                  }}
                />

                <div className="flex gap-2 items-center ">
                  <span className="text-xs">Words</span>
                  <Slider
                    marks={[
                      {
                        value: 50,
                        label: ''
                      },
                      {
                        value: 2000,
                        label: ''
                      }
                    ]}
                    step={10}
                    value={number_of_words}
                    min={50}
                    max={2000}
                    onChange={(e, value) => {
                      setRequiresSave(true)
                      setSelectedSection({
                        ...selectedSection,
                        metadata_: {
                          ...metadata_,
                          number_of_words: value
                        }
                      })
                    }}
                  />
                  <input
                    style={{
                      width: '45px'
                    }}
                    value={number_of_words}
                    onChange={(e) => {
                      setRequiresSave(true)
                      setSelectedSection({
                        ...selectedSection,
                        metadata_: {
                          ...metadata_,
                          number_of_words: e.target.value
                        }
                      })
                    }}
                    onBlur={(e) => {
                      const value = parseInt(e.target.value)
                      if (value < 50) {
                        setRequiresSave(true)
                        setSelectedSection({
                          ...selectedSection,
                          metadata_: {
                            ...metadata_,
                            number_of_words: 50
                          }
                        })
                      } else if (value > 2000) {
                        setRequiresSave(true)
                        setSelectedSection({
                          ...selectedSection,
                          metadata_: {
                            ...metadata_,
                            number_of_words: 2000
                          }
                        })
                      }
                    }}
                    type="number"
                    className="input-base border-1 border-grey-200 rounded-md p-1"
                  />
                </div>
              </div>
              <div className="flex justify-between w-full pt-2">
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget)
                    }}
                  >
                    <SettingsIcon
                      height={18}
                      width={18}
                      style={{
                        marginTop: '2px'
                      }}
                    />{' '}
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button'
                    }}
                    TransitionComponent={Fade}
                    anchorEl={anchorEl}
                    open={open}
                    elevation={2}
                    sx={{
                      borderRadius: '8px'
                    }}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem
                      className="m-0 text-xs p-2"
                      onClick={() => {
                        setShowHistory(true)
                        setAnchorEl(null)
                      }}
                    >
                      Section History
                    </MenuItem>
                    <MenuItem
                      className="m-0 text-xs p-2"
                      onClick={() => {
                        setAnchorEl(null)
                        handleDelete(id)
                      }}
                    >
                      Remove Section
                    </MenuItem>
                  </Menu>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    variant="outline"
                    disabled={disableGenerate}
                    // onClick={() => deleteSectionFromEditor(id)}
                    onClick={() => updateSection()}
                    sx={{
                      fontSize: '12px'
                    }}
                  >
                    Save & Exit
                  </Button>
                  <Button
                    onClick={() => handleSingleGenerate(selectedSection)}
                    disabled={disableGenerate}
                    sx={{
                      fontSize: '12px'
                    }}
                  >
                    <MagicWand
                      height={18}
                      width={18}
                      style={{
                        marginRight: '5px'
                      }}
                    />

                    {disableGenerate ? 'Generating' : 'Generate'}
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </>
    )
  }

  const handleAllGenerate = async () => {
    storyboards.forEach((section) => {
      const { status: sectionStatus } = section || {}
      const isDisabled = sectionStatus !== 'ready_to_be_generated'
      if (isDisabled) return
      const req = {
        draft_id: draftId,
        workspace_id: workspaceId,
        storyboard_id: section.id,
        storyboard_type: section.section_type,
        storyboard_config: section.metadata_,
        requestType: 'workspaceGenerate'
      }
      generateContentFromStory(req)
    })
  }

  const handleAllDelete = () => {
    storyboards.forEach((section) => {
      deleteSectionFromEditor(section.id)
    })
  }

  const handleSingleGenerate = async (section) => {
    const req = {
      draft_id: draftId,
      workspace_id: workspaceId,
      storyboard_id: section.id,
      storyboard_type: section.section_type,
      storyboard_config: section.metadata_,
      requestType: 'workspaceGenerate'
    }
    generateContentFromStory(req)
  }

  const navigateToProject = (projectId) => {
    window.open(`${LIBRARY_PROJECTS}/${projectId}`, '_blank')
  }

  const navigateToPeople = (peopleId) => {
    window.open(`${LIBRARY_PEOPLE_RESUME}/${peopleId}`, '_blank')
  }

  const handleAddProjects = (projects) => {
    const newProjects = projects.map((p) => {
      return {
        project_name: p.label,
        collection_id: p.value,
        reason: p.reason
      }
    })
    setRequiresSave(true)
    setSelectedSection({
      ...selectedSection,
      metadata_: {
        ...selectedSection.metadata_,
        projects: newProjects
      }
    })
    setShowSelectProject(false)
  }

  const handleSave = async () => {
    setSaving(true)
    const req = {
      storyboards,
      workspace_id: workspaceId,
      draft_id: draftId
    }
    const res = await saveDraftConfig(req)
    if (res.status === 200) {
      setRequiresSave(false)
      setStoryboardCopy(storyboards)
    } else {
      toast.error('Error saving draft')
    }
    setSaving(false)
  }

  const handleAddPeople = (selectedObj) => {
    const { peopleObj } = selectedObj
    const position = Object.keys(peopleObj)
    const positions = []
    const people = []
    position.forEach((pos) => {
      const { value, number_of_people } = peopleObj[pos]
      positions.push({
        title: pos,
        number_of_people
      })
      const peopleList = value.map((p) => {
        return {
          position: pos,
          name: p.label,
          face_group_id: p.value,
          ...p
        }
      })
      people.push(...peopleList)
    })
    setRequiresSave(true)
    setSelectedSection({
      ...selectedSection,
      metadata_: {
        ...selectedSection.metadata_,
        positions,
        people_recommendations: people
      }
    })
    setShowSelectPeople(false)
  }

  const renderPeopleOrProject = () => {
    const { id, metadata_ } = selectedSection
    const {
      projects = [],
      positions = [],
      section_type,
      people_recommendations = []
    } = metadata_ || {}

    return section_type === 'project_resume' ? (
      <Box className={classes.sectionHeaderInput}>
        <Box className={classes.sectionLabel}>
          <Box className="mt-2 mb-2 flex items-center gap-1 text-grey-500">
            <ProjectIcon className="size-5" />
            Projects
          </Box>
        </Box>

        <div className="flex flex-col gap-3">
          {projects.map((project, index) => {
            const { project_name, collection_id, reason } = project
            return (
              <Box
                key={index}
                className="parent-card border-1 border-grey-200 rounded-md p-2 px-3"
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'start'
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      color: 'var(--dark-primary)',
                      fontSize: '13px',
                      fontFamily: 'PoppinsMedium',
                      width: '80%'
                    }}
                  >
                    {index + 1}. {project_name}
                  </p>
                  <div className="card-options flex items-center ml-auto">
                    <Tooltip title="Open Project in a new tab">
                      <button
                        className="icon-btn rounded-md"
                        onClick={() => navigateToProject(collection_id)}
                      >
                        <RedirectIcon className="size-4" />
                      </button>
                    </Tooltip>
                    <Tooltip title="Remove">
                      <button
                        className="icon-btn rounded-md"
                        onClick={() => {
                          setRequiresSave(true)
                          setSelectedSection({
                            ...selectedSection,
                            metadata_: {
                              ...metadata_,
                              projects: projects.filter(
                                (p) => p.collection_id !== collection_id
                              )
                            }
                          })
                        }}
                      >
                        <DeleteIcon className="w-4 h-4" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                {reason && (
                  <p className="m-0 text-xs text-grey-600 mt-1">{reason}</p>
                )}
              </Box>
            )
          })}
        </div>

        <Button2
          styleHover={{}}
          className="w-full flex justify-center bg-grey-100 rounded-md text-dark mt-2"
          onClick={() => {
            const values = projects.map((p) => {
              return {
                label: p.project_name,
                value: p.collection_id,
                reason: p.reason
              }
            })
            setShowSelectProject(values)
          }}
          style={{
            color: 'var(--dark-primary)'
          }}
        >
          + Add Projects
        </Button2>
      </Box>
    ) : (
      <Box className={classes.sectionHeaderInput}>
        <Box
          className={classes.sectionLabel}
          style={{
            marginTop: '0.5rem'
          }}
        >
          <Box>Positions</Box>
          <Tooltip title="Add People">
            <IconButton
              onClick={() => {
                const peopleObj = {}
                positions.forEach((pos) => {
                  const { title, number_of_people } = pos
                  peopleObj[title] = {
                    value: people_recommendations.filter(
                      (p) => p.position === title
                    ),
                    number_of_people
                  }
                })
                setShowSelectPeople({ peopleObj })
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {positions.map((position, index) => {
          const { title, number_of_people } = position
          return (
            <Box key={index} className="mt-3">
              <Box
                className={classes.sectionLabel}
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'white',
                  borderBottom: '1px solid var(--grey-100)'
                }}
              >
                <p className="font-medium text-lg m-0 text-dark py-2 ml-1">
                  {title}{' '}
                  <span className="text-xs text-grey-600">
                    Recommended: {number_of_people}
                  </span>
                </p>
                <Tooltip title="Remove Position">
                  <IconButton
                    onClick={() => {
                      setRequiresSave(true)
                      setSelectedSection({
                        ...selectedSection,
                        metadata_: {
                          ...metadata_,
                          positions: positions.filter((p) => p.title !== title),
                          people_recommendations: people_recommendations.filter(
                            (p) => p.position !== title
                          )
                        }
                      })
                    }}
                  >
                    <DeleteIcon className="size-4" />
                  </IconButton>
                </Tooltip>
              </Box>
              {renderPeopleRecommendations(title, people_recommendations)}
            </Box>
          )
        })}
      </Box>
    )
  }

  const renderPeopleSelect = () => {
    const { selectedPosition = null, peopleObj = {} } = showSelectPeople || {}
    const peopleSelected = peopleObj[selectedPosition?.value]?.value
    const peopleValues = peopleSelected?.map((p) => {
      return {
        ...p,
        label: p.name,
        value: p.face_group_id
      }
    })
    const positions = Object.keys(peopleObj).map((key) => {
      return {
        label: key,
        value: key
      }
    })

    return (
      <Dialog
        open={showSelectPeople}
        onClose={() => setShowSelectPeople(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select People</DialogTitle>
        <DialogContent>
          <Box className={classes.sectionPeopleSelect}>
            <ReactSelect
              options={positions}
              isCreatable
              isClearable
              value={selectedPosition}
              onChange={(value) => {
                setShowSelectPeople({
                  ...showSelectPeople,
                  selectedPosition: value
                })
              }}
              placeholder="Select /Create Position"
              onCreateOption={(value) => {
                setShowSelectPeople({
                  ...showSelectPeople,
                  selectedPosition: {
                    value,
                    label: value
                  },
                  peopleObj: {
                    ...peopleObj,
                    [value]: {
                      value: []
                    }
                  }
                })
              }}
            />
          </Box>
          {selectedPosition && (
            <Box className={classes.sectionPeopleSelect}>
              <ReactSelect
                isMulti
                placeholder="Select People"
                options={peopleList}
                value={peopleValues}
                onChange={(value) => {
                  setShowSelectPeople({
                    ...showSelectPeople,
                    peopleObj: {
                      ...peopleObj,
                      [selectedPosition.value]: {
                        ...peopleObj[selectedPosition.value],
                        value
                      }
                    }
                  })
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleAddPeople(showSelectPeople)
            }}
          >
            Add People
          </Button>
          <Button variant="outline" onClick={() => setShowSelectPeople(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderProjectSelect = () => {
    return (
      <Dialog
        open={showSelectProject}
        onClose={() => setShowSelectProject(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select Project</DialogTitle>
        <DialogContent>
          <ReactSelect
            isMulti
            placeholder="Select Project"
            options={projectList}
            value={showSelectProject}
            onChange={(value) => {
              setShowSelectProject(value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleAddProjects(showSelectProject)
            }}
          >
            Add Project
          </Button>
          <Button variant="outline" onClick={() => setShowSelectProject(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderPeopleRecommendations = (title, people_recommendations = []) => {
    const people = people_recommendations.filter((p) => p.position === title)
    return (
      <div className="flex flex-col gap-3">
        {people.map((person, index) => {
          const {
            name,
            face_group_id,
            thumbnail_url,
            qualification,
            similar_project_experience,
            experience,
            expertise,
            is_added_by_user = false
          } = person

          console.log('person', person)
          return (
            <Box
              key={index}
              className="parent-card border-1 border-grey-200 rounded-md p-2 px-3"
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start'
                }}
              >
                <p
                  style={{
                    margin: 0,
                    color: 'var(--dark-primary)',
                    fontSize: '13px',
                    fontFamily: 'PoppinsMedium',
                    width: '80%'
                  }}
                >
                  {index + 1}. {name}
                </p>
                <div className="card-options flex items-center ml-auto">
                  <Tooltip title="Open People in a new tab">
                    <button
                      className="icon-btn rounded-md"
                      onClick={() => navigateToPeople(face_group_id)}
                    >
                      <RedirectIcon className="size-4" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Remove">
                    <button
                      className="icon-btn rounded-md"
                      onClick={() => {
                        setRequiresSave(true)
                        setSelectedSection({
                          ...selectedSection,
                          metadata_: {
                            ...selectedSection.metadata_,
                            people_recommendations:
                              people_recommendations.filter(
                                (p) => p.face_group_id !== face_group_id
                              )
                          }
                        })
                      }}
                    >
                      <DeleteIcon className="w-4 h-4" />
                    </button>
                  </Tooltip>
                </div>
              </div>
              <Box className={classes.subProjectReason}>
                {!is_added_by_user && (
                  <>
                    <div className="flex flex-col gap-2 text-xs ">
                      <Box>
                        <span className="font-medium text-dark">
                          Similar Project Experience:
                        </span>{' '}
                        {similar_project_experience}
                      </Box>
                      <Box>
                        <span className="font-medium text-dark">
                          Experience:
                        </span>{' '}
                        {experience}
                      </Box>
                      <Box>
                        <span className="font-medium text-dark">
                          Expertise:
                        </span>{' '}
                        {expertise}
                      </Box>
                    </div>
                  </>
                )}
              </Box>
            </Box>
          )
        })}
      </div>
    )
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: darkTheme ? 'var(--dark-bg)' : 'white'
      }}
    >
      {storyboards && (
        <div
          // parentRef={storyBoardRef}
          style={{
            position: 'relative',
            height: '100%'
          }}
        >
          {selectedSection ? (
            showHistory ? (
              <GenerationHistory
                storyboard_id={selectedSection?.id || ''}
                insertIntoEditor={insertIntoEditor}
                disabled={loadingStates[selectedSection?.id]}
                onClose={() => setShowHistory(false)}
              />
            ) : (
              renderSection()
            )
          ) : (
            <>
              <div
                style={{
                  height: 'calc(100% - 63px)',
                  overflow: 'auto',
                  backgroundColor: darkTheme ? '' : 'white'
                }}
              >
                <div
                  style={{
                    fontSize: '20px',
                    fontFamily: 'PoppinsMedium',
                    padding: '14px'
                  }}
                  className={`flex justify-between items-center ${
                    darkTheme ? 'text-grey-300' : ''
                  }`}
                >
                  <div>
                    Storyboard
                    <div
                      style={{
                        fontSize: '11px'
                      }}
                    >
                      {storyboards.length > 0
                        ? `${storyboards.length} sections`
                        : ''}
                    </div>
                  </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={classes.storyContainer}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {storyboards?.map((section, index) => {
                          const { metadata_, status: sectionStatus } =
                            section || {}
                          const isDisabled =
                            sectionStatus !== 'ready_to_be_generated'
                          const {
                            section_title,
                            sub_sections = [],
                            section_type,
                            positions = [],
                            projects = []
                          } = metadata_ || {}
                          const isPeopleOrProject =
                            section_type === 'project_resume' ||
                            section_type === 'people_resume'
                          return (
                            <Draggable
                              key={index}
                              draggableId={index.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <Fade
                                  in={transitionController}
                                  style={{
                                    transitionDelay: transitionDelayController
                                      ? `${500 + 100 * index}ms`
                                      : '0ms'
                                  }}
                                >
                                  <Box
                                    className={
                                      isDisabled
                                        ? clsx(
                                            classes.storyDisabled,
                                            classes.story,
                                            'parent-card'
                                          )
                                        : clsx(
                                            classes.story,
                                            'parent-card',
                                            darkTheme
                                              ? 'bg-dark-grey text-grey-300 '
                                              : 'bg-white border-05 border-grey-300'
                                          )
                                    }
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    onClick={() => {
                                      scrollToSection(section.id)
                                    }}
                                  >
                                    <div
                                      className={`flex items-start

                                      `}
                                    >
                                      <DragDotsIcon
                                        height={18}
                                        width={18}
                                        fill={
                                          isDisabled
                                            ? 'var(--disabled)'
                                            : darkTheme
                                            ? 'var(--grey-300)'
                                            : 'var(--dark-primary)'
                                        }
                                      />
                                      <p
                                        className={`m-0  font-medium ${
                                          darkTheme
                                            ? 'text-grey-300'
                                            : 'text-dark'
                                        }`}
                                        style={{
                                          fontSize: '13px',
                                          width: '80%'
                                        }}
                                      >
                                        {section_title}
                                      </p>
                                      <div className="card-options flex items-center ml-auto  ">
                                        <Tooltip title="Regenerate">
                                          <button
                                            className="icon-btn rounded-md"
                                            onClick={() => {
                                              if (!isDisabled) {
                                                handleSingleGenerate(section)
                                              }
                                            }}
                                          >
                                            <RegenerateIcon className="size-4" />
                                          </button>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                          <button
                                            className="icon-btn rounded-md"
                                            onClick={() => {
                                              if (!isDisabled) {
                                                setSelectedSection(section)
                                                setEditMeta(false)
                                              }
                                            }}
                                          >
                                            <EditIcon className="size-4" />
                                          </button>
                                        </Tooltip>
                                        <Tooltip title="Remove">
                                          <button
                                            className="icon-btn rounded-md"
                                            onClick={() => {
                                              handleDelete(section.id)
                                            }}
                                          >
                                            <DeleteIcon className="w-4 h-4" />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <div
                                      className={`text-xxs ml-4 mb-1 px-2 flex flex-col gap-1 empty card-empty
                                      ${
                                        darkTheme
                                          ? 'text-grey-400'
                                          : 'text-grey-700'
                                      }
                                      `}
                                    >
                                      {isPeopleOrProject ? (
                                        section_type === 'people_resume' ? (
                                          positions.map((position, index) => {
                                            const { title } = position
                                            return (
                                              <p className="m-0" key={index}>
                                                {title}
                                              </p>
                                            )
                                          })
                                        ) : (
                                          <p className="m-0">
                                            {projects.map((project, index) => {
                                              const { project_name } = project
                                              return (
                                                <p key={index} className="m-0">
                                                  {project_name}
                                                </p>
                                              )
                                            })}
                                          </p>
                                        )
                                      ) : (
                                        sub_sections.map(
                                          (subSection, subIndex) => (
                                            <p key={subIndex} className="m-0">
                                              {subSection}
                                            </p>
                                          )
                                        )
                                      )}
                                    </div>
                                  </Box>
                                </Fade>
                              )}
                            </Draggable>
                          )
                        })}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className="p-3">
                  <Button2
                    styleHover={{}}
                    className="w-full flex justify-center bg-grey-100 rounded-md text-dark"
                    onClick={() => {
                      handleAddNewStory()
                    }}
                    style={{
                      color: 'var(--dark-primary)'
                    }}
                  >
                    + Insert Section
                  </Button2>
                </div>
                {storyboards && storyboards.length === 0 && (
                  <Box
                    className={classes.noStories}
                    sx={{ textAlign: 'center' }}
                  >
                    No stories found
                  </Box>
                )}
              </div>
              {storyboards && !selectedSection && (
                <Box className={classes.storyToolbar}>
                  <Box className={classes.sectionButtons}>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                      }}
                    >
                      <SettingsIcon
                        height={18}
                        width={18}
                        style={{
                          marginTop: '2px'
                        }}
                      />{' '}
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button'
                      }}
                      TransitionComponent={Fade}
                      anchorEl={anchorEl}
                      open={open}
                      elevation={2}
                      sx={{
                        borderRadius: '8px'
                      }}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem
                        className="m-0 text-xs p-2"
                        onClick={() => {
                          handleSave()
                          setAnchorEl(null)
                        }}
                      >
                        Save Config
                      </MenuItem>
                      <MenuItem
                        className="m-0 text-xs p-2"
                        onClick={() => {
                          setAnchorEl(null)
                          handleReset()
                        }}
                      >
                        Reset to default
                      </MenuItem>
                      <MenuItem
                        className="m-0 text-xs p-2"
                        onClick={() => {
                          setAnchorEl(null)
                          handleAllDelete()
                        }}
                      >
                        Remove all sections
                      </MenuItem>
                    </Menu>
                    {enableGenerateAll ? (
                      <>
                        <Button
                          variant="outline"
                          disabled={!enableGenerateAll}
                          onClick={() => {
                            handleSave()
                          }}
                          sx={{
                            marginLeft: 'auto',
                            fontSize: '12px'
                          }}
                        >
                          Save Config
                        </Button>

                        <Button
                          disabled={!enableGenerateAll}
                          onClick={() => {
                            handleAllGenerate()
                          }}
                          sx={{
                            color: 'white',
                            fontSize: '12px'
                          }}
                        >
                          <MagicWand
                            height={18}
                            width={18}
                            style={{
                              marginRight: '5px'
                            }}
                          />{' '}
                          Generate
                        </Button>
                      </>
                    ) : (
                      <div className="text-xs font-medium bg-orange-200 text-orange-500 p-1 rounded-lg px-3 pulse flex items-center animate-pulse">
                        <MagicWand className="w-4 h-4 mr-1" /> Generating
                        Content
                      </div>
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}

          {renderProjectSelect()}
          {renderPeopleSelect()}
        </div>
      )}

      {/* {logsLoading && (
        <div className="p-6">
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
        </div>
      )} */}
    </div>
  )
}

export default Storyboard
