import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%'
  },
  childContainer: {
    height: 'calc(100% - 40px)',
    width: 'calc(100% - 40px)',
    padding: '20px'
  },
  textFeild: {
    width: '100%',
    marginTop: '20px',
    maxWidth: '800px'
  },
  titleText: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#797979'
  },
  subText: {
    fontSize: '16px',
    fontWeight: '400',
    marginTop: '10px',
    color: '#797979'
  },
  gradientTextStyle: {
    background: 'linear-gradient(100deg, #471CA8, #FF3465)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent !important',
    display: 'inline-block',
    '&:before': {
      content: 'attr(data-text)',
      position: 'absolute',
      left: '0',
      top: '0',
      zIndex: '-1'
    }
  }
})
export { useStyles }
