import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  key: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#A2A2A2',
    fontSize: '15px',
    minWidth: '100px'
  },
  data: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#000000',
    fontSize: '15px',
    textTransform: 'capitalize'
  },
  dataContainer: {
    display: 'flex',
    margin: '10px 0px'
  },
  loaderContainer: {
    textAlign: 'center'
  },
  cardAction: {
    justifyContent: 'center'
  },
  container: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  card: {
    width: '300px',
    marginBottom: 8,
    height: '100%'
  },
  cardContent: {
    textAlign: 'start'
  },
  container1: {
    padding: 16,
    display: 'flex',
    justifyContent: 'center'
  }
}))

export { useStyles }
