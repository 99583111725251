import { initalizeS3, getSignedUrl } from '../../utils/AWS'
import axios from 'axios'
import _, { isEmpty } from 'lodash'

export const initalizeDownload = async (url, name = 'untitled', s3Obj) => {
  const s3 = !isEmpty(s3Obj) ? s3Obj : await initalizeS3()
  if (!isEmpty(url) && !isEmpty(s3)) {
    let error = false
    const encodedName = encodeURIComponent(name);
    url = await getSignedUrl(url, s3, encodedName).catch((e) => {
      console.log(e)
      error = true
    })
    if (!error) {
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
