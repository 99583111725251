import { FETCH_DOMAIN_SETTINGS } from '../../../actionTypes'
import { getUserDomain } from '../../../api'
import { toast } from 'react-toastify'

const fetchDomainSettings = () => async (dispatch) => {
  const res = await getUserDomain()
  if (res.status === 200) {
    const domain = res.data
    dispatch({
      type: FETCH_DOMAIN_SETTINGS,
      payload: domain
    })
  }
}

export { fetchDomainSettings }
