import { getIntercomSecret } from '../../store/api'

export const addInterCom = () => {
  const intercomScript = document.createElement('script')
  const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID
  intercomScript.innerHTML = `
    (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/${APP_ID}'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
       `
  return intercomScript
}

export const initializeIntercom = async (userData) => {
  const { id = '', email = '', user_name = '', domain_name = '' } = userData
  const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID
  const USER_ID = id
  let USER_HASH = ''
  const response = await getIntercomSecret()
  if (response.status === 200) {
    if (response?.data?.result?.intercom_hash_key) {
      USER_HASH = response.data.result.intercom_hash_key
    }
  }

  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: APP_ID,
    user_id: USER_ID,
    email,
    name: user_name,
    company_name: domain_name,
    user_hash: USER_HASH,
    environment: process.env.REACT_APP_ENV
  })
}
