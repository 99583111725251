import { EMPTY_KEYWORD_REGEXP } from './constants.js'

// `$&` means the whole matched string
const escapeRegExp = (input) =>
  input && input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export const normalizeFlagKeyword = (flagKeyword) => {
  const source = flagKeyword.wholeWords
    ? ` ${flagKeyword.sentence} `
    : flagKeyword.sentence
  const flags = flagKeyword.matchCase ? 'g' : 'gi'
  const sourcekey = flagKeyword.wholeWords
    ? ` ${flagKeyword.keyword} `
    : flagKeyword.keyword
  const flagskey = flagKeyword.matchCase ? 'g' : 'gi'

  return {
    keyword: flagKeyword.keyword,
    regExp: new RegExp(escapeRegExp(source), flags),
    keywordRegExp: new RegExp(escapeRegExp(sourcekey), flagskey),
    wholeWords: flagKeyword.wholeWords || false
  }
}

export const normalizeSingleKeyword = (
  keyword,
  matchCase = true,
  wholeWords
) => {
  if (keyword instanceof RegExp) {
    return {
      keyword: keyword.source,
      regExp: keyword,
      wholeWords: wholeWords || false
    }
  }

  // Normalize a string keyword
  if (typeof keyword === 'string') {
    return keyword === ''
      ? EMPTY_KEYWORD_REGEXP
      : normalizeFlagKeyword({
          keyword,
          matchCase: matchCase || false,
          wholeWords: wholeWords || false
        })
  }

  // Normalize a keyword with flags
  if (typeof matchCase !== 'undefined') {
    keyword.matchCase = matchCase
  }
  if (typeof wholeWords !== 'undefined') {
    keyword.wholeWords = wholeWords
  }
  let temp = normalizeFlagKeyword(keyword, keyword)
  temp = { ...keyword, ...temp }
  return temp
}
