import Button from '@mui/material/Button'
import { useStyles } from './styles'
import clsx from 'clsx'
const Btn = (props) => {
  const { id = 'button', className = {}, variant, children, ...rest } = props
  const classes = useStyles()
  const { defaultButton } = classes

  return (
    <Button
      id={id}
      className={clsx(defaultButton, className)}
      variant={variant || 'contained'}
      {...rest}
      disableElevation
    >
      {children}
    </Button>
  )
}
export default Btn
