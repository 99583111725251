import { clone, isArray, findIndex } from 'lodash'
import {
  FETCH_DOMAIN_SETTINGS,
  DELETE_DOMAIN_INVITES,
  UPDATE_DOMAIN_INVITES,
  ADD_DOMAIN_INVITES,
  FETCH_DOMAIN_INVITES,
  UPDATE_DOMAIN_SETTINGS,
  FETCH_ALL_DOMAIN_LIST
} from '../../actionTypes'

const initialState = {
  domain: {},
  invites: null
}

function settingsList(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case FETCH_ALL_DOMAIN_LIST:
      return {
        ...state,
        domainList: payload
      }
    case FETCH_DOMAIN_SETTINGS:
      return {
        ...state,
        domain: payload
      }
    case FETCH_DOMAIN_INVITES:
      return {
        ...state,
        invites: payload
      }
    case UPDATE_DOMAIN_SETTINGS: {
      let updatedDomain = { ...state.domain }
      updatedDomain = { ...updatedDomain, ...payload }
      return {
        ...state,
        domain: updatedDomain
      }
    }
    case ADD_DOMAIN_INVITES: {
      const newList = [...payload, ...state.invites]
      return {
        ...state,
        invites: newList
      }
    }
    case DELETE_DOMAIN_INVITES: {
      const newLists = clone(state.invites)
      const index = findIndex(newLists, { id: payload.id })
      newLists.splice(index, 1)
      return {
        ...state,
        invites: newLists
      }
    }
    case UPDATE_DOMAIN_INVITES: {
      const updatedList = clone(state.invites)
      const elementIndex = findIndex(updatedList, { id: payload.id })
      updatedList[elementIndex] = payload
      return {
        ...state,
        invites: updatedList
      }
    }
    default:
      return state
  }
}

export default settingsList
