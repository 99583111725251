const isObjectEmpty = (object) => {
  if (object && Object.keys(object).length > 0) return false
  else return true
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const validDate = (date) => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date)) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

export { isObjectEmpty, validateEmail, validDate }
