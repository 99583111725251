import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  bgImage: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    filter: 'brightness(0.875)'
  },
  searchField: {
    width: '100%',
    marginTop: '25px',
    '& .MuiInputBase-adornedStart ': {
      background: 'white'
    }
  },
  container: {
    color: 'white',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10%'
  },
  timeContainer: {
    fontSize: '19px',
    zIndex: '0',
    fontFamily: 'PoppinsRegular',
    fontWeight: '700',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backdropFilter: 'blur(2px)',
    color: '#000000',
    textAlign: 'center'
  },
  greetingContainer: {
    marginTop: '10px',
    fontSize: '24px',
    zIndex: '0',
    fontFamily: 'PoppinsRegular',
    fontWeight: '800',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backdropFilter: 'blur(2px)',
    color: '#000000',
    textAlign: 'center'
  },
  recentBox: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '600',
    fontSize: '14px',
    color: '#FFFFFF',
    zIndex: '0',
    display: 'flex',
    alignItems: 'baseline'
  },
  recentContainter: {
    display: 'flex',
    alignItems: 'baseline',
    width: '450px',
    flexWrap: 'wrap'
  },
  recentBlur: {
    backdropFilter: 'blur(2px)'
  },
  recentWrapper: {
    background: 'rgba(255, 255, 255, 0.5)',
    border: '0.5px solid #949494',
    borderRadius: '8px',
    padding: '5px 12px',
    margin: '10px 5px',
    color: '#000000',
    fontStyle: 'normal',
    cursor: 'pointer',

    backdropFilter: 'blur(2px)',
    display: 'flex',
    alignItems: 'center'
  },
  radioWrapper: {
    margin: '10px 5px',
    color: 'black'
  },
  radioInputContainer: {
    width: '100%',
    paddingLeft: '45px'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  recentWrapperText: {
    maxWidth: '350px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block'
  },
  searchInputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'rgba(224, 224, 224, 0.9)',
    borderRadius: '6px',
    padding: '20px 40px'
  },
  searchInpurOutterWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    maxWidth: '1000px'
  },
  enterIcon: {
    width: '30px',
    height: '30px',
    cursor: 'pointer'
  },
  disableEnter: {
    pointerEvents: 'none',
    opacity: 0.4
  },
  searchTypeHightlight: {
    color: '#9f9d9d'
  },
  typeSelection: {
    paddingRight: '5px'
  },
  iconSearchBar: {
    '& .MuiSvgIcon-root': {
      padding: '2.5px 0px'
    }
  }
})

export { useStyles }
