import { createContext, useContext, useState } from 'react'

const ColorContext = createContext()

export const ThemeContext = ({ children }) => {
  const [selectedColor, setSelectedColor] = useState('#0645AD')
  const [navigationColor, setNavigationColor] = useState('#131516')
  const [backgroundWallpaper, setBackgroundWallpaper] = useState(null)
  const [logoImage, setLogoImage] = useState(null)

  const updateColor = (newColor) => {
    setSelectedColor(newColor)
  }
  const updateLogo = (newLogo) => {
    setLogoImage(newLogo)
  }
  const updateNavigationColor = (newColor) => {
    setNavigationColor(newColor)
  }
  const updateBackgroundWallpaper = (newWallpaper) => {
    setBackgroundWallpaper(newWallpaper)
  }

  return (
    <ColorContext.Provider
      value={{
        selectedColor,
        updateColor,
        logoImage,
        updateLogo,
        navigationColor,
        updateNavigationColor,
        backgroundWallpaper,
        updateBackgroundWallpaper
      }}
    >
      {children}
    </ColorContext.Provider>
  )
}

export const useColor = () => {
  return useContext(ColorContext)
}
