const eventMapping = {
  SET_DASHBOARD_SEARCH_RESULTS: 'search_content_dashboard',
  SET_WORKSPACE_SEARCH_RESULTS: 'search_content_workspace',
  PROPOSAL_FILTER: 'proposal_filter_clicked',
  DRAFT_FILTER: 'draft_filter_clicked',
  ANALYTICS_FILTER: 'analytics_filter_clicked',
  ASSETS_FILTER: 'assets_filter_clicked',
  SEARCH_CONTENT: 'search_content_editor',
  SAVED_TEXT: 'saved_text',
  SAVED_IMAGE: 'saved_image',
  SAVED_LIBRARY: 'saved_library',
  UNSAVED_TEXT: 'unsaved_text',
  UNSAVED_IMAGE: 'unsaved_image',
  UNSAVED_LIBRARY: 'unsaved_library',
  GENERATE_CONTENT: 'generate_content',
  CREATE_DOCUMENT: 'create_document',
  UPDATE_DOCUMENT: 'update_document',
  DELETE_DOCUMENT: 'delete_document',
  CREATE_SAVE_CONTENT: 'create_save_content',
  CREATE_LIBRARY_DOCUMENT: 'create_library_document',
  UPDATE_LIBRARY_DOCUMENT: 'update_library_document',
  DELETE_LIBRARY_DOCUMENT: 'delete_library_document',
  UPLOAD_PROPOSALS: 'upload_proposals',
  UPDATE_PROPOSALS: 'update_proposals',
  DELETE_PROPOSALS: 'delete_proposals',
  UPLOAD_ANALYTICS: 'upload_analytics',
  UPDATE_ANALYTICS: 'update_analytic',
  DELETE_ANALYTIC: 'delete_analytics',
  OPEN_SCREENSHOT: 'open_screenshot',
  USER_SIGN_IN: 'user_sign_in',
  USER_SIGN_OUT: 'user_sign_out',
  ADDED_TO_COLLECTION: 'added_to_collection',
  COPY_SEARCH_CONTENT: 'copy_search_content',
  PROFILE_DETAILS_UPDATED: 'profile_updated',
  DOMAIN_SETTINGS_UPDATED: 'domain_settings_updated',
  USER_INVITE_SENT: 'user_invited',
  AI_GENERATE: 'AI_Generate_used',
  UPLOAD_RFX: 'upload_rfx',
  DELETE_RFX: 'delete_rfx',
  DOWNLOAD_COMPLIANCE_MATRIX: 'download_compliance_matrix',
  CHAT_INIT_PROPOSAL: 'chat_init_proposal',
  CHAT_INIT_ANALYTICS: 'chat_init_analytics',
  CHAT_INIT_RFX: 'chat_init_rfx',
  CHAT_INIT_WORKSPACE: 'chat_init_workspace',
  CHAT_COPY_CONTENT: 'chat_copy_content',
  CHAT_STOP_GENERATION: 'chat_stop_generation',
  CHAT_RESPONSE_ERRORED: 'chat_errored'
}

export default eventMapping
