import { Container, Section, SectionFixed } from '../../components/Container'
import { useStyles } from './assetstyles'
import { Box, Tooltip, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const Wrapper = (props) => {
  const {
    onClose = () => {},
    file_name = '',
    children,
    childStyle = {
      overflow: 'auto',
      padding: '20px',
      height: 'calc(100% - 40px)',
      width: 'calc(100% - 40px)'
    }
  } = props
  const classes = useStyles()

  return (
    <Container>
      <Box className={classes.pdfContainer}>
        <SectionFixed className={classes.headerContainer}>
          <Box className={classes.editableHeader}>
            <Box>
              <div
                dangerouslySetInnerHTML={{
                  __html: file_name
                }}
              />
            </Box>
          </Box>
          {onClose && (
            <Box className={classes.closeIconWrapper}>
              <Tooltip title="Close">
                <IconButton disableRipple onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </SectionFixed>
        <Section overFlow={true}>
          <Box sx={childStyle}>{children}</Box>
        </Section>
      </Box>
    </Container>
  )
}

export default Wrapper
