import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AnalyticsPDFView from '../../sections/AnalyticsPDFView'
import { ROUTES } from '../../config/routes'
import { fetchAnalytic } from '../../store/Analytics/Actions'
import Loader from '../../components/Loader'
import { initalizeS3, getSignedUrl } from '../../utils/AWS'
import { isEmpty, cloneDeep } from 'lodash'

const AnalyticsPDFViewer = (props) => {
  const { pdf_location = '', analyticSharedId = '' } = props
  const analytic = useSelector((state) => state?.analyticList?.analytic)
  const { ANALYTICS } = ROUTES
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { analyticId, type } = useParams()
  const [initialLoad, setInitialLoad] = useState(true)
  const [loading, setLoading] = useState(true)
  const [PDFData, setPDFData] = useState({})
  const [error, setError] = useState('')
  const [s3Obj, sets3Obj] = useState({})
  const [PDFUrl, setPDFUrl] = useState(pdf_location)

  useEffect(() => {
    dispatch(fetchAnalytic(analyticId))
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    if (!analyticSharedId) {
      initalizeData()
    }
  }, [])

  useEffect(() => {
    async function initalizeData() {
      const newAnalyticObj = cloneDeep(analytic)

      if (initialLoad && !isEmpty(analytic) && !isEmpty(s3Obj)) {
        let error = false
        let url = await getPDFURL(newAnalyticObj)
        url = await getSignedUrl(url, s3Obj).catch(() => {
          setError('analytics pdf not found')
          error = true
        })
        setInitialLoad(false)
        if (!error) {
          setPDFUrl(url)
          setLoading(false)
          setPDFData(newAnalyticObj)
        }
      } else {
        if (!isEmpty(analytic)) {
          setPDFData(newAnalyticObj)
        }
      }
    }
    initalizeData()
  }, [analytic, s3Obj])

  const getPDFURL = async (data) => {
    const { processed_pdf_location, pdf_location } = data
    const uri = type === 'analytics' ? processed_pdf_location : pdf_location
    return uri
  }

  const handlePdfBack = () => {
    navigate(-1)
  }

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ width: '100%', height: '100vh' }}
      >
        <Loader loading={loading} caption="opening your pdf" />
      </Grid>
    )
  }

  return (
    <Fragment>
      <AnalyticsPDFView
        setShowPDF={handlePdfBack}
        PDFData={PDFData}
        PDFUrl={PDFUrl}
        isAnalytics={type === 'analytics' || type === 'analytics-in-process'}
        inProcess={type === 'analytics-in-process'}
      />
    </Fragment>
  )
}

export default AnalyticsPDFViewer
