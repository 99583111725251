import { UPDATE_DOMAIN_SETTINGS } from '../../../actionTypes'
import { putUserDomain } from '../../../api'
import { toast } from 'react-toastify'

const updateDomainSettings =
  (data, successCallback = () => {}, failureCallback = () => {}) =>
  async (dispatch) => {
    const res = await putUserDomain(data)
    if (res.status === 200) {
      toast.success('Updated Successfully')
      dispatch({
        type: UPDATE_DOMAIN_SETTINGS,
        payload: data,
        callback: successCallback
      })
    } else {
      failureCallback()
      toast.error('Failed to update')
    }
  }

export { updateDomainSettings }
