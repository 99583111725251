import { useEffect } from 'react'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core'
import { searchPlugin } from './customKeywordSearch'

const PDFViewer = (props) => {
  const {
    url,
    pageNavigationPluginInstance,
    setPdfCurrentPage,
    customPageTypeElement
  } = props
  let { pageNumber } = props
  pageNumber = pageNumber - 1 > 0 ? pageNumber - 1 : pageNumber
  const toolbarPluginInstance = toolbarPlugin()
  const { renderDefaultToolbar } = toolbarPluginInstance
  const transform = (slot) => {
    return {
      ...slot,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      SwitchScrollMode: () => <></>,
      SwitchScrollModeMenuItem: () => <></>
    }
  }

  const renderToolbar = (Toolbar) => (
    <>
      <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
      {customPageTypeElement && customPageTypeElement}
    </>
  )

  const searchPluginInstance = searchPlugin(props)

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar
  })

  return (
    <Viewer
      plugins={[
        defaultLayoutPluginInstance,
        searchPluginInstance,
        pageNavigationPluginInstance
      ]}
      fileUrl={url}
      initialPage={pageNumber}
      defaultScale={SpecialZoomLevel.PageFit}
      onPageChange={(page) => {
        setPdfCurrentPage(page?.currentPage)
      }}
    />
  )
}

export default PDFViewer
