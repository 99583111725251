import React, { useEffect, useState, Fragment } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import Button from '../../components/Button'
import { Auth } from 'aws-amplify'
import { useSelector, useDispatch } from 'react-redux'
import { ROUTES } from '../../config/routes'
import Container from '@mui/material/Container'
import Loader from '../../components/Loader'
import { useStyles } from './styles'

const Landing = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const isAuthenticated = useSelector(
    (state) => state.authenticate.isAuthenticated
  )
  const dispatch = useDispatch()
  const classes = useStyles()
  const { container, landingImageLogo, loginButton } = classes

  useEffect(() => {
    const code = searchParams.get('code')
    const state = searchParams.get('state')
    async function initalize() {
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (user) {
          if (state?.includes('/')) {
            navigate(state)
          } else {
            navigate(ROUTES.DASHBOARD)
          }
        }
      } catch (e) {
        if (!location.search.includes('code')) {
          redirectLogin()
        }
      }
    }
    initalize()
  }, [location, isAuthenticated])

  const redirectLogin = () => {
    navigate(ROUTES.SIGNIN)
  }

  return (
    <Container className={container}>
      <Loader loading={true} caption={''} />
    </Container>
  )
}

export default Landing
