import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor() // Get the selected color from the context

  return {
    actionContianer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    cancelButton: {
      background: '#ffffff',
      border: '1px solid #949494',
      color: '#949494',
      fontFamily: 'PoppinsRegular',
      fontWeight: '600',
      '&:hover': {
        background: '#f4f4f4'
      },
      textTransform: 'none'
    },
    submitButton: {
      background: selectedColor,
      color: '#ffffff',
      marginLeft: '8px',
      fontFamily: 'PoppinsRegular',
      fontWeight: '600',
      textTransform: 'none',
      '&:hover': {
        background: '#0a56d2'
      }
    },
    dialogHeader: {
      fontSize: '20px',
      fontFamily: 'PoppinsRegular',
      fontWeight: 'bold',
      color: '#000000',
      margin: '0px !important'
    },
    dialogContents: {
      fontSize: '18px',
      fontFamily: 'PoppinsRegular',
      color: '#000000',
      margin: '0px !important'
    },
    contentWrapper: {
      padding: '20px'
    }
  }
})

export { useStyles }
