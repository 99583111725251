import ProjectResult from '../ProjectResult'

const ProjectViewer = (props) => {
  const {
    id,
    data = {},
    onClose = () => {},
    onDeleteCallback = () => {},
    onUpdateCallback = () => {},
    setFile = () => {},
    isSuperUser = false,
    isAdmin = false,
    s3Obj = {}
  } = props

  return (
    <ProjectResult
      data={data}
      openProject={true}
      handleCloseProject={onClose}
      s3Obj={s3Obj}
      showBackButton={false}
    />
  )
}

export default ProjectViewer
