import {
  CREATE_LIBRARY_DOCUMENT,
  FETCH_LIBRARY,
  CLEAR_LIBRARY,
  DELETE_LIBRARY_DOCUMENT,
  UPDATE_LIBRARY_DOCUMENT,
  CLEAR_LIBRARY_DOCUMENT,
  CREATE_LIBRARY_FROM_EDITOR,
  FETCH_LIBRARY_COLLECTIONS,
  UPDATE_LIBRARY_COLLECTIONS,
  DELETE_LIBRARY_COLLECTIONS,
  SET_ACTIVE_LIBRARY_TAG,
  ADD_LIBRARY,
  UPDATE_LIBRARY_SUGGESTION,
  UPDATE_LIBRARY_COLLECTION_NAME,
  UPDATE_LIBRARY_VARIAITONS,
  UPDATE_LIBRARY_STATUS,
  UPDATE_LIBRARY_COLLECTIONS_STATUS,
  UPDATE_LIBRARY_COLLECTIONS_VIEWS
} from '../../actionTypes'
import { cloneDeep, findIndex, isArray } from 'lodash'
import { PageSize } from '../../../config/constants'

const initialState = {
  libraryList: null,
  search: null,
  createLibrary: {},
  libraryCollections: null,
  activeTag: { name: '', visibility: '' },
  totalCount: null,
  currentPage: null,
  pageSize: null,
  has_more: null,
  suggestedCount: null
}

function libraryList(state = initialState, action) {
  const { type, payload } = action
  let newDocsList = []
  let index
  switch (type) {
    case FETCH_LIBRARY:
      return {
        ...state,
        ...payload
      }
    case ADD_LIBRARY: {
      const { libraryList = [], currentPage = 1, has_more = null } = payload
      if (_.isArray(state?.libraryList)) {
        return {
          ...state,
          libraryList: [...(state?.libraryList || []), ...(libraryList || [])],
          currentPage,
          has_more
        }
      } else {
        return {
          ...state,
          libraryList,
          currentPage,
          has_more
        }
      }
    }

    case CLEAR_LIBRARY:
      return {
        ...state,
        search: null,
        libraryList: true,
        totalCount: null,
        currentPage: null,
        pageSize: null
      }
    case CREATE_LIBRARY_DOCUMENT: {
      const { document = {} } = payload
      const { id = '', collections = {} } = document
      newDocsList = cloneDeep(state.libraryList)
      const activeTag = state.activeTag
      const {
        name: currActiveName = '',
        visibility: currActiveVisibility = '',
        id: currentActiveId = ''
      } = activeTag
      let isAdd = false
      if (currActiveVisibility === 'public' && currActiveName === '') {
        isAdd = Object.values(collections).some(
          (obj) => obj.visibility === 'public'
        )
      } else if (currActiveVisibility === 'private' && currActiveName === '') {
        isAdd = Object.values(collections).some(
          (obj) => obj.visibility === 'private'
        )
      }
      if (
        (currActiveName === '' && currActiveVisibility === '') ||
        Object.keys(collections).includes(currentActiveId) ||
        isAdd
      ) {
        if (isArray(newDocsList)) {
          newDocsList.unshift({ ...document })
        } else {
          newDocsList = [{ ...document }]
        }
        if (newDocsList.length > PageSize) {
          newDocsList.pop()
        }
      }
      const { public_collections = [], private_collections = [] } = _.cloneDeep(
        state.libraryCollections
      )
      Object.keys(collections || {}).forEach((key) => {
        if (collections[key].visibility === 'public') {
          const pubidx = _.findIndex(public_collections, { id: key })
          if (pubidx > -1) {
            let { library_id = [] } = public_collections[pubidx]
            library_id = !library_id ? [] : library_id
            const newLibList = _.uniq([...library_id, id])
            public_collections[pubidx] = {
              ...public_collections[pubidx],
              library_id: newLibList
            }
          }
        } else {
          const prividx = _.findIndex(private_collections, { id: key })
          if (prividx > -1) {
            let { library_id = [] } = private_collections[prividx]
            library_id = !library_id ? [] : library_id
            const newLibList = _.uniq([...library_id, id])
            private_collections[prividx] = {
              ...private_collections[prividx],
              library_id: newLibList
            }
          }
        }
      })

      return {
        ...state,
        createLibrary: payload,
        libraryList: newDocsList,
        totalCount: state.totalCount + 1,
        libraryCollections: { public_collections, private_collections }
        // TODO Library immutable error
      }
    }
    case CREATE_LIBRARY_FROM_EDITOR: {
      const { document = {} } = payload
      const { id = '', collections = {} } = document
      newDocsList = cloneDeep(state.libraryList)
      const activeTag = state.activeTag
      const {
        name: currActiveName = '',
        visibility: currActiveVisibility = '',
        id: currentActiveId = ''
      } = activeTag
      let isAdd = false
      if (currActiveVisibility === 'public' && currActiveName === '') {
        isAdd = Object.values(collections).some(
          (obj) => obj.visibility === 'public'
        )
      }
      if (currActiveVisibility === 'private' && currActiveName === '') {
        isAdd = Object.values(collections).some(
          (obj) => obj.visibility === 'private'
        )
      }
      if (
        (currActiveName === '' && currActiveVisibility === '') ||
        Object.keys(collections).includes(currentActiveId) ||
        isAdd
      ) {
        if (isArray(newDocsList)) {
          newDocsList.unshift({ ...document })
        } else {
          newDocsList = [{ ...document }]
        }
        if (newDocsList.length > PageSize) {
          newDocsList.pop()
        }
      }
      const { public_collections = [], private_collections = [] } = _.cloneDeep(
        state.libraryCollections
      )
      Object.keys(collections || {}).forEach((key) => {
        if (collections[key].visibility === 'public') {
          const pubidx = _.findIndex(public_collections, { id: key })
          if (pubidx > -1) {
            let { library_id = [] } = public_collections[pubidx]
            library_id = !library_id ? [] : library_id
            const newLibList = _.uniq([...library_id, id])
            public_collections[pubidx] = {
              ...public_collections[pubidx],
              library_id: newLibList
            }
          }
        } else {
          const prividx = _.findIndex(private_collections, { id: key })
          if (prividx > -1) {
            let { library_id = [] } = private_collections[prividx]
            library_id = !library_id ? [] : library_id
            const newLibList = _.uniq([...library_id, id])
            private_collections[prividx] = {
              ...private_collections[prividx],
              library_id: newLibList
            }
          }
        }
      })
      return {
        ...state,
        libraryCollections: { public_collections, private_collections },
        libraryList: newDocsList,
        totalCount: state.totalCount + 1
      }
    }
    case CLEAR_LIBRARY_DOCUMENT:
      return {
        ...state,
        createLibrary: {}
      }
    case UPDATE_LIBRARY_DOCUMENT: {
      const { id, collections: payloadCollections = [] } = payload
      const newDocsList = cloneDeep(state.libraryList || [])
      const index = findIndex(newDocsList, { id })
      const oldCollections = Object.keys(newDocsList[index]?.collections || {})
      const newCollectionsObj = {}
      const { public_collections = [], private_collections = [] } = cloneDeep(
        state.libraryCollections
      )
      const collectionTotal = [...public_collections, ...private_collections]
      const newPrivate = [...private_collections]
      const newPublic = [...public_collections]
      const diff = oldCollections.filter((x) => !payloadCollections.includes(x))
      payloadCollections.forEach((ids) => {
        const idx = _.findIndex(collectionTotal, { id: ids })
        if (idx > -1) {
          newCollectionsObj[ids] = collectionTotal[idx]
        }
        const pubidx = _.findIndex(newPublic, { id: ids })
        if (pubidx > -1) {
          let { library_id = [] } = newPublic[pubidx]
          library_id = !library_id ? [] : library_id
          const newLibList = _.uniq([...library_id, id])
          newPublic[pubidx] = { ...newPublic[pubidx], library_id: newLibList }
        } else {
          const prividx = _.findIndex(newPrivate, { id: ids })
          if (prividx > -1) {
            let { library_id = [] } = newPrivate[prividx]
            library_id = !library_id ? [] : library_id
            const newLibList = _.uniq([...library_id, id])
            newPrivate[prividx] = {
              ...newPrivate[prividx],
              library_id: newLibList
            }
          }
        }
      })
      diff.forEach((ids) => {
        const pubidx = _.findIndex(newPublic, { id: ids })
        if (pubidx > -1) {
          let { library_id = [] } = newPublic[pubidx]
          library_id = !library_id ? [] : library_id
          const newLibList = library_id.filter((item) => item !== id)
          newPublic[pubidx] = { ...newPublic[pubidx], library_id: newLibList }
        } else {
          const prividx = _.findIndex(newPrivate, { id: ids })
          if (prividx > -1) {
            let { library_id = [] } = newPrivate[prividx]
            library_id = !library_id ? [] : library_id
            const newLibList = library_id.filter((item) => item !== id)
            newPrivate[prividx] = {
              ...newPrivate[prividx],
              library_id: newLibList
            }
          }
        }
      })
      payload.collections = newCollectionsObj
      const activeTag = cloneDeep(state.activeTag)
      const {
        name: currActiveName = '',
        visibility: currActiveVisibility = '',
        id: currentActiveId = ''
      } = activeTag
      let isAdd = false
      if (currActiveVisibility === 'public' && currActiveName === '') {
        isAdd = Object.values(newCollectionsObj).some(
          (obj) => obj.visibility === 'public'
        )
      }
      if (currActiveVisibility === 'private' && currActiveName === '') {
        isAdd = Object.values(newCollectionsObj).some(
          (obj) => obj.visibility === 'private'
        )
      }
      if (index > -1) {
        if (
          (currActiveName === '' && currActiveVisibility === '') ||
          Object.keys(newCollectionsObj).includes(currentActiveId) ||
          isAdd
        ) {
          if ('document_content' in payload) {
            delete payload.document_content
          }
          newDocsList[index] = { ...newDocsList[index], ...payload }
        } else {
          newDocsList.splice(index, 1)
        }
      }
      const newCollections = {
        public_collections: newPublic,
        private_collections: newPrivate
      }
      return {
        ...state,
        libraryList: newDocsList,
        libraryCollections: newCollections
      }
    }
    case DELETE_LIBRARY_DOCUMENT: {
      const newDocsList = cloneDeep(state.libraryList)
      const { id, collections } = payload
      const { public_collections = [], private_collections = [] } = _.cloneDeep(
        state.libraryCollections
      )
      Object.keys(collections || {}).forEach((key) => {
        if (collections[key]?.visibility === 'public') {
          const pubidx = _.findIndex(public_collections, { id: key })
          if (pubidx > -1) {
            let { library_id = [] } = public_collections[pubidx]
            library_id = !library_id ? [] : library_id
            const newLibList = library_id.filter((item) => item !== id)
            public_collections[pubidx] = {
              ...public_collections[pubidx],
              library_id: newLibList
            }
          }
        } else {
          const prividx = _.findIndex(private_collections, { id: key })
          if (prividx > -1) {
            let { library_id = [] } = private_collections[prividx]
            library_id = !library_id ? [] : library_id
            const newLibList = library_id.filter((item) => item !== id)
            private_collections[prividx] = {
              ...private_collections[prividx],
              library_id: newLibList
            }
          }
        }
      })
      const index = findIndex(newDocsList, { id })
      if (index > -1) {
        newDocsList.splice(index, 1)
      }
      return {
        ...state,
        libraryList: newDocsList,
        totalCount: state.totalCount - 1 > 0 ? state.totalCount - 1 : 0,
        libraryCollections: { public_collections, private_collections }
      }
    }
    case FETCH_LIBRARY_COLLECTIONS:
      return {
        ...state,
        ...payload
      }
    case UPDATE_LIBRARY_COLLECTIONS: {
      const { public_collections = [], private_collections = [] } = _.cloneDeep(
        state.libraryCollections
      )
      const { visibility = 'public' } = payload
      if (visibility === 'public') {
        public_collections.push(payload)
      } else {
        private_collections.push(payload)
      }
      const newCollections = { public_collections, private_collections }
      return {
        ...state,
        libraryCollections: newCollections
      }
    }
    case DELETE_LIBRARY_COLLECTIONS: {
      const { public_collections = [], private_collections = [] } = _.cloneDeep(
        state.libraryCollections
      )
      const { id, visibility } = payload
      if (visibility === 'public') {
        const index = findIndex(public_collections, { id })
        if (index > -1) {
          public_collections.splice(index, 1)
        }
      } else {
        const index = findIndex(private_collections, { id })
        if (index > -1) {
          private_collections.splice(index, 1)
        }
      }
      const newCollections = { public_collections, private_collections }
      let newLibList = []
      let newActiveTag = cloneDeep(state.activeTag)
      if (newActiveTag.id === id) {
        newActiveTag = { name: '', visibility: '' }
      } else {
        newActiveTag = state.activeTag
        newLibList = cloneDeep(state.libraryList)
        state?.libraryList.forEach((lib, idx) => {
          if (lib.collections && lib.collections[id]) {
            delete newLibList[idx].collections[id]
          }
        })
      }
      return {
        ...state,
        libraryCollections: newCollections,
        libraryList: newLibList,
        activeTag: newActiveTag
      }
    }
    case SET_ACTIVE_LIBRARY_TAG:
      return {
        ...state,
        activeTag: payload
      }
    case UPDATE_LIBRARY_SUGGESTION: {
      const { id } = payload
      let newLibList = cloneDeep(state.libraryList)
      const idx = _.findIndex(newLibList, { id })
      if (idx > -1) {
        newLibList = newLibList.filter((item) => item.id !== id)
      }
      return {
        ...state,
        libraryList: newLibList,
        suggestedCount:
          state.suggestedCount - 1 > 0 ? state.suggestedCount - 1 : 0
      }
    }
    case UPDATE_LIBRARY_COLLECTION_NAME: {
      const { id, name, visibility } = payload
      const { public_collections = [], private_collections = [] } = _.cloneDeep(
        state.libraryCollections
      )
      if (visibility === 'public') {
        const index = findIndex(public_collections, { id })
        if (index > -1) {
          public_collections[index].name = name
        }
      } else {
        const index = findIndex(private_collections, { id })
        if (index > -1) {
          private_collections[index].name = name
        }
      }
      const newCollections = { public_collections, private_collections }
      const newLibList = cloneDeep(state.libraryList)
      newLibList.forEach((lib, idx) => {
        if (lib.collections && lib.collections[id]) {
          newLibList[idx].collections[id].name = name
        }
      })
      return {
        ...state,
        libraryCollections: newCollections,
        libraryList: newLibList
      }
    }
    case UPDATE_LIBRARY_VARIAITONS: {
      const newLibList = cloneDeep(state.libraryList)
      Object.keys(payload).forEach((key) => {
        const idx = _.findIndex(newLibList, { id: key })
        if (idx > -1) {
          newLibList[idx].variationsFromApiKnowledge = payload[key]
        }
      })
      return {
        ...state,
        libraryList: newLibList
      }
    }
    case UPDATE_LIBRARY_STATUS: {
      const { id, status } = payload
      const newLibList = _.cloneDeep(state.libraryList)
      const idx = _.findIndex(newLibList, { id })
      if (idx > -1) {
        newLibList[idx].status = status
        return {
          ...state,
          libraryList: newLibList
        }
      }
      return state
    }
    case UPDATE_LIBRARY_COLLECTIONS_STATUS: {
      const { visibility, id, status } = payload
      const { public_collections = [], private_collections = [] } = _.cloneDeep(
        state.libraryCollections
      )
      if (visibility === 'public') {
        const index = findIndex(public_collections, { id })
        if (index > -1) {
          public_collections[index].status = status
        }
      } else {
        const index = findIndex(private_collections, { id })
        if (index > -1) {
          private_collections[index].status = status
        }
      }
      const newCollections = { public_collections, private_collections }
      return {
        ...state,
        libraryCollections: newCollections
      }
    }
    case UPDATE_LIBRARY_COLLECTIONS_VIEWS: {
      const { id, number_of_views } = payload
      const newLibList = cloneDeep(state.libraryList)
      const idx = _.findIndex(newLibList, { id })
      if (idx > -1) {
        newLibList[idx].number_of_views = number_of_views
      }
      return {
        ...state,
        libraryList: newLibList
      }
    }

    default:
      return state
  }
}

export default libraryList
