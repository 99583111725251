import * as React from 'react'
import { useSelector } from 'react-redux'
import Backdrop from '@mui/material/Backdrop'
import Loader from '../Loader'
import { useStyles } from './styles'

const Spinner = (props) => {
  const common = useSelector((state) => state.common)
  const loading = props.loading ? props.loading : common.loading
  const sidebar = common.sidebarActive
  const classes = useStyles(sidebar)
  const { backdrop1, backdrop2, backdrop3 } = classes

  return props.showSideBar ? (
    <Backdrop className={backdrop1} open={loading}>
      <Backdrop
        style={
          {
            // left: sidebar ? '240px' : '60px'
          }
        }
        className={backdrop2}
        open={loading}
      >
        <Loader loading={true} caption="fetching your data" />
      </Backdrop>
    </Backdrop>
  ) : (
    <Backdrop className={backdrop3} open={loading}>
      <Loader loading={true} caption="launching your workspace" />
    </Backdrop>
  )
}
export default Spinner
