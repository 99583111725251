import { FETCH_SEARCH_HISTORY } from '../../../actionTypes'
import { getuserSearchHistory } from '../../../api'

const getSearchHistory =
  (callback = () => {}) =>
  async (dispatch) => {
    const res = await getuserSearchHistory(25)
    if (res.status === 200) {
      dispatch({
        type: FETCH_SEARCH_HISTORY,
        payload: res.data,
        callback
      })
    }
  }

export { getSearchHistory }
