import TextField from '@mui/material/TextField'
import Box from '@material-ui/core/Box'
import { useStyles } from './styles'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'
import clsx from 'clsx'
import { Button } from '../../components'
import _, { isArray, isEmpty, uniqBy } from 'lodash'
import { useEffect, useState } from 'react'

const InputTags = (props) => {
  const classes = useStyles()
  const {
    labelStyleDefault,
    inputStyleDefault,
    inputTextField,
    autocomp,
    tagItem,
    tagsInputContainer,
    tagClose,
    hiddenTagAction,
    emptyTagMessage
  } = classes
  const {
    initalTags = [],
    tags = null,
    setTags = () => {},
    labelStyle = labelStyleDefault,
    inputStyle = inputStyleDefault,
    label,
    placeholder = 'Add Tags',
    options = [],
    showTagOnly = false,
    showLabel = false,
    tagStyles = {},
    tagClick = () => {},
    fullWidth = false,
    spellCheck = true,
    showInput = true,
    hideMoreTags = false,
    isObjectKey = '',
    additionalObjectData = {},
    labelStyleProps = {}
  } = props

  const [interalTags, setInternalTags] = useState([])
  const [displayTags, setDisplayTags] = useState([])
  const [hiddenTagsCount, setHiddenTagsCount] = useState(0)
  const [moreTags, setMoreTags] = useState(false)
  useEffect(() => {
    if (initalTags && _.isArray(initalTags)) {
      setInternalTags(initalTags)
      hideMoreTags && handleIntialLoad(initalTags)
    } else {
      if (tags) {
        setInternalTags(tags)
        hideMoreTags && handleIntialLoad(tags)
      }
    }
  }, [])

  useEffect(() => {
    if (moreTags) {
      setDisplayTags(interalTags)
    } else {
      handleIntialLoad(interalTags)
    }
  }, [interalTags])

  useEffect(() => {
    if (tags) {
      setInternalTags(tags)
    }
  }, [tags])

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return
    let value = e.target.value
    if (!value.trim()) return
    let newTags = []
    if (isObjectKey) {
      value = { [isObjectKey]: value, ...additionalObjectData }
      newTags = hideMoreTags
        ? uniqBy([value, ...interalTags], isObjectKey)
        : uniqBy([...interalTags, value], isObjectKey)
    } else {
      newTags = hideMoreTags
        ? uniqBy([value, ...interalTags])
        : uniqBy([...interalTags, value])
    }
    setTags(newTags)
    setInternalTags(newTags)
    e.target.value = ''
  }

  const handleOnBlur = (e) => {
    let value = e.target.value
    if (!value.trim()) return
    let newTags = []
    if (isObjectKey) {
      value = { [isObjectKey]: value, ...additionalObjectData }
      newTags = hideMoreTags
        ? uniqBy([value, ...interalTags], isObjectKey)
        : uniqBy([...interalTags, value], isObjectKey)
    } else {
      newTags = hideMoreTags
        ? uniqBy([value, ...interalTags])
        : uniqBy([...interalTags, value])
    }
    setTags(newTags)
    setInternalTags(newTags)
    e.target.value = ''
  }

  const onAutoCompleteSelect = (value) => {
    if (!value.trim()) return
    const newTags = hideMoreTags
      ? uniqBy([value, ...interalTags])
      : uniqBy([...interalTags, value])
    setTags(newTags)
    setInternalTags(newTags)
  }

  const removeTag = (indexRemoveTag) => {
    const newTags = interalTags.filter((el, i) => i !== indexRemoveTag)
    setTags(newTags)
    setInternalTags(newTags)
  }

  const handleIntialLoad = (tags) => {
    if (hideMoreTags) {
      let displayTags = tags
      let hiddenTagsCount = 0
      if (tags?.length > 5) {
        displayTags = tags.slice(0, 5)
        hiddenTagsCount = tags?.length - 5
      }
      setDisplayTags(displayTags)
      setHiddenTagsCount(hiddenTagsCount)
    }
    setMoreTags(false)
  }

  const handleExpandTags = () => {
    if (moreTags) {
      handleIntialLoad(interalTags)
      return
    }
    setMoreTags(!moreTags)
    setDisplayTags(interalTags)
  }

  const renderTags = hideMoreTags ? displayTags : interalTags

  return (
    <>
      {(showLabel || (!showTagOnly && showInput)) && (
        <Box>
          {label && (
            <Box variant="span" className={labelStyle} style={labelStyleProps}>
              {label}
            </Box>
          )}
        </Box>
      )}
      {!showTagOnly && showInput && (
        <>
          <Box></Box>
          <Box>
            {options?.length > 0 ? (
              <Autocomplete
                className={autocomp}
                fullWidth={fullWidth}
                freeSolo
                disableClearable
                closeIcon=""
                onChange={(_, v) => onAutoCompleteSelect(v)}
                options={options.map((option) => option.select)}
                renderInput={(params) => (
                  <TextField
                    className={inputTextField}
                    {...params}
                    variant="outlined"
                    onKeyDown={handleKeyDown}
                    type="text"
                    onBlur={handleOnBlur}
                    placeholder={placeholder}
                    InputProps={{
                      spellCheck
                    }}
                  />
                )}
              />
            ) : (
              <TextField
                className={inputTextField}
                fullWidth={fullWidth}
                variant="outlined"
                onKeyDown={handleKeyDown}
                type="text"
                onBlur={handleOnBlur}
                placeholder={placeholder}
                InputProps={{
                  spellCheck
                }}
              />
            )}
          </Box>
        </>
      )}
      {renderTags?.length === 0 && (
        <div className={emptyTagMessage}>No tags added </div>
      )}
      <Box className={tagsInputContainer}>
        {isArray(renderTags) &&
          renderTags.map((tag, indexTags) => (
            <Box
              className={clsx(tagItem, tagStyles)}
              key={indexTags}
              onClick={(e) => {
                e.stopPropagation()
                tagClick(indexTags)
              }}
            >
              <Box component="span">{isObjectKey ? tag[isObjectKey] : tag}</Box>
              {!showTagOnly && (
                <IconButton disableRipple onClick={() => removeTag(indexTags)}>
                  <CloseIcon className={tagClose} />
                </IconButton>
              )}
            </Box>
          ))}

        {hideMoreTags && interalTags?.length > 5 && (
          <Button
            onClick={() => {
              handleExpandTags()
            }}
            className={hiddenTagAction}
          >
            {moreTags ? 'show less' : `+${hiddenTagsCount}`}
          </Button>
        )}
      </Box>
    </>
  )
}

export default InputTags
