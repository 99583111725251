import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  container: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  landingImageLogo: {
    padding: '20px',
    width: 800,
    maxWidth: { xs: 400, md: 800 }
  },
  loginButton: {
    padding: '20px'
  }
})

export { useStyles }
