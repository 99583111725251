'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true
})
exports.defaultTheme = exports.spacing = exports.colors = undefined
const colors = {
  primary: 'hsl(0, 0%, 90%)',
  primary75: '#4C9AFF',
  primary50: '#B2D4FF',
  primary25: '#DEEBFF',
  danger: '#DE350B',
  dangerLight: '#FFBDAD',
  neutral0: 'hsl(0, 0%, 100%)',
  neutral5: 'hsl(0, 0%, 95%)',
  neutral10: 'hsl(0, 0%, 90%)',
  neutral20: 'hsl(0, 0%, 80%)',
  neutral30: 'hsl(0, 0%, 70%)',
  neutral40: 'hsl(0, 0%, 60%)',
  neutral50: 'hsl(0, 0%, 50%)',
  neutral60: 'hsl(0, 0%, 40%)',
  neutral70: 'hsl(0, 0%, 30%)',
  neutral80: 'hsl(0, 0%, 20%)',
  neutral90: 'hsl(0, 0%, 10%)',
  hover: 'rgba(0, 0, 0, 0.04)'
}
exports.colors = colors
const borderRadius = 4
const baseUnit = 4
/* Used to calculate consistent margin/padding on elements */

const controlHeight = 38
/* The minimum height of the control */

const menuGutter = baseUnit * 2
/* The amount of space between the control and menu */

const spacing = {
  baseUnit,
  controlHeight,
  menuGutter
}
exports.spacing = spacing
const defaultTheme = {
  borderRadius,
  colors,
  spacing
}
exports.defaultTheme = defaultTheme

exports.default = defaultTheme
