import React, { useState, useCallback, useEffect } from 'react'
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Tab,
  Tabs,
  Grid,
  IconButton,
  Box,
  Tooltip
} from '@mui/material'
import { Edit, Close, Add, Visibility } from '@mui/icons-material'
import { useDropzone } from 'react-dropzone'
import { Button } from '../../components'
import { getInitialsFromUserName } from '../../utils/String'
import { v4 as uuid } from 'uuid'
import Select from 'react-select'
import Loader from '../../components/Loader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getSignedUrl } from '../../utils/AWS'

function checkImageTypeBase64(imageString) {
  const isBase64 = imageString.startsWith('data:image')
  if (isBase64) {
    return true
  }
  return false
}

const ProfilePicker = (props) => {
  const {
    name = '',
    profile_pic = '',
    style = {},
    isEditable = false,
    handleMode = () => {},
    onImageSelect = () => {},
    isReadOnly = false,
    viewImage = () => {},
    faces = {},
    s3Obj = {},
    currentPage = 1,
    isMasterPicker = false,
    masterFaces = []
  } = props

  const initials = getInitialsFromUserName(name)
  const [open, setOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [facePage, setFacePage] = useState('')
  const [facesFound, setFacesFound] = useState([])
  const [faceLoading, setFaceLoading] = useState(false)
  const [profilePic, setProfilePic] = useState(null)
  const [masterFacesFound, setMasterFacesFound] = useState([])

  useEffect(() => {
    const initalizeData = async () => {
      if (profile_pic && profile_pic.src) {
        const url = checkImageTypeBase64(profile_pic.src)
          ? profile_pic.src
          : await getSignedUrl(profile_pic.src, s3Obj)
        setProfilePic({
          ...profile_pic,
          src: url
        })
      } else {
        setProfilePic(null)
      }
    }
    setProfilePic(null)
    initalizeData()
  }, [profile_pic])

  const faceOptions = Object.keys(faces)?.map((faceOption) => {
    return {
      label: 'Pg: ' + faceOption,
      value: faceOption
    }
  })

  useEffect(() => {
    if (facePage && !isMasterPicker) {
      setFaceLoading(true)
      setFacesFound(faces[facePage])
      setTimeout(() => {
        setFaceLoading(false)
      }, 1000)
    }
  }, [facePage, faces])

  useEffect(() => {
    if (isMasterPicker) {
      const faces = []
      masterFaces.forEach(async (item) => {
        const { profile_picture_location, face_id, face_group_id } = item
        if (profile_picture_location) {
          const faceObj = {
            signedUrl: await getSignedUrl(profile_picture_location, s3Obj),
            id: face_id,
            face_group_id,
            image_url: profile_picture_location
          }
          faces.push(faceObj)
        }
        setMasterFacesFound(faces)
      })
    }
  }, [masterFaces, isMasterPicker])

  useEffect(() => {
    if (currentPage && !isMasterPicker) {
      if (Object.keys(faces).includes(currentPage.toString())) {
        setFacePage(currentPage)
      }
    }
  }, [currentPage])

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result?.toString().split(',')[1]
        if (base64String) {
          const prefix = `data:${file.type};base64,`
          const src = prefix + base64String
          onImageSelect({
            src,
            name: uuid() + '.' + file.name.split('.').pop()
          })
          setOpen(false)
        }
      }
      reader.readAsDataURL(file)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*'
  })

  const handleScreenShot = () => {
    setOpen(false)
    handleMode('screenshot_profile', true)
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        cursor: isEditable ? 'pointer' : '',
        '&:hover img': {
          opacity: isEditable ? '0.5' : '1'
        },
        '&:hover': {
          '& div': {
            display: 'flex !important',
            opacity: '1 !important'
          }
        },
        '&:hover button': {
          opacity: isEditable ? '0.5' : '1'
        }
      }}
    >
      {profilePic?.src ? (
        <Avatar
          src={profilePic.base64 ? profilePic.base64 : profilePic.src}
          style={{ ...style, cursor: 'pointer' }}
          // alt={name}
        />
      ) : (
        <Avatar sx={style}>{initials}</Avatar>
      )}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'none',
          opacity: '1',
          gap: '2px',
          '& button': {
            opacity: '1 !important'
          }
        }}
      >
        {profilePic?.src && (
          <Tooltip title={'View'}>
            <IconButton
              disableRipple
              onClick={() =>
                viewImage([
                  {
                    src: profilePic.base64 ? profilePic.base64 : profilePic.src
                  }
                ])
              }
            >
              <Visibility />
            </IconButton>
          </Tooltip>
        )}
        {isEditable && (
          <>
            <Tooltip
              title={profilePic?.src ? 'Edit' : 'Add' + ' profile picture'}
            >
              <IconButton
                disableRipple
                disabled={isReadOnly}
                onClick={() => setOpen(true)}
              >
                {profilePic?.src ? <Edit /> : <Add />}
              </IconButton>
            </Tooltip>
            {profilePic?.src && (
              <Tooltip title={'Remove'}>
                <IconButton
                  disableRipple
                  disabled={isReadOnly}
                  onClick={() => onImageSelect('')}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Choose an image</DialogTitle>
        <DialogContent>
          {isMasterPicker ? (
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
            >
              <Tab label="Select from mapped resume" />
            </Tabs>
          ) : (
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
            >
              <Tab label="Select from detected images" />
              <Tab label="Screenshot PDF" />
              <Tab label="Upload" />
            </Tabs>
          )}

          {tabValue === 0 && (
            <Box
              sx={{
                padding: '21px',
                marginTop: '20px',
                minHeight: '150px',
                maxHeight: '400px',
                width: '450px'
              }}
            >
              {isMasterPicker ? (
                <>
                  <Grid container spacing={2} style={{ marginTop: 20 }}>
                    {masterFacesFound?.map((image, index) => (
                      <Grid item xs={4} key={index}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                            '& span': {
                              height: '100%',
                              width: '100%',
                              textAlign: 'center',
                              background: '#E8F0FE',
                              display: 'flex !important',
                              justifyContent: 'center'
                            },
                            '& img': {
                              objectFit: 'contain',
                              maxHeight: '100%',
                              maxWidth: '100%',
                              cursor: 'pointer',
                              border: '1px solid lightgray'
                            },
                            '&:hover img': {
                              opacity: '0.5 !important'
                            },
                            '&:hover': {
                              '& div': {
                                display: 'flex !important',
                                opacity: '1 !important'
                              }
                            },
                            '&:hover button': {
                              opacity: '0.5'
                            }
                          }}
                        >
                          <LazyLoadImage effect="blur" src={image.signedUrl} />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              display: 'none',
                              opacity: '1',
                              gap: '2px',
                              '& button': {
                                opacity: '1 !important'
                              }
                            }}
                          >
                            <Tooltip title={'View'}>
                              <IconButton
                                disableRipple
                                onClick={() =>
                                  viewImage([{ src: image.signedUrl }])
                                }
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={'Add profile picture'}>
                              <IconButton
                                disableRipple
                                disabled={isReadOnly}
                                onClick={() => {
                                  onImageSelect(
                                    {
                                      location: image.image_url,
                                      face_id: image.id,
                                      face_group_id: image.face_group_id
                                    },
                                    facePage,
                                    index
                                  )
                                  setOpen(false)
                                }}
                              >
                                <Add />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <>
                  <Select
                    options={faceOptions}
                    onChange={(e) => setFacePage(e.value)}
                    placeholder="Select Page"
                    value={
                      facePage && { label: 'Pg: ' + facePage, value: facePage }
                    }
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 })
                    }}
                  />
                  {faceLoading ? (
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      style={{ marginTop: 20 }}
                    >
                      <Loader loading={faceLoading} caption={''} />
                    </Grid>
                  ) : (
                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                      {facesFound?.map((image, index) => (
                        <Grid item xs={4} key={index}>
                          <Box
                            sx={{
                              width: '100%',
                              height: '100%',
                              position: 'relative',
                              '& span': {
                                height: '100%',
                                width: '100%',
                                textAlign: 'center',
                                background: '#E8F0FE',
                                display: 'flex !important',
                                justifyContent: 'center'
                              },
                              '& img': {
                                objectFit: 'contain',
                                maxHeight: '100%',
                                maxWidth: '100%',
                                cursor: 'pointer',
                                border: '1px solid lightgray'
                              },
                              '&:hover img': {
                                opacity: '0.5 !important'
                              },
                              '&:hover': {
                                '& div': {
                                  display: 'flex !important',
                                  opacity: '1 !important'
                                }
                              },
                              '&:hover button': {
                                opacity: '0.5'
                              }
                            }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src={image.signedUrl}
                            />
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'none',
                                opacity: '1',
                                gap: '2px',
                                '& button': {
                                  opacity: '1 !important'
                                }
                              }}
                            >
                              <Tooltip title={'View'}>
                                <IconButton
                                  disableRipple
                                  onClick={() =>
                                    viewImage([{ src: image.signedUrl }])
                                  }
                                >
                                  <Visibility />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={'Add profile picture'}>
                                <IconButton
                                  disableRipple
                                  disabled={isReadOnly}
                                  onClick={() => {
                                    onImageSelect(
                                      {
                                        src: image.image_url,
                                        name: image.image_url.split('/').pop(),
                                        face_id: image.id,
                                        face_group_id: image.face_group_id
                                      },
                                      facePage,
                                      index
                                    )
                                    setOpen(false)
                                  }}
                                >
                                  <Add />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </>
              )}
            </Box>
          )}
          {tabValue === 1 && (
            <Box
              sx={{
                padding: '21px',
                marginTop: '20px',
                height: '150px',
                width: '450px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Button onClick={() => handleScreenShot()}>
                Take a Screenshot
              </Button>
            </Box>
          )}
          {tabValue === 2 && (
            <Box
              {...getRootProps()}
              style={{
                border: '1px dashed gray',
                padding: 20,
                marginTop: 20,
                cursor: 'pointer',
                height: '150px',
                width: '450px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <input {...getInputProps()} />
              <p>Drag & drop an image here, or click to select one</p>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ProfilePicker
