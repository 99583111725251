import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { Grid } from '@mui/material'
import AnalyticCorrection from './AnalyticCorrection'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'

const Insight = (props) => {
  const classes = useStyles()
  const { errorOverWrapper } = classes

  const { words = [], isFocused, jumpToPage, handleDataChange, handleErrorTypeSelection, selectedErrorType, shareType = 'private', editingDisabled = false } = props
  const groupByErrorType = words

  const handleErrorType = (type) => {
    handleErrorTypeSelection(type)
  }

  useEffect(() => {
    trackEvent(
      mixpanelEvents.ANALYTICS_SUGGESTION_TAB_OPENED,
      'SUCCESS',
      {},
      {}
    )
  }, [])

  return (
    <AnalyticCorrection
      jumpToPage={jumpToPage}
      handleDataChange={handleDataChange}
      handleErrorType={handleErrorType}
      errorType={selectedErrorType}
      errorObj={words}
      isFocused={isFocused}
      shareType={shareType}
      editingDisabled={editingDisabled}
    />
  )
}
export default Insight
