import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useColor } from '../../ThemeContext'
import { Button } from '../index'
import { useStyles } from './styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { Container, Section } from '../../components/Container'

export default function MaterialTabs(props) {
  const {
    data = [],
    activeTab = 0,
    tabChangeCallBack,
    disabled = false
  } = props
  const classes = props.classes ? props.classes : useStyles()
  const [value, setValue] = useState(activeTab)
  const handleChange = (newValue) => {
    !disabled && setValue(newValue)
    if (tabChangeCallBack) {
      tabChangeCallBack(newValue)
    }
  }
  useEffect(() => {
    setValue(activeTab)
  }, [activeTab])

  return (
    <Grid container sx={{ height: '100%', width: '100%' }}>
      <Grid item xs={12} sm={3} lg={3} xl={2} className={classes.sidePane}>
        <Container>
          <Section overFlow>
            <Box className={classes.sidePaneContainer}>
              <List className={classes.listWrapper}>
                {data.map((tab, index) => {
                  return (
                    <ListItem
                      key={index}
                      disablePadding
                      className={classes.allItem}
                    >
                      <ListItemButton
                        disabled={disabled}
                        className={classes.listButtonWrapper}
                        selected={value === index}
                        onClick={() => handleChange(index)}
                      >
                        <ListItemText
                          primary={
                            <Box className={classes.listTextWrapper}>
                              <Box className={classes.listText}>
                                {tab.label}
                              </Box>
                            </Box>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          </Section>
        </Container>
      </Grid>
      <Grid item xs sm lg xl className={classes.mainPane}>
        <Container>
          <Section overFlow>
            {data[value].children ? data[value].children : <></>}
          </Section>
        </Container>
      </Grid>
    </Grid>
  )
}
