import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import { toast } from 'react-toastify'
import { FormControl } from '@mui/base'
import TextField from '@material-ui/core/TextField'
import { Box } from '@mui/material'
import _ from 'lodash'
import { useStyles } from './styles'
import { putUserDomain, getChatInstructions } from '../../store/api'
import { Loader } from '../../components'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { defaultChatInstructions } from '../../config/constants'
import { Container } from '../../components/Container'
import clsx from 'clsx'
import { USER_CONFIG_UPDATE } from '../../store/actionTypes'
import { useDispatch } from 'react-redux'

const Disclaimer = ({ isFocused }) => {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdaing] = useState(false)
  const [chatInstructionData, setChatInstructionData] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      if (chatInstructionData === null && isFocused) {
        const res = await getChatInstructions()
        if (res.status === 200) {
          const { data } = res
          if (!_.isEmpty(data)) {
            setChatInstructionData(data)
          } else {
            setChatInstructionData(defaultChatInstructions)
          }
          setLoading(false)
        } else {
          toast.error('Unable to fetch data')
        }
      }
    }
    fetchData()
  }, [isFocused])

  const classes = useStyles()
  const { disclaimer = '' } = chatInstructionData || {}

  const handleUpdate = async (e) => {
    setUpdaing(true)
    const data = {
      chat_instructions: chatInstructionData
    }
    const res = await putUserDomain(data)
    if (res.status === 200) {
      trackEvent(
        mixpanelEvents.SETTINGS_CHAT_INSTRUCTIONS_UPDATED,
        'SUCCESS',
        {},
        { chat_instructions: chatInstructionData }
      )
      dispatch({
        type: USER_CONFIG_UPDATE,
        payload: chatInstructionData
      })
    } else {
      toast.error('Something went wrong')
    }
    setUpdaing(false)
  }

  return (
    <Container>
      {loading ? (
        <Loader loading={loading} flex />
      ) : (
        <Box className={classes.childContainer}>
          <Box className={clsx(classes.titleText, classes.gradientTextStyle)}>
            Disclaimer
          </Box>
          <Box className={classes.subText}>
            Add a disclaimer that will be displayed to the user when using the
            chat.
          </Box>
          <FormControl>
            <TextField
              className={classes.textFeild}
              variant="outlined"
              multiline
              disabled={updating}
              minRows={3}
              maxRows={6}
              value={disclaimer}
              inputProps={{
                style: {
                  paddingTop: 8,
                  paddingBottom: 8
                }
              }}
              onChange={(e) => {
                setChatInstructionData({
                  ...chatInstructionData,
                  disclaimer: e.target.value
                })
              }}
            />
          </FormControl>
          <FormControl
            style={{
              marginTop: '20px',
              maxWidth: '800px'
            }}
          >
            <Box sx={{ textAlign: 'end' }}>
              <Button disabled={updating} onClick={(e) => handleUpdate(e)}>
                {updating ? 'Updating...' : 'Update'}
              </Button>
            </Box>
          </FormControl>
        </Box>
      )}
    </Container>
  )
}

export default Disclaimer
