export const menuData = {
  grammar_and_spelling: {
    title: 'Grammar & Spelling',
    description:
      'When a switch is on, we’ll let you know if your content has issues in that category.',
    options: [
      {
        label: 'Grammar Checker',
        description:
          'Our grammar checker catches grammar errors such as subject-verb agreement, pronoun casing, wrong prepositions, and much more.',
        type: 'switch',
        category: 'grammar_checker'
      },
      {
        label: 'Spelling  Checker',
        description:
          'Our spellchecker helps keep your writing free of typos and misspellings.',
        type: 'switch',
        category: 'spell_check',
        options: [
          {
            label: 'Words Exempt From Spell Check',
            type: 'checkbox',
            category: 'word_exempt'
          },
          {
            type: 'addinput',
            inputPlaceholder: 'Exempt Word',
            buttonLabel: 'Add',
            category: 'word_exempt_list'
          }
        ]
      },
      {
        label: 'Passive Voice',
        type: 'switch',
        category: 'passive_voice'
      },
      {
        label: 'Split Infinitive',
        type: 'switch',
        category: 'split_infinitive'
      },
      {
        label: 'Long Sentences',
        type: 'switch',
        category: 'long_sentences'
      }
    ]
  },
  // "punctuation": {
  //     "title": "Punctuation",
  //     "description": "When a switch is on, we’ll let you know if your content has issues in that category.",
  //     "options": [
  //         {
  //             "label": "Punctuation  Checker",
  //             "description": "Our punctuation checker looks for misplaced or missing commas, hyphens, semicolons, periods, and more.",
  //             "type": "switch",
  //             "category": "punctuation_checker"
  //         },
  //         {
  //             "label": "Punctuation Style",
  //             "description": "Some punctuation marks are a matter of preference. Use these settings to customize how you’d like these punctuation marks to appear in your writing. They’ll show up as Style suggestions to your team.",
  //             "type": "switch",
  //             "category": "punctuation_style",
  //             "options": [{
  //                 "label": "& / Ampersand",
  //                 "type": "title",
  //                 "options": [
  //                     {
  //                         "label": "Don't use an ampersand ",
  //                         "type": "checkbox",
  //                         "category": "ampersand"
  //                     }
  //                 ]
  //             },
  //             {
  //                 "label": "‘ / Apostrophes",
  //                 "type": "title",
  //                 "options": [
  //                     {
  //                         "label": "Possessive nouns",
  //                         "type": "checkbox",
  //                         "category": "possessive_nouns",
  //                     },
  //                     {
  //                         "label": "Plurals",
  //                         "type": "checkbox",
  //                         "category": "plurals",
  //                     }
  //                 ]
  //             },
  //             {
  //                 "label": ": ; / Colons and Semicolons",
  //                 "type": "title",
  //                 "options": [
  //                     {
  //                         "label": "Use semicolons sparingly. Consider using an em-dash or starting a new sentence.",
  //                         "type": "checkbox",
  //                         "category": "colons",
  //                     }
  //                 ]
  //             },
  //             {
  //                 "label": "` / Contractions",
  //                 "type": "title",
  //                 "options": [
  //                     {
  //                         "label": "Don’t contractions for common phrases.",
  //                         "type": "checkbox",
  //                         "example": "We cannot start building until we have all the necessary permits.",
  //                         "category": "contractions"
  //                     }
  //                 ]
  //             },
  //             {
  //                 "label": ", / Comma",
  //                 "type": "title",
  //                 "options": [
  //                     {
  //                         "label": "Always use the Oxford or serial coma when writing lists.",
  //                         "type": "checkbox",
  //                         "example": "Our construction supplies include hammers, nails, and safety goggles",
  //                         "category": "comma"
  //                     }
  //                 ]
  //             },
  //             {
  //                 "label": "... / Ellipses",
  //                 "type": "title",
  //                 "options": [
  //                     {
  //                         "label": "Use spaces before and after an ellipses",
  //                         "type": "checkbox",
  //                         "category": "ellipsis"
  //                     },

  //                 ]
  //             },
  //             {
  //                 "label": "! / Exclamation points",
  //                 "type": "title",
  //                 "options": [
  //                     {
  //                         "label": "Don’t use exclamation points",
  //                         "type": "checkbox",
  //                         "category": "exclamation_points"
  //                     },

  //                 ]
  //             },
  //             {
  //                 "label": "% / Percentages",
  //                 "type": "title",
  //                 "options": [
  //                     {
  //                         "label": "Spell out ‘percentage’ instead of using ‘%’",
  //                         "type": "checkbox",
  //                         "category": "percentages"
  //                     },

  //                 ]
  //             },
  //             {
  //                 "label": "””  / Quotation Marks",
  //                 "type": "title",
  //                 "options": [
  //                     {
  //                         "label": "Use smart quotes instead of straight",
  //                         "type": "checkbox",
  //                         "category": "quotes"
  //                     },

  //                 ]
  //             }]
  //         }
  //     ]

  // },
  advanced_detection: {
    title: 'Advanced Detection',
    description: '',
    options: [
      {
        label: 'Dates',
        type: 'switch',
        category: 'dates'
      },
      {
        label: 'Currency',
        type: 'switch',
        category: 'currency'
      },
      {
        label: 'Numbers, fractions, units ',
        type: 'switch',
        category: 'numbers'
      },
      {
        label: 'Locations',
        type: 'switch',
        category: 'locations'
      },
      {
        label: 'People Names',
        type: 'switch',
        category: 'people_names'
      },
      {
        label: 'Contact Numbers',
        type: 'switch',
        category: 'contact_numbers'
      },
      {
        label: 'Acronyms',
        type: 'switch',
        category: 'acronyms'
      },
      {
        label: 'Email IDs',
        type: 'switch',
        category: 'email_id'
      },
      {
        label: 'Organization Names',
        type: 'switch',
        category: 'organization_names'
      }
    ]
  },
  // "clarity": {
  //     "title": "Clarity",
  //     "description": "When a switch is on, we’ll let you know if your content has issues in that category.",
  //     "options": [

  //                 {
  //                     "label": "Passive Voice",
  //                     "type": "switch",
  //                     "category": "passive_voice",
  //                 },
  //                 {
  //                     "label": "Split Infinitive",
  //                     "type": "switch",
  //                     "category": "split_infinitive"
  //                 },
  //                 {
  //                     "label": "Long Sentences",
  //                     "type": "switch",
  //                     "category": "long_sentences"
  //                 }
  //     ]
  // },
  wordy_phrases: {
    title: 'Wordy Phrases',
    description:
      'Wordy phrases are expressions or sentences that contain unnecessary or redundant words, making the sentence longer or more convoluted than necessary.',
    options: [
      {
        label: 'Wordy Phrases',
        type: 'tableinput',
        rule: 'Wordy Phrases',
        category: 'wordy_phrase'
      }
    ]
  },
  words_to_avoid: {
    title: 'Words to Avoid',
    description:
      'Words to avoid are those that possess the potential for legal binding, as they can unintentionally create enforceable obligations.',
    options: [
      {
        label: 'Words to Avoid',
        type: 'tableinput',
        rule: 'Words to Avoid',
        category: 'words_to_avoid'
      }
    ]
  }
}

export const menuDefaultValue = {
  grammar_and_spelling: [
    {
      value: true,
      type: 'switch',
      label: 'Grammar Checker',
      category: 'grammar_checker'
    },
    {
      0: {
        value: false,
        type: 'checkbox',
        label: 'Words Exempt From Spell Check',
        category: 'word_exempt'
      },
      1: {
        value: [],
        label: '',
        type: 'addinput',
        category: 'word_exempt_list'
      },
      value: true,
      type: 'switch',
      label: 'Spelling Checker',
      category: 'spell_check'
    },
    {
      type: 'checkbox',
      category: 'passive_voice',
      label: 'Passive Voice',
      value: true
    },
    {
      type: 'checkbox',
      category: 'split_infinitive',
      label: 'Split Infinitive',
      value: true
    },
    {
      type: 'switch',
      category: 'long_sentences',
      label: 'Long Sentences',
      value: true
    }
  ],
  // "punctuation": [
  //     {
  //         "value": true,
  //         "type": "switch",
  //         "label": "Punctuation  Checker",
  //         "category": "punctuation_checker"
  //     },
  //     {
  //         "0": [
  //             {
  //                 "value": true,
  //                 "type": "checkbox",
  //                 "label": "Don't use an ampersand",
  //                 "category": "ampersand"
  //             }
  //         ],
  //         "1": [
  //             {
  //                 "value": true,
  //                 "type": "checkbox",
  //                 "label": "Possessive nouns",
  //                 "category": "possessive_nouns"
  //             },
  //             {
  //                 "value": true,
  //                 "type": "checkbox",
  //                 "label": "Plurals",
  //                 "category": "plurals"
  //             }
  //         ],
  //         "2": [
  //             {
  //                 "value": true,
  //                 "type": "checkbox",
  //                 "label": "Use semicolons sparingly. Consider using an em-dash or starting a new sentence.",
  //                 "category": "colons"
  //             }
  //         ],
  //         "3": [
  //             {
  //                 "value": true,
  //                 "type": "checkbox",
  //                 "label": "Don’t contractions for common phrases.",
  //                 "category": "contractions"
  //             }
  //         ],
  //         "4": [
  //             {
  //                 "value": true,
  //                 "type": "checkbox",
  //                 "label": "Always use the Oxford or serial coma when writing lists.",
  //                 "category": "comma"
  //             }
  //         ],
  //         "5": [
  //             {
  //                 "value": true,
  //                 "type": "checkbox",
  //                 "label": "Use spaces before and after an ellipses",
  //                 "category": "ellipsis"
  //             }
  //         ],
  //         "6": [
  //             {
  //                 "value": true,
  //                 "type": "checkbox",
  //                 "label": "Don’t use exclamation points",
  //                 "category": "exclamation_points"
  //             }
  //         ],
  //         "7": [
  //             {
  //                 "value": true,
  //                 "type": "checkbox",
  //                 "label": "Spell out ‘percentage’ instead of using ‘%’",
  //                 "category": "percentages"
  //             }
  //         ],
  //         "8": [
  //             {
  //                 "value": true,
  //                 "type": "checkbox",
  //                 "label": "Use smart quotes instead of straight",
  //                 "category": "quotes"
  //             }
  //         ],
  //         "value": true,
  //         "type": "switch",
  //         "label": "Punctuation  Style",
  //         "category": "punctuation_style"
  //     }
  // ],
  advanced_detection: [
    {
      type: 'switch',
      category: 'dates',
      label: 'Dates',
      value: true
    },
    {
      type: 'switch',
      category: 'currency',
      label: 'Currency',
      value: true
    },
    {
      type: 'switch',
      category: 'numbers',
      label: 'Numbers, fractions, units',
      value: false
    },
    {
      type: 'switch',
      category: 'locations',
      label: 'Locations',
      value: true
    },
    {
      type: 'switch',
      category: 'people_names',
      label: 'People Names',
      value: true
    },
    {
      type: 'switch',
      category: 'contact_numbers',
      label: 'Contact Numbers',
      value: true
    },
    {
      type: 'switch',
      category: 'acronyms',
      label: 'Acronyms',
      value: true
    },
    {
      type: 'switch',
      category: 'email_id',
      label: 'Email IDs',
      value: true
    },
    {
      type: 'switch',
      category: 'organization_names',
      label: 'Organization Names',
      value: true
    }
  ],
  // "clarity": [
  //     {
  //         "0": {
  //             "type": "checkbox",
  //             "category": "passive_voice",
  //             "label": "Passive Voice",
  //             "value": true
  //         },
  //         "1": {
  //             "type": "checkbox",
  //             "category": "split_infinitive",
  //             "label": "Split Infinitive",
  //             "value": true
  //         },
  //         "2": {
  //             "type": "switch",
  //             "category": "long_sentences",
  //             "label": "Long Sentences",
  //             "value": true
  //         },
  //         "type": "switch",
  //         "category": "clarity_checker",
  //         "label": "Clarity Checker",
  //         "value": true
  //     },
  //     {
  //         "type": "switch",
  //         "category": "readability",
  //         "label": "Readability",
  //         "value": true
  //     }
  // ],
  wordy_phrases: [
    {
      value: [],
      label: 'Wordy Phrases',
      type: 'tableinput',
      category: 'wordy_phrase'
    }
  ],
  words_to_avoid: [
    {
      value: [],
      label: 'Words to Avoid',
      type: 'tableinput',
      category: 'words_to_avoid'
    }
  ]
}
