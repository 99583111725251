import React, { useEffect, useState } from 'react'
import Tab from '../../components/Tabs'
import {
  fetchAnalytics,
  fetchStyleSettings
} from '../../store/Analytics/Actions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Section, SectionFixed } from '../../components/Container'
import Loader from '../../components/Loader'
import Analytics from '../../containers/Analytics'
import Rfx from '../../containers/RFx'
import { useStyles } from './styles'
import { Grid } from '@material-ui/core'
import StyleSettings from '../../sections/StyleSettings'
import rfxDocumentsList from '../../store/RFx/Reducer'
import { fetchRFxDocuments } from '../../store/RFx/Actions'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'

const Analyticspage = () => {
  const analytics = useSelector((state) => state.analyticList.analytics)
  const styleSettings = useSelector(
    (state) => state?.analyticList?.styleSettings
  )
  const rfxDocuments = useSelector(
    (state) => state?.rfxDocumentsList.rfxDocuments
  )
  const { currentTab } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const {
    ANALYTICS_HOME_ANALYTICS,
    ANALYTICS_HOME_RFX,
    ANALYTICS_HOME_STYLE_GUIDE
  } = ROUTES

  const [activeTab, setActiveTab] = useState(0)
  const getcurrentTab = (key) => {
    switch (key) {
      case 'analytics':
        trackEvent(mixpanelEvents.ANALYTICS_TAB_CLICKED, 'SUCCESS', {}, {})
        return 0
      case 'styleguide':
        trackEvent(
          mixpanelEvents.ANALYTICS_STYLE_GUIDE_TAB_OPENED,
          'SUCCESS',
          {},
          {}
        )
        return 1
      case 'rfx':
        trackEvent(mixpanelEvents.RFX_TAB_CLICKED, 'SUCCESS', {}, {})
        return 2
      default:
        return 0
    }
  }

  useEffect(() => {
    if (currentTab !== activeTab) {
      const tab = getcurrentTab(currentTab)
      setActiveTab(tab)
    }
  }, [currentTab])

  const tabs = [
    {
      label: 'Analytics',
      children: <Analytics isFocused={activeTab === 0} />
    },
    {
      label: 'Style Guide',
      children: <StyleSettings isFocused={activeTab === 1} />
    },
    {
      label: 'RFx',
      children: <Rfx isFocused={activeTab === 2} />
    }
  ]
  useEffect(() => {
    if (!analytics) {
      setLoading(true)
      dispatch(fetchAnalytics())
    } else {
      setLoading(false)
    }
  }, [analytics])

  useEffect(() => {
    if (activeTab === 1 && styleSettings === null) {
      dispatch(fetchStyleSettings())
    }
  }, [styleSettings, activeTab])

  useEffect(() => {
    if (!rfxDocuments) {
      setLoading(true)
      dispatch(fetchRFxDocuments())
    } else {
      setLoading(false)
    }
  }, [rfxDocuments])

  const changeTab = (e) => {
    if (e === activeTab) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 100)
    }
    if (e === 0) {
      navigate(ANALYTICS_HOME_ANALYTICS, {
        state: {
          clearSearchParams: true
        }
      })
    } else if (e === 1) {
      navigate(ANALYTICS_HOME_STYLE_GUIDE, {
        state: {
          clearSearchParams: true
        }
      })
    } else if (e === 2) {
      navigate(ANALYTICS_HOME_RFX, {
        state: {
          clearSearchParams: true
        }
      })
    }
  }

  return loading ? (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className="search-loader-container"
    >
      <Loader loading={loading} caption={'fetching your analytics'} />
    </Grid>
  ) : (
    <Container>
      <Section id="mui-tab" overFlow>
        <Tab
          sectionOverFlow
          activeTab={activeTab}
          data={tabs}
          isFlex={true}
          tabChangeCallBack={(e) => changeTab(e)}
        />
      </Section>
    </Container>
  )
}

export default Analyticspage
