import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 24
  },
  domain_selector: {
    paddingTop: '18px'
  },
  selector_box: {
    width: 500
  },
  changeButton: {
    marginTop: '10px'
  }
}))

export { useStyles }
