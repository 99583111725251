import { useState, useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box
} from '@mui/material'
import _ from 'lodash'
import { updateKey, updateValue } from '../../store/TagCenter/Actions'
import { Button, TextInput, ReactSelect } from '../../components'
import { v4 as uuid } from 'uuid'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { hasAccess } from '../../utils/User'

const AddNewKey = (props) => {
  const { isOpen = false, onClose = () => {}, source = '' } = props
  const canCreate = hasAccess('tags', 'create')

  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const dispatch = useDispatch()
  const [newKey, setNewKey] = useState({
    key: '',
    value: '',
    type: 'multiselect'
  })

  useEffect(() => {
    let keyType = []
    if (source) {
      keyType = [source]
    } else {
      keyType = ['document', 'people', 'project']
    }
    setNewKey({
      key: '',
      value: '',
      type: 'multiselect',
      key_type: keyType
    })
  }, [isOpen])

  const handleAddNewKey = () => {
    if (!canCreate) {
      toast.error('You do not have permission to create tags')
      onClose()
      return
    }
    const newKeyCopy = _.cloneDeep(newKey)
    if (Object.keys(tagsCenterStateTags).includes(newKeyCopy.key)) {
      toast.error('Tag with the same key already exists')
      return
    } else if (!newKeyCopy.key) {
      toast.error('Tag key is required')
      return
    } else if (newKeyCopy.key.includes('_')) {
      toast.error('Tag key cannot contain underscore')
      return
    }
    const id = uuid()
    const req = {
      id,
      key: newKeyCopy.key.toString(),
      value: '',
      usage_count: 0,
      status: 'create',
      editable: true,
      type: newKeyCopy.type,
      synced: false,
      key_type: newKeyCopy.key_type
    }
    dispatch(updateKey(req))
    onClose()
  }

  const typeOptions = [
    { value: 'multiselect', label: 'Multi-Select Text' },
    { value: 'singleselect', label: 'Single-Select Text' },
    { value: 'multidate', label: 'Multi-Select Date' },
    { value: 'singledate', label: 'Single-Select Date' },
    { value: 'multiurl', label: 'Multi-Select URL' },
    { value: 'singleurl', label: 'Single-Select URL' }
  ]
  const keyTypeOptions = [
    { value: 'document', label: 'Document' },
    { value: 'people', label: 'People' },
    { value: 'project', label: 'Project' }
  ]

  const handleDialogEdit = (e, key) => {
    setNewKey({
      ...newKey,
      [key]: e.target.value
    })
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      id="addkey"
      onClose={onClose}
      disablePortal
    >
      <DialogTitle>Create Tag Key</DialogTitle>
      <DialogContent>
        <TextInput
          sx={{ marginBottom: '15px' }}
          placeholder="Tag Key"
          value={newKey?.key}
          handleChange={(e) => handleDialogEdit(e, 'key')}
        />
        <Box sx={{ marginBottom: '15px' }}>
          <ReactSelect
            options={typeOptions}
            onChange={(e) => {
              setNewKey({
                ...newKey,
                type: e.value
              })
            }}
            defaultValue={
              newKey?.type.includes('multi') ? typeOptions[0] : typeOptions[1]
            }
            placeholder="Tag Type"
          />
        </Box>
        <Box sx={{ marginBottom: '15px' }}>
          <ReactSelect
            options={keyTypeOptions}
            onChange={(e) => {
              setNewKey({
                ...newKey,
                key_type: e.map((item) => item.value)
              })
            }}
            isMulti
            isClearable={false}
            value={newKey?.key_type?.map((item) => {
              return {
                value: item,
                label: _.startCase(item)
              }
            })}
            placeholder="Key Visibility"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddNewKey}>Create</Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const addNewValue = (
  newVal,
  key,
  keyType,
  tagsCenterStateTags,
  dispatch,
  callback
) => {
  if (!newVal) {
    toast.error('Tag value is required')
    return
  }
  if (
    tagsCenterStateTags[key]?.data?.filter((item) => item.value === newVal)
      .length > 0
  ) {
    toast.error('Tag with the same key already exists')
    return
  }
  const id = uuid()
  const req = {
    id,
    key,
    value: newVal,
    status: 'create',
    type: keyType,
    synced: false,
    update_count: '',
    editable: true
  }
  const cb = () => {
    const newOption = {
      value: id,
      label: newVal,
      tag_type: keyType,
      key
    }
    callback(newOption)
  }
  dispatch(updateValue(req, cb))
}

export { addNewValue }

export default AddNewKey
