import React, { useState, useEffect, useRef } from 'react'
import TextField from '@mui/material/TextField'
import Button from '../../components/Button'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import { useStyles } from './styles'

const Search = (props) => {
  const searchInput = useRef(null)
  const {
    id = '',
    variant = 'outlined',
    handleInputChange = () => {},
    value,
    handleEnterKey = () => {}
  } = props
  const classes = useStyles()
  const { container, searchText } = classes

  useEffect(() => {
    searchInput.current.focus()
  }, [searchInput])

  return (
    <Box className={container}>
      <TextField
        inputRef={searchInput}
        value={value}
        onChange={(event) => {
          handleInputChange(event.target.value)
        }}
        type="search"
        inputProps={{
          style: {
            // paddingTop: 0,
            // paddingBottom: 0,
            '&::before': { borderBottom: '2px solid red', content: "''" },
            '&::after': { borderBottom: '2px solid green', content: "''" }
          }
        }}
        onKeyDown={(event) => {
          handleEnterKey(event)
        }}
        className={searchText}
        id={id}
        variant={variant}
        placeholder={'Search'}
      />
    </Box>
  )
}

export default Search
