import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  outerContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '85%',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '8px'
  },
  topBar: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingRight: '30px',
    paddingBottom: '5px'
  },
  UploadButton: {
    textAlign: 'end',
    width: '100%'
  },

  tableCellName: {
    width: '25%',
    verticalAlign: 'baseline'
  },
  tableCellClientSector: {
    width: '20%',
    verticalAlign: 'baseline'
  },
  tableCellUploadedName: {
    width: '35%',
    verticalAlign: 'baseline'
  },
  tableCellContent: {
    display: 'flex',
    alignItems: 'center'
  },
  uploadFileName: {
    flex: 'auto'
  },
  controlButton: {
    flex: 'none'
  },
  progressBar: {
    marginTop: '10px'
  },
  progressContent: {
    width: '100%'
  },
  cardContainer: {
    position: 'fixed',
    bottom: '15px',
    right: '75px',
    width: '300px',
    zIndex: '1001',
    backgroundColor: '#000000',
    color: 'white',
    cursor: 'pointer'
  },
  cardContentContainer: {
    padding: '15px !important'
  },
  uploadIconButton: {
    padding: '0px',
    color: 'black'
  },
  linearProgressContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  linearProgressContent: {
    width: '100%',
    marginRight: '16px'
  },
  linearProgressUpload: {
    minWidth: 35
  },
  linearProgressError: {
    wordBreak: 'break-all'
  },
  cancelButton: {
    background: 'white !important',
    color: 'black',
    fontFamily: 'PoppinsRegular',
    fontWeight: '600',
    textTransform: 'none',
    borderRadius: '6px',
    border: '1px solid black',
    marginLeft: '15px'
  },
  hideMenuListText: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    width: '100px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    '& .MuiTypography-root': {
      fontSize: '14px'
    }
  },
  hideMenuDivider: {
    margin: '0px !important'
  },
  blackDivider: {
    backgroundColor: '#000000'
  },

  hideMenu: {
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    },
    '& .MuiButtonBase-root': {
      padding: '3px 5px'
    },
    '& .MuiMenu-paper': {
      border: '1px solid #E5E5E5',
      marginTop: '10px'
    },
    '& .MuiList-root': {
      padding: '0px'
    },
    '& .MuiListItemIcon-root': {
      minWidth: '0px'
    }
  },
  multiSelectCheckBox: {
    '& #multiCheckboxWrapper': {
      width: 'auto !important',
      '& button': {
        fontSize: '12px',
        padding: '1px 4px'
      }
    },
    '& #multiCheckboxOption': {
      width: '100% !important',
      fontWeight: '500',
      fontSize: '12px',
      color: '#000000',
      '& .MuiSvgIcon-root': {
        fontSize: '20px'
      },
      '& .MuiCheckbox-root': {
        padding: '5px'
      }
    },
    '& .Mui-checked': {
      color: '#0645AD !important'
    },
    '& #multiCheckboxMenuWrapper': {
      border: '1px solid #E5E5E5 !important',
      borderRadius: '4px !important',
      marginTop: '5px'
    },
    '& #multiCheckboxMenu': {
      width: '250px !important',
      '& input': {
        fontSize: '12px !important'
      }
    }
  }
})

export { useStyles }
