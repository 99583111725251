import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  customResizeHandler: {
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'ew-resize',
    boxSizing: 'border-box',
    padding: '0 5px 5px 0',

    '&:hover': {
      background: '#cdcdcd !important'
    }
  },
  containerDiv: {
    height: '100%',
    width: '100%',
    display: 'flex'
  },
  sidePaneContainer: {
    height: '100%',
    borderRight: '1px solid #EEEEEE'
  },
  mainContainer: {
    height: '100%'
  }
}))

export { useStyles }
