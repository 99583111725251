import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 300,
    margin: 'auto',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
  },
  media: {
    height: 200
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    maxHeight: '150px',
    margin: '8px',
    borderRadius: '10px'
  }
}))

export { useStyles }
