import React, { useEffect, useState, useRef } from 'react'
import { useStyles } from './styles'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import {
  Box,
  Grid,
  Tooltip,
  IconButton,
  TextareaAutosize,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import TextField from '@mui/material/TextField'

import { Button } from '../../components'
import {
  triggerAnalyticsCompliance,
  getAnalyticsCompliance,
  getAnalysedRFxList,
  updateAnalyticsCompliance,
  fetchDocument,
  fetchIdp
} from '../../store/api'
import Loader from '../../components/Loader'
import { setToasterAlert } from '../../store/Common/Actions'
import { setUploader } from '../../store/Uploader/Actions'
import { setAnalyticsComplianceData } from '../../store/AnalyticsCompliance/Actions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import InfoIcon from '@mui/icons-material/Info'
import { fetchRFx, fetchRFxDocuments } from '../../store/RFx/Actions'
import { toast } from 'react-toastify'
import { Auth } from 'aws-amplify'
import { initalizeAWSSigner } from '../../utils/AWS'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'

const Compliance = (props) => {
  const rfxDocumentsList = useSelector(
    (state) => state.rfxDocumentsList.rfxDocuments ?? []
  )
  const rfxIdForCompliance = useSelector(
    (state) => state.analyticsCompliance?.analyticComplianceData?.rfx_id ?? ''
  )
  const { PDFData, isFocused, setGoToPage } = props
  const { id = '' } = PDFData

  const [showDropdown, setShowDropdown] = useState(false)
  const [rfxName, setRfxName] = useState([])
  const [rfxId, setRfxId] = useState(null)
  const [isAnalysedClicked, setIsAnalysedClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [complianceData, setComplianceData] = useState(null)
  const classes = useStyles()
  const { buttonWrapper1, buttonText } = classes
  const [isCopied, setIsCopied] = useState(false)
  const [analysedRfxList, setAnalysedRfxList] = useState([])
  const [isDataPolling, setIsDataPolling] = useState(false)
  const [signerObj, setSignerObj] = useState({})
  const auth = useSelector((state) => state.authenticate)
  const [currentRfxName, setCurrentRfxName] = useState('')

  const dispatch = useDispatch()

  const handleAddFromRfxClick = () => {
    setShowDropdown(!showDropdown)
  }

  // useEffect(() => {
  //   const getAnalysedRfx = async () => {
  //     const data = {
  //       id: id
  //     }
  //     const result = await getAnalysedRFxList(data);
  //     if (result?.status === 200) {
  //       setAnalysedRfxList(result?.data?.data);
  //     }
  //   }
  //   if (isFocused) {
  //     // toast.warning("Please wait while we fetch the analysed RFx.");
  //     getAnalysedRfx();
  //     if (rfxDocumentsList.length === 0) {
  //       dispatch(fetchRFxDocuments());
  //     }
  //   }
  // }, [isFocused]);

  useEffect(() => {
    const getAnalysedRfx = async () => {
      const data = {
        id
      }
      const result = await getAnalysedRFxList(data)
      if (result?.status === 200) {
        setAnalysedRfxList(result?.data?.data)
      }
    }
    if (isFocused) {
      // toast.warning("Please wait while we fetch the analysed RFx.");
      getAnalysedRfx()
      if (rfxDocumentsList.length === 0) {
        dispatch(fetchRFxDocuments())
      }
    }
  }, [isFocused])

  useEffect(() => {
    async function initalizeData() {
      if (auth.isAuthenticated) {
        const signer = await initalizeAWSSigner()
        setSignerObj(signer)
      } else {
        setSignerObj('')
      }
    }
    initalizeData()
  }, [])

  useEffect(() => {
    if (isFocused) {
      if (analysedRfxList.length === 1) {
        handleAnalyseClick(
          analysedRfxList[0]?.rfx_id,
          'from_list',
          analysedRfxList[0]?.id
        )
      } else {
        setIsLoading(false)
      }
    }
  }, [analysedRfxList])

  useEffect(() => {
    if (rfxIdForCompliance !== '') {
      getComplianceByPolling('', id, rfxIdForCompliance)
    }
  }, [rfxIdForCompliance])

  const handleNewClick = () => {
    dispatch(
      setAnalyticsComplianceData({
        analytic_id: id
      })
    )
    dispatch(
      setUploader({
        uploaderType: 'rfx',
        showUploader: true,
        fullScreen: true
      })
    )
  }

  const handleAnalyseClick = async (rfxId, origin = '', complianceId) => {
    setIsLoading(true)
    setRfxId(rfxId)
    setCurrentRfxName(rfxName.find((item) => item.value === rfxId)?.label)
    setIsAnalysedClicked(true)
    if (analysedRfxList.length === 1) {
      setCurrentRfxName(analysedRfxList[0]?.rfx_filename)
    }

    const data = {
      rfx_id: rfxId,
      analytic_id: id
    }

    const data1 = {
      id: complianceId
    }

    let result
    if (origin === 'new') {
      result = {
        status: 200,
        origin: 'new'
      }
    } else {
      result = await getAnalyticsCompliance(data1)
    }
    if (result?.status === 200 && origin === 'from_list') {
      if (result?.data?.message === 'success') {
        const complianceResult = result?.data?.data
        if (result?.data?.compliance_status === 'completed') {
          setComplianceData(complianceResult)
          setIsLoading(false)
          setIsAnalysedClicked(true)
        } else {
          if (JSON.stringify(complianceResult) === JSON.stringify({})) {
            setIsAnalysedClicked(false)
            setIsLoading(true)
            getComplianceByPolling(rfxId, id, complianceId)
          } else {
            setComplianceData(complianceResult)
            setIsLoading(false)
            setIsAnalysedClicked(true)
            getComplianceByPolling(rfxId, id, complianceId)
          }
        }
      } else {
        setIsLoading(false)
        dispatch(
          setToasterAlert({
            message:
              'There was an error in analysing the document. Please try again later.',
            show: true,
            type: 'error'
          })
        )
      }
    } else {
      if (origin !== 'from_list') {
        const response = await triggerAnalyticsCompliance(data)
        const newComplianceId = response?.data?.compliance_id
        if (response?.status === 200) {
          setIsLoading(true)
          setIsAnalysedClicked(true)
          getComplianceByPolling(rfxId, id, newComplianceId || '')
        } else {
          setIsLoading(false)
          setIsAnalysedClicked(false)
          dispatch(
            setToasterAlert({
              message:
                'There was an error in analysing the document. Please try again later.',
              show: true,
              type: 'error'
            })
          )
        }
      }
    }
  }

  const getComplianceByPolling = async (
    rfx_id,
    analytics_id,
    compliance_id
  ) => {
    let canMakeCall = true
    setIsDataPolling(true)
    let elapsedTime = 1
    setIsLoading(true)
    setIsAnalysedClicked(true)

    const makeAPICall = async () => {
      if (canMakeCall) {
        const data = {
          rfx_id,
          analytic_id: analytics_id
        }
        const data1 = {
          id: compliance_id
        }
        try {
          const result = await getAnalyticsCompliance(data1)
          if (result?.status === 200) {
            if (result?.data?.message === 'success') {
              const complianceResult = result?.data?.data
              if (result?.data?.compliance_status === 'completed') {
                setComplianceData(complianceResult)
                setIsLoading(false)
                setIsAnalysedClicked(true)
                canMakeCall = false
                setIsDataPolling(false)
              } else {
                if (JSON.stringify(complianceResult) === JSON.stringify({})) {
                  setIsAnalysedClicked(false)
                  setIsLoading(true)
                } else {
                  setComplianceData(complianceResult)
                  setIsLoading(false)
                  setIsAnalysedClicked(true)
                }
              }
            } else {
              setIsLoading(false)
              canMakeCall = false
              dispatch(
                setToasterAlert({
                  message:
                    'There was an error in analysing the document. Please try again later.',
                  show: true,
                  type: 'error'
                })
              )
            }
          } else {
            setIsLoading(false)
            canMakeCall = false
            setIsAnalysedClicked(false)
            dispatch(
              setToasterAlert({
                message:
                  'There was an error in analysing the document. Please try again later.',
                show: true,
                type: 'error'
              })
            )
          }
          console.log(elapsedTime)
        } catch (error) {
          console.log(error)
        }
      }
    }

    const pollingInterval = () => {
      elapsedTime += 1
      if (!canMakeCall) {
        clearInterval(myInterval)
        setIsDataPolling(false)
        return
      }

      if (elapsedTime <= 120 && canMakeCall && elapsedTime % 10 === 0) {
        makeAPICall()
      } else if (
        elapsedTime > 120 &&
        elapsedTime <= 300 &&
        canMakeCall &&
        elapsedTime % 20 === 0
      ) {
        makeAPICall()
      }
    }

    makeAPICall()

    const myInterval = setInterval(pollingInterval, 1000)

    setTimeout(() => {
      canMakeCall = false
      setIsDataPolling(false)
      clearInterval(myInterval)
      setIsLoading(false)
    }, 600000)
  }

  useEffect(() => {
    if (rfxDocumentsList !== [] && isFocused && isLoading === false) {
      const options = rfxDocumentsList.map((rfx) => {
        return {
          label: rfx?.document_name,
          value: rfx?.id,
          name: rfx?.document_name
        }
      })
      setRfxName(options)
    }
  }, [isLoading])

  const handleBackButtonClick = () => {
    setIsAnalysedClicked(false)
    setIsDataPolling(false)
    setIsLoading(false)
    setComplianceData(null)
    setRfxId(null)
    setCurrentRfxName('')
  }

  const handleDownload = () => {
    // Convert JSON data to text format
    let textContent = ''

    for (const key in complianceData) {
      const entry = complianceData[key]
      textContent += `Requirement: ${entry.requirement_title}\n`
      entry.requirement_details !== ''
        ? (textContent += `Description: ${entry.requirement_details}\n`)
        : (textContent += '')
      textContent += `Analysis: ${entry.compliance}\n`
      textContent += `Updated at: ${entry.updated_at}\n\n`
    }

    // Create a Blob containing the text
    const blob = new Blob([textContent], { type: 'text/plain' })

    // Create a temporary URL to the file
    const url = window.URL.createObjectURL(blob)

    // Create a link element and click it to trigger the download
    const link = document.createElement('a')
    link.href = url
    link.download = 'Compliance_Data.txt'
    document.body.appendChild(link)
    link.click()

    // Clean up resources
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const handleCopyClick = (value) => {
    let text = `Requirement: ${complianceData[value].requirement_title}\n`
    text += `Description: ${complianceData[value].requirement_details}\n`
    text += `Reference (page no): ${complianceData[value].requirement_page_number}\n\n`
    text += `Analysis: ${complianceData[value].compliance}\n`
    text += `Reference (page no): ${complianceData[value].compliance_page_number}\n\n`
    setIsCopied(true)
    navigator.clipboard.writeText(text)
    setTimeout(() => {
      setIsCopied(false)
    }, 1500)
    dispatch(
      setToasterAlert({
        message: 'Content Copied',
        show: true,
        type: 'success'
      })
    )
  }

  const [editModeId, setEditModeId] = useState(null)
  const [editedPrompt, setEditedPrompt] = useState('')
  const [refreshId, setRefreshId] = useState(null)

  const handleAnalysisComplianceChange = (key, value) => {
    const newComplianceData = { ...complianceData }

    try {
      const jsonData = JSON.parse(value)
      newComplianceData[key].compliance = jsonData?.compliance_analysis
      newComplianceData[key].compliance_page_number = jsonData?.page_number
    } catch (error) {
      console.log(error)
    }
    setComplianceData(newComplianceData)
  }

  const handleEditClick = (id, currentPrompt) => {
    setRefreshId(id)
    setEditModeId(id)
    setEditedPrompt(currentPrompt)
  }

  const handleAnalyticComplianceRefresh = async () => {
    const data = {
      analytic_id: id,
      requierment: complianceData[refreshId].requirement_title,
      requirement_details: complianceData[refreshId].requirement_details,
      prompt_from_user: editedPrompt,
      prompt: complianceData[refreshId].prompt,
      requestType: 'analyticsComplianceRefresh'
    }

    const url = process.env.REACT_APP_CHAT_URL || ''
    const apiUrl = new URL(url)
    const currentSession = await Auth.currentSession()
    const token = currentSession?.getAccessToken()?.getJwtToken()
    const signedUrl = await signerObj.sign({
      method: 'POST',
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      body: JSON.stringify(data),
      protocol: apiUrl.protocol,
      headers: {
        'Content-Type': 'application/json',
        host: apiUrl.hostname,
        userAuth: 'Bearer ' + token
      }
    })
    const response = await fetch(`${url}`, { ...signedUrl })
    let completeText = ''
    try {
      if (response.status === 200 && response.body) {
        const reader = response.body.getReader()
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }
          const text = new TextDecoder().decode(value)
          console.log(text)
          completeText = completeText + text
        }
        completeText = completeText.replace(/__END_OF_CHAT__/g, '')
        console.log(completeText)
        await handleAnalysisComplianceChange(refreshId, completeText)
        setRefreshId(null)
      } else {
        console.log('Something went wrong. Extraction Failed.')
        setRefreshId(null)
      }
    } catch (error) {
      console.log(error)
      setRefreshId(null)
    }
  }

  const handleSaveClick = async () => {
    setEditModeId(null)
    const refreshCompliance = await handleAnalyticComplianceRefresh()
    const newComplianceData = { ...complianceData }

    if (newComplianceData[refreshId]?.user_prompt) {
      newComplianceData[refreshId].user_prompt = editedPrompt
    } else {
      newComplianceData[refreshId] = {
        ...newComplianceData[refreshId],
        user_prompt: editedPrompt
      }
    }
    setComplianceData(newComplianceData)
    const updateComplianceData = {
      rfx_id: rfxId,
      analytic_id: id,
      compliance: newComplianceData[refreshId].compliance,
      user_prompt: editedPrompt,
      requirement_id: editModeId,
      compliance_page: newComplianceData[refreshId].compliance_page_number
    }
    const res = await updateAnalyticsCompliance(updateComplianceData)
    if (res?.status === 200) {
      toast.success('Analysis updated')
    }
  }

  const handleCancelClick = () => {
    setEditModeId(null)
    setRefreshId(null)
  }

  const handlePDFViewer = async (rfxData, rfxPage) => {
    const newPath = `/rfx/pdf/${rfxData}/${rfxPage}`
    window.open(newPath, '_blank')
  }

  const renderAccordions = () => {
    return Object.keys(complianceData).map((id, index) => {
      const {
        requirement_title = '',
        requirement_details = '',
        status = '',
        compliance = '',
        prompt = '',
        user_prompt = '',
        requirement_page_number = '',
        compliance_page_number = ''
      } = complianceData[id]
      const isCompleted = status === 'success'
      const isDisabled = status === 'pending'
      const isIgnored = status === 'ignored'

      // let convertedAnalysis = '';
      // convertedAnalysis = convertToHTML(compliance)

      return (
        <div
          key={index}
          style={{
            padding: '8px'
          }}
        >
          <Accordion
            key={id}
            disabled={isDisabled}
            defaultExpanded={isCompleted}
            square={true}
            style={{
              marginBottom: '0px',
              backgroundColor: '#f0f3f9',
              border: '1px solid #ccc'
            }}
          >
            <AccordionSummary
              expandIcon={
                isDisabled ? (
                  <CircularProgress size={18} />
                ) : (
                  <ExpandMoreIcon style={{ fontSize: '18px' }} />
                )
              }
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Box
                  sx={{ fontWeight: 'bold', alignSelf: 'center' }}
                  style={{}}
                >
                  {requirement_title}
                </Box>
                <div
                  style={{
                    display: 'flex',
                    marginRight: '0px'
                  }}
                >
                  {!isDisabled && (
                    <Tooltip title="Copy">
                      <IconButton
                        id="copy-content"
                        disableRipple
                        onClick={(e) => {
                          e.stopPropagation()
                          handleCopyClick(id)
                        }}
                      >
                        <ContentCopyIcon style={{ fontSize: '18px' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: '0px', display: 'block' }}>
              {requirement_details && (
                <>
                  <div style={{ fontSize: '15px' }}>{requirement_details}</div>
                  {requirement_page_number &&
                    requirement_page_number !== 'None' && (
                      <span
                        color="primary"
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline'
                        }}
                        onClick={() =>
                          handlePDFViewer(rfxId, requirement_page_number)
                        }
                      >
                        Page {requirement_page_number}
                        <br></br>
                      </span>
                    )}

                  <br></br>
                </>
              )}
              {compliance && (
                <>
                  <strong>Analysis: </strong>
                  {/* <div dangerouslySetInnerHTML={{ __html: convertedAnalysis }}></div> */}
                  <div style={{ whiteSpace: 'pre-line' }}>{compliance}</div>
                  {compliance_page_number &&
                    compliance_page_number !== 'None' && (
                      <span
                        color="primary"
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline'
                        }}
                        onClick={() => setGoToPage(compliance_page_number)}
                      >
                        Page {compliance_page_number}
                      </span>
                    )}
                </>
              )}
              <div
                style={{
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: editModeId === id ? '16px' : '0px'
                }}
              >
                {editModeId === id && (
                  <>
                    <div style={{ width: '100%' }}>
                      <strong>Instructions to AI </strong>

                      <TextareaAutosize
                        value={editedPrompt}
                        onChange={(e) => setEditedPrompt(e.target.value)}
                        minRows={3}
                        style={{
                          width: '100%',
                          fontFamily: 'inherit',
                          fontSize: 'inherit',
                          padding: '5px',
                          border: '1px solid #ccc',
                          borderRadius: '4px'
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '5px'
                      }}
                    >
                      <IconButton
                        id="info-content"
                        disableRipple
                        disabled={true}
                      >
                        <InfoIcon style={{ fontSize: '20px' }} />
                      </IconButton>
                      You can add more instructions to make it more relevant to
                      your use case.
                    </div>
                  </>
                )}
                <div
                  style={{
                    marginRight: '5px',
                    marginTop: '5px'
                  }}
                >
                  {editModeId === id ? (
                    <>
                      <Button
                        className={buttonWrapper1}
                        onClick={() => handleCancelClick()}
                      >
                        <p className={buttonText}>Cancel</p>
                      </Button>
                      <Button
                        className={buttonWrapper1}
                        onClick={() => handleSaveClick()}
                      >
                        <p className={buttonText}>Re-analyse</p>
                      </Button>
                    </>
                  ) : (
                    <>
                      {isDataPolling || isIgnored ? (
                        <></>
                      ) : (
                        <Button
                          disabled={refreshId === id}
                          className={buttonWrapper1}
                          onClick={() => handleEditClick(id, user_prompt)}
                        >
                          <p className={buttonText}>
                            {refreshId === id ? 'Refreshing...' : 'Refresh'}
                          </p>
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )
    })
  }

  if (isLoading) {
    return (
      <>
        <div
          style={{
            margin: '5px 0px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Button className={buttonWrapper1} onClick={handleBackButtonClick}>
            <p className={buttonText}>Go Back</p>
          </Button>
        </div>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Loader loading={true} caption={'loading'} />
        </Box>
      </>
    )
  }

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              margin: '5px 0px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Button className={buttonWrapper1} onClick={handleBackButtonClick}>
              <p className={buttonText}>Go Back</p>
            </Button>
          </div>
          <Loader loading={true} caption={'loading'} />
        </Box>
      ) : (
        <>
          {isAnalysedClicked ? (
            <>
              <div
                style={{
                  margin: '5px 0px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Button
                  disabled={isDataPolling || refreshId}
                  className={buttonWrapper1}
                  onClick={handleBackButtonClick}
                >
                  <p className={buttonText}>Go Back</p>
                </Button>
                <span>{currentRfxName}</span>
                <Button
                  disabled={isDataPolling || refreshId}
                  className={buttonWrapper1}
                  onClick={handleDownload}
                >
                  <p className={buttonText}>Download</p>
                </Button>
              </div>
              <div>{renderAccordions()}</div>
            </>
          ) : (
            <div
              style={{
                // height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '20px'
              }}
            >
              <Box
                sx={{
                  // height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <div style={{ marginBottom: '10px' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Button className={buttonWrapper1} onClick={handleNewClick}>
                      <p className={buttonText}>Upload New</p>
                    </Button>
                    <Button
                      className={buttonWrapper1}
                      onClick={handleAddFromRfxClick}
                    >
                      <p className={buttonText}>Add from Rfx</p>
                    </Button>
                  </div>
                </div>
                {showDropdown && (
                  <div
                    style={{
                      width: '50%'
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      options={rfxName}
                      onChange={(event, value) => setRfxId(value?.value)}
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          padding: '0px 3.5px',
                          margin: '7.5px',
                          maxWidth: '-webkit-fill-available'
                        },
                        '& .MuiOutlinedInput-input': {
                          // fontSize: 'revert'
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select a RFx *"
                          type="text"
                          variant="outlined"
                          label=""
                        />
                      )}
                    />
                    <div
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      {/* <Button
                          disabled={!rfxId}
                          onClick={() => { handleAnalyseClick() }}
                          sx={{
                            backgroundColor: 'rgb(219, 234, 255)',
                            color: 'rgb(6, 69, 173)',
                            border: '1px solid rgb(6, 69, 173)',
                            borderRadius: '4px',
                            marginRight: '7.5px',
                            transition: 'transform 0.5s ease', // Smooth transition for the animation
                            transform: !rfxId ? 'translateX(-170%)' : 'translateX(0)',
                          }}
                        >
                          {rfxId ? 'Let\'s Go!' : 'Go'}
                        </Button> */}
                      <Button
                        className={buttonWrapper1}
                        disabled={!rfxId}
                        onClick={() => {
                          handleAnalyseClick(rfxId, 'new', '')
                        }}
                        // sx={{
                        //   transition: 'transform 0.5s ease', // Smooth transition for the animation
                        //   transform: !rfxId ? 'translateX(-200%)' : 'translateX(0)',
                        // }}
                      >
                        <p className={buttonText}>
                          {rfxId ? "Let's Go!" : 'Go'}
                        </p>
                      </Button>
                    </div>
                  </div>
                )}
                {analysedRfxList.length > 0 && (
                  <div
                    style={{
                      margin: '10px',
                      width: '80%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <strong>History</strong>
                    <Box
                      sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper'
                      }}
                    >
                      <nav aria-label="main mailbox folders">
                        <List
                          dense={true}
                          sx={{
                            width: '100%'
                          }}
                        >
                          {analysedRfxList.map((rfx) => {
                            return (
                              <>
                                <ListItem disablePadding>
                                  <ListItemButton
                                    onClick={() =>
                                      handleAnalyseClick(
                                        rfx?.rfx_id,
                                        'from_list',
                                        rfx?.id
                                      )
                                    }
                                  >
                                    <ListItemText primary={rfx?.rfx_filename} />
                                  </ListItemButton>
                                </ListItem>
                                <Divider />
                              </>
                            )
                          })}
                        </List>
                      </nav>
                    </Box>
                  </div>
                )}
              </Box>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Compliance
