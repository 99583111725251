import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  image: {
    width: '16px',
    height: '16px',
    color: '#27272a'
  },
  container: {
    /* width: '50px', */
    /* height: "calc(100vh - 40px)", */
    height: '100%',
    borderLeft: '1px solid rgb(238, 238, 238)'
    // borderTop: "1px solid rgb(238, 238, 238)",
  },
  wrapper: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '11px',
    display: 'flex',
    '&:hover': {
      background: '#f4f4f5',
      cursor: 'pointer'
    }
    /*     borderBottom: '1px solid rgb(238, 238, 238)'
     */
  },
  activeWrapper: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '11px',
    display: 'flex',
    background: '#f4f4f5',
    '&:hover': {
      background: '#f4f4f5',
      cursor: 'pointer'
    }
  }
})

export { useStyles }
