import * as React from 'react'
import Box from '@mui/material/Box'
import { useStyles } from './styles'
import clsx from 'clsx'
export default function Container(props) {
  const { id, className, overFlow = false, parentRef = () => {} } = props
  const classes = useStyles({ overFlow })
  const { section } = classes

  return (
    <Box
      id={id}
      {...props}
      ref={parentRef}
      component="main"
      className={clsx(section, className)}
    >
      {props.children}
    </Box>
  )
}
