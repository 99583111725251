import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  outerContainer: {
    borderTop: 'solid 1px #E5E5E5'
  },
  libraryContainer: {
    padding: '100px'
  },
  listContainer: {
    padding: '20px'
  },
  hideMenuListText: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#000000',
    width: '100px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word'
  },
  hideMenuDivider: {
    margin: '0px !important'
  },
  sortWrapper: {
    cursor: 'pointer'
  },
  hideMenu: {
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    },
    '& .MuiButtonBase-root': {
      padding: '3px 5px'
    },
    '& .MuiMenu-paper': {
      border: '1px solid #E5E5E5',
      marginTop: '10px'
    },
    '& .MuiList-root': {
      padding: '0px'
    }
  },
  iconButton: {
    padding: '0px !important'
  },
  icon: {
    fontSize: '20px !important',
    color: '#797979'
  }
})

export { useStyles }
