import React, { useEffect } from 'react'
import { Container, Section } from '../../components/Container'
import SessionChatViewer from '../../containers/SessionChatViewer'
import { ROUTES } from '../../config/routes'
import { checkUserRoleSuperUser } from '../../utils/User'
import { useNavigate } from 'react-router-dom'

const SessionChatViewers = () => {
  const { DASHBOARD } = ROUTES
  const navigate = useNavigate()

  useEffect(() => {
    const checkIsSuperUser = async () => {
      const isSuperUser = await checkUserRoleSuperUser()

      if (!isSuperUser) {
        navigate({
          pathname: DASHBOARD
        })
      }
    }

    checkIsSuperUser()
  }, [])
  return (
    <Container>
      <Section>
        <SessionChatViewer />
      </Section>
    </Container>
  )
}

export default SessionChatViewers
