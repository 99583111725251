import {
  FETCH_ANALYTIC,
  FETCH_ANALYTICS,
  UPDATE_ANALYTICS,
  DELETE_ANALYTIC,
  FETCH_STYLE_SETTINGS,
  UPDATE_STYLE_SETTINGS,
  FETCH_CHART_DATA
} from '../../actionTypes'
import { findIndex, cloneDeep } from 'lodash'

const initialState = {
  analytic: {},
  chartingData: null,
  analytics: null,
  styleSettings: null
}

function analyticsList(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case FETCH_ANALYTIC:
      return {
        ...state,
        analytic: payload
      }
    case FETCH_ANALYTICS:
      return {
        ...state,
        analytics: payload
      }
    case UPDATE_ANALYTICS: {
      const newAnalytics = cloneDeep(state.analytics)
      const index = findIndex(newAnalytics, { id: payload.id })
      newAnalytics[index] = payload
      return {
        ...state,
        analytics: newAnalytics
      }
    }
    case DELETE_ANALYTIC: {
      const newAnalyticsList = cloneDeep(state.analytics)
      const indexdel = findIndex(newAnalyticsList, { id: payload.id })
      if (indexdel > -1) {
        newAnalyticsList.splice(indexdel, 1)
      }
      return {
        ...state,
        analytics: newAnalyticsList
      }
    }
    case FETCH_STYLE_SETTINGS:
      return {
        ...state,
        styleSettings: payload
      }

    case UPDATE_STYLE_SETTINGS:
      return {
        ...state,
        styleSettings: payload
      }

    case FETCH_CHART_DATA:
      return {
        ...state,
        chartingData: payload
      }

    default:
      return state
  }
}

export default analyticsList
