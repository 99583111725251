const mixpanelEvents = {
  DASHBOARD_SEARCH_TEXT: 'Dashboard Search Text',
  DASHBOARD_SEARCH_ASSETS: 'Dashboard Search Assets',
  DASHBOARD_SEARCH: 'Dashboard Search',
  DASHBOARD_CHAT: 'Dashboard Chat',
  DASHBOARD_RESULT_COPIED: 'Dashboard Result Copied',
  DASHBOARD_RESULT_PROPOSAL_OPENED: 'Dashboard Result Proposal Opened',
  DASHBOARD_SCREENSHOT_OPENED: 'Dashboard Screenshot Opened',
  DASHBOARD_PROJECT_OPENED: 'Dashboard Project Opened',
  DASHBOARD_PEOPLE_RESUME_OPENED: 'Dashboard People Resume Opened',
  DASHBOARD_FILTERS_APPLIED: 'Dashboard Filters Applied',
  DASHBOARD_RESULT_PROPOSAL_AI_GENERATE_USED:
    'Dashboard Result Proposal AI Generate Used',
  DASHBOARD_RESULT_PROPOSAL_TEXT_COPIED:
    'Dashboard Result Proposal Text Copied',
  DASHBOARD_RESULT_PROPOSAL_CREATE_KNOWLEDGE:
    'Dashboard Result Proposal Create Knowledge',
  DASHBOARD_RESULT_PROJECT_OPENED: 'Dashboard Result Project Opened',

  KNOWLEDGE_TAB_CLICKED: 'Knowledge Tab Clicked',
  KNOWLEDGE_CONTENT_OPENED: 'Knowledge Content Opened',
  KNOWLEDGE_CONTENT_CREATED: 'Knowledge Content Created',
  KNOWLEDGE_CONTENT_UPDATED: 'Knowledge Content Updated',
  KNOWLEDGE_CONTENT_COPIED: 'Knowledge Content Copied',
  KNOWLEDGE_CONTENT_AI_GENERATE_USED: 'Knowledge Content AI Generate Used',
  KNOWLEDGE_CONTENT_DELETED: 'Knowledge Content Deleted',

  ASSETS_TAB_CLICKED: 'Assets Tab Clicked',
  ASSET_OPENED: 'Asset Opened',
  ASSET_DOWNLOAD: 'Asset Download',
  ASSET_UPLOAD: 'Asset Upload',
  ASSET_ADDED_TAG: 'Asset Added Tag', // Not added this event
  ASSET_DELETED_TAG: 'Asset Deleted Tag', // Not added this event
  ASSET_EDITED_TAG: 'Asset Edited Tag', // Not added this event

  PROPOSALS_TAB_CLICKED: 'Proposals Tab Clicked',
  PROPOSAL_OPENED: 'Proposal Opened',
  PROPOSAL_DOWNLOAD: 'Proposal Download',
  PROPOSAL_UPLOAD: 'Proposal Upload',
  PROPOSAL_DELETED: 'Proposal Deleted',
  PROPOSAL_FILTER_APPLIED: 'Proposal Filter Applied',
  PROPOSAL_CHAT: 'Proposal Chat',

  PEOPLE_TAB_CLICKED: 'People Tab Clicked',
  PEOPLE_MASTER_RESUME_OPENED: 'People Master Resume Opened',
  PEOPLE_RESUME_OPENED: 'People Resume Opened',
  PEOPLE_RESUME_DELETED: 'People Resume Deleted',
  PEOPLE_RESUME_REMOVED: 'People Resume Removed',
  PEOPLE_RESUME_MERGED: 'People Resume Merged',
  PEOPLE_RESUME_SEARCHED: 'People Resume Searched',
  PEOPLE_MASTER_RESUME_NAME_UPDATED: 'People Master Resume Name Updated',

  PROJECTS_TAB_CLICKED: 'Projects Tab Clicked',
  PROJECT_OPENED: 'Project Opened',
  PROJECT_VARIATION_OPENED: 'Project Variation Opened',
  PROJECT_SEARCHED: 'Project Searched',
  PROJECTS_MERGED: 'Projects Merged',

  WORKSPACE_DOCUMTENTS_TAB_OPENED: 'Workspace Documents Tab Opened',
  WORKSPACE_DOCUMENT_OPENED: 'Workspace Document Opened',
  WORKSPACE_DOCUMENT_DELETED: 'Workspace Document Deleted',
  WORKSPACE_DOCUMENT_CREATED: 'Workspace Document Created',
  WORKSPACE_AI_GENERATE_USED: 'Workspace AI Generate Used',
  WORKSPACE_CHAT: 'Workspace Chat',

  WORKSPACE_TAB_OPENED: 'Workspace Tab Opened',
  WORKSPACE_OPENED: 'Workspace Opened',
  WORKSPACE_DELETED: 'Workspace Deleted',
  WORKSPACE_CREATED: 'Workspace Created',
  WORKSPACE_FILES_OPENED: 'Workspace Files Opened',
  WORKSPACE_FILES_UPLOAD: 'Workspace Files Upload',
  WORKSPACE_STORYBOARD_OPENED: 'Workspace Storyboard Opened',
  WORKSPACE_STORYBOARD_DELETED: 'Workspace Storyboard Deleted',
  WORKSPACE_STORYBOARD_DATA_EDIT: 'Workspace Storyboard Data Edit',
  WORKSPACE_STORYBOARD_ADD_SUB_SECTION: 'Workspace Storyboard Add Sub Section',
  WORKSPACE_STORYBOARD_DELETE_SUB_SECTION:
    'Workspace Storyboard Delete Sub Section',
  WORKSPACE_STORYBOARD_EDIT_SUB_SECTION:
    'Workspace Storyboard Edit Sub Section',
  WORKSPACE_STORYBOARD_ADD_PEOPLE: 'Workspace Storyboard Add People',
  WORKSPACE_STORYBOARD_DELETE_PEOPLE: 'Workspace Storyboard Delete People',
  WORKSPACE_STORYBOARD_ADD_PROJECT: 'Workspace Storyboard Add Project',
  WORKSPACE_STORYBOARD_DELETE_PROJECT: 'Workspace Storyboard Delete Project',
  WORKSPACE_STORYBOARD_GENERATED: 'Workspace Storyboard Generated',
  WORKSPACE_STORYBOARD_CREATED: 'Workspace Storyboard Created',

  ANALYTICS_TAB_CLICKED: 'Analytics Tab Clicked',
  ANALYTICS_FILE_OPENED: 'Analytics File Opened',
  ANALYTICS_FILE_DELETED: 'Analytics File Deleted',
  ANALYTICS_FILE_UPLOAD: 'Analytics File Upload',
  ANALYTICS_SUGGESTION_TAB_OPENED: 'Analytics Suggestion Tab Opened',
  ANALYTICS_INSIGHTS_TAB_OPENED: 'Analytics Insights Tab Opened',
  ANALYTICS_MARKED_AS_RESOLVED: 'Analytics Marked As Resolved',
  ANALYTICS_MARKED_AS_IGNORED: 'Analytics Marked As Ignored',
  ANALYTICS_STYLE_GUIDE_TAB_OPENED: 'Analytics Style Guide Tab Opened',
  ANALYTICS_STYLE_GUIDE_WORDY_PHRASES_ADDED:
    'Analytics Style Guide Wordy Phrases Added',
  ANALYTICS_STYLE_GUIDE_WORDY_PHRASES_DELETED:
    'Analytics Style Guide Wordy Phrases Deleted',
  ANALYTICS_STYLE_GUIDE_WORDS_TO_AVOID_ADDED:
    'Analytics Style Guide Words To Avoid Added',
  ANALYTICS_STYLE_GUIDE_WORDS_TO_AVOID_DELETED:
    'Analytics Style Guide Words To Avoid Deleted',
  ANALYTICS_CHAT: 'Analytics Chat / Analytics Win Strategy Tab Opened',

  RFX_TAB_CLICKED: 'Rfx Tab Clicked',
  RFX_FILE_OPENED: 'Rfx File Opened',
  RFX_FILE_DELETED: 'Rfx File Deleted',
  RFX_FILE_UPLOAD: 'Rfx File Upload',
  RFX_CONTENT_COPIED: 'Rfx Content Copied',
  RFX_CONTENT_EDITED: 'Rfx Content Edited',
  RFX_CONTENT_REFRESHED: 'Rfx Content Refreshed',
  RFX_FILE_DOWNLOAD: 'Rfx File Download',
  RFX_CHAT: 'Rfx Chat',
  RFX_FILE_UPLOAD_FROM_COMPLIANCE: 'Rfx File Upload From Compliance',

  CHAT_TAB_CLICKED: 'Chat Tab Clicked',
  CHAT_SESSION_OPENED: 'Chat Session Opened',
  CHAT_SESSION_DELETED: 'Chat Session Deleted',
  CHAT_SESSION_SHARED: 'Chat Session Shared',
  CHAT_SESSION_RENAMED: 'Chat Session Renamed',
  CHAT_SESSION_GEN_TITLE: 'Chat Session Gen Title',
  CHAT_SESSION_REGEN_TITLE: 'Chat Session Regen Title',
  CHAT_NEW_SESSION: 'Chat New Session',
  CHAT_IMAGE_GENERATED: 'Chat Image Generated',
  CHAT_IMAGE_SAVE_INTO_ASSETS: 'Chat Image Save Into Assets',
  CHAT_FEEDBACK_SUBMITTED: 'Chat Feedback Submitted',
  CHAT_CONTENT_COPIED: 'Chat Content Copied',
  CHAT_SESSION_SEARCH: 'Chat Session Search',
  CHAT_EXISTING_SESSION: 'Chat Existing Session',
  CHAT_SESSION_FILTER_APPLIED: 'Chat Session Filter Applied',
  CHAT_VOICE_APPLIED: 'Chat Voice Applied',
  CHAT_SOCKET_CONNECTED: 'Chat Socket Connected',
  CHAT_SOCKET_DISCONNECTED: 'Chat Socket Disconnected',
  CHAT_SOCKET_ERRORED: 'Chat Socket Errored',
  CHAT_PROMPT_LIBRARY_OPENED: 'Chat Prompt Library Opened',
  CHAT_PROMPT_COPIED: 'Chat Prompt Copied',
  CHAT_PROMPT_USED: 'Chat Prompt Deleted',

  SETTINGS_TAB_CLICKED: 'Settings Tab Clicked',
  SETTINGS_PROFILE_DETAILS_UPDATED: 'Settings Profile Details Updated',
  SETTINGS_DOMAIN_DETAILS_UPDATED: 'Settings Domain Details Updated',
  SETTINGS_USER_INVITED: 'Settings User Invited',
  SETTINGS_CHAT_INSTRUCTIONS_UPDATED: 'Settings Chat Instructions Updated',

  AI_GENERATE_USED: 'AI Generate Used',

  DOCUMENT_UPLOAD: 'Document Upload',
  DOCUMENT_CHAT: 'Document Chat',

  CLIENTS_TAB_CLICKED: 'Clients Tab Clicked',

  TAGS_ADDED_TO_UPLOAD: 'Added tags while uploading',

  COMMAND_BAR_LAUNCHED: 'Command Bar Launched',
  COMMAND_BAR_COMMAND_SEARCH: 'Search from Command Bar',
  COMMANDBAR_NAVIGATION: 'Command Bar Navigation',
  SIDEBAR_TOGGLE: 'Sidebar Toggle'
}

export default mixpanelEvents
