import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { IconButton, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useStyles } from './styles'
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'

const ToasterAlert = (props) => {
  const {
    id = 'toaster',
    message = 'This is success message',
    show = false,
    type = 'success',
    handleClose,
    showSpinner = false,
    spinnerProgress = 50
  } = props
  const classes = useStyles()
  const { closeIcon, alertIcon, spinnerContainer } = classes
  const [state, setState] = React.useState({
    open: show,
    vertical: 'top',
    horizontal: 'right'
  })

  const { vertical, horizontal, open } = state

  return (
    <>
      <Snackbar
        id="snack-bar"
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose || (() => {})}
        message={message}
        key={vertical + horizontal}
        className={alertIcon}
      >
        <Alert severity={type}>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid>{message}</Grid>
            {handleClose && (
              <Grid className={closeIcon}>
                <IconButton
                  id={`${id}-close`}
                  onClick={handleClose}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <Box className={spinnerContainer}>
            {showSpinner && (
              <LinearProgress variant="determinate" value={spinnerProgress} />
            )}
          </Box>
        </Alert>
      </Snackbar>
    </>
  )
}
export default ToasterAlert
