import { Grid } from '@mui/material'
import MenuItemCard from '../MenuItemCard'
import { useStyles } from './styles'
const EmptyRecord = (props) => {
  const { icon, title, displayMessage, showAction = false } = props
  const classes = useStyles()
  const { menuButton, displayMessageContainer } = classes

  const option = {
    icon,
    title,
    description: ''
  }
  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      id="empty records"
    >
      <Grid className={displayMessageContainer}>{displayMessage}</Grid>
      {/* {showAction && <Grid className={menuButton}>{MenuItemCard(option)}</Grid>} */}
    </Grid>
  )
}

export default EmptyRecord
