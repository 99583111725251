import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import TextResult from '../TextResult'
import Button from '../../components/Button'
import { getSuggestionSource } from '../../store/api'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { setToasterAlert } from '../../store/Common/Actions'
import Loader from '../../components/Loader'
import { copyHTMLContent } from '../../utils/CopyHTML'
import { useStyles } from './styles'
import { getSignedUrlHead, checkExpiry, getImageMeta } from '../../utils/AWS'

const SuggestionSource = (props) => {
  const {
    data = {},
    showSuggestionSource = false,
    setShowSuggestionSource = () => {},
    s3Obj = {},
    selectedImages = [],
    setSelectedImages = () => {},
    setSelectedImageIndex = () => {}
  } = props

  const [sourceContent, setSourceContent] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()
  const { variationWrapper } = classes

  useEffect(() => {
    async function getSource() {
      setLoading(true)
      const req = {
        id: data.id
      }
      const res = await getSuggestionSource(req)
      if (res.status === 200) {
        const { results } = res.data
        setSourceContent(results)
        const resultClone = [...results]
        await Promise.all(
          results.map(async (result, index) => {
            const { screenshots, thumbnail_location } = result
            try {
              let combinedScreenshots = [...screenshots]
              if (_.isEmpty(combinedScreenshots)) {
                if (thumbnail_location) {
                  combinedScreenshots = [{ src: thumbnail_location }]
                } else {
                  const { proposal_id, page_number, domain_id } = result
                  if (
                    process.env.REACT_APP_IMAGE_BUCKET &&
                    proposal_id &&
                    (page_number === 0 || page_number) &&
                    domain_id
                  ) {
                    const potentialLocation = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/${page_number}.png`
                    combinedScreenshots = [{ src: potentialLocation }]
                  }
                }
              }
              const promises = combinedScreenshots.map(async (e, i) => {
                let { src, width, height } = { ...e }
                const signedSrc = await getSignedUrlHead(src, s3Obj)
                if (signedSrc) {
                  if (!width || !height) {
                    const img = await getImageMeta(signedSrc)
                    width = img.width
                    height = img.height
                  }
                  return { ...e, src: signedSrc, width, height }
                }
                return e
              })
              const updatedScreenshots = await Promise.all(promises)
              resultClone[index] = {
                ...resultClone[index],
                screenshots: updatedScreenshots
              }
            } catch (e) {
              console.log(e)
            }
          })
        )
        setTimeout(() => {
          setSourceContent(resultClone)
        }, 5000)
      } else {
        dispatch(
          setToasterAlert({
            message: 'Unable to fetch suggestion source',
            show: true,
            type: 'error'
          })
        )
        setSourceContent([])
      }
      setLoading(false)
    }
    if (showSuggestionSource && !_.isEmpty(data.id)) {
      getSource()
    }
  }, [data, showSuggestionSource])

  const handleCopy = async (content = '', title = '') => {
    const newContent = `<html><body><b>${title}</b><br>${content}</body></html>`
    copyHTMLContent(newContent)
  }

  const openPdf = (data) => {
    const { proposal_id, page_number } = data || {}
    if (proposal_id && page_number) {
      const path = `/view/pdf/${proposal_id}/${page_number}`
      window.open(path, '_blank')
    }
  }

  const handleShowImage = async (screenshots, index = 0) => {
    await Promise.all(
      screenshots.map(async (img, index) => {
        const signedSrc = await checkExpiry(img.src, s3Obj)
        screenshots[index].src = signedSrc
      })
    )
    setSelectedImages(screenshots)
    setSelectedImageIndex(index)
  }

  return (
    showSuggestionSource && (
      <Box className={variationWrapper}>
        {loading ? (
          <Box
            sx={{
              margin: '10px 0px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Loader loading={loading} caption={''} />
          </Box>
        ) : (
          <>
            {!_.isEmpty(sourceContent) &&
              sourceContent.map((item, index) => {
                const { screenshots = [], data } = item || {}
                const { content, title } = data || {}
                return (
                  <TextResult
                    key={index}
                    result={item}
                    result_type={'text'}
                    hideTextLength={300}
                    optionHiddenOpen={false}
                    handleCopy={() => handleCopy(content, title)}
                    handlePDFViewer={() => openPdf(item)}
                    enableSave={false}
                    handleShowImage={handleShowImage}
                    screenshots={screenshots}
                    s3Obj={s3Obj}
                    src={'librarySuggestions'}
                  />
                )
              })}
          </>
        )}
      </Box>
    )
  )
}

export default SuggestionSource
