import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { Grid } from '@mui/material'
import AnalyticCorrection from './AnalyticCorrection'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { getWinStrategyWithoutUser } from '../../store/api'
import MarkdownIt from 'markdown-it'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const PublicWinStrategy = (props) => {
  const { id = '' } = props

  const [initialPromptList, setInitialPromptList] = useState({})

  useEffect(() => {
    const handleWinStrategy = async () => {
      const res = await getWinStrategyWithoutUser({ id })
      if (res.status === 200) {
        setInitialPromptList(res.data.result)
      }
    }
    handleWinStrategy()
  }, [])

  const md = new MarkdownIt() // this is used to convert textToHtml

  const convertToHTML = (markdownText) => {
    return md.render(markdownText) // this is used to convert textToHtml
  }

  const processText = (inputText) => {
    const removeDoubleLines = inputText.replace(/\n\n/g, '\n') // this function removes two new line characters and changes it to only one
    return removeDoubleLines
  }

  return (
    <Box
      sx={{
        background: '#fff',
        borderRadius: '5px',
        padding: '20px',
        marginTop: '5px',
        maxWidth: '58rem',
        margin: 'auto',
        overflow: 'auto'
      }}
    >
      {Object.entries(initialPromptList).map(([key, value], index) => {
        if (!value) return <></>
        value = processText(value)
        value = convertToHTML(value)
        return (
          <Accordion
            defaultExpanded={index === 0}
            key={index}
            square={true}
            style={{
              marginBottom: '16px',
              backgroundColor: '#f0f3f9',
              border: '1px solid #ccc'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ marginBottom: '0px' }}
            >
              <Box sx={{ fontWeight: 'bold' }} style={{ marginBottom: '-5px' }}>
                {key === 'features_themes'
                  ? 'Features/Themes'
                  : _.startCase(key)}
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: '0px', marginTop: '0px' }}>
              <Box
                dangerouslySetInnerHTML={{ __html: value }}
                sx={{
                  paddingTop: '0px',
                  marginTop: '0px',
                  marginLeft: '0px',
                  paddingLeft: '0px'
                }}
              ></Box>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )
}
export default PublicWinStrategy
