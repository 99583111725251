import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/css'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

function CheckboxOption(props) {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps
  } = props

  const { onClick = () => {}, ...restInnerProps } = innerProps
  return (
    <div
      ref={innerRef}
      className={css(getStyles('option', props))}
      onClick={(e) => {
        onClick(e)
        e.stopPropagation()
      }}
      {...restInnerProps}
      id="multiCheckboxOption"
    >
      <Checkbox disableRipple checked={isSelected} />
      {children}
    </div>
  )
}
CheckboxOption.propTypes = {
  children: PropTypes.node
}
CheckboxOption.defaultProps = {
  children: null
}

export default CheckboxOption
