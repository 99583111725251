import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '10px 5px 5px',
    marginTop: '5px',
    '&:hover': {
      backgroundColor: '#edeaea'
    }
  },
  displayMessageContainer: {
    marginTop: '10px'
  }
}))

export { useStyles }
