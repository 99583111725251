import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  inputLabel: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '14px',
    color: '#000000',
    padding: 0
  },
  errorText: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '14px',
    color: 'red',
    padding: 0
  }
})
export { useStyles }
