import React, { useState } from 'react'
import Button from '../Button'
import copyIcon from '../../assets/images/copy-icon.svg'
import { useStyles } from './styles'
import Box from '@mui/material/Box'

const CopyButton = (props) => {
  const { content, id = 'copy-button', variant = 'text' } = props

  const [buttonText, setButtonText] = useState('copy')

  const classes = useStyles()

  const { buttonLabel } = classes

  const handleCopy = () => {
    if (buttonText === 'copy') {
      navigator.clipboard.writeText(content)
      setButtonText('copied')
      setTimeout(() => {
        setButtonText('copy')
      }, 500)
    }
  }

  return (
    <Button
      id={id}
      className="display-row icon-button-container"
      variant={variant}
      onClick={() => handleCopy()}
      {...props}
    >
      <img alt="copy-icon" src={copyIcon} className="copy-icon" />
      <Box className={buttonLabel}>{buttonText}</Box>
    </Button>
  )
}

export default CopyButton
