import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  escBtn: {
    fontSize: '10px',
    backgroundColor: 'var(--secondary-grey)',
    color: 'var(--primary-joist)',
    padding: '3px 7px',
    fontWeight: '300',
    borderRadius: '5px'
  },

  commandItem: {
    padding: '10px 30px',
    paddingRight: '10px',
    fontFamily: 'PoppinsMedium',
    fontSize: '14px',
    color: '#71717a',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',

    '&:hover': {
      backgroundColor: 'var(--secondary-grey)',
      color: '#18181b'
      // color: 'red'
    }
  },
  commandItemActive: {
    padding: '10px 30px',
    fontFamily: 'PoppinsMedium',
    fontSize: '14px',
    cursor: 'pointer',
    backgroundColor: 'var(--secondary-grey)',
    color: '#18181b'
  }
})

export { useStyles }
