import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  resultWrapper: {
    /* height: 'calc(100vh - 150px)', */
    flexGrow: 1
  },
  imageContainer: {
    display: 'block',
    maxWidth: '300px',
    width: 'auto',
    height: 'auto'
  },
  titleWapper: {
    fontFamily: 'PoppinsRegular',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    color: '#000000',
    marginBottom: '10px',
    wordBreak: 'break-word'
  },
  titleWapperPDF: {
    cursor: 'pointer'
  },
  textWrapper: {
    fontFamily: 'PoppinsRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    wordBreak: 'break-word'
  },
  fileNameWapper: {
    fontFamily: 'PoppinsRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    color: '#949494',
    cursor: 'pointer',
    wordBreak: 'break-word'
  },
  pdfTitleContainer: {
    margin: '15px 0px'
  },
  iconButton: {
    float: 'right'
  },
  loaderContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    height: 'calc(100vh - 150px)'
  },
  workspaceLoader: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px'
  },
  pdfTitle: {
    p: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    height: '16px',
    width: '16px'
  },
  copyContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  content: {
    fontFamily: 'PoppinsRegular',
    fontWeight: 400,
    fontSize: 14,
    '& img': {
      maxHeight: '500px'
    }
  },
  expandContentDetails: {
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#5377dd !important',
    maxWidth: '120px'
  },
  expandCollapseContainer: {
    width: '100%'
  },
  newLineCharaters: {
    whiteSpace: 'pre-line'
  },
  defaultHeight: {
    height: '120px',
    overflow: 'hidden'
  },
  heightContainer: {
    overflow: 'auto',
    height: '100%'
  },
  assetWrapper: {
    justifyContent: 'center',
    display: 'flex'
  },
  assetRootWrapper: {
    padding: '4px',
    width: '170px',
    height: '170px'
  },
  previewAssetContainer: {
    height: '30%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '10px',
    boxShadow: '0px 5px 5px rgb(249 249 249)',
    zIndex: 1,
    overflow: 'auto',
    position: 'relative'
  },
  previewAsset: {
    objectFit: 'contain !important',
    maxHeight: '90%',
    maxWidth: '90%'
  },
  previewNavigation: {
    display: 'flex',
    alignItems: 'center'
  },
  videoSpacing: {
    borderRadius: '10px'
  },
  assetPreviewRoot: { position: 'absolute', top: 10, right: 1 },
  previewWapper: {
    margin: 'auto',
    textAlign: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
export { useStyles }
