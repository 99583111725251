import { FETCH_PROMPTS } from '../../../actionTypes'
import { getExistingPrompts } from '../../../api'

const fetchPrompts =
  (callback = () => {}) =>
  async (dispatch) => {
    let prompts = []
    const res = await getExistingPrompts()
    if (res.status === 200) {
      prompts = res.data
      dispatch({
        type: FETCH_PROMPTS,
        payload: prompts,
        callback
      })
    }
  }
export default fetchPrompts
