import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  imageContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& img': {
      objectFit: 'contain'
    },
    '&:hover .overlayButtons': {
      opacity: '1 !important'
    },
    '&:hover .overlayButtons .download': {
      display: 'block !important',
      opacity: '1 !important'
    },

    '&:hover': {
      opacity: 0.8
    }
  },
  hoverVideo: (props) => ({
    width: props.width ? props.width : '430px',
    height: props.height ? props.height : '290px',
    '& video': {
      display: 'block !important',
      width: '100% !important',
      objectFit: 'fill !important',
      height: '100% !important'
    }
  }),
  imageWrapper: {
    '& img': {
      objectFit: 'contain',
      cursor: 'grab'
    }
  }
})

export { useStyles }
