import { cloneDeep } from 'lodash'
import { UPDATE_SESSION_CHAT_HISTORY } from '../../../actionTypes'
import { putChatSessionHistory } from '../../../api'
import { toast } from 'react-toastify'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../../../config/mixpanelEvents'

const updateChatSessionHistory = (newVal, oldVal) => async (dispatch) => {
  dispatch({
    type: UPDATE_SESSION_CHAT_HISTORY,
    payload: newVal
  })
  const res = await putChatSessionHistory(newVal)
  if (res.status === 200) {
    trackEvent(mixpanelEvents.CHAT_SESSION_RENAMED, 'SUCCESS', {}, {})
  } else {
    trackEvent(mixpanelEvents.CHAT_SESSION_RENAMED, 'FAILURE', {}, {})
    toast.error('Unable to update session. Please try again later.')
    dispatch({
      type: UPDATE_SESSION_CHAT_HISTORY,
      payload: oldVal
    })
  }
}

export default updateChatSessionHistory
