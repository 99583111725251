import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import {
  fetchUserInvites,
  updateUserInvite
} from '../../store/Settings/Actions'
import { useSelector, useDispatch } from 'react-redux'
import InviteForm from './invite'
import InviteList from './inviteList'
import InviteListV2 from './inviteListV2'
import Spinner from '../../components/SpinnerBackDrop'
import { isEmpty, isArray } from 'lodash'
import { useStyles } from './styles'
import { SectionFixed, Container, Section } from '../../components/Container'

const InviteSetting = ({ isFocused }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { box, tableContainer } = classes
  const invites = useSelector((state) => state?.settings?.invites)
  const [inviteList, setInviteList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (invites === null && isFocused) {
      dispatch(fetchUserInvites())
    }
  }, [isFocused])

  useEffect(() => {
    if (isArray(invites)) {
      setInviteList(invites)
      setLoading(false)
    }
  }, [invites])

  const updateInvite = async (data, updateType) => {
    const updateData = {
      id: data.id,
      status: data.status,
      role: data.role,
      updateType,
      email: data.email,
      name: data.name,
      join_type: data.join_type
    }
    dispatch(updateUserInvite(updateData))
  }

  return (
    <Container className={box}>
      {loading ? (
        <Spinner loading={loading} showSideBar />
      ) : (
        <Container>
          <div className={tableContainer}>
            <InviteListV2 inviteList={inviteList} updateInvite={updateInvite} />
          </div>
        </Container>
      )}
    </Container>
  )
}

export default InviteSetting
