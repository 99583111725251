import React from 'react'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { ViewList, Window } from '@mui/icons-material'

const ViewMode = (props) => {
  const { viewMode = '', setViewMode = () => {}, disabled = false } = props

  const setMode = (mode) => {
    setViewMode(mode)
  }

  return (
    <ToggleButtonGroup
      value={viewMode}
      disabled={disabled}
      exclusive
      onChange={(e, value) => setMode(value)}
      aria-label="text alignment"
      sx={{ height: '28px', marginLeft: '5px' }}
    >
      <Tooltip title="Card">
        <ToggleButton value="grid" aria-label="left aligned">
          <Window
            sx={{
              height: '18px',
              width: '18px'
            }}
          />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="List">
        <ToggleButton value="table" aria-label="left aligned">
          <ViewList
            sx={{
              height: '18px',
              width: '18px'
            }}
          />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  )
}

export default ViewMode
