import { FETCH_ANALYTIC, FETCH_CHART_DATA } from '../../../actionTypes'
import {
  getAnalytic,
  getChartData,
  getAnalyticWithoutUser,
  getChartDataWithoutUser
} from '../../../api'
import _ from 'lodash'
const fetchAnalytic =
  (analyticId = '', domainId = '', shareId = '') =>
  async (dispatch) => {
    const analytic = {}
    dispatch({
      type: FETCH_ANALYTIC,
      payload: analytic
    })
    let chartres
    if (domainId) {
      chartres = await getChartDataWithoutUser({ id: shareId })
    } else {
      chartres = await getChartData({ id: analyticId })
    }
    if (chartres.status === 200) {
      const { resultsets } = chartres.data
      dispatch({
        type: FETCH_CHART_DATA,
        payload: resultsets
      })
    }
    const page_count = 100
    const startPage = 1
    let endPage = startPage + page_count
    let totalPages = 0
    let currentPage = startPage
    let analyticData = {}
    do {
      let res
      if (domainId) {
        res = await getAnalyticWithoutUser(
          shareId,
          currentPage,
          endPage,
          domainId
        )
      } else {
        res = await getAnalytic(analyticId, currentPage, endPage)
      }
      if (res.status === 200) {
        const result = res.data
        const metaData = {
          ...analyticData?.metadata,
          ...transformMetaData(result?.metadata)
        }
        analyticData = result
        analyticData.metadata = metaData
        totalPages = parseInt(result.total_pages)
        currentPage += page_count + 1
        endPage = currentPage + page_count
        dispatch({
          type: FETCH_ANALYTIC,
          payload: analyticData
        })
      } else {
        currentPage = totalPages + 1
      }
    } while (currentPage <= totalPages)
  }

const transformMetaData = (obj) => {
  let transformedObj = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      Object.entries(value).map(([id, data]) => {
        const err = {
          id,
          ...data,
          errorType: key,
          pageNo: data?.pageIndex,
          pageIndexDup: Array.isArray(data?.pageIndex)
            ? _.sortBy(data?.pageIndex.map((item) => _.toInteger(item)))
            : _.toInteger(data?.pageIndex)
        }
        if (
          _.isObject(err?.keyword) &&
          'original' in err.keyword &&
          'correction' in err.keyword
        ) {
          const { original, correction } = { ...err.keyword }
          err.keyword = original
          err.keywordCorrection = correction
        }
        if (!err.sentence) {
          err.sentence = err?.keyword ? err.keyword : ''
        }
        if (!err?.keyword) {
          err.keyword = err?.sentence ? err.sentence : ''
        }
        if (err?.errorType === 'grammar') {
          if (err?.keyword) {
            if (err?.keyword.replace(/^\s+/g, '').length > 3) {
              return err
            }
          }
        } else {
          return err
        }
        return err
      })
    ])
  )
  delete transformedObj.entities
  let transformedEntitiesObj = {}
  if (obj?.entities) {
    transformedEntitiesObj = Object.fromEntries(
      Object.entries(obj.entities).map(([key, value]) => [
        key,
        Object.entries(value).map(([id, data]) => {
          const err = {
            id,
            ...data,
            errorType: key,
            pageNo: data?.pageIndex,
            pageIndexDup: Array.isArray(data?.pageIndex)
              ? data?.pageIndex.map((item) => _.toInteger(item))
              : _.toInteger(data?.pageIndex)
          }
          return err
        })
      ])
    )
  }
  transformedObj = { ...transformedObj, ...transformedEntitiesObj }

  try {
    function sortByProperty(property) {
      return function (a, b) {
        return a[property] - b[property]
      }
    }

    function sortArraysInsideObject(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
          obj[key].sort(sortByProperty('pageNo'))
        }
      }
    }

    sortArraysInsideObject(transformedObj)
  } catch (e) {
    console.log(e)
  }

  return transformedObj
}

export default fetchAnalytic
