import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined'
import { useStyles } from './styles'
import Box from '@mui/material/Box'
import { toast } from 'react-toastify'
import { CloudUpload, FolderIcon } from '../Icons/Icons'
import Button from '../Button'
import { pad } from 'lodash'

const Dropzone = (props) => {
  const {
    onDrop,
    placeHolderText1 = 'Drop the files here',
    placeHolderText2 = 'Browse files from your computer',
    acceptedFileType = '.pdf',
    placeHolderText3 = '',
    placeHolderText4 = '',
    otherProps = {}
  } = props
  const classes = useStyles()
  const { outerContainer, uploadCloudIcon, link } = classes
  const onDropFunction = useCallback((acceptedFiles) => {
    onDrop(acceptedFiles)
  }, [])

  const onDropRejected = useCallback((fileRejections) => {
    const { errors } = fileRejections[0]
    if (errors[0].code === 'file-invalid-type') {
      toast.error(`Invalid file type. Supports only ${fileType} files.`)
    } else if (errors[0].code === 'file-too-large') {
      toast.error('File size exceeds the limit.')
    } else {
      toast.error(errors?.[0]?.message || 'Something went wrong.')
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onDropFunction,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    useFsAccessApi: false,
    accept: {
      application: [acceptedFileType]
    },
    ...otherProps
  })

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex'
      }}
    >
      <Box className={outerContainer} {...getRootProps({})}>
        <input {...getInputProps()} />

        {isDragActive ? (
          <p>Drop here</p>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px'
              }}
            >
              <div>
                <CloudUpload
                  style={{
                    height: '50px',
                    width: '50px'
                  }}
                />
              </div>
              <div
                style={{
                  fontSize: '14px'
                }}
              >
                <div>{placeHolderText1}</div>
              </div>
            </div>
            <div
              style={{
                color: 'var(--grey-500)',
                fontWeight: 'lighter'
              }}
            >
              or
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                marginTop: '15px'
              }}
            >
              <div>{placeHolderText3}</div>
              <Button
                onClick={() => open()}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '7px',
                  backgroundColor: 'var(--dark-text)',
                  color: 'white',
                  padding: '8px 30px'
                }}
              >
                <FolderIcon
                  style={{
                    height: '20px',
                    width: '20px'
                  }}
                />
                Browse Files
              </Button>
            </div>
            {placeHolderText4 && (
              <div
                style={{
                  marginTop: '15px',
                  fontSize: '14px',
                  color: 'var(--grey-500)'
                }}
              >
                {placeHolderText4}
              </div>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default Dropzone
