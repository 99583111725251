import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
    border: '1px solid white'
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block'
  },
  popover: {
    position: 'absolute',
    zIndex: '2'
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  },
  pdfTitle: {
    p: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    height: '16px',
    width: '16px'
  },
  pdfContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  page: {
    height: '100%'
  },
  titleContainer: {
    padding: '15px 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  borderBot: {
    borderBottom: '1px solid #E5E5E5'
  },
  closeIconWrapper: {
    borderLeft: '1px solid #E5E5E5',
    display: 'flex',
    justifyContent: 'center'
  }
})

export { useStyles }
