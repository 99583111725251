import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  defaultSliderInput: {
    width: '100%',
    '&::before': {
      content: 'none !important',
      border: 'none !important'
    },
    '&::after': {
      content: 'none !important',
      border: 'none !important'
    },
    '& input': {
      padding: '8.5px 14px',
      border: '1px solid #e0e0e0',
      borderRadius: '4px'
    },
    root: {
      content: 'none !important',
      border: 'none !important'
    },
    '& input:focus': {
      padding: '8.5px 14px',
      /* border: "2px solid #000000", */
      borderRadius: '4px'
    }
  }
})

export { useStyles }
