import * as React from 'react'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useStyles } from './styles'

export function ResponsiveDialog(props) {
  const {
    open,
    handleSubmit,
    handleClose,
    dialogTitle = 'Are you sure you want to delete',
    dialogContent = '',
    cancelButtonText = 'Cancel',
    submitButtonText = 'Yes, Delete it',
    handleClickOutside = () => {}
  } = props
  const classes = useStyles()
  const {
    actionContianer,
    cancelButton,
    submitButton,
    dialogHeader,
    dialogContents,
    contentWrapper
  } = classes

  const defaultActions = () => {
    return (
      <div className={actionContianer}>
        <Button
          id="cancel-action"
          onClick={(e) => {
            e.stopPropagation()
            handleClose()
          }}
          className={cancelButton}
        >
          {cancelButtonText}
        </Button>
        <Button
          id="submit-action"
          onClick={(e) => {
            e.stopPropagation()
            handleSubmit()
          }}
          className={submitButton}
        >
          {submitButtonText}
        </Button>
      </div>
    )
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        onBlur={handleClickOutside}
        aria-labelledby="responsive-dialog-title"
      >
        <Grid className={contentWrapper}>
          <DialogTitle id="responsive-dialog-title" className={dialogHeader}>
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={dialogContents}>
              {dialogContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>{defaultActions()}</DialogActions>
        </Grid>
      </Dialog>
    </div>
  )
}
