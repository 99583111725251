import {
  capitalize,
  toLower,
  toUpper,
  startCase,
  camelCase,
  snakeCase,
  uniq
} from 'lodash'

const Highlighted = ({
  text = '',
  narrativeClass = '',
  searchKeyword = '',
  searchType = '',
  keepFormatting = true
}) => {
  searchKeyword = searchKeyword
    ?.replaceAll(/[^a-zA-Z0-9]\s/g, ' ')
    ?.replace(/\s\s+/g, ' ')
  let highlight = searchKeyword?.trim()

  if (!keepFormatting) {
    text = text?.replace(/<[^>]+>/g, '')
  }

  const formatOptions = [capitalize, toLower, toUpper]
  const escapeKeywords = ['table', 'tr', 'td', 'mark']

  if (!highlight?.trim()) {
    return <span dangerouslySetInnerHTML={{ __html: text }}></span>
  }

  function escapeRegExp(string) {
    return string?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  highlight = highlight.split(' ')
  highlight = uniq(highlight)
  let highlightedText = text
  highlight.forEach((element) => {
    formatOptions.forEach((fun) => {
      element = fun(element)
      const regex = new RegExp('\\b' + escapeRegExp(element) + '\\b', 'g')
      highlightedText = highlightedText?.replaceAll(
        regex,
        `<mark>${element}</mark>`
      )
    })
  })

  escapeKeywords.forEach((element) => {
    highlightedText = highlightedText?.replaceAll(
      `<<mark>${element}</mark> `,
      `<${element}`
    )
  })

  if (searchType === 'narrative') {
    highlightedText =
      '<p className={narrativeClass}>' + highlightedText + '</p>'
  }

  return <span dangerouslySetInnerHTML={{ __html: highlightedText }}></span>
}

export default Highlighted
