import { useEffect, useState } from 'react'
import CustomDialog from '../../components/Dialog'
import LibraryEditor from '../../containers/LibraryEditor'

const LibraryViewer = (props) => {
  const {
    id,
    onClose = () => {},
    onDeleteCallback = () => {},
    onUpdateCallback = () => {},
    setFile = () => {},
    isSuperUser = false,
    isAdmin = false
  } = props

  const [editStateLibrary, setEditStateLibrary] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [documentController, setDocumentController] = useState(null)

  const handleClose = () => {
    if (editStateLibrary) {
      setOpenDialog(true)
    } else {
      onClose()
    }
  }

  const handleLibraryEdit = (isEdited) => {
    setEditStateLibrary(isEdited)
  }

  const customDialogSubmit = () => {
    setOpenDialog(false)
    onClose()
  }

  const customDialogClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <LibraryEditor
        onClose={handleClose}
        libraryId={id}
        handleLibraryEdit={handleLibraryEdit}
        setDocumentController={setDocumentController}
        documentController={documentController}
        onDeleteCallback={onDeleteCallback}
        onUpdateCallback={onUpdateCallback}
      />
      <CustomDialog
        open={openDialog}
        handleClose={() => {
          customDialogClose()
        }}
        handleSubmit={() => {
          customDialogSubmit()
        }}
        dialogContent="Are you sure to discard?"
        dialogTitle="Your changes will not be saved."
        submitButtonText="Discard"
        cancelButton="Cancel"
      />
    </>
  )
}

export default LibraryViewer
