import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  menuItems: {
    borderBottom: '1px solid #D9D9D9',
    fontSize: '14px'
  },
  selectedMenu: {
    background: '#00000014 !important',
    fontSize: '14px',
    '&:hover': {
      background: '#0000000a !important'
    }
  },
  noMenuSelection: {
    background: '#fff !important',
    fontSize: '14px',
    '&:hover': {
      background: '#0000000a  !important'
    }
  },
  menuIcon: {
    marginRight: '10px'
  },
  menuCategory: {
    borderBottom: '1px solid #D9D9D9',
    color: '#000000',
    fontWeight: 'bold',
    background: '#fff !important',
    '&:hover': {
      background: '#fff'
    }
  }
})

export { useStyles }
