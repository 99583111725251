import * as React from 'react'
import { getbg } from '../errorCode'

export const getCssProperties = (area) => {
  const { errorType, status } = area
  const bgColor = getbg(errorType, status)

  return {
    left: `${area.left}%`,
    top: `${area.top}%`,
    height: `${area.height}%`,
    width: `${area.width}%`,
    cursor: 'pointer',
    background: bgColor,
    opacity: '0.5'
  }
}
