import { UPDATE_PROJECT_TAG } from '../../../actionTypes'

const updateTag =
  (data, callback = () => {}) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_PROJECT_TAG,
      payload: data,
      callback
    })
  }

export default updateTag
