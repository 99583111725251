import { FETCH_ALL_DOMAIN_LIST } from '../../../actionTypes'
import { getAllDomainList } from '../../../api'
import { toast } from 'react-toastify'

const fetchAllDomainList = () => async (dispatch) => {
  const res = await getAllDomainList()
  if (res.status === 200) {
    const domainList = JSON.parse(res.data)
    dispatch({
      type: FETCH_ALL_DOMAIN_LIST,
      payload: domainList
    })
  }
}

export { fetchAllDomainList }
