/* eslint-disable */
'use strict'

const core = require('@react-pdf-viewer/core')
const React = require('react')

function _interopNamespaceDefault(e) {
  const n = Object.create(null)
  if (e) {
    Object.keys(e).forEach(function (k) {
      if (k !== 'default') {
        const d = Object.getOwnPropertyDescriptor(e, k)
        Object.defineProperty(
          n,
          k,
          d.get
            ? d
            : {
                enumerable: true,
                get: function () {
                  return e[k]
                }
              }
        )
      }
    })
  }
  n.default = e
  return Object.freeze(n)
}

const React__namespace = /* #__PURE__ */ _interopNamespaceDefault(React)

const getImageFromArea = function () {
  const newCanvas = document.createElement('canvas')
  const dpr = window.devicePixelRatio || 1
  return function (canvasEle, highlightArea) {
    const canvasRect = canvasEle.getBoundingClientRect()
    const left = (highlightArea.left * canvasRect.width) / 100
    const top = (highlightArea.top * canvasRect.height) / 100
    const width = (highlightArea.width * canvasRect.width) / 100
    const height = (highlightArea.height * canvasRect.height) / 100
    const context = newCanvas.getContext('2d')
    newCanvas.width = width
    newCanvas.height = height
    context === null || context === void 0
      ? void 0
      : context.drawImage(
          canvasEle,
          left * dpr,
          top * dpr,
          width * dpr,
          height * dpr,
          0,
          0,
          width,
          height
        )
    return newCanvas.toDataURL('image/png')
  }
}

let HighlightStateType
;(function (HighlightStateType) {
  HighlightStateType.NoSelection = 'NoSelection'
  HighlightStateType.Selecting = 'Selecting'
  HighlightStateType.Selected = 'Selected'
  HighlightStateType.Selection = 'Selection'
  HighlightStateType.ClickDragging = 'ClickDragging'
  HighlightStateType.ClickDragged = 'ClickDragged'
})(HighlightStateType || (HighlightStateType = {}))
const EMPTY_SELECTION_REGION = {
  height: 0,
  left: 0,
  pageIndex: -1,
  top: 0,
  width: 0
}
const NO_SELECTION_STATE = {
  highlightAreas: [],
  selectionRegion: EMPTY_SELECTION_REGION,
  type: HighlightStateType.NoSelection
}
const SELECTING_STATE = {
  highlightAreas: [],
  selectionRegion: EMPTY_SELECTION_REGION,
  type: HighlightStateType.Selecting
}

const ClickDrag = function (_a) {
  const canvasLayerRef = _a.canvasLayerRef
  const canvasLayerRendered = _a.canvasLayerRendered
  const pageIndex = _a.pageIndex
  const store = _a.store
  const textLayerRef = _a.textLayerRef
  const textLayerRendered = _a.textLayerRendered
  const containerRef = React__namespace.useRef()
  const currentCursorRef = React__namespace.useRef(document.body.style.cursor)
  const startPointRef = React__namespace.useRef({ x: 0, y: 0 })
  const offsetRef = React__namespace.useRef({ top: 0, left: 0 })
  const hideContainer = function () {
    const container = containerRef.current
    if (container) {
      container.classList.add('rpv-highlight__click-drag--hidden')
    }
  }
  const handleMouseDown = function (e) {
    const textLayerEle = textLayerRef.current
    const container = containerRef.current
    if (!e.altKey || !textLayerEle || !container || e.button !== 0) {
      return
    }
    e.preventDefault()
    document.body.style.cursor = 'crosshair'
    const rect = textLayerEle.getBoundingClientRect()
    const startPoint = {
      x: e.clientX,
      y: e.clientY
    }
    startPointRef.current = startPoint
    const offset = {
      top: ((startPoint.y - rect.top) * 100) / rect.height,
      left: ((startPoint.x - rect.left) * 100) / rect.width
    }
    offsetRef.current = offset
    container.style.top = ''.concat(offset.top, '%')
    container.style.left = ''.concat(offset.left, '%')
    container.style.height = '0px'
    container.style.width = '0px'
    document.addEventListener('mousemove', handleDocumentMouseMove)
    document.addEventListener('mouseup', handleDocumentMouseUp)
    store.updateCurrentValue('highlightState', function (currentState) {
      return Object.assign({}, currentState, {
        type: HighlightStateType.ClickDragging
      })
    })
  }
  var handleDocumentMouseMove = function (e) {
    const textLayerEle = textLayerRef.current
    const container = containerRef.current
    if (!textLayerEle || !container) {
      return
    }
    e.preventDefault()
    const endPoint = {
      x: e.clientX - startPointRef.current.x,
      y: e.clientY - startPointRef.current.y
    }
    const rect = textLayerEle.getBoundingClientRect()
    if (container.classList.contains('rpv-highlight__click-drag--hidden')) {
      container.classList.remove('rpv-highlight__click-drag--hidden')
    }
    const width = Math.min(
      100 - offsetRef.current.left,
      (endPoint.x * 100) / rect.width
    )
    const height = Math.min(
      100 - offsetRef.current.top,
      (endPoint.y * 100) / rect.height
    )
    container.style.width = ''.concat(width, '%')
    container.style.height = ''.concat(height, '%')
  }
  const handleDocumentKeyDown = function (e) {
    if (
      e.key === 'Escape' &&
      store.get('highlightState').type === HighlightStateType.ClickDragged
    ) {
      e.preventDefault()
      hideContainer()
      store.update('highlightState', NO_SELECTION_STATE)
    }
  }
  const handleDocumenClick = function (e) {
    const container = containerRef.current
    if (!container) {
      return
    }
    const highlightType = store.get('highlightState').type
    if (
      highlightType === HighlightStateType.NoSelection &&
      e.target !== container
    ) {
      hideContainer()
    }
  }
  var handleDocumentMouseUp = function (e) {
    e.preventDefault()
    document.removeEventListener('mousemove', handleDocumentMouseMove)
    document.removeEventListener('mouseup', handleDocumentMouseUp)
    resetCursor()
    const container = containerRef.current
    const canvasEle = canvasLayerRef.current
    if (!container || !canvasEle) {
      return
    }
    const highlightArea = {
      pageIndex,
      top: parseFloat(container.style.top.slice(0, -1)),
      left: parseFloat(container.style.left.slice(0, -1)),
      height: parseFloat(container.style.height.slice(0, -1)),
      width: parseFloat(container.style.width.slice(0, -1))
    }
    const previewImage = getImageFromArea()(canvasEle, highlightArea)
    const newState = {
      highlightAreas: [highlightArea],
      previewImage,
      selectionRegion: highlightArea,
      type: HighlightStateType.ClickDragged
    }
    store.update('highlightState', newState)
  }
  var resetCursor = function () {
    currentCursorRef.current
      ? (document.body.style.cursor = currentCursorRef.current)
      : document.body.style.removeProperty('cursor')
  }
  const handleHighlightState = function (s) {
    if (
      s.type === HighlightStateType.Selection ||
      (s.type === HighlightStateType.ClickDragging &&
        s.selectionRegion.pageIndex !== pageIndex)
    ) {
      hideContainer()
    }
  }
  React__namespace.useEffect(function () {
    store.subscribe('highlightState', handleHighlightState)
    return function () {
      store.unsubscribe('highlightState', handleHighlightState)
    }
  }, [])
  React__namespace.useEffect(
    function () {
      const canvasEle = canvasLayerRef.current
      const textLayerEle = textLayerRef.current
      if (
        !canvasLayerRendered ||
        !textLayerRendered ||
        !canvasEle ||
        !textLayerEle
      ) {
        return
      }
      textLayerEle.addEventListener('mousedown', handleMouseDown)
      const eventOptions = {
        capture: true
      }
      document.addEventListener('keydown', handleDocumentKeyDown)
      document.addEventListener('click', handleDocumenClick, eventOptions)
      return function () {
        textLayerEle.removeEventListener('mousedown', handleMouseDown)
        document.removeEventListener('click', handleDocumenClick, eventOptions)
        document.removeEventListener('keydown', handleDocumentKeyDown)
      }
    },
    [textLayerRendered]
  )
  return React__namespace.createElement('div', {
    ref: containerRef,
    className: 'rpv-highlight__click-drag rpv-highlight__click-drag--hidden'
  })
}

const HIGHLIGHT_LAYER_ATTR = 'data-highlight-text-layer'
const HIGHLIGHT_PAGE_ATTR = 'data-highlight-text-page'

const normalizeRotation = function (rotation) {
  return rotation >= 0 ? rotation : 360 + rotation
}
const getCssProperties = function (area, rotation) {
  const r = normalizeRotation(rotation)
  switch (r) {
    case 90:
      return {
        height: ''.concat(area.width, '%'),
        position: 'absolute',
        right: ''.concat(area.top, '%'),
        top: ''.concat(area.left, '%'),
        width: ''.concat(area.height, '%')
      }
    case 180:
      return {
        bottom: ''.concat(area.top, '%'),
        height: ''.concat(area.height, '%'),
        position: 'absolute',
        right: ''.concat(area.left, '%'),
        width: ''.concat(area.width, '%')
      }
    case 270:
      return {
        height: ''.concat(area.width, '%'),
        position: 'absolute',
        left: ''.concat(area.top, '%'),
        bottom: ''.concat(area.left, '%'),
        width: ''.concat(area.height, '%')
      }
    case 0:
    case 360:
    default:
      return {
        height: ''.concat(area.height, '%'),
        position: 'absolute',
        top: ''.concat(area.top, '%'),
        left: ''.concat(area.left, '%'),
        width: ''.concat(area.width, '%')
      }
  }
}
const transformArea = function (area, rotation) {
  const r = normalizeRotation(rotation)
  switch (r) {
    case 90:
      return {
        height: area.width,
        left: area.top,
        pageIndex: area.pageIndex,
        top: 100 - area.width - area.left,
        width: area.height
      }
    case 180:
      return {
        height: area.height,
        left: 100 - area.width - area.left,
        pageIndex: area.pageIndex,
        top: 100 - area.height - area.top,
        width: area.width
      }
    case 270:
      return {
        height: area.width,
        left: 100 - area.height - area.top,
        pageIndex: area.pageIndex,
        top: area.left,
        width: area.height
      }
    case 0:
    case 360:
    default:
      return area
  }
}

const HighlightRect = function (_a) {
  const area = _a.area
  const rotation = _a.rotation
  return React__namespace.createElement('div', {
    className: 'rpv-highlight__selected-text',
    style: getCssProperties(area, rotation)
  })
}

const useRotation = function (store) {
  const _a = React__namespace.useState(store.get('rotation') || 0)
  const rotation = _a[0]
  const setRotation = _a[1]
  const handleRotationChanged = function (currentRotation) {
    return setRotation(currentRotation)
  }
  React__namespace.useEffect(function () {
    store.subscribe('rotation', handleRotationChanged)
    return function () {
      store.unsubscribe('rotation', handleRotationChanged)
    }
  }, [])
  return { rotation }
}

const HighlightAreaList = function (_a) {
  const pageIndex = _a.pageIndex
  const renderHighlightContent = _a.renderHighlightContent
  const renderHighlightTarget = _a.renderHighlightTarget
  const renderHighlights = _a.renderHighlights
  const store = _a.store
  const _b = React__namespace.useState(store.get('highlightState'))
  const highlightState = _b[0]
  const setHighlightState = _b[1]
  const rotation = useRotation(store).rotation
  const handleHighlightState = function (s) {
    return setHighlightState(s)
  }
  const cancel = function () {
    window.getSelection().removeAllRanges()
    store.update('highlightState', NO_SELECTION_STATE)
  }
  React__namespace.useEffect(function () {
    store.subscribe('highlightState', handleHighlightState)
    return function () {
      store.unsubscribe('highlightState', handleHighlightState)
    }
  }, [])
  const listAreas =
    highlightState.type === HighlightStateType.Selection
      ? highlightState.highlightAreas.filter(function (s) {
          return s.pageIndex === pageIndex
        })
      : []
  return React__namespace.createElement(
    React__namespace.Fragment,
    null,
    renderHighlightTarget &&
      (highlightState.type === HighlightStateType.Selected ||
        highlightState.type === HighlightStateType.ClickDragged) &&
      highlightState.selectionRegion.pageIndex === pageIndex &&
      renderHighlightTarget({
        highlightAreas: highlightState.highlightAreas,
        previewImage: highlightState.previewImage || '',
        selectedText: highlightState.selectedText || '',
        selectionRegion: highlightState.selectionRegion,
        selectionData: highlightState.selectionData,
        cancel,
        toggle: function () {
          const newState = Object.assign({}, highlightState, {
            type: HighlightStateType.Selection
          })
          store.update('highlightState', newState)
          window.getSelection().removeAllRanges()
        }
      }),
    renderHighlightContent &&
      highlightState.type == HighlightStateType.Selection &&
      highlightState.selectionRegion.pageIndex === pageIndex &&
      renderHighlightContent({
        highlightAreas: highlightState.highlightAreas,
        previewImage: highlightState.previewImage || '',
        selectedText: highlightState.selectedText || '',
        selectionRegion: highlightState.selectionRegion,
        selectionData: highlightState.selectionData,
        cancel
      }),
    listAreas.length > 0 &&
      React__namespace.createElement(
        'div',
        null,
        listAreas.map(function (area, idx) {
          return React__namespace.createElement(HighlightRect, {
            key: idx,
            area,
            rotation
          })
        })
      ),
    renderHighlights &&
      renderHighlights({
        pageIndex,
        rotation,
        getCssProperties
      })
  )
}

const getLengthOfNode = function (node) {
  if (!node) {
    return 0
  }
  if (node.nodeType === 3) {
    return node.nodeValue.length
  } else if (node.nodeType === 1) {
    return node.textContent.length
  }
  return 0
}

exports.Trigger = void 0
;(function (Trigger) {
  Trigger.None = 'None'
  Trigger.TextSelection = 'TextSelection'
})(exports.Trigger || (exports.Trigger = {}))

const getRectFromOffsets = function (textDiv, startOffset, endOffset) {
  const clonedEle = textDiv.cloneNode(true)
  textDiv.parentNode.appendChild(clonedEle)
  const firstChild = clonedEle.firstChild
  const range = new Range()
  range.setStart(firstChild, startOffset)
  range.setEnd(firstChild, endOffset)
  const wrapper = document.createElement('span')
  range.surroundContents(wrapper)
  const rect = wrapper.getBoundingClientRect()
  clonedEle.parentNode.removeChild(clonedEle)
  return rect
}

const getTextFromOffsets = function (
  nodes,
  pageIndex,
  startDivIdx,
  startOffset,
  endDivIdx,
  endOffset
) {
  if (startDivIdx < endDivIdx) {
    const startDivText = nodes
      .slice(startDivIdx, startDivIdx + 1)
      .map(function (node) {
        return node.textContent.substring(startOffset).trim()
      })
      .join(' ')
    const middleDivText = nodes
      .slice(startDivIdx + 1, endDivIdx)
      .map(function (node) {
        return node.textContent.trim()
      })
      .join(' ')
    const endDivText = nodes
      .slice(endDivIdx, endDivIdx + 1)
      .map(function (endDiv) {
        return endDiv.textContent.substring(
          0,
          endOffset || endDiv.textContent.length
        )
      })
      .join(' ')
    var wholeText = ''
      .concat(startDivText, ' ')
      .concat(middleDivText, ' ')
      .concat(endDivText)
    var divTexts = nodes
      .slice(startDivIdx, endDivIdx + 1)
      .map(function (node, idx) {
        return {
          divIndex: startDivIdx + idx,
          pageIndex,
          textContent: node.textContent
        }
      })
    return {
      divTexts,
      wholeText
    }
  } else {
    const div = nodes[startDivIdx]
    var wholeText = div.textContent
      .substring(startOffset, endOffset || div.textContent.length)
      .trim()
    var divTexts = [
      {
        divIndex: startDivIdx,
        pageIndex,
        textContent: div.textContent
      }
    ]
    return {
      divTexts,
      wholeText
    }
  }
}

let SelectionRange
;(function (SelectionRange) {
  SelectionRange.SameDiv = 'SameDiv'
  SelectionRange.DifferentDivs = 'DifferentDivs'
  SelectionRange.DifferentPages = 'DifferentPages'
})(SelectionRange || (SelectionRange = {}))

function findNestedTextNode(node, forward) {
  while (node) {
    if (node.nodeType === Node.TEXT_NODE) {
      return node
    } else {
      const child = forward ? node.firstChild : node.lastChild
      const foundNode = findNestedTextNode(child, forward)
      if (foundNode) {
        return foundNode
      } else {
        node = forward ? node.nextSibling : node.previousSibling
      }
    }
  }
  return null
}

function getFirstAndLastTextNodesWithinRange(range) {
  // Creating a TreeWalker to navigate through text nodes within the range.
  const treeWalker = document.createTreeWalker(
    range.commonAncestorContainer,
    NodeFilter.SHOW_TEXT,
    {
      acceptNode: function (node) {
        // Using a dummy range to check if the node is within the selection range.
        const nodeRange = document.createRange()
        nodeRange.selectNodeContents(node)
        return range.intersectsNode(node)
          ? NodeFilter.FILTER_ACCEPT
          : NodeFilter.FILTER_REJECT
      }
    }
  )

  let firstTextNode = null
  let lastTextNode = null

  while (treeWalker.nextNode()) {
    if (!firstTextNode) {
      firstTextNode = treeWalker.currentNode
    }
    lastTextNode = treeWalker.currentNode
  }

  return {
    firstTextNode,
    lastTextNode
  }
}

function compareNodePositions(node1, node2) {
  const position = node1.compareDocumentPosition(node2)
  if (position & Node.DOCUMENT_POSITION_FOLLOWING) {
    return {
      topNode: node1,
      bottomNode: node2
    }
  } else if (position & Node.DOCUMENT_POSITION_PRECEDING) {
    return {
      topNode: node2,
      bottomNode: node1
    }
  }
  return {
    topNode: node1,
    bottomNode: node2
  }
}

function haveSameParent(node1, node2) {
  return node1?.parentNode === node2?.parentNode
}

const EMPTY_SELECTION = ['', '\n']
const Tracker = function (_a) {
  const store = _a.store
  const rotation = useRotation(store).rotation
  const pagesRef = React__namespace.useRef(null)
  const _b = React__namespace.useState(false)
  const arePagesFound = _b[0]
  const setPagesFound = _b[1]
  const _c = React__namespace.useState(store.get('trigger'))
  const trigger = _c[0]
  const setTrigger = _c[1]
  const handlePagesContainer = function (getPagesContainer) {
    const ele = getPagesContainer()
    pagesRef.current = ele
    setPagesFound(!!ele)
  }
  const handleTrigger = function (trigger) {
    return setTrigger(trigger)
  }
  const onMouseUpHandler = function () {
    const selection = document.getSelection()
    const highlightState = store.get('highlightState')
    const hasSelection =
      (highlightState.type === HighlightStateType.NoSelection ||
        highlightState.type === HighlightStateType.Selected) &&
      selection.rangeCount > 0 &&
      EMPTY_SELECTION.indexOf(selection.toString()) === -1

    if (!hasSelection) {
      return
    }
    const range = selection.getRangeAt(0)
    var startContainer = range.startContainer
    var endContainer = range.endContainer
    const isSameParent = haveSameParent(startContainer, endContainer)
    if (!isSameParent) {
      const nodes = getFirstAndLastTextNodesWithinRange(range)
      var startContainer = nodes.firstTextNode
      var endContainer = nodes.lastTextNode
      const isSameParentInner = haveSameParent(startContainer, endContainer)
      if (!isSameParentInner) {
        const { topNode, bottomNode } = compareNodePositions(
          startContainer,
          endContainer
        )
        startContainer = topNode
        endContainer = bottomNode
      }
    }

    const parentEndContainer = endContainer.parentNode
    const startDiv = startContainer.parentNode
    const shouldIgnoreEndContainer =
      parentEndContainer instanceof HTMLElement &&
      parentEndContainer.hasAttribute(HIGHLIGHT_LAYER_ATTR)
    let endDiv, endOffset
    if (startDiv && startDiv.parentNode == range.endContainer) {
      endDiv = startDiv
      endOffset = endDiv.textContent.length
    } else if (shouldIgnoreEndContainer && range.endOffset == 0) {
      endDiv = range.endContainer.previousSibling
      endOffset = endDiv.textContent.length
    } else if (shouldIgnoreEndContainer) {
      endDiv = range.endContainer
      endOffset = range.endOffset
    } else {
      endDiv = parentEndContainer
      endOffset = range.endOffset
    }
    if (
      !(startDiv instanceof HTMLElement) ||
      !(endDiv instanceof HTMLElement)
    ) {
      return
    }

    const startPageIndex = parseInt(
      startDiv.getAttribute(HIGHLIGHT_PAGE_ATTR),
      10
    )
    const endPageIndex = parseInt(endDiv.getAttribute(HIGHLIGHT_PAGE_ATTR), 10)
    const startTextLayer = startDiv.parentElement
    const endTextLayer = endDiv.parentElement
    const startPageRect = startTextLayer.getBoundingClientRect()
    const startDivSiblings = [].slice.call(
      startTextLayer.querySelectorAll('['.concat(HIGHLIGHT_PAGE_ATTR, ']'))
    )
    const startDivIndex = startDivSiblings.indexOf(startDiv)
    const endPageRect = endTextLayer.getBoundingClientRect()
    const endDivSiblings = [].slice.call(
      endTextLayer.querySelectorAll('['.concat(HIGHLIGHT_PAGE_ATTR, ']'))
    )
    const endDivIndex = endDivSiblings.indexOf(endDiv)
    const startOffset = range.startOffset
    let rangeType = SelectionRange.DifferentPages

    switch (true) {
      case startPageIndex === endPageIndex && startDivIndex === endDivIndex:
        rangeType = SelectionRange.SameDiv
        break
      case startPageIndex === endPageIndex && startDivIndex < endDivIndex:
        rangeType = SelectionRange.DifferentDivs
        break
      default:
        rangeType = SelectionRange.DifferentPages
        break
    }
    const getRectBetween = function (min, max, eleArray) {
      return Array(max - min + 1)
        .fill(0)
        .map(function (_, i) {
          return eleArray[min + i].getBoundingClientRect()
        })
    }
    let highlightAreas = []
    switch (rangeType) {
      case SelectionRange.SameDiv:
        var rect = getRectFromOffsets(startDiv, startOffset, endOffset)
        highlightAreas = [
          {
            height: (rect.height * 100) / startPageRect.height,
            left:
              ((rect.left - startPageRect.left) * 100) / startPageRect.width,
            pageIndex: startPageIndex,
            top: ((rect.top - startPageRect.top) * 100) / startPageRect.height,
            width: (rect.width * 100) / startPageRect.width
          }
        ]
        break
      case SelectionRange.DifferentDivs:
        highlightAreas = [
          getRectFromOffsets(startDiv, startOffset, startDiv.textContent.length)
        ]
          .concat(
            getRectBetween(startDivIndex + 1, endDivIndex - 1, startDivSiblings)
          )
          .concat([getRectFromOffsets(endDiv, 0, endOffset)])
          .map(function (rect) {
            return {
              height: (rect.height * 100) / startPageRect.height,
              left:
                ((rect.left - startPageRect.left) * 100) / startPageRect.width,
              pageIndex: startPageIndex,
              top:
                ((rect.top - startPageRect.top) * 100) / startPageRect.height,
              width: (rect.width * 100) / startPageRect.width
            }
          })
        break
      case SelectionRange.DifferentPages:
        var startAreas = [
          getRectFromOffsets(startDiv, startOffset, startDiv.textContent.length)
        ]
          .concat(
            getRectBetween(
              startDivIndex + 1,
              startDivSiblings.length - 1,
              startDivSiblings
            )
          )
          .map(function (rect) {
            return {
              height: (rect.height * 100) / startPageRect.height,
              left:
                ((rect.left - startPageRect.left) * 100) / startPageRect.width,
              pageIndex: startPageIndex,
              top:
                ((rect.top - startPageRect.top) * 100) / startPageRect.height,
              width: (rect.width * 100) / startPageRect.width
            }
          })
        var endAreas = getRectBetween(0, endDivIndex - 1, endDivSiblings)
          .concat([getRectFromOffsets(endDiv, 0, endOffset)])
          .map(function (rect) {
            return {
              height: (rect.height * 100) / endPageRect.height,
              left: ((rect.left - endPageRect.left) * 100) / endPageRect.width,
              pageIndex: endPageIndex,
              top: ((rect.top - endPageRect.top) * 100) / endPageRect.height,
              width: (rect.width * 100) / endPageRect.width
            }
          })
        highlightAreas = startAreas.concat(endAreas)
        break
    }
    let selectedText = ''
    let divTexts = []
    switch (rangeType) {
      case SelectionRange.SameDiv:
        var textDataSameDiv = getTextFromOffsets(
          startDivSiblings,
          startPageIndex,
          startDivIndex,
          startOffset,
          startDivIndex,
          endOffset
        )
        selectedText = textDataSameDiv.wholeText
        divTexts = textDataSameDiv.divTexts
        break
      case SelectionRange.DifferentDivs:
        var textDataDifferentDivs = getTextFromOffsets(
          startDivSiblings,
          startPageIndex,
          startDivIndex,
          startOffset,
          endDivIndex,
          endOffset
        )
        selectedText = textDataDifferentDivs.wholeText
        divTexts = textDataDifferentDivs.divTexts
        break
      case SelectionRange.DifferentPages:
        var startTextData = getTextFromOffsets(
          startDivSiblings,
          startPageIndex,
          startDivIndex,
          startOffset,
          startDivSiblings.length
        )
        var endTextData = getTextFromOffsets(
          endDivSiblings,
          endPageIndex,
          0,
          0,
          endDivIndex,
          endOffset
        )
        selectedText = ''
          .concat(startTextData.wholeText, '\n')
          .concat(endTextData.wholeText)
        divTexts = startTextData.divTexts.concat(endTextData.divTexts)
        break
    }
    let selectionRegion
    if (highlightAreas.length > 0) {
      selectionRegion = highlightAreas[highlightAreas.length - 1]
    } else {
      const endDivRect = endDiv.getBoundingClientRect()
      selectionRegion = {
        height: (endDivRect.height * 100) / endPageRect.height,
        left: ((endDivRect.left - endPageRect.left) * 100) / endPageRect.width,
        pageIndex: endPageIndex,
        top: ((endDivRect.top - endPageRect.top) * 100) / endPageRect.height,
        width: (endDivRect.width * 100) / endPageRect.width
      }
    }
    const selectionData = {
      divTexts,
      selectedText,
      startPageIndex,
      endPageIndex,
      startOffset,
      startDivIndex,
      endOffset,
      endDivIndex
    }
    const selectedState = {
      type: HighlightStateType.Selected,
      selectedText,
      highlightAreas: highlightAreas.map(function (area) {
        return transformArea(area, rotation)
      }),
      selectionData,
      selectionRegion
    }
    store.update('highlightState', selectedState)
  }
  React__namespace.useEffect(
    function () {
      const ele = pagesRef.current
      if (!ele || trigger === exports.Trigger.None) {
        return
      }
      ele.addEventListener('mouseup', onMouseUpHandler)
      return function () {
        ele.removeEventListener('mouseup', onMouseUpHandler)
      }
    },
    [arePagesFound, trigger, rotation]
  )
  React__namespace.useEffect(function () {
    store.subscribe('getPagesContainer', handlePagesContainer)
    store.subscribe('trigger', handleTrigger)
    return function () {
      store.unsubscribe('getPagesContainer', handlePagesContainer)
      store.unsubscribe('trigger', handleTrigger)
    }
  }, [])
  return React__namespace.createElement(React__namespace.Fragment, null)
}

const TEXT_LAYER_END_SELECTOR = 'rpv-highlight__selected-end'
const highlightPlugin = function (props) {
  const highlightPluginProps = Object.assign(
    {},
    { trigger: exports.Trigger.TextSelection },
    props
  )
  const store = React__namespace.useMemo(function () {
    return core.createStore({
      highlightState: NO_SELECTION_STATE,
      trigger: highlightPluginProps.trigger
    })
  }, [])
  const renderViewer = function (props) {
    const currentSlot = props.slot
    if (currentSlot.subSlot && currentSlot.subSlot.children) {
      currentSlot.subSlot.children = React__namespace.createElement(
        React__namespace.Fragment,
        null,
        React__namespace.createElement(Tracker, { store }),
        currentSlot.subSlot.children
      )
    }
    return currentSlot
  }
  const handleMouseDown = function (textLayerRender) {
    return function (e) {
      if (store.get('trigger') === exports.Trigger.None || e.button !== 0) {
        return
      }
      const textLayer = textLayerRender.ele
      const pageRect = textLayer.getBoundingClientRect()
      const highlightState = store.get('highlightState')
      if (highlightState.type === HighlightStateType.Selected) {
        const mouseTop_1 = e.clientY - pageRect.top
        const mouseLeft_1 = e.clientX - pageRect.left
        const userClickedInsideArea = highlightState.highlightAreas
          .filter(function (area) {
            return area.pageIndex === textLayerRender.pageIndex
          })
          .find(function (area) {
            const t = (area.top * pageRect.height) / 100
            const l = (area.left * pageRect.width) / 100
            const h = (area.height * pageRect.height) / 100
            const w = (area.width * pageRect.width) / 100
            return (
              t <= mouseTop_1 &&
              mouseTop_1 <= t + h &&
              l <= mouseLeft_1 &&
              mouseLeft_1 <= l + w
            )
          })
        if (userClickedInsideArea) {
          window.getSelection().removeAllRanges()
          store.update('highlightState', NO_SELECTION_STATE)
        } else {
          store.update('highlightState', SELECTING_STATE)
        }
      } else {
        store.update('highlightState', NO_SELECTION_STATE)
      }
      const selectionTop = ((e.clientY - pageRect.top) * 100) / pageRect.height
      const selectEnd = textLayer.querySelector(
        '.'.concat(TEXT_LAYER_END_SELECTOR)
      )
      if (selectEnd && e.target !== textLayer) {
        selectEnd.style.top = ''.concat(Math.max(0, selectionTop), '%')
      }
    }
  }
  const handleMouseUp = function (textLayerRender) {
    return function (e) {
      if (store.get('trigger') === exports.Trigger.None) {
        return
      }
      const selectEnd = textLayerRender.ele.querySelector(
        '.'.concat(TEXT_LAYER_END_SELECTOR)
      )
      if (selectEnd) {
        selectEnd.style.removeProperty('top')
      }
    }
  }
  const onTextLayerRender = function (e) {
    const mouseDownHandler = handleMouseDown(e)
    const mouseUpHandler = handleMouseUp(e)
    const textEle = e.ele
    if (e.status === core.LayerRenderStatus.PreRender) {
      textEle.removeEventListener('mousedown', mouseDownHandler)
      textEle.removeEventListener('mouseup', mouseUpHandler)
      const selectEndEle = textEle.querySelector(
        '.'.concat(TEXT_LAYER_END_SELECTOR)
      )
      if (selectEndEle) {
        textEle.removeChild(selectEndEle)
      }
    } else if (e.status === core.LayerRenderStatus.DidRender) {
      textEle.addEventListener('mousedown', mouseDownHandler)
      textEle.addEventListener('mouseup', mouseUpHandler)
      textEle.setAttribute(HIGHLIGHT_LAYER_ATTR, 'true')
      textEle
        .querySelectorAll('.rpv-core__text-layer-text')
        .forEach(function (span) {
          return span.setAttribute(HIGHLIGHT_PAGE_ATTR, ''.concat(e.pageIndex))
        })
      if (!textEle.querySelector('.'.concat(TEXT_LAYER_END_SELECTOR))) {
        const selectEnd = document.createElement('div')
        selectEnd.classList.add(TEXT_LAYER_END_SELECTOR)
        selectEnd.classList.add('rpv-core__text-layer-text--not')
        textEle.appendChild(selectEnd)
      }
    }
  }
  const renderPageLayer = function (renderPageProps) {
    return React__namespace.createElement(
      React__namespace.Fragment,
      null,
      React__namespace.createElement(ClickDrag, {
        canvasLayerRef: renderPageProps.canvasLayerRef,
        canvasLayerRendered: renderPageProps.canvasLayerRendered,
        pageIndex: renderPageProps.pageIndex,
        store,
        textLayerRef: renderPageProps.textLayerRef,
        textLayerRendered: renderPageProps.textLayerRendered
      }),
      React__namespace.createElement(HighlightAreaList, {
        pageIndex: renderPageProps.pageIndex,
        renderHighlightContent: highlightPluginProps.renderHighlightContent,
        renderHighlightTarget: highlightPluginProps.renderHighlightTarget,
        renderHighlights: highlightPluginProps.renderHighlights,
        store
      })
    )
  }
  const jumpToHighlightArea = function (area) {
    const jumpToDestination = store.get('jumpToDestination')
    if (jumpToDestination) {
      const bottomOffset = function (_, viewportHeight) {
        return ((100 - area.top) * viewportHeight) / 100
      }
      const leftOffset = function (viewportWidth, _) {
        return ((100 - area.left) * viewportWidth) / 100
      }
      jumpToDestination({
        pageIndex: area.pageIndex,
        bottomOffset,
        leftOffset
      })
    }
  }
  const switchTrigger = function (trigger) {
    store.update('trigger', trigger)
  }
  return {
    install: function (pluginFunctions) {
      store.update('jumpToDestination', pluginFunctions.jumpToDestination)
      store.update('getPagesContainer', pluginFunctions.getPagesContainer)
    },
    onViewerStateChange: function (viewerState) {
      store.update('rotation', viewerState.rotation)
      return viewerState
    },
    onTextLayerRender,
    renderPageLayer,
    renderViewer,
    jumpToHighlightArea,
    switchTrigger
  }
}

const MessageIcon = function () {
  return React__namespace.createElement(
    core.Icon,
    { size: 16 },
    React__namespace.createElement('path', {
      d: 'M23.5,17a1,1,0,0,1-1,1h-11l-4,4V18h-6a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z'
    }),
    React__namespace.createElement('path', { d: 'M5.5 12L18.5 12' }),
    React__namespace.createElement('path', { d: 'M5.5 7L18.5 7' })
  )
}

exports.MessageIcon = MessageIcon
exports.highlightPlugin = highlightPlugin
