import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    paddingLeft: 20,
    paddingTop: 24
  },
  text: {
    width: 500
  },
  inputLabel: {
    paddingTop: 16
  },
  button1: {
    marginTop: 16
  }
})
export { useStyles }
