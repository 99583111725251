import {
  UPDATE_LIBRARY_COLLECTIONS,
  DELETE_LIBRARY_COLLECTIONS,
  UPDATE_LIBRARY_COLLECTION_NAME,
  UPDATE_LIBRARY_COLLECTIONS_STATUS
} from '../../../actionTypes'
import {
  createCollection,
  deleteCollection,
  updateCollectionName
} from '../../../api'
import { toast } from 'react-toastify'

const updateCollections = (name, visibility) => async (dispatch) => {
  const res = await createCollection({ name, visibility })
  if (res.status === 200) {
    dispatch({
      type: UPDATE_LIBRARY_COLLECTIONS,
      payload: res.data
    })
  }
}

const removeCollection =
  (data, callback = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_LIBRARY_COLLECTIONS_STATUS,
      payload: { ...data, status: 'deleting' }
    })

    const res = await deleteCollection(data)
    if (res.status === 200) {
      dispatch({
        type: DELETE_LIBRARY_COLLECTIONS,
        payload: data,
        callback
      })
      dispatch({
        type: UPDATE_LIBRARY_COLLECTIONS_STATUS,
        payload: { ...data, status: 'deleted' }
      })
    } else {
      dispatch({
        type: UPDATE_LIBRARY_COLLECTIONS_STATUS,
        payload: { ...data, status: false }
      })
      toast.error('Error deleting collection')
    }
  }

const changeCollectionName =
  (data, callback = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_LIBRARY_COLLECTIONS_STATUS,
      payload: { ...data, status: 'updating' }
    })

    const res = await updateCollectionName(data)
    if (res.status === 200) {
      dispatch({
        type: UPDATE_LIBRARY_COLLECTION_NAME,
        payload: data,
        callback
      })
      dispatch({
        type: UPDATE_LIBRARY_COLLECTIONS_STATUS,
        payload: { ...data, status: false }
      })
    } else {
      dispatch({
        type: UPDATE_LIBRARY_COLLECTIONS_STATUS,
        payload: { ...data, status: false }
      })
      toast.error('Error updating collection name')
    }
  }

export { updateCollections, removeCollection, changeCollectionName }
