import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  defaultButton: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '600',
    textTransform: 'none',
    borderRadius: '6px'
  },
  button2: {
    outline: 'none',
    fontFamily: 'PoppinsMedium',
    textTransform: 'none',
    borderRadius: '5px',
    color: 'var(--button-text)',
    backgroundColor: 'transparent',
    padding: '5px 15px',
    paddingLeft: '10px',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    cursor: 'pointer',
    '& svg': {
      color: 'var(--grey-400)'
    },

    '&:hover': {
      backgroundColor: 'var(--bg-dark-primary)',
      color: 'var(--dark-primary)'
    },

    '&:hover svg': {
      color: 'var(--light-primary)'
    }
  },
  button2Active: {
    '& svg': {
      color: 'var(--light-primary)'
    },
    color: 'var(--light-primary)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  },

  iconOnly: {
    justifyContent: 'center',
    padding: '5px'
  },

  secondaryHover: {
    '&:hover': {
      backgroundColor: 'var(--grey-100)'
    }
  }
})

export { useStyles }
