export const scrollIntoViewIfNeeded = (elementId, containerId) => {
  const target = document.getElementById(elementId)
  const container = document.getElementById(containerId)
  if (container && target) {
    const containerRect = container.getBoundingClientRect()
    const targetRect = target.getBoundingClientRect()
    if (targetRect.bottom > containerRect.bottom) {
      target.scrollIntoView(false)
    } else if (targetRect.top < containerRect.top) {
      target.scrollIntoView()
    }
  }
}
