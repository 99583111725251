import { FETCH_PEOPLE, CLEAR_PEOPLE, ADD_PEOPLE } from '../../../actionTypes'
import { getPeople, getParticularMasterPeople } from '../../../api'
import {
  initalizeS3,
  getSignedUrl,
  getSignedUrlHead
} from '../../../../utils/AWS'
import { PageSizePeople } from '../../../../config/constants'

export const formatResponse = async (people_data) => {
  const s3Obj = await initalizeS3()
  const formatedResumeData = []

  people_data &&
    (await Promise.all(
      people_data.map(async (result, index) => {
        const {
          name,
          resume_list = [],
          master_resume_data,
          id,
          verified,
          profile_picture,
          version,
          ...rest
        } = result
        let profile_pic = ''
        const resume_list_array = []
        if (profile_picture?.location) {
          const signedSrc = await getSignedUrl(profile_picture?.location, s3Obj)
          profile_pic = signedSrc
        }
        resume_list &&
          (await Promise.all(
            resume_list.map(async (resume, index) => {
              const { resume_data, screenshots = [] } = resume
              if (resume?.profile_picture_location) {
                const signedSrc = await getSignedUrl(
                  resume.profile_picture_location,
                  s3Obj
                )
                resume_list[index].profile_picture_location = signedSrc
                if (!profile_pic) {
                  profile_pic = signedSrc
                }
              }
              if (resume?.thumbnail_url) {
                resume_list[index].thumbnail_url = await getSignedUrl(
                  resume.thumbnail_url,
                  s3Obj
                )
              } else {
                const { proposal_id, page_number, domain_id } = resume
                if (
                  process.env.REACT_APP_IMAGE_BUCKET &&
                  proposal_id &&
                  (page_number === 0 || page_number) &&
                  domain_id
                ) {
                  const potentialLocation = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/${page_number}.png`
                  if (potentialLocation) {
                    resume_list[index].thumbnail_url = await getSignedUrl(
                      potentialLocation,
                      s3Obj
                    )
                  }
                }
              }
              if (_.isEmpty(screenshots)) {
                const promises = screenshots.map(async (e, i) => {
                  const { src } = { ...e }
                  const signedSrc = await getSignedUrl(src, s3Obj)
                  if (signedSrc) {
                    return { ...e, src: signedSrc }
                  }
                  return e
                })
                const updatedScreenshots = await Promise.all(promises)
                resume_list[index].screenshots = updatedScreenshots
              }
              resume_list_array.push(resume_list[index])
            })
          ))

        formatedResumeData.push({
          name,
          profile_picture_location: profile_pic,
          resumes: resume_list_array,
          // master_resume_data,
          profile_picture,
          verified,
          id,
          version,
          ...rest
        })
      })
    ))
  formatedResumeData.sort((a, b) => a.name.localeCompare(b.name))
  return formatedResumeData
}

const fetchPeople =
  (data, filters = {}, callback = () => {}) =>
  async (dispatch) => {
    const {
      keyword = '',
      page_num = 1,
      page_size = PageSizePeople,
      master_people_id = '',
      isSingleResume = false,
      ...rest
    } = data
    const body = {
      page_num,
      page_size,
      keyword,
      ...rest
    }
    const dispatchType = page_num === 1 ? FETCH_PEOPLE : ADD_PEOPLE

    const payload = {
      hasMoreData: false,
      currentPage: page_num,
      pageSize: PageSizePeople,
      peopleList: [],
      filters
    }
    let res
    if (master_people_id) {
      res = await getParticularMasterPeople(master_people_id)
    } else {
      res = await getPeople(body)
    }
    if (res.status === 200) {
      const formatedResponse = await formatResponse(res.data.people)
      payload.hasMoreData = res.data.has_next_page
      payload.peopleList = formatedResponse
      if (isSingleResume) {
        callback(payload)
      } else {
        dispatch({
          type: dispatchType,
          payload,
          callback: () => callback(payload)
        })
      }
    } else if (res.status === 204) {
      if (!isSingleResume) {
        dispatch({
          type: dispatchType,
          payload,
          callback
        })
      }
    }
  }

export { fetchPeople }
