import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Box,
  Menu,
  Tooltip,
  MenuItem,
  Modal,
  TextField,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CardContent,
  Card,
  TableRow,
  IconButton,
  ListItemText,
  Checkbox,
  ListItemIcon,
  Divider
} from '@mui/material'
import DropBox from '../DropBox'
import { useStyles } from './styles'
import _, { cloneDeep, includes, isEmpty, set } from 'lodash'
import RemoveIcon from '@mui/icons-material/Remove'
import ReplayIcon from '@mui/icons-material/Replay'
import Button from '../Button'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { updateResource, multiDocuments } from '../../store/api'
import { fetchAnalytics, updateAnalytics } from '../../store/Analytics/Actions'
import { fetchRFxDocuments, updateRfx } from '../../store/RFx/Actions'
import { Container, SectionFixed, Section } from '../Container'
import InputAdornment from '@mui/material/InputAdornment'
import Lottie from 'react-lottie'
import Loader1 from '../../assets/lottie/fileLoader1.json'
import Loader2 from '../../assets/lottie/fileLoader2.json'
import Image from '../ImageElement'
import { MultiSelectCheckBox, DateTagSelector, Loader } from '../../components'
import PDFICON from '../../assets/images/pdf_icon.png'
import { toast } from 'react-toastify'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import ResourceAddToCollection from '../../components/ResourceAddToCollection'
import { AddNewKey, addNewValue } from '../../sections/TagCenterSettings'
import { useColor } from '../../ThemeContext'
import { updateNewTagValue } from '../../store/TagCenter/Actions'
import ClearIcon from '@mui/icons-material/Clear'
import { renderURL } from '../../utils/TagCenter'
import { hasAccess } from '../../utils/User'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { CrossIcon, MinimizeIcon } from '../Icons/Icons'

const LinearProgressWithLabel = (props) => {
  const classes = useStyles()
  const {
    linearProgressContainer,
    linearProgressContent,
    linearProgressUpload,
    linearProgressError
  } = classes
  const { file = {} } = props
  const { error } = file

  const color = getProgressbarColor(file)
  return (
    <Box sx={{ color }}>
      <Box className={linearProgressContainer}>
        <Box className={linearProgressContent}>
          <LinearProgress
            variant="determinate"
            color="inherit"
            sx={{ color }}
            {...props}
          />
        </Box>
        <Box className={linearProgressUpload}>
          {`${Math.round(props.value)}%`}
        </Box>
      </Box>
      {error && (
        <Box className={linearProgressError}>{`Upload Error: ${error}`}</Box>
      )}
    </Box>
  )
}

const getProgressbarColor = (file) => {
  const { progress = {}, error, isPaused } = file
  const { uploadComplete, uploadStarted } = progress
  if (isPaused) {
    return 'lightgrey'
  }
  if (error) {
    return '#fb4d46'
  }
  if (uploadComplete) {
    return '#008631'
  } else if (uploadStarted) {
    return '#0086318f'
  }
  return 'inherit'
}

const TagSelector = (props) => {
  const {
    tagsOptions = {},
    selectedTagsOptions = [],
    setSelectedTagsOptions = () => {},
    handleOpenAddNewKey = () => {},
    canCreateTags = false
  } = props

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [filteredTagsOptions, setFilteredTagsOptions] = useState({})
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleKeysSelect = (value) => {
    trackEvent(mixpanelEvents.TAGS_ADDED_TO_UPLOAD, 'SUCCESS', {}, { value })
    if (selectedTagsOptions.includes(value)) {
      setSelectedTagsOptions(
        selectedTagsOptions.filter((item) => item !== value)
      )
    } else {
      setSelectedTagsOptions([...selectedTagsOptions, value])
    }
  }

  useEffect(() => {
    if (tagsOptions) {
      console.log(tagsOptions)

      const tagOptionsClone = Object.keys(tagsOptions).reduce((acc, key) => {
        const item = tagsOptions[key]
        if (_.includes(item.key_type, 'document')) {
          acc[key] = item
        }
        return acc
      }, {})

      console.log(tagOptionsClone)
      setFilteredTagsOptions(tagOptionsClone)
    }
  }, [tagsOptions])

  return (
    <Box>
      <Tooltip title="Add Tags">
        <Button variant="outlined" onClick={handleClick}>
          Add Tags
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.hideMenu}
      >
        <Box>
          {Object.keys(filteredTagsOptions).map((item, index) => (
            <>
              <MenuItem
                onClick={(e) => {
                  handleKeysSelect(item)
                  e.stopPropagation()
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    disableRipple
                    color="neutral"
                    checked={selectedTagsOptions.includes(item)}
                    onChange={() => handleKeysSelect(item)}
                    size="small"
                    onClick={(e) => e.stopPropagation()}
                  />
                </ListItemIcon>
                <ListItemText className={classes.hideMenuListText}>
                  {item}
                </ListItemText>
              </MenuItem>
              <Divider className={classes.hideMenuDivider} />
            </>
          ))}
          {canCreateTags && (
            <>
              <Divider className={classes.blackDivider} />
              <MenuItem
                onClick={() => {
                  handleOpenAddNewKey()
                  handleClose()
                }}
              >
                <ListItemIcon>
                  <AddIcon sx={{ padding: '3px 5px', fontSize: '18px' }} />
                </ListItemIcon>
                <ListItemText className={classes.hideMenuListText}>
                  Add New Tag
                </ListItemText>
              </MenuItem>
            </>
          )}
        </Box>
      </Menu>
    </Box>
  )
}

const UploadModal = (props) => {
  const {
    uppy = {},
    onRequestClose,
    uploaderType,
    fullScreen,
    showFullScreen,
    modalCallback = () => {},
    uploaderState = {}
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    outerContainer,
    topBar,
    tableCellName,
    tableCellClientSector,
    tableCellUploadedName,
    tableCellContent,
    uploadFileName,
    controlButton,
    progressBar,
    progressContent,
    cardContainer,
    cardContentContainer,
    uploadIconButton,
    UploadButton,
    cancelButton
  } = classes

  const [uploadedFiles, setUploadedFiles] = useState([])
  const [currentUploadPercentage, setCurrentUploadPercentage] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [nameError, setNameError] = useState({})
  const [viewState, setViewState] = useState('drop')
  const [showAddFile, setShowAddFile] = useState(false)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [selectedTagsOptions, setSelectedTagsOptions] = useState([])
  const [selectedMultiTag, setSelectedMultiTag] = useState([])
  const [selectedTagValues, setSelectedTagValues] = useState({})
  const [loading, setLoading] = useState(true)
  const { activeCollection } = uploaderState
  const [openCollectionSelector, setOpenCollectionSelector] = useState(false)
  const [openAddNewKey, setOpenAddNewKey] = useState(false)
  const [tagInputLoading, setTagInputLoading] = useState({})
  const analyticComplianceData = useSelector(
    (state) => state.analyticsCompliance.analyticComplianceData
  )
  const analyticComplianceDataId = analyticComplianceData?.analytic_id
    ? analyticComplianceData?.analytic_id
    : ''
  const { selectedColor } = useColor()
  const [rowLoading, setRowLoading] = useState({})
  const selectWrapperRef = useRef()
  const selectRef = useRef()
  const canCreateTags = hasAccess('tags', 'create')

  useEffect(() => {
    if (_.isEmpty(selectedTagsOptions)) {
      const data = getDataFromLocal()
      if (data) {
        setSelectedTagsOptions(data)
      } else {
        const values = Object.entries(tagsCenterStateTags || {}).reduce(
          (acc, [key, item]) => {
            if (item.value_type === 'document_type') {
              acc.push({ key, ...item })
            }
            return acc
          },
          []
        )
        if (values.length > 0) {
          setSelectedTagsOptions([values[0]?.key])
        }
      }
    }
  }, [tagsCenterStateTags])

  const handleOpenAddNewKey = () => {
    setOpenAddNewKey(true)
  }

  const handleCloseAddNewKey = () => {
    setOpenAddNewKey(false)
  }

  const handleCloseCollectionSelector = () => {
    setOpenCollectionSelector(false)
  }
  const handleOpenCollectionSelector = () => {
    setOpenCollectionSelector(true)
  }

  const callbackCollectionSelector = (data) => {
    setCollections(data)
    setLoading(true)
  }

  const [collections, setCollections] = useState([])

  useEffect(() => {
    setLoading(true)
    const { serverType: collectionType = '' } = activeCollection || {}
    if (collectionType === 'collection') {
      setCollections([activeCollection])
    }
    setLoading(false)
  }, [activeCollection])

  useEffect(() => {
    setLoading(false)
  }, [collections])

  useEffect(() => {
    saveDataToLocal(selectedTagsOptions)
  }, [selectedTagsOptions])

  const getDataFromLocal = () => {
    let data = localStorage.getItem('uploadSelectedTags')
    data = data ? JSON.parse(data) : null
    return data
  }

  const saveDataToLocal = (data) => {
    localStorage.setItem('uploadSelectedTags', JSON.stringify(data))
  }

  const getDocumentType = (extension) => {
    const documentType = tagsCenterStateTags?.['Document Type']?.data || []
    const defaultTags = documentType.filter((item) =>
      item.value_type.includes('default')
    )
    const defaultMap = {}
    defaultTags.forEach((item) => {
      defaultMap[item.value_type] = item
    })
    if (['pdf', 'pptx', 'docx', 'doc', 'ppt'].includes(extension)) {
      return defaultMap.document_type_Document_default || ''
    } else if (['jpeg', 'jpg', 'png'].includes(extension)) {
      return defaultMap.document_type_Image_default || ''
    } else if (['mp4'].includes(extension)) {
      return defaultMap.document_type_Video_default || ''
    } else {
      return defaultMap.document_type_Document_default || ''
    }
  }

  useEffect(() => {
    if (!isEmpty(uppy)) {
      uppy.on('file-added', (file) => {
        refreshFileState()
      })

      uppy.on('file-removed', () => {
        refreshFileState()
      })
      uppy.on('upload-progress', () => {
        refreshFileState()
      })
      uppy.on('progress', (progress) => {
        setCurrentUploadPercentage(progress)
        if (progress > 0 && progress < 100 && !isUploading) {
          setIsUploading(true)
        } else {
          refreshFileState()
          setIsUploading(false)
        }
      })
      uppy.on('upload-error', (file, error, response) => {
        refreshFileState()
      })
      uppy.on('complete', (result) => {
        if (uploaderType === 'resource') {
          modalCallback(result, 'onComplete')
        } else if (uploaderType === 'analytics') {
          dispatch(fetchAnalytics())
        } else if (uploaderType === 'rfx') {
          dispatch(fetchRFxDocuments())
        }
      })
      refreshFileState()
    }
  }, [uppy])

  useEffect(() => {
    if (!isEmpty(uppy)) {
      uppy.on('file-added', (file) => {
        const { id, extension } = file
        const type = getDocumentType(extension)
        const document_type = {
          label: type?.value || '',
          value: type?.id || '',
          type: type?.value_type || ''
        }
        if (document_type) {
          uppy.setFileMeta(id, { document_type })
          setSelectedTagValues((prevValues) => ({
            ...prevValues,
            [id]: {
              ...prevValues[id],
              'Document Type': [document_type]
            }
          }))
        }
      })

      uppy.on('complete', (result) => {
        if (uploaderType === 'resource') {
          const { successful = [] } = result
          successful.forEach((item) => {
            const { serverFileId, id } = item
            const type = getFileType(item)
            const documents = [
              {
                id: serverFileId,
                uppy_id: id,
                file_type: type
              }
            ]
            modalCallback(
              { documents, selectedTags: selectedTagValues },
              'onUpdateDataMulti'
            )
          })
        }
      })
    }
  }, [selectedTagValues, uppy])

  useEffect(() => {
    refreshFileState()
  }, [])

  useEffect(() => {
    refreshFileState()
  }, [fullScreen])

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    if (isUploading) {
      window.addEventListener('beforeunload', alertUser)
      return () => window.removeEventListener('beforeunload', alertUser)
    } else {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [isUploading])

  const addFiles = (files) => {
    const collectionIds = collections.map((item) => item.id)
    const descriptors = files.map((file) => ({
      source: uploaderType,
      name: file.path,
      type: file.type,
      data: file,
      meta: {
        collection_id: collectionIds,
        selected_collections: collections,
        analytics_id: analyticComplianceDataId
      }
    }))
    try {
      uppy.addFiles(descriptors)
    } catch (err) {
      refreshFileState()
      console.log(err)
    }
  }

  const refreshFileState = () => {
    const files = uppy.getFiles()
    setUploadedFiles(files)
  }

  const handleFileUpload = (files) => {
    if (files.length > 0) {
      addFiles(files)
    }
  }

  const handleFileRemove = (id) => {
    uppy.removeFile(id)
    setSelectedMultiTag(selectedMultiTag.filter((item) => item !== id))
    setSelectedTagValues((prevValues) => {
      const newValues = { ...prevValues }
      delete newValues[id]
      return newValues
    })
    setRowLoading((prevLoading) => {
      const newLoading = { ...prevLoading }
      delete newLoading[id]
      return newLoading
    })
  }

  const getFileType = (file) => {
    const { meta, extension } = file
    const { document_type } = meta
    let type = 'document'
    if (['pdf', 'pptx', 'docx', 'doc', 'ppt'].includes(extension)) {
      console.log(document_type?.label?.toLowerCase())
      if (document_type?.value_type?.toLowerCase().includes('proposal')) {
        type = 'proposal'
      } else {
        type = 'document'
      }
    } else {
      type = 'asset'
    }
    return type
  }

  const handlePauseResume = (id) => {
    uppy.pauseResume(id)
    refreshFileState()
  }

  const handleMetaEdit = async (
    e,
    key,
    id,
    extension,
    tag_key,
    isDocumentType
  ) => {
    if (key === 'name') {
      let value = e.target.value
      if (key === 'name' && !value) {
        setNameError({ ...nameError, [id]: true })
        return
      }
      if (extension) {
        value = value + '.' + extension
      }
      uppy.setFileMeta(id, { [key]: value })
      setRowLoading((prevLoading) => {
        const loading = { ...prevLoading }
        loading[id] = true
        return loading
      })
    } else if (key === 'tags') {
      setRowLoading((prevLoading) => {
        const loading = { ...prevLoading }
        if (selectedMultiTag.includes(id)) {
          selectedMultiTag.forEach((id) => {
            loading[id] = true
          })
        } else {
          loading[id] = true
        }
        return loading
      })
      const tags = []
      const selectedTags = selectedTagValues[id]
      Object.keys(selectedTags || {}).forEach((key, index) => {
        const values = selectedTags[key]
        if (values.length > 0) {
          values.forEach((element) => {
            tags.push(element.value)
          })
        }
      })
      if (selectedMultiTag.includes(id)) {
        selectedMultiTag.forEach((item) => {
          uppy.setFileMeta(item, { [key]: tags, selectedTags })
        })
      } else {
        uppy.setFileMeta(id, { [key]: tags, selectedTags })
      }
    }
    const file = uppy.getFile(id)
    const { serverFileId, meta } = file
    if (serverFileId && meta) {
      if (uploaderType === 'resource') {
        if (selectedMultiTag.includes(id) && key === 'tags') {
          const document_map = {}
          const documents = selectedMultiTag.map((item) => {
            const file = uppy.getFile(item)
            const { serverFileId, meta } = file
            const { document_type } = meta
            document_map[item] = { serverFileId, document_type }
            const type = getFileType(file)
            return {
              id: serverFileId,
              uppy_id: item,
              file_type: type
            }
          })
          const tagsArr = {}
          selectedMultiTag.forEach((id) => {
            const { serverFileId, document_type } = document_map[id]
            Object.keys(selectedTagValues?.[id] || {}).forEach((key, index) => {
              const arrValues = selectedTagValues[id][key]
              if (arrValues.length > 0) {
                arrValues.forEach((element) => {
                  if (!tagsArr[key]) {
                    tagsArr[key] = []
                  }
                  tagsArr[key].push({
                    tag_key: key,
                    tag_value: element.value,
                    tag_label: element.label
                  })
                })
              }
            })
          })
          const req = {
            documents,
            action: 'tags',
            data: { tags: tagsArr, date_tags: [] }
          }
          const res = await multiDocuments(req)
          if (res.status === 200) {
            if (isDocumentType) {
              selectedMultiTag.forEach((id) => {
                const value = selectedTagValues[id]['Document Type'][0]
                uppy.setFileMeta(id, { document_type: value })
              })
              const document_ids = documents.map((item) => item.id)
              const document_type = getFileType(file)
              modalCallback({ document_ids, document_type }, 'onUpdateType')
            }
            modalCallback(
              { documents, selectedTags: selectedTagValues },
              'onUpdateDataMulti'
            )
          } else {
            toast.error('Something went wrong while updating resource')
          }
          setRowLoading((prevLoading) => {
            const loading = { ...prevLoading }
            selectedMultiTag.forEach((id) => {
              loading[id] = false
            })
            return loading
          })
        } else {
          const type = getFileType(file)
          const tagsChanges = [...(meta?.tags || [])]
          const data = {
            document_id: serverFileId,
            type,
            ...meta,
            tags: tagsChanges,
            document_type: type
          }
          const res = await updateResource(data)
          if (res.status === 200) {
            if (isDocumentType) {
              const value = selectedTagValues[id]['Document Type'][0]
              uppy.setFileMeta(id, { document_type: value })
              const document_ids = [serverFileId]
              const document_type = getFileType(file)
              modalCallback({ document_ids, document_type }, 'onUpdateType')
            }
            modalCallback(
              { ...data, selectedTags: selectedTagValues[id] },
              'onUpdateData'
            )
          } else if (res.status !== 201) {
            toast.error('Something went wrong while updating resource')
          }
          setRowLoading((prevLoading) => {
            const loading = { ...prevLoading }
            loading[id] = false
            return loading
          })
        }
      } else if (uploaderType === 'analytics') {
        const data = { id: serverFileId, name: meta.name }
        dispatch(updateAnalytics(data))
      } else if (uploaderType === 'rfx') {
        const data = { id: serverFileId, name: meta.name }
        dispatch(updateRfx(data))
      }
    }
  }

  const handleNameChange = (e, id, extension, idx) => {
    if (e.target.value) {
      const updatedFiles = cloneDeep(uploadedFiles)
      updatedFiles[idx].name = e.target.value + '.' + extension
      setUploadedFiles(updatedFiles)
      setNameError({ ...nameError, [id]: false })
    }
  }

  const handleRetry = (id) => {
    uppy.retryUpload(id)
    refreshFileState()
  }

  const handleForceCloseModel = async () => {
    await uppy.cancelAll({ reason: 'user' })
    onRequestClose()
  }

  const handleCloseModelMinimize = () => {
    showFullScreen(false)
  }

  useEffect(() => {
    if (_.isEmpty(uploadedFiles)) {
      setViewState('drop')
    } else if (!_.isEmpty(uploadedFiles) && currentUploadPercentage !== 100) {
      setViewState('uploading')
    } else {
      if (currentUploadPercentage === 100) {
        setViewState('completed')
      } else {
        setViewState('drop')
      }
    }
  }, [currentUploadPercentage, uploadedFiles])

  useEffect(() => {
    if (!_.isEmpty(uploadedFiles)) {
      setShowAddFile(false)
    }
  }, [uploadedFiles])

  const handleOnAddNew = async (value, key, isMulti) => {
    if (selectRef?.current) {
      selectRef.current.selectOption(value)
    }
    if (isMulti) {
      if (selectWrapperRef?.current) {
        selectWrapperRef.current.clearInputValue()
      }
    }
    setTimeout(() => {
      setTagInputLoading({ ...tagInputLoading, [key]: false })
    }, 300)
  }

  const onChangeTags = (value, id, key, isMulti, idx, isDocumentType) => {
    if (isMulti) {
      if (selectedMultiTag.includes(id)) {
        const selectedTags = { ...selectedTagValues }
        selectedMultiTag.forEach((id) => {
          selectedTags[id] = {
            ...selectedTags[id],
            [key]: value
          }
        })
        setSelectedTagValues(selectedTags)
      } else {
        setSelectedTagValues({
          ...selectedTagValues,
          [id]: {
            ...selectedTagValues[id],
            [key]: value
          }
        })
      }
    } else {
      if (value) {
        if (selectedMultiTag.includes(id)) {
          const selectedTags = { ...selectedTagValues }
          selectedMultiTag.forEach((id) => {
            selectedTags[id] = {
              ...selectedTags[id],
              [key]: [value]
            }
          })
          setSelectedTagValues(selectedTags)
        } else {
          setSelectedTagValues({
            ...selectedTagValues,
            [id]: {
              ...selectedTagValues[id],
              [key]: [value]
            }
          })
        }
      } else {
        if (selectedMultiTag.includes(id)) {
          const selectedTags = { ...selectedTagValues }
          selectedMultiTag.forEach((id) => {
            selectedTags[id] = {
              ...selectedTags[id],
              [key]: []
            }
          })
          setSelectedTagValues(selectedTags)
        } else {
          setSelectedTagValues({
            ...selectedTagValues,
            [id]: {
              ...selectedTagValues[id],
              [key]: []
            }
          })
        }
      }
    }
  }

  const totalFiles = uploadedFiles?.length
  const uploadedCount = uploadedFiles?.filter(
    (item) => item.progress.uploadComplete
  ).length

  const onDateTagChange = async (value, id, current_key, tag_type) => {
    value = value.map((item) => {
      if (item instanceof Date) {
        return item.toISOString()
      }
      return item
    })

    const { data = [] } = tagsCenterStateTags[current_key] || {}
    const tagsFormated = []
    value.forEach((item) => {
      const tag = data.find((element) => {
        return element.value === item
      })
      if (tag) {
        tagsFormated.push({
          label: tag.value,
          value: tag.id
        })
      } else {
        tagsFormated.push({
          label: item,
          value: ''
        })
      }
    })
    let selectedTagValuesState = { ...selectedTagValues }
    if (selectedMultiTag.includes(id)) {
      selectedMultiTag.forEach((id) => {
        selectedTagValuesState[id] = {
          ...selectedTagValuesState[id],
          [current_key]: tagsFormated
        }
      })
    } else {
      selectedTagValuesState = {
        ...selectedTagValuesState,
        [id]: {
          ...selectedTagValuesState[id],
          [current_key]: tagsFormated
        }
      }
    }
    setSelectedTagValues(selectedTagValuesState)

    setRowLoading((prevLoading) => {
      const loading = { ...prevLoading }
      if (selectedMultiTag.includes(id)) {
        selectedMultiTag.forEach((id) => {
          loading[id] = true
        })
      } else {
        loading[id] = true
      }
      return loading
    })

    const tags = []
    const selectedTags = selectedTagValues[id]
    Object.keys(selectedTags || {}).forEach((key, index) => {
      const values = selectedTags[key]
      if (values.length > 0 && key !== current_key) {
        values.forEach((element) => {
          tags.push(element.value)
        })
      }
    })

    const dateArr = {}
    value.forEach((item) => {
      if (dateArr[current_key]) {
        dateArr[current_key].push({
          tag_key: current_key,
          tag_value: item,
          tag_type
        })
      } else {
        dateArr[current_key] = []
        dateArr[current_key].push({
          tag_key: current_key,
          tag_value: item,
          tag_type
        })
      }
    })

    const file = uppy.getFile(id)
    const { serverFileId, meta, extension: fileExt } = file
    const { document_type } = meta
    const type = getFileType(file)
    if (serverFileId && meta) {
      if (selectedMultiTag.includes(id)) {
        const document_map = {}
        const documents = selectedMultiTag.map((item) => {
          const file = uppy.getFile(item)
          const { serverFileId, meta, extension: fileExt } = file
          document_map[item] = serverFileId
          const type = getFileType(file)
          return {
            id: serverFileId,
            uppy_id: item,
            file_type: type
          }
        })

        const req = {
          documents,
          action: 'tags',
          data: { tags: [], date_tags: dateArr }
        }
        const res = await multiDocuments(req)
        if (res.status === 200) {
          const { new_tags } = res.data
          const newTagPayload = {}
          new_tags.forEach((item) => {
            if (!newTagPayload[item.key]) {
              newTagPayload[item.key] = []
            }
            newTagPayload[item.key].push(item)
          })
          Object.keys(new_tags || {}).forEach((key) => {
            dispatch(updateNewTagValue({ new_tags: newTagPayload[key], key }))
          })

          const { data = [] } = tagsCenterStateTags[current_key] || {}
          const tagsFormated = []
          value.forEach((item) => {
            const tag = data.find((element) => {
              return element.value === item
            })
            if (tag) {
              tagsFormated.push({
                label: tag.value,
                value: tag.id
              })
            } else {
              const newTag = new_tags.find((element) => {
                return element.value === item
              })
              if (newTag) {
                tagsFormated.push({
                  label: newTag.value,
                  value: newTag.id
                })
              }
            }
          })
          const newSelectedTagValues = { ...selectedTagValues }
          selectedMultiTag.forEach((id) => {
            newSelectedTagValues[id] = {
              ...newSelectedTagValues[id],
              [current_key]: tagsFormated
            }
          })
          setSelectedTagValues(newSelectedTagValues)
          modalCallback(
            { documents, selectedTags: newSelectedTagValues },
            'onUpdateDataMulti'
          )
        } else {
          toast.error('Something went wrong while updating resource')
        }
        setRowLoading((prevLoading) => {
          const loading = { ...prevLoading }
          selectedMultiTag.forEach((id) => {
            loading[id] = false
          })
          return loading
        })
      } else {
        const tags_date = []
        value.forEach((item) => {
          tags_date.push({
            tag_key: current_key,
            tag_value: item,
            tag_type
          })
        })
        const data = {
          document_id: serverFileId,
          type,
          ...meta,
          tags,
          tags_date
        }
        const res = await updateResource(data)
        if (res.status === 200) {
          const { new_tags } = res.data
          const { data = [] } = tagsCenterStateTags[current_key] || {}
          const tagsFormated = []
          value.forEach((item) => {
            const tag = data.find((element) => {
              return element.value === item
            })
            if (tag) {
              tagsFormated.push({
                label: tag.value,
                value: tag.id
              })
            } else {
              const newTag = new_tags.find((element) => {
                return element.value === item
              })
              if (newTag) {
                tagsFormated.push({
                  label: newTag.value,
                  value: newTag.id
                })
              }
            }
          })
          let newSelectedTagValues = { ...selectedTagValues }
          newSelectedTagValues = {
            ...newSelectedTagValues,
            [id]: {
              ...newSelectedTagValues[id],
              [current_key]: tagsFormated
            }
          }
          setSelectedTagValues(newSelectedTagValues)
          dispatch(updateNewTagValue({ new_tags, key: current_key }))
          modalCallback(
            { ...data, selectedTags: newSelectedTagValues[id] },
            'onUpdateData'
          )
        } else if (res.status !== 201) {
          toast.error('Something went wrong while updating resource')
        }
        setRowLoading((prevLoading) => {
          const loading = { ...prevLoading }
          loading[id] = false
          return loading
        })
      }
    }
  }

  const renderResourceUploader = () => {
    return (
      <Box className={outerContainer}>
        <Container>
          <ResourceAddToCollection
            isOpen={openCollectionSelector}
            handleClose={handleCloseCollectionSelector}
            callBack={callbackCollectionSelector}
            currentCollectionId={activeCollection?.id}
            actionButtons={[
              {
                label: 'Select',
                action: (e) => callbackCollectionSelector(e)
              }
            ]}
            prevSelectedCollections={collections}
            headerText={'Select Collections'}
          />
          <AddNewKey isOpen={openAddNewKey} onClose={handleCloseAddNewKey} />
          <SectionFixed>
            <Box className={topBar}>
              <Box>
                <Tooltip title="Minimize Uploader">
                  <IconButton
                    disableRipple
                    className={uploadIconButton}
                    onClick={() => handleCloseModelMinimize()}
                  >
                    <MinimizeIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </SectionFixed>
          <Section
            overFlow
            sx={{
              padding: '0 30px'
            }}
          >
            <Container>
              {viewState === 'drop' ? (
                <Section>
                  <DropBox
                    onDrop={handleFileUpload}
                    placeHolderText1={'Drop your files here'}
                    placeHolderText3={
                      collections?.length > 0 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            onClick={() => {
                              handleOpenCollectionSelector()
                            }}
                            sx={{
                              maxWidth: '450px',
                              textAlign: 'center',
                              display: 'flex',
                              fontSize: '14px',
                              margin: '0px 5px',
                              padding: '8.5px 20px',
                              background: '#DBEAFF',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }}
                          >
                            Uploading to{' '}
                            {collections.map((item) => item.name).join(', ')}
                            <IconButton
                              style={{
                                padding: '0px',
                                marginLeft: '5px',
                                fontSize: '18px'
                              }}
                              disableRipple
                              onClick={(e) => {
                                setCollections([])
                                e.stopPropagation()
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <div
                            onClick={() => {
                              handleOpenCollectionSelector()
                            }}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '7px',
                              cursor: 'pointer',
                              backgroundColor: 'var(--grey-200)',
                              color: 'var(--dark-text)',
                              padding: '8px 20px',
                              border: 'none',
                              borderRadius: '5px'
                              // '& :hover': {
                              //   backgroundColor: 'var(--dark-text)',
                              //   color: 'white'
                              // }
                            }}
                          >
                            <AddIcon sx={{ fontSize: '18px' }} />
                            Select Collection(s)
                          </div>
                        </Box>
                      )
                    }
                    placeHolderText4={
                      collections?.length > 0
                        ? "Files upload to collections will inhert the collection's visibility settings"
                        : 'Files uploaded to the root collection will be visible to all users'
                    }
                    acceptedFileType=".jpeg, .jpg, .png, .mp4, .pdf, .pptx, .docx, .doc, .ppt"
                  />
                </Section>
              ) : (
                ['uploading', 'completed'].includes(viewState) && (
                  <>
                    <SectionFixed>
                      <DropBox
                        onDrop={handleFileUpload}
                        placeHolderText1="Drop your proposals or assets here"
                        acceptedFileType=".jpeg, .jpg, .png, .mp4, .pdf, .pptx, .docx, .doc, .ppt"
                        placeHolderText3={
                          collections?.length > 0 ? (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Box
                                sx={{
                                  maxWidth: '450px',
                                  textAlign: 'center',
                                  display: 'flex',
                                  fontSize: '14px',
                                  margin: '0px 5px',
                                  padding: '8.5px 20px',
                                  background: '#DBEAFF',
                                  borderRadius: '4px'
                                }}
                              >
                                Uploading to{' '}
                                {collections
                                  .map((item) => item.name)
                                  .join(', ')}
                                <IconButton
                                  style={{
                                    padding: '0px',
                                    marginLeft: '5px',
                                    fontSize: '18px'
                                  }}
                                  disableRipple
                                  onClick={() => {
                                    setCollections([])
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '10px'
                              }}
                            >
                              <Box
                                onClick={() => {
                                  handleOpenCollectionSelector()
                                }}
                                sx={{
                                  display: 'flex',
                                  cursor: 'pointer',
                                  alignItems: 'center',
                                  margin: '0px 5px',
                                  padding: '5px',
                                  fontSize: '14px',
                                  background: '#DBEAFF',
                                  borderRadius: '4px'
                                }}
                              >
                                <AddIcon sx={{ fontSize: '18px' }} />
                                Select Collection(s)
                              </Box>
                            </Box>
                          )
                        }
                        placeHolderText4={
                          collections?.length > 0
                            ? "Files upload to collections will inhert the collection's visibility settings"
                            : 'Files uploaded to the root collection will be visible to all users'
                        }
                      />
                    </SectionFixed>
                    <Section overFlow>
                      <Container>
                        <SectionFixed>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              borderBottom: '1px solid rgba(224, 224, 224, 1)',
                              padding: '10px'
                            }}
                          >
                            {selectedTagsOptions.length > 0 ? (
                              <Tooltip title="Multi Update All Tags">
                                <Checkbox
                                  sx={{ padding: '0px', fontSize: '12px' }}
                                  disableRipple
                                  color="neutral"
                                  size="small"
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={() => {
                                    if (selectedMultiTag.length > 0) {
                                      setSelectedMultiTag([])
                                    } else {
                                      setSelectedMultiTag(
                                        uploadedFiles
                                          .filter(
                                            (item) =>
                                              !item.error && item.serverFileId
                                          )
                                          .map((item) => item.id)
                                      )
                                    }
                                  }}
                                  checked={
                                    selectedMultiTag.length ===
                                    uploadedFiles.length
                                  }
                                />
                              </Tooltip>
                            ) : (
                              <Box></Box>
                            )}
                            <TagSelector
                              tagsOptions={tagsCenterStateTags}
                              selectedTagsOptions={selectedTagsOptions}
                              setSelectedTagsOptions={setSelectedTagsOptions}
                              handleOpenAddNewKey={handleOpenAddNewKey}
                              canCreateTags={canCreateTags}
                            />
                            {/* </Box> */}
                          </Box>
                        </SectionFixed>
                        <Section overFlow sx={{ margin: '10px 0px' }}>
                          <Box>
                            {uploadedFiles.map((file, indexUploadedFiles) => {
                              const {
                                error,
                                progress,
                                isPaused,
                                name,
                                id,
                                meta = {},
                                serverFileId,
                                extension
                              } = file
                              const {
                                uploadStarted,
                                uploadComplete,
                                percentage
                              } = progress
                              const isDisabled =
                                error ||
                                rowLoading?.[id] ||
                                !uploadStarted ||
                                !serverFileId
                              const {
                                document_type = '',
                                selected_collections = []
                              } = meta
                              const nameWithoutExtension = name
                                .split('.')
                                .slice(0, -1)
                                .join('.')
                              return (
                                <Box
                                  key={id}
                                  sx={{
                                    borderBottom:
                                      '1px solid rgba(224, 224, 224, 1)',
                                    padding: '10px'
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between'
                                    }}
                                  >
                                    <Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          gap: '10px 30px',
                                          marginTop: '10px'
                                        }}
                                      >
                                        {selectedTagsOptions.length > 0 && (
                                          <Box>
                                            <Tooltip title="Multi Update Tags">
                                              <Checkbox
                                                sx={{
                                                  padding: '0px',
                                                  fontSize: '12px'
                                                }}
                                                disableRipple
                                                disabled={isDisabled}
                                                color="neutral"
                                                size="small"
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                }
                                                onChange={() => {
                                                  if (
                                                    selectedMultiTag.includes(
                                                      id
                                                    )
                                                  ) {
                                                    setSelectedMultiTag(
                                                      selectedMultiTag.filter(
                                                        (item) => item !== id
                                                      )
                                                    )
                                                  } else {
                                                    setSelectedMultiTag([
                                                      ...selectedMultiTag,
                                                      id
                                                    ])
                                                  }
                                                }}
                                                checked={selectedMultiTag.includes(
                                                  id
                                                )}
                                              />
                                            </Tooltip>
                                          </Box>
                                        )}
                                        <Box sx={{ width: '250px' }}>
                                          <TextField
                                            sx={{
                                              '& input': {
                                                padding: '6px 14px 6px 14px',
                                                fontSize: '12px'
                                              },
                                              width: '250px',
                                              '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                  borderColor: '#E5E5E5'
                                                },
                                                '&:hover fieldset': {
                                                  borderColor: '#E5E5E5'
                                                }
                                              }
                                            }}
                                            disabled={isDisabled}
                                            error={nameError[id]}
                                            helperText={
                                              nameError[id] &&
                                              'Name cannot be empty'
                                            }
                                            defaultValue={nameWithoutExtension}
                                            placeholder="Enter File Name"
                                            onChange={(e) =>
                                              handleNameChange(
                                                e,
                                                id,
                                                extension,
                                                indexUploadedFiles
                                              )
                                            }
                                            onBlur={(e) =>
                                              handleMetaEdit(
                                                e,
                                                'name',
                                                id,
                                                extension
                                              )
                                            }
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment
                                                  sx={{ fontSize: '12px' }}
                                                  position="end"
                                                >
                                                  .{extension}
                                                </InputAdornment>
                                              )
                                            }}
                                          />
                                        </Box>
                                        <Box
                                          sx={{
                                            flexWrap: 'wrap',
                                            display: 'flex',
                                            gap: '10px 30px'
                                          }}
                                        >
                                          {selectedTagsOptions.map(
                                            (key, index) => {
                                              if (tagsCenterStateTags[key]) {
                                                const {
                                                  data = [],
                                                  type,
                                                  value_type
                                                } = tagsCenterStateTags[key] ||
                                                {}
                                                const isMulti =
                                                  type.includes('multi')
                                                const isDate =
                                                  type.includes('date')
                                                const isDocumentType =
                                                  value_type === 'document_type'
                                                const isURL =
                                                  type.includes('url')
                                                const loading =
                                                  tagInputLoading[key] || false
                                                const defaultValue =
                                                  selectedTagValues[id]
                                                    ? isMulti
                                                      ? selectedTagValues[id][
                                                          key
                                                        ]
                                                      : selectedTagValues[id][
                                                          key
                                                        ] &&
                                                        selectedTagValues[id][
                                                          key
                                                        ][0]
                                                    : isMulti
                                                    ? []
                                                    : ''
                                                const options = loading
                                                  ? []
                                                  : data
                                                      .filter((item) => {
                                                        return item.value
                                                      })
                                                      .map((item) => {
                                                        return {
                                                          value: item.id,
                                                          label: item.value,
                                                          value_type:
                                                            item.value_type
                                                        }
                                                      })
                                                return isDate ? (
                                                  <Box
                                                    sx={{ width: '250px' }}
                                                    className={
                                                      classes.multiSelectCheckBox
                                                    }
                                                  >
                                                    <DateTagSelector
                                                      isDisabled={isDisabled}
                                                      isMulti={isMulti}
                                                      onChange={(value) => {
                                                        onDateTagChange(
                                                          value,
                                                          id,
                                                          key,
                                                          type
                                                        )
                                                      }}
                                                      value={
                                                        isMulti
                                                          ? defaultValue
                                                          : [defaultValue]
                                                      }
                                                      placeholder={key}
                                                    />
                                                  </Box>
                                                ) : (
                                                  <Box
                                                    sx={{ width: '250px' }}
                                                    className={
                                                      classes.multiSelectCheckBox
                                                    }
                                                  >
                                                    <MultiSelectCheckBox
                                                      getDropdownButtonLabel={({
                                                        placeholderButtonLabel,
                                                        value
                                                      }) => {
                                                        if (!value) {
                                                          return placeholderButtonLabel
                                                        }
                                                        if (
                                                          Array.isArray(value)
                                                        ) {
                                                          if (
                                                            value.length === 0
                                                          ) {
                                                            return placeholderButtonLabel
                                                          }
                                                          return `${placeholderButtonLabel}: ${value.length}`
                                                        }
                                                        return isURL ? (
                                                          <Box
                                                            sx={{
                                                              whiteSpace:
                                                                'break-spaces',
                                                              wordBreak:
                                                                'break-all',
                                                              display: 'flex',
                                                              alignItems:
                                                                'center'
                                                            }}
                                                          >
                                                            {renderURL(
                                                              value.label,
                                                              true
                                                            )}
                                                          </Box>
                                                        ) : (
                                                          value.label
                                                        )
                                                      }}
                                                      formatOptionLabel={(
                                                        option
                                                      ) => {
                                                        const {
                                                          __isNew__ = false
                                                        } = option || {}
                                                        return !__isNew__ &&
                                                          isURL
                                                          ? renderURL(
                                                              option.label,
                                                              true
                                                            )
                                                          : option.label
                                                      }}
                                                      isDisabled={
                                                        isDisabled || loading
                                                      }
                                                      isSortOptions
                                                      isCreatable={
                                                        !isDocumentType &&
                                                        canCreateTags
                                                      }
                                                      isClearable={
                                                        !isDocumentType
                                                      }
                                                      id={key}
                                                      options={options}
                                                      value={defaultValue}
                                                      placeholderButtonLabel={
                                                        key
                                                      }
                                                      placeholder={key}
                                                      onBlurStateChange={(e) =>
                                                        handleMetaEdit(
                                                          e,
                                                          'tags',
                                                          id,
                                                          extension,
                                                          key,
                                                          isDocumentType
                                                        )
                                                      }
                                                      onChange={(value) => {
                                                        onChangeTags(
                                                          value,
                                                          id,
                                                          key,
                                                          isMulti,
                                                          indexUploadedFiles,
                                                          isDocumentType
                                                        )
                                                      }}
                                                      components={{
                                                        IndicatorsContainer: (
                                                          props
                                                        ) => {
                                                          const {
                                                            clearValue,
                                                            hasValue,
                                                            isDisabled,
                                                            selectProps
                                                          } = props
                                                          const {
                                                            isClearable
                                                          } = selectProps || {}
                                                          return (
                                                            hasValue &&
                                                            isClearable && (
                                                              <Box
                                                                x={{
                                                                  '& .MuiSvgIcon-root':
                                                                    {
                                                                      color:
                                                                        '#0645AD',
                                                                      fontSize:
                                                                        '18px'
                                                                    }
                                                                }}
                                                              >
                                                                <IconButton
                                                                  disableRipple
                                                                  onClick={
                                                                    clearValue
                                                                  }
                                                                  disabled={
                                                                    isDisabled
                                                                  }
                                                                >
                                                                  <ClearIcon />
                                                                </IconButton>
                                                              </Box>
                                                            )
                                                          )
                                                        }
                                                      }}
                                                      styles={{
                                                        indicatorsContainer: (
                                                          base
                                                        ) => ({
                                                          ...base,
                                                          color: '#0645AD'
                                                        }),
                                                        control: (base) => ({
                                                          ...base,
                                                          minHeight: '0',
                                                          margin: '5px'
                                                        }),
                                                        input: (base) => ({
                                                          ...base,
                                                          fontSize: '12px',
                                                          padding: '0px'
                                                        }),
                                                        valueContainer: (
                                                          base
                                                        ) => ({
                                                          ...base,
                                                          padding: '0px 8px'
                                                        }),
                                                        placeholder: (
                                                          base
                                                        ) => ({
                                                          ...base,
                                                          fontSize: '12px'
                                                        }),
                                                        noOptionsMessage: (
                                                          base
                                                        ) => ({
                                                          ...base,
                                                          fontSize: '12px',
                                                          color: '#0645AD'
                                                        }),
                                                        singleValue: (
                                                          base
                                                        ) => ({
                                                          ...base,
                                                          fontSize: '12px'
                                                        }),
                                                        multiValue: (base) => ({
                                                          ...base,
                                                          fontSize: '12px'
                                                        }),
                                                        loadingMessage: (
                                                          base
                                                        ) => ({
                                                          ...base,
                                                          fontSize: '12px'
                                                        })
                                                      }}
                                                      selectRef={selectRef}
                                                      ref={selectWrapperRef}
                                                      isLoading={loading}
                                                      onCreateOption={(
                                                        value
                                                      ) => {
                                                        setTagInputLoading({
                                                          ...tagInputLoading,
                                                          [key]: true
                                                        })
                                                        const callback = (
                                                          newOption
                                                        ) => {
                                                          handleOnAddNew(
                                                            newOption,
                                                            key,
                                                            isMulti
                                                          )
                                                        }
                                                        addNewValue(
                                                          value,
                                                          key,
                                                          type,
                                                          tagsCenterStateTags,
                                                          dispatch,
                                                          callback
                                                        )
                                                      }}
                                                      isMulti={isMulti}
                                                    />
                                                  </Box>
                                                )
                                              } else {
                                                return <></>
                                              }
                                            }
                                          )}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box sx={{ width: '100px' }}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          fontSize: '18px',
                                          color: 'black',
                                          flexWrap: 'wrap'
                                        }}
                                      >
                                        <Box sx={{ color: '#2948FF' }}>
                                          {percentage}%
                                        </Box>
                                        {!uploadComplete && (
                                          <Tooltip title="Remove File">
                                            <IconButton
                                              disableRipple
                                              className={uploadIconButton}
                                              onClick={() =>
                                                handleFileRemove(id)
                                              }
                                            >
                                              <RemoveIcon />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                        {!error ? (
                                          !uploadComplete &&
                                          uploadStarted &&
                                          (isPaused ? (
                                            <Tooltip title="Resume Upload">
                                              <IconButton
                                                disableRipple
                                                className={uploadIconButton}
                                                onClick={() =>
                                                  handlePauseResume(id)
                                                }
                                              >
                                                <PlayArrowIcon />
                                              </IconButton>
                                            </Tooltip>
                                          ) : (
                                            <Tooltip title="Pause Upload">
                                              <IconButton
                                                disableRipple
                                                className={uploadIconButton}
                                                onClick={() =>
                                                  handlePauseResume(id)
                                                }
                                              >
                                                <PauseIcon />
                                              </IconButton>
                                            </Tooltip>
                                          ))
                                        ) : (
                                          <Tooltip title="Retry Upload">
                                            <IconButton
                                              disableRipple
                                              className={uploadIconButton}
                                              onClick={() => handleRetry(id)}
                                            >
                                              <ReplayIcon />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                  {selected_collections?.length > 0 && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        gap: '5px',
                                        fontSize: '12px',
                                        marginTop: '15px',
                                        alignItems: 'center'
                                      }}
                                    >
                                      Uploads to:
                                      {selected_collections.map(
                                        (item, index) => (
                                          <Box
                                            key={index}
                                            sx={{
                                              background: '#DBEAFF',
                                              padding: '5px',
                                              borderRadius: '4px'
                                            }}
                                          >
                                            {' '}
                                            {item.name}{' '}
                                          </Box>
                                        )
                                      )}
                                    </Box>
                                  )}
                                  {error && (
                                    <Box
                                      sx={{ color: 'red', marginTop: '10px' }}
                                    >{`Upload Error: ${error}`}</Box>
                                  )}
                                </Box>
                              )
                            })}
                          </Box>
                        </Section>
                      </Container>
                    </Section>
                  </>
                )
              )}
            </Container>
          </Section>
          <SectionFixed
            sx={{
              paddingRight: '30px'
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {uploadedFiles?.length > 0 ? (
                <Box>
                  {uploadedCount} of {totalFiles} Completed (
                  {currentUploadPercentage || 0}%)
                </Box>
              ) : (
                <Box></Box>
              )}
              <Box>
                <Button
                  id="uploader-close"
                  onClick={() => handleForceCloseModel()}
                  variant="outlined"
                >
                  {viewState === 'completed' ? 'Close' : 'Cancel'}
                </Button>
              </Box>
            </Box>
          </SectionFixed>
        </Container>
      </Box>
    )
  }

  const renderAnalyticsUploader = () => {
    return (
      <Box className={outerContainer}>
        <Container>
          <SectionFixed>
            <Box className={topBar}>
              <Box>
                <Tooltip title="Minimize Uploader">
                  <IconButton
                    disableRipple
                    className={uploadIconButton}
                    onClick={() => handleCloseModelMinimize()}
                  >
                    <RemoveIcon sx={{ fontSize: '40px', color: 'black' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Minimize Uploader">
                  <IconButton
                    disableRipple
                    className={uploadIconButton}
                    onClick={() => handleForceCloseModel()}
                  >
                    <CrossIcon sx={{ fontSize: '40px', color: 'black' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </SectionFixed>
          <Section overFlow>
            <Container>
              {viewState === 'drop' ? (
                <Section>
                  <DropBox
                    onDrop={handleFileUpload}
                    acceptedFileType=".pdf"
                    placeHolderText1="Drop your analytics file here"
                  />
                </Section>
              ) : viewState === 'uploading' ? (
                <>
                  <SectionFixed>
                    {showAddFile ? (
                      <Box sx={{ width: '100%', height: '350px' }}>
                        <DropBox
                          onDrop={handleFileUpload}
                          acceptedFileType=".pdf"
                          placeHolderText1="Drop your analytics file here"
                        />
                      </Box>
                    ) : (
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: Loader1
                        }}
                        height={350}
                      />
                    )}
                    {!showAddFile && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '10px'
                        }}
                      >
                        <Box sx={{ width: '100%', textAlign: 'end' }}>
                          <Button
                            variant="outlined"
                            onClick={() => setShowAddFile(true)}
                          >
                            Add more files
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </SectionFixed>
                  <Section overFlow>
                    <Box>
                      {uploadedFiles.map((file, indexUploadedFiles) => {
                        const {
                          error,
                          progress,
                          isPaused,
                          name,
                          id,
                          meta = {},
                          extension
                        } = file
                        const { uploadStarted, uploadComplete, percentage } =
                          progress
                        const nameWithoutExtension = name
                          .split('.')
                          .slice(0, -1)
                          .join('.')
                        return (
                          <Box
                            key={id}
                            sx={{
                              padding: '10px',
                              borderBottom: '1px solid rgba(224, 224, 224, 1)'
                            }}
                          >
                            <Box
                              className={tableCellContent}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                              }}
                            >
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  gap: '15px',
                                  alignItems: 'center'
                                }}
                              >
                                <Box>
                                  <Image src={PDFICON} />
                                </Box>
                                <TextField
                                  sx={{
                                    width: '100%',
                                    maxWidth: '400px',
                                    '& input': {
                                      padding: '5px 14px 6px 14px'
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        borderColor: '#E5E5E5'
                                      },
                                      '&:hover fieldset': {
                                        borderColor: '#E5E5E5'
                                      }
                                    }
                                  }}
                                  error={nameError[id]}
                                  helperText={
                                    nameError[id] && 'Name cannot be empty'
                                  }
                                  defaultValue={nameWithoutExtension}
                                  placeholder="Enter File Name"
                                  onChange={(e) =>
                                    handleNameChange(
                                      e,
                                      id,
                                      extension,
                                      indexUploadedFiles
                                    )
                                  }
                                  onBlur={(e) =>
                                    handleMetaEdit(e, 'name', id, extension)
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        .{extension}
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Box>
                              {/* <Box className={uploadFileName}>{name} </Box> */}
                              <Box className={controlButton}>
                                <Tooltip title="Remove File">
                                  <IconButton
                                    disableRipple
                                    className={uploadIconButton}
                                    onClick={() => handleFileRemove(id)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Tooltip>
                                {!error ? (
                                  !uploadComplete &&
                                  uploadStarted &&
                                  (isPaused ? (
                                    <Tooltip title="Resume Upload">
                                      <IconButton
                                        disableRipple
                                        className={uploadIconButton}
                                        onClick={() => handlePauseResume(id)}
                                      >
                                        <PlayArrowIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Pause Upload">
                                      <IconButton
                                        disableRipple
                                        className={uploadIconButton}
                                        onClick={() => handlePauseResume(id)}
                                      >
                                        <PauseIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ))
                                ) : (
                                  <Tooltip title="Retry Upload">
                                    <IconButton
                                      disableRipple
                                      className={uploadIconButton}
                                      onClick={() => handleRetry(id)}
                                    >
                                      <ReplayIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                            </Box>
                            <Box className={progressBar}>
                              <Box className={progressContent}>
                                <LinearProgressWithLabel
                                  variant="determinate"
                                  value={percentage}
                                  file={file}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )
                      })}
                    </Box>
                  </Section>
                </>
              ) : (
                viewState === 'completed' && (
                  <>
                    <Container>
                      <Section overFlow>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Lottie
                            options={{
                              loop: true,
                              autoplay: true,
                              animationData: Loader2
                            }}
                            height={315}
                          />
                          <Box sx={{ textAlign: 'center', maxWidth: '500px' }}>
                            Your document is being processed. You can close the
                            window. We'll notify you once it's done.
                          </Box>
                          <Button
                            variant="outlined"
                            sx={{ marginTop: '20px' }}
                            onClick={() => {
                              setShowAddFile(true)
                              setCurrentUploadPercentage('')
                            }}
                          >
                            Add more files
                          </Button>
                        </Box>
                      </Section>
                    </Container>
                  </>
                )
              )}
            </Container>
          </Section>
          <SectionFixed>
            <Box className={UploadButton}>
              <Button
                id="uploader-close"
                onClick={() => handleForceCloseModel()}
                variant="outlined"
              >
                {viewState === 'completed' ? 'Close' : 'Cancel'}
              </Button>
            </Box>
          </SectionFixed>
        </Container>
      </Box>
    )
  }

  return (
    <>
      <Modal
        open={fullScreen}
        disableEnforceFocus
        onClose={() => {
          handleCloseModelMinimize()
        }}
      >
        {loading ? (
          <Box className={outerContainer}>
            <Loader loading={loading} caption={''} flex />
          </Box>
        ) : ['resource'].includes(uploaderType) ? (
          renderResourceUploader()
        ) : ['analytics', 'rfx'].includes(uploaderType) ? (
          renderAnalyticsUploader()
        ) : (
          <></>
        )}
      </Modal>
      {!fullScreen && (
        <Card
          variant="outlined"
          onClick={() => showFullScreen(true)}
          className={cardContainer}
        >
          <CardContent className={cardContentContainer}>
            <Box>Uploading Files</Box>
            <Box>
              {uploadedFiles?.length > 0 ? (
                <Box>
                  {uploadedCount} of {totalFiles} Completed (
                  {currentUploadPercentage || 0}%)
                </Box>
              ) : (
                <Box></Box>
              )}
            </Box>
            {/* {currentUploadPercentage && parseInt(currentUploadPercentage) > 0 && (
              <Box className={progressBar}>
                <
              </Box>
            )} */}
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default UploadModal
