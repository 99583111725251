import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    borderBottom: '1px solid #E5E5E5',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  infoContainer: {
    height: '100%',
    borderLeft: '1px solid #E5E5E5',
    display: 'flex',
    flexDirection: 'column'
  },
  infoWrapper: {
    height: 'calc(100% - 8px)',
    marginTop: '8px'
  },
  infoTitle: {
    padding: '15px !important',
    borderBottom: '1px solid #E5E5E5',
    fontFamily: 'PoppinsRegular',
    fontWeight: '600',
    fontSize: '16px',
    color: theme.palette.primary.main
  },
  infoData: {
    overflow: 'auto',
    padding: 15
  },
  keyclass: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#A2A2A2',
    fontSize: '15px',
    width: '120px'
  },
  dataclass: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#000000',
    fontSize: '15px',
    wordBreak: 'break-all'
  },
  dataContainer: {
    display: 'flex',
    margin: '10px 0px'
  },
  pdfTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px'
  },
  icon: {
    height: '16px',
    width: '16px'
  },
  iconButton: {
    float: 'left'
  },
  pdfContainer: {
    height: '100%',
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  page: {
    height: '100%'
  },
  customResizeHandler: {
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'ew-resize',
    boxSizing: 'border-box',
    padding: '0 5px 5px 0',

    '&:hover': {
      background: '#cdcdcd !important'
    }
  },
  containerDiv: {
    height: '100%',
    width: '100%',
    display: 'flex'
  },
  sidePaneContainer: {
    height: '100%',
    display: 'flex'
  },
  mainContainer: {
    height: '100%',
    borderRight: '2px solid #EEEEEE'
  },
  sidepane: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderLeft: '1px solid #E5E5E5',
    padding: '5px',
    justifyContent: 'space-between'
  },
  sidepaneIconGroup: {
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  editableHeader: {
    padding: '15px 10px'
  },
  closeIconWrapper: {
    padding: '5px',
    borderLeft: '1px solid #E5E5E5',
    display: 'flex',
    justifyContent: 'center'
  },
  sidepaneWrapper: {
    padding: '10px',
    display: 'flex',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    justifyContent: 'center'
  },
  inputWrapper: {
    flexDirection: 'column',
    width: '100%',
    padding: '10px'
  },
  textContainer: {
    margin: '10px 0px',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  textInput: {
    width: '100%'
  },
  readOnlyContent: {
    color: '#9b9696'
  },
  collectionName: {
    color: '#797979',
    fontSize: '12px'
  }
}))

export { useStyles }
