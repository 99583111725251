import {
  CLEAR_SEARCH_HISTORY,
  ADD_RECENT_SEARCH,
  FETCH_SEARCH_HISTORY
} from '../../actionTypes'
import _, { cloneDeep } from 'lodash'

const initialState = {
  recentSearch: null
}

function searchReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ADD_RECENT_SEARCH: {
      const newRecent = cloneDeep(state.recentSearch)
      newRecent?.unshift(payload)
      return {
        ...state,
        recentSearch: newRecent
      }
    }
    case CLEAR_SEARCH_HISTORY: {
      const newData = cloneDeep(state.recentSearch)
      const index = newData.findIndex((element) => element.id === payload.id)
      if (index > -1) {
        newData.splice(index, 1)
      }
      return {
        ...state,
        recentSearch: newData
      }
    }
    case FETCH_SEARCH_HISTORY: {
      return {
        ...state,
        recentSearch: payload
      }
    }
    default:
      return state
  }
}

export default searchReducer
