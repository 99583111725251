import * as React from 'react'
import Button from '../Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'

const SelectButton = (props) => {
  const {
    options = [],
    splitButton = false,
    handleButtonClick = () => {},
    handleOptionClick = () => {},
    buttonProps = {},
    menuProps = {},
    buttonGroupProps = {},
    splitArrowProps = {},
    defaultSelectedIndex = 0,
    title = '',
    id = '',
    tooltip = '',
    disabled = false
  } = props
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(defaultSelectedIndex)

  const handleClick = () => {
    if (splitButton) {
      handleButtonClick(options[selectedIndex], id)
    } else {
      handleToggle()
    }
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
    handleOptionClick(options[index], index, id)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Tooltip title={tooltip}>
        <ButtonGroup
          disabled={disabled}
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
          {...buttonGroupProps}
        >
          <Button onClick={handleClick} {...buttonProps}>
            {title && (
              <>
                {title}
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ margin: '5px' }}
                />
              </>
            )}
            {options[selectedIndex]?.label || options[selectedIndex]}
          </Button>
          {splitButton && (
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              {...splitArrowProps}
            >
              <ArrowDropDownIcon />
            </Button>
          )}
        </ButtonGroup>
      </Tooltip>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        {...menuProps}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option?.value || option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option?.label || option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}

export default SelectButton
