export const PageSize = 10
export const PageSizePeople = 20
export const ProposalPathPerfix = 'raw-pdfs/'
export const getDashboardImage = () => {
  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  const rndInt = randomIntFromInterval(0, 21)
  return `https://assets.joist.ai/DashboardBackground/${rndInt}.jpg`
}
export const userRoleSuperUser = 'superuser'
export const userRoleUser = 'user'
export const userRoleAdmin = 'admin'
export const userRoleAnnotator = 'annotator'
export const userRoleViewer = 'viewer'
export const PAGE_TYPE_KEYS = [
  'people_resume',
  'project_resume',
  'cover_letter',
  'organization_chart_related_data',
  'table_content',
  'project_schedule',
  'technical_write_up',
  'forms',
  'licenses_and_certifications',
  'others'
]
export const PAGE_SUBTYPE_KEYS = [
  'single_resume_no_face',
  'single_resume_single_face',
  'single_resume_multiple_face',
  'multiple_resume_no_face',
  'multiple_resume_single_face',
  'multiple_resume_multiple_face'
]

export const defaultChatInstructions = {
  disclaimer: 'Chat can make mistakes. Consider checking important information.'
}

export const defaultDocumentTypes = [
  'Article',
  'Award Submission',
  'Case Study',
  'Client Testimonial',
  'Flyer',
  'Leave-Behind',
  'Newsletter',
  'Presentation',
  'Proposal',
  'Qualification',
  'RFQ/P',
  'Report',
  'Resume',
  'Others',
  'Image',
  'Video'
]
