import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  cancelButton: {
    background: 'white !important',
    color: 'black',
    fontFamily: 'PoppinsRegular',
    fontWeight: '600',
    textTransform: 'none',
    borderRadius: '6px'
  },
  container: {
    width: '100%',
    maxWidth: '500px',
    display: 'inline',
    justifyContent: 'center'
  },
  editWrapper: {
    border: 'solid 1px #E5E5E5',
    padding: '5px',
    overflowY: 'auto',
    maxHeight: '300px'
  },
  inputLabelText: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  libraryEditor: {
    width: '99%',
    height: '300px',
    borderColor: '#EEEEEE',
    borderStyle: 'solid',
    borderWidth: '0px 1px 0px 1px'
  },
  inputLabel: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#000000',
    padding: 0,
    margin: '10px 0px'
  }
})

export { useStyles }
