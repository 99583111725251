import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ToasterAlert from '../../components/ToasterAlert'
import { isEmpty } from 'lodash'

const ToastContainer = () => {
  const commonState = useSelector((state) => state?.common ?? {})
  const { toaster } = commonState
  const [toasterData, setToasterData] = useState(toaster)
  const { message = '', show = false, type = 'success' } = toasterData

  useEffect(() => {
    if (!isEmpty(toaster)) {
      const data = {
        message: toaster.message,
        show: toaster.show,
        type: toaster.type
      }
      setToasterData(data)
      toaster.type !== 'error' &&
        setTimeout(() => {
          handleClose()
        }, [3000])
    }
  }, [toaster])

  const handleClose = () => {
    const closeData = {
      message: '',
      show: false,
      type: 'success'
    }
    setToasterData(closeData)
  }

  return (
    <div>
      {show && (
        <ToasterAlert
          message={message}
          show={show}
          type={type}
          handleClose={handleClose}
        />
      )}
    </div>
  )
}

export default ToastContainer
