import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  collectionsList: {
    padding: '20px',
    color: '#797979'
  },

  listWrapper: {
    padding: '0px',
    width: '100%',
    '& .Mui-selected': {
      backgroundColor: '#DBEAFF'
    }
  },
  listButtonWrapper: {
    padding: '0px'
  },
  listTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    color: '#18181b'
  },
  listText: {
    marginLeft: '5px',
    fontWeight: 500,
    width: '100%',
    wordBreak: 'break-word',
    fontSize: '15px',
    flex: '1',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical'
  },
  collectionTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  collectionTitle: {
    fontSize: '16px',
    fontWeight: '500'
  },
  iconButton: {
    padding: '0px'
  },
  iconEmpty: {
    fontSize: '8px !important',
    color: '#797979',
    width: '15px !important'
  },
  icon: {
    fontSize: '15px !important',
    color: '#797979 !important'
  },
  collectionListWrapper: {
    border: '1px solid #E5E5E5',
    maxHeight: '500px',
    overflow: 'auto'
  },
  emptyIcon: {
    width: '24px',
    height: '24px'
  },
  nestedCollection: {
    paddingLeft: '10px'
  }
}))

export { useStyles }
