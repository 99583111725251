import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  pageTitle: {
    fontWeight: 'bold',
    color: '#949494',
    fontSize: '16px',
    marginBottom: '8px !important',
    padding: 0,
    paddingLeft: '5px'
  }
})

export { useStyles }
