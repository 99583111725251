export const exampleQuestions = {
  'Organization and Structure':
    'Is the proposal logically organized with a clear beginning, middle, and end? Are there clearly defined sections such as the executive summary, project description, implementation plan, budget, etc.?',
  'Clarity and Coherence':
    'Does the proposal flow smoothly from one section to another? Is each point presented in a clear and concise manner? Does the author use clear, professional, and straightforward language?',
  'Executive Summary':
    'Does the executive summary effectively encapsulate the main points of the proposal? Does it provide a compelling snapshot that encourages further reading?',
  'Detail and Depth':
    "Are the explanations, descriptions, and discussions thorough? Do they provide enough detail to understand the proposal's full scope and implications?",
  Consistency:
    'Is the tone, language, and format consistent throughout the document? Are the same terms, phrases, and abbreviations used consistently to refer to the same things?',
  Proofreading:
    'Has the proposal been thoroughly proofread? Are there any typographical, grammatical, or punctuation errors that could distract from the content?',
  Comprehensiveness:
    'Does the proposal cover all expected sections such as company background, technical approach, project management, risks and mitigation, timeline, staffing, costs, and quality assurance?',
  'Reader Engagement':
    "Does the proposal keep the reader's interest throughout? Is it engaging and compelling?",
  Persuasiveness:
    'Is the proposal persuasive? Does it present compelling arguments and evidence to support its case?',
  'Action Oriented':
    'Does the proposal end with a clear call to action, summarizing why the proposal should be accepted and what the next steps should be?'
}
