import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  closeIcon: {
    paddingLeft: '6px'
  },
  alertIcon: {
    top: 4,
    right: 4,
    '& .MuiAlert-icon': {
      margin: '3px',
      paddingTop: 0,
      alignSelf: 'center'
    }
  },
  spinnerContainer: {
    margin: '7px 0px'
  }
})

export { useStyles }
