import React, { useRef, useEffect, useState } from 'react'
import { Container, SectionFixed, Section } from '../../components/Container'
import DashboardSearch from '../../containers/DashboardSearch'
import { useSelector, useDispatch } from 'react-redux'
import { getSearchHistory } from '../../store/Search/Actions'
import { Box } from '@mui/system'
import { useStyles } from './styles'
import { useColor } from '../../ThemeContext'
import { fetchUser } from '../../store/api'
import { getSignedUrl } from '../../utils/AWS'

const BackgroundWrapper = ({ imgURL, children }) => {
  const [imageSrc, setImageSrc] = useState(null)

  useEffect(() => {
    const fetchAndCacheImage = async (url) => {
      try {
        const cache = await caches.open('joist-img-cache')
        const cachedResponse = await cache.match(url)

        if (cachedResponse) {
          const blob = await cachedResponse.blob()
          setImageSrc(URL.createObjectURL(blob))
        } else {
          const response = await fetch(url)
          const blob = await response.blob()
          setImageSrc(URL.createObjectURL(blob))
          cache.put(url, response.clone())
        }
      } catch (error) {
        console.error('Failed to fetch and cache image:', error)
      }
    }

    fetchAndCacheImage(imgURL)
  }, [imgURL])

  const classes = useStyles()
  const { backgroundWrapper } = classes

  return (
    <Box
      className={backgroundWrapper}
      sx={{ backgroundImage: `url(${imageSrc})` }}
    >
      {children}
    </Box>
  )
}

const Dashboard = (props) => {
  const searchState = useSelector((state) => state.search)
  const { recentSearch } = searchState
  const dispatch = useDispatch()
  const { imgURL, s3Obj } = props

  const { backgroundWallpaper } = useColor()
  const newImgURL =
    backgroundWallpaper === null ? null : backgroundWallpaper || imgURL

  useEffect(() => {
    if (!recentSearch) {
      dispatch(getSearchHistory())
    }
  }, [])

  return (
    <Container>
      <Section>
        <BackgroundWrapper imgURL={newImgURL}>
          <DashboardSearch s3Obj={s3Obj} />
        </BackgroundWrapper>
      </Section>
    </Container>
  )
}

export default Dashboard
