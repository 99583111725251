import React, { useState } from 'react'
import {
  Tooltip,
  Checkbox,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Skeleton
} from '@mui/material'
import { useStyles } from './styles'
import { Container, Section, SectionFixed } from '../../components'
import { ReactComponent as SortUp } from '../../assets/images/SortUp.svg'
import { ReactComponent as SortDown } from '../../assets/images/SortDown.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import moment from 'moment'
import clsx from 'clsx'
import Pagination from '../Pagination'

const TableLoader = ({ pageSize, headCell = [] }) => {
  const classes = useStyles()

  return (
    <Table
      stickyHeader
      id="table-drawer-container"
      classes={classes.tableStyle}
      aria-labelledby="tableTitle"
    >
      <TableHead>
        <TableRow>
          {headCell.map((cell, index) => {
            return (
              <TableCell
                key={index}
                className={classes.tableHeader}
                sx={{ width: headCell.width }}
              >
                <Skeleton variant="text" />
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody classes={classes.tablebody}>
        {Array.from(new Array(pageSize)).map((_, index) => {
          return (
            <TableRow key={index} className={classes.tablerow}>
              {headCell.map((cell, index) => {
                return (
                  <TableCell key={index}>
                    <Skeleton variant="text" />
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

function descendingComparator(a, b, orderBy) {
  const orderA = a[orderBy] || ''
  const orderB = b[orderBy] || ''
  if (orderB && moment(orderB).isValid() && moment(orderA).isValid()) {
    if (moment(orderB) < moment(orderA)) {
      return -1
    } else {
      return 1
    }
  }
  if (orderB < orderA) {
    return -1
  }
  if (orderB > orderA) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array = [], comparator) {
  const stabilizedThis = array.map((el, indexStableSort) => [
    el,
    indexStableSort
  ])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export default function EnhancedTable(props) {
  const {
    rows = [],
    headCells = [],
    sortOrder = {
      defaultSortElement: '',
      defaultSortOrder: 'asc'
    },
    pageSize = 10,
    page,
    handlePageChange = () => {},
    renderMenu = null,
    enableMultiSelect = false,
    selectedRows = [],
    setSelectedRows = () => {},
    enableSort = true,
    enablePaginate = false,
    paginationConfig = {},
    emptyMessage = '',
    loading = false,
    activeRowIndex = null
  } = props
  const classes = useStyles()

  const { defaultSortElement, defaultSortOrder } = sortOrder
  const [order, setOrder] = useState(defaultSortOrder)
  const [orderBy, setOrderBy] = useState(defaultSortElement)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler = (property) => (event) => {
    if (enableSort) {
      handleRequestSort(event, property)
    }
  }

  const GetSortIcon = ({ sort }) => {
    return enableSort && sort ? (
      <div className={classes.sortLabel}>
        <SvgIcon
          className={sort === 'asc' ? classes.sortIconActive : classes.sortIcon}
        >
          <SortUp />
        </SvgIcon>
        <SvgIcon
          className={
            sort === 'desc' ? classes.sortIconActive : classes.sortIcon
          }
        >
          <SortDown />
        </SvgIcon>
      </div>
    ) : null
  }

  const currentPageRowData = enablePaginate
    ? stableSort(rows, getComparator(order, orderBy)).slice(
        page * pageSize,
        page * pageSize + pageSize
      )
    : stableSort(rows, getComparator(order, orderBy))

  return (
    <Container>
      <Section overFlow>
        {loading ? (
          <TableLoader pageSize={pageSize} headCell={headCells} />
        ) : (
          <Table
            stickyHeader
            id="table-drawer-container"
            classes={classes.tableStyle}
            aria-labelledby="tableTitle"
          >
            <TableHead>
              <TableRow>
                {enableMultiSelect && (
                  <TableCell
                    className={clsx(
                      classes.tableHeader,
                      classes.multiSelectWrapper
                    )}
                    style={{ paddingLeft: '4x' }}
                  >
                    <Checkbox
                      color="primary"
                      checked={selectedRows.length === rows.length}
                      onChange={(e) => {
                        if (e.target.checked && selectedRows.length === 0) {
                          setSelectedRows(rows.filter((row) => !row.rowLoading))
                        } else {
                          setSelectedRows([])
                        }
                      }}
                      size="small"
                      indeterminate={selectedRows.length > 0}
                    />
                  </TableCell>
                )}
                {headCells.map((headCell, indexTableRow) => {
                  const { id, sort } = headCell
                  return (
                    <TableCell
                      className={classes.tableHeader}
                      sx={{ width: headCell.width }}
                      key={indexTableRow}
                      sortDirection={orderBy === id ? order : sort}
                    >
                      <TableSortLabel
                        id={`table-sort-icon-${id}`}
                        active={false}
                        IconComponent={() => (
                          <GetSortIcon sort={orderBy === id ? order : sort} />
                        )}
                        disabled={!enableSort || rows.length === 0}
                        onClick={createSortHandler(id)}
                        className={classes.tablesortlabel}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                {renderMenu && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody classes={classes.tablebody}>
              {currentPageRowData.map((row, indexStableSort) => {
                const { rowLoading = false } = row
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={indexStableSort}
                    active={activeRowIndex === indexStableSort}
                    className={clsx(
                      classes.tablerow,
                      rowLoading ? classes.tablerowDisabled : '',
                      activeRowIndex === indexStableSort
                        ? classes.tablerowActiveRow
                        : ''
                    )}
                  >
                    {enableMultiSelect && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={rowLoading}
                          color="primary"
                          checked={
                            selectedRows.findIndex(
                              (selectedRow) => selectedRow.id === row.id
                            ) > -1
                          }
                          onChange={() => {
                            const selectedIndex = selectedRows.findIndex(
                              (selectedRow) => selectedRow.id === row.id
                            )
                            let newSelected = []
                            if (selectedIndex === -1) {
                              newSelected = newSelected.concat(
                                selectedRows,
                                row
                              )
                            } else {
                              selectedRows.splice(selectedIndex, 1)
                              newSelected = [...selectedRows]
                            }
                            setSelectedRows(newSelected)
                          }}
                          size="small"
                        />
                      </TableCell>
                    )}
                    {headCells.map((cell, indexKey) => {
                      const {
                        id: key,
                        label,
                        tooltip,
                        onClick,
                        onDoubleClick,
                        format = (e) => {
                          return e
                        }
                      } = cell
                      return (
                        <>
                          <Tooltip
                            enterDelay={1500}
                            title={tooltip || label}
                            placement="bottom-start"
                            arrow
                            classes={classes.tooltip}
                          >
                            <TableCell
                              id={`table-row-${key}`}
                              component={'span'}
                              className={
                                !rowLoading && (onClick || onDoubleClick)
                                  ? classes.cellPointer
                                  : classes.cellText
                              }
                              sx={{ padding: '10px', wordBreak: 'break-word' }}
                              onClick={() => {
                                !rowLoading &&
                                  onClick &&
                                  onClick(row.index, row)
                              }}
                              onDoubleClick={() => {
                                !rowLoading &&
                                  onDoubleClick &&
                                  onDoubleClick(row.index, row)
                              }}
                              key={indexKey}
                            >
                              <span
                                style={{
                                  displa: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography className="tableCell">
                                  {format(row[key])}
                                </Typography>
                              </span>
                            </TableCell>
                          </Tooltip>
                        </>
                      )
                    })}
                    {renderMenu && (
                      <TableCell
                        id={`table-row-menu`}
                        component={'span'}
                        className={classes.cellText}
                      >
                        {renderMenu(row, indexStableSort)}
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
              {emptyMessage && currentPageRowData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={headCells.length + 1}>
                    {emptyMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </Section>
      {enablePaginate && (
        <SectionFixed>
          <Pagination
            paginationConfig={paginationConfig}
            rows={rows.length}
            pageSize={pageSize}
            page={page + 1}
            handleChangePage={handlePageChange}
            startIndex={page * pageSize + 1}
            endIndex={page * pageSize + currentPageRowData.length}
            fullCount={rows.length}
            loading={loading}
          />
        </SectionFixed>
      )}
    </Container>
  )
}
