import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  defaultText: {
    fontFamily: 'PoppinsRegular',
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    alignItems: 'center',
    letterSpacing: '0.01em'
  }
})

export { useStyles }
