import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  fileViwerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%'
  }
})

export { useStyles }
