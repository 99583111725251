import * as React from 'react'
import Box from '@mui/material/Box'
import { useStyles } from './styles'

export default function Container(props) {
  const { className } = props
  const classes = useStyles()
  const { sectionfixed } = classes

  return (
    <Box {...props} component="main" className={`${sectionfixed} ${className}`}>
      {props.children}
    </Box>
  )
}
