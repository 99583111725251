import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useStyles } from './styles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Highlighted from '../../utils/Highlight/Highlight'
import TextLineLimiter from '../../components/TextLineLimiter'
import { stripHTML } from '../../utils/CopyHTML'
import Loader from '../../components/Loader'

const LibraryVariations = (props) => {
  const { similarities = [], current_document = {} } = props
  const classes = useStyles()
  const {
    container,
    proposalTitle,
    proposalTitlePage,
    emptyVariationMessage,
    variationCardWrapper,
    variationTitle,
    variationContainer,
    proposalContainer,
    variationContent,
    accordianTitle
  } = classes
  const [expanded, setExpanded] = useState({
    variations: true
  })
  const handleAccordianClick = (key) => {
    setExpanded({ ...expanded, [key]: !expanded[key] })
  }

  const { metadata = {}, source_prposal_name = '' } = current_document
  const {
    pageIndex: srcPageIndex = 0,
    proposal_id: srcProposalId,
    selectedTitle = ''
  } = metadata || {}

  const openProposal = (proposalId, page = 0) => {
    if (proposalId) {
      const path = `/view/pdf/${proposalId}/${page}`
      window.open(path)
    }
  }
  if (!similarities) {
    return <Loader loading={true} caption={''} flex />
  }

  if (similarities && similarities.length === 0) {
    return <div className={emptyVariationMessage}> No variations found </div>
  }

  return (
    <Box className={container}>
      {source_prposal_name && (
        <>
          <Box className={variationTitle}>Source</Box>
          <Box
            className={proposalTitle}
            onClick={() => openProposal(srcProposalId, srcPageIndex)}
          >
            {selectedTitle && (
              <Highlighted text={selectedTitle} keepFormatting={false} />
            )}
            {source_prposal_name}
            {srcPageIndex && (
              <span className={proposalTitlePage}>(Page {srcPageIndex})</span>
            )}
          </Box>
        </>
      )}
      <Box className={variationContainer}>
        {similarities.map((val, i) => {
          const { proposal_id, page_number, data, proposal_name = '' } = val
          return (
            <Box className={proposalContainer} key={i}>
              <Box>
                <Box
                  className={variationTitle}
                  onClick={() => openProposal(proposal_id, page_number)}
                >
                  <TextLineLimiter content={stripHTML(data?.title)} limit={3} />
                  <Box>
                    {page_number && proposal_name ? (
                      <span className={proposalTitlePage}>
                        (Page {page_number}) {proposal_name}
                      </span>
                    ) : null}
                  </Box>
                </Box>
                <Box className={variationContent}>
                  <TextLineLimiter
                    content={stripHTML(data?.content)}
                    limit={3}
                  />
                </Box>
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default LibraryVariations
