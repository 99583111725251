import { UPDATE_VALUE_TAG, UPDATE_VALUE_MULTI_TAG } from '../../actionTypes'
import { postTagCenterValue, addMultiTagCenterValue } from '../../api'
import { toast } from 'react-toastify'

const updateValue = (req, callback) => async (dispatch) => {
  const { status } = req
  dispatch({
    type: UPDATE_VALUE_TAG,
    payload: { ...req }
  })
  const res = await postTagCenterValue(req)
  if (res.status === 200) {
    if (status === 'delete') {
      dispatch({
        type: UPDATE_VALUE_TAG,
        payload: { ...req, synced: true },
        callback
      })
    } else {
      dispatch({
        type: UPDATE_VALUE_TAG,
        payload: { ...req, synced: true, status: 'statusUpdate' },
        callback
      })
    }
  } else {
    toast.error(
      res?.response?.data?.error || 'Something went wrong updaing tags'
    )
    if (status === 'update') {
      const { value, oldValue } = req
      dispatch({
        type: UPDATE_VALUE_TAG,
        payload: { ...req, value: oldValue, oldValue: value, synced: true },
        callback
      })
    } else if (status === 'delete') {
      dispatch({
        type: UPDATE_VALUE_TAG,
        payload: { ...req, synced: true, status: 'statusUpdate' },
        callback
      })
    }
  }
}

const addMultiValue = (req, callback) => async (dispatch) => {
  const { tags } = req
  dispatch({
    type: UPDATE_VALUE_MULTI_TAG,
    payload: { tags, status: 'create' }
  })
  const res = await addMultiTagCenterValue(req)
  if (res.status === 200) {
    const { warnings_ids = [], warnings = [] } = res.data
    if (warnings_ids.length > 0) {
      toast.warn(
        `Some values are already present against the key. ${warnings.join(
          ', '
        )}`
      )
    }
    const tagsAdd = tags.filter((tag) => !warnings_ids.includes(tag.id))
    const tagsDelete = tags.filter((tag) => warnings_ids.includes(tag.id))
    dispatch({
      type: UPDATE_VALUE_MULTI_TAG,
      payload: { tags: tagsAdd, status: 'statusUpdate' },
      callback
    })
    dispatch({
      type: UPDATE_VALUE_MULTI_TAG,
      payload: { tags: tagsDelete, status: 'delete' },
      callback
    })
  } else {
    toast.error(
      res?.response?.data?.error || 'Something went wrong creating tags'
    )
    dispatch({
      type: UPDATE_VALUE_MULTI_TAG,
      payload: { tags, status: 'delete' },
      callback
    })
  }
}

export { addMultiValue }
export default updateValue
