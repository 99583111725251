import {
  CREATE_LIBRARY_DOCUMENT,
  CLEAR_LIBRARY_DOCUMENT,
  CREATE_LIBRARY_FROM_EDITOR,
  TOASTER_ALERT
} from '../../../actionTypes'
import { addToLibrary } from '../../../api'
import { toast } from 'react-toastify'
import { ROUTES } from '../../../../config/routes'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../../../config/eventMapping'
import mixpanelEvents from '../../../../config/mixpanelEvents'

const createLibraryDocument = (data) => async (dispatch) => {
  dispatch({
    type: CLEAR_LIBRARY_DOCUMENT
  })
  const response = await addToLibrary(data)
  if (response.status === 200) {
    dispatch({
      type: CREATE_LIBRARY_DOCUMENT,
      payload: response.data
    })
  }
}

const clearLibraryDocument =
  (callback = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: CLEAR_LIBRARY_DOCUMENT,
      callback
    })
  }

const createLibraryDocumentEditor = (data) => async (dispatch) => {
  const response = await addToLibrary(data)
  if (response.status === 200) {
    trackEvent(mixpanelEvents.KNOWLEDGE_CONTENT_CREATED, 'SUCCESS', {}, {})
    dispatch({
      type: TOASTER_ALERT,
      payload: { message: 'Added to collection', show: true, type: 'success' }
    })
  } else {
    dispatch({
      type: TOASTER_ALERT,
      payload: {
        message: 'Failed to add collection',
        show: true,
        type: 'error'
      }
    })
  }
  dispatch({
    type: CREATE_LIBRARY_FROM_EDITOR,
    payload: response.data
  })
}

export {
  createLibraryDocument,
  clearLibraryDocument,
  createLibraryDocumentEditor
}
