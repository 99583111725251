import { useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  Checkbox,
  List,
  ListItem,
  Skeleton,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { useStyles } from './styles'
import { Button, MenuButton } from '../../components'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { getCollectionChildren } from '../../store/Resource/Action'
import * as MUIIcons from '@mui/icons-material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import StopIcon from '@mui/icons-material/Stop'

const ResourceAddToCollection = (props) => {
  const {
    isOpen = false,
    handleClose = () => {},
    currentCollectionId = null,
    actionButtons = [],
    headerText = 'Add to Collection',
    prevSelectedCollections = null,
    selectSubCollections = false,
    singleSelect = false
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const [selectedCollections, setSelectedCollections] = useState([])
  const [expanded, setExpanded] = useState({})

  useEffect(() => {
    if (prevSelectedCollections) {
      setSelectedCollections(prevSelectedCollections)
    }
  }, [prevSelectedCollections, isOpen])

  const collections = useSelector((state) => state.resource?.collections)

  useEffect(() => {
    if (currentCollectionId) {
      const newExpanded = { ...expanded }
      const path = findPathToChild(currentCollectionId, collections)
      if (path) {
        for (const item of path) {
          if (item.id !== currentCollectionId) {
            newExpanded[item?.id] = true
          }
        }
      }
      setExpanded(newExpanded)
    } else {
      setExpanded({})
    }
  }, [currentCollectionId])

  const handleSelect = (item) => {
    const isSelected = selectedCollections.some((obj) => obj.id === item.id)
    if (singleSelect) {
      setSelectedCollections([
        {
          id: item.id,
          name: item.name,
          label: item.name,
          is_public: item.is_public
        }
      ])
    } else {
      if (isSelected) {
        setSelectedCollections(
          selectedCollections.filter((obj) => obj.id !== item.id)
        )
      } else {
        setSelectedCollections([
          ...selectedCollections,
          {
            id: item.id,
            name: item.name,
            label: item.name,
            is_public: item.is_public
          }
        ])
      }
    }
  }

  const findPathToChild = (id, nestedObject) => {
    function recursiveFindPath(nodes, currentPath) {
      for (const node of nodes) {
        const newPath = [
          ...currentPath,
          {
            name: node.name,
            id: node.id
          }
        ]

        if (node.id === id) {
          return newPath
        }

        if (node.children && node.children.length > 0) {
          const foundPath = recursiveFindPath(node.children, newPath)
          if (foundPath) {
            return foundPath
          }
        }
      }

      return null
    }
    const result = recursiveFindPath(nestedObject, [])
    return result || null
  }

  const closeModel = () => {
    handleClose()
    setTimeout(() => {
      setSelectedCollections([])
      setExpanded({})
    }, 100)
  }

  const CollectionItem = ({
    item,
    index = '',
    path = [],
    parentSelected = false
  }) => {
    const { id, name, icon, status, type, hasChildren = false, children } = item
    const IconRender = MUIIcons[icon]
    const isSelected =
      parentSelected || selectedCollections.some((obj) => obj.id === id)
    const currentPath = [...path, item]
    const isExpanded = expanded[id]
    const isCurrent = currentCollectionId === id
    return status === 'active' ? (
      <Box key={index}>
        <ListItem disablePadding>
          <ListItemButton
            className={classes.listButtonWrapper}
            sx={{ background: isCurrent ? '#DBEAFF' : 'inherit' }}
            onClick={() => {
              if (!parentSelected) {
                handleSelect(item)
              }
            }}
          >
            <ListItemText
              primary={
                <Box className={classes.collectionTitleWrapper}>
                  <Box className={classes.listTextWrapper}>
                    <Checkbox
                      size="small"
                      sx={{ padding: '0px' }}
                      checked={isSelected}
                      disabled={parentSelected}
                      onChange={(e) => {
                        if (!parentSelected) {
                          handleSelect(item)
                        }
                      }}
                    />
                    {hasChildren ? (
                      isExpanded ? (
                        <IconButton
                          className={classes.iconButton}
                          onClick={(e) => {
                            setExpanded({ ...expanded, [id]: false })
                            e.stopPropagation()
                          }}
                        >
                          <ArrowDropDownIcon className={classes.icon} />
                        </IconButton>
                      ) : (
                        <IconButton
                          className={classes.iconButton}
                          onClick={(e) => {
                            if (!children) {
                              dispatch(getCollectionChildren(id))
                            }
                            setExpanded({ ...expanded, [id]: true })
                            e.stopPropagation()
                          }}
                        >
                          <ArrowRightIcon className={classes.icon} />
                        </IconButton>
                      )
                    ) : (
                      <IconButton disabled className={classes.iconButton}>
                        <StopIcon className={classes.iconEmpty} />
                      </IconButton>
                    )}
                    {icon && <IconRender className={classes.icon} />}
                    <Box className={classes.listText}>{name}</Box>
                  </Box>
                </Box>
              }
            />
          </ListItemButton>
        </ListItem>
        {isExpanded && (
          <Box className={classes.nestedCollection}>
            {children ? (
              children.map((item, index) => {
                return (
                  <CollectionItem
                    key={index}
                    item={item}
                    index={index}
                    path={currentPath}
                    parentSelected={selectSubCollections ? isSelected : false}
                  />
                )
              })
            ) : (
              <Skeleton
                variant="rectangular"
                width={'100%'}
                height={26.5}
                sx={{ marginBottom: '3px', marginTop: '2px' }}
              />
            )}
          </Box>
        )}
      </Box>
    ) : status === 'loading' ? (
      <ListItem disablePadding>
        <ListItemButton disabled className={classes.listButtonWrapper}>
          <ListItemText
            primary={
              <Box className={classes.listTextWrapper}>
                <IconButton disabled className={classes.iconButton}>
                  <StopIcon className={classes.iconEmpty} />
                </IconButton>
                {icon && <IconRender className={classes.icon} />}
                <Box className={classes.listText}>{name}</Box>
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
    ) : (
      <></>
    )
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={closeModel}>
      <DialogTitle>{headerText}</DialogTitle>
      <DialogContent>
        <Box>
          {collections && !_.isEmpty(collections) ? (
            <Box className={classes.collectionsList}>
              <Box className={classes.collectionListWrapper}>
                <List className={classes.listWrapper}>
                  {collections.map((item, index) => (
                    <CollectionItem key={index} item={item} index={index} />
                  ))}
                </List>
              </Box>
            </Box>
          ) : (
            <Box sx={{ padding: '20px' }}>
              No collections Found. Create a collection to add resources.
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModel}>
          Cancel
        </Button>
        {actionButtons.map((button, index) => {
          const { label = '', action = () => {}, condition = true } = button
          return (
            condition && (
              <Button
                key={index}
                disabled={!(selectedCollections.length > 0)}
                onClick={() => {
                  action([...selectedCollections])
                  closeModel()
                }}
              >
                {label}
              </Button>
            )
          )
        })}
      </DialogActions>
    </Dialog>
  )
}

export default ResourceAddToCollection
