import React from 'react'
import VideoJS from './VideoJS'
import { useStyles } from './styles'

const App = ({
  url = '',
  type = 'mp4',
  markers = [],
  playerRef,
  setTriggerRender
}) => {
  const classes = useStyles()
  const { webviewer } = classes

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fill: true,
    playbackRates: [0.5, 1, 1.5, 2],
    sources: [
      {
        src: url,
        type
      }
    ],
    userActions: {
      hotkeys: true
    }
  }

  const handlePlayerReady = (player) => {
    if (playerRef) {
      playerRef.current = player
    }
    if (setTriggerRender) {
      setTriggerRender(true)
    }
  }

  return (
    <div className={webviewer}>
      <VideoJS
        options={videoJsOptions}
        onReady={handlePlayerReady}
        markers={markers}
      />
    </div>
  )
}
export default App
