import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  userInput: {
    '& input': {
      padding: '2px !important'
    },
    '.MuiAutocomplete-clearIndicator': {
      color: 'red',
      visibility: 'hidden'
    }
  }
})

export { useStyles }
