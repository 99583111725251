import { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ImageNotFound from '../../assets/images/ImageNotFound.svg'
import Loader from '../../components/Loader'
import { checkExpiry } from '../../utils/AWS'
import _ from 'lodash'

const CheckAndRenderImage = (props) => {
  const {
    s3Obj = {},
    src = '',
    overlay = <></>,
    fallback = '',
    showLoader = true,
    ...rest
  } = props

  const { className, key, style } = rest

  const [imageUrl, setImageUrl] = useState(null)
  const [error, setError] = useState(false)
  const handleLoadFailure = (e) => {
    e.target.onerror = null
    if (fallback) {
      setError(true)
    } else {
      e.target.src = ImageNotFound
    }
  }

  useEffect(() => {
    async function getImageUrl() {
      const signedSrc = await checkExpiry(src, s3Obj)
      setImageUrl(signedSrc)
    }
    if (!_.isEmpty(s3Obj) && !_.isEmpty(src)) {
      getImageUrl()
    }
  }, [src, s3Obj])

  return showLoader && !imageUrl ? (
    <div
      className={className}
      key={key}
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style
      }}
    >
      <Loader loading={true} caption={''} />
    </div>
  ) : error ? (
    <>{fallback}</>
  ) : (
    <>
      {overlay}
      <LazyLoadImage onError={handleLoadFailure} src={imageUrl} {...rest} />
    </>
  )
}

export default CheckAndRenderImage
