import _ from 'lodash'

export const formatOptions = (arr) => {
  const options = []
  if (_.isArray(arr)) {
    arr.map((e) => options.push({ label: e, value: e }))
  }
  return options
}

export const extractOptionsValue = (arr) => {
  const options = []
  if (_.isArray(arr)) {
    arr.map((e) => options.push(e.value))
  }
  return options
}
