import { useEffect, useState } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import {
  Loader,
  Container,
  Section,
  TextLineLimiter,
  Button,
  CopyButton
} from '../../components'
import { getStoryboardGenerationHistory } from '../../store/api'
import { toast } from 'react-toastify'
import { useStyles } from './styles'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { formatDate } from '../../utils/Date'
import ReactPullToRefresh from 'react-pull-to-refresh'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const GenerationHistory = (props) => {
  const {
    storyboard_id,
    insertIntoEditor = () => {},
    disabled = false,
    onClose = () => {}
  } = props

  const classes = useStyles()
  const [history, setHistory] = useState([])
  const [loading, setLoading] = useState(true)

  const getHistory = async () => {
    try {
      const res = await getStoryboardGenerationHistory(storyboard_id)
      if (res.status === 200) {
        setHistory(res.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error('Failed to fetch generation history')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (storyboard_id) {
      getHistory()
    } else {
      setHistory([])
      setLoading(false)
    }
  }, [storyboard_id])

  const handlePull = () => {
    setLoading(true)
    setHistory([])
    getHistory()
  }

  if (loading) {
    return <Loader loading={loading} caption={'loading your history'} flex />
  }
  return (
    <Container>
      <Section overFlow>
        <Box className={classes.sectionContainer}>
          <ReactPullToRefresh onRefresh={() => handlePull()}>
            <div className={classes.pullWrapper} onClick={() => handlePull()}>
              <div>Pull down or click here to refresh</div>
              <ArrowDownwardIcon />
            </div>
          </ReactPullToRefresh>
          <Box className={classes.sectionLabel}>
            <Tooltip title="Back">
              <IconButton
                onClick={() => {
                  onClose()
                }}
              >
                <KeyboardBackspaceIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className={classes.historyWrapper}>
            {history.map((item, index) => {
              const { generated_content, created_at, created_by_user_name } =
                item
              return (
                <Box key={index} className={classes.historyContainer}>
                  <Box className={classes.historyContent}>
                    <TextLineLimiter
                      content={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: generated_content
                          }}
                        />
                      }
                      limit={5}
                    />
                  </Box>
                  <Box className={classes.historyMeta}>
                    <Box>{formatDate(created_at)}</Box>
                    <Box>{created_by_user_name}</Box>
                  </Box>
                  <Box className={classes.historyMeta}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        insertIntoEditor(generated_content, storyboard_id)
                      }}
                      disabled={disabled}
                    >
                      Insert into Editor
                    </Button>
                    <CopyButton text={generated_content} variant="outlined" />
                  </Box>
                </Box>
              )
            })}
            {history.length === 0 && (
              <Box className={classes.historyContainer}>
                <Box className={classes.noStories}> No history available</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Section>
    </Container>
  )
}

export default GenerationHistory
