import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  borderBot: {
    borderBottom: '1px solid #E5E5E5'
  },
  infoContainer: {
    borderLeft: '1px solid #E5E5E5',
    display: 'flex',
    flexDirection: 'column'
  },
  infoTitle: {
    padding: '15px !important',
    borderBottom: '1px solid #E5E5E5',
    fontFamily: 'PoppinsRegular',
    fontWeight: '600',
    fontSize: '16px',
    color: theme.palette.primary.main
  },
  infoData: {
    padding: 15,
    overflow: 'auto'
  },
  keyclass: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#A2A2A2',
    fontSize: '15px',
    minWidth: '120px',
    wordBreak: 'break-word'
  },
  dataclass: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#000000',
    fontSize: '15px',
    marginLeft: '10px',
    '&::before': { borderBottom: '0' },
    wordBreak: 'break-word'
  },
  dataContainer: {
    display: 'flex',
    margin: '10px 0px',
    wordBreak: 'break-all'
  },
  pdfTitle: {
    p: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    height: '16px',
    width: '16px'
  },
  iconButton: {
    float: 'left'
  },
  iconButtonRight: {
    float: 'right'
  },
  pdfContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  page: {
    height: '100%'
  },
  titleContainer: {
    margin: '15px 0px'
  },
  imageContainer: {
    height: 'calc(65% - 1px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      objectFit: 'contain',
      maxHeight: '90%',
      maxWidth: '100%'
    }
  },
  imageWrapper: {
    textAlign: 'center',
    height: '100%',
    width: '100%'
  },
  infoWrapper: {
    height: 'calc(100% - 8px)',
    marginTop: '8px'
  },
  similarContainer: {
    height: '35%'
  },
  timeStampContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
    overflow: 'auto',
    zIndex: 1
  },
  markerKeyTime: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#A2A2A2',
    fontSize: '15px',
    // cursor: 'pointer',
    width: '70px'
  },
  markerKeyText: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    color: '#A2A2A2',
    fontSize: '15px',
    width: '100%'
  },
  markerText: {
    fontFamily: 'PoppinsRegular',
    cursor: 'pointer',
    fontWeight: '500',
    color: '#A2A2A2',
    fontSize: '12px',
    listStyleType: 'circle',
    wordBreak: 'break-all'
    // paddingBottom: "10px",
  },
  markerLabel: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '100',
    cursor: 'pointer',
    color: '#000000',
    fontSize: '15px',
    listStyleType: 'circle',
    wordBreak: 'break-all'
    // paddingBottom: "10px",
  },
  listPointer: {
    paddingBottom: '10px',
    margin: '0px',
    paddingInlineStart: '14px',
    borderLeft: '1px solid #E5E5E5'
  },
  liPointer: {
    margin: '0px',
    marginRight: '0px'
  },
  markerTime: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    cursor: 'pointer',
    color: '#000000',
    fontSize: '15px',
    wordBreak: 'break-all'
  },
  searchWrapper: {
    background: '#f4f4f4'
  },
  chapterDataContainer: {
    display: 'flex'
  }
}))

export { useStyles }
