import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import { fetchRFx } from '../../store/RFx/Actions'
import Loader from '../../components/Loader'
import { initalizeS3, getSignedUrl } from '../../utils/AWS'
import { isEmpty, cloneDeep } from 'lodash'
import { getPublicAnalytics } from '../../store/api'
// import PDFViewer from "../../sections/RfxPDFView/PDFViewer";
import PDFViewer from '../../sections/AnalyticsPDFView/PDFViewer'
import AnalyticsPDFView from '../../sections/AnalyticsPDFView'
import { Container, Section } from '../../components/Container'
import { fetchAnalytic } from '../../store/Analytics/Actions'
import AnalyticsPdfViewer from '../AnalyticsPdfViewer'
import Error from '../../components/Error'

const ShareAnalytics = () => {
  const [rfx, setRfx] = useState({})
  const analytic = useSelector((state) => state?.analyticList?.analytic)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { type, shareId } = useParams()
  const [initialLoad, setInitialLoad] = useState(true)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [PDFUrl, setPDFUrl] = useState('')
  const [analyticsId, setAnalyticsId] = useState('')
  const [PDFData, setPDFData] = useState({})
  const [domainId, setDomainId] = useState('')

  useEffect(() => {
    async function initalizeData() {
      const res = await getPublicAnalytics(shareId)
      console.log(res)
      if (res.status === 200) {
        const navigateId = res.data?.rfxId
        dispatch(fetchAnalytic(navigateId, res.data?.domainId, shareId))
        setAnalyticsId(navigateId)
        setPDFUrl(res.data?.pdf_location)
      } else {
        setLoading(false)
        setError('No data found')
      }
    }
    initalizeData()
  }, [])

  const handlePDFDataChange = (data) => {
    if (JSON.stringify(data) === JSON.stringify({})) return
    const newAnalyticObj = cloneDeep(data)
    setPDFData(newAnalyticObj)
    setLoading(false)
  }

  useEffect(() => {
    handlePDFDataChange(analytic)
  }, [analytic])

  const handlePdfBack = () => {
    navigate(-1)
  }

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ width: '100%', height: '100vh' }}
      >
        <Loader loading={loading} caption="opening your analytics" />
      </Grid>
    )
  }

  return (
    <>
      {error === '' ? (
        <Fragment>
          {PDFUrl && PDFData && (
            <AnalyticsPDFView
              setShowPDF={handlePdfBack}
              PDFData={PDFData}
              PDFUrl={PDFUrl}
              isAnalytics={true}
              shareType={'public'}
              shareId={shareId}
            />
          )}
        </Fragment>
      ) : (
        <Error
          errorMessage={
            'No data found for the shared file, please check the shared url again.'
          }
          refresh={false}
          errorLogo={false}
        />
      )}
    </>
  )
}

export default ShareAnalytics
