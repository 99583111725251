import {
  CLEAR_SESSION_CHAT_HISTORY,
  SESSION_CHAT_HISTORY,
  SESSION_CHAT_HISTORY_ADD,
  UPDATE_SESSION_CHAT_HISTORY,
  DELETE_SESSION_CHAT_HISTORY,
  ADD_CHAT_FILTER,
  DELETE_SESSION_CHAT_HISTORY_REVERT,
  ADD_SESSION_CHAT,
  UPDATE_SESSION_CHAT,
  ADD_NEW_SESSION,
  UPDATE_SESSION_CHAT_FEEDBACK,
  SESSION_CHAT_HISTORY_ADD_SINGLE,
  UPDATE_SESSION_CHAT_PARTIAL,
  UPDATE_SESSION_CHAT_TITLE
} from '../../actionTypes'
import _, { cloneDeep } from 'lodash'

const initialState = {
  sessionChatHistory: {}
}

function chatReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case CLEAR_SESSION_CHAT_HISTORY: {
      return {
        ...state,
        sessionChatHistory: {}
      }
    }

    case SESSION_CHAT_HISTORY: {
      return {
        ...state,
        sessionChatHistory: payload
      }
    }
    case SESSION_CHAT_HISTORY_ADD: {
      const currentSessionData = cloneDeep(state.sessionChatHistory)
      const { sessions: currentSessions, sessionsCount: currentCount } =
        currentSessionData
      const { sessions, currentPage, pageSize, hasMoreData, sessionsCount } =
        payload
      const {
        today,
        yesterday,
        previous_7_Days,
        previous_30_Days,
        more_than_30_Days
      } = sessions
      let newSessions = {
        today: [...currentSessions.today, ...today],
        yesterday: [...currentSessions.yesterday, ...yesterday],
        previous_7_Days: [
          ...currentSessions.previous_7_Days,
          ...previous_7_Days
        ],
        previous_30_Days: [
          ...currentSessions.previous_30_Days,
          ...previous_30_Days
        ],
        more_than_30_Days: [
          ...currentSessions.more_than_30_Days,
          ...more_than_30_Days
        ]
      }
      const { uniqueSessions, count } = getUniqueSessions(newSessions)
      newSessions = uniqueSessions
      const newCount = sessionsCount + currentCount - count
      return {
        ...state,
        sessionChatHistory: {
          sessions: newSessions,
          hasMoreData,
          currentPage,
          pageSize,
          sessionsCount: newCount
        }
      }
    }
    case SESSION_CHAT_HISTORY_ADD_SINGLE: {
      const currentSessionData = cloneDeep(state.sessionChatHistory)
      const { sessions: currentSessions, sessionsCount: currentCount } =
        currentSessionData
      const { sessions, sessionsCount } = payload
      const {
        today,
        yesterday,
        previous_7_Days,
        previous_30_Days,
        more_than_30_Days
      } = sessions
      let newSessions = {
        today: [...currentSessions.today, ...today],
        yesterday: [...currentSessions.yesterday, ...yesterday],
        previous_7_Days: [
          ...currentSessions.previous_7_Days,
          ...previous_7_Days
        ],
        previous_30_Days: [
          ...currentSessions.previous_30_Days,
          ...previous_30_Days
        ],
        more_than_30_Days: [
          ...currentSessions.more_than_30_Days,
          ...more_than_30_Days
        ]
      }
      const { uniqueSessions, count } = getUniqueSessions(newSessions)
      newSessions = uniqueSessions
      const newCount = sessionsCount + currentCount - count
      return {
        ...state,
        sessionChatHistory: {
          ...state.sessionChatHistory,
          sessions: newSessions,
          sessionsCount: newCount
        }
      }
    }
    case UPDATE_SESSION_CHAT_HISTORY: {
      const updatedSessionData = cloneDeep(state.sessionChatHistory.sessions)
      const { title, id, sessionsIndex, index } = payload
      updatedSessionData[sessionsIndex][index][id] = {
        ...updatedSessionData[sessionsIndex][index][id],
        title
      }
      return {
        ...state,
        sessionChatHistory: {
          ...state.sessionChatHistory,
          sessions: updatedSessionData
        }
      }
    }
    case DELETE_SESSION_CHAT_HISTORY: {
      const modifiedSessionData = cloneDeep(state.sessionChatHistory.sessions)
      const { id, sessionsIndex, index } = payload
      modifiedSessionData[sessionsIndex].splice(index, 1)
      return {
        ...state,
        sessionChatHistory: {
          ...state.sessionChatHistory,
          sessions: modifiedSessionData
        }
      }
    }
    case DELETE_SESSION_CHAT_HISTORY_REVERT: {
      const modifiedSessionData = cloneDeep(state.sessionChatHistory.sessions)
      const { id, sessionsIndex, index, data } = payload
      modifiedSessionData[sessionsIndex].splice(index, 0, {})
      _.set(modifiedSessionData, `${sessionsIndex}.${index}.${id}`, data)
      return {
        ...state,
        sessionChatHistory: {
          ...state.sessionChatHistory,
          sessions: modifiedSessionData
        }
      }
    }
    case ADD_NEW_SESSION: {
      const { id, data } = payload
      const { data: sessionData } = data || {}
      const modifiedSessionData = cloneDeep(state.sessionChatHistory.sessions)
      modifiedSessionData.today.unshift(sessionData)
      return {
        ...state,
        sessionChatHistory: {
          ...state.sessionChatHistory,
          sessions: modifiedSessionData
        }
      }
    }

    case ADD_SESSION_CHAT: {
      const { id, data } = payload
      const modifiedSessionData = cloneDeep(state.sessionChatHistory.sessions)
      const obj = findPositionById(id, modifiedSessionData)
      const { key = '', index = -1 } = obj || {}
      if (key && index > -1) {
        modifiedSessionData[key][index][id].chat = { ...data }
      }
      return {
        ...state,
        sessionChatHistory: {
          ...state.sessionChatHistory,
          sessions: modifiedSessionData
        }
      }
    }
    case UPDATE_SESSION_CHAT: {
      const { id, data } = payload
      const { chats, count } = data
      const modifiedSessionData = cloneDeep(state.sessionChatHistory.sessions)
      const obj = findPositionById(id, modifiedSessionData)
      const { key = '', index = -1 } = obj || {}
      if (key && index > -1) {
        modifiedSessionData[key][index][id].chat = {
          ...data,
          chats: [...modifiedSessionData[key][index][id].chat.chats, ...chats],
          count: count + modifiedSessionData[key][index][id].chat.count
        }
      }
      return {
        ...state,
        sessionChatHistory: {
          ...state.sessionChatHistory,
          sessions: modifiedSessionData
        }
      }
    }
    case UPDATE_SESSION_CHAT_PARTIAL: {
      const { session_id: id, chat_id, message, type } = payload
      const modifiedSessionData = _.cloneDeep(state.sessionChatHistory.sessions)
      const obj = findPositionById(id, modifiedSessionData)
      const { key = '', index = -1 } = obj || {}
      if (key && index > -1) {
        if (type === 'chat_status' && message === '__END_OF_CHAT__') {
          modifiedSessionData[key][index][id].chat = {
            ...modifiedSessionData[key][index][id].chat,
            count: modifiedSessionData[key][index][id].chat.count + 1
          }
        }
        const chat_index = modifiedSessionData[key][index][
          id
        ].chat.chats.findIndex(
          (item) => item.id === chat_id && item.author === 'Them'
        )
        const chatList = modifiedSessionData[key][index][id].chat.chats
        if (chat_index > -1) {
          const chatToModify = _.cloneDeep(chatList[chat_index])
          if (!chatToModify?.userstopped) {
            if (type === 'chat') {
              if (chatToModify.data.text === ' ') {
                chatToModify.data.text = message
              } else {
                chatToModify.data.text = chatToModify.data.text + message
              }
            } else if (type === 'chat_full') {
              chatToModify.data.text = message
            } else if (type === 'status') {
              chatToModify.data.status.push(message)
            } else if (type === 'prompt') {
              chatToModify.data.systemPrompt =
                chatToModify.data?.systemPrompt + message
            } else if (
              type === 'chat_status' &&
              message === '__START_OF_CHAT__'
            ) {
              chatToModify.data.startedChat = true
            } else if (
              type === 'chat_status' &&
              message === '__END_OF_CHAT__'
            ) {
              chatToModify.loading = false
            } else if (type === 'filter') {
              chatToModify.data.filter = message
            } else if (type === 'userstopped') {
              chatToModify.userstopped = message
              if (chatToModify.data) {
                chatToModify.data.partialText = chatToModify.data?.text
                  ? chatToModify.data.text
                  : 'Generation Stopped by User'
              }
            }
            chatList[chat_index] = chatToModify
          }
        } else {
          if (type === 'chat') {
            const obj = {
              id: chat_id,
              type: 'text',
              author: 'Them',
              created_at: new Date().toUTCString(),
              loading: true,
              data: {
                text: message,
                systemPrompt: '',
                inputText: '',
                filter: {},
                status: [],
                error: '',
                startedChat: false
              }
            }
            chatList.push(obj)
          } else if (type === 'status') {
            const obj = {
              id: chat_id,
              type: 'text',
              author: 'Them',
              created_at: new Date().toUTCString(),
              loading: true,
              data: {
                text: ' ',
                systemPrompt: '',
                inputText: '',
                filter: {},
                error: '',
                status: [message],
                startedChat: false
              }
            }
            chatList.push(obj)
          } else if (type === 'filter') {
            const obj = {
              id: chat_id,
              type: 'text',
              author: 'Them',
              created_at: new Date().toUTCString(),
              loading: true,
              data: {
                text: ' ',
                systemPrompt: '',
                inputText: '',
                filter: message,
                error: '',
                status: [],
                startedChat: false
              }
            }
            chatList.push(obj)
          }
        }
        modifiedSessionData[key][index][id].chat.chats = chatList
      }
      return {
        ...state,
        sessionChatHistory: {
          ...state.sessionChatHistory,
          sessions: modifiedSessionData
        }
      }
    }
    case UPDATE_SESSION_CHAT_FEEDBACK: {
      const { sessionId, chatIndex, feedback, reaction } = payload
      const modifiedSessionData = cloneDeep(state.sessionChatHistory.sessions)
      const obj = findPositionById(sessionId, modifiedSessionData)
      const { key = '', index = -1 } = obj || {}
      if (key && index > -1) {
        modifiedSessionData[key][index][sessionId].chat.chats[
          chatIndex
        ].data.feedback = feedback
        modifiedSessionData[key][index][sessionId].chat.chats[
          chatIndex
        ].data.reaction = reaction
      }
      return {
        ...state,
        sessionChatHistory: {
          ...state.sessionChatHistory,
          sessions: modifiedSessionData
        }
      }
    }
    case ADD_CHAT_FILTER: {
      const dataWithFilter = cloneDeep(state.sessionChatHistory)
      dataWithFilter[payload.title][payload.sessionsIndex][
        payload.currentSessionId
      ].filtered_proposals = payload.filters
      dataWithFilter[payload.title][payload.sessionsIndex][
        payload.currentSessionId
      ].filtered_options = payload.filtered_options
      return {
        ...state,
        sessionChatHistory: dataWithFilter
      }
    }
    case UPDATE_SESSION_CHAT_TITLE: {
      const { id, title, regenerateTitle = false } = payload
      const modifiedSessionData = cloneDeep(state.sessionChatHistory.sessions)
      const obj = findPositionById(id, modifiedSessionData)
      const { key = '', index = -1 } = obj || {}
      if (key && index > -1) {
        if (title) {
          modifiedSessionData[key][index][id].title = title
        }
        modifiedSessionData[key][index][id].regenerateTitle = regenerateTitle
      }
      return {
        ...state,
        sessionChatHistory: {
          ...state.sessionChatHistory,
          sessions: modifiedSessionData
        }
      }
    }

    default:
      return state
  }
}

function findPositionById(idToFind, sessions) {
  for (const key in sessions) {
    if (Array.isArray(sessions[key])) {
      const index = sessions[key].findIndex((item) => item[idToFind])
      if (index !== -1) {
        return { key, index }
      }
    }
  }
  return null
}

function getUniqueSessions(newSessions) {
  let countNotAdded = 0
  const uniqueSessions = Object.keys(newSessions).reduce((acc, key) => {
    const uniqueArray = newSessions[key].reduce((unique, item) => {
      const id = Object.keys(item)[0]
      if (!unique.find((obj) => Object.keys(obj)[0] === id)) {
        unique.push(item)
      } else {
        countNotAdded++
      }
      return unique
    }, [])
    acc[key] = uniqueArray
    return acc
  }, {})
  return { uniqueSessions, count: countNotAdded }
}

export default chatReducer
