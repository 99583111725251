import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  maincontainer: {
    flexGrow: 1
  },
  section: (props) => {
    if (props?.overFlow) {
      return {
        flex: '8 8 auto',
        overflow: 'auto'
      }
    } else {
      return {
        flex: '8 8 auto'
      }
    }
  },
  sectionfixed: {
    flex: '0 1 auto'
  }
})

export { useStyles }
