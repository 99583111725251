import React, { useEffect, useState } from 'react'
import { Container } from '../../components/Container'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { useStyles } from './styles'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../config/routes'
import AutoCompleteSearch from '../../components/AutoCompleteSearch'
import { Box, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { ReactComponent as Arrow_Right } from '../../assets/images/Blue_Arrow_Right.svg'
import { checkUserRoleAnnotator } from '../../utils/User'
import mixpanelEvents from '../../config/mixpanelEvents'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import SvgIcon from '@mui/material/SvgIcon'
import clsx from 'clsx'

const DashboardSearch = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    bgImage,
    recentBlur,
    iconSearchBar,
    recentBox,
    iconWrapper,
    recentWrapperText,
    recentWrapper,
    searchField,
    container,
    timeContainer,
    greetingContainer,
    radioWrapper,
    searchInputContainer,
    searchInpurOutterWrapper,
    enterIcon,
    disableEnter,
    searchTypeHightlight,
    typeSelection
  } = classes
  const { DASHBOARD_RESULT } = ROUTES
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { user_name, domain_id } = domain || {}
  const [searchKey, setSearchKey] = useState()
  const [searchType, setSearchType] = useState('all')
  const [greeting, setGreeting] = useState('')
  const isAnnotator = checkUserRoleAnnotator()

  useEffect(() => {
    const date = new Date()
    const hour = date.getHours()
    if (hour >= 0 && hour < 12) {
      setGreeting('Good Morning')
    } else if (hour >= 12 && hour < 18) {
      setGreeting('Good Afternoon')
    } else {
      setGreeting('Good Evening')
    }
  }, [])

  const handleKeyDown = (e, val) => {
    if (e.key !== 'Enter') return
    e.preventDefault()
    e.target.blur()
    let newSearchKey = ''
    let newSearchType = ''
    if (val) {
      if (val?.type === 'recent') {
        newSearchKey = val?.search_keyword
        newSearchType = val?.search_type
      } else {
        if (val) {
          if (val?.label) {
            newSearchKey = val?.label
          } else {
            newSearchKey = val
          }
        }
        newSearchType = searchType
      }
      setSearchKey(newSearchKey)
      setSearchType(newSearchType)
    }
    if ((newSearchKey && newSearchType) || e.target.value) {
      handleSearch(newSearchKey, newSearchType)
    }
  }

  const handleSearch = (newKey, keyType) => {
    if (newKey || searchKey) {
      if (
        JSON.stringify(keyType || searchType).replaceAll('"', '') === 'chat'
      ) {
        const sessionId = 'new'
        navigate(`/chat/${sessionId}`, {
          state: {
            searchKey: JSON.stringify(newKey || searchKey).replaceAll('"', '')
          }
        })
        trackEvent(
          mixpanelEvents.DASHBOARD_CHAT,
          'SUCCESS',
          {},
          { searchKey: JSON.stringify(newKey || searchKey).replaceAll('"', '') }
        )
      } else {
        const keyword = JSON.stringify(newKey || searchKey)
        const type = keyType || searchType
        const searchParams = createSearchParams({
          keyword
        }).toString()
        navigate(`${DASHBOARD_RESULT}?${searchParams}`, {
          state: { searchType: type }
        })
      }
    }
  }

  const getFormatedSearchKey = (searchKeyword = '') => {
    const keyWords = searchKeyword
      .replace(' in text', '')
      .replace(' in assets', '')
      .replace(' in knowledge', '')
    return keyWords
  }

  const handleTypeSelect = (e) => {
    const value = e.target.value
    setSearchType(value)
  }

  return (
    <Container>
      <Box className={container}>
        <Box className={searchInpurOutterWrapper}>
          <Box className={searchInputContainer}>
            <Box className={timeContainer}>
              {moment().format('dddd, MMMM DD, YYYY')}
            </Box>
            <Box className={greetingContainer}>
              {greeting}, {user_name}
            </Box>
            <Box className={searchField}>
              <AutoCompleteSearch
                s3Obj={props?.s3Obj}
                setSearchKey={setSearchKey}
                setSearchType={(e) => {
                  if (e === 'text') {
                    setSearchType('all')
                  } else {
                    setSearchType(e)
                  }
                }}
                searchKey={getFormatedSearchKey(searchKey)}
                handleKeyDown={handleKeyDown}
                endAdornment={
                  <Box
                    className={searchKey ? '' : disableEnter}
                    onClick={() => handleSearch()}
                  >
                    <Box className={clsx(enterIcon, iconSearchBar)}>
                      <SvgIcon>
                        <Arrow_Right />
                      </SvgIcon>
                    </Box>
                  </Box>
                }
                initalState={false}
              />
            </Box>
            <Box>
              <RadioGroup
                className={radioWrapper}
                row
                value={searchType || ''}
                onChange={(e) => handleTypeSelect(e)}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio className={typeSelection} />}
                  label={<span className={iconWrapper}>Text</span>}
                />
                <FormControlLabel
                  value="asset"
                  control={<Radio className={typeSelection} />}
                  label={<span className={iconWrapper}>Assets</span>}
                />
                {!isAnnotator && (
                  <FormControlLabel
                    value="chat"
                    control={<Radio className={typeSelection} />}
                    label={<span className={iconWrapper}>Chat</span>}
                  />
                )}
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
export default DashboardSearch
