import { DELETE_COLLECTION, UPDATE_COLLECTIONS } from '../../../actionTypes'
import { deleteCollectionFolder } from '../../../api'
import { toast } from 'react-toastify'

const delCollection =
  (req, callback, directDelete = false) =>
  async (dispatch) => {
    if (directDelete) {
      dispatch({
        type: DELETE_COLLECTION,
        payload: req
      })
    } else {
      dispatch({
        type: UPDATE_COLLECTIONS,
        payload: { ...req, status: 'loading' },
        callback
      })
      const res = await deleteCollectionFolder(req)
      if (res.status === 200) {
        dispatch({
          type: DELETE_COLLECTION,
          payload: req
        })
      } else {
        toast.error('Unable to update collection')
        dispatch({
          type: UPDATE_COLLECTIONS,
          payload: { ...req, status: 'active' }
        })
      }
    }
  }

export default delCollection
