import { createTheme } from '@mui/material/styles'
import { useColor } from '../ThemeContext'

const customtheme = () => {
  return createTheme({
    palette: {
      primary: {
        main: '#000000',
        secondary: '#949494',
        liteBlue: '#0645AD1A'
      },
      secondary: {
        main: '#797979'
      },
      neutral: {
        main: '#0645AD',
        contrastText: '#fff'
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              opacity: '0.7',
              border: '1px solid',
              backgroundColor: 'inherit'
            }
          }
        }
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            color: '#000000',
            fontFamily: 'PoppinsRegular'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& input': {
              padding: '8.5px 14px'
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            top: '-7px'
          }
        }
      }
    },
    typography: {
      fontFamily: [
        'PoppinsRegular',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    }
  })
}

const theme = customtheme()

theme.typography.h6 = {
  fontWeight: 'bold',
  fontSize: '22px',
  color: '#000000',
  padding: '0'
}

export { theme }
