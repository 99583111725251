import React, { useEffect, useState } from 'react'
import Uppy from '../../utils/uppy/Uppy'
import UploadModal from '../UploadModel'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import AwsS3Multipart from '../../utils/uppy/aws-multipart'
import PropTypes from 'prop-types'
import './Uppy.css'
import { useSelector, useDispatch } from 'react-redux'
import { setUploader, resizeUploader } from '../../store/Uploader/Actions'
import { isEmpty } from 'lodash'

const Upload = () => {
  const uploaderState = useSelector((state) => state.uploader.uploaderState)
  const dispatch = useDispatch()
  const {
    showUploader,
    uploaderType,
    fullScreen,
    callback = () => {}
  } = uploaderState
  const [uppy, setUppy] = useState({})

  useEffect(() => {
    async function initalize() {
      if (showUploader) {
        const newUppy = new Uppy({
          autoProceed: true,
          restrictions: {
            allowedFileTypes: [
              'application/pdf',
              'video/mp4',
              'video/webm',
              'video/mpeg',
              'video/ogg',
              'video/avi',
              'image/jpeg',
              'image/png',
              'image/jpg',
              'application/vnd.ms-powerpoint',
              'application/vnd.openxmlformats-officedocument.presentationml.presentation',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ]
          },
          showSelectedFiles: true
        }).use(AwsS3Multipart, {
          limit: 4,
          companionUrl: process.env.REACT_APP_API_GATEWAY_URL,
          uploaderType
        })
        setUppy(newUppy)
      }
    }
    initalize()
  }, [showUploader])

  const handleUploaderClose = () => {
    setUppy({})
    dispatch(setUploader({ showUploader: false, uploaderType: '' }))
  }

  const handleFullScreen = (e) => {
    dispatch(resizeUploader(e))
  }

  return (
    !isEmpty(uppy) &&
    showUploader && (
      <UploadModal
        uppy={uppy}
        modalCallback={callback}
        fullScreen={fullScreen}
        showFullScreen={(val) => handleFullScreen(val)}
        onRequestClose={() => handleUploaderClose()}
        uploaderType={uploaderType}
        uploaderState={uploaderState}
      />
    )
  )
}

export default Upload
