import { SET_UPLOADER, RESIZE_UPLOADER, TOASTER_ALERT } from '../../actionTypes'
import { toast } from 'react-toastify'
import { startCase } from 'lodash'
const initialState = {
  uploaderState: {
    showUploader: false,
    uploaderType: '',
    fullScreen: true
  }
}

function uploaderReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SET_UPLOADER:
      if (
        payload.uploaderType === state.uploaderState.uploaderType ||
        state.uploaderState.uploaderType === '' ||
        payload.uploaderType === ''
      ) {
        return {
          ...state,
          uploaderState: payload,
          showUploader: true
        }
      } else {
        toast.error(
          `${
            startCase(state.uploaderState.uploaderType) || 'Another'
          } uploader is active`
        )
        return state
      }
    case RESIZE_UPLOADER:
      return {
        ...state,
        uploaderState: {
          ...state.uploaderState,
          fullScreen: payload
        }
      }
    default:
      return state
  }
}

export default uploaderReducer
