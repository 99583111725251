import { useState, useEffect } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import useDidMountEffect from '../../hooks'
import { useStyles } from './styles'
import clsx from 'clsx'
import { TextField, Tooltip } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export default function MultipleSelectCheckmarks(props) {
  const {
    id = 'menu-multi-select',
    placeholder = 'Select',
    options = [],
    groupSelect = false,
    value = [],
    selectTitle = 'Select all',
    handleChange,
    fullWidth = false,
    width = 100,
    disabled = false
  } = props
  const [selected, setSelected] = useState(value)
  const [searchInput, setSearchInput] = useState('')
  const [optionList, setOptionList] = useState(options)

  const isAllSelected = options.length > 0 && selected.length === options.length

  useEffect(() => {
    setSelected(value)
  }, [value])

  useEffect(() => {
    const unSelectedItems = options.filter(
      (element) => !value.includes(element)
    )
    const newSelected = value.concat(unSelectedItems)
    setOptionList(newSelected)
  }, [options])

  /* useEffect(() =>{
    const unSelectedItems = options.filter(element => (!selected.includes(element)))
    const newSelected = selected.concat(unSelectedItems)
    setOptionList(newSelected)
  },[selected]) */

  const handleScrollUpdates = () => {
    const unSelectedItems = options.filter(
      (element) => !selected.includes(element)
    )
    const newSelected = selected.concat(unSelectedItems)
    setOptionList(newSelected)
  }

  const handleInputChange = (event) => {
    const input = event.target.value
    setSearchInput(input)
    if (input === '' && selected.length === 0) {
      setOptionList(options)
      handleChange(options)
    }
    const filteredData = options.filter((data) =>
      data.toLocaleLowerCase().includes(input.toLocaleLowerCase())
    )
    setOptionList(filteredData)
  }

  const handleClickOutside = () => {
    setSearchInput('')
    setOptionList(options)
  }

  const handleSelect = (event) => {
    const value = event.target.value
    let selectedData
    if (value[value.length - 1] === 'all') {
      selectedData = selected.length === options.length ? [] : options
      setSelected(selectedData)
    } else {
      selectedData = value
      setSelected(selectedData)
    }
    handleChange(selectedData)
  }

  const classes = useStyles({ width })
  const {
    rootContainer,
    menuItems,
    fullWidthContainer,
    autoWidth,
    textSearch,
    listItemText,
    tooltip
  } = classes

  const MenuProps = {
    PaperProps: {
      onScroll: (event) => {
        handleScrollUpdates()
      },
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        minWidth: 100,
        maxWidth: 500,
        padding: 0
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    autoFocus: false,
    animated: false
  }

  return (
    <div id="select-root">
      <FormControl
        /* sx={{ m: 1, minWidth: 60, maxWidth: 100, margin: 0 }} */ className={
          fullWidth
            ? clsx(rootContainer, fullWidthContainer)
            : clsx(rootContainer, autoWidth)
        }
      >
        <Select
          disabled={disabled}
          labelId="demo-multiple-checkbox-label"
          id={id}
          multiple
          displayEmpty
          value={selected}
          onChange={(event) => handleSelect(event)}
          onClose={handleClickOutside}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em style={{ fontStyle: 'normal' }}>{placeholder}</em>
            }
            return selected ? selected.join(', ') : ''
          }}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
        >
          <TextField
            id="search-input"
            fullWidth
            placeholder="Search"
            value={searchInput}
            variant="outlined"
            onChange={(event) => {
              handleInputChange(event)
            }}
            onKeyDown={(event) => {
              if (event.key !== 'Escape') {
                event.stopPropagation()
              }
            }}
            className={textSearch}
          />
          <MenuItem value="all" className={menuItems}></MenuItem>
          <MenuItem value="all" className={menuItems}>
            <Checkbox checked={isAllSelected} />
            <ListItemText primary={selectTitle} className={listItemText} />
          </MenuItem>
          {optionList.map((option, key) => (
            <MenuItem key={key} value={option} className={menuItems}>
              <Checkbox checked={selected.includes(option)} />
              <Tooltip
                classes={{ tooltip }}
                title={option}
                arrow
                placement="right"
              >
                <ListItemText className={listItemText} primary={option} />
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
