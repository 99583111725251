import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%'
  },
  multiSelectCheckBox: {
    marginTop: '16px',
    marginBottom: '16px',
    color: 'rgba(0, 0, 0, 0.6)',
    '& #multiCheckboxButtonText': {
      whiteSpace: 'normal !important'
    },
    '& #multiCheckboxWrapper': {
      width: 'auto !important',
      '& button': {
        fontSize: '12px',
        padding: '1px 4px'
      }
    },
    '& #multiCheckboxOption': {
      width: '100% !important',
      fontWeight: '500',
      fontSize: '12px',
      color: '#000000',
      '& .MuiSvgIcon-root': {
        fontSize: '20px'
      },
      '& .MuiCheckbox-root': {
        padding: '5px'
      }
    },
    '& .Mui-checked': {
      color: '#0645AD !important'
    },
    '& #multiCheckboxMenuWrapper': {
      border: '1px solid #E5E5E5 !important',
      borderRadius: '4px !important',
      marginTop: '5px'
    },
    '& #multiCheckboxMenu': {
      '& input': {
        fontSize: '12px !important'
      }
    }
  }
}))

export { useStyles }
