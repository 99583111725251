import { USER_SIGN_OUT } from '../../../actionTypes'

const userSignOut = () => {
  return {
    type: USER_SIGN_OUT,
    payload: {}
  }
}

export default userSignOut
