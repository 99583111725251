import { cloneDeep } from 'lodash'
import {
  DELETE_SESSION_CHAT_HISTORY,
  DELETE_SESSION_CHAT_HISTORY_REVERT
} from '../../../actionTypes'
import { putChatSessionHistory } from '../../../api'
import { toast } from 'react-toastify'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../../../config/mixpanelEvents'

const deleteChatSessionHistory = (newVal, oldVal) => async (dispatch) => {
  dispatch({
    type: DELETE_SESSION_CHAT_HISTORY,
    payload: newVal
  })
  const req = {
    id: newVal.id,
    active: false
  }
  const res = await putChatSessionHistory(req)
  if (res.status === 200) {
    trackEvent(mixpanelEvents.CHAT_SESSION_DELETED, 'SUCCESS', {}, {})
  } else {
    trackEvent(mixpanelEvents.CHAT_SESSION_DELETED, 'FAILURE', {}, {})
    toast.error('Unable to delete session. Please try again later.')
    dispatch({
      type: DELETE_SESSION_CHAT_HISTORY_REVERT,
      payload: oldVal
    })
  }
}

export default deleteChatSessionHistory
