import { FETCH_RFX_DOCUMENTS } from '../../../actionTypes'
import { getRFxDocuments } from '../../../api'

const fetchRFxDocuments = () => async (dispatch) => {
  dispatch({
    type: FETCH_RFX_DOCUMENTS,
    payload: null
  })
  let rfxdocuments = []
  const res = await getRFxDocuments()
  if (res.status === 200) {
    rfxdocuments = JSON.parse(res.data)
    dispatch({
      type: FETCH_RFX_DOCUMENTS,
      payload: rfxdocuments
    })
  }
}

export default fetchRFxDocuments
