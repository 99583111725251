import * as React from 'react'
import Box from '@mui/material/Box'
import { isEmpty } from 'lodash'
import { useStyles } from './styles'
import clsx from 'clsx'

export default function Container(props) {
  const classes = useStyles()
  const { container } = classes
  const { parentref, className = '', hidden = false, children, ...rest } = props
  return hidden ? (
    <Box ref={parentref} {...props}>
      {children}
    </Box>
  ) : (
    <Box
      ref={parentref}
      component="main"
      className={clsx(container, className)}
      {...rest}
    >
      {children}
    </Box>
  )
}
