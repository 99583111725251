import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../components/Button'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { toast } from 'react-toastify'
import { createNewDomain } from '../../store/api'
import { useStyles } from './styles'
import SelectRadio from '../../components/SelectRadio'
import { signOut, checkUserRoleSuperUser } from '../../utils/User'
import CircularProgress from '@mui/material/CircularProgress'

const DomainCreate = (props) => {
  const { isSettings = false, isFocused = false } = props
  const [name, setName] = useState('')
  const [policy, setPolicy] = useState('open_to_subdomain')
  const [subdomain, setSubdomain] = useState('')
  const [loading, setLoading] = useState(false)
  const isSuperUser = checkUserRoleSuperUser()
  const selectOptions = [
    {
      label: 'Discoverable and open to anyone with certain domain',
      value: 'open_to_subdomain'
    },
    { label: 'Invitation only', value: 'invite_only' }
  ]
  const classes = useStyles({ isSettings })
  const { formcontrol, text, text1, container } = classes

  const dispatch = useDispatch()

  const createDomain = async () => {
    if (name && policy && subdomain) {
      setLoading(true)
      const data = {
        policy,
        name: name.trim(),
        domain_name: subdomain.trim()
      }
      const res = await createNewDomain(data)
      if (res.status === 200) {
        if (isSettings) {
          toast.success('Domain Created Successfully')
          setName('')
          setSubdomain('')
          setPolicy('open_to_subdomain')
        } else {
          signOut(dispatch)
        }
      } else {
        toast.error(res.data.message || 'Oops! Unable to Create Domain.')
      }
    } else {
      toast.error('All the fields are required')
    }
    setLoading(false)
  }

  const handlePolicyChange = (event, keyId) => {
    if (event.target.value) {
      setPolicy(event.target.value)
    }
  }

  return isSuperUser ? (
    <Box component="form" noValidate autoComplete="off" className={container}>
      <FormControl className={formcontrol}>
        <TextField
          onChange={(e) => {
            setName(e.target.value)
          }}
          value={name}
          className={text}
          label="Your Team Name"
          variant="outlined"
          disabled={loading}
        />
        <TextField
          onChange={(e) => {
            setSubdomain(e.target.value)
          }}
          value={subdomain}
          className={text}
          label="Email Domain End with"
          variant="outlined"
          disabled={loading}
        />
        <FormControl className={text1}>
          <SelectRadio
            disabled={loading}
            label="Team signup mode"
            options={selectOptions}
            value={policy}
            handleChange={handlePolicyChange}
          />
        </FormControl>
        <Button disabled={loading} onClick={() => createDomain()}>
          {loading ? <CircularProgress size={24} /> : 'Create Domain'}
        </Button>
      </FormControl>
    </Box>
  ) : (
    <Box>Uh oh! You don't have permission to create a domain.</Box>
  )
}

export default DomainCreate
