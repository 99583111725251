/* eslint-disable quotes */
import styled, { css } from 'styled-components'

const StyledLinkButton = styled.button`
  color: #000000;
  background-color: white;
  font-weight: bold;
  border: none;
  font-family: PoppinsRegular;
  cursor: pointer;
  ${(props) =>
    props.inActive &&
    css`
      color: #949494 !important;
      font-weight: bold;
      background-color: white;
    `}
`

export { StyledLinkButton }
