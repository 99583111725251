import React, { useEffect, useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import _, { isEmpty, set } from 'lodash'
import IconButton from '@mui/material/IconButton'
import { Grid } from '@material-ui/core'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import RemoveIcon from '@mui/icons-material/Remove'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import PreviewIcon from '@mui/icons-material/Preview'
import SyntaxHighlighter from 'react-syntax-highlighter'
import CodeIcon from '@mui/icons-material/Code'
import {
  CopyButton,
  Loader,
  ContentEditable,
  Container,
  Section,
  SectionFixed
} from '../../components'
import { getSignedUrl, checkExpiry } from '../../utils/AWS'
import ProfilePicturePicker from './ProfilePicker'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Scrollbars } from 'react-custom-scrollbars'
import { Visibility, Edit, Delete } from '@mui/icons-material'
import { useColor } from '../../ThemeContext'

const ScrollablePictures = (props) => {
  const {
    images = [],
    s3Obj,
    viewImage = () => {},
    type = 'edit',
    deleteImage = () => {}
  } = props
  const [imagesUrl, setImagesUrl] = useState([])
  const [loading, setLoading] = useState(true)

  const [hoveredIndex, setHoveredIndex] = useState(null)
  const handleMouseEnter = (index) => {
    setHoveredIndex(index)
  }
  const handleMouseLeave = () => {
    setHoveredIndex(null)
  }

  const s3Urls = async (images, s3Obj) => {
    setLoading(true)
    try {
      const signedUrls = await Promise.all(
        images.map(async (imageUrl, index) => {
          const signedSrc = await checkExpiry(
            imageUrl?.src ? imageUrl.src : imageUrl,
            s3Obj
          )
          return { index, signedSrc }
        })
      )
      signedUrls.sort((a, b) => a.index - b.index)
      const newImagesUrl = signedUrls.map(({ signedSrc }) => signedSrc)

      setImagesUrl(newImagesUrl)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    s3Urls(images, s3Obj)
  }, [images, s3Obj])

  return (
    <Scrollbars style={{ height: '175px' }}>
      {loading ? (
        <Loader loading={loading} caption={''} />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {imagesUrl.map((image, index) => (
            <div
              key={index}
              style={{
                position: 'relative',
                marginRight: '10px',
                margin: '8px',
                borderRadius: '10px'
              }}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={image}
                alt={`Image ${index}`}
                style={{
                  // width: '100%',
                  maxHeight: '150px',
                  objectFit: 'cover',
                  borderRadius: '10px'
                }}
              />
              {hoveredIndex === index && type === 'edit' && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    padding: '0px',
                    borderRadius: '10px 0px 10px 0px'
                  }}
                >
                  <Tooltip title="View">
                    <IconButton
                      disableRipple
                      onClick={() => viewImage([{ src: image }])}
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove">
                    <IconButton
                      disableRipple
                      onClick={() => deleteImage(index)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </Scrollbars>
  )
}

const Text = (props) => {
  const { text, style } = props
  return <span style={style} dangerouslySetInnerHTML={{ __html: text }} />
}

const ProjectEditor = (props) => {
  const {
    annotations: projects,
    setAnnotations: setProjects,
    selectedEntry: selectedProject,
    setSelectedEntry: setSelectedProject,
    isSaving: saving,
    s3Obj,
    faces,
    currentPage,
    edit,
    reprocessMode,
    setTextBBox,
    textBBox,
    handleSelectionAction,
    setSelectedImage,
    handleMode,
    addprofilePicture
  } = props

  const selectedProjectIndex = projects.findIndex(
    (item) => item.id === selectedProject.id
  )
  const [dragActiveTextBox, setDragActiveTextBox] = useState(false)
  const [insideView, setInsideView] = useState('edit')

  const { selectedColor } = useColor()

  useEffect(() => {
    setInsideView('edit')
  }, [selectedProject, edit])

  const addNewTeamMemberToProject = (key) => {
    const newObj = {
      name: '',
      role: '',
      responsibilities: ''
    }
    let { parsedData = {} } = selectedProject || {}
    parsedData = {
      ...parsedData,
      [key]: [...parsedData[key], newObj]
    }
    const editedProjectData = {
      ...selectedProject,
      parsedData,
      updateStatus: 'edit'
    }
    setSelectedProject(editedProjectData)
    projects[selectedProjectIndex] = editedProjectData
    setProjects([...projects])
  }
  const removeTeamMemberFromProject = (key, index) => {
    let { parsedData = {} } = selectedProject || {}
    parsedData = {
      ...parsedData,
      [key]: parsedData[key].filter((item, i) => i !== index)
    }
    const editedProjectData = {
      ...selectedProject,
      parsedData,
      updateStatus: 'edit'
    }
    setSelectedProject(editedProjectData)
    projects[selectedProjectIndex] = editedProjectData
    setProjects([...projects])
  }

  const handleProjectOnEdit = (key, value, index, childKey) => {
    const { parsedData = {} } = selectedProject || {}
    if (key === 'project_timeline') {
      parsedData[key][childKey] = value
    } else if (key === 'project_team_members') {
      parsedData[key][index][childKey] = value
    } else if (key === 'images') {
      const newArray = [...parsedData[key]]
      newArray.splice(index, 1)
      parsedData[key] = newArray
    } else {
      parsedData[key] = value
    }
    const editedProjectData = {
      ...selectedProject,
      parsedData,
      updateStatus: 'edit'
    }
    setSelectedProject(editedProjectData)
    projects[selectedProjectIndex] = editedProjectData
    setProjects([...projects])
  }

  const renderEditor = () => {
    const { parsedData = {} } = selectedProject || {}
    const {
      project_name = '',
      project_location = '',
      project_description = '',
      project_timeline = {},
      project_team_members = [],
      square_footage = '',
      total_project_cost = '',
      images = []
    } = parsedData

    const deleteImage = (index) => {
      handleProjectOnEdit('images', '', index)
    }

    return (
      <Box sx={{ width: '100%', height: '100%', wordBreak: 'break-word' }}>
        <Box sx={{ padding: '25px 20px' }}>
          <ScrollablePictures
            images={images}
            s3Obj={s3Obj}
            viewImage={setSelectedImage}
            deleteImage={deleteImage}
          />
          <ProfilePicturePicker
            isReadOnly={saving}
            name={'Project Image'}
            handleMode={handleMode}
            onImageSelect={addprofilePicture}
            viewImage={setSelectedImage}
            faces={faces}
            s3Obj={s3Obj}
            currentPage={currentPage + 1}
          />
          <div>
            {
              <>
                <ContentEditable
                  key={'Project Name'}
                  html={project_name}
                  placeholder={'Project Name'}
                  style={{ fontWeight: 'bold' }}
                  onChange={(e) =>
                    handleProjectOnEdit(
                      'project_name',
                      e.currentTarget.innerText
                    )
                  }
                  disabled={saving}
                />
              </>
            }
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '100%',
              gap: '0px',
              gridAutoFlow: 'row'
            }}
          >
            <div style={{ gridColumn: '1', margin: '5px' }}>
              {
                <>
                  <Text text={'Location : '} style={{ fontWeight: 'bold' }} />
                  {/* <Text text={project_location + "<br/>"} style={{ fontStyle: 'italic' }} /> */}
                  <ContentEditable
                    key={'Location'}
                    html={project_location}
                    placeholder={'Location'}
                    onChange={(e) =>
                      handleProjectOnEdit(
                        'project_location',
                        e.currentTarget.innerText
                      )
                    }
                    disabled={saving}
                  />
                </>
              }
              {
                <>
                  <Text text={'Budget : '} style={{ fontWeight: 'bold' }} />
                  {/* <Text text={total_project_cost + "<br/>"} style={{ fontStyle: 'italic' }} /> */}
                  <ContentEditable
                    key={'Project Cost'}
                    html={total_project_cost}
                    placeholder={'Project Cost'}
                    onChange={(e) =>
                      handleProjectOnEdit(
                        'total_project_cost',
                        e.currentTarget.innerText
                      )
                    }
                    disabled={saving}
                  />
                </>
              }
              {
                <>
                  <Text text={'Area : '} style={{ fontWeight: 'bold' }} />
                  {/* <Text text={square_footage + "<br/>"} style={{ fontStyle: 'italic' }} /> */}
                  <ContentEditable
                    key={'Square Footage'}
                    html={square_footage}
                    placeholder={'Square Footage'}
                    onChange={(e) =>
                      handleProjectOnEdit(
                        'square_footage',
                        e.currentTarget.innerText
                      )
                    }
                    disabled={saving}
                  />
                </>
              }
              {
                <>
                  <Text text={'Start Date : '} style={{ fontWeight: 'bold' }} />
                  {/* <Text text={timeline + "<br/>"} style={{ fontStyle: 'italic' }} /> */}
                  <ContentEditable
                    key={'Start Date'}
                    html={project_timeline?.start_date}
                    placeholder={'Start Date'}
                    onChange={(e) =>
                      handleProjectOnEdit(
                        'project_timeline',
                        e.currentTarget.innerText,
                        '',
                        'start_date'
                      )
                    }
                    disabled={saving}
                  />
                  <Text text={'End Date : '} style={{ fontWeight: 'bold' }} />
                  {/* <Text text={timeline + "<br/>"} style={{ fontStyle: 'italic' }} /> */}
                  <ContentEditable
                    key={'End Date'}
                    html={project_timeline?.end_date}
                    placeholder={'End Date'}
                    onChange={(e) =>
                      handleProjectOnEdit(
                        'project_timeline',
                        e.currentTarget.innerText,
                        '',
                        'end_date'
                      )
                    }
                    disabled={saving}
                  />
                </>
              }
              {
                <>
                  <Text
                    text={'Project Details  <br/>'}
                    style={{ fontWeight: 'bold' }}
                  />
                  {/* <Text text={project_description} /> */}
                  <ContentEditable
                    key={'Project Description'}
                    html={project_description}
                    placeholder={'Project Description'}
                    onChange={(e) =>
                      handleProjectOnEdit(
                        'project_description',
                        e.currentTarget.innerText
                      )
                    }
                    disabled={saving}
                  />
                </>
              }
            </div>
          </div>
          <Box key={'team_members'}>
            {
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Text
                    text={'Team Members  <br/>'}
                    style={{ fontWeight: 'bold' }}
                  />
                </Box>
                <Tooltip title={'Add New ' + 'Team Member'}>
                  <IconButton
                    disableRipple
                    sx={{ padding: '0' }}
                    onClick={(e) => {
                      addNewTeamMemberToProject('project_team_members')
                    }}
                    disabled={saving}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            {project_team_members.length > 0 &&
              project_team_members?.map((displayValueObj, index2) => {
                return (
                  <Box key={index2}>
                    <Box sx={{ textAlign: 'end' }}>
                      <Tooltip title={'Remove ' + 'Team Member'}>
                        <IconButton
                          disableRipple
                          sx={{ padding: '0' }}
                          disabled={saving}
                          onClick={(e) => {
                            removeTeamMemberFromProject(
                              'project_team_members',
                              index2
                            )
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    {Object.keys(displayValueObj || {})?.map(
                      (childKey, index3) => {
                        const childPlaceholder = _.startCase(childKey)
                        const childValue = displayValueObj[childKey] || ''
                        return (
                          <Tooltip
                            enterDelay={1000}
                            title={childPlaceholder}
                            placement="right-start"
                            arrow
                            key={index3}
                          >
                            <Box>
                              <ContentEditable
                                key={index3}
                                style={{}}
                                html={childValue}
                                onChange={(e) =>
                                  handleProjectOnEdit(
                                    'project_team_members',
                                    e.currentTarget.innerText,
                                    index2,
                                    childKey
                                  )
                                }
                                placeholder={childPlaceholder}
                                disabled={saving}
                              />
                            </Box>
                          </Tooltip>
                        )
                      }
                    )}
                  </Box>
                )
              })}
          </Box>
        </Box>
      </Box>
    )
  }
  const renderJSON = () => {
    const { parsedData = {} } = selectedProject || {}

    const json = JSON.stringify(parsedData, null, 2)
    return (
      <Box
        sx={{
          padding: '20px',
          '& pre': {
            maxHeight: '100%'
          }
        }}
      >
        <Box sx={{ textAlign: 'end' }}>
          <Tooltip title={'Copy JSON'}>
            <CopyButton content={json} />
          </Tooltip>
        </Box>
        <SyntaxHighlighter language="json">{json}</SyntaxHighlighter>
      </Box>
    )
  }

  const renderProject = () => {
    const { parsedData = {} } = selectedProject || {}
    const {
      project_name = '',
      project_location = '',
      project_description = '',
      images = [],
      project_timeline = {},
      project_team_members = [],
      square_footage = '',
      total_project_cost = ''
    } = parsedData
    const timeline =
      project_timeline?.start_date +
      (project_timeline?.end_date ? ' - ' + project_timeline?.end_date : '')

    const renderKeyTeamMembers = (teamMembers) => {
      return teamMembers.map((member, index) => (
        <div key={index}>
          <Text
            text={
              (member?.name ? member?.name : '') +
              (member?.role ? ', ' + member?.role : '') +
              (member?.responsibilities
                ? ', ' + member?.responsibilities
                : '') +
              '<br/>'
            }
          />
        </div>
      ))
    }
    return (
      <Box sx={{ width: '100%', height: '100%', wordBreak: 'break-word' }}>
        <Box
          sx={{
            padding: '25px 20px'
          }}
        >
          <ScrollablePictures images={images} s3Obj={s3Obj} type={'view'} />
          <div>
            {project_name && (
              <>
                <Text
                  text={project_name + '<br/>'}
                  style={{ fontWeight: 'bold' }}
                />
              </>
            )}
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '30% 70%',
              gap: '0px',
              gridAutoFlow: 'row'
            }}
          >
            <div style={{ gridColumn: '1', margin: '5px' }}>
              {project_location && (
                <>
                  <Text
                    text={'Location : <br/>'}
                    style={{ fontWeight: 'bold' }}
                  />
                  <Text
                    text={project_location + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </>
              )}
              {total_project_cost && (
                <>
                  <Text
                    text={'Budget : <br/>'}
                    style={{ fontWeight: 'bold' }}
                  />
                  <Text
                    text={total_project_cost + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </>
              )}
              {square_footage && (
                <>
                  <Text text={'Area : <br/>'} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={square_footage + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </>
              )}
              {timeline && (
                <>
                  <Text
                    text={'Duration : <br/>'}
                    style={{ fontWeight: 'bold' }}
                  />
                  <Text
                    text={timeline + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </>
              )}
              {project_team_members.length > 0 && (
                <>
                  <Text
                    text={'Key Team Members :'}
                    style={{ fontWeight: 'bold' }}
                  />
                  {renderKeyTeamMembers(project_team_members)}
                </>
              )}
            </div>
            <div style={{ gridColumn: '2', margin: '5px', overflow: 'auto' }}>
              {project_description && (
                <>
                  <Text
                    text={'Project Details  <br/>'}
                    style={{ fontWeight: 'bold' }}
                  />
                  <Text text={project_description} />
                </>
              )}
            </div>
          </div>
        </Box>
      </Box>
    )
  }

  const renderFloatTools = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title={'Edit Project'}>
          <IconButton
            disableRipple
            sx={{
              color: insideView === 'edit' ? selectedColor : 'primary',
              padding: '0'
            }}
            onClick={() => {
              setInsideView('edit')
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        {edit === 'people' && (
          <Tooltip title={'Preview'}>
            <IconButton
              disableRipple
              sx={{
                color: insideView === 'preview' ? selectedColor : 'primary',
                padding: '0'
              }}
              onClick={() => {
                setInsideView('preview')
              }}
            >
              <PreviewIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={'View JSON'}>
          <IconButton
            disableRipple
            sx={{
              color: insideView === 'viewjson' ? selectedColor : 'primary',
              padding: '0'
            }}
            onClick={() => {
              setInsideView('viewjson')
            }}
          >
            <CodeIcon />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  const renderMainEditor = () => {
    switch (insideView) {
      case 'edit':
        return renderEditor()
      case 'preview':
        return renderProject()
      case 'viewjson':
        return renderJSON()
    }
  }

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData('text/plain', index)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }
  const handleDrop = (event, targetIndex) => {
    const draggedIndex = parseInt(event.dataTransfer.getData('text/plain'), 10)
    const newTextBBox = [...textBBox]
    ;[newTextBBox[draggedIndex], newTextBBox[targetIndex]] = [
      newTextBBox[targetIndex],
      newTextBBox[draggedIndex]
    ]
    setTextBBox(newTextBBox)
  }

  const handleDragEnter = (key) => {
    if (!saving) {
      setDragActiveTextBox(key)
    }
  }

  const handleDragEnd = () => {
    setDragActiveTextBox('')
  }

  const handleTextExtractUpdate = (index, value) => {
    const newAnn = _.cloneDeep(textBBox)
    newAnn[index].text = value
    setTextBBox(newAnn)
  }

  return (
    <Box sx={{ height: '1px', minHeight: '100%' }}>
      <Container>
        {reprocessMode === 'textextract' ? (
          <Container>
            <Section overFlow>
              <Box sx={{ padding: '20px' }}>
                <Box sx={{ fontSize: '16px' }}>Text Extracted</Box>
                {textBBox.length > 0 &&
                  textBBox.map((textextract, index) => (
                    <Box
                      key={index}
                      draggable={!saving}
                      onDragStart={(event) => handleDragStart(event, index)}
                      onDragOver={handleDragOver}
                      onDragEnd={() => handleDragEnd()}
                      onDrop={(event) => handleDrop(event, index)}
                      onDragEnter={() => handleDragEnter(index)}
                      sx={{
                        borderTop:
                          dragActiveTextBox === index ? '1px solid red' : ''
                      }}
                    >
                      <Box sx={{ textAlign: 'end' }}>
                        <Tooltip title={'Delete BBox'}>
                          <IconButton
                            disableRipple
                            sx={{ padding: '0' }}
                            onClick={() => {
                              handleSelectionAction('deleteTextBoxIndex', index)
                            }}
                            disabled={saving}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          disableRipple
                          sx={{ padding: '0' }}
                          disabled={saving}
                        >
                          <DragIndicatorIcon />
                        </IconButton>
                      </Box>
                      <ContentEditable
                        style={{
                          border: '1px solid #E5E5E5',
                          margin: '10px 0px',
                          padding: '5px',
                          borderRadius: '5px'
                        }}
                        html={textextract?.text}
                        onChange={(e) =>
                          handleTextExtractUpdate(index, e.target.value)
                        }
                        placeholder={'Text Extracted'}
                        disabled={saving}
                      />
                    </Box>
                  ))}
              </Box>
            </Section>
          </Container>
        ) : _.isEmpty(selectedProject) ? (
          <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
            Selected Project will appear here
          </Box>
        ) : (
          <>
            <Section overFlow>
              {renderFloatTools()}
              {renderMainEditor()}
            </Section>
          </>
        )}
      </Container>
    </Box>
  )
}

export default ProjectEditor
