import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  backgroundWrapper: (props) => ({
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#E5E5E5'
  })
})

export { useStyles }
