import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RfxPDFView from '../../sections/RfxPDFView'
import { ROUTES } from '../../config/routes'
import { fetchRFx } from '../../store/RFx/Actions'
import Loader from '../../components/Loader'
import { initalizeS3, getSignedUrl } from '../../utils/AWS'
import { isEmpty, cloneDeep } from 'lodash'
import { getPublicRFx } from '../../store/api'
import PDFViewer from '../../sections/RfxPDFView/PDFViewer'
import { Container, Section } from '../../components/Container'

const ShareRfx = () => {
  const [rfx, setRfx] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { type, shareId } = useParams()
  const [initialLoad, setInitialLoad] = useState(true)
  const [loading, setLoading] = useState(true)
  const [PDFData, setPDFData] = useState({})
  const [error, setError] = useState('')
  const [s3Obj, sets3Obj] = useState({})
  const [PDFUrl, setPDFUrl] = useState('')

  useEffect(() => {
    async function initalizeData() {
      const res = await getPublicRFx(shareId)
      console.log(res)
      setRfx(res.data)

      // const s3 = await initalizeS3()
      // sets3Obj(s3)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    async function initalizeData() {
      const newRfxObj = cloneDeep(rfx)

      if (initialLoad && !isEmpty(rfx)) {
        const error = false
        const url = await getPDFURL(newRfxObj)
        setInitialLoad(false)
        if (!error) {
          setPDFUrl(url)
          setLoading(false)
          setPDFData(newRfxObj)
        }
      } else {
        if (!isEmpty(rfx)) {
          setPDFData(newRfxObj)
        }
      }
    }
    initalizeData()
  }, [rfx])

  const getPDFURL = async (data) => {
    const { pdf_location } = data
    const uri = pdf_location
    return uri
  }

  const handlePdfBack = () => {
    navigate(-1)
  }

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ width: '100%', height: '100vh' }}
      >
        <Loader loading={loading} caption="opening your rfx" />
      </Grid>
    )
  }

  return (
    <Container>
      <Section overFlow={true}>
        <PDFViewer
          url={PDFUrl}
          PDFData={PDFData}
          isAnalytics={false}
          shareType={'public'}
        />
      </Section>
    </Container>
  )
}

export default ShareRfx
