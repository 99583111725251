import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  webviewer: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    overflow: 'auto'
  },
  infoContainer: {
    borderLeft: '1px solid #E5E5E5',
    display: 'flex',
    // width:"20vh",
    flexDirection: 'column'
  },
  page: {
    height: '100%'
  },
  container: {
    position: 'centre',
    padding: '20px'
  },
  dot: {
    width: '20px',
    height: '20px',
    borderRadius: '50%'
  },
  errorWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  keywordWrapper: {
    width: '40%',
    fontSize: '16px',
    fontWeight: '500'
  },
  typeWrapper: {
    width: '35%',
    fontWeight: '400',
    fontStyle: 'italic'
  },
  pageNoWrapper: {
    width: '15%',
    fontWeight: '500'
  },
  errorContainer: {
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '12px',
    margin: '7px 0px',
    fontFamily: 'PoppinsRegular'
  },
  descWrapper: {
    color: '#949494',
    fontWeight: '500'
  },
  collapseWrapper: {
    marginTop: '10px'
  },
  buttonWrapper: {
    textAlign: 'end',
    '& button': {
      margin: '10px 5px'
    }
  },
  generateDropDown: {
    marginTop: '10px'
  },
  generateText: {
    marginTop: '10px',
    width: '100%'
  },
  generateButton: {
    marginTop: '10px',
    textAlign: 'end'
  },
  buttonGroupWrapper: {
    justifyContent: 'center',
    marginBottom: '10px',
    display: 'flex'
  },
  pptThumbnails: {
    overflow: 'hidden'
  },
  pptViewerBottomLine: {
    bottom: '0',
    height: '32px',
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    alignItems: 'center'
  },
  pptViewerNavLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  pptViewerNavButton: {
    margin: '0 10px',
    color: 'white',
    backgroundColor: '#f2f2f2',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  pptViewerFullScreenButton: {
    margin: '0 10px',
    color: 'white',
    backgroundColor: '#f2f2f2',
    border: 'none',
    right: '0',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: '10px'
  }
})

export { useStyles }
