import { makeStyles } from '@mui/styles'

import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor() // Get the selected color from the context

  return {
    labelStyleDefault: {
      fontWeight: 'bold',
      margin: '14px 0px'
    },
    inputStyleDefault: {
      width: '100%',
      '& .MuiAutocomplete-input': {
        padding: '8.5px 14px'
      }
    },
    tagsInputContainer: {
      margin: '5px 0px',
      borderRadius: '3px',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '.5em'
    },
    tagItem: {
      wordBreak: 'break-all',
      display: 'flex',
      padding: '4px 14px',
      background: 'rgba(9, 30, 66, 0.08)',
      borderRadius: '4px',
      border: 'solid 1px #E5E5E5',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      color: '#515151',
      '& button': {
        padding: 0
      }
    },
    tagClose: {
      fontSize: '16px',
      color: '#515151',
      padding: '0px 3px'
    },
    autocomp: {
      '& .MuiOutlinedInput-root': {
        padding: 0
      }
    },
    hiddenTagAction: {
      background: selectedColor,
      paddingTop: '4px',
      paddingBottom: '4px',
      textTransform: 'capitalize',
      '&:hover': {
        background: '#195ccb'
      }
    },
    inputTextField: {
      /* width:"100%" */
    },
    emptyTagMessage: {
      marginTop: '10px'
    }
  }
})

export { useStyles }
