import React, { useEffect, useState } from 'react'
import { Button, Loader } from '../../components'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { FormControl } from '@mui/base'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@mui/material/InputLabel'
import { Box } from '@mui/material'
import { updateUserConfig } from '../../store/Authentication/Actions'
import { setToasterAlert } from '../../store/Common/Actions'
import { isEmpty } from 'lodash'
import { useStyles } from './styles'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../config/eventMapping'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'

const ProfileForm = ({ isFocused }) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.authenticate)
  const user = auth?.user?.domain
  const [name, setName] = useState('')
  const [companyName, setcompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const { container, button1, inputLabel, text } = classes
  const { selectedColor } = useColor()

  useEffect(() => {
    if (!isEmpty(user)) {
      setName(user.user_name)
      setcompanyName(user.company_name)
      setEmail(user.email)
      setLoading(false)
    }
  }, [user])

  const handleUpdate = async (e) => {
    e.preventDefault()
    if (name && companyName) {
      const data = {
        user_name: name,
        company_name: companyName
      }
      trackEvent(
        mixpanelEvents.SETTINGS_PROFILE_DETAILS_UPDATED,
        'SUCCESS',
        {},
        {}
      )
      const cb = () => {
        toast.success('Profile updated successfully')
      }
      dispatch(updateUserConfig(data, cb))
    } else {
      // toast.error('All field are required')
      trackEvent(
        mixpanelEvents.SETTINGS_PROFILE_DETAILS_UPDATED,
        'FAILED',
        {},
        { message: 'All field are required' }
      )
      dispatch(
        setToasterAlert({
          message: 'All field are required',
          show: true,
          type: 'error'
        })
      )
    }
  }

  return (
    <Box className={container}>
      {loading ? (
        <Loader loading={loading} caption={'loading your profile'} />
      ) : (
        <>
          <FormControl>
            <InputLabel>Name</InputLabel>
            <TextField
              className={text}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <InputLabel className={inputLabel}>Company Name</InputLabel>
            <TextField
              className={text}
              variant="outlined"
              // disabled
              value={companyName}
              onChange={(e) => setcompanyName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <InputLabel className={inputLabel}>Email</InputLabel>
            <TextField
              className={text}
              variant="outlined"
              disabled
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Box className={button1}>
            <Button
              onClick={(e) => handleUpdate(e)}
              sx={{
                backgroundColor: selectedColor
              }}
            >
              Update
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default ProfileForm
