import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './RootReducer'

const callbackMiddleware = (store) => (next) => (action) => {
  if (action.callback) {
    action.callback(store.getState())
  }
  return next(action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(callbackMiddleware)
})

export default store
