import { useState, useEffect } from 'react'
// import { useStyles } from "./styles";
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import {
  fetchLibrary,
  setActiveTag,
  updateLibraryDocument,
  removeCollection,
  changeCollectionName
} from '../../../store/Library/Actions'
import { scrollIntoViewIfNeeded } from '../../../utils/ScrollIntoView'
import Favorite from '@mui/icons-material/Favorite'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import { UPDATE_LIBRARY_COLLECTIONS } from '../../../store/actionTypes'
import { SelectRadio, TextInput } from '../../../components'
import Loader from '../../../components/Loader'
import { createCollection } from '../../../store/api'
import {
  checkUserRoleAdmin,
  checkDomainAccess,
  checkUserRoleViewer
} from '../../../utils/User'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import DoneIcon from '@mui/icons-material/Done'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as AI_Flash } from '../../../assets/images/AI_Flash.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Typography } from '@mui/material'
import TextLineLimiter from '../../../components/TextLineLimiter'
import { useColor } from '../../../ThemeContext'
import { toast } from 'react-toastify'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { useStyles } from './styles'

const SortMenu = (props) => {
  const {
    filterBy = [],
    setFilterBy = () => {},
    disabled = false,
    filterOpen = false,
    setFilterOpen = () => {}
  } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!disabled) {
      setFilterOpen(!filterOpen)
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setFilterOpen(false)
  }

  const filterOptions = [
    {
      key: 'name',
      order: 'asc',
      label: 'Name (A-Z)'
    },
    {
      key: 'name',
      order: 'desc',
      label: 'Name (Z-A)'
    },
    {
      key: 'library_id',
      order: 'desc',
      label: 'Collections Size (Largest)'
    },
    {
      key: 'library_id',
      order: 'asc',
      label: 'Collections Size (Smallest)'
    },
    {
      key: 'created_at',
      order: 'desc',
      label: 'Created Date (Newest)'
    },
    {
      key: 'created_at',
      order: 'asc',
      label: 'Created Date (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'asc',
      label: 'Last Modified (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'desc',
      label: 'Last Modified (Newest)'
    }
  ]

  const handleFilter = (e, filter) => {
    e.stopPropagation()
    e.preventDefault()
    setFilterBy(filter)
    handleClose()
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <IconButton
        className={classes.iconButton}
        onClick={handleClick}
        disabled={disabled}
      >
        <SwapVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.hideMenu}
      >
        {filterOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={
              filterBy.key === option.key && filterBy.order === option.order
            }
            onClick={(e) =>
              handleFilter(e, { key: option.key, order: option.order })
            }
          >
            <Box className={classes.hideMenuListText}>{option.label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

const LibraryCollectionList = (props) => {
  const { active, setActive } = props
  const libraryState = useSelector((state) => state.library)
  const {
    libraryCollections: libraryCollectionsState = {},
    activeTag = '',
    suggestedCount = 0
  } = libraryState
  const [libraryCollections, setLibraryCollections] = useState({})
  const { public_collections = [], private_collections = [] } =
    libraryCollections || {}
  const libraryTagsCount =
    public_collections.length + private_collections.length
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '' } = domain || {}
  // const classes = useStyles();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showCreateCollection, setShowCreateCollection] = useState(false)
  const [collectionName, setCollectionName] = useState('')
  const isAdmin = checkUserRoleAdmin()
  const hasSuggestions = checkDomainAccess('library_suggestions')
  const showSuggestions = isAdmin && hasSuggestions
  const [hovered, setHovered] = useState('')
  const [isEditIndex, setIsEditIndex] = useState({})
  const [isEditName, setIsEditName] = useState({})
  const [filterBy, setFilterBy] = useState({
    key: 'name',
    order: 'asc'
  })
  const [filterOpen, setFilterOpen] = useState(false)

  const isUserViewer = checkUserRoleViewer()

  const getDataFromLocal = () => {
    let data = localStorage.getItem('libraryCollectionsSort')
    data = data ? JSON.parse(data) : null
    return data
  }

  const saveDataToLocal = (data) => {
    localStorage.setItem('libraryCollectionsSort', JSON.stringify(data))
  }

  useEffect(() => {
    const data = getDataFromLocal()
    if (data) {
      setFilterBy(data)
    }
  }, [])

  useEffect(() => {
    if (libraryCollectionsState) {
      const collections = sortCollections(
        _.cloneDeep(libraryCollectionsState),
        filterBy
      )
      setLibraryCollections(collections)
      saveDataToLocal(filterBy)
    }
  }, [libraryCollectionsState, filterBy])

  const sortCollections = (collections, filterBy) => {
    const { public_collections = [], private_collections = [] } =
      collections || {}
    const sortData = (data) => {
      return data.sort((a, b) => {
        const key = filterBy.key
        let order = filterBy.order
        let comparisonA = a[key]
        let comparisonB = b[key]
        if (key === 'created_at' || key === 'updated_at') {
          comparisonA = new Date(a[key])
          comparisonB = new Date(b[key])
          if (comparisonA.getTime() === comparisonB.getTime()) {
            comparisonA = a.name.toLowerCase()
            comparisonB = b.name.toLowerCase()
            order = 'asc'
          }
        } else if (key === 'name') {
          comparisonA = a[key].toLowerCase()
          comparisonB = b[key].toLowerCase()
        } else if (key === 'library_id') {
          comparisonA = a[key]?.length || 0
          comparisonB = b[key]?.length || 0
          if (comparisonA === comparisonB) {
            comparisonA = a.name.toLowerCase()
            comparisonB = b.name.toLowerCase()
            order = 'asc'
          }
        }
        if (comparisonA < comparisonB) {
          return order === 'desc' ? 1 : -1
        }
        if (comparisonA > comparisonB) {
          return order === 'desc' ? -1 : 1
        }
        return 0
      })
    }
    return {
      public_collections: sortData(public_collections),
      private_collections
    }
  }

  const handleTagClick = (tag) => {
    if (filterOpen) {
      return
    }
    const data = {
      domainId: domain_id
    }
    if (tag) {
      data.collection = tag?.id
      data.collectionType = tag?.visibility
    }
    dispatch(setActiveTag(tag))
  }

  const handleNewCollection = async (visibility) => {
    setLoading(visibility)
    try {
      if (collectionName) {
        const res = await createCollection({ name: collectionName, visibility })
        if (res.status === 200) {
          dispatch({
            type: UPDATE_LIBRARY_COLLECTIONS,
            payload: res.data
          })
          setShowCreateCollection(false)
          setCollectionName('')
        } else {
          toast.error(res.data.message || 'Error')
        }
      } else {
        toast.error('Collection Name and Type are required')
      }
    } catch (e) {
      setLoading(false)
    }
    setLoading(false)
  }

  const handleDrop = (event, tag) => {
    let el = event.dataTransfer.getData('data')
    if (el) {
      el = JSON.parse(el)
      const { collections } = el
      const { id } = tag
      const objKeys = [...Object.keys(collections)]
      if (!objKeys.includes(id)) {
        const temp = {
          id: el.id,
          collections: [...objKeys, id]
        }
        dispatch(updateLibraryDocument(temp))
      }
    }
    setActive('')
  }

  const handleDragOver = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handeShowClose = () => {
    setCollectionName('')
    setLoading(false)
    setShowCreateCollection(false)
  }

  useEffect(() => {
    const { name, visibility } = activeTag || {}
    const index = _.findIndex(
      visibility === 'public' ? public_collections : private_collections,
      { name }
    )
    if (index > -1) {
      scrollIntoViewIfNeeded('collections_' + visibility + index, 'collections')
    }
  }, [activeTag])

  const handleMouseEnter = (id) => {
    setHovered(id)
  }

  const handleMouseLeave = () => {
    setHovered('')
  }

  const handleChangeCollectionName = (tag) => {
    const { id, visibility } = tag
    if (!isEditName?.[id]) {
      toast.error('Collection name is required')
      return
    }
    const callback = () => {
      setIsEditIndex({
        ...isEditIndex,
        [id]: false
      })
      setIsEditName({
        ...isEditName,
        [id]: ''
      })
    }
    const req = {
      name: isEditName?.[id],
      id,
      visibility
    }
    dispatch(changeCollectionName(req, callback))
  }

  const { selectedColor } = useColor()

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box
        id="collections"
        sx={{
          padding: '10px',
          height: 'calc(100% - 30px)',
          width: 'calc(100% - 30px)',
          overflow: 'auto',
          fontSize: '16px',
          color: '#71717a',
          '& .Mui-selected': {
            backgroundColor: '#f4f4f5',
            color: '#18181b'
          },
          '& .Mui-selected:hover': {
            backgroundColor: '#f4f4f5',
            color: '#18181b'
          },
          '& input': {
            padding: '2px 7px'
          },
          '& .MuiButtonBase-root': {
            padding: '3px 10px',
            borderRadius: '5px'
          }
        }}
      >
        <List sx={{ padding: '0px' }}>
          <ListItem
            disablePadding
            id="collections_fav"
            sx={{
              marginBottom: '3px'
            }}
          >
            <ListItemButton
              selected={activeTag?.name === '' && activeTag?.visibility === ''}
              onClick={() => handleTagClick({ name: '', visibility: '' })}
            >
              <ListItemText
                primary={
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        wordBreak: 'break-word',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '13px',
                        textWrap: 'balance'
                      }}
                    >
                      All
                    </Box>
                    <SortMenu
                      filterBy={filterBy}
                      setFilterBy={setFilterBy}
                      filterOpen={filterOpen}
                      setFilterOpen={setFilterOpen}
                    />
                  </Box>
                }
              />
            </ListItemButton>
          </ListItem>
          {showSuggestions && (
            <ListItem
              disablePadding
              id="collections_suggestion"
              sx={{ marginBottom: '5px' }}
            >
              <ListItemButton
                selected={
                  activeTag?.name === '' &&
                  activeTag?.visibility === 'suggestion'
                }
                onClick={() =>
                  handleTagClick({ name: '', visibility: 'suggestion' })
                }
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex' }}>
                      <Box
                        sx={{
                          color: '#949494',
                          width: '100%',
                          wordBreak: 'break-word',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '15px',
                          textWrap: 'balance'
                        }}
                      >
                        <SvgIcon style={{ fontSize: '16px' }}>
                          <AI_Flash />
                        </SvgIcon>
                        <span style={{ fontWeight: 600, marginLeft: '7px' }}>
                          AI Suggestions
                        </span>
                        &nbsp;
                        <Box component="span" sx={{ fontWeight: '' }}>
                          ({suggestedCount || 0})
                        </Box>
                        <Tooltip
                          title={'Generated Suggestions. Visible to Admins'}
                        >
                          <InfoOutlinedIcon
                            sx={{ fontSize: '18px', marginLeft: '5px' }}
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                  }
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding id="collections_public_fav">
            <ListItemButton
              sx={{ width: '-webkit-fill-available', marginBottom: '3px' }}
              selected={
                activeTag?.name === '' && activeTag?.visibility === 'public'
              }
              disableRipple
              onClick={() => handleTagClick({ name: '', visibility: 'public' })}
            >
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex' }}>
                    <Box
                      sx={{
                        width: '100%',
                        wordBreak: 'break-word',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '13px',
                        textWrap: 'balance'
                      }}
                    >
                      Global Collections
                      <Tooltip
                        title={
                          'Visible to all users, ensuring easy sharing and collaboration.'
                        }
                      >
                        <InfoOutlinedIcon
                          sx={{ fontSize: '18px', marginLeft: '5px' }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                }
              />
            </ListItemButton>
            {isAdmin && (
              <ListItemButton
                sx={{ justifyContent: 'flex-end' }}
                onClick={() => setShowCreateCollection('public')}
              >
                <Box
                  sx={{
                    display: 'flex',
                    color: selectedColor,
                    alignItems: 'center',
                    margin: '4px 0px',
                    width: '75px'
                  }}
                >
                  <AddBoxOutlinedIcon
                    size
                    sx={{
                      marginRight: '5px',
                      strokeWidth: 1,
                      fontSize: '18px'
                    }}
                  />
                  <Box
                    sx={{
                      wordBreak: 'break-word',
                      fontWeight: 600,
                      fontSize: '14px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: 'auto',
                      textWrap: 'nowrap'
                    }}
                  >
                    New
                  </Box>
                </Box>
              </ListItemButton>
            )}
          </ListItem>
          {public_collections?.map((tag, key) => {
            const { name, library_id = [], status, id } = tag
            const count = parseInt(library_id?.length ? library_id?.length : 0)
            const keyid = 'public' + key
            const disableItem = !!status
            const isEditing = isEditIndex?.[id]
            return (
              <ListItem
                key={key}
                id={keyid}
                disableRipple
                disablePadding
                sx={{
                  border: active === id ? '1px dashed red' : '',
                  marginBottom: '3px',
                  marginLeft: '10px'
                }}
                onMouseEnter={() => handleMouseEnter(id)}
                onMouseLeave={() => handleMouseLeave()}
              >
                <ListItemButton
                  disableRipple
                  onDrop={(event) => isAdmin && handleDrop(event, tag)}
                  onDragEnter={() => isAdmin && setActive(id)}
                  onDragOver={(event) => isAdmin && handleDragOver(event)}
                  selected={
                    activeTag?.name === name &&
                    activeTag.visibility === 'public'
                  }
                  id={id}
                  onClick={() => !isEditing && handleTagClick(tag)}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          fontSize: '14px',
                          opacity: disableItem ? '0.3' : 1
                        }}
                      >
                        {isEditing ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextInput
                              placeholder="Name"
                              value={isEditName?.[id]}
                              handleChange={(e) => {
                                setIsEditName({
                                  ...isEditName,
                                  [id]: e.target.value
                                })
                              }}
                              onClick={(e) => e.stopPropagation()}
                            />

                            <Tooltip title={'Save'}>
                              <IconButton
                                disableRipple
                                disabled={disableItem}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleChangeCollectionName(tag)
                                }}
                              >
                                <DoneIcon
                                  sx={{
                                    strokeWidth: 1,
                                    stroke: '#ffffff',
                                    fontSize: '20px'
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={'Close'}>
                              <IconButton
                                disableRipple
                                disabled={disableItem}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setIsEditIndex({
                                    ...isEditIndex,
                                    [id]: false
                                  })
                                  setIsEditName({
                                    ...isEditName,
                                    [id]: ''
                                  })
                                }}
                              >
                                <CloseIcon
                                  sx={{
                                    strokeWidth: 1,
                                    stroke: '#ffffff',
                                    fontSize: '20px'
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : (
                          <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Tooltip title={'Visible to all'}>
                                <LanguageOutlinedIcon
                                  sx={{
                                    marginRight: '5px',
                                    strokeWidth: 1,
                                    stroke: '#ffffff',
                                    // fontSize: '17px'
                                    height: '14px',
                                    width: '14px'
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title={name}>
                                <span
                                  style={{
                                    fontSize: '12px'
                                  }}
                                >
                                  {name}
                                </span>
                              </Tooltip>
                              &nbsp;
                              <span
                                style={{
                                  fontSize: '11px',
                                  marginLeft: 'auto'
                                }}
                              >
                                ({count})
                              </span>
                            </Box>
                            {isAdmin && !isEditing && hovered === id && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  flexWrap: 'nowrap'
                                }}
                              >
                                <>
                                  <IconButton
                                    disableRipple
                                    sx={{
                                      padding: '',
                                      height: '14px',
                                      width: '14px'
                                    }}
                                    disableItem={disableItem}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setIsEditName({
                                        ...isEditName,
                                        [id]: name
                                      })
                                      setIsEditIndex({
                                        ...isEditIndex,
                                        [id]: true
                                      })
                                    }}
                                  >
                                    <Tooltip title={'Change Collection Name'}>
                                      <DriveFileRenameOutlineIcon
                                        sx={{
                                          strokeWidth: 1,
                                          stroke: '#ffffff',
                                          fontSize: '20px'
                                        }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                  <IconButton
                                    disableRipple
                                    disableItem={disableItem}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      dispatch(removeCollection(tag))
                                    }}
                                  >
                                    <Tooltip title={'Delete Collection'}>
                                      <DeleteOutlineOutlinedIcon
                                        sx={{
                                          strokeWidth: 1,
                                          stroke: '#ffffff',
                                          fontSize: '20px'
                                        }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                </>
                              </div>
                            )}
                          </>
                        )}
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
          {isAdmin && (
            <ListItem disablePadding id="collections_add">
              {showCreateCollection === 'public' ? (
                <ListItemText
                  primary={
                    loading === 'public' ? (
                      <div
                        style={{
                          height: '100%',
                          width: '100',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Loader loading={loading} />
                      </div>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '5px'
                        }}
                      >
                        <TextInput
                          placeholder="Name"
                          value={collectionName}
                          handleChange={(e) =>
                            setCollectionName(e.target.value)
                          }
                        />
                        <Tooltip title={'Save'}>
                          <IconButton
                            disableRipple
                            onClick={() => handleNewCollection('public')}
                          >
                            <DoneIcon
                              sx={{
                                strokeWidth: 1,
                                stroke: '#ffffff',
                                fontSize: '20px'
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Close'}>
                          <IconButton
                            disableRipple
                            onClick={() => handeShowClose()}
                          >
                            <CloseIcon
                              sx={{
                                strokeWidth: 1,
                                stroke: '#ffffff',
                                fontSize: '20px'
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )
                  }
                />
              ) : (
                <></>
              )}
            </ListItem>
          )}
          <ListItem
            disablePadding
            id="collections_public_fav"
            sx={{ marginTop: '12px' }}
          >
            <ListItemButton
              sx={{ width: '-webkit-fill-available', marginBottom: '3px' }}
              selected={
                activeTag?.name === '' && activeTag?.visibility === 'private'
              }
              onClick={() =>
                handleTagClick({ name: '', visibility: 'private' })
              }
              disableRipple
            >
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex' }}>
                    <Box
                      sx={{
                        width: '100%',
                        wordBreak: 'break-word',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '13px',
                        textWrap: 'balance'
                      }}
                    >
                      My Collections
                      <Tooltip title={'Visible only to you.'}>
                        <InfoOutlinedIcon
                          sx={{ fontSize: '18px', marginLeft: '5px' }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                }
              />
            </ListItemButton>
            {!isUserViewer ? (
              <></>
            ) : (
              <ListItemButton
                sx={{ justifyContent: 'flex-end' }}
                onClick={() => setShowCreateCollection('private')}
              >
                <Box
                  sx={{
                    display: 'flex',
                    color: selectedColor,
                    alignItems: 'center'
                    // width: '75px'
                  }}
                >
                  <AddBoxOutlinedIcon
                    size
                    sx={{
                      strokeWidth: 1,
                      fontSize: '18px'
                    }}
                  />
                </Box>
              </ListItemButton>
            )}
          </ListItem>
          {private_collections?.map((tag, key) => {
            const { name, library_id = [], status, id } = tag
            const count = parseInt(library_id?.length ? library_id?.length : 0)
            const keyid = 'private' + key
            const disableItem = !!status
            const isEditing = isEditIndex?.[id]
            return (
              <ListItem
                id={keyid}
                key={key}
                disablePadding
                disableRipple
                sx={{
                  border: active === id ? '1px dashed red' : '',
                  marginBottom: '3px',
                  marginLeft: '10px'
                }}
                onMouseEnter={() => handleMouseEnter(id)}
                onMouseLeave={() => handleMouseLeave()}
              >
                <ListItemButton
                  disableRipple
                  onDrop={(event) => handleDrop(event, tag)}
                  onDragEnter={() => setActive(id)}
                  onDragOver={(event) => handleDragOver(event)}
                  selected={
                    activeTag?.name === name &&
                    activeTag.visibility === 'private'
                  }
                  id={id}
                  onClick={() => !isEditing && handleTagClick(tag)}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          fontSize: '14px',
                          opacity: disableItem ? '0.3' : 1
                        }}
                      >
                        {isEditing ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextInput
                              placeholder="Name"
                              value={isEditName?.[id]}
                              handleChange={(e) => {
                                setIsEditName({
                                  ...isEditName,
                                  [id]: e.target.value
                                })
                              }}
                              onClick={(e) => e.stopPropagation()}
                            />

                            <Tooltip title={'Save'}>
                              <IconButton
                                disableRipple
                                disabled={disableItem}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleChangeCollectionName(tag)
                                }}
                              >
                                <DoneIcon
                                  sx={{
                                    strokeWidth: 1,
                                    stroke: '#ffffff',
                                    fontSize: '20px'
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={'Close'}>
                              <IconButton
                                disableRipple
                                disabled={disableItem}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setIsEditIndex({
                                    ...isEditIndex,
                                    [id]: false
                                  })
                                  setIsEditName({
                                    ...isEditName,
                                    [id]: ''
                                  })
                                }}
                              >
                                <CloseIcon
                                  sx={{
                                    strokeWidth: 1,
                                    stroke: '#ffffff',
                                    fontSize: '20px'
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : (
                          <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Tooltip title={'Visible to all'}>
                                <LanguageOutlinedIcon
                                  sx={{
                                    marginRight: '5px',
                                    strokeWidth: 1,
                                    stroke: '#ffffff',
                                    width: '12px',
                                    height: '12px'
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title={name}>
                                <span
                                  style={{
                                    fontSize: '12px'
                                  }}
                                >
                                  {name}
                                </span>
                              </Tooltip>
                              &nbsp;
                              <span
                                style={{
                                  fontSize: '11px',
                                  marginLeft: 'auto'
                                }}
                              >
                                ({count})
                              </span>
                            </Box>
                            {!isEditing && hovered === id && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  flexWrap: 'nowrap'
                                }}
                              >
                                <>
                                  <IconButton
                                    disableRipple
                                    sx={{ height: '14px', width: '14px' }}
                                    disableItem={disableItem}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setIsEditName({
                                        ...isEditName,
                                        [id]: name
                                      })
                                      setIsEditIndex({
                                        ...isEditIndex,
                                        [id]: true
                                      })
                                    }}
                                  >
                                    <Tooltip title={'Change Collection Name'}>
                                      <DriveFileRenameOutlineIcon
                                        sx={{
                                          strokeWidth: 1,
                                          stroke: '#ffffff',
                                          fontSize: '20px'
                                        }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                  <IconButton
                                    disableRipple
                                    sx={{ height: '12px', width: '12px' }}
                                    disableItem={disableItem}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      dispatch(removeCollection(tag))
                                    }}
                                  >
                                    <Tooltip title={'Delete Collection'}>
                                      <DeleteOutlineOutlinedIcon
                                        sx={{
                                          strokeWidth: 1,
                                          stroke: '#ffffff',
                                          fontSize: '20px'
                                        }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                </>
                              </div>
                            )}
                          </>
                        )}
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
          <ListItem disablePadding id="collections_add">
            {showCreateCollection === 'private' ? (
              <ListItemText
                primary={
                  loading === 'private' ? (
                    <div
                      style={{
                        height: '100%',
                        width: '100',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Loader loading={loading} />
                    </div>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px'
                      }}
                    >
                      <TextInput
                        placeholder="Name"
                        value={collectionName}
                        handleChange={(e) => setCollectionName(e.target.value)}
                      />
                      <Tooltip title={'Save'}>
                        <IconButton
                          disableRipple
                          onClick={() => handleNewCollection('private')}
                        >
                          <DoneIcon
                            sx={{
                              strokeWidth: 1,
                              stroke: '#ffffff',
                              fontSize: '20px'
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={'Close'}>
                        <IconButton
                          disableRipple
                          onClick={() => handeShowClose()}
                        >
                          <CloseIcon
                            sx={{
                              stroke: '#ffffff',
                              fontSize: '20px'
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )
                }
              />
            ) : (
              <></>
            )}
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}

export default LibraryCollectionList
