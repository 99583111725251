import { UPDATE_ANALYTICS, TOASTER_ALERT } from '../../../actionTypes'
import { postAnalytics } from '../../../api'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../../../config/eventMapping'
import fetchAnalytics from '../fetchAnalytics'
const updateAnalytics =
  (data, type = 'UPDATE_ANALYTICS') =>
  async (dispatch) => {
    try {
      const response = await postAnalytics(data)
      if (response.status === 200) {
        dispatch(fetchAnalytics())
        dispatch({
          type: TOASTER_ALERT,
          payload: {
            message: 'Analytics updated success',
            show: true,
            type: 'success'
          }
        })
      } else {
        dispatch({
          type: TOASTER_ALERT,
          payload: {
            message: 'Analytics updated failed',
            show: true,
            type: 'success'
          }
        })
      }
    } catch (e) {
      /* toast.error(e); */
      dispatch({
        type: TOASTER_ALERT,
        payload: {
          message: 'Failed to upload analytics',
          show: true,
          type: 'error'
        }
      })
    }
  }
export default updateAnalytics
